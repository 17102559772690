import React, { useEffect, useState, useRef } from "react";
import styles from "shared/listStyles.scss";
import { withStyles } from "@mui/styles";
import { green } from "@mui/material/colors";
import { useDispatch, useSelector } from "react-redux";
import { actionCreators as crmActions } from "redux/modules/crm";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import { Controller, FormProvider, useForm, useFormContext, useWatch } from "react-hook-form";
import { MDBTable } from "louis-mdbreact";
import PendingButton from "components/PendingButton";
import { axiosInstance } from "shared/axiosInst";
import { Helmet } from "react-helmet";

const signal = axiosInstance.CancelToken.source();

const GreenCheckbox = withStyles({
    root: {
        color: green[400],
        "&$checked": {
            color: green[600]
        }
    },
    checked: {}
})(props => <Checkbox color="default" {...props} />);

const CustomFormControlLabel = withStyles({
    root: {
        marginBottom: 0
    }
})(props => <FormControlLabel {...props} />);

const RelationshipItem = ({ relationship }) => {
    const { setValue } = useFormContext();
    const currentRelationship = useWatch({ name: "relationships" });
    const onChange = event => {
        const selectedCurrent = currentRelationship.find(cur => cur === relationship.id);
        setValue(
            `relationships`,
            !!selectedCurrent ? currentRelationship.filter(cur => cur !== relationship.id) : [...currentRelationship, relationship.id]
        );
    };
    return (
        <CustomFormControlLabel
            control={<Checkbox color="primary" checked={!!currentRelationship.find(cur => cur === relationship.id)} onChange={onChange} />}
            label={relationship.name}
        />
    );
};

const EntityEmail = () => {
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);
    const [files, setFiles] = useState([]);
    const inputRef = useRef();
    const {
        crm: { commonItems }
    } = useSelector(state => state);
    const defaultValues = { relationships: [4], only_current: true, subject: "", body: "" };
    const method = useForm({
        defaultValues
    });
    const {
        handleSubmit,
        control,
        reset,
        formState: { errors }
    } = method;
    useEffect(() => {
        dispatch(crmActions.getAttrCommons());
        return () => {
            signal.cancel("cancelled by user.");
        };
    }, [dispatch]);

    const onFileChange = event => {
        const files = event.target.files;
        setFiles(Object.values(files));
    };

    const onSubmit = data => {
        setIsLoading(true);
        const fd = new FormData();
        Object.keys(data).forEach(da => {
            fd.append(da, data[da]);
        });
        files.forEach(file => {
            fd.append("files", file);
        });
        dispatch(crmActions.sendEmail(fd, signal)).then(value => {
            if (value === 204) {
                setIsLoading(false);
                reset(defaultValues);
                inputRef.current.value = "";
                setFiles([]);
            }
        });
    };

    return (
        <div className={styles.container}>
            <Helmet>
                <title>CRM | Send Email</title>
            </Helmet>
            <div className={styles.titleWrap}>
                <h2 className={`${styles.taskTitle}`}>Send Email</h2>
            </div>
            <FormProvider {...method}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <MDBTable small className="mb-0">
                        <tbody>
                            <tr>
                                <th width="10%" className="orange-text align-middle font-weight-bold">
                                    Recipients
                                </th>
                                <td className="align-middle">
                                    <FormGroup row>
                                        {commonItems?.relationship.map(rel => (
                                            <RelationshipItem key={rel.id} relationship={rel} />
                                        ))}
                                    </FormGroup>
                                </td>
                            </tr>
                            <tr>
                                <th width="10%" className="orange-text align-middle font-weight-bold">
                                    Filters
                                </th>
                                <td className="align-middle">
                                    <FormGroup row>
                                        <CustomFormControlLabel
                                            control={
                                                <Controller
                                                    name="only_current"
                                                    control={control}
                                                    render={({ field: { onChange, value } }) => <GreenCheckbox checked={value} onChange={onChange} />}
                                                />
                                            }
                                            label="Only Current"
                                        />
                                    </FormGroup>
                                </td>
                            </tr>
                            <tr>
                                <th width="10%" className="orange-text align-middle font-weight-bold">
                                    Email
                                </th>
                                <td className="align-middle">
                                    <Controller
                                        name="subject"
                                        control={control}
                                        rules={{ required: true }}
                                        render={({ field: { ref, ...rest } }) => (
                                            <TextField
                                                fullWidth
                                                error={errors?.subject?.type === "required"}
                                                label="Subject"
                                                variant="outlined"
                                                className="mb-3"
                                                inputRef={ref}
                                                helperText={errors?.subject?.type === "required" ? "Required" : ""}
                                                {...rest}
                                            />
                                        )}
                                    />
                                    <TextField
                                        fullWidth
                                        label="Attaching Files"
                                        inputProps={{
                                            multiple: true
                                        }}
                                        inputRef={inputRef}
                                        onChange={onFileChange}
                                        InputLabelProps={{ shrink: true }}
                                        type="file"
                                        variant="outlined"
                                        className="mb-3"
                                    />
                                    <Controller
                                        name="body"
                                        control={control}
                                        rules={{ required: true }}
                                        render={({ field: { ref, ...rest } }) => (
                                            <TextField
                                                fullWidth
                                                error={errors?.body?.type === "required"}
                                                multiline
                                                rows={12}
                                                label="Message Body"
                                                variant="outlined"
                                                className="mb-3"
                                                helperText={errors?.body?.type === "required" ? "Required" : ""}
                                                inputRef={ref}
                                                {...rest}
                                            />
                                        )}
                                    />
                                </td>
                            </tr>
                        </tbody>
                    </MDBTable>

                    <div className="float-right">
                        <PendingButton type="submit" className="btn btn-primary" pendingLoading={isLoading}>
                            Send
                        </PendingButton>
                    </div>
                </form>
            </FormProvider>
        </div>
    );
};

export default EntityEmail;
