import { connect } from "react-redux";

import Container from "./container";
import { actionCreators as operationsActions } from "redux/modules/operations";
import { getContactListForSelect } from "redux/reducers";

const mapStateToProps = (state, ownProps) => {
    return {
        contactList: getContactListForSelect(state)
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getContactList: id => dispatch(operationsActions.getContactList(id)),
        submitEmail: (submitData, isPre) => dispatch(operationsActions.submitEmail(submitData, isPre)),
        submitFormEmail: submitData => dispatch(operationsActions.submitFormEmail(submitData)),
        submitDiaryEmail: submitData => dispatch(operationsActions.submitDiaryEmail(submitData)),
        downloadQAReport: (ids, signal) => dispatch(operationsActions.downloadQAReport(ids, signal)),
        downloadOperationalForm: (ids, signal) => dispatch(operationsActions.downloadOperationalForm(ids, signal)),
        downloadDiary: (ids, signal) => dispatch(operationsActions.downloadDiary(ids, signal))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Container);
