import React, { Component } from "react";
import Header from "./presenter";

class Container extends Component {
    constructor(props) {
        super(props);
        this.toggleNavbar = this.toggleNavbar.bind(this);
        this.state = {
            collapsed: false,
            isShow: false,
            timeModal: false,
            isShowApps: false
        };

        this.onTransitionEnd = this.onTransitionEnd.bind(this);
        this.toggleMainNav = this.toggleMainNav.bind(this);
    }

    componentDidMount() {
        const regionName = localStorage.getItem("currentRegion");
        this.setState({
            regionName: regionName || "TMPL"
        });
    }

    onTransitionEnd(event) {
        const { collapsed } = this.state;
        if (collapsed && event.propertyName === "height") {
            this.setState({
                isShow: true
            });
        }
    }

    toggleNavbar = (bool = undefined) => {
        const { pathname } = this.props;
        if (bool === false) {
            setTimeout(() => {
                document.body.style = "";
            }, 10);
            this.setState({
                collapsed: false,
                isShow: false
            });
        } else {
            if (window.innerWidth < 876) {
                document.body.style = "height:100%; overflow:hidden";
                this.setState({
                    collapsed: !this.state.collapsed
                });
            }
            if (this.state.collapsed) {
                document.body.style = "";
                this.setState({
                    isShow: false
                });
            }
        }
        if (pathname.split("/")[1] === "user" || pathname.split("/")[1] === "") {
            this.setState(prevState => ({
                isShowApps: bool !== undefined ? !!bool : false
            }));
        }

        sessionStorage.clear();
    };

    toggleMainNav = ({ isFromMain = false }) => {
        const { pathname } = this.props;
        this.setState(prevState => ({
            isShowApps: !prevState.isShowApps
        }));
        if ((pathname.split("/")[1] === "user" || pathname.split("/")[1] === "") && !isFromMain) {
            document.body.style = "";
            this.setState({
                collapsed: false,
                isShow: false
            });
        }
        sessionStorage.clear();
        // const { toggleMainNav, isShowApps } = this.props;
        // toggleMainNav(!isShowApps);
    };

    render() {
        const { collapsed, isShow, regionName, isShowApps } = this.state;
        const { pathname, permissions = {}, isQS, isAdmin, logout, isSLT, isMobile, menuList } = this.props;
        return (
            <>
                <Header
                    toggleMainNav={this.toggleMainNav}
                    toggleNavbar={this.toggleNavbar}
                    isOpen={collapsed}
                    onTransitionEnd={this.onTransitionEnd}
                    permissions={permissions}
                    isShow={isShow}
                    isShowApps={isShowApps}
                    pathname={pathname}
                    regionName={regionName}
                    isQS={isQS}
                    isSLT={isSLT}
                    isAdmin={isAdmin}
                    logout={logout}
                    isMobile={isMobile}
                    menuList={menuList}
                />
            </>
        );
    }
}

export default Container;
