import React, { memo } from "react";
import styles from "shared/headNavStyles.scss";
import IndicatorsList from "./IndicatorList";
import { useSelector } from "react-redux";
import { MDBIcon } from "louis-mdbreact";

const Indicators = () => {
    const {
        jobs: { detailData, isJobView }
    } = useSelector(state => state);
    const regions = JSON.parse(localStorage.getItem("regions"));
    const region = detailData && regions && regions.find(reg => reg.id === detailData.region);
    const title = detailData && `${detailData.id}${region ? `-${region.region_short_code}` : ""}`;

    return detailData && isJobView ? (
        <div className={styles.indicatorsContainer}>
            <div className={styles.indicators}>
                <h2>
                    <div>
                        <span className="font-weight-bold">{title}</span>{" "}
                        <span className="d-sm-inline-block d-none font-weight-bold">
                            <MDBIcon icon="angle-right" /> {detailData.job_name}
                        </span>{" "}
                    </div>
                    <div>
                        <span
                            className="d-sm-inline-block d-none font-weight-normal"
                            style={{ fontSize: "0.9em" }}
                        >
                            {detailData.client_string}
                        </span>
                    </div>
                </h2>
                <IndicatorsList job={detailData} />
            </div>
        </div>
    ) : (
        ""
    );
};

export default memo(Indicators);
