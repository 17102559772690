import React, { lazy, useState, Suspense } from "react";
import Button from "@mui/material/Button";
import { useSelector } from "react-redux";
const PublishModal = lazy(() => import("./PublishModal"));

const PublishButton = ({ refresh }) => {
    const [isOpen, setIsOpen] = useState(false);
    const { isRosterer, permissions } = useSelector(state => state.user);
    return (
        isRosterer &&
        permissions.maintenance === 4 && (
            <>
                <Button
                    onClick={() => {
                        setIsOpen(!isOpen);
                    }}
                    variant="contained"
                    color="primary"
                >
                    publish & notify
                </Button>

                {isOpen && (
                    <Suspense fallback={<div />}>
                        <PublishModal
                            modal={isOpen}
                            toggle={() => {
                                refresh();
                                setIsOpen(!isOpen);
                            }}
                        />
                    </Suspense>
                )}
            </>
        )
    );
};

export default PublishButton;
