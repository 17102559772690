// imports
import { axiosInstance } from "shared/axiosInst";
import { downloadFile } from "shared/Utils";

// variables

// actions
const SET_PO_LIST = "SET_PO_LIST";
const SET_DELIVERY_OPTIONS = "SET_DELIVERY_OPTIONS";
const SET_REFERENCE_TYPES = "SET_REFERENCE_TYPES";
const SET_PO_DETAILS = "SET_PO_DETAILS";
const ADD_PO_DETAIL = "ADD_PO_DETAIL";
const SET_TRANSFERRED_PO = "SET_TRANSFERRED_PO";
const REMOVE_TRANSFERRING_DATA = "REMOVE_TRANSFERRING_DATA";
const SET_PRICE_LIST = "SET_PRICE_LIST";
const SET_PRICE_DETAIL = "SET_PRICE_DETAIL";
const SET_PRICE_COMMONS = "SET_PRICE_COMMONS";
const SET_SELECTED_SKU_ID = "SET_SELECTED_SKU_ID";
const SET_SKU_DATABASE = "SET_SKU_DATABASE";
const SET_SLIM_SKU_DATABASE = "SET_SLIM_SKU_DATABASE";
const SET_MYGIB_ORDER = "SET_MYGIB_ORDER";

// action creators
function setPOList(poList) {
    return {
        type: SET_PO_LIST,
        poList
    };
}
function setDeliveryOptions(deliveryOptions) {
    return {
        type: SET_DELIVERY_OPTIONS,
        deliveryOptions
    };
}

function setReferenceTypes(referenceTypes) {
    return {
        type: SET_REFERENCE_TYPES,
        referenceTypes
    };
}

function setPODetails(poDetails) {
    return {
        type: SET_PO_DETAILS,
        poDetails
    };
}

function addPODetail(poDetail) {
    return {
        type: ADD_PO_DETAIL,
        poDetail
    };
}

function setTransferredPO(transferingData) {
    return {
        type: SET_TRANSFERRED_PO,
        transferingData
    };
}

function removeTransferringData() {
    return {
        type: REMOVE_TRANSFERRING_DATA
    };
}

function setPriceList(priceList) {
    return {
        type: SET_PRICE_LIST,
        priceList
    };
}

function setPriceDetail(priceDetailData) {
    return {
        type: SET_PRICE_DETAIL,
        priceDetailData
    };
}

function setPriceCommons(priceCommons) {
    return {
        type: SET_PRICE_COMMONS,
        priceCommons
    };
}

function setSelectedSKUId(skuId) {
    return {
        type: SET_SELECTED_SKU_ID,
        skuId
    };
}

function setSKUDatabase(skuDatabase) {
    return {
        type: SET_SKU_DATABASE,
        skuDatabase
    };
}

function setSlimSkuDatabase(slimSkuDatabase) {
    return {
        type: SET_SLIM_SKU_DATABASE,
        slimSkuDatabase
    };
}

function setMygibOrder(mygibOrder) {
    return {
        type: SET_MYGIB_ORDER,
        mygibOrder
    };
}

// function

// API actions

function getPOList(filter = "") {
    return async (dispatch, getState) => {
        const {
            user: { currentCompany }
        } = getState();
        const response = await axiosInstance.get(`${currentCompany}ordering/slim_purchase_order/${filter}`);
        const poList = response.data;
        dispatch(setPOList(poList));
        return poList;
    };
}

function getPODetailsByPONumber(poNumber) {
    return async (dispatch, getState) => {
        const {
            user: { currentCompany },
            material: { referenceTypes: currentReferenceType }
        } = getState();
        const referenceTypes = currentReferenceType.length ? currentReferenceType : await dispatch(getReferenceTypes());
        const response = await axiosInstance.get(`${currentCompany}ordering/purchase_order/?po_number=${poNumber}`);
        const responseData = response.data;
        const currentPO = responseData ? responseData[0] : {};
        const poDetails = {
            po_number: currentPO.po_number,
            job: currentPO.job,
            job_name: currentPO.job_name,
            supplier: currentPO.supplier,
            supplier_name: currentPO.supplier_name,
            revisions: responseData.map(detail => ({
                ...detail,
                po_external_references: detail.po_external_references.map(refer => ({
                    ...refer,
                    reference_type_name: referenceTypes.find(type => type.id === refer.external_reference_type).name
                }))
            }))
        };
        dispatch(setPODetails(poDetails));
        return poDetails;
    };
}

function getDeliveryOptions() {
    return async (dispatch, getState) => {
        const {
            user: { currentCompany, permissions },
            material: { deliveryOptions }
        } = getState();
        try {
            if (deliveryOptions.length === 0) {
                const response = await axiosInstance.get(`${currentCompany}ordering/delivery_options/`);
                const deliveryOptions = response.data;
                if (permissions.ordering !== 4) {
                    deliveryOptions.pop();
                }
                dispatch(setDeliveryOptions(deliveryOptions));
            } else {
                dispatch(setDeliveryOptions(deliveryOptions));
            }
        } catch (error) {
            console.log(error.response);
        }
    };
}

function getReferenceTypes() {
    return async (dispatch, getState) => {
        const {
            user: { currentCompany },
            material: { referenceTypes }
        } = getState();
        try {
            if (referenceTypes.length === 0) {
                const response = await axiosInstance.get(`${currentCompany}ordering/external_reference_types/`);
                const referenceTypes = response.data;
                dispatch(setReferenceTypes(referenceTypes));
                return referenceTypes;
            }
        } catch (error) {
            console.log(error.response);
        }
    };
}

function submitPO(data) {
    return async (dispatch, getState) => {
        const {
            user: { currentCompany }
        } = getState();
        try {
            const response = data.id
                ? await axiosInstance.put(`${currentCompany}ordering/purchase_order/${data.id}/`, data)
                : await axiosInstance.post(`${currentCompany}ordering/purchase_order/`, data);

            const last_revision = response.data;
            await dispatch(addPODetail(last_revision));
            return last_revision;
        } catch (error) {
            alert(error.response);
            console.log(error);
        }
    };
}

function downloadOrder(id, poNum = null) {
    return async (dispatch, getState) => {
        const {
            user: { currentCompany }
        } = getState();
        try {
            const currentUrl = poNum
                ? `${currentCompany}ordering/purchase_order/download/${poNum}/`
                : `${currentCompany}ordering/purchase_order/${id}/download/`;
            const response = await axiosInstance.get(currentUrl, { responseType: "blob" });
            downloadFile(response);
            return { message: "completed" };
        } catch (error) {}
    };
}

function getPriceList() {
    return async (dispatch, getState) => {
        const {
            user: { currentCompany }
        } = getState();
        try {
            const priceListResponse = await axiosInstance.get(`${currentCompany}pricing/price_book/slim/`);
            const priceList = priceListResponse.data;
            dispatch(setPriceList(priceList));
            return priceList;
        } catch (error) {
            return error.response;
        }
    };
}

function getPriceDetail(id) {
    return async (dispatch, getState) => {
        const {
            user: { currentCompany }
        } = getState();

        try {
            const detailResponse = await axiosInstance.get(`${currentCompany}pricing/price_book/${id}/`);
            const detailData = detailResponse.data;
            dispatch(setPriceDetail(detailData));
            return detailData;
        } catch (error) {
            return error.response;
        }
    };
}

function getPriceLogList(filter) {
    return async (dispatch, getState) => {
        const {
            user: { currentCompany }
        } = getState();
        try {
            const priceLogListResponse = await axiosInstance.get(`${currentCompany}pricing/price_log/${filter?.isCurrent ? "current/" : ""}`);
            const priceLogList = priceLogListResponse.data;
            // dispatch(setPriceList(priceList));
            return priceLogList;
        } catch (error) {
            return error.response;
        }
    };
}

function submitMyGibPO(data) {
    return async (dispatch, getState) => {
        const {
            user: { currentCompany }
        } = getState();

        try {
            const response = await axiosInstance.post(`${currentCompany}ordering/purchase_order/issue_merchant_po/`, data);
            return response.data;
        } catch (error) {
            return error.response.data;
        }
    };
}

function getMyGIBOrder(filter = "") {
    return async (dispatch, getState) => {
        const {
            user: { currentCompany }
        } = getState();

        try {
            let myGibOrder = [];
            if (currentCompany === "fire-akl/") {
                const myGibOrderResponse = await axiosInstance.get(`${currentCompany}ordering/mygib_orders/${filter}`);
                myGibOrder = myGibOrderResponse.data;
            }
            dispatch(setMygibOrder(myGibOrder));
            return myGibOrder;
        } catch (error) {
            return error.response;
        }
    };
}

function getUpcomingDelivery() {
    return async (_, getState) => {
        const {
            user: { currentCompany }
        } = getState();

        try {
            const response = await axiosInstance.get(`${currentCompany}ordering/mygib_future_deliveries/`);
            return response.data;
        } catch (error) {
            console.log(error.response);
            return [];
        }
    };
}

function getMyGIBOrderDetail(id) {
    return async (_, getState) => {
        const {
            user: { currentCompany }
        } = getState();
        try {
            const myGibOrderResponse = await axiosInstance.get(`${currentCompany}ordering/mygib_orders/${id}/detail/`);
            return myGibOrderResponse.data;
        } catch (error) {
            alert(error.response);
            console.log(error.response);
        }
    };
}

function getMerchantPOStatus() {
    return async (dispatch, getState) => {
        const {
            user: { currentCompany }
        } = getState();

        try {
            const response = await axiosInstance.get(`${currentCompany}ordering/merchant_po_status/`);
            return response.data;
        } catch (error) {
            return error.response;
        }
    };
}

function requiredMerchantPO(data, isEdit) {
    return async (dispatch, getState) => {
        const {
            user: { currentCompany }
        } = getState();

        try {
            const response = isEdit
                ? await axiosInstance.put(`${currentCompany}ordering/required_merchant_po/${data.id}/`, data)
                : await axiosInstance.post(`${currentCompany}ordering/required_merchant_po/`, data);
            return { ...response.data, timestamp: data.timestamp };
        } catch (error) {
            console.log(error.response);
            alert("Requiring merchant PO is failed. Please try it agin later.");
            return error.response;
        }
    };
}

function deleteMinimumPO(data) {
    return async (_, getState) => {
        const {
            user: { currentCompany }
        } = getState();

        try {
            const response = await axiosInstance.delete(`${currentCompany}ordering/required_merchant_po/${data.id}/`);
            return response.status;
        } catch (error) {
            console.log(error.response);
            alert("Deleting minimum PO is failed. Please try it agin later.");
            return error.response;
        }
    };
}

function getMerchantPOTemplate(data) {
    return async (_, getState) => {
        const {
            user: { currentCompany }
        } = getState();

        try {
            const response = await axiosInstance.post(`${currentCompany}ordering/purchase_order/merchant_po_template/`, data, {
                responseType: "blob"
            });
            downloadFile(response);
        } catch (error) {
            console.log(error.response);
        }
    };
}

function uploadPOTemplate(file) {
    return async (_, getState) => {
        const {
            user: { currentCompany }
        } = getState();

        try {
            const fd = new FormData();
            fd.append("template", file);

            await axiosInstance.post(`${currentCompany}ordering/purchase_order/upload_merchant_po/`, fd);

            return { message: "completed" };
        } catch (error) {
            return error.response;
        }
    };
}

function getSlimSkuDatabase(reload = false) {
    return async (dispatch, getState) => {
        const {
            user: { permissions },
            material: { slimSkuDatabase }
        } = getState();
        try {
            if (permissions.pricing && permissions.pricing > 0) {
                if (slimSkuDatabase && !reload) {
                    return slimSkuDatabase;
                } else {
                    const response = await axiosInstance.get(`linings-akl/pricing/sku_database/slim_fire/`);
                    const slimSkuDatabase = response.data;
                    dispatch(setSlimSkuDatabase(slimSkuDatabase));
                    return slimSkuDatabase;
                }
            } else {
                return [];
            }
        } catch (error) {
            return error.response;
        }
    };
}

function getSkuDatabase(id) {
    return async (dispatch, getState) => {
        const {
            user: { currentCompany }
        } = getState();

        try {
            const skuResponse = await axiosInstance.get(`${currentCompany}pricing/sku_database/${id}/`);
            const SKUDatabase = skuResponse.data;
            dispatch(setSKUDatabase(SKUDatabase));
            return SKUDatabase;
        } catch (error) {
            return error.response;
        }
    };
}

function submitSKUDatabase(data) {
    return async (dispatch, getState) => {
        const {
            user: { currentCompany }
        } = getState();

        const { skuData, quantityConversions, associatedSKU } = data;
        try {
            let responseId;
            let skuDatabase;
            if (skuData.id) {
                const response = await axiosInstance.put(`${currentCompany}pricing/sku_database/${skuData.id}/`, skuData);
                skuDatabase = response.data;
                responseId = skuData.id;
            } else {
                const response = await axiosInstance.post(`${currentCompany}pricing/sku_database/`, skuData);
                skuDatabase = response.data;
                responseId = skuDatabase.id;
            }
            await Promise.all(quantityConversions.map(conversion => dispatch(submitQuantityConversionLink(responseId, conversion))));
            await Promise.all(associatedSKU.map(sku => dispatch(submitAssociatedSKU(responseId, sku))));
            await dispatch(getSlimSkuDatabase(true));
            return skuDatabase;
        } catch (error) {
            console.log(error.response);
        }
    };
}

function submitQuantityConversionLink(skuId, quantityConversion) {
    return async (_, getState) => {
        const {
            user: { currentCompany }
        } = getState();

        try {
            if (quantityConversion.id) {
                const response = await axiosInstance.put(`${currentCompany}pricing/quantity_conversion_link/${quantityConversion.id}/`, {
                    ...quantityConversion,
                    sku: skuId
                });
                return response.data;
            } else {
                const response = await axiosInstance.post(`${currentCompany}pricing/quantity_conversion_link/`, {
                    ...quantityConversion,
                    sku: skuId
                });
                return response.data;
            }
        } catch (error) {
            console.log(error.response);
        }
    };
}

function submitAssociatedSKU(skuId, associatedSKU) {
    return async (_, getState) => {
        const {
            user: { currentCompany }
        } = getState();

        try {
            const data = {
                ...associatedSKU,
                base: associatedSKU.base || skuId,
                derived: associatedSKU.derived || skuId
            };
            if (data.id) {
                const response = await axiosInstance.put(`${currentCompany}pricing/associated_sku/${data.id}/`, data);
                return response.data;
            } else {
                const response = await axiosInstance.post(`${currentCompany}pricing/associated_sku/`, data);
                return response.data;
            }
        } catch (error) {
            console.log(error.response);
        }
    };
}

function submitPricebook(data) {
    return async (dispatch, getState) => {
        const {
            user: { currentCompany }
        } = getState();
        const { pricebookData, priceRecord } = data;
        try {
            let pricebookId;
            let pricebookResponse;
            if (pricebookData.id) {
                const response = await axiosInstance.put(`${currentCompany}pricing/price_book/${pricebookData.id}/`, pricebookData);
                pricebookResponse = response.data;
                pricebookId = pricebookResponse.id;
            } else {
                const response = await axiosInstance.post(`${currentCompany}pricing/price_book/`, pricebookData);
                pricebookResponse = response.data;
                pricebookId = pricebookResponse.id;
            }
            if (priceRecord) {
                if (priceRecord.length === undefined) {
                    const missingSku = await dispatch(submitPricesFile(pricebookId, priceRecord));
                    if (missingSku.length > 0) {
                        return { ...pricebookResponse, missingSku };
                    }
                } else if (priceRecord.length > 0) {
                    await Promise.all(priceRecord.map(record => dispatch(submitPriceRecord(pricebookId, record))));
                }
            }
            return pricebookResponse;
        } catch (error) {
            console.log(error.response);
            return { errorMessage: error.response.data.detail };
        }
    };
}

function submitPriceRecord(pricebookId, priceRecord) {
    return async (_, getState) => {
        const {
            user: { currentCompany }
        } = getState();

        try {
            if (priceRecord.id) {
                const response = await axiosInstance.put(`${currentCompany}pricing/price_record/${priceRecord.id}/`, {
                    ...priceRecord,
                    price_book: pricebookId
                });
                return response.data;
            } else {
                const response = await axiosInstance.post(`${currentCompany}pricing/price_record/`, {
                    ...priceRecord,
                    price_book: pricebookId
                });
                return response.data;
            }
        } catch (error) {
            console.log(error.response);
        }
    };
}

function submitPricesFile(pricebookId, priceRecord) {
    return async (dispatch, getState) => {
        const {
            user: { currentCompany }
        } = getState();
        const fd = new FormData();
        fd.append("pricebook", priceRecord);
        const priceRecordFileUploadResponse = await axiosInstance.post(
            `${currentCompany}pricing/price_book/${pricebookId}/upload_price_records/`,
            fd
        );
        const missingSKU = priceRecordFileUploadResponse.data.missing_skus;
        return missingSKU;
    };
}

function getPriceCommons() {
    return async (dispatch, getState) => {
        const {
            user: { currentCompany },
            material: { priceCommons }
        } = getState();

        try {
            if (priceCommons) {
                return priceCommons;
            }
            const urls = ["association_types", "grouped_items", "pricing_attribute"];
            const commonsResponse = await Promise.all(urls.map(url => axiosInstance.get(`${currentCompany}pricing/${url}/`)));

            const commons = commonsResponse.reduce((total, res, i) => ({ ...total, [urls[i]]: res.data }), {});
            dispatch(setPriceCommons(commons));
            return commons;
        } catch (error) {
            return error.response;
        }
    };
}

function downloadMyGIBOrder(order) {
    return async (_, getState) => {
        const {
            user: { currentCompany }
        } = getState();

        try {
            const mygibOrderResponse = await axiosInstance.get(`${currentCompany}ordering/mygib_orders/${order}/download/`, { responseType: "blob" });

            downloadFile(mygibOrderResponse);
            return { message: "completed", id: order };
        } catch (error) {
            console.log(error);
        }
    };
}

function downloadMyGIBOrders(downloadType, signal = null) {
    return async (_, getState) => {
        const {
            user: { currentCompany }
        } = getState();

        try {
            const mygibOrderResponse = await axiosInstance.post(`${currentCompany}ordering/mygib_orders/download_orders/`, downloadType, {
                responseType: "blob",
                cancelToken: signal?.token
            });

            downloadFile(mygibOrderResponse);
            return { message: "completed", id: downloadType };
        } catch (error) {
            console.log(error);
        }
    };
}

// initial states

const initialState = { deliveryOptions: [], referenceTypes: [] };

// reducer

function reducer(state = initialState, action) {
    switch (action.type) {
        case SET_PO_LIST:
            return applyPOList(state, action);
        case SET_DELIVERY_OPTIONS:
            return applyDeliveryOptions(state, action);
        case SET_REFERENCE_TYPES:
            return applyReferenceTypes(state, action);
        case SET_PO_DETAILS:
            return applyPODetails(state, action);
        case ADD_PO_DETAIL:
            return applyAddPODetail(state, action);
        case SET_TRANSFERRED_PO:
            return applyTransferredPO(state, action);
        case REMOVE_TRANSFERRING_DATA:
            return {
                ...state,
                transferingData: undefined
            };
        case SET_PRICE_LIST:
            return applyPriceList(state, action);
        case SET_PRICE_DETAIL:
            return applyPriceDetail(state, action);
        case SET_PRICE_COMMONS:
            return applyPriceCommons(state, action);
        case SET_SELECTED_SKU_ID:
            return applySelectedSKUID(state, action);
        case SET_SKU_DATABASE:
            return applySKUDatabase(state, action);
        case SET_SLIM_SKU_DATABASE:
            return applySlimSkuDatabase(state, action);
        case SET_MYGIB_ORDER:
            return applyMygibOrder(state, action);
        default:
            return state;
    }
}

// reducer function

function applyPOList(state, action) {
    const { poList } = action;
    return {
        ...state,
        poList
    };
}

function applyDeliveryOptions(state, action) {
    const { deliveryOptions } = action;
    return {
        ...state,
        deliveryOptions
    };
}

function applyReferenceTypes(state, action) {
    const { referenceTypes } = action;
    return {
        ...state,
        referenceTypes
    };
}

function applyPODetails(state, action) {
    const { poDetails } = action;
    return {
        ...state,
        poDetails
    };
}

function applyAddPODetail(state, action) {
    const { poDetails } = state;
    const { poDetail } = action;
    return {
        ...state,
        poDetails:
            poDetails?.revisions.length > 0
                ? { ...poDetails, revisions: [poDetail, ...poDetails.revisions] }
                : { ...poDetails, revisions: [poDetail] }
    };
}

function applyTransferredPO(state, action) {
    const { transferingData } = action;
    return {
        ...state,
        transferingData
    };
}

function applyPriceList(state, action) {
    const { priceList } = action;
    return {
        ...state,
        priceList
    };
}

function applyPriceDetail(state, action) {
    const { priceDetailData } = action;
    return {
        ...state,
        priceDetailData
    };
}

function applyPriceCommons(state, action) {
    const { priceCommons } = action;
    return {
        ...state,
        priceCommons
    };
}

function applySelectedSKUID(state, action) {
    const { skuId } = action;
    return {
        ...state,
        skuId
    };
}

function applySKUDatabase(state, action) {
    const { skuDatabase } = action;
    return {
        ...state,
        skuDatabase
    };
}

function applySlimSkuDatabase(state, action) {
    const { slimSkuDatabase } = action;
    return {
        ...state,
        slimSkuDatabase
    };
}

function applyMygibOrder(state, action) {
    const { mygibOrder } = action;
    return {
        ...state,
        mygibOrdered: mygibOrder.length > 0
    };
}

// export

const actionCreators = {
    getPOList,
    getPODetailsByPONumber,
    submitPO,
    getDeliveryOptions,
    getReferenceTypes,
    setTransferredPO,
    removeTransferringData,
    downloadOrder,
    getPriceList,
    getPriceDetail,
    submitMyGibPO,
    getMyGIBOrder,
    getMyGIBOrderDetail,
    getMerchantPOStatus,
    requiredMerchantPO,
    deleteMinimumPO,
    getMerchantPOTemplate,
    uploadPOTemplate,
    getSkuDatabase,
    getPriceCommons,
    setSelectedSKUId,
    submitSKUDatabase,
    submitPricebook,
    submitPricesFile,
    submitPriceRecord,
    getSlimSkuDatabase,
    downloadMyGIBOrder,
    downloadMyGIBOrders,
    getUpcomingDelivery,
    getPriceLogList
};

export { actionCreators };

// reducer export
export default reducer;
