import React from "react";

const PendingButton = ({
    type,
    forwardRef,
    className,
    onClick,
    pendingLoading,
    name,
    children
}) => {
    return (
        <button type={type} name={name} ref={forwardRef} className={className} onClick={onClick}>
            {pendingLoading ? (
                <>
                    wait{" "}
                    <div className="spinner-border spinner-border-sm" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </>
            ) : (
                children
            )}
        </button>
    );
};

export default PendingButton;
