import { connect } from "react-redux";
import Container from "./container";

const mapStateToProps = (state, ownProps) => {
    const {
        user: { isMobile }
    } = state;
    return {
        isMobile
    };
};

export default connect(mapStateToProps)(Container);
