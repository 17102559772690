import React, { Fragment } from "react";
import Loading from "components/Loading";
import styles from "shared/details.scss";
import { MDBCard, MDBCardHeader, MDBBtn, MDBCardBody, MDBRow, MDBCol, MDBTable, MDBTableBody, MDBTableHead, MDBIcon } from "louis-mdbreact";
import ValidSelect from "components/ValidSelect";
import SubmitButton from "components/SubmitButton";
import RecipientsForm from "components/RecipientsForm";
import MultiFileList from "components/MultiFileList";
import ArrowBackButton from "components/ArrowBackButton";

const PreQADetail = props => {
    const {
        isLoading,
        jobLoading,
        onJobChange,
        title,
        type,
        baseData: { jobList, qaResponseOption },
        sendingData,
        onSubmit,
        jobDetailDiary,
        onValueChange,
        onFileChange,
        onAreaChange,
        isValidated,
        permissionLevel,
        pendingLoading,
        downloadImage,
        subInfo,
        setupValid,
        fileClickTrigger,
        returnToList,
        cancelRef,
        fileNoteChange,
        onFileRemove,
        togglePreviewPop,
        typeTitle
    } = props;
    let isDisabled = true;
    switch (type) {
        case "new":
            if (permissionLevel === 4 || permissionLevel === 3) {
                isDisabled = false;
            } else {
                isDisabled = true;
            }
            break;
        case "detail":
            if (permissionLevel === 4) {
                isDisabled = false;
            } else {
                isDisabled = true;
            }
            break;
        default:
            isDisabled = true;
            break;
    }

    const areaPhotos = sendingData.qa_element && sendingData.qa_element.find(elem => elem.name === "Add area photo(s)");
    return (
        <div className={styles.container}>
            <h2 className={styles.title}>
                {type !== "new" && <ArrowBackButton />}
                <span>{title}</span>
            </h2>
            {isLoading ? (
                <Loading loadingType="detail" />
            ) : (
                <form onSubmit={onSubmit} className={`needs-validation ${isValidated ? "was-validated" : ""}`} noValidate>
                    <MDBCard className={styles.card} style={{ zIndex: 2 }}>
                        <MDBCardHeader>
                            <span className="float-left">Job Details</span>
                        </MDBCardHeader>
                        <MDBCardBody>
                            <MDBRow>
                                <MDBCol sm="12" className="mb-2">
                                    <label htmlFor="jobSelection">
                                        Job{" "}
                                        {jobLoading && (
                                            <div className="spinner-border text-primary spinner-border-sm" role="status">
                                                <span className="sr-only">Loading...</span>
                                            </div>
                                        )}
                                    </label>
                                    <ValidSelect
                                        options={jobList}
                                        onChange={onJobChange}
                                        value={sendingData.job}
                                        name="jobSelection"
                                        placeholder="Select a job..."
                                        isValid={isValidated && sendingData.job}
                                        isDisabled={!isDisabled}
                                    />
                                </MDBCol>
                            </MDBRow>
                            {jobDetailDiary && (
                                <MDBRow className="mt-1 mb-3">
                                    <MDBCol>
                                        <MDBTable small>
                                            <MDBTableBody>
                                                <tr>
                                                    <th width="30%">Client</th>
                                                    <td width="70%">{jobDetailDiary.client_string || "-"}</td>
                                                </tr>
                                                <tr>
                                                    <th>Project Title</th>
                                                    <td>{jobDetailDiary.job_name || "-"}</td>
                                                </tr>
                                                <tr>
                                                    <th>QS</th>
                                                    <td>{jobDetailDiary.internal_qa_string || "-"}</td>
                                                </tr>
                                                <tr>
                                                    <th>Supervisor</th>
                                                    <td>{jobDetailDiary.internal_supervisor_string || "-"}</td>
                                                </tr>
                                            </MDBTableBody>
                                        </MDBTable>
                                    </MDBCol>
                                </MDBRow>
                            )}
                        </MDBCardBody>
                    </MDBCard>
                    <MDBCard className={styles.card}>
                        <MDBCardHeader>{typeTitle} Details</MDBCardHeader>
                        <MDBCardBody>
                            <MDBRow>
                                <MDBCol className="mb-2">
                                    <label htmlFor="area">Area</label>
                                    {areaPhotos && (
                                        <div className="float-right">
                                            <input
                                                type="file"
                                                className="d-none"
                                                multiple
                                                id={`file_${areaPhotos.report_element}`}
                                                name={areaPhotos.report_element}
                                                onChange={onFileChange}
                                                aria-describedby={`file_input_${areaPhotos.report_element}`}
                                            />
                                            <MDBBtn
                                                size="sm"
                                                color="primary"
                                                onClick={fileClickTrigger}
                                                name={areaPhotos.report_element}
                                                disabled={!areaPhotos.element_response || Number(areaPhotos.element_response) === 3 || isDisabled}
                                            >
                                                {areaPhotos.name}
                                            </MDBBtn>
                                        </div>
                                    )}
                                    <input
                                        type="text"
                                        className="form-control form-control-sm"
                                        name="area"
                                        id="area"
                                        onChange={onAreaChange}
                                        value={sendingData.area}
                                        required
                                        disabled={isDisabled}
                                    />
                                    <div className="invalid-feedback">Required</div>
                                </MDBCol>
                            </MDBRow>
                            {areaPhotos && (
                                <MDBRow>
                                    <MDBCol>
                                        {(areaPhotos.files && areaPhotos.files.length > 0) ||
                                        (areaPhotos.associated_files && areaPhotos.associated_files.length > 0) ? (
                                            <MDBTable small className={styles.detailResponsiveTable}>
                                                <MDBTableBody>
                                                    <MultiFileList
                                                        element={areaPhotos}
                                                        names={areaPhotos.report_element}
                                                        downloadImage={downloadImage}
                                                        togglePreviewPop={togglePreviewPop}
                                                        fileNoteChange={fileNoteChange}
                                                        onFileRemove={onFileRemove}
                                                    />
                                                </MDBTableBody>
                                            </MDBTable>
                                        ) : undefined}
                                    </MDBCol>
                                </MDBRow>
                            )}
                            <hr />
                            {isValidated && sendingData.qa_element.filter(elem => elem.element_response).length <= 1 ? (
                                <MDBRow className="mb-3 text-center">
                                    <MDBCol className="card-text red-text">At least one of QA elements has to be selected.</MDBCol>
                                </MDBRow>
                            ) : undefined}
                            <MDBTable small className={styles.detailResponsiveTable}>
                                <MDBTableHead>
                                    <tr className={styles.textCentre}>
                                        <th width="50%">Criteria</th>
                                        <th width="21%">Y/N</th>
                                        <th>Rectification Date</th>
                                        <th width="40px">File</th>
                                    </tr>
                                </MDBTableHead>
                                <MDBTableBody>
                                    {sendingData.qa_element &&
                                        sendingData.qa_element
                                            .filter(elem => elem.name !== "Add area photo(s)")
                                            .map(element => (
                                                <Fragment key={element.report_element}>
                                                    <tr className={styles.addItemContainer}>
                                                        <td data-th="Criteria">
                                                            <label>{element.name}</label>
                                                            <input
                                                                className="form-control form-control-sm"
                                                                name={`note_${element.report_element}`}
                                                                type="text"
                                                                value={element.element_comment}
                                                                onChange={onValueChange}
                                                                disabled={
                                                                    !element.element_response || Number(element.element_response) === 3 || isDisabled
                                                                }
                                                            />
                                                        </td>
                                                        <td data-th="Y/N">
                                                            <MDBRow>
                                                                {qaResponseOption.map(option => (
                                                                    <MDBCol key={option.id} size="3">
                                                                        <div className="custom-control custom-radio custom-control-inline">
                                                                            <input
                                                                                type="radio"
                                                                                className="custom-control-input"
                                                                                id={`options_${element.report_element}_${option.name}`}
                                                                                name={`options_${element.report_element}`}
                                                                                value={option.id}
                                                                                checked={Number(element.element_response) === Number(option.id)}
                                                                                onChange={onValueChange}
                                                                                disabled={isDisabled}
                                                                            />
                                                                            <label
                                                                                className="custom-control-label"
                                                                                htmlFor={`options_${element.report_element}_${option.name}`}
                                                                            >
                                                                                {option.name}
                                                                            </label>
                                                                        </div>
                                                                    </MDBCol>
                                                                ))}
                                                            </MDBRow>
                                                        </td>
                                                        <td data-th="Rectification">
                                                            <input
                                                                className="form-control form-control-sm"
                                                                name={`date_${element.report_element}`}
                                                                type="date"
                                                                value={element.element_rectification_date}
                                                                onChange={onValueChange}
                                                                disabled={
                                                                    !element.element_response || Number(element.element_response) === 3 || isDisabled
                                                                }
                                                            />
                                                        </td>
                                                        <td data-th="File">
                                                            <input
                                                                type="file"
                                                                className="d-none"
                                                                multiple
                                                                id={`file_${element.report_element}`}
                                                                name={element.report_element}
                                                                onChange={onFileChange}
                                                                aria-describedby={`file_input_${element.report_element}`}
                                                            />
                                                            <MDBBtn
                                                                className={styles.buttonSize}
                                                                color="primary"
                                                                onClick={fileClickTrigger}
                                                                name={element.report_element}
                                                                disabled={
                                                                    !element.element_response || Number(element.element_response) === 3 || isDisabled
                                                                }
                                                            >
                                                                <MDBIcon icon="arrow-up" />
                                                            </MDBBtn>
                                                        </td>
                                                    </tr>
                                                    {(element.files && element.files.length > 0) ||
                                                    (element.associated_files && element.associated_files.length > 0) ? (
                                                        <MultiFileList
                                                            element={element}
                                                            names={element.report_element}
                                                            downloadImage={downloadImage}
                                                            togglePreviewPop={togglePreviewPop}
                                                            fileNoteChange={fileNoteChange}
                                                            onFileRemove={onFileRemove}
                                                        />
                                                    ) : undefined}
                                                </Fragment>
                                            ))}
                                </MDBTableBody>
                            </MDBTable>
                        </MDBCardBody>
                    </MDBCard>
                    {type === "new" && jobDetailDiary ? (
                        <RecipientsForm jobInfo={jobDetailDiary.id} subInfo={subInfo} setupValid={setupValid} />
                    ) : undefined}
                    <MDBRow>
                        <MDBCol>
                            <div className={`${styles.card} float-right`}>
                                <button type="button" ref={cancelRef} className="btn btn-blue-grey" onClick={returnToList}>
                                    {type === "new" ? "Cancel" : "List"}
                                </button>{" "}
                                {!isDisabled && (
                                    <SubmitButton
                                        type={type}
                                        pendingLoading={pendingLoading}
                                        //emailAddress={isValid && mailList.length > 0}
                                    />
                                )}
                            </div>
                        </MDBCol>
                    </MDBRow>
                </form>
            )}
        </div>
    );
};

export default PreQADetail;
