import React, { Component } from "react";
import JobValidModal from "./presenter";

class Container extends Component {
    componentWillUnmount = () => {
        document.body.style = undefined;
    };

    changeToCategory = () => {};

    render = () => {
        const { modal, toggle, message, buttons, title } = this.props;
        return (
            <JobValidModal
                modal={modal}
                toggle={toggle}
                message={message}
                buttons={buttons}
                title={title}
            />
        );
    };
}

export default Container;
