import React, { forwardRef, useEffect, useState, memo, useContext, useMemo, useCallback } from "react";
import { axiosInstance } from "shared/axiosInst";
import { Divider, List, ListItemButton, Stack, Typography, Box, Chip } from "@mui/material";
import { Virtuoso } from "react-virtuoso";
import { ModalContext } from "../TaskContext";
import { formatDateToTime, timeDiff } from "shared/Utils";
import Decisions from "./Decisions";
import LunchDiningIcon from "@mui/icons-material/LunchDining";
import dayjs from "shared/dayjs";
import NoLunchArea from "./NoLunchArea";

const FORTY_FIVE_MINUTES = 2700000;
const TWENTY_MINUTES = 1200000;
const MILLISECOND_FOR_THREE_MIN = 180000;

const TodayTimeEntry = ({ entryId, workerId, startingTime, setIsDone, isDone }) => {
    const [timeEntries, setTimeEntries] = useState([]);
    const [noLunchTime, setNoLunchTime] = useState(false);
    const [lunchableEntry, setLunchableEntry] = useState();
    const { setModalData } = useContext(ModalContext);

    useEffect(() => {
        const getTodayTimeEntries = async () => {
            const response = await axiosInstance.get(
                `fire-akl/maintenance/time_tracking/slim_everything/?next_revision__isnull=true&worker__entity=${workerId}&starting_timestamp__date=${startingTime}`
            );
            const data = response.data;
            setTimeEntries(data.sort((a, b) => Number(new Date(a.starting_timestamp)) - Number(new Date(b.starting_timestamp))));
            const filteredData = data.filter(
                item => Number(new Date(item.ending_timestamp)) - Number(new Date(item.starting_timestamp)) > MILLISECOND_FOR_THREE_MIN
            );
            const isLunchTime = filteredData.map((current, index, arr) => {
                const lunchItem = current.mjs_id === 6;
                if (lunchItem) return true;
                const nextItem = arr[index + 1];
                const hasGap =
                    nextItem && nextItem.starting_timestamp && current.ending_timestamp
                        ? Number(new Date(nextItem.starting_timestamp)) - Number(new Date(current.ending_timestamp)) >= TWENTY_MINUTES
                        : false;

                return hasGap;
            });
            const lastJob = filteredData[filteredData.length - 1];
            const today = dayjs(lastJob.ending_timestamp).format("YYYY-MM-DD");
            const onlyMorningWork = dayjs(lastJob.ending_timestamp).isBefore(`${today}T14:00`);
            const noLunchTime = onlyMorningWork ? false : !isLunchTime.some(bool => bool);
            if (noLunchTime) {
                setLunchableEntry(
                    filteredData.filter(
                        item =>
                            (dayjs(item.starting_timestamp).isAfter(`${today}T12:30`) &&
                                dayjs(item.ending_timestamp).diff(item.starting_timestamp, "m") > 30) ||
                            dayjs(`${today}T13:00`).isBetween(item.starting_timestamp, item.ending_timestamp)
                    )[0]
                );
            }
            setNoLunchTime(noLunchTime);
        };
        getTodayTimeEntries();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isDone]);

    const onDetailClick = useCallback(
        id => {
            axiosInstance.get(`fire-akl/maintenance/time_tracking/${id}/`).then(value => {
                setModalData(value.data);
                setIsDone(false);
            });
        },
        [setModalData, setIsDone]
    );

    const TimeEntryComponent = useMemo(
        () => ({
            List: forwardRef(({ children, style }, listRef) => (
                <List style={style} ref={listRef} dense component="div">
                    {children}
                </List>
            )),
            Item: ({ children, ...props }) => {
                const nextItem = timeEntries[Number(props["data-index"]) + 1];
                const itHasGap =
                    nextItem && nextItem.starting_timestamp && props.item.ending_timestamp
                        ? Number(new Date(nextItem.starting_timestamp)) - Number(new Date(props.item.ending_timestamp)) >= MILLISECOND_FOR_THREE_MIN
                        : false;
                const diff = dayjs(props.item.ending_timestamp).diff(props.item.starting_timestamp);
                const isLongLunch = props.item.mjs_id === 6 && diff > FORTY_FIVE_MINUTES;
                return (
                    <>
                        <ListItemButton
                            component="div"
                            {...props}
                            sx={{
                                background: props.item.id === entryId ? "#d8eed9" : isLongLunch ? "#ffe082" : "",
                                flexDirection: "column",
                                alignItems: "flex-start",
                                position: "relative"
                            }}
                            onClick={() => {
                                onDetailClick(props.item.id);
                            }}
                        >
                            {children}
                        </ListItemButton>
                        {itHasGap ? (
                            <Box
                                component="div"
                                textAlign="center"
                                sx={{
                                    width: "100%",
                                    background: "rgba(0, 0, 0, 0.12)",
                                    borderTop: "1px solid rgba(0, 0, 0, 0.2)",
                                    borderBottom: "1px solid rgba(0, 0, 0, 0.2)",
                                    margin: "0"
                                }}
                            >
                                <Typography variant="caption">GAP</Typography>
                            </Box>
                        ) : (
                            <Divider />
                        )}
                    </>
                );
            }
        }),
        [entryId, onDetailClick, timeEntries]
    );
    return (
        <>
            {noLunchTime && <NoLunchArea lunchableEntry={lunchableEntry} />}
            <Virtuoso
                style={{ height: `calc(100vh - ${noLunchTime ? "188" : "150"}px)`, border: "1px solid rgba(0, 0, 0, 0.12)", borderRadius: "3px" }}
                data={timeEntries}
                components={TimeEntryComponent}
                itemContent={(index, timeEntry) => {
                    const diff = dayjs(timeEntry.ending_timestamp).diff(timeEntry.starting_timestamp);
                    const isLongLunch = timeEntry.mjs_id === 6 && diff > FORTY_FIVE_MINUTES;
                    return (
                        <>
                            <div style={{ position: "absolute", top: "3px", right: "3px" }}>
                                <Decisions decisions={timeEntry.decisions} />
                            </div>
                            <Stack
                                direction="row"
                                spacing={1}
                                alignItems="center"
                                sx={{ textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden", width: "calc(100% - 30px)" }}
                            >
                                <Chip label="JOB" size="small" sx={{ bgcolor: "#4285f4", color: "white" }} />
                                <Box>
                                    <Typography component="div" variant="caption">
                                        {timeEntry.job_id ? `${timeEntry.job_id} (${timeEntry.job_name})` : "-"}
                                    </Typography>
                                    {timeEntry.end_job_id && timeEntry.job_id !== timeEntry.end_job_id ? (
                                        <Typography component="div" variant="caption">
                                            {timeEntry.end_job_id} ({timeEntry.end_job_name})
                                        </Typography>
                                    ) : (
                                        ""
                                    )}
                                </Box>
                            </Stack>
                            <Divider flexItem sx={{ marginY: "0.3rem", borderColor: "rgba(0, 0, 0, 0.05)" }} />
                            <Stack
                                direction="row"
                                spacing={1}
                                alignItems="center"
                                sx={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", width: "100%" }}
                            >
                                <Chip label="MJS" size="small" sx={{ bgcolor: "#5e49de", color: "white" }} />
                                <Typography component="div" variant="caption">
                                    {timeEntry.mjs_id ? `${timeEntry.mjs_id} (${timeEntry.mjs_note})` : "-"}{" "}
                                    {timeEntry?.mjs_id === 6 && <LunchDiningIcon color={isLongLunch ? "error" : "success"} fontSize="small" />}
                                </Typography>
                            </Stack>
                            <Divider flexItem sx={{ marginY: "0.3rem", borderColor: "rgba(0, 0, 0, 0.05)" }} />
                            <Stack
                                direction="row"
                                spacing={1}
                                alignItems="center"
                                sx={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", width: "100%" }}
                            >
                                <Chip label="TIME" size="small" />
                                <Typography component="div" variant="caption">
                                    {timeEntry.starting_timestamp
                                        ? `${formatDateToTime(timeEntry.starting_timestamp)} ~ ${formatDateToTime(
                                              timeEntry.ending_timestamp
                                          )} (${timeDiff(timeEntry.starting_timestamp, timeEntry.ending_timestamp)})`
                                        : "-"}
                                </Typography>
                            </Stack>
                        </>
                    );
                }}
            />
        </>
    );
};

export default memo(TodayTimeEntry);
