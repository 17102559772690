import React, { useState, useEffect } from "react";
import styles from "shared/listStyles.scss";
import reportStyles from "./styles.scss";
import { useDispatch } from "react-redux";
import { MDBDataTable, MDBBtn, MDBIcon, MDBBtnGroup } from "louis-mdbreact";
import { actionCreators as stockActions } from "redux/modules/stock";
import Loading from "components/Loading";
import dayjs from "shared/dayjs";
import { Link } from "react-router-dom";
import { axiosInstance } from "shared/axiosInst";
import { Helmet } from "react-helmet";

let signal = axiosInstance.CancelToken.source();

const ReportList = () => {
    const dispatch = useDispatch();
    const [reportList, setReportList] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [searchText, setSearchText] = useState(sessionStorage.getItem("jobSearch") || "");
    const [activePage] = useState(sessionStorage.getItem("jobActivePage") ? Number(sessionStorage.getItem("jobActivePage")) - 1 : 0);

    useEffect(() => {
        const getDatas = async () => {
            const list = await dispatch(stockActions.getReportList());
            setReportList(list.map(li => ({ ...li, pending: false })));
            setIsLoading(false);
        };
        getDatas();
        return () => {
            signal.cancel("canceled by user");
            signal = axiosInstance.CancelToken.source();
        };
    }, [dispatch]);
    const onSearch = searchText => {
        sessionStorage.setItem("jobSearch", searchText);
        setSearchText(searchText);
    };

    const onPageChange = pageInfo => {
        sessionStorage.setItem("jobActivePage", pageInfo.activePage);
    };

    const downloadStockReport = event => {
        const {
            currentTarget: { value }
        } = event;
        dispatch(stockActions.downloadReport(value, signal));
    };

    const data = {
        columns: [
            { label: "ID", field: "id", sort: "asc" },
            { label: "Generated Date", field: "generated_date", sort: "asc" },
            { label: "Created by", field: "created_by", sort: "asc" },
            { label: "Report Up to date", field: "report_up_to", sort: "asc" },
            { label: "Record Count", field: "record_count", sort: "asc" },
            { label: "Action", field: "action", sort: "asc" }
        ],
        rows: reportList.map(list => ({
            id: list.id,
            report_up_to: <span searchvalue={Number(new Date(list.report_up_to))}>{dayjs(list.report_up_to).format("DD/MM/YYYY")}</span>,
            record_count: list.priced_movement_records,
            created_by: list.created_by_string,
            generated_date: <span searchvalue={Number(new Date(list.created_at))}>{dayjs(list.created_at).format("DD/MM/YYYY")}</span>,
            search: `${dayjs(list.report_up_to).format("DD/MM/YYYY")} ${dayjs(list.created_at).format("DD/MM/YYYY")} ${
                list.priced_movement_records.length
            }`,
            action: (
                <MDBBtnGroup>
                    <Link to={`/stock/reports/${list.id}`}>
                        <MDBBtn color="primary" className={`${styles.buttonSize} ${styles.noRound}`}>
                            <MDBIcon icon="info-circle" />
                        </MDBBtn>
                    </Link>
                    <MDBBtn color="success" value={list.id} onClick={downloadStockReport} className={`${styles.buttonSize} ${styles.noRound}`}>
                        {list.pending ? (
                            <div className="spinner-border spinner-border-sm" role="status">
                                <span className="sr-only">Loading...</span>
                            </div>
                        ) : (
                            <MDBIcon icon="file-download" />
                        )}
                    </MDBBtn>
                </MDBBtnGroup>
            )
        }))
    };

    return (
        <div className={styles.container}>
            <Helmet>
                <title>Stock | Reports</title>
            </Helmet>
            <div className={styles.titleWrap}>
                <h2 className={`${styles.taskTitle}`}>Report List</h2>
            </div>
            {isLoading ? (
                <Loading loadingType="list" />
            ) : (
                <MDBDataTable
                    entriesOptions={[10, 20, 50, 100, 500]}
                    entries={20}
                    responsive
                    borderless
                    striped
                    responsiveSm
                    pagesAmount={5}
                    data={data}
                    sortRows={["report_up_to", "generated_date"]}
                    searchText={searchText}
                    initialPage={activePage}
                    onSearch={onSearch}
                    onPageChange={onPageChange}
                    className={`${styles.dataTable} ${reportStyles.dataTable} mt-3`}
                />
            )}
        </div>
    );
};

export default ReportList;
