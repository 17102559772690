import React from "react";
import { NavLink } from "react-router-dom";
import styles from "./styles.scss";
import { MDBIcon, MDBBtn } from "louis-mdbreact";
import SwitchCompany from "components/SwitchCompany";
import { useSelector } from "react-redux";

const MainNavigation = props => {
    const {
        user: { menuList }
    } = useSelector(state => state);
    // const isDashboard = pathname.search("dashboard") > 0;
    return (
        <div className={`${styles.container} ${props.isShowApps && styles.show}`}>
            <div className={`${styles.closeBtn}`}>
                <button onClick={props.toggleMainNav}>
                    <span></span>
                    <span></span>
                </button>
            </div>
            <p className={styles.navTitle}>Apps</p>
            <ul>
                {menuList?.map(list =>
                    list.visibility ? (
                        <li key={list.name} className={styles.listContainer}>
                            <NavLink
                                className={styles.link}
                                onClick={() => {
                                    //props.toggleNavbar();
                                    props.toggleMainNav({ isFromMain: true });
                                }}
                                activeClassName={styles.active}
                                to={list.link}
                            >
                                <div className={`${styles.circle} ${styles[list.style]}`}>
                                    <MDBIcon icon={list.icon} />
                                </div>{" "}
                                <span>{list.name}</span>
                            </NavLink>
                        </li>
                    ) : undefined
                )}
            </ul>
            <SwitchCompany isShow={true} />
            <div className={styles.logoutContainer}>
                <MDBBtn size="sm" color="deep-purple" onClick={props.logout}>
                    logout
                </MDBBtn>
            </div>
        </div>
    );
};

export default MainNavigation;
