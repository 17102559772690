import React from "react";
import Badge from "@mui/material/Badge";
import { withStyles } from "@mui/styles";

const styles = theme => ({
    customBadge: props => ({
        ...props.customcolor,
        zIndex: "0",
        top: props.isnormal ? "10px" : "-5px",
        right: props.isnormal ? "-25px" : "0"
    })
});

const customBadge = props => {
    const { classes, children } = props;
    return (
        <Badge {...props} classes={{ badge: classes.customBadge }}>
            {children}
        </Badge>
    );
};

export default withStyles(styles)(customBadge);
