import React from "react";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import styles from "./styles.scss";

const FileUploadBackdrop = ({ pendingLoading, progress = null, comment = null }) => {
    return (
        <Backdrop className={styles.fileUploadBackdrop} open={pendingLoading}>
            <div>
                <CircularProgress color="inherit" value={progress} variant={progress !== null ? "determinate" : "indeterminate"} />
            </div>
            <Typography variant="body1">
                {comment || (
                    <>
                        PLEASE WAIT UNTIL
                        <br />
                        THIS FORM UPLOADED COMPLETELY
                    </>
                )}
            </Typography>
        </Backdrop>
    );
};

export default FileUploadBackdrop;
