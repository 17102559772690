import React from "react";
import { TableContainer, Table, TableHead, TableCell, TableBody, TableRow } from "@mui/material";
import { formatter } from "shared/Utils";

const TotalValueTable = ({ totalValues }) => {
    return (
        <TableContainer
            sx={{
                marginTop: "10px",
                display: { xs: "inherit", sm: "inherit", md: "flex" },
                justifyContent: { xs: "inherit", sm: "inherit", md: "flex-end" }
            }}
        >
            <Table size="small" sx={{ maxWidth: "400px" }}>
                <TableHead>
                    <TableRow>
                        <TableCell></TableCell>
                        <TableCell align="right">Total value</TableCell>
                        <TableCell align="right">Probable</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {totalValues.map(value => (
                        <TableRow key={value.company}>
                            <TableCell variant="head">{value.company}</TableCell>
                            <TableCell align="right">{formatter.format(value.totalValue)}</TableCell>
                            <TableCell align="right">{value.isWon ? "-" : formatter.format(value.probalbleValue)}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default TotalValueTable;
