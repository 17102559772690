import { styled } from "@mui/material/styles";
import Badge from "@mui/material/Badge";
import Box from "@mui/material/Box";
import CircleIcon from "@mui/icons-material/Circle";
import ContrastIcon from "@mui/icons-material/Contrast";
import theme from "../LeaveItem/ColorCode";

const Label = styled("label")(({ theme }) => ({
    ...theme.typography.button,
    color: "#f47920",
    display: "block",
    fontSize: "0.8rem"
}));

const StyledBadge = styled(Badge)(({ theme }) => ({
    "& .MuiBadge-badge": {
        transform: "translate(100%, -50%)",
        right: "-4px",
        top: 10,
        padding: "0 6px",
        height: "20px"
    }
}));

const PendingBadge = styled(Badge)(({ theme }) => ({
    "& .MuiBadge-badge": {
        transform: "translate(100%, -50%)",
        top: 5,
        right: 7,
        border: "1px solid white",
        width: "10px",
        height: "10px",
        borderRadius: "5px"
    }
}));

const PublicHolidayDot = styled(Box)(({ isincalendar }) => ({
    marginRight: isincalendar ? "" : "0.3rem",
    borderRadius: "50%",
    width: isincalendar ? 8 : 12,
    height: isincalendar ? 8 : 12,
    backgroundColor: theme.palette["Public Holiday"].main,
    border: `1px solid ${theme.palette["Public Holiday"].contrastText}`
}));
const PublicHolidayText = styled(Box)(() => ({
    color: theme.palette["Public Holiday"].contrastText
}));

const LeaveIcon = ({ leaveProportion, ...rest }) => {
    return leaveProportion === "1.00" ? <CircleIcon {...rest} /> : <ContrastIcon {...rest} />;
};

const PublicHolidayInfo = () => {
    return (
        <Box
            flex={1}
            sx={{
                marginTop: "5px",
                display: "flex",
                flexWrap: "wrap",
                alignItems: "center",
                justifyContent: "flex-end",
                width: "100%",
                fontSize: "0.8rem"
            }}
        >
            <PublicHolidayDot component="span" />
            <PublicHolidayText component="span">Public Holiday</PublicHolidayText>
        </Box>
    );
};

const PublicHolidayDotInCalendar = () => {
    return (
        <Box
            sx={{
                marginTop: "2px",
                display: "flex",
                flexWrap: "wrap",
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
                fontSize: "0.8rem"
            }}
        >
            <PublicHolidayDot component="span" isincalendar="true" />
        </Box>
    );
};

export { Label, StyledBadge, PendingBadge, LeaveIcon, PublicHolidayInfo, PublicHolidayDotInCalendar, PublicHolidayDot };
