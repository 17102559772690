import React from "react";
import {
    MDBCard,
    MDBCardBody,
    MDBCardHeader,
    MDBTable,
    MDBTableBody,
    MDBTableHead,
    MDBRow,
    MDBCol,
    MDBBtn
} from "louis-mdbreact";
import styles from "shared/details.scss";

const SiteInspection = ({
    isLoading,
    type,
    form_content,
    onResponseChange,
    onCommentChange,
    isDisabled,
    onDateChange,
    pageType,
    onYesToAllClick
}) => {
    const formResponseOptions = JSON.parse(localStorage.getItem("qaResponseOption"));
    return (
        <>
            <MDBCard className={styles.card}>
                <MDBCardHeader>
                    {type.name}{" "}
                    {isLoading ? (
                        <div
                            className="spinner-border spinner-border-sm text-primary"
                            role="status"
                        >
                            <span className="sr-only">Loading...</span>
                        </div>
                    ) : undefined}
                    {pageType === "new" && (
                        <div className="float-right">
                            <MDBBtn
                                color="primary"
                                size="sm"
                                onClick={onYesToAllClick}
                                value={type.name}
                            >
                                Yes to all
                            </MDBBtn>
                        </div>
                    )}
                </MDBCardHeader>
                <MDBCardBody>
                    {form_content && (
                        <MDBTable small className={styles.detailResponsiveTable}>
                            <MDBTableHead>
                                <tr className={styles.textCentre}>
                                    <th>Question</th>
                                    <th width="21%">Y/N</th>
                                    <th width="50%">Comment</th>
                                </tr>
                            </MDBTableHead>
                            <MDBTableBody>
                                {form_content.map(element => (
                                    <tr className={styles.addItemContainer} key={element.element}>
                                        <td data-th="Question">{element.form_element_string}</td>
                                        <td data-th="Y/N">
                                            <MDBRow>
                                                {element.limit_responses_to.map(response => (
                                                    <MDBCol key={response} size="3">
                                                        <div className="custom-control custom-radio custom-control-inline">
                                                            <input
                                                                type="radio"
                                                                className="custom-control-input"
                                                                checked={
                                                                    response ===
                                                                    Number(element.response)
                                                                }
                                                                name={element.element}
                                                                value={response}
                                                                id={`option_${element.element}_${response}`}
                                                                onChange={onResponseChange}
                                                                disabled={isDisabled}
                                                                required
                                                            />
                                                            <label
                                                                htmlFor={`option_${element.element}_${response}`}
                                                                className="custom-control-label"
                                                            >
                                                                {
                                                                    formResponseOptions.find(
                                                                        option =>
                                                                            option.id === response
                                                                    ).name
                                                                }
                                                            </label>
                                                        </div>
                                                    </MDBCol>
                                                ))}
                                            </MDBRow>
                                        </td>
                                        <td data-th="Comment">
                                            {element.include_relevant_date &&
                                            Number(element.response) === 1 ? (
                                                <input
                                                    className="form-control form-control-sm mb-1"
                                                    type="date"
                                                    name={element.element}
                                                    value={element.relevant_date}
                                                    onChange={onDateChange}
                                                    disabled={isDisabled}
                                                />
                                            ) : undefined}
                                            {element.include_free_response && (
                                                <input
                                                    className="form-control form-control-sm"
                                                    type="text"
                                                    name={element.element}
                                                    value={element.comment}
                                                    onChange={onCommentChange}
                                                    disabled={
                                                        isDisabled ||
                                                        !(
                                                            Number(element.response) === 1 ||
                                                            Number(element.response) === 2
                                                        )
                                                    }
                                                />
                                            )}
                                        </td>
                                    </tr>
                                ))}
                            </MDBTableBody>
                        </MDBTable>
                    )}
                </MDBCardBody>
            </MDBCard>
        </>
    );
};

export default SiteInspection;
