import React, { Fragment, useContext, useState, useEffect } from "react";
import { DialogContent, Grid, Stack, Button, Collapse, Box } from "@mui/material";
import DialogContainer from "components/DialogContainer";
import { ModalContext } from "../TaskContext";
import { useForm, useFieldArray, FormProvider } from "react-hook-form";
import TimesheetDetail from "./TimecloudDetail";
import TimesheetHistory from "./TimesheetHistory";
import TimesheetEntry from "./TimecloudEntry";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useDispatch, useSelector } from "react-redux";
import { actionCreators as taskActions } from "redux/modules/tasks";
import ReasonItem from "./ReasonItem";
import dayjs from "shared/dayjs";
import TodayTimeEntry from "./TodayTimeEntry";
import { Label } from "components/HumanResources/CustomComponent";

const EditModal = ({ open, toggle, isLog = false }) => {
    const dispatch = useDispatch();
    const {
        user: { userId, manager }
    } = useSelector(state => state);
    const { modalData, setModalData } = useContext(ModalContext);

    const methods = useForm({
        defaultValues: {
            reason: 0,
            reason_free_text: "",
            entryList: []
        }
    });

    const { control, handleSubmit, setValue } = methods;
    const { fields, append, remove } = useFieldArray({ control, name: "entryList", keyName: "idx" });
    const [expanded, setExpanded] = useState(isLog);
    const [isDecided, setIsDecided] = useState(false);
    const [isDone, setIsDone] = useState(false);
    const matches = useMediaQuery(theme => theme.breakpoints.up("sm"));

    useEffect(() => {
        if (modalData.decisions.length > 0) {
            if (modalData.decisions.find(decision => decision.decision === 2)) {
                setIsDecided(true);
                return;
            } else {
                const decisions = modalData.decisions.find(
                    decision => decision.decision_maker === userId || decision.decision_maker === manager?.manager_id
                );
                if (decisions) {
                    setIsDecided(true);
                } else {
                    setIsDecided(false);
                }
            }
        } else {
            setIsDecided(false);
        }
    }, [modalData.decisions, userId, manager.manager_id]);

    useEffect(() => {
        setValue("entryList", [
            {
                ...modalData,
                previous_revision: modalData.id,
                date: dayjs(modalData.starting_timestamp || modalData.ending_timestamp).format("YYYY-MM-DD"),
                reason: 0,
                reason_free_text: ""
            }
        ]);
    }, [modalData, setValue]);

    const modifyTimeEntry = data => {
        return dispatch(
            taskActions.modifyTimecloud(
                data.entryList.map(entry => ({
                    starting_timestamp: entry.starting_timestamp,
                    starting_job: entry.starting_job,
                    starting_mjs: entry.starting_mjs,
                    ending_timestamp: entry.ending_timestamp,
                    ending_job: entry.starting_mjs === 302 ? entry.ending_job : entry.starting_job,
                    ending_mjs: entry.starting_mjs === 302 ? entry.starting_mjs : entry.ending_mjs,
                    reason: data.reason === 0 || data.reason === "0" ? null : data.reason,
                    reason_free_text: data.reason_free_text,
                    checked: undefined,
                    date: undefined
                })),
                modalData.id
            )
        );
    };

    const onSubmit = async data => {
        // setIsDone(true);
        const finished = await modifyTimeEntry(data);
        if (finished === "finished") {
            setIsDone(true);
        }
    };

    const onSubmitClose = data => {
        modifyTimeEntry(data);
        setModalData(null);
    };

    return (
        <DialogContainer open={open} handleClose={toggle} maxWidth="lg" title={isLog ? "TimeEntry Details" : "Edit TimeEntry"}>
            <DialogContent sx={!matches ? { paddingBottom: "60px" } : {}}>
                <Grid container spacing={2}>
                    <Grid item md={9} sm={12} lg={9} xl={9} xs={12}>
                        <FormProvider {...methods}>
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <Grid container spacing={2}>
                                    <TimesheetDetail isDone={isDone} modalData={modalData} toggle={toggle} />
                                    {!isLog && !isDecided && !isDone && (
                                        <>
                                            {fields.map((entry, index) => (
                                                <Fragment key={entry.idx}>
                                                    <TimesheetEntry entry={entry} remove={remove} append={append} index={index} />
                                                    {index === 0 && (
                                                        <Grid item container direction="row-reverse">
                                                            <Button
                                                                size="small"
                                                                color="primary"
                                                                variant="outlined"
                                                                onClick={_ => {
                                                                    append({
                                                                        ...entry,
                                                                        id: undefined,
                                                                        previous_revision: entry.id || entry.previous_revision
                                                                    });
                                                                }}
                                                            >
                                                                new row
                                                            </Button>
                                                        </Grid>
                                                    )}
                                                </Fragment>
                                            ))}
                                            <ReasonItem />
                                        </>
                                    )}
                                    <Grid
                                        item
                                        container
                                        direction="row-reverse"
                                        sx={!matches ? { position: "fixed", bottom: "15px", right: "24px", zIndex: 5 } : {}}
                                    >
                                        <Stack direction="row" spacing={1}>
                                            {!isDone && (
                                                <Button
                                                    size="small"
                                                    onClick={() => {
                                                        setExpanded(!expanded);
                                                    }}
                                                    variant="contained"
                                                    color="secondary"
                                                >
                                                    {expanded ? "hide" : "view"} history
                                                </Button>
                                            )}
                                            {(isLog || isDone) && (
                                                <Button
                                                    onClick={() => {
                                                        setModalData(null);
                                                    }}
                                                    size="small"
                                                    variant="contained"
                                                    color="primary"
                                                >
                                                    Close
                                                </Button>
                                            )}
                                            {!isLog && !isDecided && !isDone && (
                                                <>
                                                    <Button type="submit" size="small" variant="contained" color="primary">
                                                        Edit
                                                    </Button>
                                                    <Button onClick={handleSubmit(onSubmitClose)} size="small" variant="contained" color="primary">
                                                        Edit & Close
                                                    </Button>
                                                </>
                                            )}
                                        </Stack>
                                    </Grid>
                                </Grid>
                            </form>
                        </FormProvider>
                        {!isDone ? (
                            <Collapse in={expanded} timeout={1}>
                                <Box paddingY={2}>
                                    <TimesheetHistory />
                                </Box>
                            </Collapse>
                        ) : (
                            ""
                        )}
                    </Grid>
                    <Grid item md={3} sm={12} lg={3} xl={3} xs={12}>
                        <Label>
                            {modalData.worker_name} ({dayjs(modalData.starting_timestamp).format("DD/MM/YYYY")})
                        </Label>
                        <TodayTimeEntry
                            isDone={isDone}
                            setIsDone={setIsDone}
                            entryId={modalData.id}
                            workerId={modalData.worker_id}
                            startingTime={dayjs(modalData.starting_timestamp).format("YYYY-MM-DD")}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
        </DialogContainer>
    );
};

export default EditModal;
