import React from "react";
import styles from "shared/details.scss";
import { MDBBtn, MDBIcon } from "louis-mdbreact";

const AddItem = props => (
    <tr className={styles.addItemContainer}>
        <td data-th="File">
            <div className="custom-file">
                {props.file.approved_job || props.file.request_job ? (
                    <input type="text" className="form-control" id={`file_name__${props.idx}`} value={props.file.original_file_name} readOnly />
                ) : (
                    <>
                        <input
                            type="file"
                            className="custom-file-input"
                            id={`input_${props.idx}`}
                            name="new_file"
                            ref={props.fileInput}
                            onChange={e => {
                                props.onFileChange(e, props.idx);
                            }}
                        />
                        <label className={`custom-file-label ${styles.fileNameLabel}`} htmlFor={`input_${props.idx}`} id={`input_${props.idx}-label`}>
                            {props.file.new_file.name || "Choose file"}
                        </label>
                    </>
                )}
            </div>
        </td>
        <td data-th="File Category">
            <select
                className="browser-default custom-select"
                id={`file_category__${props.idx}`}
                name="file_category"
                value={`${props.file.file_category}`}
                disabled={!props.isDisabled || props.file.originalId}
                onChange={e => {
                    props.onFileChange(e, props.idx);
                }}
            >
                {props.file_type.map(type => {
                    return (
                        <option key={type.id} value={type.id}>
                            {type.name}
                        </option>
                    );
                })}
            </select>
        </td>
        <td className={styles.textCentre} data-th="Action">
            {props.file.originalId ? (
                <MDBBtn color="purple" className={styles.buttonSize} size="sm" name="undo" onClick={e => props.onReplaceFile(e, props.idx)}>
                    <MDBIcon icon="undo" />
                </MDBBtn>
            ) : (
                <>
                    {props.file.approved_job || props.file.request_job ? (
                        <>
                            <MDBBtn
                                className={styles.buttonSize}
                                color={props.file.large_file ? "dark-green" : "success"}
                                size="sm"
                                name="download"
                                onClick={e => {
                                    props.fileDownload(e, props.idx, props.file.large_file);
                                }}
                            >
                                <MDBIcon icon="arrow-down" />
                            </MDBBtn>
                            {props.isDisabled && (
                                <MDBBtn
                                    className={styles.buttonSize}
                                    color="cyan"
                                    name="replacement"
                                    onClick={e => {
                                        props.onReplaceFile(e, props.idx);
                                    }}
                                >
                                    <MDBIcon icon="exchange-alt" />
                                </MDBBtn>
                            )}
                        </>
                    ) : (
                        <MDBBtn
                            className={styles.buttonSize}
                            color="danger"
                            size="sm"
                            name="remove"
                            onClick={e => {
                                props.removeItem(e, props.idx);
                            }}
                        >
                            <MDBIcon icon="trash-alt" />
                        </MDBBtn>
                    )}
                </>
            )}
        </td>
    </tr>
);

export default AddItem;
