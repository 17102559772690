import { connect } from "react-redux";
import Container from "./container";
import { actionCreators as operationActions } from "redux/modules/operations";
import { getQAAllListAddingSpinner } from "redux/reducers";

const mapStateToProps = (state, ownProps) => {
    const {
        jobs: { jobList },
        user: { companyPrefix, permissions, userId }
    } = state;

    return {
        jobList: jobList && jobList.filter(list => list.pre_qa_reports),
        QAAllList: getQAAllListAddingSpinner(state),
        userId,
        prefixText: companyPrefix,
        permissions
    };
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        getQAAllList: (isMine, isCurrent, normalView, isPre) => {
            dispatch(operationActions.getQAAllList(isMine, isCurrent, normalView, isPre));
        },
        downloadQAReport: (id, signal) => dispatch(operationActions.downloadQAReport(id, signal))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Container);
