import { connect } from "react-redux";
import Container from "./container";

import { actionCreators as jobActions } from "redux/modules/jobs";
import { actionCreators as operationActions } from "redux/modules/operations";

const mapStateToProps = state => {
    const {
        user: { companies, permissions, isSLT }
    } = state;
    return {
        companies,
        permissions,
        isSLT
    };
};

const mapDispatchToProps = dispatch => {
    return {
        generateBluesheet: (id, tempCompany) => dispatch(jobActions.generateBluesheet(id, tempCompany)),
        downloadPLReport: (info, tempCompany) => dispatch(jobActions.downloadPLReport(info, tempCompany)),
        downloadRegionalPLReport: (data, signal, tempCompany) => dispatch(jobActions.downloadRegionalPLReport(data, signal, tempCompany)),
        downloadOperationalForm: (ids, tempCompany) => dispatch(operationActions.downloadOperationalForm(ids, null, tempCompany))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Container);
