import React from "react";
import styles from "shared/headNavStyles.scss";

const DependingMenu = props => {
    return (
        <React.Fragment>
            <h2 className={styles.navTitle}>{props.title}</h2>
            <ul className={styles.navList}>{props.children}</ul>
        </React.Fragment>
    );
};

export default DependingMenu;
