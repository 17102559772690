import React, { memo, useState } from "react";
import { Card, CardContent, Typography, Chip, Fab } from "@mui/material";
import { makeStyles } from "@mui/styles";
import dayjs from "shared/dayjs";
import styles from "shared/details.scss";
import SpeedDial from "@mui/material/SpeedDial";
import SpeedDialIcon from "@mui/material/SpeedDialIcon";
import SpeedDialAction from "@mui/material/SpeedDialAction";

import EditIcon from "@mui/icons-material/Edit";
import ReplyIcon from "@mui/icons-material/Reply";
import Reply from "./Reply";
import NoteFileList from "./NoteFileList";
import CollapsedNote from "./CollapsedNote";

const useStyles = makeStyles(theme => ({
    dates: {
        fontSize: "0.8rem"
    },
    infoText: {
        paddingTop: "5px",
        fontSize: "0.8rem",
        color: "#457d06"
    },
    root: {
        transform: "translateZ(0px)",
        flexGrow: 1
    },
    speedDial: {
        zIndex: "1",
        position: "absolute",
        "&.MuiSpeedDial-directionUp, &.MuiSpeedDial-directionLeft": {
            bottom: theme.spacing(1),
            right: theme.spacing(2)
        }
    },
    fab: {
        margin: "8px !important"
    },
    replyFab: {
        zIndex: "1",
        position: "absolute",
        bottom: theme.spacing(2),
        right: theme.spacing(2)
    },
    markComplete: {
        background: "#a6c",
        color: "white",
        "&:hover": {
            background: "#a6c"
        }
    },
    edit: {
        background: "#4285f4",
        color: "white",
        "&:hover": {
            background: "#4285f4"
        }
    },
    reply: {
        background: "#f50057",
        color: "white",
        "&:hover": {
            background: "#f50057"
        }
    },
    cardContentRoot: {
        "&:last-child": {
            paddingBottom: "16px"
        }
    }
}));

const Note = ({ log, title, onEditClick, onReplyClick }) => {
    const classes = useStyles();
    const isFollowUp = title === "Follow Ups";
    const [open, setOpen] = useState(false);
    const handleClose = () => {
        setOpen(false);
    };

    const handleOpen = () => {
        setOpen(true);
    };
    const actions = [
        {
            icon: <ReplyIcon />,
            name: "Reply",
            className: "reply",
            func: () => {
                onReplyClick(log.id);
            }
        },
        {
            icon: <EditIcon />,
            name: "Edit",
            className: "edit",
            func: () => {
                onEditClick(log.id);
            }
        }
    ];
    const hasReply = log.reply_details?.length > 0;
    return isFollowUp ? (
        <CollapsedNote key={log.id} onReplyClick={onReplyClick} onEditClick={onEditClick} log={log} />
    ) : (
        <Card style={{ position: "relative" }} className="mb-2" variant="outlined">
            <CardContent>
                {log.follow_up?.completed_by ? (
                    <>
                        <div className="float-right">
                            <Chip label="Completed" variant="outlined" />
                        </div>
                        <Fab className={classes.replyFab} size="small" color="secondary" onClick={() => onReplyClick(log.id)}>
                            <ReplyIcon />
                        </Fab>
                    </>
                ) : (
                    <SpeedDial
                        ariaLabel="Note menus"
                        className={classes.speedDial}
                        icon={<SpeedDialIcon />}
                        onClose={handleClose}
                        onOpen={handleOpen}
                        open={open}
                        direction="left"
                        FabProps={{ size: "small" }}
                    >
                        {actions.map(action => (
                            <SpeedDialAction
                                key={action.name}
                                icon={action.icon}
                                className={`${classes.fab} ${classes[action.className]}`}
                                tooltipTitle={action.name}
                                onClick={action.func}
                                tooltipPlacement="bottom"
                            />
                        ))}
                    </SpeedDial>
                )}
                {log.follow_up && (
                    <Typography color={log.follow_up.completed_by ? "textSecondary" : "secondary"} component="div" className={classes.dates}>
                        {`Due Date ${dayjs(log.follow_up.due_date).format("Do MMM YYYY")}`}{" "}
                    </Typography>
                )}
                <Typography component="p" className={styles.preWrap}>
                    {log.note}
                </Typography>
                {log.follow_up && (
                    <>
                        <Typography component="div" className={classes.infoText}>
                            Assigned: {log.follow_up.assigned_to_name} <br />
                            Notified: {log.follow_up.also_notify_list.map(noti => noti.name_string).join(", ")}
                        </Typography>
                    </>
                )}
                <Typography color="textSecondary" component="div" className={classes.dates}>
                    {`Created at ${dayjs(log.created_at).format("Do MMM YYYY")} by ${log.created_by_name}`}
                    <br />
                    {`Last modified at ${dayjs(log.last_modified_at).format("Do MMM YYYY")} by ${log.last_modified_by_name}`}
                </Typography>
                <NoteFileList files={log?.files_list} />
            </CardContent>
            {hasReply && <Reply replies={log.reply_details} />}
        </Card>
    );
};

export default memo(Note);
