import React from "react";
import Loading from "components/Loading";
import styles from "shared/listStyles.scss";
import { Link } from "react-router-dom";
import { MDBBtn, MDBDataTable, MDBIcon } from "louis-mdbreact";
import CreateNewButton from "components/CreateNewButton";
import BranchSelect from "components/BranchSelect";

const JobsRequested = props => {
    const data = {
        columns: [
            { label: "No.", field: "job_no", sort: "asc" },
            { label: "Client", field: "client", sort: "asc" },
            { label: "Site", field: "site", sort: "asc" },
            { label: "Supervisor", field: "supervisor", sort: "asc" },
            { label: "QS", field: "qs", sort: "asc" },
            { label: "Details", field: "detail", sort: "asc" }
        ],
        rows:
            !props.loading &&
            props.jobList.map(job => ({
                job_no: `${job.id}${job.short_region ? `-${job.short_region}` : ""}`,
                client: job.resource_string || "-",
                site: job.job_name || "-",
                supervisor: job.internal_supervisor_string || "-",
                qs: job.internal_qs_string || "-",
                detail: (
                    <Link to={`/jobs/requested/${job.id}`}>
                        <MDBBtn className={styles.buttonSize} name={job.id} size="sm" color="primary">
                            <MDBIcon icon="info-circle" />
                        </MDBBtn>
                    </Link>
                )
            }))
    };
    // const titles = [
    //     "Request No.",
    //     "Client",
    //     "Site",
    //     "Supervisor",
    //     "QS",
    //     "Details"
    // ];
    return (
        <div className={styles.container}>
            <div className={styles.titleWrap}>
                <h2 className={`${styles.taskTitle}`}>{props.titleName}</h2>
                <CreateNewButton />
                <div className={styles.switchWrap}>
                    <div className={`${styles.listSwitch} custom-control custom-switch`}>
                        <input
                            type="checkbox"
                            className="custom-control-input"
                            id="myjob"
                            name="isMine"
                            checked={props.isMine}
                            onChange={props.changeList}
                            readOnly
                        />
                        <label className="custom-control-label" htmlFor="myjob">
                            MY JOBS
                        </label>
                    </div>
                </div>
            </div>
            {props.currentRegion === "Auckland" && <BranchSelect />}
            {props.loading ? (
                <Loading loadingType="list" />
            ) : (
                <MDBDataTable
                    entries={20}
                    responsive
                    borderless
                    striped
                    responsiveSm
                    pagesAmount={5}
                    data={data}
                    className={`${styles.jobsDataTable} ${styles.dataTable} mt-3`}
                />
                // <JobList
                //     titles={titles}
                //     jobList={props.jobList}
                //     listType="jobs/requested"
                // />
                // <TaskList
                //     titles={titles}
                //     listType="tasks"
                //     permissions={props.permissions}
                //     jobList={props.jobList}
                // />
            )}
        </div>
    );
};

export default JobsRequested;
