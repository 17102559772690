import loadImage from "blueimp-load-image";
import _ from "lodash";
import dayjs from "shared/dayjs";
import Linings from "images/linings_icon.svg";
import Fire from "images/fire_icon.svg";
import ActiveFire from "images/activefire_icon.svg";

/**
 * parsing token to return expiry date
 * @param {String} token
 * @returns {Date | 0}
 */
const parseJwt = token => {
    if (token) {
        const base64Url = token.split(".")[1];
        const base64 = base64Url?.replace(/-/g, "+")?.replace(/_/g, "/");
        if (base64) {
            const jsonPayload = decodeURIComponent(
                atob(base64)
                    .split("")
                    .map(c => {
                        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
                    })
                    .join("")
            );

            const expiry = JSON.parse(jsonPayload).exp * 1000;
            return new Date(expiry);
        } else {
            return false;
        }
    } else {
        return false;
    }
};

/**
 * To get last pay period
 * @param {}
 * @returns {Object} return Object of start and end date of period
 */
const getLastPayPeriod = () => {
    const dayLastweek = dayjs().subtract(14, "days");
    const minDate = dayjs("2019-11-26");
    const start = dayLastweek.subtract(Math.floor((dayLastweek - minDate) / (1000 * 3600 * 24)) % 14, "days").format("YYYY-MM-DD");
    const end = dayjs(start).add(13, "days").format("YYYY-MM-DD");
    return { start, end };
};

/**
 * To get current pay period
 * @param {}
 * @returns {Object} return Object of start and end date of period
 */
const getCurrentPayPeriod = () => {
    const dayLastweek = dayjs();
    const minDate = dayjs("2019-11-26");
    const start = dayLastweek.subtract(Math.floor((dayLastweek - minDate) / (1000 * 3600 * 24)) % 14, "days").format("YYYY-MM-DD");
    const end = dayjs(start).add(13, "days").format("YYYY-MM-DD");
    return { start, end };
};

const formatter = new Intl.NumberFormat("en-NZ", {
    style: "currency",
    currency: "NZD",
    minimumFractionDigits: 2
});

/**
 * get numbers to return string with commas
 * @param {number} num
 * @returns {string} string with commas
 */
const numberWithCommas = num => {
    return Number(num).toLocaleString("en-US");
};

/**
 * get two params to create image file
 * @param {String} dataurl
 * @param {String} filename
 * @returns {File} file blob
 */
const dataURLtoFile = (dataurl, filename, transparent = null) => {
    var arr = dataurl.split(","),
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);

    while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: transparent ? "image/png" : "image/jpeg" });
};

/**
 * change blob to base64
 * @param {Blob} blob
 * @returns {String} base64
 */
function blobToBase64(blob) {
    return new Promise((resolve, _) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.readAsDataURL(blob);
    });
}

/**
 * Return formdata from file object
 * @param {{new_file:File, file_category?:String, originalId?:Number, orientation?:String}} file
 * @param {String} type
 * @param {Number} id
 * @returns {FormData}
 */
const getFileData = (file, type, id) => {
    const fd = new FormData();
    const fileName = file.new_file.name;
    const extention = fileName.split(".").slice(-1)[0];
    const limitLength = 100;
    if (fileName.length > limitLength) {
        const newFileName = fileName.slice(0, limitLength - 5);
        file.new_file = new File([file.new_file], `${newFileName}.${extention}`, {
            type: file.new_file.type
        });
    }
    fd.append("new_file", file.new_file);
    if (file.file_category) {
        fd.append("file_category", file.file_category);
    }
    if (file.originalId) {
        fd.append("original", file.originalId);
    }
    if (file.orientation) {
        fd.append("orientation", file.orientation);
    }
    if (type) {
        fd.append(type, id);
    }
    return fd;
};

/**
 * loading image to apply image head and get its base64data and thumnail etc.
 * @param {String} src
 * @param {String} fileName
 * @param {String} type
 * @param {{aborted:Boolean}} signal
 * @param {String} imageHead
 * @returns {Promise<{file: File, imageHead: String, image: base64data, thumb: String, orientation: "landscape" | "portrait", size: { width:Number, height:Number }}>}
 */
const imageLoad = (src, fileName, type, signal = { aborted: false }, imageHead = null) => {
    return new Promise((resolve, reject) => {
        try {
            loadImage(src, {
                meta: true,
                maxWidth: 1600,
                maxHeight: 1600,
                orientation: true,
                canvas: true,
                imageSmoothingQuality: "high"
            })
                .then(data => {
                    return new Promise((resolve, reject) => {
                        if (signal.aborted) {
                            reject("Cancel images loaded");
                            return;
                        }
                        data.image.toBlob(blob => {
                            data.blob = blob;
                            resolve(data);
                        }, type);
                    });
                })
                .then(data => {
                    return new Promise(resolve => {
                        if (signal.aborted) {
                            reject("Cancel images loaded");
                            return;
                        }
                        if (data.imageHead && data.exif) {
                            loadImage.writeExifData(data.imageHead, data, "Orientation", 1);
                            loadImage.replaceHead(data.blob, data.imageHead, newBlob => {
                                const reader = new FileReader();
                                reader.readAsDataURL(newBlob);
                                reader.onloadend = () => {
                                    const canvas = getThumb(data);
                                    const base64data = reader.result;
                                    const width = data.image.width;
                                    const height = data.image.height;
                                    resolve({
                                        file: new File([newBlob], fileName, { type: "image/jpeg" }),
                                        imageHead: data.imageHead,
                                        image: base64data,
                                        thumb: canvas.toDataURL("image/jpeg", 0.5),
                                        orientation: width >= height ? "landscape" : "portrait",
                                        size: {
                                            width,
                                            height
                                        }
                                    });
                                };
                            });
                        } else if (imageHead) {
                            loadImage.replaceHead(data.blob, imageHead, newBlob => {
                                const reader = new FileReader();
                                reader.readAsDataURL(newBlob);
                                reader.onloadend = () => {
                                    const canvas = getThumb(data);
                                    const base64data = reader.result;
                                    const width = data.image.width;
                                    const height = data.image.height;
                                    resolve({
                                        file: new File([newBlob], fileName, { type: "image/jpeg" }),
                                        imageHead,
                                        image: base64data,
                                        thumb: canvas.toDataURL("image/jpeg", 0.5),
                                        orientation: width >= height ? "landscape" : "portrait",
                                        size: {
                                            width,
                                            height
                                        }
                                    });
                                };
                            });
                        } else {
                            const reader = new FileReader();
                            reader.readAsDataURL(data.blob);
                            reader.onloadend = () => {
                                const canvas = getThumb(data);
                                const base64data = reader.result;
                                const width = data.image.width;
                                const height = data.image.height;
                                resolve({
                                    file: new File([data.blob], fileName, { type: "image/jpeg" }),
                                    image: base64data,
                                    thumb: canvas.toDataURL("image/jpeg", 0.5),
                                    orientation: width >= height ? "landscape" : "portrait",
                                    size: {
                                        width,
                                        height
                                    }
                                });
                            };
                        }
                    });
                })
                .then(data => {
                    resolve(data);
                })
                .catch(error => console.log(error));
        } catch (error) {
            console.log("Cancel images loaded");
        }
    });
};

/**
 * get image data to return thumnail of that image
 * @param {{blob: Blob, image: HTMLCanvasElement, originalHeight: number, originalWidth: number}} data
 * @returns {HTMLCanvasElement}
 */
const getThumb = data => {
    const canvas = document.createElement("canvas"),
        ctx = canvas.getContext("2d"),
        maxWidth = 600,
        maxHeight = 600;
    let width = data.image.width;
    let height = data.image.height;
    if (width > height) {
        if (width > maxWidth) {
            height = Math.round((height * maxWidth) / width);
            width = maxWidth;
        }
    } else {
        if (height > maxHeight) {
            width = Math.round((width * maxHeight) / height);
            height = maxHeight;
        }
    }
    canvas.width = width;
    canvas.height = height;

    // draw source image into the off-screen canvas:
    ctx.drawImage(data.image, 0, 0, width, height);

    return canvas;
};

/**
 * check if it is mobile browser.
 * @returns {boolean} whether it's mobile browser
 */
const detectMobile = () => {
    const toMatch = [/Android/i, /webOS/i, /iPhone/i, /iPad/i, /iPod/i, /BlackBerry/i, /Windows Phone/i];

    return toMatch.some(toMatchItem => {
        return navigator.userAgent.match(toMatchItem);
    });
};

/**
 * check if it's running on react native app
 * @returns {boolean} if it's react native app
 */
const detectApp = () => {
    return window.navigator.userAgent.includes("gl-portal");
};

/**
 *
 * @returns {boolean}
 */
const getConfirmation = () => {
    const lastCheck = window.confirm("Are you sure you want to submit this form?");
    if (lastCheck) {
        return true;
    } else {
        return false;
    }
};

/**
 *
 * @returns {boolean}
 */
const loadStoredDataConfirmation = () => {
    const check = window.confirm("Do you want to load data stored not submitted?\n(if you click 'Cancel', stored data will be deleted.)");
    if (check) {
        return true;
    } else {
        return false;
    }
};

/**
 * put grade and return background color and font color
 * @param {number} grade
 * @returns {{background:string, color:"black" | "white"}}
 */
const getColor = grade => {
    const COLOR = [
        { start: 0, middle: 45, end: 96 },
        { start: 100, middle: 100, end: 42 },
        { start: 50, middle: 70, end: 48 }
    ];
    const color = COLOR.map(col => {
        if (grade > 2) {
            return Math.round(((col.end - col.middle) / 2) * (grade - 2) + col.middle);
        } else {
            return Math.round(((col.middle - col.start) / 2) * grade + col.start);
        }
    });
    return {
        background: `hsl(${color.reduce((a, b, i) => {
            return i > 0 ? a + `,${b}%` : a + b;
        }, "")})`,
        color: color[2] > 57 ? "black" : "white"
    };
};

/**
 * to make common objects for CRM from response data
 * @param {string[]} urls
 * @param {any[]} dataList
 * @returns {{[name:string]:any[]}}
 */
const getCommonObj = (urls, dataList) => {
    const names = urls.map(url => {
        const splitName = url.split("/");
        return splitName[splitName.length - 2];
    });

    return names.reduce((obj, items, index) => {
        return {
            ...obj,
            [items]: dataList[index].data
        };
    }, {});
};

/**
 * have no idea what I was tyring to do with it, will find out later.
 * @param {any[]} origin
 * @param {string?} types
 * @param {any[]} names
 * @param {string?} detail_name
 * @returns {any[]}
 */
const getNameStrings = (origin, types, names, detail_name) => {
    const obj = types
        ? origin.map(a => ({
              ...a,
              [types]: a[types].map(c => ({ id: c, name: names.find(b => b.id === c).name }))
          }))
        : detail_name
        ? origin.map(a => ({
              ...a,
              name: names?.find(b => a[detail_name] === b.id)?.name,
              linked_service: detail_name === "skill" ? names.find(b => a[detail_name] === b.id).linked_service : undefined
          }))
        : origin.map(a => ({ id: a, name: names.find(b => a === b.id).name }));

    return obj.map(ob => _.pickBy(ob, v => v !== undefined));
};

/**
 * get unicode to return string
 * @param {string} text
 * @returns {string}
 */
const unicodeToChar = text =>
    text.replace(/\\u[\dA-F]{4}/gi, function (match) {
        return String.fromCharCode(parseInt(match.replace(/\\u/g, ""), 16));
    });

/**
 * to download file from response
 * @param {AxiosResponse} response
 */

const downloadFile = response => {
    const disposition = response.request.getResponseHeader("Content-Disposition");
    const contentType = response.request.getResponseHeader("Content-Type");
    const convertBase64 = async () => {
        const result = await blobToBase64(response.data);
        window.ReactNativeWebView.postMessage(JSON.stringify({ type: "downloadFile", result, fileName: disposition.split('"')[1] }));
    };

    const url = window.URL.createObjectURL(new Blob([response.data], { type: contentType }));
    const isApp = detectApp();
    if (isApp) {
        convertBase64();
    } else {
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", disposition.split('"')[1]);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }
};

/**
 * Capitalised first letter
 * @param {string} string
 * @returns {string}
 */
function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

/**
 * To get an array flatten
 * @param {Array} into new array
 * @param {Array} node node to down the tree to get all node in an flat array
 * @param {String} fieldName children of the node
 * @returns {Array} an array flatten
 */
const flatten = (into, node, fieldName) => {
    const flat = (into, node) => {
        if (node == null) return into;
        if (Array.isArray(node)) return node.reduce(flat, into);
        into.push(node);
        return flat(into, node[fieldName]);
    };
    return flat(into, node);
};

/**
 * to get a string removed double gap between words
 * @param {String} str string wanted to get rid of double space
 * @returns {String} removed string
 */
const removeDoubleGap = str => {
    return str.replace(/\s{2,}/g, " ").trim();
};

function timeDiff(start, end) {
    if (!start) return "-";
    if (!end) return "-";
    const startD = dayjs(start);
    const endD = dayjs(end);
    const differ = endD.diff(startD);
    const duration = dayjs.duration(differ);
    const str = duration.seconds() > 30 ? duration.add(1, "minute") : duration;
    return str
        .format("H [hrs] m [mins]")
        .replace(/\b0 hrs \b/, "")
        .replace(/\b 0 mins\b/, "");
}

function getTime(dateStr) {
    return dateStr ? new Date(dateStr).toLocaleTimeString("en-NZ") : "";
}

function formatDateToTime(dateStr) {
    let date;
    if (dateStr) {
        date = new Date(dateStr);
    } else {
        return "";
    }
    let hours = date.getHours();
    let minutes = date.getMinutes();
    let ampm = "";

    // Add leading zeros if necessary
    if (hours > 12) {
        hours = hours - 12;
        ampm = "pm";
    } else if (hours === 12 && minutes >= 0) {
        ampm = "pm";
    } else {
        ampm = "am";
    }
    if (hours < 10) {
        hours = "0" + hours;
    }
    if (minutes < 10) {
        minutes = "0" + minutes;
    }

    return `${hours}:${minutes} ${ampm}`;
}

const stringToColor = string => {
    let hash = 0;
    let i;
    for (i = 0; i < string.length; i += 1) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = "#";

    for (i = 0; i < 3; i += 1) {
        const value = (hash >> (i * 8)) & 0xff;
        color += `00${value.toString(16)}`.substr(-2);
    }
    return color;
};

const sendMobilePostRequest = data => {
    const isApp = detectApp();
    if (isApp) {
        window.ReactNativeWebView.postMessage(data);
    }
};

const stringAvatar = name => {
    const colorCode = stringToColor(name);
    const c = colorCode.substring(1); // strip #
    const rgb = parseInt(c, 16); // convert rrggbb to decimal
    const r = (rgb >> 16) & 0xff; // extract red
    const g = (rgb >> 8) & 0xff; // extract green
    const b = (rgb >> 0) & 0xff; // extract blue

    const luma = 0.2126 * r + 0.7152 * g + 0.0722 * b; // per ITU-R BT.709
    return {
        style: {
            backgroundColor: colorCode,
            color: luma > 150 ? "black" : "white",
            cursor: "pointer"
        },
        children: `${name.split(" ")[0][0]}${name.split(" ")[1]?.[0]}`
    };
};

const getDistance = distance => {
    const currentDistance = Math.round(Number(distance) * 100) / 100;
    if (currentDistance < 1) {
        return `${currentDistance * 1000} m`;
    } else {
        return `${currentDistance} km`;
    }
};

function isSuperUser(userId) {
    return userId === 1 || userId === 2;
}

function getRosteringServices(service) {
    return service.filter(ser => ser.id === 1 || ser.id === 2 || ser.id === 3 || ser.id === 4);
}

function getDateRange(isRostering = true) {
    const today = dayjs();
    let startDate, endDate;
    const ADDED_DAYS = isRostering ? 6 : 1;
    if (isRostering) {
        if (today.day() === 1) {
            const thresholdTime = today.set("hour", 12).set("minute", 0).set("second", 0).set("millisecond", 0);

            if (today.isBefore(thresholdTime)) {
                startDate = today.startOf("week").subtract(5, "days");
                endDate = startDate.add(ADDED_DAYS, "days");
            } else {
                startDate = today.startOf("week").add(2, "days");
                endDate = startDate.add(ADDED_DAYS, "days");
            }
        } else if (today.day() === 0) {
            startDate = today.startOf("week").subtract(5, "days");
            endDate = startDate.add(ADDED_DAYS, "days");
        } else {
            startDate = today.startOf("week").add(2, "days");
            endDate = startDate.add(ADDED_DAYS, "days");
        }
    } else {
        startDate = today;
        endDate = startDate.add(ADDED_DAYS, "days");
    }

    return { startDate, endDate };
}

function getDateList(dateRange) {
    const dateList = [];
    const today = new Date();
    const startOfWeek = dayjs(today).startOf("week");
    const endOfWeek = dayjs(today).endOf("week");
    const diff = dayjs(dateRange.endDate).diff(dayjs(dateRange.startDate), "days");
    for (let i = 0; i <= diff; i++) {
        const date = dayjs(dateRange.startDate).add(i, "days");
        dateList.push({
            str: `${date.format("DD/MM/YYYY")}`,
            calendarDay: date.calendar(null, {
                sameDay: "dddd ([Today])",
                nextDay: "dddd ([Tomorrow])",
                nextWeek: `dddd`,
                lastDay: "dddd ([Yesterday])",
                lastWeek: `dddd`,
                sameElse: `dddd`
            }),
            currentWeek: date.isBetween(startOfWeek, endOfWeek, null, "[]"),
            isPast: date.isBefore(dayjs(today)),
            isWeekend: date.day() === 0 || date.day() === 6,
            d: date.format("YYYY-MM-DD")
        });
    }
    return dateList;
}

function uuidv4() {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
        const r = (Math.random() * 16) | 0,
            v = c === "x" ? r : (r && 0x3) || 0x8;
        return v.toString(16);
    });
}

const getNextWorkingDay = (day, holidays) => {
    const currentNextDay = dayjs(day);
    const notWorkingDay =
        currentNextDay.day() === 0 ||
        currentNextDay.day() === 6 ||
        !!holidays.find(holiday => holiday.holiday_date === currentNextDay.format("YYYY-MM-DD"));
    if (!notWorkingDay) {
        return currentNextDay.format("YYYY-MM-DD");
    }
    return getNextWorkingDay(currentNextDay.add(1, "day"), holidays);
};

function approvalWorkerSortingList(a, b) {
    if (a.worker_name === b.worker_name) {
        const aTime = a.starting_timestamp || a.ending_timestamp;
        const bTime = b.starting_timestamp || b.ending_timestamp;
        return Number(new Date(aTime)) > Number(new Date(bTime)) ? 1 : -1;
    }
    return a.worker_name > b.worker_name ? 1 : -1;
}

function approvalJobSortingList(a, b) {
    if (a.job_id === b.job_id) {
        if (a.worker_name === b.worker_name) {
            return Number(new Date(a.starting_timestamp)) > Number(new Date(b.starting_timestamp)) ? 1 : -1;
        }
        return a.worker_name > b.worker_name ? 1 : -1;
    }
    return b.job_id - a.job_id;
}

function refineTimeEntryToSlim(timeEntry) {
    return {
        ...timeEntry,
        job_id: timeEntry.starting_job,
        job_name: timeEntry.starting_job_name,
        job_region: timeEntry.starting_job_region,
        end_job_id: timeEntry.ending_job,
        end_job_name: timeEntry.ending_job_name,
        end_job_region: timeEntry.ending_job_region,
        mjs_id: timeEntry.starting_mjs,
        mjs_note: timeEntry.starting_mjs_note
    };
}

function getCompanyColour(companyStr) {
    switch (companyStr) {
        case "GL":
            return { color: "#7fcb2b", icon: Linings };
        case "GF":
            return { color: "#EE691B", icon: Fire };
        case "GAF":
            return { color: "#ED1C24", icon: ActiveFire };
        default:
            return "grey";
    }
}

function copyToClipboard(str) {
    const el = document.createElement("textarea");
    el.value = str;
    el.setAttribute("readonly", "");
    el.style.position = "absolute";
    el.style.left = "-9999px";
    document.body.appendChild(el);
    el.select();
    navigator.clipboard.writeText(el.value);
    document.body.removeChild(el);
}

function filterToString(filter, exceptionKeys = []) {
    let filterString = "";
    const exceptions = exceptionKeys.join(", ");
    for (const key in filter) {
        if (!exceptions.includes(key) && filter[key] !== null && filter[key] !== undefined && filter[key] !== "") {
            filterString += `${filterString === "" ? "?" : "&"}${key}=${filter[key]}`;
        }
    }
    return filterString;
}

// const env = process.env.NODE_ENV;

const limitRequest = 5;
const THRESHOLD_DATE = "2025-02-22";

export {
    parseJwt,
    formatter,
    getFileData,
    detectMobile,
    detectApp,
    numberWithCommas,
    imageLoad,
    getThumb,
    dataURLtoFile,
    getConfirmation,
    loadStoredDataConfirmation,
    getColor,
    getCommonObj,
    getNameStrings,
    unicodeToChar,
    downloadFile,
    capitalizeFirstLetter,
    getLastPayPeriod,
    getCurrentPayPeriod,
    flatten,
    stringAvatar,
    removeDoubleGap,
    limitRequest,
    timeDiff,
    getTime,
    isSuperUser,
    formatDateToTime,
    getDistance,
    stringToColor,
    getRosteringServices,
    getDateRange,
    getDateList,
    uuidv4,
    getNextWorkingDay,
    approvalWorkerSortingList,
    approvalJobSortingList,
    refineTimeEntryToSlim,
    blobToBase64,
    sendMobilePostRequest,
    getCompanyColour,
    copyToClipboard,
    filterToString,
    THRESHOLD_DATE
};
