import React, { useEffect, useState } from "react";
import SendIcon from "@mui/icons-material/Send";
import dayjs from "shared/dayjs";
import { axiosInstance } from "shared/axiosInst";
import { LoadingButton } from "@mui/lab";

const TimecloudButton = ({ entitiesData }) => {
    const [ableToSend, setAbleToSend] = useState(false);
    const [sending, setSending] = useState(false);
    useEffect(() => {
        if (entitiesData) {
            const isCurrentEmployee = !!entitiesData.relationship_details.find(detail => {
                const current = !detail.end_date || (detail.end_date && dayjs().isBetween(detail.start_date, detail.end_date));
                return detail.name === "Employee" && !detail.pending && current;
            });
            // const workingWithServices = !!entitiesData.roles.find(role => role === 2 || role === 3 || role === 4 || role === 11);
            const isMaintenance = !!entitiesData.assignments.find(assignment => assignment === 2);
            setAbleToSend(isCurrentEmployee && isMaintenance);
        }
    }, [entitiesData]);
    const onSendClick = () => {
        try {
            setSending(true);
            axiosInstance.post(`crm/entity/${entitiesData.id}/send_timecloud_login/`).then(value => {
                if (value) {
                    setSending(false);
                }
            });
        } catch (error) {
            console.log(error.response);
        } finally {
            setSending(false);
        }
    };

    return ableToSend ? (
        <LoadingButton size="small" loading={sending} variant="outlined" color="warning" endIcon={<SendIcon />} onClick={onSendClick}>
            Send timecloud
        </LoadingButton>
    ) : (
        ""
    );
};

export default TimecloudButton;
