import React, { memo, useState } from "react";
import dayjs from "shared/dayjs";
import { MDBBtn, MDBIcon, MDBRow, MDBCol } from "louis-mdbreact";
import styles from "shared/details.scss";
import { formatter } from "shared/Utils";

const ReportTableRow = ({ priced }) => {
    const regions = JSON.parse(localStorage.getItem("regions"));
    const getRegionName = ({ name, id, isNormal = false }) => {
        if (id < 100) {
            return regions.find(region => Number(region.id) === Number(id)).region_name;
        } else {
            return isNormal ? name : name.split(" ")[0];
        }
    };
    const [isExpanded, setIsExpanded] = useState(false);
    const expandTable = () => {
        setIsExpanded(!isExpanded);
    };
    const {
        stock_movement_record: {
            created_at: stockCreatedAt,
            source_job,
            source_job_string,
            description,
            quantity,
            destination_job,
            destination_job_string,
            created_by_string: stockCreatedByString,
            pickup_by,
            directed_to,
            id,
            last_modified_at: stockModifiedAt,
            last_modified_by_string: stockModifiedBy
        },
        created_at,
        created_by_string,
        total_cost,
        last_modified_at,
        last_modified_by_string
    } = priced;
    const stockRowData = [
        { name: "ID", value: id },
        { name: "Created Date", value: dayjs(stockCreatedAt).format("DD/MM/YYYY") },
        {
            name: "From",
            value: getRegionName({ name: source_job_string, id: source_job, isNormal: true })
        },
        {
            name: "To",
            value: getRegionName({
                name: destination_job_string,
                id: destination_job,
                isNormal: true
            })
        },
        { name: "Description", value: description },
        { name: "Quantity", value: quantity },
        { name: "Logged By", value: stockCreatedByString },
        { name: "Pickup By", value: pickup_by || "-" },
        { name: "User of Consumable", value: directed_to || "-" },
        { name: "Modified Date", value: dayjs(stockModifiedAt).format("DD/MM/YYYY") },
        { name: "Modified By", value: stockModifiedBy }
    ];
    const priceRowData = [
        { name: "Created Data", value: dayjs(created_at).format("DD/MM/YYYY") },
        { name: "Created By", value: created_by_string },
        { name: "Total Cost", value: formatter.format(total_cost) },
        { name: "Modified Date", value: dayjs(last_modified_at).format("DD/MM/YYYY") },
        { name: "Modified By", value: last_modified_by_string }
    ];
    return (
        <>
            <tr className={styles.addItemContainer}>
                <td data-th="Jobs">
                    <span>
                        {getRegionName({
                            name: source_job_string,
                            id: source_job
                        })}
                    </span>
                    <MDBIcon className="mx-2" icon="arrow-circle-right" />
                    <span>
                        {getRegionName({
                            name: destination_job_string,
                            id: destination_job
                        })}
                    </span>
                </td>
                <td data-th="Description">{description}</td>
                <td data-th="Quantity">{quantity}</td>
                <td data-th="Price">{formatter.format(total_cost)}</td>
                <td data-th="Created Date">{dayjs(stockCreatedAt).format("DD/MM/YYYY")}</td>
                <td data-th="Details">
                    <MDBBtn className={styles.buttonSize} onClick={expandTable} size="sm" color="primary">
                        <MDBIcon icon="info-circle" />
                    </MDBBtn>
                </td>
            </tr>
            {isExpanded ? (
                <tr className={styles.expandedRowContainer}>
                    <td colSpan={7} className={styles.expandedRow}>
                        <h5>Stock Transfer</h5>
                        <MDBRow>
                            {stockRowData.map((stock, i) => (
                                <MDBCol key={i} sm="6" className={styles.expandedWrap}>
                                    <span className={`${styles.title} orange-text`}>{stock.name}</span>
                                    <span className={styles.content}>{stock.value}</span>
                                </MDBCol>
                            ))}
                        </MDBRow>
                        <h5 className={`mt-2`}>Priced</h5>
                        <MDBRow>
                            {priceRowData.map((price, i) => (
                                <MDBCol key={i} sm="4" className={styles.expandedWrap}>
                                    <span className={`${styles.title} orange-text`}>{price.name}</span>
                                    <span className={styles.content}>{price.value}</span>
                                </MDBCol>
                            ))}
                        </MDBRow>
                    </td>
                </tr>
            ) : undefined}
        </>
    );
};

export default memo(ReportTableRow);
