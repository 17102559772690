import { connect } from "react-redux";
import { withRouter } from "react-router";
import Container from "./container";
import { actionCreators as jobActions } from "redux/modules/jobs";
import { actionCreators as operationActions } from "redux/modules/operations";
import { actionCreators as userActions } from "redux/modules/user";

const mapStateToProps = (state, ownProps) => {
    const {
        jobs: { detailData, consentData },
        user: { permissions, isQS, isSupervisor, isAdmin, isMobile, isSLT, userId },
        operations: { QAList, PreQAList }
    } = state;
    return {
        detailData,
        consentData,
        permissions,
        isMobile,
        QAList,
        PreQAList,
        userId,
        roles: { isQS, isAdmin, isSupervisor, isSLT },
        priceType: JSON.parse(localStorage.getItem("priceType")),
        category: JSON.parse(localStorage.getItem("category")),
        service: JSON.parse(localStorage.getItem("service")),
        qsCheckList: JSON.parse(localStorage.getItem("qsCheck")),
        serviceSubRate: JSON.parse(localStorage.getItem("serviceSubRate")),
        product: JSON.parse(localStorage.getItem("product")),
        regions: JSON.parse(localStorage.getItem("regions"))
    };
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        getDetailJob: (jobId, listType) => {
            dispatch(jobActions.getDetailJob(jobId, listType));
        },
        generateBluesheet: jobId => dispatch(jobActions.generateBluesheet(jobId)),
        fileDownload: fileId => dispatch(jobActions.fileDownload(fileId)),
        getQAList: (jobId, isPre) => dispatch(operationActions.getQAList(jobId, isPre)),
        removeQAList: () => dispatch(operationActions.removeQAList()),
        downloadPLReport: info => dispatch(jobActions.downloadPLReport(info)),
        getUserCommons: hasToRefresh => dispatch(userActions.getUserCommons(hasToRefresh)),
        markComplete: info => dispatch(jobActions.markComplete(info)),
        setJobView: isJobView => dispatch(jobActions.setJobView(isJobView)),
        clearHiddenJob: jobId => dispatch(jobActions.clearHiddenJob(jobId)),
        setHiddenJob: jobId => dispatch(jobActions.setHiddenJob(jobId)),
        updateTimecloud: jobId => dispatch(jobActions.updateTimecloud(jobId)),
        getGlobalUsers: () => dispatch(userActions.getGlobalUsers()),
        getLogList: jobId => dispatch(jobActions.getLogList(`?job=${jobId}`)),
        getFollowupList: jobId => dispatch(jobActions.getFollowupList(`?note__job=${jobId}`))
    };
};

const mergeProps = (stateProps, dispatchProps) => {
    const { regions } = stateProps;
    const { getUserCommons } = dispatchProps;
    return {
        ...stateProps,
        ...dispatchProps,
        regions: regions || getUserCommons(true)
    };
};

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(withRouter(Container));
