import React, { memo } from "react";
import { NavLink, Link } from "react-router-dom";
import { MDBIcon } from "louis-mdbreact";
import styles from "shared/headNavStyles.scss";
import { useSelector } from "react-redux";

const HRDependPermission = props => {
    const { toggleNavbar } = props;
    const { permissions } = useSelector(state => state.user);
    return (
        <>
            <li>
                <NavLink exact activeClassName={styles.active} to={`/humanresources/leaverequest`} onClick={toggleNavbar}>
                    <MDBIcon icon="arrow-alt-circle-right" /> <span>Leave</span>
                </NavLink>
                <Link className={styles.new} to={`/humanresources/leaverequest/new`} onClick={toggleNavbar}>
                    <MDBIcon far icon="plus-square" />
                </Link>
            </li>
            <li>
                <NavLink exact activeClassName={styles.active} to={`/humanresources/leavecalendar`} onClick={toggleNavbar}>
                    <MDBIcon far icon="calendar" /> <span>Calendar</span>
                </NavLink>
            </li>
            {permissions.hr === 4 && (
                <li>
                    <NavLink exact activeClassName={styles.active} to={`/humanresources/leavereport`} onClick={toggleNavbar}>
                        <MDBIcon far icon="file-alt" /> <span>Report</span>
                    </NavLink>
                </li>
            )}
        </>
    );
};

export default memo(HRDependPermission);
