import React, { memo, useState } from "react";
import { axiosInstance } from "shared/axiosInst";
import { useSelector, useDispatch } from "react-redux";
import { actionCreators as jobActions } from "redux/modules/jobs";
import history from "shared/history";
import styles from "shared/details.scss";
import NoteFileList from "./NoteFileList";
import { CardContent, Typography, Button } from "@mui/material";
import { makeStyles } from "@mui/styles";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import DueDate from "./DueDate";
import EditIcon from "@mui/icons-material/Edit";
import ReplyIcon from "@mui/icons-material/Reply";
import Reply from "./Reply";
import SpeedDial from "@mui/material/SpeedDial";
import SpeedDialIcon from "@mui/material/SpeedDialIcon";
import SpeedDialAction from "@mui/material/SpeedDialAction";

const useStyles = makeStyles(theme => ({
    dates: {
        fontSize: "0.8rem"
    },
    infoText: {
        paddingTop: "5px",
        fontSize: "0.8rem",
        color: "#457d06"
    },
    root: {
        transform: "translateZ(0px)",
        flexGrow: 1
    },
    speedDial: {
        zIndex: "1",
        position: "absolute",
        "&.MuiSpeedDial-directionUp, &.MuiSpeedDial-directionLeft": {
            bottom: theme.spacing(1),
            right: theme.spacing(2)
        }
    },
    fab: {
        margin: "8px !important"
    },
    replyFab: {
        zIndex: "1",
        position: "absolute",
        bottom: theme.spacing(2),
        right: theme.spacing(2)
    },
    markComplete: {
        background: "#a6c !important",
        color: "white !important",
        "&:hover": {
            background: "#a6c !important"
        }
    },
    edit: {
        background: "#4285f4 !important",
        color: "white !important",
        "&:hover": {
            background: "#4285f4 !important"
        }
    },
    reply: {
        background: "#f50057 !important",
        color: "white !important",
        "&:hover": {
            background: "#f50057 !important"
        }
    },
    cardContentRoot: {
        "&:last-child": {
            paddingBottom: "16px !important"
        }
    }
}));

const buttonStyles = makeStyles({
    root: {
        color: "#a6c !important"
    }
});
const FollowupNote = ({ log, onEditClick, openClick, onReplyClick, type = null }) => {
    const dispatch = useDispatch();
    const classes = useStyles();
    const buttonClasses = buttonStyles();
    const {
        user: { currentCompany },
        jobs: { followupList, logList }
    } = useSelector(state => state);
    const [open, setOpen] = useState(false);
    const handleClose = () => {
        setOpen(false);
    };

    const handleOpen = () => {
        setOpen(true);
    };
    const completeFollowUp = () => {
        axiosInstance.post(`${currentCompany}job/job_note_follow_up/${log.id}/mark_complete/`).then(value => {
            if (value.data) {
                dispatch(jobActions.setFollowupList(followupList.filter(list => list.note !== log.id)));
                dispatch(jobActions.setLogList(logList.map(list => (list.id === log.id ? { ...list, follow_up: value.data } : list))));
            }
        });
    };
    const goJob = () => {
        dispatch(jobActions.setLogOpened(log.id));
        history.push(`/jobs/${log.job}/#followup`);
    };
    const actions = [
        {
            icon: <ReplyIcon />,
            name: "Reply",
            className: "reply",
            func: () => {
                onReplyClick(log.id);
            }
        },
        {
            icon: <EditIcon />,
            name: "Edit",
            className: "edit",
            func: () => {
                onEditClick(log.id);
            }
        },
        {
            icon: <DoneIcon />,
            name: "Mark Complete",
            className: "markComplete",
            func: completeFollowUp
        }
    ];
    return (
        <>
            <CardContent>
                {type === "dashboard" ? (
                    <div className="float-right">
                        <Button size="small" className={buttonClasses.root} onClick={goJob}>
                            Go To job
                        </Button>
                    </div>
                ) : (
                    <SpeedDial
                        ariaLabel="Note menus"
                        className={classes.speedDial}
                        icon={<SpeedDialIcon />}
                        onClose={handleClose}
                        onOpen={handleOpen}
                        open={open}
                        direction="left"
                        FabProps={{ size: "small" }}
                    >
                        {actions.map(action => (
                            <SpeedDialAction
                                key={action.name}
                                icon={action.icon}
                                className={`${classes.fab} ${classes[action.className]}`}
                                tooltipTitle={action.name}
                                onClick={action.func}
                                tooltipPlacement="bottom"
                            />
                        ))}
                    </SpeedDial>
                )}
                <div className="d-flex align-items-center">
                    <IconButton onClick={openClick} size="small">
                        <CloseIcon />
                    </IconButton>
                    <DueDate time={log.follow_up?.due_date} />
                </div>
                <Typography color="textSecondary" variant="subtitle1">
                    {`From ${log.created_by_name}`}
                </Typography>
                <Typography component="p" className={styles.preWrap}>
                    {log.note}
                </Typography>
                <Typography component="div" className={classes.infoText} gutterBottom>
                    Assigned: {log.follow_up?.assigned_to_name} <br />
                    Notified: {log.follow_up?.also_notify_list?.map(noti => noti.name_string).join(", ")}
                </Typography>
                {type === "dashboard" && (
                    <Typography variant="body2" color="textSecondary">
                        <strong>GF{log.job}</strong> - {log.follow_up?.job_name}
                    </Typography>
                )}

                <NoteFileList files={log?.files_list} />
            </CardContent>
            {log.reply_details.length > 0 && <Reply opened={true} count={log.reply_details.length} replies={log.reply_details} />}
        </>
    );
};

export default memo(FollowupNote);
