import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";

const RoleDot = styled(Box)(({ theme, color }) => {
    return {
        background: theme.palette[color].main,
        width: "10px",
        height: "10px",
        borderRadius: "5px"
    };
});

export default RoleDot;
