import React, { useState, useEffect, useContext, useCallback } from "react";
import { Box, CircularProgress, IconButton, Stack, Button, TextField } from "@mui/material";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import dayjs from "shared/dayjs";
import { useDispatch } from "react-redux";
import { getDateList, getDateRange } from "shared/Utils";

import PublishButton from "./PublishButton";
import { actionCreators as hrActions } from "redux/modules/humanResources";

import { axiosInstance } from "shared/axiosInst";
import { RosterCalendarContext, RosterContext } from "../TaskContext";
import VirtualTable from "./VirtualTable";
// import axios from "axios";

const RosterCalendar = () => {
    const dispatch = useDispatch();
    const { setAssignedList } = useContext(RosterCalendarContext);
    const { currentBranch, isInRostering } = useContext(RosterContext);
    const [dateRange, setDateRange] = useState(getDateRange(isInRostering));
    const [isLoading, setIsLoading] = useState(false);
    const [isToday, setIsToday] = useState(true);
    const [search, setSearch] = useState("");
    const [originalList, setOriginalList] = useState([]);
    const DAYS = getDateList(dateRange).length;

    const changeDate = event => {
        const {
            currentTarget: { name }
        } = event;

        let startDate;
        let endDate;
        if (name === "prev") {
            startDate = dayjs(dateRange.startDate).subtract(DAYS, "days");
            endDate = dayjs(dateRange.endDate).subtract(DAYS, "days");
        } else if (name === "next") {
            startDate = dayjs(dateRange.startDate).add(DAYS, "days");
            endDate = dayjs(dateRange.endDate).add(DAYS, "days");
        } else {
            startDate = getDateRange(isInRostering).startDate;
            endDate = getDateRange(isInRostering).endDate;
        }

        setDateRange({ startDate, endDate });
        const today = dayjs();
        const thresholdTime = today.set("hour", 12).set("minute", 0).set("second", 0).set("millisecond", 0);
        const thresholdDay = today.day() === 1 && today.isBefore(thresholdTime) ? today : today.add(1, "day");
        if (thresholdDay.isBetween(startDate, endDate, "day", "[]")) {
            setIsToday(true);
        } else {
            setIsToday(false);
        }
    };

    const fetchAssignments = useCallback(
        (start, end) => {
            // axios.get(`../jsons/assigned_roster.json`).then(value => {
            //     const list = value.data.filter(list => !list.removed);
            //     setAssignedList(list);
            //     setOriginalList(list);
            //     setIsLoading(false);
            // });
            axiosInstance
                .get(
                    `fire-akl/maintenance/assigned_roster/${
                        isInRostering ? "current/" : "my/"
                    }?assigned_date__gte=${start}&assigned_date__lte=${end}&assigned_labour__entityassignmentlink__regions__fire_areas__id=${currentBranch}`
                )
                .then(value => {
                    const list = value.data.filter(list => !list.removed);
                    setAssignedList(list);
                    setOriginalList(list);
                    setIsLoading(false);
                });
        },
        [setAssignedList, isInRostering, currentBranch]
    );

    useEffect(() => {
        setIsLoading(true);
        if (dateRange && currentBranch) {
            const { startDate, endDate } = dateRange;
            const formattedStartDate = dayjs(startDate).format("YYYY-MM-DD");
            const formattedEndDate = dayjs(endDate).format("YYYY-MM-DD");
            fetchAssignments(formattedStartDate, formattedEndDate);
            dispatch(hrActions.getHolidaysByPeriod({ currentBranch, formattedStartDate, formattedEndDate }));
        }
    }, [dateRange, dispatch, currentBranch, fetchAssignments]);

    useEffect(() => {
        setAssignedList(
            originalList.filter(list => {
                const listStr = Object.values(list).join(" ");
                return listStr.toLowerCase().includes(search.toLowerCase());
            })
        );
    }, [search, setAssignedList, originalList]);

    return (
        <>
            <Stack direction={{ md: "row", sm: "column" }} justifyContent="space-between" width="100%">
                <Box display="flex" flexWrap="nowrap" alignItems="center" flex={1}>
                    <IconButton disabled={isLoading} name="prev" color="success" onClick={changeDate}>
                        <ArrowBackIcon />
                    </IconButton>
                    <Box display="flex" flexWrap="nowrap" sx={{ fontSize: "24px" }}>
                        <span>{dayjs(dateRange.startDate).format("DD/MM")}</span>
                        <span> - </span>
                        <span>{dayjs(dateRange.endDate).format("DD/MM")}</span>
                    </Box>
                    <IconButton disabled={isLoading} name="next" color="success" onClick={changeDate}>
                        <ArrowForwardIcon />
                    </IconButton>
                    {isLoading && <CircularProgress size={30} />}
                    {!isLoading && !isToday ? (
                        <Button name="today" size="small" onClick={changeDate} color="success" variant="contained">
                            {isInRostering ? "Current Week" : "Today"}
                        </Button>
                    ) : undefined}
                </Box>
                {/* <PublicHolidayInfo /> */}
                <TextField
                    label="Job Search"
                    size="small"
                    sx={{ marginBottom: 1 }}
                    value={search}
                    onChange={event => {
                        setSearch(event.target.value);
                    }}
                />
            </Stack>
            <Box
                sx={{
                    maxHeight: "calc(100vh - 300px)",
                    height: "calc(100vh - 300px)",
                    minHeight: "300px",
                    overflow: "auto",
                    border: "1px solid #efefef",
                    width: "100%"
                }}
            >
                <VirtualTable dateRange={dateRange} />
            </Box>
            {isInRostering && (
                <Box width="100%" display="flex" justifyContent="flex-end" marginTop="20px">
                    <PublishButton
                        refresh={() => {
                            const { startDate, endDate } = dateRange;
                            const formattedStartDate = dayjs(startDate).format("YYYY-MM-DD");
                            const formattedEndDate = dayjs(endDate).format("YYYY-MM-DD");
                            fetchAssignments(formattedStartDate, formattedEndDate);
                        }}
                    />
                </Box>
            )}
        </>
    );
};

export default RosterCalendar;
