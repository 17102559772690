import React, { useState, useEffect } from "react";
import styles from "../styles.scss";
import { Card, CardContent, Typography, TextField, Grid, LinearProgress, Button } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useForm, Controller, useFormContext, FormProvider, useWatch } from "react-hook-form";
import zxcvbn from "zxcvbn";
import { axiosInstance } from "shared/axiosInst";
import history from "shared/history";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Helmet } from "react-helmet";

const useStyles = makeStyles(theme => ({
    root: {
        margin: "100px auto",
        padding: "50px",
        [theme.breakpoints.down("xs")]: {
            padding: "50px 10px",
            margin: "30px auto"
        },
        width: "100%",
        maxWidth: 700
    }
}));

const useProcessStyles = step =>
    makeStyles({
        barColorPrimary: {
            backgroundColor: step > 2 ? "blue" : "red"
        }
    });

const NewPassword = () => {
    const {
        control,
        formState: { errors }
    } = useFormContext();
    const [score, setScore] = useState(0);
    const classes = useProcessStyles(score);
    const newPassword = useWatch({ control, name: "new_password" });
    useEffect(() => {
        const result = zxcvbn(newPassword);
        setScore(25 * result.score);
    }, [newPassword]);
    return (
        <Grid item xs={12}>
            <Controller
                name="new_password"
                control={control}
                rules={{
                    required: { value: true, message: "Required" },
                    minLength: { value: 8, message: "At least 8 characters" },
                    validate: () => (score >= 3 ? true : "Password must be strong")
                }}
                render={({ field: { ref, ...rest } }) => (
                    <TextField
                        error={!!errors?.new_password?.type}
                        helperText={errors?.new_password?.type && errors?.new_password?.message}
                        fullWidth
                        label="New Password"
                        type="password"
                        required
                        autoComplete="on"
                        variant="outlined"
                        inputRef={ref}
                        {...rest}
                    />
                )}
            />
            <Typography variant="caption" color="primary">
                You are required to use a sufficiently strong password. Password must be more than 7 characters.
            </Typography>
            <LinearProgress variant="determinate" value={score} className={classes.barColorPrimary} />
        </Grid>
    );
};

const ConfirmPassword = () => {
    const {
        control,
        formState: { errors }
    } = useFormContext();
    const newPassword = useWatch({ control, name: "new_password" });
    return (
        <Grid item xs={12}>
            <Controller
                name="new_password_confirmation"
                control={control}
                rules={{
                    required: { value: true, message: "Required" },
                    minLength: {
                        value: 8,
                        message: "At least 8 characters"
                    },
                    pattern: {
                        value: new RegExp(`^${newPassword}$`),
                        message: "Confirm password provided does not match the new password."
                    }
                }}
                render={({ field: { ref, ...rest } }) => (
                    <TextField
                        error={!!errors?.new_password_confirmation?.type}
                        fullWidth
                        label="Confirm Password"
                        type="password"
                        required
                        autoComplete="on"
                        variant="outlined"
                        inputRef={ref}
                        helperText={errors?.new_password_confirmation?.type && errors?.new_password_confirmation?.message}
                        {...rest}
                    />
                )}
            />
        </Grid>
    );
};

const ChangePassword = () => {
    const [responseMessage, setResponseMessage] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const classes = useStyles();
    const methods = useForm({
        defaultValues: { old_password: "", new_password: "", new_password_confirmation: "" }
    });
    const {
        control,
        handleSubmit,
        formState: { errors },
        reset
    } = methods;

    const onSubmit = data => {
        setIsLoading(true);
        axiosInstance
            .put(`users/change_password/`, data)
            .then(value => {
                setIsLoading(false);
                if (value.data === "Password successfully changed") {
                    setResponseMessage(value.data);
                    reset({ old_password: "", new_password: "", new_password_confirmation: "" });
                }
            })
            .catch(error => {
                if (error.response.status === 400) {
                    setIsLoading(false);
                    setResponseMessage(error.response?.data.detail);
                }
            });
    };

    return (
        <div className={styles.container}>
            <Helmet>
                <title>Global Portal | Change Password</title>
            </Helmet>
            <Card variant="outlined" className={classes.root}>
                <Button startIcon={<ArrowBackIcon />} onClick={() => history.goBack()}>
                    Go back
                </Button>
                <CardContent>
                    <Typography variant="h6" align="center" paragraph>
                        Change Password
                    </Typography>
                    {responseMessage !== "" && (
                        <Typography variant="body2" color="secondary" align="center" paragraph>
                            {responseMessage}
                        </Typography>
                    )}
                    <FormProvider {...methods}>
                        <form onSubmit={handleSubmit(onSubmit)} noValidate>
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <Controller
                                        name="old_password"
                                        rules={{
                                            required: { value: true, message: "Required" },
                                            minLength: {
                                                value: 8,
                                                message: "At least 8 characters"
                                            }
                                        }}
                                        control={control}
                                        render={({ field: { ref, ...rest } }) => (
                                            <TextField
                                                error={!!errors?.old_password?.type}
                                                helperText={errors?.old_password?.type && errors?.old_password?.message}
                                                fullWidth
                                                label="Current Password"
                                                type="password"
                                                required
                                                variant="outlined"
                                                autoComplete="off"
                                                inputRef={ref}
                                                {...rest}
                                            />
                                        )}
                                    />
                                </Grid>
                                <NewPassword />
                                <ConfirmPassword />
                                <Grid item xs={12} style={{ textAlign: "right" }}>
                                    <Button type="submit" variant="contained" color="primary">
                                        {isLoading ? (
                                            <>
                                                submitting...
                                                <div className="spinner-border spinner-border-sm text-white" role="status">
                                                    <span className="sr-only">Loading...</span>
                                                </div>
                                            </>
                                        ) : (
                                            "Submit"
                                        )}
                                    </Button>
                                </Grid>
                            </Grid>
                        </form>
                    </FormProvider>
                </CardContent>
            </Card>
        </div>
    );
};

export default ChangePassword;
