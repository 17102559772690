import React from "react";
import styles from "shared/details.scss";
import { MDBCard, MDBCardHeader, MDBCardBody, MDBBtn, MDBTable, MDBTableHead, MDBTableBody } from "louis-mdbreact";
import AddItem from "./AddItem";

const FileDetailCard = props => {
    const { permissions, items, isDisabled, fileCategory, addItem } = props;
    return (
        <MDBCard className={styles.card}>
            <MDBCardHeader>
                <span className="float-left">File Details</span>
                {permissions.job !== 1 && (
                    <span className="float-right">
                        <MDBBtn color="primary" size="sm" name="add" onClick={addItem}>
                            Add File
                        </MDBBtn>
                    </span>
                )}
            </MDBCardHeader>
            <MDBCardBody>
                <MDBTable className={styles.detailResponsiveTable}>
                    <MDBTableHead>
                        <tr className={styles.textCentre}>
                            <th width="50%">File</th>
                            <th>File Category</th>
                            <th width="100px">Action</th>
                        </tr>
                    </MDBTableHead>
                    <MDBTableBody>
                        {items.map(item => {
                            return (
                                <AddItem
                                    key={item.id}
                                    fileInput={props.fileInput}
                                    onFileChange={props.onFileChange}
                                    file_type={fileCategory}
                                    file={item}
                                    idx={item.id}
                                    isDisabled={isDisabled}
                                    removeItem={props.removeItem}
                                    fileDownload={props.fileDownload}
                                    onReplaceFile={props.onReplaceFile}
                                    onFileUndo={props.onFileUndo}
                                />
                            );
                        })}
                    </MDBTableBody>
                </MDBTable>
            </MDBCardBody>
        </MDBCard>
    );
};

export default FileDetailCard;
