import { connect } from "react-redux";
import { withRouter } from "react-router";
import { actionCreators as taskActions } from "redux/modules/tasks";

import Container from "./container";

const mapStateToProps = (state, ownProps) => {
    const {
        tasks: { timesheetList, timesheetWorkers },
        user: { permissions, isAdmin, currentCompany }
    } = state;
    return {
        permissions,
        isAdmin,
        currentCompany,
        timesheetWorkers,
        timesheetList
    };
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        getTimesheetWorkers: () => dispatch(taskActions.getTimesheetWorkers()),
        getTimesheetList: requestdata => dispatch(taskActions.getTimesheetList(requestdata)),
        getTimesheetInfo: (modalType, detailData, loggedInfo) => {
            dispatch(taskActions.getTimesheetInfo(modalType, detailData, loggedInfo));
        },
        removeTimesheetList: () => dispatch(taskActions.removeTimesheetList())
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Container));
