import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import dayjs from "shared/dayjs";

const CompleteJobDialog = ({ open, handleClose, markComplete }) => {
    const [completionDate, setCompletiondate] = useState(dayjs().format("YYYY-MM-DD"));
    const onCompleteClick = event => {
        event.preventDefault();
        handleClose();
        markComplete(completionDate);
    };
    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">Complete the job</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    Please select when this job is completed.
                </DialogContentText>
                <TextField
                    autoFocus
                    margin="dense"
                    id="completion_date"
                    label="Completion Date"
                    type="date"
                    value={completionDate}
                    onChange={event => setCompletiondate(event.target.value)}
                    fullWidth
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    close
                </Button>
                <Button onClick={onCompleteClick} color="primary" autoFocus>
                    complete
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default CompleteJobDialog;
