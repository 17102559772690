import React, { Component } from "react";
import RecipientsForm from "./presenter";
import { axiosInstance } from "shared/axiosInst";
import FileUploadBackdrop from "components/FileUploadBackdrop";

class Container extends Component {
    constructor(props) {
        super(props);

        this.state = {
            mailList: [],
            isLoading: false,
            isPending: false,
            notes: ""
        };

        this.signal = axiosInstance.CancelToken.source();
    }
    componentDidMount = () => {
        const { jobInfo, getContactList, isLeadingHand, supervisor } = this.props;
        if (jobInfo) {
            getContactList(Number(jobInfo));
        }

        if (isLeadingHand && supervisor) {
            this.findEmail();
        }
    };

    componentDidUpdate = prevProps => {
        const { jobInfo, subInfo, hasSendBtn, getContactList, isLeadingHand, supervisor, inDetail } = this.props;
        if (subInfo !== prevProps.subInfo && !hasSendBtn) {
            this.sendEmail(subInfo);
        }

        if (jobInfo !== prevProps.jobInfo && (!hasSendBtn || inDetail)) {
            getContactList(Number(jobInfo));
        }

        if (isLeadingHand && supervisor !== prevProps.supervisor) {
            this.findEmail();
        }
    };

    findEmail = () => {
        const { setupValid, supervisor } = this.props;
        axiosInstance.get(`users/email/${supervisor}/`).then(value => {
            const email = value.data.email;
            this.setState(
                {
                    mailList: [
                        {
                            emailAddress: { label: email, value: email }
                        }
                    ],
                    isValid: true
                },
                () => setupValid(this.state)
            );
        });
    };

    componentWillUnmount = () => {
        // const { removeContactList } = this.props;
        // removeContactList();
        this.signal.cancel("Downloading(s) is cancelled by user.");
    };

    emailChange = options => {
        const { setupValid } = this.props;
        if (options) {
            const regx = /\b[\w.-]+@[\w.-]+\.\w{2,4}\b/gi;
            this.setState(
                {
                    mailList: options.map(option => {
                        const matched = option.label.match(regx);
                        return {
                            emailAddress: matched ? option : undefined
                        };
                    })
                },
                () => {
                    const { mailList } = this.state;
                    this.setState(
                        {
                            isValid: mailList.reduce((total, list) => !!list.emailAddress && total, true)
                        },
                        () => {
                            setupValid(this.state);
                        }
                    );
                }
            );
        } else {
            this.setState(
                {
                    mailList: [],
                    isValid: undefined
                },
                () => {
                    setupValid(this.state);
                }
            );
        }
    };

    onNotesChange = event => {
        this.setState({
            notes: event.target.value
        });
    };

    onClickSend = e => {
        const { subInfo } = this.props;
        this.sendEmail(subInfo);
    };

    sendEmail = subInfo => {
        const { mailList, notes } = this.state;
        const { jobInfo, submitEmail, submitFormEmail, hasSendBtn, toggleQA, submitDiaryEmail, inDetail } = this.props;

        if (mailList.length > 0) {
            this.setState({ isLoading: true });
            const email_addresses = mailList.map(mail => {
                if (mail.emailAddress.__isNew__) {
                    return {
                        email_address: mail.emailAddress.label,
                        associated_job: jobInfo
                    };
                } else {
                    return mail.emailAddress.label;
                }
            });

            if (hasSendBtn && !inDetail) {
                toggleQA();
            }

            if (subInfo.qa) {
                submitEmail({ qa: subInfo.qa, emailAddress: email_addresses, notes }, subInfo.submitedQA).then(value => {
                    if (String(value).match(/400/)) {
                    } else {
                    }
                });
            } else if (subInfo.form) {
                submitFormEmail({ form: subInfo.form, emailAddress: email_addresses }).then(value => {
                    if (String(value).match(/400/)) {
                    } else {
                    }
                });
            } else if (subInfo.diary) {
                submitDiaryEmail({ diary: subInfo.diary, emailAddress: email_addresses }).then(value => {
                    if (String(value).match(/400/)) {
                    } else {
                        if (hasSendBtn) this.setState({ isLoading: false });
                    }
                });
            }
        }
    };

    onClickDownload = event => {
        event.preventDefault();
        const { subInfo, downloadQAReport, downloadOperationalForm, downloadDiary } = this.props;
        this.setState(
            {
                isPending: true
            },
            () => {
                if (subInfo.form) {
                    downloadOperationalForm(subInfo.form, this.signal)
                        .then(value => {
                            if (value) {
                                if (value.message === "completed") {
                                    this.setState({
                                        isPending: false
                                    });
                                }
                            }
                        })
                        .catch(err => alert(err));
                } else if (subInfo.qa) {
                    downloadQAReport(subInfo.qa, this.signal)
                        .then(value => {
                            if (value) {
                                if (value.message === "completed") {
                                    this.setState({
                                        isPending: false
                                    });
                                }
                            }
                        })
                        .catch(err => alert(err));
                } else if (subInfo.diary) {
                    downloadDiary(subInfo.diary, this.signal)
                        .then(value => {
                            if (value) {
                                if (value.message === "completed") {
                                    this.setState({
                                        isPending: false
                                    });
                                }
                            }
                        })
                        .catch(err => alert(err));
                }
            }
        );
    };

    render = () => {
        const { contactList, hasSendBtn, hasNotes, isLeadingHand, inDetail, detailTitle } = this.props;
        const { isPending } = this.state;
        return (
            <>
                <RecipientsForm
                    {...this.state}
                    isLeadingHand={isLeadingHand}
                    contactList={contactList}
                    hasSendBtn={hasSendBtn}
                    hasNotes={hasNotes}
                    inDetail={inDetail}
                    detailTitle={detailTitle}
                    emailChange={this.emailChange}
                    onClickSend={this.onClickSend}
                    onClickDownload={this.onClickDownload}
                    onNotesChange={this.onNotesChange}
                />
                <FileUploadBackdrop pendingLoading={isPending} comment="DOWNLOADING FORMS..." />
            </>
        );
    };
}

export default Container;
