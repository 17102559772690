import React, { useState, useContext, useEffect, memo } from "react";
import detailStyles from "shared/details.scss";
import styles from "shared/listStyles.scss";
import { MDBTable, MDBBtn, MDBIcon, MDBBtnGroup } from "louis-mdbreact";
import { Stack, Chip, Checkbox, Button } from "@mui/material";
import WindowedSelect from "react-windowed-select";
import { useSelector, useDispatch } from "react-redux";
import { axiosInstance } from "shared/axiosInst";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import CloseIcon from "@mui/icons-material/Close";
import dayjs from "shared/dayjs";
import { ModalContext } from "../TaskContext";
import { formatDateToTime, timeDiff } from "shared/Utils";
import { actionCreators as taskActions } from "redux/modules/tasks";
import { LoadingButton } from "@mui/lab";
import { TableVirtuoso } from "react-virtuoso";
import { ThemeProvider, createTheme } from "@mui/material/styles";

const EARLY_HOUR = 6;
const LATE_HOUR = 17;

const TableComponents = {
    Table: props => <MDBTable {...props} small className={detailStyles.detailResponsiveTable} />,
    // TableHead: forwardRef((props, ref) => <MDBTableHead {...props} style={{ ...props.style, borderBottom: "2px solid #ced4da" }} ref={ref} />),
    TableRow: props => {
        const startHour = dayjs(props.item.starting_timestamp).get("h");
        const endHour = dayjs(props.item.ending_timestamp).get("h");
        let isNight = false;
        if ((startHour > 0 && startHour < EARLY_HOUR) || (endHour > LATE_HOUR + 1 && endHour < 24)) {
            isNight = true;
        }
        const theme = createTheme({
            palette: {
                mode: isNight ? "dark" : "light"
            }
        });
        return (
            <ThemeProvider theme={theme}>
                <tr
                    className={`${detailStyles.addItemContainer} ${props["data-index"] % 2 === 0 ? detailStyles.darkerLine : ""} ${
                        isNight ? detailStyles.night : ""
                    }`}
                    {...props}
                />
            </ThemeProvider>
        );
    }
};

const MissingMJS = ({ items }) => {
    const {
        tasks: { jobList: taskList = [] },
        user: { currentCompany }
    } = useSelector(state => state);
    const dispatch = useDispatch();
    const { setModalData, setRejectData } = useContext(ModalContext);
    const [totalSelectedMJSLength, setTotalSelectedMJSLength] = useState(0);
    const [totalSelectedLength, setTotalSelectedLength] = useState(0);
    const [missingList, setMissingList] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    useEffect(() => {
        setMissingList(items.map(item => ({ ...item, checked: false })));
        setIsLoading(false);
    }, [items]);

    useEffect(() => {
        const totalLength = missingList?.filter(list => !!list.mjs_id)?.length;
        setTotalSelectedMJSLength(missingList?.length === totalLength ? "All" : `${totalLength} item(s)`);
        const totalSelectedLength = missingList?.filter(list => !!list.checked).length;
        setTotalSelectedLength(totalSelectedLength);
    }, [missingList]);

    const onTotalSaveClick = () => {
        const selectedTimesheet = missingList.filter(list => !!list.mjs_id);
        if (selectedTimesheet.length > 0) {
            setIsLoading(true);
            dispatch(
                taskActions.modifyTimecloud(
                    selectedTimesheet.map(entry => ({
                        id: entry.id,
                        starting_mjs: entry.mjs_id,
                        ending_mjs: entry.mjs_id,
                        reason: 3,
                        checked: undefined,
                        date: undefined
                    }))
                )
            );
        }
    };
    const onSaveClick = item => {
        if (item.mjs_id) {
            dispatch(
                taskActions.modifyTimecloud([
                    {
                        id: item.id,
                        starting_mjs: item.mjs_id,
                        ending_mjs: item.mjs_id,
                        reason: 3,
                        checked: undefined,
                        date: undefined
                    }
                ])
            );
        } else {
            alert("Please select MJS first.");
        }
    };

    const onRejectClick = () => {
        setRejectData(missingList?.filter(list => !!list.checked));
    };

    return (
        missingList && (
            <>
                <TableVirtuoso
                    data={missingList}
                    useWindowScroll={missingList.length <= 6}
                    components={TableComponents}
                    style={{ height: "400px", marginBottom: "20px", border: "1px solid #ced4da" }}
                    fixedHeaderContent={() => (
                        <tr style={{ background: "white" }}>
                            <th width="10"></th>
                            <th width="230">Worker</th>
                            <th width="20%">Job</th>
                            <th>Date</th>
                            <th>Time</th>
                            <th>Supervisor</th>
                            <th width="30%">MJS</th>
                            <th width="100">Action</th>
                        </tr>
                    )}
                    itemContent={(index, list) => {
                        const startHour = dayjs(list.starting_timestamp).get("h");
                        const endHour = dayjs(list.ending_timestamp).get("h");
                        const startMin = dayjs(list.starting_timestamp).get("m");
                        const endMin = dayjs(list.ending_timestamp).get("m");
                        const overTime = { start: false, end: false };
                        if (startHour === EARLY_HOUR || (startHour === LATE_HOUR && startMin > 0)) {
                            overTime.start = true;
                        }
                        if (endHour === EARLY_HOUR || (endHour === LATE_HOUR && endMin > 0)) {
                            overTime.end = true;
                        }
                        return (
                            <>
                                <td data-th="check">
                                    <Checkbox
                                        id={`rejecting-check-${list.id}`}
                                        checked={list.checked}
                                        onChange={() => {
                                            setMissingList(current =>
                                                current.map(cur =>
                                                    cur.id === list.id
                                                        ? {
                                                              ...cur,
                                                              checked: !cur.checked
                                                          }
                                                        : cur
                                                )
                                            );
                                        }}
                                    />
                                </td>
                                <td data-th="Worker">{list.worker_name}</td>
                                <td data-th="Job">
                                    {list.job_id
                                        ? `${list.job_id}-${list.job_region} (${list.job_name})`
                                        : list.end_job_id
                                        ? `${list.end_job_id}-${list.end_job_region} (${list.end_job_name})`
                                        : "-"}
                                </td>
                                <td data-th="Date">
                                    {list.starting_timestamp
                                        ? dayjs(list.starting_timestamp).format("DD/MM/YYYY")
                                        : list.ending_timestamp
                                        ? dayjs(list.ending_timestamp).format("DD/MM/YYYY")
                                        : "-"}
                                </td>
                                <td data-th="Time">
                                    <div>{timeDiff(list.starting_timestamp, list.ending_timestamp)}</div>
                                    <Stack direction="row" spacing={0.5}>
                                        {list.starting_timestamp && (
                                            <Chip
                                                size="small"
                                                color={overTime.start ? "warning" : "default"}
                                                label={formatDateToTime(list.starting_timestamp)}
                                            />
                                        )}
                                        <span>~</span>
                                        {list.ending_timestamp && (
                                            <Chip
                                                size="small"
                                                color={overTime.end ? "warning" : "default"}
                                                label={formatDateToTime(list.ending_timestamp)}
                                            />
                                        )}
                                    </Stack>
                                </td>
                                <td data-th="Supervisor">{list.supervisor_name || "-"}</td>
                                <td data-th="MJS">
                                    <WindowedSelect
                                        styles={{ option: baseStyle => ({ ...baseStyle, color: "black" }) }}
                                        options={taskList.filter(task => task.job === list.job_id || task.id === 302)}
                                        value={taskList.find(task => task.id === list.mjs_id) || ""}
                                        menuPosition="fixed"
                                        id={`list-mjs-selection-${list.id}`}
                                        onChange={option => {
                                            if (option.id === 302) {
                                                setModalData({ ...list, starting_mjs: 302, starting_mjs_note: "Travel (per km)" });
                                            } else {
                                                setMissingList(current =>
                                                    current.map(cur =>
                                                        cur.id === list.id
                                                            ? {
                                                                  ...cur,
                                                                  mjs_id: option.id
                                                              }
                                                            : cur
                                                    )
                                                );
                                            }
                                        }}
                                        getOptionValue={option => option.id}
                                        getOptionLabel={option => `MJS-${option.id} (${option.notes})`}
                                    />
                                </td>
                                <td data-th="Action">
                                    <MDBBtnGroup className={detailStyles.buttonGroup}>
                                        <MDBBtn
                                            className={`${styles.buttonSize}`}
                                            size="sm"
                                            color="primary"
                                            onClick={_ => {
                                                setModalData(null);
                                                axiosInstance.get(`${currentCompany}maintenance/time_tracking/${list.id}/`).then(value => {
                                                    setModalData(value.data);
                                                });
                                            }}
                                        >
                                            <MDBIcon far icon="edit" />
                                        </MDBBtn>
                                        <MDBBtn
                                            onClick={() => {
                                                onSaveClick(list);
                                            }}
                                            className={`${styles.buttonSize}`}
                                            size="sm"
                                            color="success"
                                        >
                                            <MDBIcon icon="save" />
                                        </MDBBtn>
                                        <MDBBtn onClick={_ => setRejectData(list)} className={`${styles.buttonSize}`} size="sm" color="danger">
                                            <MDBIcon icon="times" />
                                        </MDBBtn>
                                    </MDBBtnGroup>
                                </td>
                            </>
                        );
                    }}
                />
                <Stack direction="row" spacing={1} justifyContent="flex-end">
                    <LoadingButton
                        loading={isLoading}
                        variant="contained"
                        size="small"
                        sx={{ backgroundColor: "#00c851", "&:hover": { backgroundColor: "#00c851" } }}
                        startIcon={<SaveOutlinedIcon />}
                        onClick={onTotalSaveClick}
                    >
                        Save {totalSelectedMJSLength}
                    </LoadingButton>
                    <Button variant="contained" size="small" startIcon={<CloseIcon />} color="error" onClick={onRejectClick}>
                        Reject {totalSelectedLength} item(s)
                    </Button>
                </Stack>
            </>
        )
    );
};

export default memo(MissingMJS);
