import { connect } from "react-redux";
import React from "react";
import styles from "shared/listStyles.scss";

const mapStateToProps = state => {
    const {
        user: { isLeadingHand }
    } = state;
    return { isLeadingHand };
};

const ListSwitch = props => {
    return !props.isLeadingHand ? (
        <>
            <div className={styles.switchWrap}>
                <div className={`${styles.listSwitch} custom-control custom-switch`}>
                    <input
                        type="checkbox"
                        className="custom-control-input"
                        id="myjob"
                        name="isMine"
                        checked={props.isMine}
                        onChange={props.changeList}
                        readOnly
                    />
                    <label className="custom-control-label" htmlFor="myjob">
                        MY {props.listType}
                    </label>
                </div>
                <div className={`${styles.listSwitch} custom-control custom-switch`}>
                    <input
                        type="checkbox"
                        className="custom-control-input"
                        id="current"
                        name="isCurrent"
                        checked={props.isCurrent}
                        onChange={props.changeList}
                        readOnly
                    />
                    <label className="custom-control-label" htmlFor="current">
                        CURRENT
                    </label>
                </div>
            </div>
        </>
    ) : (
        <div></div>
    );
};

export default connect(mapStateToProps)(ListSwitch);
