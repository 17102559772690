import React, { Component } from "react";
import JobDetail from "./presenter";
import _ from "lodash";
import SiteDiaryModal from "components/modals/SiteDiaryModal";
import ConfirmModal from "components/modals/ConfirmModal";
import "mapbox-gl/dist/mapbox-gl.css";
import { Prompt } from "react-router";
import { Helmet } from "react-helmet";
import dayjs from "shared/dayjs";
import { axiosInstance } from "shared/axiosInst";
import AddressMap from "../AddressMap";
import EntityDetail from "components/Crm/EntityDetail";

class Container extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isBlock: false,
            isLoading: true,
            sendingData: this.initialSendingData,
            compareData: "",
            clientContact: [],
            isResourceValid: true,
            // isEditAddress: false,
            isEditAddressModal: false,
            isNewAddressModal: false,
            isInspectionModal: false,

            diaryModal: false,
            jobValidModal: false,
            currentAddress: "",
            items: [],
            validMessage: "",
            validButtons: [],
            post_type: 0, // 0:post, 1:approve, 2:reject
            pendingLoading: false
        };

        this.cancelRef = React.createRef();
        this.serviceDetailRef = React.createRef();
        this.priceTypeRef = React.createRef();
        this.addressTimeout = "";
    }

    initialSendingData = {
        newclient: {
            legal_name: "",
            company_type: "1",
            nzbn: "",
            last_credit_check: "",
            new_clicent_notes: ""
        },
        client: "",
        client_qs_entity: "",
        resource: "",
        client_qs: "",
        quote_identifier: "",
        csq_reference: "",
        price_type: "1",
        business_category: "1",
        job_name: "",
        region: 1,
        sub_address_identifier: "",
        main_address: "",
        main_address_full: {
            number: "",
            road_name: "",
            suburb: "",
            town_city: "",
            post_code: "",
            country: ""
        },
        project_detail_notes: "",
        internal_qs: "",
        internal_supervisor: "",
        fire_engineer: null,
        job_services: "",
        dl_period: "",
        claim_date: 31,
        retention_threshold_one: 0,
        retention_threshold_two: 0,
        retention_threshold_three: 0,
        retention_percentage_one: 0,
        retention_percentage_two: 0,
        retention_percentage_three: 0,
        qs_checklist: "",
        legacy_job_id: "",
        job_notes: "",
        under_review: false,
        linings_on_job: false,
        prestart_attendees: [],
        prestart_date: null
    };

    static getDerivedStateFromProps(props, state) {
        switch (props.type) {
            case "new":
                return { title: "NEW JOB", isDisabled: true };
            case "detail":
                if (props.permissions.job === 4) {
                    return { title: "JOB DETAILS", isDisabled: true };
                } else {
                    return { title: "JOB DETAILS", isDisabled: false };
                }
            case "requested":
                if (props.permissions.job === 4 || 3) {
                    return { title: "REQUESTED", isDisabled: true };
                } else {
                    return { title: "REQUESTED", isDisabled: false };
                }
            default:
                return { title: "NEW JOB", isDisabled: false };
        }
    }

    componentDidMount = () => {
        window.scrollTo(0, 0);
        const { getJobBaseData } = this.props;
        getJobBaseData();
    };

    componentDidUpdate = (prevProps, prevState) => {
        const { baseData, clientContact, detailData, type, editedAddressData } = this.props;
        if (prevProps.clientContact !== clientContact) {
            this.setState({
                clientContact,
                sendingData: {
                    ...this.state.sendingData,
                    isPendingClientQSEntity: false
                }
            });
        }

        if (type !== prevProps.type) {
            window.scrollTo(0, 0);
        }

        if (baseData !== prevProps.baseData || type !== prevProps.type) {
            this.initialise();
        }

        if (detailData !== prevProps.detailData) {
            this.matchDetailData();
        }

        if (editedAddressData !== prevProps.editedAddressData) {
            this.setState({
                sendingData: {
                    ...prevState.sendingData,
                    ...editedAddressData
                }
            });
        }
    };

    initialise = () => {
        const {
            baseData,
            type,
            history: {
                match: { params }
            },
            getDetailJob,
            isQS,
            userId
        } = this.props;
        const job_services = baseData.serviceData.map(service => ({
            service: service.id,
            service_order: service.service_order,
            checked: false,
            notes: ""
        }));
        const qs_checklist = baseData.qsCheckData.map(check => ({
            check_item: check.id,
            completed: false
        }));

        this.setState(
            {
                toggleNewAddress: false,
                isBlock: false,
                client_qs: "",
                clientContact: [],
                isResourceValid: true,
                isEditAddressModal: false,
                isNewAddressModal: false,
                // isEditAddress: false,
                currentAddress: "",
                items: [],
                isLoading: type === "new" ? false : true,
                sendingData: {
                    ...this.initialSendingData,
                    job_services,
                    qs_checklist,
                    // product_volume,
                    internal_qs: isQS ? userId : ""
                },
                compareData: JSON.stringify({
                    ...this.initialSendingData,
                    job_services,
                    qs_checklist,
                    // product_volume,
                    internal_qs: isQS ? userId : ""
                })
            },
            () => {
                if (params.id && this.state.isLoading) {
                    getDetailJob(params.id, type);
                }
            }
        );
    };

    matchDetailData = () => {
        const {
            detailData,
            attendeesList,
            baseData,
            history: { history }
        } = this.props;
        const { sendingData } = this.state;
        const job_services = this.mergeOrderObjects(
            detailData.job_services.map(service => ({
                ...service,
                service_order: sendingData.job_services.find(jobSer => jobSer.service === service.service).service_order,
                checked: true
            })),
            sendingData.job_services,
            "service_order"
        );

        const qs_checklist = this.mergeOrderObjects(detailData.qs_checklist, sendingData.qs_checklist, "check_item");

        const prestart_attendees =
            detailData.prestart_attendees && detailData.prestart_attendees.length > 0
                ? detailData.prestart_attendees.map(attendee => ({
                      ...attendeesList.find(list => list.value === attendee),
                      isFixed: true
                  }))
                : [];
        const currentClient = baseData.clients[0].options.find(opt => opt.value === detailData.client);
        const isPendingClient = currentClient?.pending || currentClient?.pending_client;
        const isCurrenClient = currentClient?.current_client;
        if (!(isCurrenClient || isPendingClient)) {
            alert("This client doesn't have relationship with Global anymore. If you want to change details, please come and see IT team");
            history.goBack();
        }
        //internal_qs, internal_supervisor
        this.setState(
            {
                isLoading: false,
                breakdown_created: detailData.breakdown_created,
                // clientContact: detailData.clients,
                sendingData: {
                    ...detailData,
                    job_services,
                    main_address: {
                        value: detailData.main_address,
                        label: detailData.main_address_string
                    },
                    resource: currentClient,
                    client: currentClient,
                    client_qs_entity: { value: detailData.client_qs_entity, label: detailData.client_qs_entity_string },
                    qs_checklist,
                    prestart_date: detailData.prestart_date || dayjs(new Date()).format("YYYY-MM-DD"),
                    prestart_attendees
                },
                items: detailData.file
                // totalCost: job_services.reduce(
                //     (a, b) => (b["checked"] ? a + Number(b["cost"]) : a),
                //     0
                // ),
                // totalPay: job_services.reduce(
                //     (a, b) => (b["checked"] ? a + Number(b["pay_supplier"]) : a),
                //     0
                // )
            },
            () => {
                this.setState({
                    compareData: JSON.stringify(this.state.sendingData)
                });
            }
        );
    };

    mergeOrderObjects = (inspactor, comparator, partucularData) => {
        return _.orderBy(
            _.unionWith(inspactor, comparator, (a, b) => a[partucularData] === b[partucularData]),
            [partucularData],
            ["asc"]
        );
    };

    onChangeValue = event => {
        const {
            target: { name, value, checked }
        } = event;
        if (name.match(/_newclient/)) {
            const removedName = name.split("_newclient")[0];
            this.setState(
                {
                    sendingData: {
                        ...this.state.sendingData,
                        newclient: {
                            ...this.state.sendingData.newclient,
                            [removedName]: checked || value
                        }
                    }
                },
                () => this.setIsBlock()
            );
        } else if (name.match(/_addr_/)) {
            const removedName = name.split("_addr_")[0];
            this.setState(
                {
                    sendingData: {
                        ...this.state.sendingData,
                        main_address_full: {
                            ...this.state.sendingData.main_address_full,
                            [removedName]: checked || value
                        }
                    }
                },
                () => this.setIsBlock()
            );
        } else if (name.match(/_checklist/)) {
            const removedName = name.split("_")[0];
            const {
                sendingData: { qs_checklist }
            } = this.state;
            qs_checklist[removedName - 1].completed = checked;
            this.setState(
                {
                    sendingData: {
                        ...this.state.sendingData,
                        qs_checklist
                    }
                },
                () => this.setIsBlock()
            );
        } else {
            const target = event.nativeEvent.target;
            function iosClearDefault() {
                target.defaultValue = "";
            }
            window.setTimeout(iosClearDefault, 0);
            const newValue = value !== undefined ? value : checked;
            this.setState(
                {
                    sendingData: {
                        ...this.state.sendingData,
                        [name]: newValue
                    }
                },
                () => {
                    const { type } = this.props;
                    if (name === "business_category" && type === "new") {
                        switch (value) {
                            case "1":
                                this.setState({
                                    sendingData: {
                                        ...this.state.sendingData,
                                        dl_period: "",
                                        claim_date: 31,
                                        retention_threshold_one: 0,
                                        retention_threshold_two: 0,
                                        retention_threshold_three: 0,
                                        retention_percentage_one: 0,
                                        retention_percentage_two: 0,
                                        retention_percentage_three: 0
                                    }
                                });
                                break;
                            case "2":
                                this.setState({
                                    sendingData: {
                                        ...this.state.sendingData,
                                        dl_period: 12,
                                        claim_date: 25,
                                        retention_threshold_one: 200000,
                                        retention_threshold_two: 1000000,
                                        retention_threshold_three: 0,
                                        retention_percentage_one: 10,
                                        retention_percentage_two: 5,
                                        retention_percentage_three: 1.75
                                    }
                                });
                                break;
                            case "3":
                                this.setState({
                                    sendingData: {
                                        ...this.state.sendingData,
                                        dl_period: "",
                                        claim_date: 31,
                                        retention_threshold_one: 0,
                                        retention_threshold_two: 0,
                                        retention_threshold_three: 0,
                                        retention_percentage_one: 5,
                                        retention_percentage_two: 0,
                                        retention_percentage_three: 0
                                    }
                                });
                                break;
                            default:
                                break;
                        }
                    }
                    this.setIsBlock();
                }
            );
        }
    };

    onClientChange = option => {
        if (option.value !== 0) {
            this.props.getClientContactDetails(option, option.entityType || null);
        }
        const isPendingClient = option.pending || option.pending_relationship;
        this.setState(
            {
                isResourceValid: true,
                sendingData: {
                    ...this.state.sendingData,
                    isPendingClient,

                    client: option,
                    client_qs_entity: ""
                }
            },
            () => this.setIsBlock()
        );
    };

    onClientContactChange = option => {
        const isPendingClientQSEntity = option.pending;
        this.setState({
            sendingData: {
                ...this.state.sendingData,
                client_qs_entity: option,
                isPendingClientQSEntity
            }
        });
    };

    onServiceChange = event => {
        const {
            target: { name, value, checked }
        } = event;
        const {
            sendingData: { job_services }
        } = this.state;
        const devidedName = name.split("__");
        if (devidedName[0] === "checked") {
            job_services[devidedName[1] - 1][devidedName[0]] = checked;
        } else {
            job_services[devidedName[1] - 1][devidedName[0]] = value || "";
        }

        this.setState(
            {
                sendingData: {
                    ...this.state.sendingData,
                    job_services
                }
            },
            () => this.setIsBlock()
        );
    };

    onChangeMainAddress = option => {
        this.setState(
            {
                sendingData: {
                    ...this.state.sendingData,
                    main_address: option,
                    main_address_full: option
                }
            },
            () => this.setIsBlock()
        );
    };

    getAddressOptions = value => {
        clearTimeout(this.addressTimeout);
        if (value.length > 4) {
            return new Promise((resolve, reject) => {
                this.addressTimeout = setTimeout(async () => {
                    try {
                        const addressResponse = await axiosInstance.get(`common-fire/job/address_geocode/?address=${value}`);
                        if (addressResponse.data) {
                            resolve(
                                addressResponse.data.map(addr =>
                                    addr.id
                                        ? {
                                              ...addr,
                                              label: addr.address_string,
                                              value: addr.id
                                          }
                                        : { ...addr, label: addr.address_string, value: 0 }
                                )
                            );
                        } else {
                            reject("no response");
                        }
                    } catch (error) {
                        reject(error);
                    }
                }, 600);
            });
        }
    };

    onNewAddress = event => {
        const { sendingData } = this.state;
        this.setState(
            prevState => ({
                isNewAddressModal: !prevState.isNewAddressModal,
                sendingData: { ...sendingData, main_address: event.value ? event : sendingData.main_address }
            }),
            () => this.setIsBlock()
        );
    };

    onEditAddress = event => {
        const {
            sendingData: { main_address },
            isEditAddressModal
        } = this.state;

        if (!isEditAddressModal) {
            this.props.getFullAddress(main_address.value);
        }
        this.setState(
            prevState => ({
                isEditAddressModal: !prevState.isEditAddressModal
            }),
            () => this.setIsBlock()
        );
    };

    setIsBlock = () => {
        const { sendingData, compareData, items } = this.state;
        if (JSON.stringify(sendingData) !== compareData || items.length > 0) {
            this.setState({
                isBlock: true
            });
        } else {
            this.setState({
                isBlock: false
            });
        }
    };

    generateBluesheet = event => {
        event.preventDefault();
        const { generateBluesheet } = this.props;
        const {
            sendingData: { id }
        } = this.state;
        generateBluesheet(id);
    };

    onDetailSubmit = event => {
        if (event && event.type === "submit") event.preventDefault();
        const { postJob } = this.props;
        const { items } = this.state;
        this.setState({ post_type: 0 }, () => {
            const submitData = this.validation();
            const filesData = items
                .filter(file => file.new_file)
                .map(file => ({
                    new_file: file.new_file,
                    file_category: file.file_category,
                    originalId: file.originalId || undefined
                }));
            if (submitData) {
                const cancelButton = this.cancelRef.current;
                cancelButton.disabled = true;
                this.setState({
                    pendingLoading: true
                });
                postJob(submitData, filesData).then(value => {
                    if (String(value).match(/400/)) {
                        cancelButton.disabled = false;
                        this.setState({
                            pendingLoading: false
                        });
                    }
                });
            }
        });
    };

    requestApprove = event => {
        if (event && event.type === "submit") event.preventDefault();
        const { approveRequest } = this.props;
        const { items } = this.state;
        this.setState({ post_type: 1 }, () => {
            const submitData = this.validation();
            if (submitData) {
                const removeRequest = object => {
                    delete object.id;
                    delete object.job_request;
                    return object;
                };
                submitData.job_request_id = submitData.id;
                delete submitData.id;
                submitData.job_services = submitData.job_services.map(job => removeRequest(job));
                submitData.qs_checklist = submitData.qs_checklist.map(checklist => removeRequest(checklist));

                //console.log(submitData);
                approveRequest(submitData, items);
            }
        });
    };

    requestReject = event => {
        if (event && event.type === "submit") event.preventDefault();
        const { postJob } = this.props;
        this.setState({ post_type: 2 }, () => {
            const submitData = this.validation();
            if (submitData) {
                submitData.rejected = true;
                postJob(submitData);
            }
        });
    };

    validation = event => {
        this.setState({
            isValidated: true
        });
        const { sendingData } = this.state;
        const { currentRegion, currentCompany } = this.props;
        const submitData = {
            ...JSON.parse(JSON.stringify(sendingData)),
            prestart_attendees: sendingData.prestart_attendees.map(attendee => attendee.value)
        };

        delete submitData.clients;
        delete submitData.mjs;
        submitData.dl_period = submitData.dl_period || 0;
        submitData.job_services = submitData.job_services
            .filter(service => service.checked || service.id)
            .map(job => ({
                id: job.id || undefined,
                service: job.service,
                notes: job.notes
            }));

        submitData.qs_checklist = submitData.qs_checklist.filter(qs => qs.completed || qs.id);

        if (currentCompany === "fire-wbop/") {
            if (!submitData.resource) {
                this.setState({
                    isResourceValid: false
                });
                return false;
            } else {
                delete submitData.client;
                delete submitData.client_qs_entity;
                submitData.resource = submitData.resource.value;
            }
        } else {
            if (!submitData.client) {
                this.setState({
                    isResourceValid: false
                });
                return false;
            } else {
                delete submitData.resource;
                delete submitData.client_qs;
                delete submitData.client_foreperson;
                submitData.client = submitData.client.value;
                submitData.client_qs_entity = submitData.client_qs_entity && submitData.client_qs_entity.value;
            }
        }

        // if there is an empty field, it will return array with more than 1 length.
        // const removedJobService = _.remove(
        //     submitData.job_services,
        //     a => !a.cost || !a.supplier || !a.pay_supplier
        // );

        // const removedProductVolume = _.remove(
        //     submitData.product_volume,
        //     a => !a.quantity || !a.wastage
        // );

        if (
            // !submitData.internal_qs ||
            // !submitData.internal_supervisor ||
            // removedJobService.length > 0 ||
            // removedProductVolume.length > 0 ||
            // !submitData.fire_engineer ||
            !submitData.main_address ||
            submitData.claim_date > 31 ||
            submitData.dl_period > 31 ||
            _.trim(submitData.claim_date) === "" ||
            _.trim(submitData.retention_threshold_one) === "" ||
            submitData.retention_threshold_one > 1000000 ||
            _.trim(submitData.retention_threshold_two) === "" ||
            submitData.retention_threshold_two > 1000000 ||
            _.trim(submitData.retention_threshold_three) === "" ||
            submitData.retention_threshold_three > 1000000 ||
            _.trim(submitData.retention_percentage_one) === "" ||
            _.trim(submitData.retention_percentage_two) === "" ||
            _.trim(submitData.retention_percentage_three) === ""
        ) {
            return false;
        }

        if (submitData.main_address.value === 0) {
            if (!submitData.main_address.address_string) {
                return false;
            }
        }

        currentRegion !== "Auckland" && delete submitData.region;

        this.setState({
            isBlock: false
        });
        return submitData;
    };

    addItem = event => {
        event.preventDefault();
        const {
            baseData: { fileCategory }
        } = this.props;
        this.setState(
            prevState => ({
                items: [
                    ...prevState.items,
                    {
                        new_file: "",
                        file_category: fileCategory.find(cate => cate.name === "Quote").id,
                        id: Number(new Date())
                    }
                ]
            }),
            () => this.setIsBlock()
        );
    };

    onFileChange = (event, idx) => {
        event.preventDefault();
        const { items } = this.state;
        const index = _.findIndex(items, n => {
            return n.id === idx;
        });
        if (event.target.name === "new_file") {
            if (event.target.files[0]) {
                items[index].new_file = event.target.files[0];
            }
        } else {
            items[index].file_category = event.target.value;
        }
        this.setState(prevState => ({
            ...prevState,
            items
        }));
    };

    onReplaceFile = (event, idx) => {
        event.preventDefault();
        const { items } = this.state;
        const {
            baseData: { fileCategory }
        } = this.props;
        const index = _.findIndex(items, n => n.id === idx);
        if (items[index].oldState) {
            items[index] = {
                ...items[index].oldState
            };
        } else {
            items[index] = {
                new_file: "",
                file_category: fileCategory.find(cate => cate.name === items[index].file_category_string).id,
                id: Number(new Date()),
                originalId: idx,
                oldState: { ...items[index] }
            };
        }

        this.setState({
            items
        });
    };

    removeItem = (event, idx) => {
        event.preventDefault();
        const { items } = this.state;

        const index = _.findIndex(items, n => {
            return n.time === idx;
        });

        items.splice(index, 1);

        this.setState(
            prevState => ({
                ...prevState,
                items
            }),
            () => this.setIsBlock()
        );
    };

    fileDownload = (event, idx, isLarge) => {
        event.preventDefault();
        if (isLarge) {
            const answer = window.confirm(
                "This file is quite large. We suggest you don't download it on mobile connection. Do you want to continue?"
            );
            if (!answer) {
                return false;
            }
        }
        this.props.fileDownload(idx);
    };

    onChangeAttendees = option => {
        this.setState({
            sendingData: {
                ...this.state.sendingData,
                prestart_attendees: option || []
            }
        });
    };

    returnToList = () => {
        this.setState(
            {
                isBlock: false
            },
            () => {
                const {
                    type,
                    history: {
                        history,
                        match: { params }
                    }
                } = this.props;
                if (type === "new") {
                    history.push("/jobs");
                } else if (type === "detail") {
                    history.push(`/jobs/${params.id}`);
                } else {
                    history.push("/jobs/requested");
                }
            }
        );
    };

    toggleDiary = () => {
        const { diaryModal } = this.state;
        this.setState({
            diaryModal: !diaryModal
        });
    };

    toggleJobValid = (e, idx) => {
        const offsetTop = this.serviceDetailRef.current.offsetParent.offsetTop - 55;
        const { jobValidModal } = this.state;
        if (idx !== 0) {
            if (idx === 1) {
                window.scrollTo(0, offsetTop);
                this.setState({
                    isValidated: false
                });
            } else if (idx === 4) {
                this.setState(
                    {
                        sendingData: {
                            ...this.state.sendingData,
                            price_type: 2
                        }
                    },
                    () => this.sortedSubmit()
                );
            } else {
                this.setState(
                    {
                        sendingData: {
                            ...this.state.sendingData,
                            business_category: idx
                        }
                    },
                    () => this.sortedSubmit()
                );
            }
        }

        this.setState({
            jobValidModal: !jobValidModal
        });
    };

    addNewClient = event => {
        const {
            target: { name }
        } = event;
        this.setState({
            isNewClient: !this.state.isNewClient,
            inJob: name === "newContact" ? "contact" : "client"
        });
    };

    switchScreen = newEntity => {
        const { sendingData, inJob } = this.state;
        window.scrollTo(0, 0);
        this.setState({
            isNewClient: !this.state.isNewClient,
            sendingData:
                newEntity && inJob === "client"
                    ? {
                          ...sendingData,
                          client: { value: newEntity.id, label: newEntity.name },
                          isPendingClient: newEntity.pending,
                          resource: { value: newEntity.id, label: newEntity.name }
                      }
                    : newEntity && inJob === "contact"
                    ? {
                          ...sendingData,
                          isPendingClientQSEntity: newEntity.pending,
                          client_qs_entity: { value: newEntity.id, label: newEntity.name }
                      }
                    : sendingData
        });
    };

    sortedSubmit = () => {
        const { post_type } = this.state;
        switch (post_type) {
            case 0:
                this.onDetailSubmit();
                break;
            case 1:
                this.requestApprove();
                break;
            case 2:
                this.requestReject();
                break;
            default:
                break;
        }
    };

    componentWillUnmount = () => {
        const { removeClientContact } = this.props;
        this.setState({
            clientContact: []
        });
        removeClientContact();
    };

    render() {
        const {
            diaryModal,
            sendingData,
            jobValidModal,
            validMessage,
            validButtons,
            isBlock,
            isEditAddressModal,
            isNewAddressModal,
            title,
            isNewClient,
            inJob
        } = this.state;
        const {
            baseData,
            type,
            permissions,
            isQS,
            attendeesList,
            history: {
                match: { params }
            },
            currentRegion,
            detailData
        } = this.props;
        return (
            <>
                <Helmet>
                    <title>
                        Jobs | {title} {type !== "new" ? `> ${detailData?.job_name}` : ""}
                    </title>
                </Helmet>
                {isNewClient ? (
                    <EntityDetail inJob={inJob} parent={sendingData.client} switchScreen={this.switchScreen} />
                ) : (
                    <JobDetail
                        {...this.state}
                        type={type}
                        pageId={params.id}
                        permissions={permissions}
                        isQS={isQS}
                        baseData={baseData}
                        currentRegion={currentRegion}
                        attendeesList={attendeesList}
                        onChangeValue={this.onChangeValue}
                        onClientChange={this.onClientChange}
                        onServiceChange={this.onServiceChange}
                        onDetailSubmit={this.onDetailSubmit}
                        onChangeMainAddress={this.onChangeMainAddress}
                        getAddressOptions={this.getAddressOptions}
                        returnToList={this.returnToList}
                        generateBluesheet={this.generateBluesheet}
                        requestApprove={this.requestApprove}
                        requestReject={this.requestReject}
                        onEditAddress={this.onEditAddress}
                        onNewAddress={this.onNewAddress}
                        addressData={baseData?.addressData}
                        main_address={sendingData.main_address}
                        toggleDiary={this.toggleDiary}
                        addItem={this.addItem}
                        removeItem={this.removeItem}
                        onFileChange={this.onFileChange}
                        fileDownload={this.fileDownload}
                        onReplaceFile={this.onReplaceFile}
                        onChangeAttendees={this.onChangeAttendees}
                        onClientContactChange={this.onClientContactChange}
                        forwardRef={this.cancelRef}
                        serviceDetailRef={this.serviceDetailRef}
                        priceTypeRef={this.priceTypeRef}
                        addNewClient={this.addNewClient}
                    />
                )}
                {isEditAddressModal && <AddressMap modal={isEditAddressModal} toggle={this.onEditAddress} />}
                {isNewAddressModal && <AddressMap isNewAddress modal={isNewAddressModal} toggle={this.onNewAddress} />}
                {/* <EditAddressModal currentAddress={currentAddress} modal={isEditAddress} toggle={this.onEditAddress} /> */}

                <SiteDiaryModal diaryModal={diaryModal} selectedJob={sendingData.id} toggleDiary={this.toggleDiary} />

                <ConfirmModal modal={jobValidModal} message={validMessage} buttons={validButtons} toggle={this.toggleJobValid} />

                <Prompt when={isBlock} message="Changes that you made may not be saved." />
            </>
        );
    }
}

export default Container;
