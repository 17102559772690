import React, { Component } from "react";
import JobsRequested from "./presenter";

class Container extends Component {
    constructor(props) {
        super(props);

        const isMine =
            localStorage.getItem("isMineJob") === "true" ||
            localStorage.getItem("isMineJob") === null;

        this.state = {
            loading: true,
            isMine,
            titleName: this.setTitleName(isMine)
        };

        this.changeList = this.changeList.bind(this);
    }

    setTitleName(isMine) {
        const title = `${isMine ? "my " : ""}requested jobs`;

        return title.toUpperCase();
    }

    componentDidMount() {
        const { getJobRequested } = this.props;
        getJobRequested(this.state.isMine);
        window.scrollTo(0, 0);
    }

    componentDidUpdate(prevProps) {
        const { jobList } = this.props;
        if (jobList !== prevProps.jobList) {
            this.setState({
                loading: false
            });
        }
    }

    changeList(event) {
        const {
            target: { name, checked }
        } = event;
        const { getJobRequested } = this.props;
        localStorage.setItem([`${name}Job`], checked);
        this.setState(
            {
                [name]: checked,
                loading: true
            },
            () => {
                const { isMine } = this.state;
                getJobRequested(isMine);
                this.setState({
                    titleName: this.setTitleName(isMine)
                });
            }
        );
    }

    render() {
        const { jobList, permissions, currentRegion } = this.props;
        const { loading, titleName, isMine } = this.state;
        return (
            <JobsRequested
                permissions={permissions}
                currentRegion={currentRegion}
                jobList={jobList}
                loading={loading}
                titleName={titleName}
                isMine={isMine}
                changeList={this.changeList}
            />
        );
    }
}

export default Container;
