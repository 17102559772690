import { connect } from "react-redux";
import Container from "./container";
import { withRouter } from "react-router";
import { actionCreators as jobActions } from "redux/modules/jobs";
// import { actionCreators as taskActions } from "redux/modules/tasks";
import { actionCreators as operationActions } from "redux/modules/operations";
import { getSlimJobListByDiary } from "redux/reducers";
import { dbActions } from "shared/dbs";

const mapStateToProps = (state, ownProps) => {
    const {
        user: { permissions },
        router: {
            location: { query }
        }
        // tasks: { timesheetWorkers } // TODO : getting workers from reducer,
    } = state;
    return {
        // timesheetWorkers:
        //     timesheetWorkers &&
        //     timesheetWorkers.map(worker => ({
        //         value: worker.id,
        //         label: `${worker.first_name} ${worker.last_name}`,
        //         signature: ""
        //     })),
        query,
        permissions,
        jobList: getSlimJobListByDiary(state),
        formType: JSON.parse(localStorage.getItem("formType"))
    };
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        getSlimJobList: () => dispatch(jobActions.getSlimJobsForQA()),
        // getTimesheetWorkers: () => dispatch(taskActions.getTimesheetWorkers()),
        getFormDetail: id => dispatch(operationActions.getFormDetail(id)),
        submitOperationalForm: (submitData, uploadProgress) => dispatch(operationActions.submitOperationalForm(submitData, uploadProgress)),
        getOperationCommon: refresh => dispatch(operationActions.getOpsCommons(refresh)),
        getSkinnyJobDetail: id => dispatch(jobActions.getSkinnyJobDetail(id)),
        loadTableByKey: key => dispatch(dbActions.loadTableByKey(key)),
        addValuesByKey: (key, data) => dispatch(dbActions.addValuesByKey(key, data)),
        deleteTableByKey: key => dispatch(dbActions.deleteTableByKey(key))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Container));
