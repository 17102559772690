import React from "react";
import { NavLink } from "react-router-dom";
import styles from "shared/headNavStyles.scss";
import { MDBIcon } from "louis-mdbreact";
import { useSelector } from "react-redux";

const TaskDependPermission = props => {
    const { toggleNavbar } = props;
    const {
        user: { permissions, isSLT }
    } = useSelector(state => state);
    // const [open, setOpen] = useState(false);
    switch (permissions.maintenance) {
        case 4:
            return (
                <>
                    <li>
                        <NavLink to={`/tasks/rostering`} activeClassName={styles.active} onClick={toggleNavbar}>
                            <MDBIcon far icon="user-circle" /> <span>Rostering</span>
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to={`/tasks/assignments`} activeClassName={styles.active} onClick={toggleNavbar}>
                            <MDBIcon far icon="user-circle" /> <span>Assignments</span>
                        </NavLink>
                    </li>
                    {/* <li>
                        <button
                            className={styles.active}
                            onClick={() => {
                                setOpen(current => !current);
                                toggleNavbar();
                            }}
                        >
                            <MDBIcon far icon="user-circle" /> <span>Request Labour</span>
                        </button>
                    </li> */}
                    {/* <li>
                        <NavLink to={`/tasks/approve`} activeClassName={styles.active} onClick={toggleNavbar}>
                            <MDBIcon far icon="check-circle" /> <span>Approvals</span>
                        </NavLink>
                    </li> */}
                    {/* {open && (
                        <RequestLaboursModal
                            modal={open}
                            toggle={() => {
                                setOpen(false);
                            }}
                        />
                    )} */}
                    {/* <li>
                        <NavLink to={`/tasks/timelogs`} activeClassName={styles.active} onClick={toggleNavbar}>
                            <WebStoriesOutlinedIcon /> <span>Logs</span>
                        </NavLink>
                    </li> */}
                </>
            );
        case 3:
            return (
                <>
                    <li>
                        <NavLink to={`/tasks/rostering`} activeClassName={styles.active} onClick={toggleNavbar}>
                            <MDBIcon far icon="user-circle" /> <span>Rostering</span>
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to={`/tasks/assignments`} activeClassName={styles.active} onClick={toggleNavbar}>
                            <MDBIcon far icon="user-circle" /> <span>Assignments</span>
                        </NavLink>
                    </li>
                    {/* <li>
                        <button
                            className={styles.active}
                            onClick={() => {
                                setOpen(current => !current);
                                toggleNavbar();
                            }}
                        >
                            <MDBIcon far icon="user-circle" /> <span>Request Labour</span>
                        </button>
                    </li> */}
                    {/* <li>
                        <NavLink to={`/tasks/approve`} activeClassName={styles.active} onClick={toggleNavbar}>
                            <MDBIcon far icon="check-circle" /> <span>Approvals</span>
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to={`/tasks/timelogs`} activeClassName={styles.active} onClick={toggleNavbar}>
                            <WebStoriesOutlinedIcon /> <span>Logs</span>
                        </NavLink>
                    </li> */}
                    {/* {open && (
                        <RequestLaboursModal
                            modal={open}
                            toggle={() => {
                                setOpen(false);
                            }}
                        />
                    )} */}
                </>
            );
        default:
            return (
                <>
                    {isSLT && (
                        <>
                            <li>
                                <NavLink to={`/tasks/approve`} activeClassName={styles.active} onClick={toggleNavbar}>
                                    <MDBIcon far icon="check-circle" /> <span>Approvals</span>
                                </NavLink>
                            </li>
                            {/* <li>
                                <NavLink to={`/tasks/timelogs`} activeClassName={styles.active} onClick={toggleNavbar}>
                                    <WebStoriesOutlinedIcon /> <span>Logs</span>
                                </NavLink>
                            </li> */}
                        </>
                    )}
                </>
            );
    }
};

export default TaskDependPermission;
