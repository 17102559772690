import React, { useEffect, useState } from "react";
import { MDBRow, MDBCol, MDBBtn } from "louis-mdbreact";
import styles from "shared/details.scss";
import Loading from "components/Loading";
import SubmitButton from "components/SubmitButton";
import Entity from "components/Crm/Details/Entity";
import Notes from "components/Crm/Details/Notes";
import TaxSpec from "components/Crm/Details/TaxSpec";
import Contact from "components/Crm/Details/Contact";
import Attributes from "components/Crm/Details/Attributes";
import WorkerAttributes from "components/Crm/Details/WorkerAttributes";
import ArrowBackButton from "components/ArrowBackButton";

const EntityDetail = ({ isLoading, wasValidated, submitEntity, returnToList, getSendingData, skill, role, inJob, permissions, isAdmin, parent }) => {
    const administration = permissions.crm === 4 && isAdmin;
    const [title, setTitle] = useState("NEW ENTITY");
    useEffect(() => {
        if (inJob) setTitle(`NEW ${String(inJob).toLocaleUpperCase()}`);
    }, [inJob]);
    return (
        <div className={styles.container}>
            <h2 className={styles.title}>
                {inJob && <ArrowBackButton onClick={returnToList} />}
                <span>{title}</span>
            </h2>
            {isLoading ? (
                <Loading loadingType="detail" />
            ) : (
                <form className={`needs-validation ${wasValidated ? "was-validated" : ""}`} onSubmit={submitEntity} noValidate>
                    <Entity type="new" parent={parent} inJob={inJob} getSendingData={getSendingData} wasValidated={wasValidated} />
                    <Notes type="new" getSendingData={getSendingData} />
                    {administration && <TaxSpec type="new" getSendingData={getSendingData} />}
                    <Contact type="new" getSendingData={getSendingData} wasValidated={wasValidated} />
                    <Attributes type="new" getSendingData={getSendingData} skill={skill} wasValidated={wasValidated} inJob={inJob} />
                    {administration && <WorkerAttributes type="new" getSendingData={getSendingData} role={role} />}
                    <MDBRow>
                        <MDBCol>
                            <div className={`${styles.card} float-right`}>
                                <MDBBtn color="blue-grey" onClick={returnToList}>
                                    Cancel
                                </MDBBtn>{" "}
                                <SubmitButton type="new" />
                            </div>
                        </MDBCol>
                    </MDBRow>
                </form>
            )}
        </div>
    );
};

export default EntityDetail;
