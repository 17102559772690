import React, { Component } from "react";
import EntityDetail from "./presenter";
import { Helmet } from "react-helmet";

class Container extends Component {
    constructor(props) {
        super(props);

        this.state = {
            entity: {},
            Email: [],
            Location: [],
            "Phone Number": [],
            assignment: [],
            notes: [],
            qualification: [],
            relationship: [],
            role: [],
            skill: [],
            tax: [],
            validate: {}
        };
    }

    returnToList = event => {
        event.preventDefault();
        const { history, inJob, switchScreen } = this.props;
        if (inJob) {
            switchScreen();
        } else {
            history.goBack();
        }
    };

    submitEntity = event => {
        event.preventDefault();
        const { submitFullEntity, inJob, switchScreen } = this.props;
        const { validate, Email, "Phone Number": phoneNumber } = this.state;
        this.setState({ wasValidated: true });
        const validated = Object.keys(validate).reduce((bool, key) => bool && validate[key], true);
        if (validated) {
            if (Email.length + phoneNumber.length === 0) {
                alert("An entity should have at least either an email address or a phone number.");
                return;
            }
            submitFullEntity(this.state, inJob).then(value => {
                if (value && value.id) {
                    if (inJob) {
                        switchScreen(value);
                    } else {
                        const { history } = this.props;
                        history.push("/crm/entities");
                    }
                }
            });
        } else {
            alert("Please write down all required fields or check if values are valid.");
        }
    };

    getSendingData = (name, data, goodToGo) => {
        const { validate } = this.state;
        validate[`${name}`] = goodToGo;
        this.setState({
            [name]: data,
            validate
        });
    };

    render() {
        const { inJob, permissions, parent, isAdmin } = this.props;
        return (
            <>
                {!inJob && (
                    <Helmet>
                        <title>CRM | New Entity</title>
                    </Helmet>
                )}
                <EntityDetail
                    {...this.state}
                    inJob={inJob}
                    parent={parent}
                    permissions={permissions}
                    isAdmin={isAdmin}
                    submitEntity={this.submitEntity}
                    getSendingData={this.getSendingData}
                    returnToList={this.returnToList}
                />
            </>
        );
    }
}

export default Container;
