import { connect } from "react-redux";
import Container from "./container";

import { actionCreators as jobActions } from "redux/modules/jobs";
import { getBranchListByOrderFiltered } from "redux/reducers";

const mapStateToProps = (state, ownProps) => {
    const {
        jobs: { currentBranch }
    } = state;
    return {
        branchList: getBranchListByOrderFiltered(state),
        currentBranch
    };
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        getBranchList: () => dispatch(jobActions.getBranchList()),
        setCurrentBranch: id => dispatch(jobActions.setCurrentBranch(id))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Container);
