import React from "react";
import Loading from "components/Loading";
import styles from "shared/listStyles.scss";
import { Link } from "react-router-dom";
import CreateNewButton from "components/CreateNewButton";
import { MDBDataTable, MDBIcon, MDBBtn, MDBBtnGroup, MDBCard, MDBCardHeader, MDBCardBody, MDBRow, MDBCol, MDBCardText } from "louis-mdbreact";
import ChipList from "../ChipList";
import Button from "@mui/material/Button";
import CloseIcon from "@mui/icons-material/Close";

const Entity = props => {
    const {
        entitiesList,
        filterSetting,
        commonItems,
        entityCommons,
        toggleFilter,
        changeList,
        searchText,
        activePage,
        onSearch,
        onPageChange,
        loading,
        clearFilters
    } = props;
    const hasfilter = Object.keys(filterSetting).reduce((a, current) => {
        if (current !== "isOpen" && current !== "pending") {
            return (filterSetting[current] === "0" ? false : true) || a;
        } else {
            return a || false;
        }
    }, false);
    const data = {
        columns: [
            { label: "No.", field: "job_no", sort: "asc" },
            { label: "Type", field: "type", sort: "asc" },
            { label: "Parent", field: "associated", sort: "asc" },
            { label: "Name", field: "name", sort: "asc" },
            { label: "Relationships", field: "relationships", sort: "asc" },
            { label: "Responsibilities", field: "responsibilities", sort: "asc" },
            { label: "Actions", field: "actions", sort: "disabled" }
        ],
        rows:
            !loading &&
            entitiesList.map(entity => ({
                job_no: entity.id,
                name: entity.short_name ? `${entity.name} (${entity.short_name})` : entity.name,
                type: entity.entity_type || "-",
                associated: entity.parent_name_string
                    ? entity.parent_short_name_string
                        ? `${entity.parent_name_string} (${entity.parent_short_name_string})`
                        : entity.parent_name_string
                    : "-",
                searchable: entity.relationships.join(", ") + entity.responsibilities.join(", ") + entity.id,
                relationships: (
                    <div
                        className={`${entity.pending_client ? "light-blue lighten-4" : undefined} ${styles.pendingRelationship}`}
                        searchvalue={entity.relationships.join(", ")}
                    >
                        <ChipList parent={entity.relationships || []} />
                    </div>
                ),
                responsibilities: (
                    <span searchvalue={entity.responsibilities.join(", ")}>
                        <ChipList parent={entity.responsibilities || []} />
                    </span>
                ),
                actions: (
                    <MDBBtnGroup>
                        <Link to={`/crm/entities/${entity.id}`}>
                            <MDBBtn color="primary" className={`${styles.buttonSize} ${styles.noRound}`}>
                                <MDBIcon icon="info-circle" />
                            </MDBBtn>
                        </Link>
                    </MDBBtnGroup>
                ),
                rowClassName: entity.pending ? "light-blue lighten-5" : undefined
            }))
    };
    return (
        <div className={styles.container}>
            <div className={styles.titleWrap}>
                <h2 className={`${styles.taskTitle}`}>
                    <MDBIcon
                        className="orange-text"
                        style={{ cursor: "pointer" }}
                        onClick={toggleFilter}
                        icon="cog"
                        spin={filterSetting.isOpen}
                        fixed
                    />
                    Entity
                </h2>
                <CreateNewButton />
                <div className={styles.switchWrap}>
                    <div className={`${styles.listSwitch} custom-control custom-switch`}>
                        <input
                            type="checkbox"
                            className="custom-control-input"
                            id="pending"
                            name="pending"
                            checked={filterSetting.pending}
                            onChange={e => changeList({ target: { name: "pending", value: e.target.checked } })}
                            readOnly
                        />
                        <label className="custom-control-label" htmlFor="pending">
                            PENDING
                        </label>
                    </div>
                </div>
            </div>
            {hasfilter && !filterSetting.isOpen && (
                <div className="mb-4" style={{ marginTop: "-20px" }}>
                    <Button startIcon={<CloseIcon />} size="small" color="secondary" onClick={clearFilters}>
                        Clear filters
                    </Button>
                </div>
            )}
            {filterSetting.isOpen ? (
                <MDBCard className="mb-5">
                    <MDBCardHeader>
                        Entity Filter
                        <span className="float-right">
                            <Button className="ml-2" startIcon={<CloseIcon />} size="small" color="secondary" onClick={clearFilters}>
                                Clear filters
                            </Button>
                        </span>
                    </MDBCardHeader>
                    <MDBCardBody>
                        <MDBRow>
                            <MDBCol sm="4">
                                <MDBCardText>
                                    <label htmlFor="entity_type">Entity Type</label>
                                    <select
                                        className="custom-select custom-select-sm"
                                        value={filterSetting.entity_type}
                                        onChange={changeList}
                                        name="entity_type"
                                        id="entity_type"
                                    >
                                        <option value="0">Select...</option>
                                        {entityCommons &&
                                            entityCommons.entity_type.map(type => (
                                                <option key={type.id} value={type.id}>
                                                    {type.name}
                                                </option>
                                            ))}
                                    </select>
                                </MDBCardText>
                            </MDBCol>
                            <MDBCol sm="4">
                                <MDBCardText>
                                    <label htmlFor="relationships">Relationship</label>
                                    <select
                                        className="custom-select custom-select-sm"
                                        value={filterSetting.relationships}
                                        onChange={changeList}
                                        name="relationships"
                                        id="relationships"
                                    >
                                        <option value="0">Select...</option>
                                        {commonItems &&
                                            commonItems.relationship.map(rel => (
                                                <option key={rel.id} value={rel.id}>
                                                    {rel.name}
                                                </option>
                                            ))}
                                    </select>
                                </MDBCardText>
                            </MDBCol>
                            <MDBCol sm="4">
                                <MDBCardText>
                                    <label htmlFor="assignments">Assignment</label>
                                    <select
                                        className="custom-select custom-select-sm"
                                        value={filterSetting.assignments}
                                        onChange={changeList}
                                        name="assignments"
                                        id="assignments"
                                    >
                                        <option value="0">Select...</option>
                                        {commonItems &&
                                            commonItems.assignment.map(assign => (
                                                <option key={assign.id} value={assign.id}>
                                                    {assign.name}
                                                </option>
                                            ))}
                                    </select>
                                </MDBCardText>
                            </MDBCol>
                        </MDBRow>
                    </MDBCardBody>
                </MDBCard>
            ) : undefined}
            {loading ? (
                <Loading loadingType="list" />
            ) : (
                <MDBDataTable
                    entriesOptions={[10, 20, 50, 100, 500]}
                    entries={20}
                    responsive
                    borderless
                    striped
                    responsiveSm
                    pagesAmount={5}
                    data={data}
                    sortRows={["relationships", "responsibilities"]}
                    searchText={searchText}
                    initialPage={activePage}
                    onSearch={onSearch}
                    onPageChange={onPageChange}
                    className={`${styles.entityDataTable} ${styles.dataTable} mt-3`}
                />
            )}
        </div>
    );
};

export default Entity;
