// imports
import Cookies from "js-cookie";
import { axiosInstance, API_URL } from "shared/axiosInst";
import { parseJwt, detectMobile } from "shared/Utils";
import { actionCreators as jobActions } from "redux/modules/jobs";
import { actionCreators as operationActions } from "redux/modules/operations";
import { actionCreators as hrActions } from "./humanResources";
import history from "shared/history";
import _ from "lodash";

// variables
const setRole = role => {
    const isQS = _.findIndex(role, r => r.id === 1) !== -1 ? true : false;
    const isSupervisor = _.findIndex(role, r => r.id === 2) !== -1 ? true : false;
    const isAdmin = _.findIndex(role, r => r.id === 3) !== -1 ? true : false;
    const isSLT = _.findIndex(role, r => r.id === 5) !== -1 ? true : false;
    const isRosterer = _.findIndex(role, r => r.name === "Rosterer") !== -1 ? true : false;
    const isLeadingHand = _.findIndex(role, r => r.name === "Leading Hand") !== -1 ? true : false;
    return { isQS, isSupervisor, isAdmin, isSLT, isRosterer, isLeadingHand };
};

// actions
const SAVE_TOKEN = "SAVE_TOKEN";
const LOG_OUT = "LOG_OUT";
const CHECK_PERMISSIONS = "CHECK_PERMISSIONS";
const SET_COMPANY = "SET_COMPANY";
const NOTIFY_NOT_LOGGED_IN = "NOTIFY_NOT_LOGGED_IN";
const SET_RESOURCES = "SET_RESOURCES";
const SET_GLOBAL_USERS = "SET_GLOBAL_USERS";
const SET_SUPERVISORS = "SET_SUPERVISORS";
const SET_FILE_CATEGORY = "SET_FILE_CATEGORY";

// action creators
function saveToken(token) {
    return {
        type: SAVE_TOKEN,
        token
    };
}

function logout() {
    return {
        type: LOG_OUT
    };
}

function setCompany(company) {
    return {
        type: SET_COMPANY,
        company
    };
}

function setPersonPermission(level) {
    return {
        type: CHECK_PERMISSIONS,
        level
    };
}

function notifyNotLoggedIn() {
    return {
        type: NOTIFY_NOT_LOGGED_IN
    };
}

function setResources(resourcesList) {
    return {
        type: SET_RESOURCES,
        resourcesList
    };
}

function setGlobalUSers(globalUsers) {
    return {
        type: SET_GLOBAL_USERS,
        globalUsers
    };
}

function setSupervisors(supervisors) {
    return {
        type: SET_SUPERVISORS,
        supervisors
    };
}

function setFileCategory(file_category) {
    return {
        type: SET_FILE_CATEGORY,
        file_category
    };
}

// function

function logOut() {
    return dispatch => {
        dispatch(logout());
    };
}

// API actions

function login(username, password) {
    localStorage.setItem("wName", _.upperFirst(_.toLower(username.split("@")[0])));
    return async dispatch => {
        fetch(`${API_URL}token/`, {
            method: "POST",
            headers: {
                "content-Type": "application/json"
            },
            body: JSON.stringify({
                username,
                password
            })
        })
            .then(response => {
                if (response.status === 401) {
                    dispatch(notifyNotLoggedIn());
                }
                return response.json();
            })
            .then(async json => {
                if (json.access && json.refresh) {
                    const { access, refresh } = json;
                    Cookies.set("acc_token", access, {
                        expires: parseJwt(access),
                        path: "/",
                        secure: true,
                        sameSite: "Lax"
                    });
                    Cookies.set("ref_token", refresh, {
                        expires: parseJwt(refresh),
                        path: "/",
                        secure: true,
                        sameSite: "Lax"
                    });
                    Object.assign(axiosInstance.defaults, {
                        headers: { Authorization: `Bearer ${access}` }
                    });

                    // permissions initial setting up
                    const response = await axiosInstance.get(`users/user_access_detail/`);
                    const responseData = await response.data;
                    const currentComObj = responseData.authorised_company.filter(
                        company => company.brand === 2 && company.display_region === "True"
                    )[0];
                    if (!currentComObj) {
                        alert("You have not been granted Global Fire account, please contact IT to get it.");
                        dispatch(notifyNotLoggedIn());
                        dispatch(logOut());
                        return;
                    }
                    const currentCompany = currentComObj.company_string.split(" ")[1].toLowerCase();
                    if (responseData.user_app_permission.length > 0) {
                        await dispatch(getUserCommons());
                        await dispatch(jobActions.getJobCommons());
                        if (responseData.user_app_permission.find(permission => permission.app === 6)) {
                            await dispatch(operationActions.getOpsCommons());
                        }
                        if (responseData.user_app_permission.find(permission => permission.app === 12)) {
                            await dispatch(hrActions.getTotalHolidayByRegion(responseData.home_region));
                        }

                        dispatch(
                            saveToken({
                                currentCompany,
                                currentRegion: currentComObj.region_string,
                                companies: responseData.authorised_company.filter(company => company.display_region === "True"),
                                companyPrefix: currentComObj.company_prefix,
                                permissions: responseData.user_app_permission,
                                userId: responseData.id,
                                role: responseData.role_held,
                                welcomeName: `${responseData.first_name} ${responseData.last_name}`,
                                homeRegion: responseData.home_region,
                                direct_report: responseData.direct_report,
                                manager: responseData.manager
                            })
                        );
                        dispatch(
                            setPersonPermission({
                                currentComObj,
                                permissions: responseData.user_app_permission,
                                companies: responseData.authorised_company.filter(company => company.display_region === "True"),
                                userId: responseData.id,
                                role: responseData.role_held,
                                welcomeName: `${responseData.first_name} ${responseData.last_name}`,
                                homeRegion: responseData.home_region,
                                direct_report: responseData.direct_report,
                                manager: responseData.manager,
                                minions: responseData.minions
                            })
                        );
                        localStorage.setItem("wName", `${responseData.first_name} ${responseData.last_name}`);
                    } else {
                        dispatch(logOut());
                        alert("You don't have any permissions.");
                    }
                }
            });
    };
}

function getUserCommons(hasToRefresh = null) {
    return async () => {
        const urls = ["users/nz_regions/", "users/companies/"];

        await Promise.all(urls.map(url => axiosInstance.get(url))).then(values => {
            const [regionResponse, companiesResponse] = values;
            localStorage.setItem("regions", JSON.stringify(regionResponse.data));
            localStorage.setItem("allCompanies", JSON.stringify(companiesResponse.data));
        });

        if (hasToRefresh) {
            window.location.reload();
        }
    };
}

function getFileCategory() {
    return async (dispatch, getState) => {
        const response = await axiosInstance.get(`common-fire/job/uploaded_file_category/`);
        dispatch(setFileCategory(response.data));
    };
}

function checkPermission() {
    return async (dispatch, getState) => {
        const {
            user: { permissions }
        } = getState();

        if (permissions.job === 0) {
            const response = await axiosInstance.get(`users/user_access_detail/`);

            const responseData = await response.data;
            if (permissions.hr) {
                await dispatch(hrActions.getTotalHolidayByRegion(responseData.home_region));
            }
            const currentComObj = responseData.authorised_company.filter(company => company.brand === 2 && company.display_region === "True")[0];
            dispatch(
                setPersonPermission({
                    currentComObj,
                    permissions: responseData.user_app_permission,
                    companies: responseData.authorised_company.filter(company => company.display_region === "True"),
                    userId: responseData.id,
                    role: responseData.role_held,
                    welcomeName: `${responseData.first_name} ${responseData.last_name}`,
                    homeRegion: responseData.home_region,
                    direct_report: responseData.direct_report,
                    manager: responseData.manager,
                    minions: responseData.minions
                })
            );
            return responseData;
        }
    };
}

function switchCompany(company) {
    return async (dispatch, getState) => {
        const {
            router: {
                location: { pathname }
            }
        } = getState();
        dispatch(setCompany(company));
        window.location.replace(`/${pathname.split("/")[1]}`);
    };
}

function getResources() {
    return async dispatch => {
        const resourcesResponse = await axiosInstance.get(`crm/slim_entity/`);
        const resourcesList = resourcesResponse.data;
        dispatch(setResources(resourcesList));
        return resourcesList;
    };
}

function getGlobalUsers() {
    return async (dispatch, getState) => {
        const {
            user: { globalUsers }
        } = getState();
        try {
            if (!globalUsers) {
                const userResponse = await axiosInstance.get(`users/slim_users/fire/`);
                dispatch(setGlobalUSers(userResponse.data));
                return userResponse.data;
            } else {
                return globalUsers;
            }
        } catch (error) {
            console.log(error.response);
        }
    };
}

function getSupervisors() {
    return async (dispatch, getState) => {
        const {
            user: { supervisors }
        } = getState();
        try {
            if (!supervisors) {
                const supervisorsResponse = await axiosInstance.get(`users/supervisor_users/fire/`);
                const supervisorList = supervisorsResponse.data;
                dispatch(setSupervisors(supervisorList));
                return supervisorList;
            } else {
                return supervisors;
            }
        } catch (error) {
            console.log(error.response);
        }
    };
}

function getSalaryStaff() {
    return async () => {
        try {
            const staffLeaveEventsResponse = await axiosInstance.get(`users/salary_staff/`);
            return staffLeaveEventsResponse.data;
        } catch (error) {
            console.log(error.response);
            return [];
        }
    };
}

// initial states

const initialState = {
    isLoggedIn: Cookies.get("ref_token") ? true : false,
    permissions: { job: 0, operations: 0 },
    welcomeName: localStorage.getItem("wName") || "",
    companies: localStorage.getItem("companies") || "[{}]",
    currentCompany: localStorage.getItem("currentCompany") || "fire-akl/",
    companyPrefix: localStorage.getItem("companyPrefix") || "",
    isMobile: detectMobile(),
    resourcesList: []
};

// reducer

function reducer(state = initialState, action) {
    switch (action.type) {
        case SAVE_TOKEN:
            return applySaveToken(state, action);
        case LOG_OUT:
            return applyLogout(state);
        case CHECK_PERMISSIONS:
            return applyPermission(state, action);
        case SET_COMPANY:
            return applySetCompany(state, action);
        case NOTIFY_NOT_LOGGED_IN:
            return applyNotifyNotLoggedIn(state, action);
        case SET_RESOURCES:
            return applyResouces(state, action);
        case SET_GLOBAL_USERS:
            return applyGlobalUsers(state, action);
        case SET_SUPERVISORS:
            return { ...state, supervisors: action.supervisors };
        case SET_FILE_CATEGORY:
            return applyFileCategory(state, action);
        default:
            return state;
    }
}

// reducer function

function applySaveToken(state, action) {
    const {
        token: { permissions, currentCompany, currentRegion, companies, userId, role, companyPrefix }
    } = action;
    const stringfiedCompanies = JSON.stringify(companies);
    localStorage.setItem("currentCompany", `${currentCompany}/`);
    localStorage.setItem("currentRegion", currentRegion);
    localStorage.setItem("companies", stringfiedCompanies);
    localStorage.setItem("companyPrefix", companyPrefix);
    const permissionsRedefined = permissions.reduce((a, b) => {
        a[b.app_permission_string.split("-")[0].toLowerCase()] = b.permission_level;
        return a;
    }, {});
    return {
        ...state,
        userId,
        isLoggedIn: true,
        permissions: permissionsRedefined,
        currentCompany: `${currentCompany}/`,
        companies: stringfiedCompanies,
        companyPrefix,
        ...setRole(role)
    };
}

function applyPermission(state, action) {
    const {
        level: { permissions, companies, userId, role, welcomeName, homeRegion, direct_report, manager, minions }
    } = action;
    const { currentCompany } = state;
    const stringfiedCompanies = JSON.stringify(companies);
    localStorage.setItem("companies", stringfiedCompanies);
    const permissionsRedefined = permissions.reduce((a, b) => {
        a[b.app_permission_string.split("-")[0].toLowerCase()] = b.permission_level;
        return a;
    }, {});
    localStorage.setItem("companies", stringfiedCompanies);
    return {
        ...state,
        permissions: permissionsRedefined,
        companies: stringfiedCompanies,
        userId,
        welcomeName,
        homeRegion,
        direct_report,
        manager,
        minions,
        menuList: [
            {
                name: "Jobs",
                link: "/jobs",
                style: "jobs",
                icon: "wrench",
                visibility: permissionsRedefined.job > 0
            },
            {
                name: "Rostering",
                link: "/tasks",
                icon: "align-left",
                visibility: permissionsRedefined.maintenance > 0,
                style: "tasks"
            },
            {
                name: "Ops",
                link: "/operations",
                icon: "clipboard",
                visibility: permissionsRedefined.operations > 0,
                style: "operation"
            },
            {
                name: "CRM",
                link: "/crm",
                icon: "user",
                visibility: permissionsRedefined.crm > 0 && currentCompany !== "fire-wbop/",
                style: "crm"
            },
            {
                name: "Stock",
                link: "/stock",
                icon: "warehouse",
                visibility: permissionsRedefined.stock > 0 && currentCompany !== "fire-wbop/",
                style: "stock"
            },
            {
                name: "HR",
                link: "/humanresources",
                icon: "user-circle",
                visibility: permissionsRedefined.hr > 0 && currentCompany !== "fire-wbop/",
                style: "hr"
            }
        ],
        ...setRole(role)
    };
}

function applyLogout(state, action) {
    Cookies.remove("acc_token");
    Cookies.remove("ref_token");
    const currentURL = window.location.href;
    if (currentURL.search(/user\/changepassword/) >= 0) {
        history.goBack();
    }
    return {
        ...state,
        isLoggedIn: false,
        acc_token: ""
    };
}

function applySetCompany(state, action) {
    const { company } = action;
    company.companyValue = company.companyValue === "company-tmpl" ? "" : company.companyValue;
    localStorage.setItem("currentCompany", `${company.companyValue}/`);
    localStorage.setItem("currentRegion", company.companyName);
    localStorage.setItem("companyPrefix", company.prefix);
    return {
        ...state,
        currentCompany: `${company.companyValue}/`,
        currentRegion: company.companyName,
        companyPrefix: company.prefix
    };
}

function applyNotifyNotLoggedIn(state, action) {
    return {
        ...state,
        notFoundUser: { value: true, timestamp: new Date() }
    };
}

function applyResouces(state, action) {
    const { resourcesList } = action;
    return {
        ...state,
        resourcesList
    };
}

function applyGlobalUsers(state, action) {
    const { globalUsers } = action;
    return {
        ...state,
        globalUsers
    };
}

function applyFileCategory(state, action) {
    const { file_category } = action;
    return {
        ...state,
        file_category
    };
}

// export

const actionCreators = {
    login,
    logOut,
    checkPermission,
    switchCompany,
    getResources,
    getUserCommons,
    getGlobalUsers,
    getSalaryStaff,
    getSupervisors,
    getFileCategory
};

export { actionCreators };

// reducer export
export default reducer;
