import React, { memo } from "react";
import { MDBCard, MDBCardHeader, MDBCardBody, MDBRow, MDBCol } from "louis-mdbreact";
import styles from "shared/details.scss";

const AdminDetailCard = ({ difference_signed_off_approved, difference_emailed_date, legacy_job_id, final_bill_date, onChangeValue, isDisabled }) => {
    return (
        <MDBCard className={styles.card}>
            <MDBCardHeader>Admin Details</MDBCardHeader>
            <MDBCardBody>
                <MDBRow>
                    <MDBCol sm="6" className="mb-2">
                        <label htmlFor="">Sign Off As Approved</label>
                        <input
                            className="form-control"
                            type="date"
                            id="difference_signed_off_approved"
                            name="difference_signed_off_approved"
                            value={difference_signed_off_approved || ""}
                            onChange={onChangeValue}
                            disabled={!isDisabled}
                        />
                    </MDBCol>
                    <MDBCol sm="6" className="mb-2">
                        <label htmlFor="">Difference Emailed Date</label>
                        <input
                            className="form-control"
                            type="date"
                            id="difference_emailed_date"
                            name="difference_emailed_date"
                            value={difference_emailed_date || ""}
                            onChange={onChangeValue}
                            disabled={!isDisabled}
                        />
                    </MDBCol>
                </MDBRow>
                <MDBRow>
                    <MDBCol sm="6" className="mb-2">
                        <label htmlFor="">Legacy Job No.</label>
                        <input
                            className="form-control"
                            type="text"
                            id="legacy_job_id"
                            name="legacy_job_id"
                            value={legacy_job_id || ""}
                            onChange={onChangeValue}
                            disabled={!isDisabled}
                        />
                    </MDBCol>
                    <MDBCol sm="6">
                        <label htmlFor="">Base Contract Billing Completed</label>
                        <input
                            className="form-control"
                            type="date"
                            id="final_bill_date"
                            name="final_bill_date"
                            value={final_bill_date || ""}
                            onChange={onChangeValue}
                            disabled={!isDisabled}
                        />
                    </MDBCol>
                </MDBRow>
            </MDBCardBody>
        </MDBCard>
    );
};

export default memo(AdminDetailCard);
