import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import TransferredList from "components/Stock/TransferredList";
import TransferGoods from "components/Stock/NewTransfer";
import TransferredView from "components/Stock/TransferredView";
import { useSelector } from "react-redux";
import ReportList from "components/Stock/ReportList";
import ReportView from "components/Stock/ReportView";

const ReportsRoute = () => {
    const {
        user: { permissions }
    } = useSelector(state => state);
    return (
        <Switch>
            <Route
                exact
                path="/stock"
                component={permissions.stock === 3 ? TransferGoods : TransferredList}
            />
            <Route exact path="/stock/transferred" component={TransferredList} />
            {permissions.stock === 4 || permissions.stock === 3 ? (
                <Route exact path="/stock/transferred/new" component={TransferGoods} />
            ) : (
                <Redirect from="/stock/transferred/new" to="/stock/transferred" />
            )}
            <Route exact path="/stock/transferred/:id" component={TransferredView} />
            {permissions.stock === 4 ? (
                <Route exact path="/stock/reports" component={ReportList} />
            ) : (
                <Redirect from="/stock/reports" to="/stock/transferred/new" />
            )}
            {permissions.stock === 4 ? (
                <Route exact path="/stock/reports/:id" component={ReportView} />
            ) : (
                <Redirect from="/stock/reports/:id" to="/stock/transferred/new" />
            )}
        </Switch>
    );
};

export default ReportsRoute;
