import { connect } from "react-redux";
import Container from "./container";
import { actionCreators as jobActions } from "redux/modules/jobs";
import { getSlimJobFilteredByBranch } from "redux/reducers";

const mapStateToProps = (state, ownProps) => {
    const {
        user: { companyPrefix, permissions }
    } = state;

    return {
        jobList: getSlimJobFilteredByBranch(state),
        prefixText: companyPrefix,
        permissions,
        currentRegion: localStorage.getItem("currentRegion")
    };
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        getSlimJob: (isMine, isCurrent, filter) => {
            dispatch(jobActions.getSlimJobs(isMine, isCurrent, filter));
        },
        getJobCommons: () => {
            dispatch(jobActions.getJobCommons(true));
        }
    };
};

const mergeProps = (mapProps, dispatchProps, ownProps) => {
    const { getJobCommons } = dispatchProps;
    return {
        ...mapProps,
        ...dispatchProps,
        ...ownProps,
        businessCategory: JSON.parse(localStorage.getItem("category")) || getJobCommons()
    };
};

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(Container);
