import React from "react";
import styles from "./styles.scss";
import { withRouter } from "react-router";

const ArrowBack = ({ history, onClick }) => {
    return (
        <button type="button" onClick={onClick || history.goBack} className={styles.arrowBack}>
            <span className={styles.bar}></span>
        </button>
    );
};

export default withRouter(ArrowBack);
