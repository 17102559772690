import dayjs from "shared/dayjs";

const getStartEndDate = ({ startDate, endDate }) => {
    let start = dayjs(startDate);
    const end = dayjs(endDate);
    const arr = [];
    while (start.isBefore(end) || start.isSame(end)) {
        arr.push(start);
        start = start.add(1, "day");
    }
    return arr.map(date => {
        return {
            id: Number(date),
            yearMonth: date.format("YYYY-MM"),
            dt: date.date(),
            day: date.format("ddd"),
            fullDate: date.format("YYYY-MM-DD")
        };
    });
};

const getColumns = ({ startDate, endDate }) => {
    return getStartEndDate({ startDate, endDate }).reduce((total, current) => {
        if (current.yearMonth === total[total.length - 1]?.label) {
            total[total.length - 1].colspan++;
        } else {
            total.push({
                id: current.yearMonth,
                label: current.colspan <= 2 ? current.yearMonth.split("-")[1] : current.yearMonth,
                align: "center",
                colspan: 1,
                style: {
                    borderRight: "1px solid rgba(224, 224, 224, 1)",
                    background: "white"
                }
            });
        }
        return total;
    }, []);
};

const getContainersWidth = () => {
    const tableContainer = document.getElementById("table-container");
    const calendarTable = document.getElementById("calendar-table");
    return { tableContainer, calendarTable };
};

const addNewEvents = (current, leaveEvents) => {
    const merged = current
        .map(curr => {
            const matchingItem = leaveEvents.find(newEvent => newEvent.id === curr.id);
            if (matchingItem) {
                return { ...curr, dates: [...curr.dates, ...matchingItem.dates] };
            } else {
                return curr;
            }
        })
        .concat(leaveEvents.filter(newEvent => !current.find(curr => curr.id === newEvent.id)));
    return merged;
};

export { getColumns, getStartEndDate, getContainersWidth, addNewEvents };
