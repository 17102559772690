import React from "react";
import Typography from "@mui/material/Typography";
import styles from "./styles.scss";

export default function MouseOverPopover({ text }) {
    return (
        <div className={styles.ellipsis}>
            <Typography className="mx-2">{text}</Typography>
            <Typography className={styles.hoverText}>{text}</Typography>
        </div>
    );
}
