import React, { useContext, useEffect, useState, memo } from "react";
import { IconButton, Stack, Chip } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import dayjs from "shared/dayjs";
import { ModalContext } from "../TaskContext";
import { formatDateToTime, timeDiff } from "shared/Utils";
import { useSelector } from "react-redux";
import { axiosInstance } from "shared/axiosInst";
import LoginIcon from "@mui/icons-material/Login";
import LogoutIcon from "@mui/icons-material/Logout";
import LunchDiningIcon from "@mui/icons-material/LunchDining";
import ColoredChip from "../TimeApprove/ColoredChip";
import Decisions from "../TimeApprove/Decisions";
import RoleDot from "components/modals/RosteringModal/RoleDot";

const EARLY_HOUR = 6;
const LATE_HOUR = 17;
const FORTY_FIVE_MINUTES = 2700000;

const ListItem = ({ list, index }) => {
    const [longLunch, setLongLunch] = useState(null);
    const [overTime, setOverTime] = useState({ start: false, end: false });

    useEffect(() => {
        const startHour = dayjs(list.starting_timestamp).get("h");
        const startMin = dayjs(list.starting_timestamp).get("m");
        const endHour = dayjs(list.ending_timestamp).get("h");
        const endMin = dayjs(list.ending_timestamp).get("m");
        if (startHour === EARLY_HOUR || (startHour === LATE_HOUR && startMin > 0)) {
            setOverTime(current => ({ ...current, start: true }));
        }
        if (endHour === EARLY_HOUR || (endHour === LATE_HOUR && endMin > 0)) {
            setOverTime(current => ({ ...current, end: true }));
        }
    }, [list.starting_timestamp, list.ending_timestamp]);
    const { setModalData } = useContext(ModalContext);
    const [isDecided, setIsDecided] = useState(false);
    const {
        user: { currentCompany, userId, manager },
        tasks: { timecloudAction }
    } = useSelector(state => state);

    useEffect(() => {
        if (list.decisions?.length > 0) {
            if (list.decisions.find(decision => decision.decision === 2)) {
                const decisions = list.decisions.find(decision => decision.decision === 2);
                setIsDecided(timecloudAction?.find(action => action.id === decisions.decision));
                return;
            } else {
                const decisions = list.decisions.find(
                    decision => decision.decision_maker === userId || decision.decision_maker === manager?.manager_id
                );
                if (timecloudAction?.length > 0 && decisions) {
                    setIsDecided(timecloudAction?.find(action => action.id === decisions.decision));
                }
            }
        }
    }, [list.decisions, userId, timecloudAction, manager.manager_id]);

    useEffect(() => {
        if (list.mjs_id === 6) {
            const diff = dayjs(list.ending_timestamp).diff(list.starting_timestamp);
            if (diff > FORTY_FIVE_MINUTES) {
                setLongLunch(true);
            } else {
                setLongLunch(false);
            }
        } else {
            setLongLunch(null);
        }
    }, [list.starting_timestamp, list.ending_timestamp, list.mjs_id]);

    const onDetailClick = id => {
        setModalData(null);
        axiosInstance.get(`${currentCompany}maintenance/time_tracking/${id}/`).then(value => {
            setModalData(value.data);
        });
    };
    return (
        <>
            <td data-th="Worker">{list.worker_name}</td>
            <td data-th="Job">
                {`${list.job_id}-${list.job_region} (${list.job_name})`}
                <Stack direction="row" spacing={1}>
                    {list.start_job_punch_proximity && <ColoredChip icon={<LoginIcon />} distance={list.start_job_punch_proximity} />}
                    {list.end_job_punch_proximity && <ColoredChip icon={<LogoutIcon />} distance={list.end_job_punch_proximity} />}
                </Stack>
            </td>
            <td data-th="MJS">
                {`MJS-${list.mjs_id} (${list.mjs_note})`} {longLunch !== null && <LunchDiningIcon color={longLunch ? "error" : "success"} />}
            </td>
            <td data-th="Supervisor">{list.supervisor_name || "-"}</td>
            <td data-th="Date">{list.starting_timestamp ? dayjs(list.starting_timestamp).format("DD/MM/YYYY") : "-"}</td>
            <td data-th={"Time"}>
                {list.starting_timestamp ? (
                    <div>
                        <Stack direction="row" alignItems="center">
                            <span>{timeDiff(list.starting_timestamp, list.ending_timestamp)}</span>{" "}
                            {list.previous_revision ? <RoleDot color="primary" sx={{ marginLeft: 0.7 }} /> : ""}
                        </Stack>
                        <Stack direction="row" spacing={0.5}>
                            {list.starting_timestamp && (
                                <Chip size="small" color={overTime.start ? "warning" : "default"} label={formatDateToTime(list.starting_timestamp)} />
                            )}
                            <span>~</span>
                            {list.ending_timestamp && (
                                <Chip size="small" color={overTime.end ? "warning" : "default"} label={formatDateToTime(list.ending_timestamp)} />
                            )}
                        </Stack>
                    </div>
                ) : list.total_hours ? (
                    `${list.total_hours} hrs`
                ) : (
                    "-"
                )}
            </td>
            <td data-th={"Details"} className={"text-center"}>
                {isDecided ? (
                    <div>
                        <IconButton
                            color={isDecided.id === 1 ? "success" : "error"}
                            sx={{ width: "inherit !important" }}
                            onClick={_ => {
                                onDetailClick(list.id);
                            }}
                        >
                            <InfoIcon />
                        </IconButton>{" "}
                        {`${isDecided.name}${isDecided.id === 1 ? "d" : "ed"}`}
                    </div>
                ) : (
                    <div>
                        <IconButton
                            color="primary"
                            sx={{ width: "inherit !important" }}
                            onClick={_ => {
                                onDetailClick(list.id);
                            }}
                        >
                            <InfoIcon />
                        </IconButton>{" "}
                        Details
                    </div>
                )}

                <Decisions decisions={list.decisions} />
            </td>
        </>
    );
};

export default memo(ListItem);
