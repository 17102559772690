import React, { useState, useEffect, useContext } from "react";
import { getDateList } from "shared/Utils";
import { TableVirtuoso } from "react-virtuoso";
import DateList from "./DateList";
import { useSelector } from "react-redux";
import WorkerList from "./WorkerList";
import scheduleStyle from "./styles.scss";
import { RosterCalendarContext, RosterContext } from "../TaskContext";
import { Button, Stack } from "@mui/material";
// import dayjs from "dayjs";
// import { axiosInstance } from "shared/axiosInst";

const VirtualTable = ({ dateRange }) => {
    const {
        crm: { skinnyRoleList },
        user: { userId, isRosterer }
    } = useSelector(state => state);
    const { assignedList } = useContext(RosterCalendarContext);
    const { isInRostering } = useContext(RosterContext);
    const [workersList, setWorkersList] = useState([]);
    const [seeAll, setSeeAll] = useState(false);
    // const [hoursWorked, setHoursWorked] = useState([]);
    useEffect(() => {
        if (skinnyRoleList?.length > 0) {
            if (isInRostering) {
                if (isRosterer) {
                    setWorkersList(skinnyRoleList.filter(worker => worker.in_timecloud));
                } else {
                    if (seeAll) {
                        setWorkersList(skinnyRoleList.filter(worker => worker.in_timecloud));
                    } else {
                        setWorkersList(skinnyRoleList.filter(worker => worker.in_timecloud && userId === worker.gru));
                    }
                }
            } else {
                const assignedWorkers = assignedList.reduce((workerIDs, current) => {
                    const existed = workerIDs.find(worker => worker === current.assigned_labour);
                    if (!existed) {
                        workerIDs.push(current.assigned_labour);
                    }
                    return workerIDs;
                }, []);
                if (assignedWorkers.length === 0) {
                    setWorkersList([{ id: 0, in_timecloud: true, name: "No Assignment", roles: [] }]);
                } else {
                    setWorkersList(
                        skinnyRoleList
                            .filter(worker => worker.in_timecloud)
                            .filter(worker => assignedWorkers.find(assigned => worker.id === assigned))
                    );
                }
            }
        } else {
            setWorkersList([]);
        }
    }, [skinnyRoleList, assignedList, isInRostering, userId, seeAll, isRosterer]);

    // useEffect(() => {
    //     setHoursWorked([]);
    //     const { startDate, endDate } = dateRange;
    //     const formattedStartDate = dayjs(startDate).format("YYYY-MM-DD");
    //     const formattedEndDate = dayjs(endDate).format("YYYY-MM-DD");
    //     axiosInstance
    //         .get(`fire-akl/maintenance/work_hours_log/?work_date__gte=${formattedStartDate}&work_date__lte=${formattedEndDate}`)
    //         .then(value => {
    //             setHoursWorked(value.data);
    //         });
    // }, [isRosterer, dateRange]);

    return workersList ? (
        <TableVirtuoso
            className={scheduleStyle.scheduleTable}
            totalCount={workersList.length}
            fixedHeaderContent={index => (
                <tr key={index}>
                    <th style={{ position: "sticky", left: 0, minWidth: 150, width: "15%", zIndex: 3 }}>
                        <Stack gap={1}>
                            <span>Employees</span>
                            {isInRostering && !isRosterer && (
                                <Button size="small" color="secondary" variant="contained" onClick={e => setSeeAll(!seeAll)}>
                                    {seeAll ? "team only" : "See all"}
                                </Button>
                            )}
                        </Stack>
                    </th>
                    {getDateList(dateRange).map(date => (
                        <DateList key={date.d} date={date} />
                    ))}
                </tr>
            )}
            itemContent={index => {
                const worker = workersList[index];
                // const totalHours = hoursWorked
                //     .filter(hour => hour.entity === worker.id)
                //     .reduce((total, current) => total + Number(current.hours_worked) + Number(current.overtime_hours_worked), 0);
                return <WorkerList worker={worker} dateRange={dateRange} />;
            }}
        />
    ) : (
        <div></div>
    );
};

export default VirtualTable;
