import React from "react";
import styles from "shared/listStyles.scss";
import CreateNewButton from "components/CreateNewButton";
import Loading from "components/Loading";
import { MDBDataTable, MDBBtn, MDBIcon } from "louis-mdbreact";
import { Link } from "react-router-dom";

const Diary = props => {
    const {
        normalView: { diary },
        toggleChangeView
    } = props;
    const data = !diary
        ? {
              columns: [
                  { label: "No.", field: "id", sort: "asc" },
                  { label: "Job No.", field: "job", sort: "asc" },
                  { label: "Client", field: "client", sort: "asc" },
                  { label: "Site", field: "site", sort: "asc" },
                  { label: "Supervisor", field: "supervisor", sort: "asc" },
                  { label: "QS", field: "qs", sort: "asc" },
                  { label: "Date", field: "date", sort: "asc" },
                  { label: "Details", field: "details" }
              ],
              rows:
                  !props.loading &&
                  props.diaryList.map(job => ({
                      id: `${job.id}`,
                      job: job.job,
                      client: job.client_string || "-",
                      site: job.job_name || "-",
                      supervisor: job.supervisor_name_string || "-",
                      qs: job.qs_name_string || "-",
                      date: job.diary_date || "-",
                      details: (
                          <Link to={`/operations/diary/${job.id}`}>
                              <MDBBtn color="primary" className={`${styles.buttonSize} ${styles.noRound}`} value={job.id}>
                                  <MDBIcon icon="info-circle" />
                              </MDBBtn>
                          </Link>
                      )
                  }))
          }
        : {
              columns: [
                  { label: "No.", field: "id", sort: "asc" },
                  { label: "Client", field: "client", sort: "asc" },
                  { label: "Site", field: "site", sort: "asc" },
                  { label: "Supervisor", field: "supervisor", sort: "asc" },
                  { label: "QS", field: "qs", sort: "asc" },
                  { label: "Details", field: "details" }
              ],
              rows:
                  !props.loading &&
                  props.diaryList.map(job => ({
                      id: `${props.prefixText}-${job.id}`,
                      client: job.client_string || "-",
                      site: job.job_name || "-",
                      supervisor: job.internal_supervisor_string || "-",
                      qs: job.internal_qs_string || "-",
                      details: (
                          <MDBBtn color="primary" className={`${styles.buttonSize} ${styles.noRound}`} onClick={props.toggleDiary} value={job.id}>
                              <MDBIcon far icon="calendar-alt" />
                          </MDBBtn>
                      )
                  }))
          };

    return (
        <div className={styles.container}>
            <div className={styles.titleWrap}>
                <h2 className={`${styles.taskTitle}`}>
                    <MDBBtn type="button" color="primary" className={styles.buttonSize} onClick={toggleChangeView} size="sm">
                        <MDBIcon icon={`${diary ? "align-justify" : "layer-group"}`} />
                    </MDBBtn>
                    {!diary ? "SITE DIARY" : props.titleName}
                </h2>
                <CreateNewButton />
                {!diary ? undefined : (
                    <div className={styles.switchWrap}>
                        <div className={`${styles.listSwitch} custom-control custom-switch`}>
                            <input
                                type="checkbox"
                                className="custom-control-input"
                                id="myjob"
                                name="isMine"
                                checked={props.isMine}
                                onChange={props.changeList}
                                readOnly
                            />
                            <label className="custom-control-label" htmlFor="myjob">
                                MY SITE DIARY
                            </label>
                        </div>
                        <div className={`${styles.listSwitch} custom-control custom-switch`}>
                            <input
                                type="checkbox"
                                className="custom-control-input"
                                id="current"
                                name="isCurrent"
                                checked={props.isCurrent}
                                onChange={props.changeList}
                                readOnly
                            />
                            <label className="custom-control-label" htmlFor="current">
                                CURRENT
                            </label>
                        </div>
                    </div>
                )}
            </div>
            {props.loading ? (
                <Loading loadingType="list" />
            ) : (
                <MDBDataTable
                    entriesOptions={[10, 20, 50, 100, 500]}
                    entries={20}
                    responsive
                    borderless
                    striped
                    responsiveSm
                    pagesAmount={5}
                    data={data}
                    searchText={props.searchText}
                    initialPage={props.activePage}
                    onSearch={props.onSearch}
                    onPageChange={props.onPageChange}
                    className={`${!diary ? styles.diaryDataTable : styles.jobsDataTable} ${styles.dataTable}`}
                />
            )}
        </div>
    );
};

Diary.defaultProps = {
    diaryList: []
};

export default Diary;
