import React, { useEffect, useState } from "react";
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { styled } from "@mui/material/styles";
import { getCheckedFilters, getFilteredStageData, getTotalValues } from "./quoteUtils";
import { formatter } from "shared/Utils";
import SummaryTableCell from "./SummaryTableCell";

const useStyles = makeStyles({
    sticky: {
        position: "sticky",
        left: 0,
        background: "white",
        borderRight: "1px solid rgba(224, 224, 224, 1)"
    }
});

const colorCode = "#ede7f6";

const StyledTable = styled(Table)(({ theme }) => ({
    borderCollapse: "separate",
    "& th": {
        backgroundColor: colorCode
    }
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover
    }
}));

const SummaryTable = ({ stages, searchText, companiesList, nonStages }) => {
    const classes = useStyles();
    const [totalValues, setTotalValues] = useState([]);
    useEffect(() => {
        const valueByStage = stages.map(stage => {
            const filtered = getFilteredStageData({ searchText, stageData: stage.data });
            return getTotalValues(filtered);
        });
        const valueByNonStage = nonStages.map(stage => {
            const filtered = getFilteredStageData({ searchText, stageData: stage.data });
            return getTotalValues(filtered);
        });
        const newCompanyList = companiesList.map(company => ({ ...company, stages: [], nonStages: [] }));
        const transformedData = getCheckedFilters(newCompanyList).map(company => {
            valueByStage.forEach(value => {
                const matched = value.find(val => val.company === company.shortName);
                if (matched) {
                    company.stages.push(matched);
                } else {
                    company.stages.push({ totalValue: 0, probalbleValue: 0, isWon: true });
                }
            });
            valueByNonStage.forEach(value => {
                const matched = value.find(val => val.company === company.shortName);
                if (matched) {
                    company.nonStages.push(matched);
                } else {
                    company.nonStages.push({ totalValue: 0, probalbleValue: 0, isWon: true });
                }
            });
            return company;
        });
        setTotalValues(transformedData);
    }, [stages, companiesList, searchText, nonStages]);
    return (
        <TableContainer component={Paper} sx={{ marginY: "10px" }}>
            <StyledTable size="small">
                <TableHead>
                    <TableRow>
                        <TableCell className={classes.sticky}></TableCell>
                        {stages.map(stage => (
                            <TableCell align="right" key={stage.title}>
                                {stage.title} (Probable)
                            </TableCell>
                        ))}
                        <TableCell align="right">Total (Probable)</TableCell>
                        {nonStages.map(stage => (
                            <TableCell align="right" key={stage.title}>
                                {stage.title} {stage.title !== "Won (calendar month)" && "(Probable)"}
                            </TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {totalValues.map(value => (
                        <StyledTableRow key={value.name}>
                            <TableCell variant="head" component="th" className={classes.sticky}>
                                {value.shortName}
                            </TableCell>
                            {value.stages?.map((stage, i) => (
                                <SummaryTableCell key={i} stage={stage} />
                            ))}
                            <TableCell align="right" sx={{ backgroundColor: colorCode }}>
                                <Typography component="p" variant="subtitle2">
                                    {formatter.format(value.stages?.reduce((total, stage) => (total += stage.totalValue), 0))}
                                </Typography>
                                <Typography color="green" variant="body2">
                                    ({formatter.format(value.stages?.reduce((total, stage) => (total += stage.probalbleValue), 0))})
                                </Typography>
                            </TableCell>
                            {value.nonStages?.map((stage, i) => (
                                <SummaryTableCell key={i} stage={stage} />
                            ))}
                        </StyledTableRow>
                    ))}
                </TableBody>
            </StyledTable>
        </TableContainer>
    );
};

export default SummaryTable;
