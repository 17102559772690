import { connect } from "react-redux";
import Container from "./container";
import { actionCreators as jobActions } from "redux/modules/jobs";
import { getClientContact, getInternalStaffList } from "redux/reducers";
const mapStateToProps = (state, ownProps) => {
    const { type } = ownProps;
    const {
        user: { permissions, isQS, userId, currentCompany },
        jobs: { detailData, baseData, editedAddressData }
    } = state;
    return {
        type,
        editedAddressData,
        currentCompany,
        permissions,
        baseData,
        attendeesList: getInternalStaffList(state),
        clientContact: getClientContact(state),
        currentRegion: localStorage.getItem("currentRegion"),
        detailData,
        isQS,
        userId
    };
};

const mapDispatchProps = (dispatch, ownProps) => {
    return {
        getJobBaseData: () => dispatch(jobActions.getJobBaseData()),
        getClientContactDetails: (option, entityType) => dispatch(jobActions.getClientContactDetails(option, entityType)),
        removeClientContact: () => dispatch(jobActions.removeClientContact()),
        postJob: (data, files) => dispatch(jobActions.postJob(data, ownProps.type, files)),
        getDetailJob: (jobId, listType) => {
            dispatch(jobActions.getDetailJob(jobId, listType));
        },
        generateBluesheet: jobId => dispatch(jobActions.generateBluesheet(jobId)),
        approveRequest: (requestData, items) => dispatch(jobActions.approveRequest(requestData, items)),
        fileDownload: id => {
            dispatch(jobActions.fileDownload(id));
        },
        getFullAddress: id => {
            dispatch(jobActions.getFullAddress(id));
        },
        getJobCommons: () => {
            dispatch(jobActions.getJobCommons(true));
        }
    };
};

export default connect(mapStateToProps, mapDispatchProps)(Container);
