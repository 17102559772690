import React, { useEffect, useState } from "react";
import { FiltersContainer } from "components/FiltersContainer";
import { Label } from "components/HumanResources/CustomComponent";
import { Grid, Stack, Box, TextField, MenuItem, Select } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

const JobFilters = ({ isOpen, filters, setFilters }) => {
    const [hasFilter, setHasFilter] = useState(false);
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up("sm"));
    const businessCartegory = JSON.parse(localStorage.getItem("category"));
    const priceType = JSON.parse(localStorage.getItem("priceType"));
    useEffect(() => {
        const filter = Object.keys(filters).reduce((total, current) => {
            if (current === "isAdminOpen") return false;
            return filters[current] || total;
        }, false);
        setHasFilter(filter);
    }, [filters]);

    const onValueChange = ({ event, filterName }) => {
        const value = event.target.value === "on" ? event.target.checked : event.target.value;
        const newFilter = { ...filters, [filterName]: value };
        setFilters(newFilter);
        localStorage.setItem("jobAdminSetting", JSON.stringify(newFilter));
    };

    const clearFilters = () => {
        setFilters({ isAdminOpen: isOpen });
        localStorage.setItem("jobAdminSetting", JSON.stringify({ isAdminOpen: isOpen }));
    };

    return (
        <FiltersContainer isOpen={isOpen} hasFilter={hasFilter} clearFilters={clearFilters} title="Job Filters">
            <Grid container spacing={2}>
                <Grid item xs={12} sm={12}>
                    <Label>Job Creation Date</Label>
                    <Stack direction={matches ? "row" : "column"} alignItems="flex-start" spacing={2}>
                        <TextField
                            size="small"
                            type="date"
                            fullWidth
                            value={filters.job_creation_date__gte || ""}
                            InputLabelProps={{ shrink: true }}
                            onChange={event => {
                                onValueChange({ event, filterName: "job_creation_date__gte" });
                            }}
                            label="Start"
                        />

                        {matches && (
                            <Box
                                component="p"
                                sx={{
                                    paddingTop: "9px",
                                    fontWeight: 400,
                                    fontSize: "1rem",
                                    lineHeight: 1.5,
                                    letterSpacing: "0.00938em"
                                }}
                            >
                                &#8211;
                            </Box>
                        )}
                        <TextField
                            size="small"
                            type="date"
                            fullWidth
                            value={filters.job_creation_date__lte || ""}
                            InputLabelProps={{ shrink: true }}
                            onChange={event => {
                                onValueChange({ event, filterName: "job_creation_date__lte" });
                            }}
                            label="End"
                        />
                    </Stack>
                </Grid>
                <Grid item sm={6} xs={12} md={3}>
                    <Label htmlFor="jobType">Job Type</Label>
                    <Select
                        size="small"
                        fullWidth
                        displayEmpty
                        id="jobType"
                        onChange={event => {
                            onValueChange({ event, filterName: "business_category" });
                        }}
                        value={filters.business_category || ""}
                    >
                        <MenuItem value="">Select...</MenuItem>
                        {businessCartegory?.map((category, index) => (
                            <MenuItem key={index} value={category.id}>
                                {category.name}
                            </MenuItem>
                        ))}
                    </Select>
                </Grid>
                <Grid item sm={6} xs={12} md={3}>
                    <Label htmlFor="priceType">Price type</Label>
                    <Select
                        size="small"
                        fullWidth
                        displayEmpty
                        id="priceType"
                        onChange={event => {
                            onValueChange({ event, filterName: "price_type" });
                        }}
                        value={filters.price_type || ""}
                    >
                        <MenuItem value="">Select...</MenuItem>
                        {priceType.map((type, index) => (
                            <MenuItem key={index} value={type.id}>
                                {type.name}
                            </MenuItem>
                        ))}
                    </Select>
                </Grid>
                <Grid item sm={6} xs={12} md={3}>
                    <Label htmlFor="allocated">allocated</Label>
                    <Select
                        size="small"
                        fullWidth
                        displayEmpty
                        id="allocated"
                        onChange={event => {
                            onValueChange({ event, filterName: "allocated" });
                        }}
                        value={filters.allocated || ""}
                    >
                        <MenuItem value="">All</MenuItem>
                        <MenuItem value="true">Yes</MenuItem>
                        <MenuItem value="false">No</MenuItem>
                    </Select>
                </Grid>
                <Grid item sm={6} xs={12} md={3}>
                    <Label htmlFor="survey_job">survey job</Label>
                    <Select
                        size="small"
                        fullWidth
                        displayEmpty
                        id="survey_job"
                        onChange={event => {
                            onValueChange({ event, filterName: "internal_surveyor__isnull" });
                        }}
                        value={filters.internal_surveyor__isnull || ""}
                    >
                        <MenuItem value="">All</MenuItem>
                        <MenuItem value="false">Yes</MenuItem>
                        <MenuItem value="true">No</MenuItem>
                    </Select>
                </Grid>
            </Grid>
        </FiltersContainer>
    );
};

export default JobFilters;
