import React, { useEffect, useState } from "react";
import styles from "shared/listStyles.scss";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import CreateNewButton from "components/CreateNewButton";
import { actionCreators as crmActions } from "redux/modules/crm";
import MUIDataTable, { ExpandButton } from "mui-datatables";
import Loading from "components/Loading";
import { Helmet } from "react-helmet";
import { Button, TableRow, TableCell, TextField, Table, TableBody } from "@mui/material";
import { grey } from "@mui/material/colors";
import { styled } from "@mui/material/styles";
import SearchIcon from "@mui/icons-material/Search";

const CustomTable = styled(Table)(({ theme }) => ({
    "& .MuiTableRow-root:last-child td": {
        borderBottom: 0
    }
}));

const CustomDataTable = styled(MUIDataTable)(() => ({
    "& .MuiToolbar-root p": {
        margin: 0
    }
}));

const ROW_PER_PAGE = 50;

const RelationshipList = ({ title, relationshipId }) => {
    const dispatch = useDispatch();
    const [filterSetting, setFilterSetting] = useState(JSON.parse(localStorage.getItem(`${title}Filter`)) || { current: false });
    const [relationshipListData, setRelationshipListData] = useState({ columns: [], rows: [] });
    const [currentSearchText, setSearchText] = useState(sessionStorage.getItem("search") || "");
    const [isLoading, setIsLoading] = useState(false);
    const [activePage] = useState(sessionStorage.getItem("activePage") ? Number(sessionStorage.getItem("activePage")) : 0);

    const onSearch = searchText => {
        sessionStorage.setItem("search", searchText || "");
        setSearchText(searchText);
    };

    const onPageChange = activePage => {
        sessionStorage.setItem("activePage", activePage);
    };

    useEffect(() => {
        const getRelationList = async () => {
            setIsLoading(true);
            const list = await dispatch(crmActions.getSlimCurrentEntity(`?relationships=${relationshipId}`));
            const currentList = filterSetting.current ? list.filter(entity => !!entity.current_relationships.find(rel => rel.includes(title))) : list;
            setRelationshipListData(getRelationshipListData(currentList));
        };
        getRelationList();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, filterSetting, title, relationshipId]);

    const getRelationshipListData = list => {
        setIsLoading(false);
        return {
            columns: [
                {
                    label: "No.",
                    name: "id",
                    options: {
                        customHeadRender: ({ index, ...column }) => {
                            return (
                                <TableCell width={80} key={index}>
                                    {column.label}
                                </TableCell>
                            );
                        }
                    }
                },
                { label: "Name", name: "name" },
                { label: "Children", name: "children", options: { display: false } },
                { label: "Status", name: "status", options: { display: false } },
                {
                    label: "Actions",
                    name: "actions",
                    options: {
                        download: false,
                        searchable: false,
                        sort: false,
                        customHeadRender: ({ index, ...column }) => {
                            return (
                                <TableCell width={80} key={index}>
                                    {column.label}
                                </TableCell>
                            );
                        }
                    }
                }
            ],
            rows: list.map(entity => ({
                id: entity.id,
                name: entity.short_name ? `${entity.name} (${entity.short_name})` : entity.name,
                children: entity.children,
                actions: (
                    <Link to={`/crm/entities/${entity.id}/?from_relationship=${title.toLowerCase()}`}>
                        <Button variant="contained" size="small">
                            detail
                        </Button>
                    </Link>
                ),
                status: entity.pending ? "light-blue lighten-5" : undefined
            }))
        };
    };

    const components = {
        ExpandButton: props => {
            if (props.dataIndex && relationshipListData.rows[props.dataIndex]?.children.length > 0) return <ExpandButton {...props} />;
            return <div style={{ width: "24px" }} />;
        }
    };
    const options = {
        searchAlwaysOpen: true,
        search: false,
        elevation: 0,
        rowsPerPage: ROW_PER_PAGE,
        rowsPerPageOptions: [10, 50, 100],
        expandableRows: true,
        filter: false,
        viewColumns: false,
        print: false,
        page: ROW_PER_PAGE >= relationshipListData.rows.length ? 0 : activePage,
        jumpToPage: true,
        onChangePage: currentPage => {
            onPageChange(currentPage);
        },
        searchText: currentSearchText,
        onSearchChange: searchText => {
            onSearch(searchText);
        },

        customSearch: (searchQuery, currentRow, columns) => {
            const childrenIndex = columns.findIndex(col => col.name === "children");
            const searchText = searchQuery.toLowerCase();
            const childrenText = currentRow[childrenIndex]
                .map(child => child.name)
                .join(" ")
                .toLowerCase();
            return currentRow.some(field => field?.toString().toLowerCase().includes(searchText)) || childrenText.includes(searchText);
        },

        customSearchRender: (searchText, handleSearch, hideSearch, options) => {
            return (
                <>
                    <TextField
                        size="small"
                        placeholder="Search..."
                        InputProps={{
                            "aria-label": options.textLabels.toolbar.search,
                            startAdornment: <SearchIcon />
                        }}
                        value={searchText || ""}
                        onChange={event => handleSearch(event.target.value)}
                        fullWidth={true}
                    />
                </>
            );
        },
        setRowProps: (row, index) => {
            return {
                className: row[3],
                style: { backgroundColor: index % 2 === 0 ? grey[100] : "white" }
            };
        },
        setTableProps: () => {
            return {
                size: "small"
            };
        },
        downloadOptions: {
            filename: `${title}.csv`,
            filterOptions: { useDisplayedColumnsOnly: true }
        },
        selectableRows: "none",
        renderExpandableRow: (rowData, rowMeta) => {
            const colSpan = rowData.length + 1;
            const data = JSON.parse(JSON.stringify(rowData[2]));
            if (data.length > 0) {
                return (
                    <TableRow sx={{ backgroundColor: grey[200], boxShadow: "inset 3px 3px 5px rgba(0, 0, 0, 0.1)" }}>
                        <TableCell colSpan={colSpan} sx={{ paddingLeft: { sm: 1, xs: 1, md: 7 } }}>
                            <CustomTable size="small">
                                <TableBody>
                                    {data.map(da => (
                                        <TableRow key={da.id}>
                                            <TableCell width={80}>{da.id}</TableCell>
                                            <TableCell>{da.short_name ? `${da.name} (${da.short_name})` : da.name}</TableCell>
                                            <TableCell width={80}>
                                                <Link to={`/crm/entities/${da.id}/?from_relationship=${title.toLowerCase()}`}>
                                                    <Button variant="contained" size="small">
                                                        detail
                                                    </Button>
                                                </Link>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </CustomTable>
                        </TableCell>
                    </TableRow>
                );
            } else {
                return null;
            }
        }
    };

    const filterChange = e => {
        const newFilterSetting = { ...filterSetting, current: !filterSetting.current };
        setFilterSetting(newFilterSetting);
        localStorage.setItem(`${title}Filter`, JSON.stringify(newFilterSetting));
    };
    return (
        <div className={styles.container}>
            <Helmet>
                <title>CRM | {title}</title>
            </Helmet>
            <div className={styles.titleWrap}>
                <h2 className={`${styles.taskTitle}`}>{title}</h2>
                <CreateNewButton />
                <div className={styles.switchWrap}>
                    <div className={`${styles.listSwitch} custom-control custom-switch`}>
                        <input
                            type="checkbox"
                            className="custom-control-input"
                            id="current"
                            name="current"
                            checked={filterSetting.current}
                            onChange={filterChange}
                            readOnly
                        />
                        <label className="custom-control-label" htmlFor="current">
                            CURRENT
                        </label>
                    </div>
                </div>
            </div>
            {isLoading ? (
                <Loading loadingType="list" />
            ) : (
                <div style={{ marginBottom: "60px" }}>
                    <CustomDataTable
                        options={options}
                        columns={relationshipListData.columns}
                        data={relationshipListData.rows}
                        components={components}
                    />
                </div>
            )}
        </div>
    );
};

export default RelationshipList;
