import { connect } from "react-redux";
import Container from "./container";
import { actionCreators as operationActions } from "redux/modules/operations";
import { withRouter } from "react-router";

const mapStateToProps = (state, ownProps) => {
    const {
        user: { permissions }
    } = state;
    return { permissions };
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        getFormElement: id => dispatch(operationActions.getFormElement(id)),
        downloadImage: id => dispatch(operationActions.fileDownload(id)),
        updateFormData: data => ownProps.updateFormData(data),
        updateFiles: files => ownProps.updateFiles(files)
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Container));
