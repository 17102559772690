import React from "react";
import styles from "shared/modalStyles.scss";
import { MDBTable, MDBTableHead, MDBTableBody, MDBBtn, MDBBtnGroup, MDBIcon, MDBRow, MDBCol } from "louis-mdbreact";
import { Link } from "react-router-dom";
import dayjs from "shared/dayjs";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import RecipientsForm from "components/RecipientsForm";
import DialogContainer from "components/DialogContainer";
import { DialogContent } from "@mui/material";

const OperationalFormModal = props => {
    return (
        <DialogContainer
            open={props.formModal || false}
            handleClose={props.toggleForm}
            fullScreen={false}
            maxWidth="md"
            title="Health & Safety Forms"
            isLoading={props.isLoading}
        >
            <DialogContent className={styles.card}>
                <MDBRow>
                    <MDBCol>
                        <div className="float-right">
                            <FormControlLabel
                                control={<Checkbox onChange={props.onCheckTotal} checked={props.checkTotal} name="check_total" id="check_total" />}
                                label="Check All"
                            />
                        </div>
                    </MDBCol>
                </MDBRow>
                {props.elementChecked && (
                    <RecipientsForm
                        hasSendBtn={true}
                        toggleQA={props.toggleForm}
                        jobInfo={props.selectedJob}
                        subInfo={{ form: props.checkedList }}
                        setupValid={props.setupValid}
                    />
                )}
                <h5>SSSP Checklist</h5>
                <MDBTable small striped className={styles.responsiveTable}>
                    <MDBTableHead>
                        <tr>
                            <th width="1%"></th>
                            <th width="70px">ID</th>
                            <th>Response File(s)</th>
                            <th width="70px" className={styles.textCentre}>
                                Action
                            </th>
                        </tr>
                    </MDBTableHead>
                    <MDBTableBody>
                        {props.nonLogList &&
                            props.nonLogList.map(log => (
                                <tr key={log.id}>
                                    <td data-th="Sending" className="align-middle">
                                        <label htmlFor={`check_${log.id}`} className="mb-0">
                                            <Checkbox
                                                sx={{ padding: "0" }}
                                                onChange={e => props.onOperationFormListCheck(e, "nonLogList")}
                                                name={`check_${log.id}`}
                                                id={`check_${log.id}`}
                                                checked={log.checked}
                                            />
                                        </label>
                                    </td>
                                    <td data-th="ID" className="align-middle">
                                        {log.id}
                                    </td>
                                    <td data-th="File(s)" className="align-middle">
                                        <ul className={styles.fileList}>
                                            {log.files.map(file => (
                                                <li onClick={() => props.fileDownload(file.id)} key={file.id}>
                                                    {file.original_file_name} <MDBIcon icon="file-download" />
                                                </li>
                                            ))}
                                            {log.files.length === 0 && "-"}
                                        </ul>
                                    </td>
                                    <td data-th="Actions" className="align-middle">
                                        <MDBBtnGroup className={styles.buttonGroup}>
                                            <Link to={`/operations/health/${log.id}`}>
                                                <MDBBtn size="sm" color="primary" className={styles.buttonSize}>
                                                    <MDBIcon icon="info-circle" />
                                                </MDBBtn>
                                            </Link>
                                            <MDBBtn
                                                color="success"
                                                size="sm"
                                                value={log.id}
                                                onClick={props.downloadOperationalForm}
                                                className={styles.buttonSize}
                                            >
                                                {log.pending ? (
                                                    <div className="spinner-border spinner-border-sm" role="status">
                                                        <span className="sr-only">Loading...</span>
                                                    </div>
                                                ) : (
                                                    <MDBIcon icon="file-download" />
                                                )}
                                            </MDBBtn>
                                        </MDBBtnGroup>
                                    </td>
                                </tr>
                            ))}
                    </MDBTableBody>
                </MDBTable>
                <h5 className="mt-3">Operational Forms</h5>
                <MDBTable small striped className={styles.responsiveTable}>
                    <MDBTableHead>
                        <tr>
                            <th width="1%"></th>
                            <th width="70px">ID</th>
                            <th>Submitted Date</th>
                            <th>Date</th>
                            <th>Form Type</th>
                            <th width="70px" className={styles.textCentre}>
                                Action
                            </th>
                        </tr>
                    </MDBTableHead>
                    <MDBTableBody>
                        {props.logList &&
                            props.logList.map(log => (
                                <tr key={log.id}>
                                    <td data-th="Sending" className="align-middle">
                                        <label htmlFor={`check_${log.id}`} className="mb-0">
                                            <Checkbox
                                                sx={{ padding: "0" }}
                                                onChange={e => props.onOperationFormListCheck(e, "logList")}
                                                name={`check_${log.id}`}
                                                id={`check_${log.id}`}
                                                checked={log.checked}
                                            />
                                        </label>
                                    </td>
                                    <td data-th="ID" className="align-middle">
                                        {log.id}
                                    </td>
                                    <td data-th="Submit Date" className="align-middle">
                                        {dayjs(log.form_submitted_datetime).format("DD/MM/YYYY")}
                                    </td>
                                    <td data-th="Date" className="align-middle">
                                        {log.date ? dayjs(log.date).format("DD/MM/YYYY") : "-"}
                                    </td>
                                    <td data-th="Form Type" className="align-middle">
                                        {log.form_type_string}
                                    </td>
                                    <td data-th="Actions" className="align-middle">
                                        <MDBBtnGroup className={styles.buttonGroup}>
                                            <Link to={`/operations/health/${log.id}`}>
                                                <MDBBtn size="sm" color="primary" className={styles.buttonSize}>
                                                    <MDBIcon icon="info-circle" />
                                                </MDBBtn>
                                            </Link>
                                            <MDBBtn
                                                color="success"
                                                size="sm"
                                                value={log.id}
                                                onClick={props.downloadOperationalForm}
                                                className={styles.buttonSize}
                                            >
                                                {log.pending ? (
                                                    <div className="spinner-border spinner-border-sm" role="status">
                                                        <span className="sr-only">Loading...</span>
                                                    </div>
                                                ) : (
                                                    <MDBIcon icon="file-download" />
                                                )}
                                            </MDBBtn>
                                        </MDBBtnGroup>
                                    </td>
                                </tr>
                            ))}
                    </MDBTableBody>
                </MDBTable>
            </DialogContent>
        </DialogContainer>
    );
};

export default OperationalFormModal;
