import Container from "./container";
import { connect } from "react-redux";
import { actionCreators as userActions } from "redux/modules/user";

const mapStateToProps = state => {
    const {
        router: {
            location: { pathname }
        },
        user: { permissions, isQS, isAdmin, isSLT, isMobile, menuList }
    } = state;
    return {
        pathname,
        permissions,
        isSLT,
        isQS,
        isAdmin,
        isMobile,
        menuList
    };
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        logout: () => {
            dispatch(userActions.logOut());
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Container);
