const getTotalValues = stageList => {
    const totalValues = stageList
        .filter(stage => !stage.isExpandable)
        .reduce((total, stage) => {
            const matchedCompany = total.find(to => to.company === stage.company);
            if (matchedCompany) {
                matchedCompany.totalValue += Number(stage.quote_value);
                matchedCompany.probalbleValue += Math.round(stage.quote_value * stage.win_chance) / 100;
                matchedCompany.number++;
            } else {
                total.push({
                    company: stage.company,
                    totalValue: Number(stage.quote_value),
                    probalbleValue: Math.round(stage.quote_value * stage.win_chance) / 100,
                    isWon: stage.status_name === "Won",
                    number: 1
                });
            }
            return total;
        }, []);
    return totalValues;
};

const getFilteredStageData = ({ searchText, stageData }) => {
    return searchText
        ? stageData.filter(cur => {
              const currentText = Object.values(cur)
                  .join(" ")
                  .replaceAll(/\d{4}-\d{2}-\d{2}|None/g, "");
              return currentText.toLowerCase().includes(searchText.toLowerCase());
          })
        : stageData;
};

const getSelectedList = filterList => {
    let filteredList = filterList;
    filteredList = filterList.filter(list => list.checked);
    return filteredList;
};

const getCheckedFilters = filterList => {
    let filteredList = filterList;
    if (filterList.filter(list => list.checked).length === 0) {
        filteredList = filterList;
    } else {
        filteredList = filterList.filter(list => list.checked);
    }
    return filteredList;
};

// const quoteBaseURL = http://192.168.33.42:8000/quotes/
const quoteBaseURL = "https://interim.gl.co.nz/quotes/";
const errorMessage = "Something went wrong, please try again later or contact Dev team.";

export { getTotalValues, getFilteredStageData, quoteBaseURL, getCheckedFilters, errorMessage, getSelectedList };
