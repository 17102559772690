import { connect } from "react-redux";
import Container from "./container";
import { withRouter } from "react-router";
import { actionCreators as operationActions } from "redux/modules/operations";
// import { getContactListForSelect } from "redux/reducers";

const mapStateToProps = (state, ownProps) => {
    const {
        user: { isMobile },
        operations: { QAList, PreQAList }
    } = state;
    const { selectedJob, isPre } = ownProps;
    return {
        isMobile,
        selectedJob,
        QAList: isPre ? PreQAList : QAList,
        isPre
    };
};

const mapDispatchToProps = (dispatch, ownProps) => {
    const { isPre } = ownProps;
    return {
        getQAList: id => dispatch(operationActions.getQAList(id, isPre)),
        downloadQAReport: (ids, signal) => dispatch(operationActions.downloadQAReport(ids, signal))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Container));
