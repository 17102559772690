import React, { useState, useRef, useEffect, useCallback } from "react";
import mapboxgl from "mapbox-gl";
import { AddressAutofill } from "@mapbox/search-js-react";
import { Button, DialogContent, Grid, TextField, DialogActions, Collapse } from "@mui/material";
import { axiosInstance } from "shared/axiosInst";
import DialogContainer from "components/DialogContainer";
import { useSelector, useDispatch } from "react-redux";
import { actionCreators as jobActions } from "redux/modules/jobs";

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_KEY;
let marker;

const AddressInput = ({ isManualInput, value, onChange, name, label }) => {
    return (
        <TextField
            fullWidth
            size={!isManualInput ? "medium" : "small"}
            name={name}
            InputLabelProps={{ shrink: true }}
            label={label}
            value={value}
            onChange={onChange}
            disabled={!isManualInput}
        />
    );
};

const AddressMap = ({ isNewAddress, modal, toggle }) => {
    const {
        jobs: { fullAddress }
    } = useSelector(state => state);
    const dispatch = useDispatch();
    const map = useRef(null);
    const [address, setAddress] = useState();
    const emptyAddress = {
        country: "",
        latitude: "",
        longitude: "",
        number: "",
        post_code: "",
        road_name: "",
        suburb: "",
        town_city: ""
    };
    const [manualAddress, setManualAddress] = useState(emptyAddress);
    const [mapContainer, setMapContainer] = useState(null);
    const [isAutoFilled, setIsAutoFilled] = useState(!isNewAddress);
    const [isManualInput, setIsManualInput] = useState(false);

    const dragEnd = useCallback(event => {
        const {
            target: { _lngLat }
        } = event;
        setManualAddress(current => ({ ...current, longitude: _lngLat.lng, latitude: _lngLat.lat }));
    }, []);

    const replacedString = txt => {
        const reg = /\d+\//;
        return String(txt).replace(reg, "");
    };

    const editAddressSubmit = event => {
        if (manualAddress.id) {
            dispatch(jobActions.editAddress(manualAddress));
            toggle();
        } else {
            dispatch(jobActions.editAddress(manualAddress)).then(value =>
                toggle({ value: value.id, label: `${value.number} ${value.road_name} ${value.suburb}` })
            );
        }
    };

    const onInterceptSearch = searchText => {
        return replacedString(searchText);
    };

    useEffect(() => {
        if (!isNewAddress) {
            setAddress(fullAddress);
            setManualAddress(fullAddress);
        }
    }, [fullAddress, isNewAddress]);

    const onRetrieve = features => {
        setIsAutoFilled(true);
        const center = features.features[0].geometry.coordinates;
        const addressProperties = features.features[0].properties;
        setAddress({
            id: address?.id,
            country: addressProperties.country,
            latitude: center[1],
            longitude: center[0],
            number: addressProperties.address_number,
            post_code: addressProperties.postcode,
            road_name: addressProperties.street,
            suburb: addressProperties.locality,
            town_city: addressProperties.address_level1
        });
        setManualAddress({
            id: address?.id,
            country: addressProperties.country,
            latitude: center[1],
            longitude: center[0],
            number: addressProperties.address_number,
            post_code: addressProperties.postcode,
            road_name: addressProperties.street,
            suburb: addressProperties.locality,
            town_city: addressProperties.address_level1
        });
    };

    const onChange = event => {
        const {
            target: { name, value }
        } = event;
        setManualAddress(current => ({ ...current, [name]: value, latitude: "", longitude: "" }));
    };

    useEffect(() => {
        const addMarker = center => {
            marker?.off("dragend", dragEnd);
            marker?.remove();
            const newMarker = new mapboxgl.Marker({ draggable: true }).setLngLat(center).addTo(map.current);
            marker = newMarker;
            marker.on("dragend", dragEnd);
        };

        const createMap = center => {
            const zoom = 17;
            if (map.current) {
                map.current.flyTo({ center, essential: true, zoom });
                addMarker(center);
            } else {
                map.current = new mapboxgl.Map({
                    container: mapContainer,
                    style: "mapbox://styles/mapbox/streets-v12",
                    center,
                    zoom
                });
                addMarker(center);
            }
        };

        if (mapContainer) {
            if (address) {
                if (!address.latitude) {
                    const addressString = `${address.number} ${address.road_name} ${address.suburb} ${address.post_code} ${address.country}`;
                    const replacedAddress = replacedString(addressString);
                    axiosInstance
                        .get(`https://api.mapbox.com/geocoding/v5/mapbox.places/${replacedAddress}.json?access_token=${mapboxgl.accessToken}&limit=1`)
                        .then(geocode => {
                            createMap(geocode.data.features[0].center);
                        });
                } else {
                    createMap([address.longitude, address.latitude]);
                }
            }
        }
    }, [mapContainer, address, dragEnd]);

    useEffect(() => {
        return () => {
            map.current?.remove();
            map.current = null;
        };
    }, []);

    return (
        <DialogContainer open={modal} handleClose={toggle} maxWidth="md" fullScreen={false} title={`${manualAddress?.id ? "Edit" : "New"} Address`}>
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item md={12} sm={12} xs={12}>
                        {!isManualInput && (
                            <AddressAutofill
                                options={{ country: "NZ" }}
                                accessToken={mapboxgl.accessToken}
                                value=""
                                theme={{ cssText: `.Results {z-index:2000}` }}
                                interceptSearch={onInterceptSearch}
                                onRetrieve={onRetrieve}
                            >
                                <TextField fullWidth id="street_no" placeholder="Search..." autoComplete="address-line1" />
                            </AddressAutofill>
                        )}
                    </Grid>
                    <Grid item md={12} sm={12} xs={12} display="flex" justifyContent="flex-end">
                        <Button size="small" variant="outlined" onClick={e => setIsManualInput(!isManualInput)}>
                            {isManualInput ? "Search" : "Manual input"}
                        </Button>
                    </Grid>
                    {(manualAddress?.number || isManualInput) && (
                        <>
                            <Grid item md={6} sm={12} xs={12}>
                                <AddressInput
                                    isManualInput={isManualInput}
                                    name="number"
                                    label="Street No."
                                    value={manualAddress?.number}
                                    onChange={onChange}
                                />
                            </Grid>
                            <Grid item md={6} sm={12} xs={12}>
                                <AddressInput
                                    isManualInput={isManualInput}
                                    name="road_name"
                                    label="Street Name"
                                    value={manualAddress?.road_name}
                                    onChange={onChange}
                                />
                            </Grid>
                            <Grid item md={6} sm={12} xs={12}>
                                <AddressInput
                                    isManualInput={isManualInput}
                                    name="suburb"
                                    label="Suburb"
                                    value={manualAddress?.suburb}
                                    onChange={onChange}
                                />
                            </Grid>
                            <Grid item md={6} sm={12} xs={12}>
                                <AddressInput
                                    isManualInput={isManualInput}
                                    name="town_city"
                                    label="City"
                                    value={manualAddress?.town_city}
                                    onChange={onChange}
                                />
                            </Grid>
                            <Grid item md={6} sm={12} xs={12}>
                                <AddressInput
                                    isManualInput={isManualInput}
                                    name="post_code"
                                    label="Post Code"
                                    value={manualAddress?.post_code}
                                    onChange={onChange}
                                />
                            </Grid>
                            <Grid item md={6} sm={12} xs={12}>
                                <AddressInput
                                    isManualInput={isManualInput}
                                    name="country"
                                    label="Country"
                                    value={manualAddress?.country}
                                    onChange={onChange}
                                />
                            </Grid>
                            <Grid item sm={12} md={12} xs={12}>
                                <Collapse in={isAutoFilled && !isManualInput} sx={{ width: "100%" }} timeout="auto">
                                    <div
                                        style={{ width: "100%", height: "300px" }}
                                        className="map-container"
                                        ref={el => {
                                            setMapContainer(el);
                                        }}
                                    />
                                </Collapse>
                            </Grid>
                        </>
                    )}
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={toggle}>Cancel</Button>
                <Button color="primary" onClick={editAddressSubmit}>
                    {manualAddress?.id ? "Edit" : "Create"}
                </Button>
            </DialogActions>
        </DialogContainer>
    );
};

export default AddressMap;
