import React, { memo, useState, useEffect, useCallback, useMemo } from "react";
import { MDBCard, MDBCardHeader, MDBBtn, MDBCardBody, MDBTable, MDBTableHead, MDBTableBody, MDBIcon, MDBRow, MDBCol } from "louis-mdbreact";
import { useSelector, useDispatch } from "react-redux";
import { actionCreators as crmActions } from "redux/modules/crm";
import styles from "shared/details.scss";
import AsyncSelect from "react-select/async";
import ValidSelect from "components/ValidSelect";
import { axiosInstance } from "shared/axiosInst";
import AddressMap from "components/Jobs/AddressMap";
import { Chip, Stack } from "@mui/material";

const Contact = ({ type: mainType, getSendingData, wasValidated: fromParentValidated, onEditClick, entityDetail }) => {
    const dispatch = useDispatch();
    const { contactCommons } = useSelector(state => state.crm);
    const { currentCompany } = useSelector(state => state.user);
    const [addressModal, setAddressModal] = useState(false);
    const { contact_type, location_type } = contactCommons || {};
    const [wasValidated, setIsValidated] = useState(fromParentValidated);
    const [contacts, setContacts] = useState(
        entityDetail
            ? [
                  {
                      idx: 1,
                      name: "Location",
                      data: entityDetail.location_details.map((location, i) => ({
                          id: location.id,
                          timestamp: Number(new Date()) + i,
                          address: { value: location.address, label: location.address_string },
                          location_types: location.location_types
                      }))
                  },
                  {
                      idx: 2,
                      name: "Email",
                      data: entityDetail.email_addresses.map((address, i) => ({
                          ...address,
                          timestamp: Number(new Date()) + i
                      }))
                  },
                  {
                      idx: 3,
                      name: "Phone Number",
                      data: entityDetail.phone_numbers.map((phone, i) => ({
                          ...phone,
                          timestamp: Number(new Date()) + i
                      }))
                  }
              ]
            : [
                  {
                      idx: 1,
                      name: "Location",
                      data: []
                  },
                  {
                      idx: 2,
                      name: "Email",
                      data: []
                  },
                  {
                      idx: 3,
                      name: "Phone Number",
                      data: []
                  }
              ]
    );
    const keyNames = [
        { idx: 1, input_type: "", name: "address", type: "location_types" },
        { idx: 2, input_type: "email", name: "email_address", type: "contact_types" },
        { idx: 3, input_type: "tel", name: "phone_number", type: "contact_types" }
    ];

    const urls = [
        { idx: 1, url: "entity_location_link" },
        { idx: 2, url: "entity_email_address" },
        { idx: 3, url: "entity_phone_number" }
    ];

    const pattern = useMemo(
        () =>
            // eslint-disable-next-line no-useless-escape
            /^(?:(?:[\w`~!#$%^&*\-=+;:{}'|,?\/]+(?:(?:\.(?:"(?:\\?[\w`~!#$%^&*\-=+;:{}'|,?\/\.()<>\[\] @]|\\"|\\\\)*"|[\w`~!#$%^&*\-=+;:{}'|,?\/]+))*\.[\w`~!#$%^&*\-=+;:{}'|,?\/]+)?)|(?:"(?:\\?[\w`~!#$%^&*\-=+;:{}'|,?\/\.()<>\[\] @]|\\"|\\\\)+"))@(?:[a-zA-Z\d\-]+(?:\.[a-zA-Z\d\-]+)*|\[\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}\])$/gm,
        []
    );

    useEffect(() => {
        setIsValidated(fromParentValidated);
    }, [fromParentValidated]);

    useEffect(() => {
        if (entityDetail) {
            setContacts([
                {
                    idx: 1,
                    name: "Location",
                    data: entityDetail.location_details.map((location, i) => ({
                        id: location.id,
                        timestamp: Number(new Date()) + i,
                        address: { value: location.address, label: location.address_string },
                        location_types: location.location_types
                    }))
                },
                {
                    idx: 2,
                    name: "Email",
                    data: entityDetail.email_addresses.map((address, i) => ({
                        ...address,
                        timestamp: Number(new Date()) + i
                    }))
                },
                {
                    idx: 3,
                    name: "Phone Number",
                    data: entityDetail.phone_numbers.map((phone, i) => ({
                        ...phone,
                        timestamp: Number(new Date()) + i
                    }))
                }
            ]);
        }
    }, [entityDetail]);

    useEffect(() => {
        const getCommon = async () => {
            await dispatch(crmActions.getContactCommons());
        };
        getCommon();
    }, [dispatch]);

    const makeSendingData = useCallback(
        newContacts => {
            newContacts.forEach(contact => {
                if (contact.name === "Location") {
                    const validated = contact.data.reduce((bool, element) => !!(bool && element.address && element.location_types.length > 0), true);
                    getSendingData(
                        contact.name,
                        contact.data.map(da => ({
                            ...da,
                            address: da.address.value,
                            location_types: da.location_types.length > 0 ? da.location_types.map(type => type.id) : []
                        })),
                        validated
                    );
                } else {
                    const validated = contact.data.reduce((bool, element) => {
                        return contact.name === "Email"
                            ? !!(bool && element.email_address && element.email_address.match(pattern) && element.contact_types.length > 0)
                            : !!(bool && element.phone_number && element.contact_types.length > 0);
                    }, true);
                    getSendingData(
                        contact.name,
                        contact.data.map(da => ({
                            ...da,
                            contact_types: da.contact_types.length > 0 ? da.contact_types.map(type => type.id) : []
                        })),
                        validated
                    );
                }
            });
        },
        [getSendingData, pattern]
    );

    useEffect(() => {
        mainType === "new" && makeSendingData(contacts);
    }, [contacts, mainType, makeSendingData]);

    const addContact = idx => {
        const { name, type } = keyNames.find(name => name.idx === idx);
        setContacts(
            contacts.map(contact =>
                idx === contact.idx
                    ? {
                          ...contact,
                          data: [...contact.data, { timestamp: Number(new Date()), [name]: "", [type]: [] }]
                      }
                    : contact
            )
        );
    };
    const editContactType = ({ idx, name, timestamp }, option) => {
        const newContacts = contacts.map(contact =>
            idx === contact.idx
                ? {
                      ...contact,
                      data: contact.data.map(da => (da.timestamp === timestamp ? { ...da, [name]: option || [] } : da))
                  }
                : contact
        );
        setContacts(newContacts);
    };

    const editContact = ({ idx, name, timestamp }, event) => {
        const {
            currentTarget: { value }
        } = event;
        const newContacts = contacts.map(contact =>
            idx === contact.idx
                ? {
                      ...contact,
                      data: contact.data.map(da => (da.timestamp === timestamp ? { ...da, [name]: value } : da))
                  }
                : contact
        );
        setContacts(newContacts);
    };
    const editAddress = ({ idx, timestamp }, option) => {
        const newContacts = contacts.map(contact =>
            idx === contact.idx
                ? {
                      ...contact,
                      data: contact.data.map(da => (da.timestamp === timestamp ? { ...da, address: option } : da))
                  }
                : contact
        );
        setContacts(newContacts);
    };

    const getAddressOptions = async (inputValue, callback) => {
        const addressResponse = await axiosInstance.get(`${currentCompany}job/address/?search=${inputValue}`);
        const addressList = addressResponse.data;
        callback(
            addressList.map(address => ({
                value: address.id,
                label: `${address.number} ${address.road_name} ${address.suburb}`
            }))
        );
    };

    const deleteContact = (idx, timestamp) => {
        const newContacts = contacts.map(contact =>
            contact.idx === idx ? { ...contact, data: contact.data.filter(da => da.timestamp !== timestamp) } : contact
        );
        setContacts(newContacts);
    };

    const submitContact = async event => {
        event.preventDefault();
        setIsValidated(true);
        const validated = contacts.reduce(
            (validate, contact) => {
                let count = validate.count;
                if (contact.name !== "Location") {
                    count += contact.data.length;
                }
                const required =
                    validate.required &&
                    contact.data.reduce((bool, element) => {
                        if (contact.name === "Location") {
                            return !!(bool && element.address && element.location_types.length > 0);
                        } else if (contact.name === "Email") {
                            return !!(bool && element.email_address && element.email_address.match(pattern) && element.contact_types.length > 0);
                        } else {
                            return !!(bool && element.phone_number && element.contact_types.length > 0);
                        }
                    }, true);
                return { count, required };
            },
            { count: 0, required: true }
        );

        if (validated.required) {
            if (validated.count === 0) {
                alert("An entity should have at least either an email address or a phone number.");
                return;
            }
            await Promise.all(
                contacts.map(contact => {
                    const types = contact.name === "Location" ? "location_types" : "contact_types";
                    return contact.data.map(da =>
                        dispatch(
                            crmActions.submitLink(urls.find(url => contact.idx === url.idx).url, entityDetail.id, {
                                ...da,
                                address: contact.name === "Location" ? da.address.value : undefined,
                                [types]: da[types].map(type => type.id)
                            })
                        )
                    );
                })
            );
            onEditClick(1);
        } else {
            alert("Please white down all required field.");
        }
    };

    const submitLink = ({ da, contact, type }) => {
        setIsValidated(true);
        let validated;
        if (contact.name === "Location") {
            validated = !!(da.address && da.location_types.length > 0);
        } else if (contact.name === "Email") {
            validated = !!(da.email_address && da.email_address.match(pattern) && da.contact_types.length > 0);
        } else {
            validated = !!(da.phone_number && da.contact_types.length > 0);
        }
        if (validated) {
            setContacts(
                contacts.map(con =>
                    con === contact
                        ? {
                              ...con,
                              data: con.data.map(data => (data === da ? { ...data, isLoading: true } : data))
                          }
                        : con
                )
            );
            dispatch(
                crmActions.submitLink(
                    urls.find(url => url.idx === contact.idx).url,
                    entityDetail.id,
                    contact.name === "Location"
                        ? {
                              ...da,
                              address: da.address.value,
                              [type]: da[type].map(type => type.id)
                          }
                        : {
                              ...da,
                              [type]: da[type].map(type => type.id)
                          }
                )
            );
        } else {
            alert("Please white down all required field.");
        }
    };

    const directDeleteContact = (contactId, dataId, timestamp) => {
        const count = contacts.reduce((count, contact) => {
            if (contact.name !== "Location") {
                count += contact.data.length;
            }
            return count;
        }, 0);
        if (contactId !== 1 && count <= 1) {
            alert("An entity should have at least either an email address or a phone number.");
            return;
        }
        const confirm = window.confirm("Are you sure you delete this item completely?");
        if (confirm) {
            deleteContact(contactId, timestamp);
            dispatch(crmActions.deleteLink(urls.find(url => url.idx === contactId).url, entityDetail.id, dataId));
        }
    };

    const toggleAddress = address => {
        setAddressModal(!addressModal);
        if (address) {
            setContacts(
                contacts.map(contact => {
                    if (contact.name === "Location") {
                        const emptyAddress = contact.data.find(da => da.address === "");
                        return emptyAddress
                            ? {
                                  ...contact,
                                  data: contact.data.map(da => (emptyAddress.timestamp === da.timestamp ? { ...da, address } : da))
                              }
                            : {
                                  ...contact,
                                  data: [...contact.data, { address, timestamp: Number(new Date()), location_types: [] }]
                              };
                    } else {
                        return contact;
                    }
                })
            );
        }
    };

    return (
        <div className={mainType === "view" && wasValidated ? "was-validated" : ""}>
            {contacts.map(contact => {
                const listType = contact.name === "Location" ? location_type || [] : contact_type || [];
                const { input_type, name, type } = keyNames.find(name => name.idx === contact.idx);
                return (
                    <MDBCard key={contact.idx} className={`${styles.card} ${mainType === "view" ? styles.flatCard : ""}`}>
                        <MDBCardHeader className={styles.cardHeader}>
                            <Stack spacing={1} direction="row">
                                <span>{contact.name} Detail</span>
                                {contact.name === "Location" && (
                                    <Chip
                                        color="success"
                                        size="small"
                                        clickable
                                        aria-label="new address"
                                        onClick={() => setAddressModal(!addressModal)}
                                        label="New Address"
                                    />
                                )}
                            </Stack>
                            <div className="position-absolute" style={{ right: "15px", top: "9px" }}>
                                <MDBBtn color="primary" size="sm" name="note" onClick={() => addContact(contact.idx)}>
                                    add {contact.name}
                                </MDBBtn>
                            </div>
                        </MDBCardHeader>
                        <MDBCardBody>
                            <MDBTable small className={styles.detailResponsiveTable}>
                                <MDBTableHead>
                                    <tr>
                                        <th width="60%">{contact.name}</th>
                                        <th>{contact.name} Type</th>
                                        <th className="text-center" width="100px">
                                            Action
                                        </th>
                                    </tr>
                                </MDBTableHead>
                                <MDBTableBody>
                                    {contact.data.map(da => (
                                        <tr key={da.timestamp}>
                                            <td>
                                                {contact.name === "Location" ? (
                                                    <AsyncSelect
                                                        inputId={`addressInput-${da.timestamp}`}
                                                        placeholder="Search address..."
                                                        value={da.address}
                                                        loadOptions={getAddressOptions}
                                                        onChange={option =>
                                                            editAddress(
                                                                {
                                                                    idx: contact.idx,
                                                                    timestamp: da.timestamp
                                                                },
                                                                option
                                                            )
                                                        }
                                                        // isDisabled={!isDisabled}
                                                        classNamePrefix="recipient-input"
                                                        name="main_adress"
                                                        styles={{
                                                            control: base => {
                                                                if (wasValidated !== undefined) {
                                                                    return {
                                                                        ...base,
                                                                        "&:hover": {
                                                                            borderColor: da.address && wasValidated ? "hsl(0,0%,80%)" : "#dc3545"
                                                                        },
                                                                        borderColor: da.address && wasValidated ? "hsl(0,0%,80%)" : "#dc3545"
                                                                    };
                                                                } else {
                                                                    return {
                                                                        ...base
                                                                    };
                                                                }
                                                            },
                                                            option: base => {
                                                                return {
                                                                    ...base,
                                                                    whiteSpace: "nowrap",
                                                                    overflow: "hidden",
                                                                    textOverflow: "ellipsis"
                                                                };
                                                            }
                                                        }}
                                                    />
                                                ) : (
                                                    <input
                                                        type={input_type}
                                                        className="form-control"
                                                        value={da[name]}
                                                        required
                                                        onChange={e =>
                                                            editContact(
                                                                {
                                                                    idx: contact.idx,
                                                                    name,
                                                                    timestamp: da.timestamp
                                                                },
                                                                e
                                                            )
                                                        }
                                                    />
                                                )}
                                            </td>
                                            <td>
                                                <ValidSelect
                                                    value={da[type]}
                                                    options={listType}
                                                    isMulti
                                                    isValid={wasValidated && da[type].length > 0}
                                                    getOptionValue={option => option.id}
                                                    getOptionLabel={option => option.name}
                                                    onChange={option =>
                                                        editContactType(
                                                            {
                                                                idx: contact.idx,
                                                                name: type,
                                                                timestamp: da.timestamp
                                                            },
                                                            option
                                                        )
                                                    }
                                                />
                                            </td>
                                            <td data-th="Action" className="text-center">
                                                {mainType === "view" && (
                                                    <MDBBtn
                                                        className={styles.buttonSize}
                                                        color={da.id ? "secondary" : "primary"}
                                                        onClick={() => submitLink({ da, contact, type })}
                                                    >
                                                        {da.isLoading ? (
                                                            <div className="spinner-border spinner-border-sm text-white" role="status">
                                                                <span className="sr-only">Loading...</span>
                                                            </div>
                                                        ) : (
                                                            <>{da.id ? <MDBIcon icon="pencil-alt" /> : <MDBIcon far icon="save" />}</>
                                                        )}
                                                    </MDBBtn>
                                                )}
                                                <MDBBtn
                                                    className={styles.buttonSize}
                                                    color="danger"
                                                    onClick={() =>
                                                        da.id
                                                            ? directDeleteContact(contact.idx, da.id, da.timestamp)
                                                            : deleteContact(contact.idx, da.timestamp)
                                                    }
                                                >
                                                    <MDBIcon icon="trash-alt" />
                                                </MDBBtn>
                                            </td>
                                        </tr>
                                    ))}
                                </MDBTableBody>
                            </MDBTable>
                        </MDBCardBody>
                    </MDBCard>
                );
            })}
            {mainType === "view" && (
                <MDBRow>
                    <MDBCol>
                        <div className={`${styles.card} float-right`}>
                            <MDBBtn type="button" color="blue-grey" onClick={() => onEditClick(1)}>
                                cancel
                            </MDBBtn>{" "}
                            <MDBBtn type="button" color="primary" onClick={submitContact}>
                                Save
                            </MDBBtn>
                        </div>
                    </MDBCol>
                </MDBRow>
            )}
            {addressModal && <AddressMap isNewAddress modal={addressModal} toggle={toggleAddress} />}
        </div>
    );
};

export default memo(Contact);
