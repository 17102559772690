import React, { memo, useState } from "react";
import Chip from "@mui/material/Chip";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import MapIcon from "@mui/icons-material/Map";
import AdjustWorkSiteModal from "./JobView/AdjustWorkSiteModal";
import { Stack, Table, TableBody, TableRow, TableCell, Grid } from "@mui/material";

const JobDetail = ({ jobDetail, showMapButton = false, setLatLng }) => {
    const {
        user: { isSupervisor }
    } = useSelector(state => state);
    const [isWorkSiteModal, setIsWorkSiteModal] = useState(false);
    return (
        <>
            <Grid container>
                <Grid item xs={12}>
                    <Table size="small" sx={{ marginTop: 2 }}>
                        <TableBody>
                            <TableRow>
                                <TableCell component="th" width="30%">
                                    Job
                                </TableCell>
                                <TableCell width="70%">
                                    {jobDetail.id}{" "}
                                    <Link to={`/jobs/${jobDetail.id}`}>
                                        <Chip size="small" label="GO" color="primary" clickable />
                                    </Link>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell component="th">Client</TableCell>
                                <TableCell>{jobDetail.client_string || jobDetail.resource_string || "-"}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell component="th">Project Title</TableCell>
                                <TableCell>{jobDetail.job_name || "-"}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell component="th">QS</TableCell>
                                <TableCell>{jobDetail.internal_qs_string || "-"}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell component="th">Supervisor</TableCell>
                                <TableCell>
                                    <Stack direction="row" spacing={1}>
                                        <span>{jobDetail.internal_supervisor_string || "-"}</span>
                                        {isSupervisor && !jobDetail.latitude && showMapButton && (
                                            <Chip
                                                onClick={e => setIsWorkSiteModal(!isWorkSiteModal)}
                                                size="small"
                                                icon={<MapIcon />}
                                                label="Adjust Work Site"
                                                clickable
                                                color="warning"
                                            />
                                        )}
                                    </Stack>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell component="th">Surveyor</TableCell>
                                <TableCell>{jobDetail.internal_surveyor_string || "-"}</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </Grid>
            </Grid>
            {isWorkSiteModal && (
                <AdjustWorkSiteModal
                    jobId={jobDetail.id}
                    workCoords={{ lng: jobDetail.longitude, lat: jobDetail.latitude }}
                    jobAddress={{
                        number: "",
                        suburb: "",
                        town_city: "",
                        post_code: "",
                        road_name: jobDetail.job_name,
                        longitude: jobDetail.main_address_longitude,
                        latitude: jobDetail.main_address_latitude
                    }}
                    modal={isWorkSiteModal}
                    toggle={e => {
                        if (e.latitude) {
                            setLatLng(e);
                        }
                        setIsWorkSiteModal(!isWorkSiteModal);
                    }}
                />
            )}
        </>
    );
};

export default memo(JobDetail);
