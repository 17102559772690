import React, { Component } from "react";
import SiteInspection from "./presenter";

class Container extends Component {
    constructor(props) {
        super(props);
        const {
            match: { params },
            permissions,
            jobDetail,
            type,
            formDetail
        } = props;

        this.state = {
            pageType: params.id ? "detail" : "new",
            permissionLevel: permissions.operations,
            formData: {
                id: formDetail.id || null,
                job: jobDetail.id,
                form_type: type.id
            },
            isLoading: true
        };
    }

    componentDidMount() {
        const {
            type: { id },
            getFormElement
        } = this.props;
        getFormElement(id).then(value => this.setFormType(value));
    }

    componentDidUpdate(prevProps) {
        const { jobDetail } = this.props;
        const { formData } = this.state;
        if (jobDetail !== prevProps.jobDetail) {
            this.setState(
                {
                    formData: { ...formData, job: jobDetail.id }
                },
                () => this.updateFormData()
            );
        }
    }

    setFormType = formElement => {
        const { formDetail, assignedData } = this.props;
        const { pageType } = this.state;
        this.setState(
            {
                isLoading: false,
                form_content: formElement.map(element => {
                    let existedContent;
                    if (pageType === "detail") {
                        existedContent = formDetail.form_content.find(content => content.element === element.element);
                    } else if (pageType === "new" && assignedData) {
                        existedContent = assignedData.form_content.find(content => content.element === element.element);
                    } else {
                        existedContent = {};
                    }
                    return {
                        ...element,
                        id: null,
                        response: "",
                        comment: "",
                        ...existedContent,
                        relevant_date: existedContent.relevant_date || ""
                    };
                })
            },
            () => this.updateFormData()
        );
    };

    onResponseChange = event => {
        const {
            target: { name, value }
        } = event;
        const { form_content } = this.state;
        this.setState(
            {
                form_content: form_content.map(element =>
                    element.element === Number(name)
                        ? {
                              ...element,
                              response: value
                          }
                        : element
                )
            },
            () => this.updateFormData()
        );
    };

    onYesToAllClick = event => {
        const { form_content } = this.state;
        this.setState(
            {
                form_content: form_content.map(element => ({
                    ...element,
                    response: 1
                }))
            },
            () => this.updateFormData()
        );
    };

    onDateChange = event => {
        const {
            target: { name, value }
        } = event;
        const { form_content } = this.state;
        this.setState(
            {
                form_content: form_content.map(element =>
                    element.element === Number(name)
                        ? {
                              ...element,
                              relevant_date: value
                          }
                        : element
                )
            },
            () => this.updateFormData()
        );
    };

    onCommentChange = event => {
        const {
            target: { name, value }
        } = event;
        const { form_content } = this.state;
        this.setState(
            {
                form_content: form_content.map(element =>
                    element.element === Number(name)
                        ? {
                              ...element,
                              comment: value
                          }
                        : element
                )
            },
            () => this.updateFormData()
        );
    };

    updateFormData = () => {
        const { updateFormData } = this.props;
        const { formData, form_content, pageType } = this.state;
        updateFormData({
            ...formData,
            form_content:
                form_content &&
                form_content.map(content => ({
                    id: pageType === "detail" ? content.id || undefined : undefined,
                    element: content.element,
                    response: content.response || null,
                    comment: content.comment,
                    relevant_date: content.relevant_date || null
                }))
        });
    };

    render() {
        const { type, jobDetail, isDisabled } = this.props;
        return (
            <SiteInspection
                {...this.state}
                type={type}
                isDisabled={isDisabled}
                jobDetail={jobDetail}
                onResponseChange={this.onResponseChange}
                onCommentChange={this.onCommentChange}
                onDateChange={this.onDateChange}
                onYesToAllClick={this.onYesToAllClick}
            />
        );
    }
}

Container.defaultProps = {
    jobDetail: {},
    formDetail: {}
};

export default Container;
