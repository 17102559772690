import React from "react";
import withListItems from "./hoc/withListItems";
import { Checkbox } from "@mui/material";
import { MDBTable, MDBTableBody, MDBTableHead } from "louis-mdbreact";
import styles from "shared/details.scss";
import ListItem from "./ListItem";
import { uuidv4 } from "shared/Utils";

const TravelTimeLists = ({ listItems, onChange, onTotalCheckboxChange }) => {
    return (
        <MDBTable small striped className={styles.detailResponsiveTable}>
            <MDBTableHead>
                <tr>
                    <th width="10">
                        <Checkbox
                            id={uuidv4()}
                            sx={{ padding: 0 }}
                            onChange={onTotalCheckboxChange}
                            checked={listItems.reduce((t, c) => t && c.checked, true)}
                            indeterminate={!listItems.every((val, i, arr) => val.checked === arr[0].checked)}
                        />
                    </th>
                    <th>Worker</th>
                    <th>Job</th>
                    <th width="30%">MJS</th>
                    <th>Supervisor</th>
                    <th>Date</th>
                    <th>Job Distance (punch)</th>
                    <th width="120">Action</th>
                </tr>
            </MDBTableHead>
            <MDBTableBody>
                {listItems.map((list, index) => (
                    <ListItem key={list.id} list={list} index={index} onChange={onChange} isTravel />
                ))}
            </MDBTableBody>
        </MDBTable>
    );
};

export default withListItems(TravelTimeLists);
