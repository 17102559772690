import React from "react";
import styles from "shared/details.scss";
import Loading from "components/Loading";
import { MDBBtn, MDBTable, MDBTableBody, MDBRow, MDBCol, MDBIcon, MDBTableHead, MDBBadge } from "louis-mdbreact";
import dayjs from "shared/dayjs";
import { Link } from "react-router-dom";
import { formatter } from "shared/Utils";
import CollapsedView from "components/CollapsedView";
import ArrowBackButton from "components/ArrowBackButton";
import Chip from "@mui/material/Chip";
import RoomIcon from "@mui/icons-material/Room";
import DirectionsIcon from "@mui/icons-material/Directions";
import MapIcon from "@mui/icons-material/Map";
import ActionButton from "./ActionButton";
import FileComponent from "./FileComponent";
import { isSuperUser } from "shared/Utils";
import CommunicationsLogs from "./CommunationsLogs";

const JobView = props => {
    const {
        isLoading,
        permissions,
        title,
        detailData,
        pageId,
        priceType,
        category,
        service,
        qsCheckList,
        generateBluesheet,
        isCollapsed,
        toggleCollapsed,
        fileDownload,
        toggleDiary,
        QAList,
        PreQAList,
        downloadPLReport,
        region,
        roles: { isSupervisor },
        onClickWorkSite,
        setHiddenJob,
        clearHiddenJob,
        canHideJob,
        canUnHideJob,
        userId,
        updateTimecloud
    } = props;
    return (
        <div className={styles.container}>
            <h2 className={`${styles.title}`}>
                <div style={{ display: "flex", alignItems: "center" }}>
                    <ArrowBackButton />
                    <span>
                        <span>{title}</span> <span className="d-none d-sm-inline">DETAILS</span>
                    </span>
                </div>
                <div style={{ display: "flex", alignItems: "center" }}>
                    {permissions.job === 4 && (
                        <Link to={`/jobs/edit/${pageId}`} style={{ fontSize: "0" }}>
                            <MDBBtn size="sm" color="primary" className="mt-0 mb-0">
                                edit
                            </MDBBtn>
                        </Link>
                    )}
                    {isSuperUser(userId) && (
                        <MDBBtn size="sm" onClick={updateTimecloud} color="secondary" className="mt-0 mb-0">
                            update timecloud
                        </MDBBtn>
                    )}
                    {canHideJob && (
                        <MDBBtn size="sm" onClick={setHiddenJob} color="indigo" className="mt-0 mb-0">
                            hide
                        </MDBBtn>
                    )}
                    {canUnHideJob && (
                        <MDBBtn size="sm" onClick={clearHiddenJob} color="indigo" className="mt-0 mb-0">
                            unhide
                        </MDBBtn>
                    )}
                </div>
            </h2>
            <ActionButton
                pageId={pageId}
                detailData={detailData}
                toggleDiary={toggleDiary}
                downloadPLReport={downloadPLReport}
                onMarkCompleteClick={props.completeClose}
            />
            {isLoading ? (
                <Loading loadingType="detail" />
            ) : (
                <div className="mt-4">
                    <MDBRow>
                        <MDBCol>
                            <CollapsedView
                                idx={0}
                                toggleCollapsed={toggleCollapsed}
                                isCollapsed={isCollapsed[0]}
                                callBack={generateBluesheet}
                                title="Project Details"
                                btnName="Generate Bluesheet"
                                underReview={detailData.under_review}
                                keyInfo={[
                                    { title: "Client", content: detailData.client_string },
                                    { title: "Address", content: detailData.main_address_string }
                                ]}
                            >
                                <MDBTable small borderless striped>
                                    <MDBTableBody>
                                        <tr>
                                            <th className="deep-orange-text">Client</th>
                                            <td>{detailData.client_string}</td>
                                        </tr>
                                        <tr>
                                            <th className="deep-orange-text">Supervisor</th>
                                            <td>{detailData.internal_supervisor_string || "-"}</td>
                                        </tr>
                                        <tr>
                                            <th className="deep-orange-text">
                                                Q<span className="d-none d-sm-inline">uantity</span>
                                                <span className="d-none d-sm-inline"> </span>S<span className="d-none d-sm-inline">urveyor</span>
                                            </th>
                                            <td>{detailData.internal_qs_string || "-"}</td>
                                        </tr>
                                        <tr>
                                            <th className="deep-orange-text">Surveyor</th>
                                            <td>{detailData.internal_surveyor_string || "-"}</td>
                                        </tr>
                                        <tr>
                                            <th className="deep-orange-text">Client Contact</th>
                                            <td>
                                                <MDBRow>
                                                    <MDBCol sm="12">
                                                        <span className="font-weight-normal mr-1 deep-orange-text">Client QS</span>
                                                        <span>{detailData.client_qs_entity_string || "-"}</span>
                                                    </MDBCol>
                                                    {/* <MDBCol sm="7">
                                                        <span className="font-weight-normal mr-1 deep-orange-text">Fire Engineer</span>
                                                        <span>{detailData.fire_engineer_entity_string || "-"}</span>
                                                    </MDBCol> */}
                                                </MDBRow>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th width="30%" className="deep-orange-text">
                                                Project Title
                                            </th>
                                            <td width="70%">
                                                {detailData.job_name || "-"}{" "}
                                                {detailData.linings_on_job ? <MDBIcon className="green-text" size="lg" icon="home" /> : ""}
                                            </td>
                                        </tr>
                                        <tr>
                                            <th className="deep-orange-text">Address</th>
                                            <td>
                                                <MDBRow>
                                                    <MDBCol sm="5">
                                                        {detailData.main_address_string ? (
                                                            <>
                                                                {detailData.main_address_string}{" "}
                                                                <div className="d-inline-block">
                                                                    <a
                                                                        href={`http://maps.apple.com/?${
                                                                            detailData.latitude
                                                                                ? `ll=${detailData.latitude},${detailData.longitude}&z=18`
                                                                                : `address=${detailData.main_address_string}`
                                                                        }`}
                                                                        className="pt-1 pb-1 pr-1"
                                                                    >
                                                                        <Chip
                                                                            size="small"
                                                                            icon={<RoomIcon />}
                                                                            label="Map"
                                                                            clickable
                                                                            color="secondary"
                                                                        />
                                                                    </a>
                                                                    <a
                                                                        href={`http://maps.apple.com/?daddr=${
                                                                            detailData.latitude
                                                                                ? `${detailData.latitude},${detailData.longitude}`
                                                                                : `${detailData.main_address_string}`
                                                                        }&dirflg=d&t=m`}
                                                                        className="pt-1 pb-1 pr-1"
                                                                    >
                                                                        <Chip
                                                                            size="small"
                                                                            icon={<DirectionsIcon />}
                                                                            label="Directions"
                                                                            clickable
                                                                            color="primary"
                                                                        />
                                                                    </a>
                                                                    {isSupervisor && (
                                                                        <Chip
                                                                            onClick={onClickWorkSite}
                                                                            size="small"
                                                                            icon={<MapIcon />}
                                                                            label="Work Site"
                                                                            clickable
                                                                            color="warning"
                                                                        />
                                                                    )}
                                                                </div>
                                                            </>
                                                        ) : (
                                                            "-"
                                                        )}
                                                    </MDBCol>
                                                    <MDBCol sm="7">
                                                        <span className="font-weight-normal mr-1 deep-orange-text">Unit / Lot Number</span>
                                                        <span>{detailData.sub_address_identifier || "-"}</span>
                                                    </MDBCol>
                                                </MDBRow>
                                            </td>
                                        </tr>
                                        {region && (
                                            <tr>
                                                <th className="deep-orange-text">Region</th>
                                                <td>{`${region.region_name} (${region.region_short_code})`}</td>
                                            </tr>
                                        )}
                                        <tr>
                                            <th className="deep-orange-text">External Services</th>
                                            <td>
                                                {detailData.pp_project_id ? (
                                                    <a
                                                        className="btn btn-sm btn-primary"
                                                        href={`https://app.passivepro.co.nz/#/project/${detailData.id}%20/100/false/${detailData.pp_project_id}`}
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                    >
                                                        Passive Pro
                                                    </a>
                                                ) : (
                                                    "-"
                                                )}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colSpan="2">
                                                <p className="deep-orange-text font-weight-normal mb-1">Note</p>
                                                {detailData.job_notes || "-"}
                                            </td>
                                        </tr>
                                    </MDBTableBody>
                                </MDBTable>
                            </CollapsedView>
                        </MDBCol>
                    </MDBRow>
                    <CommunicationsLogs idx={1} pageId={pageId} isCollapsed={isCollapsed[1]} toggleCollapsed={toggleCollapsed} title="Follow Ups" />
                    <MDBRow className="mt-3">
                        <MDBCol>
                            <CollapsedView
                                idx={2}
                                toggleCollapsed={toggleCollapsed}
                                isCollapsed={isCollapsed[2]}
                                title="Job Details"
                                // callBack={permissions.operations === 4 ? toggleDiary : null}
                                // btnName={permissions.operations === 4 ? "site diary" : ""}
                                keyInfo={[
                                    {
                                        title: "Quote No.",
                                        content: detailData.quote_identifier
                                    }
                                ]}
                            >
                                <MDBTable small borderless striped>
                                    <MDBTableBody>
                                        <tr>
                                            <th width="30%" className="deep-orange-text">
                                                Creation Date
                                            </th>
                                            <td>{dayjs(detailData.job_creation_date).format("DD/MM/YYYY")}</td>
                                        </tr>
                                        {detailData.final_bill_date && (
                                            <tr>
                                                <th width="30%" className="deep-orange-text">
                                                    Completion Date
                                                </th>
                                                <td className="red-text">{dayjs(detailData.final_bill_date).format("DD/MM/YYYY")}</td>
                                            </tr>
                                        )}
                                        <tr>
                                            <th width="30%" className="deep-orange-text">
                                                Quote No.
                                            </th>
                                            <td width="70%">{detailData.quote_identifier || "-"}</td>
                                        </tr>
                                        <tr>
                                            <th className="deep-orange-text">Price Type</th>
                                            <td>{priceType.find(type => Number(type.id) === detailData.price_type).name}</td>
                                        </tr>
                                        <tr>
                                            <th className="deep-orange-text">Job Type</th>
                                            <td>{category.find(cate => Number(cate.id === detailData.business_category)).name}</td>
                                        </tr>
                                        {detailData.prestart_attendees.length > 0 && (
                                            <>
                                                <tr>
                                                    <th className="deep-orange-text">Prestart Meeting</th>
                                                    <td>
                                                        <MDBRow>
                                                            <MDBCol sm="4">
                                                                <span className="font-weight-normal mr-1 deep-orange-text">Date</span>
                                                                <span>{dayjs(detailData.prestart_date).format("DD/MM/YYYY")}</span>
                                                            </MDBCol>
                                                            <MDBCol sm="8">
                                                                <span className="font-weight-normal mr-1 deep-orange-text">Attendees</span>
                                                                <span>
                                                                    {detailData.prestart_attendee_list.map(list => (
                                                                        <MDBBadge className={styles.fileList} key={list.id} color="secondary">
                                                                            {list.name_string}
                                                                        </MDBBadge>
                                                                    ))}
                                                                </span>
                                                            </MDBCol>
                                                        </MDBRow>
                                                    </td>
                                                </tr>
                                            </>
                                        )}
                                        {detailData.qs_checklist.filter(check => check.completed).length > 0 && (
                                            <tr>
                                                <td colSpan="2">
                                                    <p className="deep-orange-text font-weight-normal mb-1">QS Checklist</p>
                                                    <ul className="mb-0">
                                                        {detailData.qs_checklist
                                                            .filter(check => check.completed)
                                                            .map(checklist => (
                                                                <li
                                                                    key={checklist.id}
                                                                    className="d-inline-block mr-2 mb-1 rounded py-1 px-3 orange darken-1 white-text"
                                                                >
                                                                    <MDBIcon far icon="check-square" />{" "}
                                                                    {qsCheckList.find(list => list.id === checklist.check_item).name}
                                                                </li>
                                                            ))}
                                                    </ul>
                                                </td>
                                            </tr>
                                        )}
                                    </MDBTableBody>
                                </MDBTable>
                                <FileComponent detailData={detailData} fileDownload={fileDownload} />
                            </CollapsedView>
                        </MDBCol>
                    </MDBRow>
                    <MDBRow className="mt-3">
                        <MDBCol>
                            <CollapsedView idx={3} toggleCollapsed={toggleCollapsed} isCollapsed={isCollapsed[3]} title="Billing Information">
                                <MDBRow>
                                    <MDBCol sm="6">
                                        <MDBTable small striped borderless>
                                            <MDBTableBody>
                                                <tr>
                                                    <th width="30%" className="deep-orange-text">
                                                        D&L Period
                                                    </th>
                                                    <td width="70%">{detailData.dl_period || "-"}</td>
                                                </tr>
                                                <tr>
                                                    <th className="deep-orange-text">Claim Date</th>
                                                    <td>{detailData.claim_date ? `${detailData.claim_date}th` : "-"}</td>
                                                </tr>
                                            </MDBTableBody>
                                        </MDBTable>
                                    </MDBCol>
                                    <MDBCol sm="6">
                                        <MDBTable small className="mb-0">
                                            <MDBTableHead>
                                                <tr className="orange lighten-3">
                                                    <th>Max Value</th>
                                                    <th>retention %</th>
                                                </tr>
                                            </MDBTableHead>
                                            <MDBTableBody>
                                                {Number(detailData.retention_percentage_one) > 0 ? (
                                                    <>
                                                        <tr>
                                                            <td>{formatter.format(detailData.retention_threshold_one)}</td>
                                                            <td>{detailData.retention_percentage_one}%</td>
                                                        </tr>
                                                        <tr>
                                                            <td>{formatter.format(detailData.retention_threshold_two)}</td>
                                                            <td>{detailData.retention_percentage_two}%</td>
                                                        </tr>
                                                        <tr>
                                                            <td>{formatter.format(detailData.retention_threshold_three)}</td>
                                                            <td>{detailData.retention_percentage_three}%</td>
                                                        </tr>
                                                    </>
                                                ) : (
                                                    <tr>
                                                        <td colSpan="2" className="text-center">
                                                            No Retention
                                                        </td>
                                                    </tr>
                                                )}
                                            </MDBTableBody>
                                        </MDBTable>
                                    </MDBCol>
                                </MDBRow>
                            </CollapsedView>
                        </MDBCol>
                    </MDBRow>
                    {detailData.job_services.length > 0 && (
                        <MDBRow className={`${styles.card} mt-3`}>
                            <MDBCol>
                                <CollapsedView idx={4} toggleCollapsed={toggleCollapsed} isCollapsed={isCollapsed[4]} title="Services">
                                    <MDBTable className={styles.detailResponsiveTable} small borderless striped>
                                        <MDBTableHead>
                                            <tr className="orange lighten-3">
                                                <th width="30%">Service</th>
                                                <th width="70%">Notes</th>
                                            </tr>
                                        </MDBTableHead>
                                        <MDBTableBody>
                                            {detailData.job_services.map(ser => (
                                                <tr key={ser.id}>
                                                    <td data-th="Service">{service.find(s => s.id === ser.service).name}</td>
                                                    <td data-th="notes">{ser.notes}</td>
                                                </tr>
                                            ))}
                                        </MDBTableBody>
                                    </MDBTable>
                                </CollapsedView>
                            </MDBCol>
                        </MDBRow>
                    )}
                    {PreQAList && PreQAList.length > 0 && (
                        <MDBRow className={`${styles.card} mt-3`}>
                            <MDBCol>
                                <CollapsedView idx={5} toggleCollapsed={toggleCollapsed} isCollapsed={isCollapsed[5]} title="Pre QA">
                                    <MDBTable small borderless striped className={styles.detailResponsiveTable}>
                                        <MDBTableHead>
                                            <tr className="orange lighten-3">
                                                <th width="70px">QA No.</th>
                                                <th width="120px">Date</th>
                                                <th>Area</th>
                                                <th width="70px">Details</th>
                                            </tr>
                                        </MDBTableHead>
                                        <MDBTableBody>
                                            {PreQAList.map(list => (
                                                <tr key={list.id} className={styles.addItemContainer}>
                                                    <td data-th="QA No.">{list.id}</td>
                                                    <td data-th="Date">{dayjs(list.date).format("DD/MM/YYYY")}</td>
                                                    <td data-th="Area">{list.area}</td>
                                                    <td data-th="Details">
                                                        <Link to={`/operations/preqa/${list.id}`}>
                                                            <MDBBtn className={styles.buttonSize} color="primary" size="sm" name="go" value={list.id}>
                                                                <MDBIcon icon="info-circle" />
                                                            </MDBBtn>
                                                        </Link>
                                                    </td>
                                                </tr>
                                            ))}
                                        </MDBTableBody>
                                    </MDBTable>
                                </CollapsedView>
                            </MDBCol>
                        </MDBRow>
                    )}
                    {QAList && QAList.length > 0 ? (
                        <MDBRow className={`${styles.card} mt-3`}>
                            <MDBCol>
                                <CollapsedView idx={6} toggleCollapsed={toggleCollapsed} isCollapsed={isCollapsed[6]} title="QA Report">
                                    <MDBTable small borderless striped className={styles.detailResponsiveTable}>
                                        <MDBTableHead>
                                            <tr className="orange lighten-3">
                                                <th width="70px">QA No.</th>
                                                <th width="150px">QA Type</th>
                                                <th width="120px">Date</th>
                                                <th>Area</th>
                                                <th width="70px">Details</th>
                                            </tr>
                                        </MDBTableHead>
                                        <MDBTableBody>
                                            {QAList.map(list => (
                                                <tr key={list.id} className={styles.addItemContainer}>
                                                    <td data-th="QA No.">{list.id}</td>
                                                    <td data-th="QA Type">{list.qa_type_string}</td>
                                                    <td data-th="Date">{dayjs(list.date).format("DD/MM/YYYY")}</td>
                                                    <td data-th="Area">{list.area}</td>
                                                    <td data-th="Details">
                                                        <Link to={`/operations/qa/${list.id}`}>
                                                            <MDBBtn className={styles.buttonSize} color="primary" size="sm" name="go" value={list.id}>
                                                                <MDBIcon icon="info-circle" />
                                                            </MDBBtn>
                                                        </Link>
                                                    </td>
                                                </tr>
                                            ))}
                                        </MDBTableBody>
                                    </MDBTable>
                                </CollapsedView>
                            </MDBCol>
                        </MDBRow>
                    ) : undefined}
                    <CommunicationsLogs idx={7} pageId={pageId} isCollapsed={isCollapsed[7]} toggleCollapsed={toggleCollapsed} title="Notes" />
                    <MDBRow>
                        <MDBCol>
                            <div className={`${styles.card} float-right`}>
                                <MDBBtn color="blue-grey" onClick={props.returnToList}>
                                    List
                                </MDBBtn>{" "}
                                {permissions.job === 4 && (
                                    <>
                                        {!detailData.final_bill_date && (
                                            <MDBBtn color="secondary" onClick={props.completeClose}>
                                                Mark Complete
                                            </MDBBtn>
                                        )}{" "}
                                        <Link to={`/jobs/edit/${pageId}`}>
                                            <MDBBtn color="primary">edit</MDBBtn>
                                        </Link>
                                    </>
                                )}
                            </div>
                        </MDBCol>
                    </MDBRow>
                </div>
            )}
        </div>
    );
};

export default JobView;
