import React, { Component } from "react";
import HealthSafetyDetail from "./presenter";
import { Prompt } from "react-router";
import dayjs from "shared/dayjs";
import { getConfirmation, loadStoredDataConfirmation, dataURLtoFile } from "shared/Utils";
import { Helmet } from "react-helmet";

class Container extends Component {
    constructor(props) {
        super(props);
        const { permissions } = this.props;

        this.state = {
            progress: 0,
            isBlock: false,
            isLoading: true,
            jobLoading: false,
            pending: false,
            isFirst: true,
            added: true,
            dataArr: [],
            mailList: [],
            job: "",
            attendeeList: [],
            toolboxFiles: [],
            isDisabled: !(permissions.operations === 4 || permissions.operations === 3)
        };

        this.cancelRef = React.createRef();
    }

    static getDerivedStateFromProps = (props, state) => {
        const {
            match: { params },
            permissions
        } = props;
        if (params.id) {
            return {
                type: "detail",
                title: "HEALTH & SAFETY DETAILS",
                permissionLevel: permissions.operations
            };
        } else {
            return {
                type: "new",
                title: "HEALTH & SAFETY FORM",
                permissionLevel: permissions.operations
            };
        }
    };

    componentDidMount() {
        window.scrollTo(0, 0);
        const { jobList, getSlimJobList } = this.props;
        getSlimJobList();

        if (jobList.length > 0) {
            this.initialise();
        }
    }

    componentDidUpdate(prevProps) {
        const { jobList } = this.props;
        if (JSON.stringify(jobList) !== JSON.stringify(prevProps.jobList)) {
            this.initialise();
        }
    }

    initialise = () => {
        const {
            formType,
            match: { params },
            jobList,
            getFormDetail,
            query,
            getOperationCommon
        } = this.props;
        this.setState(
            {
                job: query.job ? jobList.find(job => job.value === Number(query.job)) : "",
                isLoading: params.id ? true : false,
                compareJob: JSON.stringify(this.state.job),
                formType: formType ? formType.map(type => ({ ...type, checked: false })) : getOperationCommon(true),
                compareFormType: JSON.stringify(formType.map(type => ({ ...type, checked: false })))
            },
            () => {
                const { job, type } = this.state;
                const { loadTableByKey } = this.props;

                if (type === "new") {
                    loadTableByKey("healthsafety").then(form => {
                        if (form && !form.added) {
                            if (loadStoredDataConfirmation()) {
                                this.setState(
                                    {
                                        job: form.job,
                                        formType: form.formType,
                                        assigningData: form.dataArr.map(data =>
                                            data && data.form_type === 3
                                                ? {
                                                      ...data,
                                                      storedAttendees: data.storedAttendees.map(attendee => ({
                                                          ...attendee,
                                                          signature: attendee.signature_url
                                                              ? dataURLtoFile(attendee.signature_url, attendee.signature.name, true)
                                                              : attendee.signature
                                                      }))
                                                  }
                                                : data
                                        )
                                    },
                                    () => {
                                        const { job } = this.state;
                                        job && this.onJobChange(job);
                                    }
                                );
                            }
                        }
                    });

                    this.interval = setInterval(() => {
                        const { addValuesByKey } = this.props;
                        const { job, formType, dataArr, attendeeList, toolboxFiles, added } = this.state;
                        addValuesByKey("healthsafety", {
                            job,
                            formType,
                            dataArr: dataArr.map(data =>
                                data && data.form_type === 3
                                    ? {
                                          ...data,
                                          storedAttendees: attendeeList,
                                          storedFiles: toolboxFiles
                                      }
                                    : data
                            ),
                            added
                        });
                    }, 5000);
                }

                if (!params.id && job) {
                    this.onJobChange(job);
                }

                if (params.id && jobList && formType) {
                    getFormDetail(params.id).then(response => this.setFormDetail(response));
                }
            }
        );
    };

    setFormDetail = formDetail => {
        const { jobList } = this.props;
        const { formType, permissionLevel } = this.state;
        const jobOption = jobList.find(job => job.value === formDetail.job);
        const diffDay = dayjs(formDetail.form_submitted_datetime).diff(new Date(), "day", true);
        let isDisabled = true;
        if (formDetail.form_type === 1) {
            isDisabled = permissionLevel !== 4;
        } else {
            if (diffDay > -2) {
                isDisabled = !(permissionLevel === 4 || permissionLevel === 3);
            } else {
                isDisabled = permissionLevel !== 4;
            }
        }
        this.setState({
            isLoading: false,
            job: jobOption,
            formType: formType.map(type => (type.id === formDetail.form_type ? { ...type, checked: true } : type)),
            compareJob: JSON.stringify(jobOption),
            compareFormType: JSON.stringify(formType.map(type => (type.id === formDetail.form_type ? { ...type, checked: true } : type))),
            formDetail,
            isDisabled
        });
        this.onJobChange(jobOption);
    };

    onJobChange = option => {
        const { getSkinnyJobDetail } = this.props;
        this.setState(
            {
                jobLoading: true,
                job: option
            },
            () => {
                this.setIsBlock();
                option &&
                    getSkinnyJobDetail(option.value).then(value => {
                        this.setState({
                            jobDetail: value,
                            jobLoading: false
                        });
                    });
            }
        );
    };

    onFormTypeChange = event => {
        const {
            target: { name, checked }
        } = event;
        const { formType } = this.state;
        this.setState(
            {
                formType: formType.map(type =>
                    type.name === name
                        ? {
                              ...type,
                              checked
                          }
                        : type
                )
            },
            () => this.setIsBlock()
        );
    };

    updateFormData = data => {
        const { dataArr, type } = this.state;
        dataArr[Number(data.form_type) - 1] = data;
        this.setState({
            dataArr,
            isBlock: type === "detail" ? false : !this.state.isFirst,
            isFirst: false
        });
    };

    updateAttendeeData = attendeeList => {
        const { type } = this.state;
        this.setState({
            attendeeList,
            isBlock: type === "detail" ? false : true
        });
    };

    updateToolboxFiles = toolboxFiles => {
        const { type } = this.state;
        this.setState({
            toolboxFiles,
            isBlock: type === "detail" ? false : true
        });
    };

    updateSSSPFiles = SSSPFiles => {
        const { type } = this.state;
        this.setState({
            SSSPFiles,
            isBlock: type === "detail" ? false : true
        });
    };

    setupValid = valid => {
        this.setState({ mailList: valid.mailList, isValid: valid.isValid });
    };

    returnToList = () => {
        const { history } = this.props;
        this.setState(
            {
                isBlock: false
            },
            () => {
                history.push("/operations/health");
            }
        );
    };

    submitForms = event => {
        event.preventDefault();
        this.setState(
            {
                isValidated: true,
                isBlock: false
            },
            () => {
                const { submitOperationalForm } = this.props;
                const { dataArr, formType, attendeeList, toolboxFiles, SSSPFiles, mailList, isValid, job } = this.state;
                if (!job) {
                    alert("Please complete all required fields.");
                    return false;
                }
                const datas = formType
                    .filter(type => type.checked)
                    .map(type => {
                        return dataArr.find(data => {
                            return data && data.form_type === type.id;
                        });
                    });
                if (datas.length === 0) {
                    alert("At least one form has to be selected.");
                    this.setState({
                        isValidated: undefined
                    });
                    return false;
                }
                // } else {
                //     const isFilled = datas.reduce((bool, data) => {
                //         if (data.form_type === 3) {
                //             return (
                //                 data.form_content.reduce((bool, content) => {
                //                     if (content.element === 61) {
                //                         return !!content.response && bool;
                //                     } else {
                //                         return !!content.comment && bool;
                //                     }
                //                 }, true) && bool
                //             );
                //         } else {
                //             return (
                //                 data.form_content.reduce((bool, content) => (content.response_to ? !!content.response : true) && bool, true) && bool
                //             );
                //         }
                //     }, true);
                //     if (!isFilled) {
                //         return false;
                //     }
                // }

                if (mailList.length > 0) {
                    if (!isValid) {
                        return false;
                    }
                }
                if (getConfirmation()) {
                    this.setState({
                        pending: true
                    });
                    this.cancelRef.current.disabled = true;
                    const submitData = { datas, attendeeList, SSSPFiles, toolboxFiles };
                    submitOperationalForm(submitData, progress => {
                        this.setState({ progress });
                    }).then(value => {
                        if (value) {
                            if (value.responses && value.responses.length > 0) {
                                const { deleteTableByKey } = this.props;
                                this.setState(
                                    {
                                        subInfo: { form: value.responses.map(res => res.id) }
                                    },
                                    () => {
                                        clearInterval(this.interval);
                                        deleteTableByKey("healthsafety");
                                        this.returnToList();
                                    }
                                );
                            }

                            if (String(value).match(/400/)) {
                                this.setState({
                                    pending: false
                                });
                                this.cancelRef.current.disabled = false;
                            }
                        }
                    });
                }
            }
        );
    };

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    setIsBlock = () => {
        const { job, compareJob, formType, compareFormType, type } = this.state;
        const isBlock = JSON.stringify(job) !== compareJob || JSON.stringify(formType) !== compareFormType;
        this.setState({
            isBlock: type === "detail" ? false : isBlock,
            added: false
        });
    };

    render() {
        const { jobList } = this.props;
        const { title, type, jobDetail } = this.state;
        return (
            <>
                <Helmet>
                    <title>
                        Operations | {title} {jobDetail && type === "detail" ? `> ${jobDetail.job_name}` : ""}
                    </title>
                </Helmet>
                <HealthSafetyDetail
                    {...this.state}
                    jobList={jobList}
                    cancelRef={this.cancelRef}
                    returnToList={this.returnToList}
                    onJobChange={this.onJobChange}
                    onFormTypeChange={this.onFormTypeChange}
                    updateFormData={this.updateFormData}
                    updateAttendeeData={this.updateAttendeeData}
                    updateToolboxFiles={this.updateToolboxFiles}
                    updateSSSPFiles={this.updateSSSPFiles}
                    submitForms={this.submitForms}
                    setupValid={this.setupValid}
                />
                <Prompt when={this.state.isBlock} message="Changes that you made may not be saved." />
            </>
        );
    }
}

Container.defaultProps = {
    jobList: [],
    formContents: []
};

export default Container;
