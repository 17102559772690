import { connect } from "react-redux";
import Container from "./container";
import { actionCreators as operationActions } from "redux/modules/operations";
import { getQAAllListAddingSpinner } from "redux/reducers";

const mapStateToProps = (state, ownProps) => {
    const {
        jobs: { jobList },
        user: { permissions, userId, isLeadingHand },
        operations: { filterData }
    } = state;
    return {
        jobList: jobList && jobList.filter(list => list.operational_forms),
        HSAllList: getQAAllListAddingSpinner(state),
        userId,
        isLeadingHand,
        permissions,
        filterData
    };
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        getHSAllList: (isMine, isCurrent, normalView, isPre) => {
            dispatch(operationActions.getHSAllList(isMine, isCurrent, normalView, isPre));
        },
        downloadOperationalForm: (id, signal) => dispatch(operationActions.downloadOperationalForm(id, signal)),
        getFilterData: () => dispatch(operationActions.getFilterData()),
        removeQAAllList: () => dispatch(operationActions.removeQAAllList()),
        getOpsCommons: () => dispatch(operationActions.getOpsCommons(true))
    };
};

const mergeProps = (mapProps, dispatchProps, ownProps) => {
    const { getOpsCommons } = dispatchProps;
    return {
        ...mapProps,
        ...dispatchProps,
        ...ownProps,
        formType: JSON.parse(localStorage.getItem("formType")) || getOpsCommons()
    };
};

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(Container);
