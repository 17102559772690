import React, { Component } from "react";
import PreQADetail from "./presenter";
import { Prompt } from "react-router";
import ConfirmModal from "components/modals/ConfirmModal";
import { imageLoad } from "shared/Utils";
import { axiosInstance } from "shared/axiosInst";
import { Helmet } from "react-helmet";

class Container extends Component {
    constructor(props) {
        super(props);

        this.state = {
            pendingLoading: false,
            isBlock: false,
            isLoading: true,
            jobLoading: false,
            mailList: [],
            togglePreview: false,
            previewImage: "",
            previewButtons: [],
            sendingData: { job: "", area: "" }
        };

        this.cancelRef = React.createRef();
        this.abortController = new AbortController();
        this.abortSignal = this.abortController.signal;
    }

    static getDerivedStateFromProps = (props, state) => {
        const {
            match: { params },
            permissions,
            title
        } = props;
        if (params.id) {
            return {
                type: "detail",
                title: `${title.toUpperCase()} DETAILS`,
                permissionLevel: permissions.operations
            };
        } else {
            return {
                type: "new",
                title: `NEW ${title.toUpperCase()}`,
                permissionLevel: permissions.operations
            };
        }
    };

    componentDidMount = () => {
        window.scrollTo(0, 0);
        const { getSlimJobsForQA, baseData, getQAElements, title } = this.props;
        const id = title === "Pre QA" ? 1 : 2;
        if (!baseData.jobList) {
            getSlimJobsForQA();
        } else {
            this.initialise();
        }
        getQAElements(id);
    };

    componentDidUpdate = (prevProps, prevState) => {
        const { baseData, QaDetail } = this.props;
        if (JSON.stringify(prevProps.baseData) !== JSON.stringify(baseData)) {
            this.initialise();
        }

        if (QaDetail !== prevProps.QaDetail) {
            this.setQaDetail();
        }
    };

    initialise = () => {
        const {
            match: { params },
            getQaDetail,
            baseData: { jobList, qaElement },
            query
        } = this.props;
        this.setState(
            {
                isLoading: params.id ? true : false,
                sendingData: {
                    ...this.state.sendingData,
                    qa_element:
                        qaElement &&
                        qaElement
                            .filter(elem => elem.current_element)
                            .map(elem => ({
                                report_element: elem.id,
                                name: elem.report_element,
                                element_response: elem.report_element === "Add area photo(s)" ? 1 : "",
                                element_comment: "",
                                element_rectification_date: "",
                                files: []
                            })),
                    job: query.job && jobList ? jobList.find(list => list.value === Number(query.job)) : ""
                }
            },
            () => {
                if (params.id && jobList && qaElement) {
                    getQaDetail(params.id);
                }
            }
        );
    };

    setQaDetail = () => {
        const { sendingData } = this.state;
        const {
            QaDetail,
            baseData: { jobList }
        } = this.props;
        this.setState(
            {
                isLoading: false,
                sendingData: {
                    ...QaDetail,
                    job: jobList.find(list => list.value === QaDetail.job),
                    qa_element: sendingData.qa_element.map(element => {
                        const existedElement = QaDetail.qa_items.find(item => item.report_element === element.report_element);
                        if (existedElement) {
                            return {
                                ...existedElement,
                                files: [],
                                name: element.name,
                                element_rectification_date: existedElement.element_rectification_date || ""
                            };
                        } else {
                            return {
                                ...element
                            };
                        }
                    })
                }
            },
            () => {
                const { sendingData } = this.state;
                this.onJobChange(sendingData.job);
                this.setState({ compareData: JSON.stringify(sendingData) });
            }
        );
    };

    onJobChange = option => {
        const { currentCompany } = this.props;
        this.setState(
            {
                sendingData: {
                    ...this.state.sendingData,
                    job: option
                },
                jobLoading: true
            },
            () => {
                this.setIsBlock();
                axiosInstance.get(`${currentCompany}job/slim_job/${option.value}/skinny/`).then(value => {
                    console.log(value);
                    this.setState({
                        jobDetailDiary: {
                            ...value.data
                            // resource_string: resourcesList.find(res => res.id === value.data.resource).legal_name
                        },
                        jobLoading: false
                    });
                });
            }
        );
    };

    onValueChange = event => {
        const {
            sendingData: { qa_element }
        } = this.state;
        const {
            target: { name, value }
        } = event;

        const splitedName = name.split("_");
        let inputName = "";
        switch (splitedName[0]) {
            case "options":
                inputName = "element_response";
                break;
            case "date":
                inputName = "element_rectification_date";
                const target = event.nativeEvent.target;
                function iosClearDefault() {
                    target.defaultValue = "";
                }
                window.setTimeout(iosClearDefault, 0);
                break;
            case "note":
                inputName = "element_comment";
                break;
            default:
                inputName = "";
                break;
        }
        this.setState(
            {
                sendingData: {
                    ...this.state.sendingData,
                    qa_element: qa_element.map(elem => {
                        if (elem.report_element === Number(splitedName[1])) {
                            return {
                                ...elem,
                                [inputName]: value
                            };
                        } else {
                            return elem;
                        }
                    })
                }
            },
            () => this.setIsBlock()
        );
    };

    onFileChange = async event => {
        const {
            target: { name, files }
        } = event;

        const idx = Number(name);
        for (let i = 0; i < files.length; i++) {
            if (files[i].type.search(/image/) >= 0) {
                if (this.abortSignal.aborted) {
                    break;
                }
                const img = await imageLoad(URL.createObjectURL(files[i]), files[i].name, files[i].type, this.abortSignal);

                this.saveImages(idx, {
                    f: img.file,
                    src: img.image,
                    thumb: img.thumb,
                    note: "",
                    id: Number(new Date()) + i
                });
            } else {
                this.saveImages(idx, {
                    f: files[i],
                    note: "",
                    id: Number(new Date()) + i
                });
            }
        }
    };

    saveImages = (idx, filesArr) => {
        this.setState(
            {
                sendingData: {
                    ...this.state.sendingData,
                    qa_element: this.state.sendingData.qa_element.map(elem => {
                        return elem.report_element === Number(idx) ? { ...elem, files: [...elem.files, filesArr] } : elem;
                    })
                }
            },
            () => {
                this.setIsBlock();
            }
        );
    };

    fileNoteChange = event => {
        const {
            target: { name, value }
        } = event;

        const {
            sendingData: { qa_element }
        } = this.state;

        const ids = name.split("_");

        this.setState({
            sendingData: {
                ...this.state.sendingData,
                qa_element: qa_element.map(element => {
                    return element.report_element === Number(ids[0])
                        ? {
                              ...element,
                              files: element.files.map(file => {
                                  return file.id === Number(ids[1])
                                      ? {
                                            ...file,
                                            note: value
                                        }
                                      : file;
                              })
                          }
                        : element;
                })
            }
        });
    };

    onFileRemove = event => {
        const {
            currentTarget: { name, value }
        } = event;
        const {
            sendingData: { qa_element }
        } = this.state;
        this.setState({
            sendingData: {
                ...this.state.sendingData,
                qa_element: qa_element.map(element => {
                    return element.report_element === Number(name)
                        ? {
                              ...element,
                              files: element.files.filter(file => file.id !== Number(value))
                          }
                        : element;
                })
            }
        });
    };

    togglePreviewPop = (event, idx) => {
        const { togglePreview } = this.state;
        if (!togglePreview) {
            const {
                currentTarget: { name, value }
            } = event;
            this.setState({
                previewImage: <img width="100%" src={value} alt={name} />,
                previewButtons: [{ name: "Close", colour: "indigo", func_id: 1 }],
                togglePreview: !togglePreview
            });
        } else {
            this.setState({
                togglePreview: !togglePreview
            });
        }
    };

    onAreaChange = event => {
        this.setState({
            sendingData: {
                ...this.state.sendingData,
                area: event.target.value
            }
        });
    };

    returnToList = () => {
        const { history, title } = this.props;
        const currentMenu = title === "Pre QA" ? "preqa" : "qa";
        this.setState(
            {
                isBlock: false
            },
            () => history.push(`/operations/${currentMenu}`)
        );
    };

    setupValid = valid => {
        this.setState({ mailList: valid.mailList, isValid: valid.isValid });
    };

    onSubmit = event => {
        event.preventDefault();
        this.setState(
            {
                isBlock: false,
                isValidated: true
            },
            () => {
                const { postQA, history, title } = this.props;
                const { sendingData, mailList, isValid } = this.state;
                if (!sendingData.job || sendingData.qa_element.filter(elem => elem.element_response).length <= 1) {
                    return false;
                }
                if (mailList.length > 0) {
                    if (!isValid) {
                        return false;
                    }
                }
                if (!sendingData.area) {
                    const confirmed = window.confirm("You have left the Area empty, is this QA for the whole project?");
                    if (!confirmed) {
                        return false;
                    }
                }
                const submitData = [
                    {
                        id: sendingData.id,
                        job: sendingData.job.value,
                        qa_type: JSON.parse(localStorage.getItem("qaReportType")).find(type => type.name === title).id,
                        area: sendingData.area || "whole project",
                        qa_items: sendingData.qa_element
                            .filter(elem => elem.element_response)
                            .map(elem => ({
                                ...elem,
                                element_rectification_date: elem.element_rectification_date || undefined,
                                associated_files: undefined,
                                files: []
                            }))
                    }
                ];
                const files = sendingData.qa_element
                    .filter(elem => (Number(elem.element_response) === 1 || Number(elem.element_response) === 2) && elem.files.length > 0)
                    .map(elem => ({ report_element: elem.report_element, new_file: elem.files }));
                this.cancelRef.current.disabled = true;
                this.setState({
                    pendingLoading: true
                });

                postQA(submitData, files).then(value => {
                    if (value) {
                        const currentMenu = title === "Pre QA" ? "preqa" : "qa";
                        if (String(value).match(/400/)) {
                            this.setState({
                                pendingLoading: false
                            });
                            this.cancelRef.current.disabled = false;
                        }

                        if (value.qa) {
                            this.setState({
                                subInfo: value
                            });
                            history.push(`/operations/${currentMenu}`);
                        }
                    }
                });
            }
        );
    };

    setIsBlock = () => {
        const { sendingData, compareData } = this.state;
        const isBlock = JSON.stringify(sendingData) !== compareData ? true : false;

        this.setState({
            isBlock
        });
    };

    downloadImage = event => {
        const {
            currentTarget: { value }
        } = event;
        const { fileDownload } = this.props;
        fileDownload(value);
    };

    componentWillUnmount = () => {
        this.abortController.abort();
    };

    fileClickTrigger = event => {
        document.getElementById(`file_${event.currentTarget.name}`).click();
    };

    render = () => {
        const { baseData, title } = this.props;
        const { togglePreview, previewImage, previewButtons, jobDetailDiary, type } = this.state;
        return (
            <>
                <Helmet>
                    <title>
                        Operations | {title} {jobDetailDiary && type === "detail" ? `> ${jobDetailDiary.job_name}` : ""}
                    </title>
                </Helmet>
                <PreQADetail
                    {...this.state}
                    baseData={baseData}
                    typeTitle={title}
                    cancelRef={this.cancelRef}
                    onJobChange={this.onJobChange}
                    onValueChange={this.onValueChange}
                    onFileChange={this.onFileChange}
                    onAreaChange={this.onAreaChange}
                    onSubmit={this.onSubmit}
                    returnToList={this.returnToList}
                    downloadImage={this.downloadImage}
                    setupValid={this.setupValid}
                    fileClickTrigger={this.fileClickTrigger}
                    fileNoteChange={this.fileNoteChange}
                    onFileRemove={this.onFileRemove}
                    togglePreviewPop={this.togglePreviewPop}
                />
                <ConfirmModal modal={togglePreview} message={previewImage} buttons={previewButtons} title="Preview" toggle={this.togglePreviewPop} />

                <Prompt when={this.state.isBlock} message="Changes that you made may not be saved." />
            </>
        );
    };
}

export default Container;
