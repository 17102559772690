import React, { Component } from "react";
import Jobs from "./presenter";

class Container extends Component {
    constructor(props) {
        super(props);
        const isMine = localStorage.getItem("isMineJob") === "true" || localStorage.getItem("isMineJob") === null;
        const isCurrent = localStorage.getItem("isCurrentJob") === "true" || localStorage.getItem("isCurrentJob") === null;
        this.state = {
            loading: true,
            isMine,
            isCurrent,
            adminSetting: {
                ...(JSON.parse(localStorage.getItem("jobAdminSetting")) || {
                    isOpen: false,
                    isMaintenance: false
                })
            },
            titleName: this.setTitleName(isMine, isCurrent),
            searchText: sessionStorage.getItem("jobSearch") || "",
            activePage: sessionStorage.getItem("jobActivePage") ? Number(sessionStorage.getItem("jobActivePage")) - 1 : 0
        };
    }

    componentDidMount = () => {
        const { getSlimJob } = this.props;
        const { isMine, isCurrent, adminSetting } = this.state;
        getSlimJob(isMine, isCurrent, adminSetting);
        window.scrollTo(0, 0);
    };

    componentDidUpdate = prevProps => {
        const { jobList } = this.props;
        if (jobList !== prevProps.jobList) {
            this.setState({
                loading: false
            });
        }
        // if (prefixText !== prevProps.prefixText) {
        //     getSlimJob();
        //     this.setState({
        //         loading: true
        //     });
        // }
    };

    setTitleName = (isMine, isCurrent) => {
        const title = `${isMine ? "my " : ""}${isCurrent ? "current " : ""}jobs`;

        return title.toUpperCase();
    };

    changeList = event => {
        const {
            target: { name, checked }
        } = event;
        const { getSlimJob } = this.props;
        localStorage.setItem([`${name}Job`], checked);
        this.setState(
            {
                [name]: checked,
                loading: true
            },
            () => {
                const { isMine, isCurrent, adminSetting } = this.state;

                getSlimJob(isMine, isCurrent, adminSetting);
                this.setState({
                    titleName: this.setTitleName(isMine, isCurrent)
                });
            }
        );
    };

    onChangeSetting = filter => {
        const { getSlimJob } = this.props;
        const { isMine, isCurrent } = this.state;
        this.setState({
            adminSetting: filter,
            loading: true
        });
        getSlimJob(isMine, isCurrent, filter);
    };

    toggleAdmin = () => {
        const { adminSetting } = this.state;
        this.setState(
            {
                adminSetting: {
                    ...adminSetting,
                    isAdminOpen: !adminSetting.isAdminOpen
                }
            },
            () => localStorage.setItem("jobAdminSetting", JSON.stringify(this.state.adminSetting))
        );
    };

    onSearch = searchText => {
        sessionStorage.setItem("jobSearch", searchText);
    };

    onPageChange = pageInfo => {
        sessionStorage.setItem("jobActivePage", pageInfo.activePage);
    };

    render() {
        const { jobList, prefixText, permissions, currentRegion } = this.props;
        return (
            <Jobs
                {...this.state}
                jobList={jobList}
                permissions={permissions}
                prefixText={prefixText}
                currentRegion={currentRegion}
                changeList={this.changeList}
                toggleAdmin={this.toggleAdmin}
                onChangeSetting={this.onChangeSetting}
                onSearch={this.onSearch}
                onPageChange={this.onPageChange}
            />
        );
    }
}

export default Container;
