import React, { useEffect, useState } from "react";
import { Controller, useFormContext, useWatch } from "react-hook-form";
import WindowedSelect from "react-windowed-select";
import { Grid } from "@mui/material";

import { Label } from "components/HumanResources/CustomComponent";
const SelectMJS = ({ jobList, name, job, title, zIndex }) => {
    const { control, setValue } = useFormContext();
    const [MJSList, setMJSList] = useState([]);
    const currentJob = useWatch({ control, name: job, defaultValue: "" });
    useEffect(() => {
        setValue(`${name}`, "");
        setMJSList(!currentJob ? jobList : jobList?.filter(list => list.job === currentJob?.id) || []);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentJob, jobList]);
    return (
        <Grid item xs={12} sm={12} md={6} sx={{ zIndex }}>
            <Label>{title} (optional)</Label>
            <Controller
                control={control}
                name={name}
                render={({ field }) => (
                    <WindowedSelect
                        menuPosition="fixed"
                        isClearable
                        options={MJSList || []}
                        getOptionValue={option => option.id}
                        getOptionLabel={option => `MJS-${option.id} (${option.job}) : ${option.job_name_string} - ${option.notes}`}
                        {...field}
                    />
                )}
            />
        </Grid>
    );
};

export default SelectMJS;
