import React, { useContext } from "react";
import { Button, DialogContent, Grid } from "@mui/material";
import DialogContainer from "components/DialogContainer";
import { MDBTable, MDBTableBody } from "louis-mdbreact";
import { ModalContext } from "../TaskContext";
import dayjs from "shared/dayjs";
import { formatDateToTime, timeDiff } from "shared/Utils";
import { useForm, FormProvider } from "react-hook-form";
import ReasonItem from "./ReasonItem";
import { axiosInstance } from "shared/axiosInst";
import { useDispatch } from "react-redux";
import { actionCreators as taskActions } from "redux/modules/tasks";

const RejectModal = ({ open, toggle }) => {
    const { rejectData, setRejectData } = useContext(ModalContext);
    const dispatch = useDispatch();
    const isIterable = typeof rejectData[Symbol.iterator] === "function";
    const method = useForm({ defaultValues: { reason: 0, reason_free_text: "" } });
    const { handleSubmit } = method;
    const onSubmit = data => {
        if (isIterable) {
            Promise.allSettled(
                rejectData.map(reject =>
                    axiosInstance.post(`fire-akl/maintenance/time_tracking_decisions/reject/`, {
                        ...data,
                        time_entry: reject.id,
                        reason: data.reason === "0" || data.reason === 0 ? undefined : data.reason
                    })
                )
            )
                .then(results => {
                    if (!!results.find(result => result.status === "rejected")) {
                        alert("Some rejection went wrong. Please try again later.");
                    }
                    setRejectData(null);
                    dispatch(taskActions.getTimeCloudList());
                })
                .catch(error => {
                    console.log(error.response);
                });
        } else {
            axiosInstance
                .post(`fire-akl/maintenance/time_tracking_decisions/reject/`, {
                    ...data,
                    time_entry: rejectData.id,
                    reason: data.reason === "0" || data.reason === 0 ? undefined : data.reason
                })
                .then(value => {
                    setRejectData(null);
                    dispatch(taskActions.getTimeCloudList());
                });
        }
    };
    return (
        <DialogContainer open={open} handleClose={toggle} title="Timecloud Rejection" fullScreen={false} maxWidth="md">
            <DialogContent>
                <FormProvider {...method}>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Grid direction="column" container spacing={2}>
                            <Grid item>
                                {!isIterable && (
                                    <MDBTable small striped>
                                        <MDBTableBody>
                                            <tr>
                                                <th>Worker</th>
                                                <td>{rejectData.worker_name}</td>
                                            </tr>
                                            <tr>
                                                <th>Job</th>
                                                <td>
                                                    {rejectData.job_id
                                                        ? `${rejectData.job_id}-${rejectData.job_region} (${rejectData.job_name})`
                                                        : rejectData.end_job_id
                                                        ? `${rejectData.end_job_id}-${rejectData.end_job_region} (${rejectData.end_job_name})`
                                                        : "-"}
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>MJS</th>
                                                <td>{rejectData.mjs_id ? `MJS-${rejectData.mjs_id} (${rejectData.mjs_note})` : "-"}</td>
                                            </tr>
                                            <tr>
                                                <th>Date</th>
                                                <td>
                                                    {rejectData.starting_timestamp ? dayjs(rejectData.starting_timestamp).format("DD/MM/YYYY") : "-"}
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>Time</th>
                                                <td>
                                                    {rejectData.starting_timestamp
                                                        ? `${formatDateToTime(rejectData.starting_timestamp)} ~ ${formatDateToTime(
                                                              rejectData.ending_timestamp
                                                          )} (${timeDiff(rejectData.starting_timestamp, rejectData.ending_timestamp)})`
                                                        : rejectData.total_hours
                                                        ? `${rejectData.total_hours} hrs`
                                                        : "-"}
                                                </td>
                                            </tr>
                                        </MDBTableBody>
                                    </MDBTable>
                                )}
                            </Grid>
                            <ReasonItem />
                            <Grid item container direction="row-reverse">
                                <Button type="submit" variant="contained" color="error">
                                    Reject
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                </FormProvider>
            </DialogContent>
        </DialogContainer>
    );
};

export default RejectModal;
