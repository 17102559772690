import React, { useState, useEffect } from "react";
import styles from "shared/listStyles.scss";
import { Helmet } from "react-helmet";
import { Grid } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
// import { getRosteringServices } from "shared/Utils";
import { getWorkerTheme } from "../Roster/WorkerTheme";
import RosterCalendar from "../Roster/RosterCalendar";
import { RosterCalendarContext, RosterContext } from "../TaskContext";
import { actionCreators as crmActions } from "redux/modules/crm";
import { useDispatch, useSelector } from "react-redux";
import BranchSelect from "../Roster/BranchSelect";

const Assignments = () => {
    const dispatch = useDispatch();
    const { companyPrefix } = useSelector(state => state.user);
    const services = JSON.parse(localStorage.getItem("service"));
    const workerTheme = getWorkerTheme(services);
    const [assignedList, setAssignedList] = useState([]);
    const [currentBranch, setCurrentBranch] = useState("");
    const [isInRostering] = useState(false);
    useEffect(() => {
        if (currentBranch) {
            dispatch(
                crmActions.getSkinnyRole(
                    `?assignment=2&reporting_area=${currentBranch}&entity_type=5&relationship_type=1&global_company=${companyPrefix}`
                )
            );
        }
    }, [dispatch, currentBranch, companyPrefix]);
    return (
        <div className={styles.container}>
            <ThemeProvider theme={workerTheme}>
                <Helmet>
                    <title>Tasks | Assignments</title>
                </Helmet>
                <RosterContext.Provider
                    value={{
                        currentBranch,
                        setCurrentBranch,
                        services,
                        isInRostering
                    }}
                >
                    <div className={styles.titleWrap} style={{ display: "flex", width: "100%", justifyContent: "space-between" }}>
                        <h2 className={`${styles.taskTitle}`}>Assignments</h2>
                        {/* <Stack direction="row" flexWrap="wrap" justifyContent={{ md: "flex-end", sm: "flex-end", xs: "flex-start" }}>
                            {getRosteringServices(services).map(service => (
                                <Chip key={service.id} color={`${service.name}`} size="small" sx={{ margin: "2px" }} label={service.name} />
                            ))}
                        </Stack> */}
                    </div>
                    <Grid container alignItems="flex-start" spacing={2}>
                        <Grid item sm={12} md={12} lg={12}>
                            <BranchSelect />
                        </Grid>
                        <RosterCalendarContext.Provider value={{ setAssignedList, assignedList }}>
                            <Grid container item xs={12} sm={12} md={12} lg={12}>
                                <RosterCalendar />
                            </Grid>
                        </RosterCalendarContext.Provider>
                    </Grid>
                </RosterContext.Provider>
            </ThemeProvider>
        </div>
    );
};

export default Assignments;
