import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";

import Entity from "components/Crm/Entity";
import EntityDetail from "components/Crm/EntityDetail";
import EntityView from "components/Crm/EntityView";
import EntityEmail from "components/Crm/Email";
import Payrate from "components/Crm/Payrate";
import { useSelector } from "react-redux";
import RelationshipList from "components/Crm/RelationshipList";
// import Debtors from "components/Crm/Debtors";
import Quotes from "components/Crm/Quotes";

const CrmRoute = () => {
    const {
        user: { permissions, isPayrollRate, isSLT, isAdmin, isQS }
    } = useSelector(state => state);
    return (
        <Switch>
            <Route exact path="/crm" component={Entity} />
            <Route exact path="/crm/entities" type="all" component={Entity} />
            <Route exact path="/crm/merchant" type="merchant" render={props => <RelationshipList {...props} title="Merchant" relationshipId="5" />} />
            <Route exact path="/crm/client" type="client" render={props => <RelationshipList {...props} title="Client" relationshipId="4" />} />
            <Route exact path="/crm/employee" type="employee" render={props => <RelationshipList {...props} title="Employee" relationshipId="1" />} />
            {/* {permissions.crm && (isSLT || isQS) ? (
                <Route exact path="/crm/debtors" type="debtors" component={Debtors} />
            ) : (
                <Redirect from="/crm/debtors" to="/crm" />
            )} */}

            {permissions.crm === 4 || permissions.crm === 3 ? (
                <Route exact path="/crm/entities/new" key="new" component={EntityDetail} />
            ) : (
                <Redirect from="/crm/entities/new" to="/crm/entities" />
            )}
            {permissions.crm === 4 && isPayrollRate ? (
                <Route exact path="/crm/payrate" component={Payrate} />
            ) : (
                <Redirect from="/crm/payrate" to="/crm" />
            )}
            {permissions.crm && (isSLT || isAdmin) ? (
                <Route exact path="/crm/email" component={EntityEmail} />
            ) : (
                <Redirect from="/crm/email" to="/crm" />
            )}
            <Route exact path="/crm/entities/:id" key="view" render={props => <EntityView {...props} key={window.location.pathname} />} />
            {permissions.crm && (isSLT || isAdmin || isQS) ? <Route exact path="/crm/quotes" component={Quotes} /> : <Redirect to="/crm" />}
        </Switch>
    );
};

export default CrmRoute;
