import React, { memo } from "react";
import styles from "shared/details.scss";
import { MDBDropdown, MDBDropdownToggle, MDBDropdownMenu, MDBDropdownItem, MDBIcon } from "louis-mdbreact";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

const ActionButton = ({ isServiceRates, pageId, detailData, toggleDiary, downloadPLReport, onMarkCompleteClick }) => {
    const { permissions, isSLT, isJobPLAccess, isAllPLAccess } = useSelector(state => state.user);
    const accessable = isSLT || isJobPLAccess || isAllPLAccess;
    const isProvisionalJob = detailData?.business_category === 6;
    return (
        <div className={styles.newButton}>
            {permissions.maintenance === 3 ||
            permissions.maintenance === 4 ||
            ((permissions.budget === 3 || permissions.budget === 4) && isServiceRates) ||
            permissions.operations === 3 ||
            permissions.operations === 4 ||
            permissions.ordering === 3 ||
            permissions.ordering === 4 ||
            isSLT ? (
                <MDBDropdown>
                    <MDBDropdownToggle className={styles.dropdownToggle} color="secondary" size="sm">
                        <MDBIcon icon="plus" />
                    </MDBDropdownToggle>
                    <MDBDropdownMenu basic>
                        {!detailData?.final_bill_date && permissions.job === 4 && (
                            <MDBDropdownItem className={styles.dropDownItem}>
                                <span className={styles.link} onClick={onMarkCompleteClick}>
                                    Base Contract Billed
                                </span>
                            </MDBDropdownItem>
                        )}
                        {/* {!breakdown_created &&
                            permissions.budget === 4 &&
                            isServiceRates ? (
                                <MDBDropdownItem>
                                    <Link
                                        className={styles.link}
                                        to={`/budget/new`}>
                                        New Budget
                                    </Link>
                                </MDBDropdownItem>
                            ) : (
                                ""
                            )} */}
                        {/* {breakdown_created && (
                                <MDBDropdownItem>
                                    <Link
                                        className={styles.link}
                                        to={`/budget/${pageId}`}>
                                        View Budget
                                    </Link>
                                </MDBDropdownItem>
                            )} */}
                        {(permissions.operations === 3 || permissions.operations === 4) && !isProvisionalJob && (
                            <>
                                {/* <MDBDropdownItem className={styles.dropDownItem}>
                                    <span className={styles.link} onClick={toggleDiary}>
                                        New Site Diary
                                    </span>
                                </MDBDropdownItem> */}
                                {/* <MDBDropdownItem className={styles.dropDownItem}>
                                    <Link className={styles.link} to={`/operations/observations/new?job=${pageId}`}>
                                        New Observation
                                    </Link>
                                </MDBDropdownItem> */}
                                {/* <MDBDropdownItem className={styles.dropDownItem}>
                                        <Link
                                            className={styles.link}
                                            to={`/operations/preqa/new?job=${pageId}`}
                                        >
                                            New Pre QA
                                        </Link>
                                    </MDBDropdownItem> */}
                                {/* <MDBDropdownItem className={styles.dropDownItem}>
                                    <Link className={styles.link} to={`/operations/qa/new?job=${pageId}`}>
                                        New QA
                                    </Link>
                                </MDBDropdownItem> */}
                                <MDBDropdownItem className={styles.dropDownItem}>
                                    <Link className={styles.link} to={`/operations/health/new?job=${pageId}`}>
                                        New H&S
                                    </Link>
                                </MDBDropdownItem>
                            </>
                        )}
                        {/* {(permissions.ordering === 3 || permissions.ordering === 4) && (
                            <>
                                <MDBDropdownItem className={styles.dropDownItem}>
                                    <Link className={styles.link} to={`/material/order/new?job=${pageId}`}>
                                        New Order
                                    </Link>
                                </MDBDropdownItem>
                                <MDBDropdownItem className={styles.dropDownItem}>
                                    <span className={styles.link} onClick={myGibPOClick}>
                                        New MyGIB PO
                                    </span>
                                </MDBDropdownItem>
                            </>
                        )} */}
                        {accessable && !isProvisionalJob && (
                            <>
                                <MDBDropdownItem className={styles.dropDownItem}>
                                    <span className={styles.link} onClick={() => downloadPLReport("pdf")}>
                                        Get P&L Report
                                    </span>
                                </MDBDropdownItem>
                                <MDBDropdownItem className={styles.dropDownItem}>
                                    <span className={styles.link} onClick={() => downloadPLReport("xlsx")}>
                                        Get P&L Report(Excel)
                                    </span>
                                </MDBDropdownItem>
                            </>
                        )}
                        {(permissions.stock === 3 || permissions.stock === 4) && (
                            <MDBDropdownItem className={styles.dropDownItem}>
                                <Link className={styles.link} to={`/stock/transferred/new`}>
                                    New Transfer
                                </Link>
                            </MDBDropdownItem>
                        )}
                    </MDBDropdownMenu>
                </MDBDropdown>
            ) : (
                ""
            )}
        </div>
    );
};

export default memo(ActionButton);
