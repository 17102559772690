import React from "react";
import styles from "./styles.scss";

const SwitchCompany = props => {
    if (props.companyList.length > 1) {
        return (
            <div className={styles.switchContainer}>
                <p className={styles.switchTitle}>Regions</p>
                <select
                    className={`custom-select custom-select-sm`}
                    onChange={props.onChange}
                    value={props.companyValue}>
                    {props.companyList.map(company => {
                        return (
                            <option
                                key={company.id}
                                value={company.value}
                                prefix={company.prefix}>
                                {company.label}
                            </option>
                        );
                    })}
                </select>
            </div>
        );
    } else {
        return null;
    }
};

export default SwitchCompany;
