import React from "react";
import styles from "shared/details.scss";
import Loading from "components/Loading";
import { MDBBtn, MDBCol, MDBRow, MDBTable, MDBTableHead, MDBTableBody, MDBIcon } from "louis-mdbreact";
import { Link } from "react-router-dom";
import ArrowBackButton from "components/ArrowBackButton";
import CollapsedView from "components/CollapsedView";
import RolesList from "./RolesList";
import AttributeList from "./AttributeList";
import dayjs from "shared/dayjs";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Chip from "@mui/material/Chip";
import CreateIcon from "@mui/icons-material/Create";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import NoteList from "components/Crm/Details/Notes/NoteList";
import Entity from "components/Crm/Details/Entity";
import Contact from "components/Crm/Details/Contact";
import Attributes from "components/Crm/Details/Attributes";
import WorkerAttributes from "components/Crm/Details/WorkerAttributes";
import TaxSpec from "components/Crm/Details/TaxSpec";
import ChipList from "components/Crm/ChipList";
import SubLists from "./SubLists";
import InvoiceCard from "./InvoiceCard";
import TimecloudButton from "./TimecloudButton";

const EntityView = props => {
    const {
        isLoading,
        toggleCollapsed,
        permissions,
        returnToList,
        entitiesData,
        parents,
        cardState,
        noteInfo,
        editNote,
        onEditClick,
        onAddNoteClick,
        onDelete,
        deleteNote,
        approveEntity,
        rejectEntity,
        isAdmin,
        ableToSeeInvoice,
        globalUsers
    } = props;
    const editable = permissions.crm === 4;
    const administration = editable && isAdmin;
    return (
        <div className={styles.container}>
            {isLoading ? (
                <Loading loadingType="detail" />
            ) : (
                <>
                    {parents && (
                        <ul className={styles.parentBreadcrums}>
                            {parents.map((parent, i) => (
                                <li key={parent.id}>
                                    <Link to={`/crm/entities/${parent.id}`}>{parent.name}</Link>
                                </li>
                            ))}
                        </ul>
                    )}
                    <h2 className={`${styles.title} align-middle`}>
                        <ArrowBackButton />
                        <div>
                            {entitiesData.name}
                            {entitiesData.pending ? <Chip color="secondary" label="PENDING" className="ml-1" /> : undefined}{" "}
                            {editable && <TimecloudButton entitiesData={entitiesData} />}
                        </div>
                    </h2>
                    {/* <ActionsButton permissions={permissions} /> */}
                    {ableToSeeInvoice ? <InvoiceCard /> : ""}
                    <MDBRow>
                        <MDBCol>
                            <CollapsedView
                                toggleCollapsed={toggleCollapsed}
                                isCollapsed={cardState[0].isCollapsed}
                                onEditClick={onEditClick}
                                editable={editable}
                                isEdit={cardState[0].isEdit}
                                idx={0}
                                title="Details"
                                keyInfo={[
                                    {
                                        title: "Type",
                                        content: entitiesData.entity_type_string
                                    }
                                ]}
                            >
                                {cardState[0].isEdit ? (
                                    <>
                                        <Entity type="view" entityDetail={entitiesData} onEditClick={onEditClick} />
                                        {administration && <TaxSpec type="view" entityDetail={entitiesData} onEditClick={onEditClick} />}
                                    </>
                                ) : (
                                    <MDBTable small borderless striped>
                                        <MDBTableBody>
                                            <tr>
                                                <th width="25%" className="orange-text">
                                                    Entity Type
                                                </th>
                                                <td>{entitiesData.entity_type_string}</td>
                                            </tr>
                                            <tr>
                                                <th width="25%" className="orange-text">
                                                    Name
                                                </th>
                                                <td>{`${entitiesData.name}${entitiesData.short_name ? ` (${entitiesData.short_name})` : ""}`}</td>
                                            </tr>
                                            <tr>
                                                <th
                                                    width="25%"
                                                    className={`${
                                                        !entitiesData.nzbn && entitiesData.entity_type_string === "Company"
                                                            ? "red-text"
                                                            : "orange-text"
                                                    }`}
                                                >
                                                    NZBN
                                                </th>
                                                <td>{entitiesData.nzbn || "-"}</td>
                                            </tr>
                                            {entitiesData.responsibilities.length > 0 && (
                                                <tr>
                                                    <th width="25%" className="orange-text">
                                                        Responsibilities
                                                    </th>
                                                    <td>
                                                        <ul className={styles.capsuleList}>
                                                            {entitiesData.responsibilities.map(ele => (
                                                                <AttributeList key={ele.id} element={ele} />
                                                            ))}
                                                        </ul>
                                                    </td>
                                                </tr>
                                            )}
                                            {entitiesData.group_association_details.length > 0 && (
                                                <tr>
                                                    <th width="25%" className="orange-text">
                                                        Group Association
                                                    </th>
                                                    <td>
                                                        <ul className={styles.capsuleList}>
                                                            {entitiesData.group_association_details.map(ele => (
                                                                <AttributeList key={ele.id} element={ele} />
                                                            ))}
                                                        </ul>
                                                    </td>
                                                </tr>
                                            )}
                                            {entitiesData.default_followup_linings && (
                                                <tr>
                                                    <th width="25%" className="orange-text">
                                                        Default Followup
                                                    </th>
                                                    <td>
                                                        {globalUsers?.find(user => user.id === entitiesData.default_followup_linings).name_string}
                                                    </td>
                                                </tr>
                                            )}
                                            {entitiesData.gru && (
                                                <tr>
                                                    <th width="25%" className="orange-text">
                                                        Team Leader
                                                    </th>
                                                    <td>{globalUsers?.find(user => user.id === entitiesData.gru).name_string}</td>
                                                </tr>
                                            )}
                                            {entitiesData.tax_spec_details.length > 0 && administration && (
                                                <tr>
                                                    <td width="25%" className="orange-text" colSpan="2">
                                                        <p className="orange-text font-weight-normal mb-1">Tax Specification</p>
                                                        <MDBTable small borderless striped className={styles.detailResponsiveTable}>
                                                            <MDBTableHead>
                                                                <tr className="orange lighten-3">
                                                                    <th>ID Number</th>
                                                                    <th>Specification</th>
                                                                    <th>Effective Date</th>
                                                                    <th>End Date</th>
                                                                </tr>
                                                            </MDBTableHead>
                                                            <MDBTableBody>
                                                                {entitiesData.tax_spec_details.map(tax => (
                                                                    <tr key={tax.id}>
                                                                        <td data-th="ID Number">{tax.tax_id_number}</td>
                                                                        <td data-th="Specification">{tax.name}</td>
                                                                        <td data-th="Effective Date">
                                                                            {dayjs(tax.effective_date).format("DD/MM/YYYY")}
                                                                        </td>
                                                                        <td data-th="End Date">
                                                                            {tax.end_date ? dayjs(tax.end_date).format("DD/MM/YYYY") : "-"}
                                                                        </td>
                                                                    </tr>
                                                                ))}
                                                            </MDBTableBody>
                                                        </MDBTable>
                                                    </td>
                                                </tr>
                                            )}
                                        </MDBTableBody>
                                    </MDBTable>
                                )}
                            </CollapsedView>
                        </MDBCol>
                    </MDBRow>
                    <MDBRow className="mt-3">
                        <MDBCol>
                            <CollapsedView
                                toggleCollapsed={toggleCollapsed}
                                isCollapsed={cardState[1].isCollapsed}
                                idx={1}
                                title="Contacts"
                                isEdit={cardState[1].isEdit}
                                onEditClick={onEditClick}
                                editable={editable}
                            >
                                {cardState[1].isEdit ? (
                                    <Contact type="view" onEditClick={onEditClick} entityDetail={entitiesData} />
                                ) : (
                                    <MDBTable small borderless striped>
                                        <MDBTableBody>
                                            {entitiesData.location_details.length > 0 && (
                                                <tr>
                                                    <th width="25%" className="orange-text">
                                                        Address
                                                    </th>
                                                    <td>
                                                        <MDBRow>
                                                            {entitiesData.location_details.map(location => (
                                                                <MDBCol sm="12" key={location.id}>
                                                                    <span>{location.address_string}</span>{" "}
                                                                    {location.location_types.map(type => (
                                                                        <span
                                                                            key={type.id}
                                                                            className={`${styles.contactPill} ${styles[`type-${type.id}`]}`}
                                                                        >
                                                                            {type.name}
                                                                        </span>
                                                                    ))}
                                                                </MDBCol>
                                                            ))}
                                                        </MDBRow>
                                                    </td>
                                                </tr>
                                            )}
                                            {entitiesData.email_addresses.length > 0 && (
                                                <tr>
                                                    <th width="25%" className="orange-text">
                                                        Email
                                                    </th>
                                                    <td>
                                                        <MDBRow>
                                                            {entitiesData.email_addresses.map(address => (
                                                                <MDBCol sm="4" key={address.id}>
                                                                    <span>
                                                                        <a href={`mailto:${address.email_address}`}>{address.email_address}</a>
                                                                    </span>{" "}
                                                                    {address.contact_types.map(type => (
                                                                        <span
                                                                            key={type.id}
                                                                            className={`${styles.contactPill} ${styles[`type-${type.id}`]}`}
                                                                        >
                                                                            {type.name}
                                                                        </span>
                                                                    ))}
                                                                </MDBCol>
                                                            ))}
                                                        </MDBRow>
                                                    </td>
                                                </tr>
                                            )}

                                            {entitiesData.phone_numbers.length > 0 && (
                                                <tr>
                                                    <th width="25%" className="orange-text">
                                                        Phone Number
                                                    </th>
                                                    <td>
                                                        <MDBRow>
                                                            {entitiesData.phone_numbers.map(phone => (
                                                                <MDBCol sm="4" key={phone.id}>
                                                                    <span>{phone.phone_number}</span>{" "}
                                                                    {phone.contact_types.map(type => (
                                                                        <span
                                                                            key={type.id}
                                                                            className={`${styles.contactPill} ${styles[`type-${type.id}`]}`}
                                                                        >
                                                                            {type.name}
                                                                        </span>
                                                                    ))}
                                                                </MDBCol>
                                                            ))}
                                                        </MDBRow>
                                                    </td>
                                                </tr>
                                            )}
                                        </MDBTableBody>
                                    </MDBTable>
                                )}
                            </CollapsedView>
                        </MDBCol>
                    </MDBRow>
                    <MDBRow className="mt-3">
                        <MDBCol>
                            <CollapsedView
                                toggleCollapsed={toggleCollapsed}
                                isCollapsed={cardState[2].isCollapsed}
                                idx={2}
                                title="Current Attributes"
                                onEditClick={onEditClick}
                                isEdit={cardState[2].isEdit}
                                editable={editable}
                            >
                                {cardState[2].isEdit ? (
                                    <Attributes type="view" onEditClick={onEditClick} entityDetail={entitiesData} />
                                ) : (
                                    <MDBTable small borderless striped>
                                        <MDBTableBody>
                                            {entitiesData.relationship_details.length > 0 && (
                                                <tr>
                                                    <th width="25%" className="orange-text align-middle">
                                                        Relationships
                                                    </th>
                                                    <td>
                                                        <ul className={styles.capsuleList}>
                                                            {entitiesData.relationship_details.map(ele => (
                                                                <AttributeList key={ele.id} element={ele} />
                                                            ))}
                                                        </ul>
                                                    </td>
                                                </tr>
                                            )}
                                            {entitiesData.role_details.length > 0 && (
                                                <tr>
                                                    <th width="25%" className="orange-text align-middle">
                                                        Roles (Linings only)
                                                    </th>
                                                    <td>
                                                        <ul className={styles.roleList}>
                                                            {entitiesData.role_details.map(ele => (
                                                                <RolesList key={ele.id} element={ele} />
                                                            ))}
                                                        </ul>
                                                    </td>
                                                </tr>
                                            )}
                                            {entitiesData.assignment_details.length > 0 && (
                                                <tr>
                                                    <th width="25%" className="orange-text align-middle">
                                                        Assignments
                                                    </th>
                                                    <td>
                                                        <ul className={styles.capsuleList}>
                                                            {entitiesData.assignment_details.map(ele => (
                                                                <AttributeList key={ele.id} element={ele} />
                                                            ))}
                                                        </ul>
                                                    </td>
                                                </tr>
                                            )}
                                        </MDBTableBody>
                                    </MDBTable>
                                )}
                            </CollapsedView>
                        </MDBCol>
                    </MDBRow>
                    {administration && (
                        <MDBRow className="mt-3">
                            <MDBCol>
                                <CollapsedView
                                    toggleCollapsed={toggleCollapsed}
                                    isCollapsed={cardState[3].isCollapsed}
                                    idx={3}
                                    title="Current Worker Attributes"
                                    onEditClick={onEditClick}
                                    isEdit={cardState[3].isEdit}
                                    editable={editable}
                                >
                                    {cardState[3].isEdit ? (
                                        <WorkerAttributes
                                            type="view"
                                            role={entitiesData.roles}
                                            onEditClick={onEditClick}
                                            entityDetail={entitiesData}
                                        />
                                    ) : (
                                        <MDBTable small borderless striped>
                                            <MDBTableBody>
                                                {entitiesData.skill_details.length > 0 && (
                                                    <tr>
                                                        <th width="25%" className="orange-text align-middle">
                                                            Skills
                                                        </th>
                                                        <td>
                                                            <ul className={styles.capsuleList}>
                                                                {entitiesData.skill_details.map(ele => (
                                                                    <AttributeList key={ele.id} element={ele} />
                                                                ))}
                                                            </ul>
                                                        </td>
                                                    </tr>
                                                )}
                                                {entitiesData.qualification_details.length > 0 && (
                                                    <tr>
                                                        <th width="25%" className="orange-text align-middle">
                                                            Qualifications
                                                        </th>
                                                        <td>
                                                            <ul className={styles.capsuleList}>
                                                                {entitiesData.qualification_details.map(ele => (
                                                                    <AttributeList key={ele.id} element={ele} />
                                                                ))}
                                                            </ul>
                                                        </td>
                                                    </tr>
                                                )}
                                            </MDBTableBody>
                                        </MDBTable>
                                    )}
                                </CollapsedView>
                            </MDBCol>
                        </MDBRow>
                    )}
                    {entitiesData.entity_note && (
                        <MDBRow className="mt-3">
                            <MDBCol>
                                <CollapsedView
                                    toggleCollapsed={toggleCollapsed}
                                    isCollapsed={cardState[4].isCollapsed}
                                    idx={4}
                                    title="Notes"
                                    onAddNoteClick={e => {
                                        onAddNoteClick();
                                        toggleCollapsed(4, true);
                                    }}
                                    creatable={editable}
                                    keyInfo={
                                        entitiesData.entity_note.length > 0
                                            ? [
                                                  {
                                                      title: "Notes",
                                                      content: (
                                                          <>
                                                              <span className="orange-text">{noteInfo.pinned}</span> pinned &{" "}
                                                              <span className="orange-text">{noteInfo.normal}</span> normal(s)
                                                          </>
                                                      )
                                                  },
                                                  {
                                                      title: "First Note",
                                                      content: <span className={styles.ellipsisText}>{noteInfo.first}</span>
                                                  }
                                              ]
                                            : [{ title: "Notes", content: "No notes" }]
                                    }
                                >
                                    {entitiesData.entity_note.map(note => (
                                        <React.Fragment key={note.id || note.timestamp}>
                                            {note.isEdit ? (
                                                <NoteList
                                                    content={note.note}
                                                    pinned={note.pinned}
                                                    id={note.id}
                                                    entityID={entitiesData.id}
                                                    editNote={editNote}
                                                    onDelete={onDelete}
                                                    timestamp={note.timestamp}
                                                    type="edit"
                                                />
                                            ) : (
                                                <Card className="mb-2" variant="outlined">
                                                    <CardContent>
                                                        <Typography color="textSecondary" component="div">
                                                            {`Created ${dayjs(note.created).format("DD/MM/YYYY")} by ${note.creator_string}`}{" "}
                                                            {note.pinned && (
                                                                <span className={styles.pinned}>
                                                                    <MDBIcon className={`purple-text ${styles.rotate30Deg}`} icon="thumbtack" />{" "}
                                                                    Pinned
                                                                </span>
                                                            )}
                                                            {editable && (
                                                                <div className="float-right">
                                                                    <IconButton
                                                                        size="small"
                                                                        aria-label="edit"
                                                                        className="ml-1"
                                                                        color="primary"
                                                                        name={note.id}
                                                                        onClick={editNote}
                                                                    >
                                                                        <CreateIcon />
                                                                    </IconButton>
                                                                    <IconButton
                                                                        size="small"
                                                                        aria-label="edit"
                                                                        className="ml-1"
                                                                        color="secondary"
                                                                        name={note.id}
                                                                        onClick={deleteNote}
                                                                    >
                                                                        <DeleteForeverIcon />
                                                                    </IconButton>
                                                                </div>
                                                            )}
                                                        </Typography>
                                                        {dayjs(note.created).format("DD/MM/YYYY-HH:mm") !==
                                                            dayjs(note.last_modified).format("DD/MM/YYYY-HH:mm") && (
                                                            <Typography color="textSecondary" variant="caption" component="p">
                                                                {`Updated ${dayjs(note.last_modified).format("DD/MM/YYYY")} by ${
                                                                    note.last_modified_string
                                                                }`}
                                                            </Typography>
                                                        )}
                                                        <Typography variant="body2" component="p" gutterBottom className={styles.preWrap}>
                                                            {note.note}
                                                        </Typography>
                                                    </CardContent>
                                                </Card>
                                            )}
                                        </React.Fragment>
                                    ))}
                                </CollapsedView>
                            </MDBCol>
                        </MDBRow>
                    )}

                    <MDBRow className="mt-3">
                        <MDBCol>
                            <CollapsedView toggleCollapsed={toggleCollapsed} isCollapsed={cardState[5].isCollapsed} idx={5} title="Child Entities">
                                <MDBTable small borderless striped className={styles.detailResponsiveTable}>
                                    <MDBTableHead>
                                        <tr className="orange lighten-3">
                                            <th>Type</th>
                                            <th>Name</th>
                                            <th>Relationships</th>
                                            <th>Responsibilities</th>
                                            <th width="70px">Details</th>
                                        </tr>
                                    </MDBTableHead>
                                    <MDBTableBody>
                                        {entitiesData.children.map(child => (
                                            <tr key={child.id} className={styles.addItemContainer}>
                                                <td data-th="Type">{child.entity_type}</td>
                                                <td data-th="Name">{child.short_name ? `${child.name} (${child.short_name})` : child.name}</td>
                                                <td data-th="Relationships">
                                                    <ChipList parent={child.relationships || []} />
                                                </td>
                                                <td data-th="Responsibilities">
                                                    <ChipList parent={child.responsibilities || []} />
                                                </td>
                                                <td data-th="Details">
                                                    <Link to={`/crm/entities/${child.id}`}>
                                                        <MDBBtn className={styles.buttonSize} name={child.id} size="sm" color="primary">
                                                            <MDBIcon icon="info-circle" />
                                                        </MDBBtn>
                                                    </Link>
                                                </td>
                                            </tr>
                                        ))}
                                    </MDBTableBody>
                                </MDBTable>
                            </CollapsedView>
                        </MDBCol>
                    </MDBRow>
                    <SubLists entitiesData={entitiesData} />
                    <MDBRow>
                        <MDBCol>
                            <div className={`${styles.card} float-right ${styles.btnsInRow}`}>
                                {administration && entitiesData.pending ? (
                                    <>
                                        <MDBBtn color="primary" onClick={approveEntity}>
                                            Approve
                                        </MDBBtn>{" "}
                                        <MDBBtn color="danger" onClick={rejectEntity}>
                                            Reject
                                        </MDBBtn>
                                    </>
                                ) : (
                                    ""
                                )}{" "}
                                <MDBBtn color="blue-grey" onClick={returnToList}>
                                    List
                                </MDBBtn>
                            </div>
                        </MDBCol>
                    </MDBRow>
                </>
            )}
        </div>
    );
};

EntityView.defaultProps = {
    entitiesData: { attribute: [], children: [] },
    entity_note: []
};

export default EntityView;
