import React, { memo, useEffect, useState } from "react";
import styles from "./styles.scss";
import Tooltip from "@mui/material/Tooltip";
import { useSelector } from "react-redux";

const IndicatorList = ({ job, isList = false, mjs = false }) => {
    const {
        material: { mygibOrdered }
    } = useSelector(state => state);
    const [indicators, setIndicators] = useState([]);
    useEffect(() => {
        const indi = [];
        if (mjs) {
            // task list
            if (job?.works_complete) {
                indi.push({ idx: 1, name: "Work Completed", color: "purple" });
            }
            if (job?.dayworks) {
                indi.push({ idx: 2, name: "Dayworks", color: "pink accent-1" });
            }
            if (job?.piece_rates) {
                indi.push({ idx: 3, name: "Piece Rate", color: "light-green" });
            }
            if (job?.billing_state === 2) {
                indi.push({ idx: 4, name: "Partially Billed", color: "amber" });
            }
        } else if (!mjs) {
            // job list
            if (job?.deposit_required || !!job?.qs_checklist?.find(check => check.check_item === 4)) {
                indi.push({ idx: 1, name: "Deposit required", color: "amber" });
            }
            if (job?.business_category_name === "Maintenance" || job?.business_category === 5) {
                indi.push({ idx: 2, name: "Maintenance", color: "pink accent-1" });
            }
            if (job?.under_review) {
                indi.push({ idx: 3, name: "Under Review", color: "light-blue" });
            }
            if (job?.has_long_lead_products || job?.long_lead_products?.length > 0) {
                indi.push({ idx: 4, name: "Long Lead Products", color: "lime" });
            }
            if (job?.business_category_name === "Provisional" || job?.business_category === 6) {
                indi.push({ idx: 5, name: "Provisional", color: "mdb-color darken-2" });
            }
            if (job?.mygib_order_exists) {
                indi.push({ idx: 8, name: "MyGIB Order Exists", color: "purple lighten-1" });
            }
            // only in job view
            if (!isList) {
                if (job?.fire_on_job) {
                    indi.push({ idx: 6, name: "Fire On Job", color: styles.fireOnJob });
                }
                if (job?.vaccine_required) {
                    indi.push({ idx: 7, name: "Vaccine Required", color: styles.vaccineRequired });
                }
                if (mygibOrdered) {
                    indi.push({ idx: 8, name: "MyGIB Order Exists", color: "purple lighten-1" });
                }
            }
        }

        setIndicators(indi);
    }, [job, mjs, isList, mygibOrdered]);
    return (
        <div className={`${styles.statusContainer} ${!isList ? styles.isOnTop : ""}`}>
            {indicators.map(indicator => (
                <Tooltip key={indicator.idx} title={indicator.name} placement={isList ? "left" : "bottom"} enterTouchDelay={0}>
                    <div className={`${styles.status}`}>
                        <span className={indicator.color}>{indicator.name}</span>
                    </div>
                </Tooltip>
            ))}
        </div>
    );
};

export default memo(IndicatorList);
