import React, { memo, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { MDBRow, MDBCol, MDBBtn, MDBBtnGroup } from "louis-mdbreact";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { actionCreators as crmActions } from "redux/modules/crm";
import { actionCreators as userActions } from "redux/modules/user";
import WindowedSelect from "react-windowed-select";

const NoteList = ({ content, pinned, id, entityID, type, onDelete, onEdit, timestamp, editNote }) => {
    const [noteContent, setContent] = useState({ note: content, pinned });
    const [isValidated, setIsValidated] = useState(false);
    const [users, setUsers] = useState([]);
    const [notifyUsers, setNotifyUsers] = useState([]);
    const dispatch = useDispatch();
    useEffect(() => {
        (async () => {
            const users = await dispatch(userActions.getGlobalUsers());
            setUsers(users);
        })();
    }, [dispatch]);
    const submitNote = event => {
        setIsValidated(true);
        if (noteContent.note.trim() !== "") {
            dispatch(
                crmActions.submitLink("entity_note", entityID, {
                    ...noteContent,
                    id: id || undefined,
                    notify: notifyUsers.length > 0 ? notifyUsers.map(user => user.id) : undefined
                })
            );
        } else {
            alert("Please write down all required field.");
        }
    };

    return (
        <div className={`mb-2 ${type === "edit" && isValidated ? "was-validated" : ""}`}>
            <MDBRow>
                <MDBCol size="12">
                    <textarea
                        className="form-control"
                        rows="3"
                        name="note"
                        required
                        value={type === "edit" ? noteContent.note : content}
                        onChange={e =>
                            type === "edit"
                                ? setContent({ ...noteContent, note: e.target.value })
                                : onEdit(timestamp, { name: e.target.name, value: e.target.value })
                        }
                    />
                </MDBCol>
            </MDBRow>
            <MDBRow>
                <MDBCol size="12" className="mt-1" style={{ zIndex: "2" }}>
                    <WindowedSelect
                        options={users.filter(user => user.active)}
                        isClearable
                        isMulti
                        value={notifyUsers}
                        onChange={options => setNotifyUsers(options)}
                        placeholder="Select who gets notification ..."
                        getOptionLabel={option => option.name_string}
                        getOptionValue={option => option.id}
                    />
                </MDBCol>
            </MDBRow>
            <MDBRow>
                <MDBCol size="12">
                    <FormControlLabel
                        control={
                            <Checkbox
                                color="primary"
                                checked={type === "edit" ? noteContent.pinned : pinned}
                                name="pinned"
                                onChange={e =>
                                    type === "edit"
                                        ? setContent({ ...noteContent, pinned: e.target.checked })
                                        : onEdit(timestamp, {
                                              name: e.target.name,
                                              value: e.target.checked
                                          })
                                }
                            />
                        }
                        label="Pinned"
                        className="mb-0"
                    />
                    <div className="float-right mt-1">
                        <MDBBtnGroup>
                            {timestamp ? (
                                <>
                                    <MDBBtn size="sm" color="danger" onClick={onDelete} name={timestamp}>
                                        Delete
                                    </MDBBtn>
                                    {entityID && (
                                        <MDBBtn size="sm" type="button" color="primary" onClick={submitNote}>
                                            Add new
                                        </MDBBtn>
                                    )}
                                </>
                            ) : (
                                <>
                                    <MDBBtn size="sm" type="button" color="blue-grey" name={0} onClick={editNote}>
                                        Cancel
                                    </MDBBtn>
                                    <MDBBtn size="sm" type="button" color="primary" onClick={submitNote}>
                                        Change
                                    </MDBBtn>
                                </>
                            )}
                        </MDBBtnGroup>
                    </div>
                </MDBCol>
            </MDBRow>
        </div>
    );
};

export default memo(NoteList);
