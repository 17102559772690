import React, { Component } from "react";
import EntityView from "./presenter";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { Helmet } from "react-helmet";

class Container extends Component {
    constructor(props) {
        super(props);

        const {
            entitiesData,
            entity_note,
            match: { params },
            isQS,
            isSLT
        } = props;
        const reEntered = entitiesData.id === Number(params.id);
        this.state = {
            isBackdrop: false,
            ableToSeeInvoice:
                !entitiesData.pending &&
                !!entitiesData?.relationship_details?.find(relationship => relationship.relationship === 4 && !relationship.pending) &&
                (isQS || isSLT),
            isLoading: reEntered ? false : true,
            entitiesData,
            entity_note,
            parents: reEntered ? this.getParents() : [],
            cardState: [
                { isCollapsed: true, isEdit: false, isSave: false },
                { isCollapsed: true, isEdit: false, isSave: false },
                { isCollapsed: true, isEdit: false, isSave: false },
                { isCollapsed: true, isEdit: false, isSave: false },
                { isCollapsed: false, isEdit: false, isSave: false },
                { isCollapsed: false, isEdit: false, isSave: false }
            ],
            noteInfo: reEntered && entitiesData.entity_note.length > 0 ? this.getNotesInfo() : {}
        };
    }
    componentDidMount() {
        const {
            match: { params },
            getEntityDetail,
            getEntityCommons,
            getTaxCommons,
            getContactCommons,
            getAttrCommons,
            getWorkerAttrCommons,
            entitiesData,
            getGlobalUsers
        } = this.props;
        if (entitiesData.id !== Number(params.id)) {
            getEntityDetail(params.id);
            getEntityCommons();
            getTaxCommons();
            getContactCommons();
            getAttrCommons();
            getWorkerAttrCommons();
            getGlobalUsers();
        }
        window.scrollTo(0, 0);
    }

    componentDidUpdate(prevProps) {
        const { entitiesData, isQS, isSLT } = this.props;
        if (JSON.stringify(prevProps.entitiesData) !== JSON.stringify(entitiesData)) {
            const ableToSeeInvoice =
                !entitiesData.pending &&
                !!entitiesData?.relationship_details?.find(relationship => relationship.relationship === 4 && !relationship.pending) &&
                (isQS || isSLT);
            this.setState({
                ableToSeeInvoice,
                entitiesData,
                parents: this.getParents(),
                noteInfo: entitiesData.entity_note.length > 0 && this.getNotesInfo(),
                isLoading: false
            });
        }
    }

    getParents = () => {
        //let temp = [];
        const { entitiesData } = this.props;
        // const getParent = parent => {
        //     temp = [parent, ...temp];
        //     if (parent.parent) {
        //         getParent(parent.parent);
        //     }
        //     return temp;
        // };

        // return getParent([{id:entitiesData.parent, name:entitiesData.parent_name_string}]);
        return entitiesData.parent ? [{ id: entitiesData.parent, name: entitiesData.parent_name_string }] : null;
    };

    getNotesInfo = () => {
        const {
            entitiesData: { entity_note }
        } = this.props;
        const info = {
            pinned: entity_note.filter(note => note.pinned).length,
            normal: entity_note.filter(note => !note.pinned).length,
            first: entity_note.find(note => note.pinned || !note.pinned).note
        };
        return info;
    };

    editNote = event => {
        const {
            currentTarget: { name }
        } = event;
        const { entitiesData } = this.state;
        this.setState({
            entitiesData: {
                ...entitiesData,
                entity_note: entitiesData.entity_note.map(note => ({
                    ...note,
                    isEdit: Number(name) === note.id || !note.id
                }))
            }
        });
    };

    onDelete = event => {
        const {
            currentTarget: { name }
        } = event;
        const {
            entitiesData: { entity_note }
        } = this.state;
        this.setState({
            entitiesData: {
                ...this.state.entitiesData,
                entity_note: entity_note.filter(note => note.timestamp !== Number(name))
            }
        });
    };

    onAddNoteClick = () => {
        const {
            entitiesData: { entity_note }
        } = this.state;
        this.setState({
            entitiesData: {
                ...this.state.entitiesData,
                entity_note: [
                    {
                        note: "",
                        pinned: false,
                        timestamp: Number(new Date()),
                        isEdit: true
                    },
                    ...entity_note
                ]
            }
        });
    };

    deleteNote = event => {
        const {
            currentTarget: { name }
        } = event;
        const { deleteLink, entitiesData } = this.props;
        const confirm = window.confirm("Are you sure you delete this item completely?");
        if (confirm) {
            deleteLink("entity_note", entitiesData.id, Number(name));
        }
    };

    toggleCollapsed = (idx, isNew = false) => {
        const { cardState } = this.state;
        cardState[idx].isCollapsed = isNew ? true : !cardState[idx].isCollapsed;
        this.setState({
            cardState
        });
    };

    onEditClick = idx => {
        const { cardState } = this.state;
        cardState[idx].isEdit = !cardState[idx].isEdit;
        this.setState({
            cardState
        });
    };

    onSaveClick = idx => {
        const { cardState } = this.state;
        cardState[idx].isSave = !cardState[idx].isSave;
        this.setState({
            cardState
        });
    };

    approveEntity = async () => {
        const { submitLink, submitEntity, entitiesData } = this.props;
        if (entitiesData.relationship_details && entitiesData.relationship_details.length > 0) {
            await entitiesData.relationship_details.forEach(relationship => {
                relationship.name === "Client" &&
                    relationship.pending &&
                    submitLink("entity_relationship_link", entitiesData.id, { ...relationship, pending: false }, true);
            });
        }
        await submitEntity({
            ...entitiesData,
            responsibilities: entitiesData.responsibilities.map(res => res.id),
            pending: false
        });
    };

    rejectEntity = async () => {
        const { entitiesData, deleteFullEntity, getEntityDetail } = this.props;
        if (entitiesData.children.length > 0) {
            const confirm = window.confirm(
                `This entity has ${entitiesData.children.length} children. If you click "OK", they also will be deleted. Do you want to proceed?`
            );
            if (confirm) {
                this.setState({ isBackdrop: true });
                const totalDetails = await Promise.all(entitiesData.children.map(child => getEntityDetail(child.id, true)));
                await Promise.all(totalDetails.map(detail => deleteFullEntity(detail).then(value => value)));
                await deleteFullEntity(entitiesData).then(value => {
                    if (value.message === "deleted") {
                        this.returnToList();
                    }
                });
            }
        } else {
            const confirm = window.confirm('If you click "OK", this entity will be completely deleted. Do you want to proceed?');
            if (confirm) {
                this.setState({ isBackdrop: true });
                deleteFullEntity(entitiesData).then(value => {
                    if (value.message === "deleted") {
                        this.returnToList();
                    }
                });
            }
        }
    };

    returnToList = () => {
        const { history, query } = this.props;
        const url = query.from_relationship ? `/crm/${query.from_relationship}` : "/crm";
        history.push(url);
    };

    render() {
        const { permissions, isAdmin, entitiesData, globalUsers } = this.props;
        const { isBackdrop } = this.state;
        return (
            <>
                <Helmet>
                    <title>CRM | Entity | {entitiesData?.name || ""}</title>
                </Helmet>
                <EntityView
                    {...this.state}
                    permissions={permissions}
                    isAdmin={isAdmin}
                    globalUsers={globalUsers}
                    toggleCollapsed={this.toggleCollapsed}
                    returnToList={this.returnToList}
                    editNote={this.editNote}
                    onDelete={this.onDelete}
                    onEditClick={this.onEditClick}
                    onSaveClick={this.onSaveClick}
                    onAddNoteClick={this.onAddNoteClick}
                    deleteNote={this.deleteNote}
                    approveEntity={this.approveEntity}
                    rejectEntity={this.rejectEntity}
                />
                <Backdrop open={isBackdrop}>
                    <CircularProgress color="inherit" />
                </Backdrop>
            </>
        );
    }
}

export default Container;
