import { connect } from "react-redux";
import Container from "./container";
import { withRouter } from "react-router";
import { actionCreators as operationActions } from "redux/modules/operations";

const mapStateToProps = (state, ownProps) => {
    const {
        user: { isMobile, permissions },
        operations: { datesOnActive }
    } = state;
    const { selectedJob } = ownProps;
    return {
        permissions,
        isMobile,
        selectedJob,
        datesOnActive
    };
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        getActiveDates: id => dispatch(operationActions.getActiveDates(id))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Container));
