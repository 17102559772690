import React, { memo, useState, useEffect } from "react";
import { MDBCard, MDBCardHeader, MDBCardBody, MDBBtn, MDBTable, MDBTableHead, MDBTableBody, MDBIcon } from "louis-mdbreact";
import styles from "shared/details.scss";
import { useSelector, useDispatch } from "react-redux";
import { actionCreators as crmActions } from "redux/modules/crm";
import { getTaxCommonrefined } from "redux/reducers";

const TaxSpec = ({ type, getSendingData, entityDetail }) => {
    const [sendingTax, setsendingTax] = useState(
        entityDetail
            ? entityDetail.tax_spec_details.map((tax, i) => ({
                  ...tax,
                  end_date: tax.end_date || "",
                  timestamp: Number(new Date()) + i
              }))
            : []
    );

    const dispatch = useDispatch();

    const state = useSelector(state => state);
    const taxCommons = getTaxCommonrefined(state);
    const { tax_specification } = taxCommons;
    const [isValidated, setIsValidated] = useState();

    useEffect(() => {
        setsendingTax(
            entityDetail
                ? entityDetail.tax_spec_details.map((tax, i) => ({
                      ...tax,
                      timestamp: Number(new Date()) + i
                  }))
                : []
        );
    }, [entityDetail]);

    useEffect(() => {
        dispatch(crmActions.getTaxCommons());
    }, [dispatch]);

    useEffect(() => {
        const validated = sendingTax.reduce((bool, tax) => !!(bool && tax.effective_date), true);
        type === "new" &&
            getSendingData(
                "tax",
                sendingTax.map(sTax => ({ ...sTax, end_date: sTax.end_date || undefined })),
                validated
            );
    }, [sendingTax, getSendingData, type]);

    const addTax = event => {
        setsendingTax([
            ...sendingTax,
            {
                timestamp: Number(new Date()),
                tax_id_number: "",
                tax_specification: 1,
                effective_date: "",
                end_date: ""
            }
        ]);
    };

    const onEditTaxSpec = event => {
        const {
            currentTarget: { name, value }
        } = event;
        const splitName = name.split("-");
        const newTax = sendingTax.map(tax => (tax.timestamp === Number(splitName[1]) ? { ...tax, [splitName[0]]: value } : tax));
        setsendingTax(newTax);
    };
    const onDeleteTaxSpec = selectedTax => {
        if (selectedTax.id) {
            const confirm = window.confirm("Are you sure you delete this item completely?");
            if (confirm) {
                dispatch(crmActions.deleteLink("entity_tax_specification_link", entityDetail.id, selectedTax.id));
            }
        }
        const newTax = sendingTax.filter(tax => selectedTax.timestamp !== tax.timestamp);
        setsendingTax(newTax);
    };
    const submitLink = ({ tax }) => {
        setIsValidated(true);
        if (tax.effective_date === "") {
            alert("Please write down all required field.");
            return;
        }
        setsendingTax(sendingTax.map(sTax => (sTax.id === tax.id ? { ...tax, isLoading: true } : sTax)));
        dispatch(
            crmActions.submitLink("entity_tax_specification_link", entityDetail.id, {
                ...tax,
                end_date: tax.end_date || undefined
            })
        );
    };
    return (
        <MDBCard className={`${styles.card} ${type === "view" ? styles.flatCard : ""} ${isValidated ? "was-validated" : ""}`}>
            <MDBCardHeader>
                Tax Detail
                <div className="position-absolute" style={{ right: "15px", top: "9px" }}>
                    <MDBBtn color="primary" size="sm" name="note" onClick={addTax}>
                        add tax
                    </MDBBtn>
                </div>
            </MDBCardHeader>
            <MDBCardBody>
                <MDBTable small className={styles.detailResponsiveTable}>
                    <MDBTableHead>
                        <tr>
                            <th>Tax ID</th>
                            <th>Tax Specification</th>
                            <th>Effective Date</th>
                            <th>End Date</th>
                            <th className="text-center" width="100px">
                                Action
                            </th>
                        </tr>
                    </MDBTableHead>
                    <MDBTableBody>
                        {sendingTax.map(tax => (
                            <tr key={tax.timestamp} className={styles.addItemContainer}>
                                <td data-th="Tax ID">
                                    <input
                                        type="text"
                                        className="form-control"
                                        name={`tax_id_number-${tax.timestamp}`}
                                        value={tax.tax_id_number}
                                        onChange={onEditTaxSpec}
                                    />
                                </td>
                                <td data-th="Tax Specification">
                                    <select
                                        className="custom-select"
                                        name={`tax_specification-${tax.timestamp}`}
                                        value={tax.tax_specification}
                                        onChange={onEditTaxSpec}
                                    >
                                        {tax_specification.map(spec => (
                                            <option key={spec.id} value={spec.id}>
                                                {spec.name}
                                            </option>
                                        ))}
                                    </select>
                                </td>
                                <td data-th="Effective Date">
                                    <input
                                        type="date"
                                        className="form-control"
                                        name={`effective_date-${tax.timestamp}`}
                                        value={tax.effective_date}
                                        onChange={onEditTaxSpec}
                                        required
                                    />
                                </td>
                                <td data-th="End Date">
                                    <input
                                        type="date"
                                        className="form-control"
                                        name={`end_date-${tax.timestamp}`}
                                        value={tax.end_date || ""}
                                        onChange={onEditTaxSpec}
                                    />
                                </td>
                                <td data-th="Action" className="text-center">
                                    {type === "view" && (
                                        <MDBBtn
                                            className={styles.buttonSize}
                                            color={tax.id ? "secondary" : "primary"}
                                            onClick={() => submitLink({ tax })}
                                        >
                                            {tax.isLoading ? (
                                                <div className="spinner-border spinner-border-sm text-white" role="status">
                                                    <span className="sr-only">Loading...</span>
                                                </div>
                                            ) : (
                                                <>{tax.id ? <MDBIcon icon="pencil-alt" /> : <MDBIcon far icon="save" />}</>
                                            )}
                                        </MDBBtn>
                                    )}
                                    <MDBBtn className={styles.buttonSize} onClick={e => onDeleteTaxSpec(tax)} name={tax.timestamp} color="danger">
                                        <MDBIcon icon="trash-alt" />
                                    </MDBBtn>
                                </td>
                            </tr>
                        ))}
                    </MDBTableBody>
                </MDBTable>
            </MDBCardBody>
        </MDBCard>
    );
};

export default memo(TaxSpec);
