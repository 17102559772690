import React, { Component } from "react";
import DiaryDetail from "./presenter";
import { Prompt } from "react-router";
import dayjs from "shared/dayjs";
import _ from "lodash";
import { imageLoad } from "shared/Utils";
import ConfirmModal from "components/modals/ConfirmModal";
import { axiosInstance } from "shared/axiosInst";
import { Helmet } from "react-helmet";

class Container extends Component {
    constructor(props) {
        super(props);
        const { query } = props;
        this.state = {
            pendingLoading: false,
            isDateChanged: false,
            isEditable: false,
            isBlock: false,
            isLoading: true,
            jobDetailLoading: false,
            items: [],
            togglePreview: false,
            previewImage: "",
            previewButtons: [],
            weatherTime: [
                { id: 1, time: "AM", name: "weather_first_half" },
                { id: 2, time: "PM", name: "weather_second_half" }
            ],
            compareData: "",
            sendingData: {
                ...this.initialSendingData,
                diary_date: query.date || dayjs(new Date()).format("YYYY-MM-DD")
            }
        };
        this.submitRef = React.createRef();
        this.abortController = new AbortController();
        this.abortSignal = this.abortController.signal;
    }

    initialSendingData = {
        job: "",
        labour_note: "",
        weather_first_half: 1,
        weather_second_half: 1,
        activity_on_job: true,
        diary_note: "",
        site_report: "",
        short_term_plan: "",
        medium_term_plan: "",
        files: []
    };

    static getDerivedStateFromProps = props => {
        const {
            match: { params }
        } = props;

        if (params.id) {
            return {
                type: "detail",
                title: "SITE DIARY DETAILS"
            };
        } else {
            return {
                type: "new",
                title: "NEW SITE DIARY"
            };
        }
    };

    componentDidMount = () => {
        const { labourType, getOpsCommons, getSlimJobsNoDiary } = this.props;
        const {
            sendingData: { diary_date },
            type
        } = this.state;

        if (labourType) {
            this.initialise();
            if (diary_date && type === "new") {
                getSlimJobsNoDiary(diary_date);
            }
        } else {
            getOpsCommons(true);
        }
    };

    componentDidUpdate = prevProps => {
        const { diaryDetail, jobList, query } = this.props;
        const { type, sendingData, isDateChanged } = this.state;
        if (!isDateChanged && jobList !== prevProps.jobList) {
            this.setState({
                sendingData: {
                    ...sendingData,
                    job: type === "new" && query.job ? jobList.find(list => list.value === Number(query.job)) : sendingData.job
                }
            });
        }

        if (diaryDetail !== prevProps.diaryDetail) {
            this.setDetailData();
        }
    };

    initialise = () => {
        const {
            match: { params },
            getDiaryDetail,
            labourType
        } = this.props;

        this.setState(
            {
                isLoading: params.id ? true : false,
                sendingData: {
                    ...this.state.sendingData,
                    primary_labour: labourType.map(type => ({
                        labour_type: type.id,
                        number_of_workers: "",
                        name: type.name
                    }))
                }
            },
            () => {
                if (params.id) {
                    getDiaryDetail(params.id);
                }
                this.setState({
                    compareData: JSON.stringify(this.state.sendingData)
                });
            }
        );
    };

    setDetailData = () => {
        const { diaryDetail, companyPrefix, getSlimJobsNoDiary } = this.props;
        const { sendingData } = this.state;
        getSlimJobsNoDiary(diaryDetail.diary_date);
        const diffDay = dayjs(diaryDetail.diary_date).diff(new Date(), "day");
        this.setState(
            {
                isLoading: false,
                isEditable: diffDay > -2,
                sendingData: {
                    ...diaryDetail,
                    job: {
                        value: diaryDetail.job,
                        label: `${companyPrefix}-${diaryDetail.job}: ${diaryDetail.job_name} ${diaryDetail.job_name ? "- " : ""}${
                            diaryDetail.client_string
                        }`
                    },
                    primary_labour: sendingData.primary_labour.map(labour => {
                        const existedLabour = diaryDetail.primary_labour.find(detailLabour => detailLabour.labour_type === labour.labour_type);
                        if (existedLabour) {
                            return {
                                ...existedLabour,
                                name: labour.name
                            };
                        } else {
                            return { ...labour };
                        }
                    })
                }
            },
            () => {
                const { sendingData } = this.state;
                this.onJobChange(sendingData.job);
                this.setState({ compareData: JSON.stringify(sendingData) });
            }
        );
    };

    onJobChange = option => {
        const { currentCompany, resourcesList } = this.props;
        this.setState(
            {
                sendingData: {
                    ...this.state.sendingData,
                    job: option
                },
                jobDetailLoading: true
            },
            () => {
                this.setIsBlock();
                axiosInstance.get(`${currentCompany}job/job/${option.value}/`).then(value => {
                    this.setState({
                        jobDetailDiary: {
                            ...value.data,
                            resource_string: resourcesList.find(res => res.id === value.data.resource).legal_name
                        },
                        jobDetailLoading: false
                    });
                });
            }
        );
    };

    onLabourChange = event => {
        const {
            target: { name, value }
        } = event;

        const {
            sendingData: { primary_labour }
        } = this.state;
        primary_labour.find(type => type.name === name).number_of_workers = value;
        this.setState(
            {
                sendingData: {
                    ...this.state.sendingData,
                    primary_labour
                }
            },
            () => this.setIsBlock()
        );
    };

    onActivityChange = event => {
        const {
            target: { checked }
        } = event;
        this.setState({ sendingData: { ...this.state.sendingData, activity_on_job: checked } }, () => this.setIsBlock());
    };

    onValueChange = event => {
        const {
            target: { name, value }
        } = event;
        const { getSlimJobsNoDiary } = this.props;
        if (name === "diary_date") {
            getSlimJobsNoDiary(value);
            this.setState(
                {
                    isDateChanged: true,
                    jobDetailDiary: "",
                    sendingData: {
                        ...this.state.sendingData,
                        ...this.initialSendingData,
                        diary_date: value
                    }
                },
                () => this.initialise()
            );
        } else {
            this.setState({
                sendingData: {
                    ...this.state.sendingData,
                    [name]: value
                }
            });
        }
    };

    onFileChange = async event => {
        const {
            target: { name, files }
        } = event;
        const file_uploaded = [];
        for (let i = 0; i < files.length; i++) {
            if (files[i].type.search(/image/) >= 0) {
                if (this.abortSignal.aborted) {
                    break;
                }
                const img = await imageLoad(URL.createObjectURL(files[i]), files[i].name, files[i].type, this.abortSignal);
                this.saveImages(name, {
                    f: img.file,
                    src: img.image,
                    thumb: img.thumb,
                    note: "",
                    id: Number(new Date()) + i
                });
            } else {
                this.saveImages(name, {
                    f: files[i],
                    note: "",
                    id: Number(new Date()) + i
                });
            }
            file_uploaded.push({
                f: files[i],
                src: files[i].type.search(/image/) >= 0 ? URL.createObjectURL(files[i]) : "",
                note: "",
                id: Number(new Date()) + i
            });
        }
    };

    saveImages = (name, image) => {
        const { sendingData } = this.state;
        const elementName = name.split("--")[0];
        const idx = name.split("--")[1];

        sendingData[elementName][idx].files.push(image);
        this.setState(
            {
                sendingData
            },
            () => this.setIsBlock()
        );
    };

    togglePreviewPop = (event, idx) => {
        const { togglePreview } = this.state;
        if (!togglePreview) {
            const {
                currentTarget: { name, value }
            } = event;
            this.setState({
                previewImage: <img width="100%" src={value} alt={name} />,
                previewButtons: [{ name: "Close", colour: "indigo", func_id: 1 }],
                togglePreview: !togglePreview
            });
        } else {
            this.setState({
                togglePreview: !togglePreview
            });
        }
    };

    fileClickTrigger = event => {
        document.getElementById(event.currentTarget.name).click();
    };

    returnToList = e => {
        const { history } = this.props;
        history.push("/operations/diary");
    };

    diarySubmit = e => {
        e.preventDefault();
        this.setState(
            {
                isValidated: true,
                isBlock: false
            },
            () => {
                const { postDiary, history } = this.props;
                const { sendingData, items } = this.state;
                if (
                    !sendingData.job ||
                    !sendingData.diary_date ||
                    (sendingData.activity_on_job && !sendingData.weather_first_half) ||
                    (sendingData.activity_on_job && !sendingData.weather_second_half)
                ) {
                    return false;
                }

                const submitData = sendingData.activity_on_job
                    ? {
                          ...sendingData,
                          items: items,
                          job: sendingData.job.value,
                          primary_labour: sendingData.primary_labour.map(labour => ({
                              ...labour,
                              number_of_workers: labour.number_of_workers || 0
                          }))
                      }
                    : {
                          id: sendingData.id,
                          items: items,
                          job: sendingData.job.value,
                          diary_date: sendingData.diary_date,
                          activity_on_job: sendingData.activity_on_job
                      };

                postDiary(submitData).then(value => {
                    if (String(value).match(/400/)) {
                        this.submitRef.current.disabled = false;
                        this.setState({
                            pendingLoading: false,
                            isValidated: false
                        });
                    }

                    if (value.message === "upload ended") {
                        history.push("/operations/diary");
                    }
                });
            }
        );
    };

    addItem = event => {
        event.preventDefault();
        const timestamp = new Date().getTime();
        this.setState(
            prevState => ({
                items: [
                    ...prevState.items,
                    {
                        new_file: "",
                        time: timestamp
                    }
                ]
            }),
            () => this.setIsBlock()
        );
    };

    removeItem = (event, idx) => {
        event.preventDefault();
        const { items } = this.state;

        const index = _.findIndex(items, n => {
            return n.time === idx;
        });

        items.splice(index, 1);

        this.setState(
            prevState => ({
                ...prevState,
                items
            }),
            () => this.setIsBlock()
        );
    };

    onGenericFileChange = (event, idx) => {
        event.preventDefault();
        const { items } = this.state;

        const index = _.findIndex(items, n => {
            return n.time === idx;
        });
        if (event.target.name === "new_file") {
            if (event.target.files[0]) {
                items[index].new_file = event.target.files[0];
            }
        } else {
            items[index].file_category = event.target.value;
        }
        this.setState(prevState => ({
            ...prevState,
            items
        }));
    };

    downloadImage = event => {
        const {
            currentTarget: { value }
        } = event;
        const { fileDownload } = this.props;
        fileDownload(value);
    };

    componentWillUnmount = () => {
        this.abortController.abort();
    };

    setIsBlock = () => {
        const { sendingData, compareData } = this.state;
        let isBlock = false;
        if (JSON.stringify(sendingData) !== compareData) {
            isBlock = true;
        } else {
            isBlock = false;
        }

        this.setState({
            isBlock
        });
    };

    render = () => {
        const { jobList, weatherType, permissions } = this.props;
        const { togglePreview, previewImage, previewButtons, title, type, jobDetailDiary } = this.state;
        return (
            <>
                <Helmet>
                    <title>
                        Operations | {title} {jobDetailDiary && type === "detail" ? `> ${jobDetailDiary.job_name}` : ""}
                    </title>
                </Helmet>
                <DiaryDetail
                    {...this.state}
                    jobList={jobList}
                    weatherType={weatherType}
                    permissions={permissions}
                    forwardRef={this.submitRef}
                    returnToList={this.returnToList}
                    onActivityChange={this.onActivityChange}
                    onJobChange={this.onJobChange}
                    onValueChange={this.onValueChange}
                    onLabourChange={this.onLabourChange}
                    onFileChange={this.onFileChange}
                    downloadImage={this.downloadImage}
                    diarySubmit={this.diarySubmit}
                    onGenericFileChange={this.onGenericFileChange}
                    addItem={this.addItem}
                    removeItem={this.removeItem}
                    togglePreviewPop={this.togglePreviewPop}
                    fileClickTrigger={this.fileClickTrigger}
                />
                <ConfirmModal modal={togglePreview} message={previewImage} buttons={previewButtons} title="Preview" toggle={this.togglePreviewPop} />
                <Prompt when={this.state.isBlock} message="Changes that you made may not be saved." />
            </>
        );
    };
}

export default Container;
