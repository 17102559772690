import React from "react";
import ExpandedStageItem from "./ExpandedStageItem";
import StageItem from "./StageItem";

const StageItemContainer = ({ stage, index, title }) => {
    return stage.isExpandable ? (
        <ExpandedStageItem stage={stage} index={index} title={title} />
    ) : (
        <StageItem stage={stage} index={index} title={title} />
    );
};

export default StageItemContainer;
