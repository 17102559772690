import React, { Component } from "react";
import ToolboxTalk from "./presenter";
import dayjs from "shared/dayjs";
import SignModal from "components/modals/SignModal";
import { imageLoad } from "shared/Utils";

class Container extends Component {
    constructor(props) {
        super(props);
        const {
            match: { params },
            permissions,
            jobDetail,
            type,
            formDetail
        } = props;

        this.state = {
            modal: false,
            isLoading: true,
            pageType: params.id ? "detail" : "new",
            permissionLevel: permissions.operations,
            totalAttendeeList: [],
            attendeeList: [],
            files: [],
            existedFiles: [],
            formData: {
                id: formDetail.id || null,
                job: jobDetail.id,
                form_type: type.id,
                form_date: params.id ? formDetail.form_date : dayjs(new Date()).format("YYYY-MM-DD")
            }
        };

        this.abortController = new AbortController();
        this.abortSignal = this.abortController.signal;
    }

    componentDidMount() {
        const {
            type: { id },
            getFormElement,
            attendees,
            getAttendees
        } = this.props;
        if (!attendees) {
            getAttendees();
        } else {
            this.initAttendees();
        }
        getFormElement(id).then(value => this.initialise(value));
    }

    componentDidUpdate(prevProps) {
        const { attendees, jobDetail } = this.props;
        const { formData } = this.state;
        if (jobDetail !== prevProps.jobDetail) {
            this.setState(
                {
                    formData: { ...formData, job: jobDetail.id }
                },
                () => this.updateFormData()
            );
        }
        if (attendees !== prevProps.attendees) {
            this.initAttendees();
        }
    }

    initAttendees = () => {
        const { attendees, formDetail, assignedData } = this.props;
        const { form_content, pageType } = this.state;
        const timestamp = Number(new Date());
        let totalAttendeeList = [];
        let attendeeList = [];
        if (pageType === "new") {
            if (assignedData && assignedData.storedAttendees.length > 0) {
                totalAttendeeList = attendees.filter(
                    attendee => !assignedData.storedAttendees.find(a => (a.attendee_entity || a.attendee) === attendee.value)
                );
                attendeeList = assignedData.storedAttendees
                    .filter(attendee => !attendee.removed)
                    .map(attendee => {
                        const selectedAttendee = attendees.find(a => a.value === (attendee.attendee_entity || attendee.attendee));
                        return {
                            ...selectedAttendee,
                            ...attendee
                        };
                    });
            } else {
                totalAttendeeList = attendees;
                attendeeList = [];
            }
        } else if (pageType === "detail") {
            totalAttendeeList = attendees.filter(attendee => !formDetail.attendee.find(a => (a.attendee_entity || a.attendee) === attendee.value));
            attendeeList = formDetail.attendee
                .filter(attendee => !attendee.removed)
                .map(attendee => {
                    const selectedAttendee = attendees.find(a => a.value === (attendee.attendee_entity || attendee.attendee));
                    return {
                        ...selectedAttendee,
                        ...attendee,
                        timestamp: timestamp + attendee.id
                    };
                });
        }
        this.setState(
            {
                totalAttendeeList,
                attendeeList,
                isLoading: form_content ? false : true
            },
            () => {
                this.updateFormData();
            }
        );
    };

    initialise = formElement => {
        const { attendees, formDetail, assignedData } = this.props;
        const { pageType } = this.state;
        this.setState(
            {
                isLoading: attendees ? false : true,
                form_content: formElement.map(element => {
                    let existedElement;
                    if (pageType === "detail") {
                        existedElement = formDetail.form_content.find(content => content.element === element.element);
                    } else if (pageType === "new" && assignedData) {
                        existedElement = assignedData.form_content.find(content => content.element === element.element);
                        delete existedElement.storedAttendees;
                        delete existedElement.storedFiles;
                    } else {
                        existedElement = {};
                    }
                    return existedElement
                        ? {
                              ...element,
                              ...existedElement,
                              relevant_date: existedElement.relevant_date || ""
                          }
                        : {
                              ...element,
                              response: "",
                              comment: "",
                              relevant_date: ""
                          };
                }),
                existedFiles:
                    pageType === "detail"
                        ? formDetail.files_list.filter(file => {
                              const regex = /([A-z ])+_((19[0-9]{2}|2[0-9]{3})-(0[1-9]|1[012])-([123]0|[012][1-9]|31)|\d{13}).png/;
                              const signatureFile = file.original_file_name.match(regex);
                              return !signatureFile;
                          })
                        : [],
                files: pageType === "new" && assignedData && assignedData.storedFiles ? assignedData.storedFiles : []
            },
            () => {
                this.updateFormData();
                this.updateFiles();
            }
        );
    };

    onFormDateChange = event => {
        const {
            target: { name, value }
        } = event;
        const { formData } = this.state;
        this.setState({ formData: { ...formData, [name]: value } });
    };

    onResponseChange = event => {
        const {
            target: { name, value }
        } = event;
        const { form_content } = this.state;
        this.setState(
            {
                form_content: form_content.map(element =>
                    element.element === Number(name)
                        ? {
                              ...element,
                              response: value
                          }
                        : element
                )
            },
            () => this.updateFormData()
        );
    };

    onDateChange = event => {
        const {
            target: { name, value }
        } = event;
        const { form_content } = this.state;
        this.setState(
            {
                form_content: form_content.map(element =>
                    element.element === Number(name)
                        ? {
                              ...element,
                              relevant_date: value
                          }
                        : element
                )
            },
            () => this.updateFormData()
        );
    };

    onCommentChange = event => {
        const {
            target: { name, value }
        } = event;
        const { form_content } = this.state;
        this.setState(
            {
                form_content: form_content.map(element =>
                    element.element === Number(name)
                        ? {
                              ...element,
                              comment: value
                          }
                        : element
                )
            },
            () => this.updateFormData()
        );
    };

    onAttendeesChange = option => {
        this.setState({
            selectedAttendee: option
        });
        this.signToggle();
    };

    onAddOther = event => {
        this.setState({
            selectedAttendee: { attendee_name: "", signature: "" }
        });
        this.signToggle();
    };

    openAttendeeSignModal = (event, selectedAttendee) => {
        this.setState({
            selectedAttendee
        });
        this.signToggle();
    };

    signToggle = event => {
        this.setState(prevState => ({
            modal: !prevState.modal
        }));
    };

    saveAttendee = signedAttendee => {
        const { attendeeList, totalAttendeeList } = this.state;
        const matchedAttendee = attendeeList.find(attendee => signedAttendee.timestamp === attendee.timestamp);
        this.setState(
            {
                attendeeList: matchedAttendee
                    ? attendeeList.map(attendee => (attendee.timestamp === signedAttendee.timestamp ? signedAttendee : attendee))
                    : [...attendeeList, signedAttendee],
                totalAttendeeList: signedAttendee.value
                    ? totalAttendeeList.filter(attendee => attendee.value !== signedAttendee.value)
                    : totalAttendeeList
            },
            () => {
                this.updateFormData();
                this.signToggle();
            }
        );
    };

    removeAttendee = selectedAttendee => {
        const { totalAttendeeList, attendeeList } = this.state;
        this.setState(
            {
                attendeeList: attendeeList.filter(attendee => {
                    if (selectedAttendee.id) {
                        selectedAttendee.isRemoved = true;
                        return true;
                    } else {
                        return attendee !== selectedAttendee;
                    }
                }),
                totalAttendeeList: selectedAttendee.value
                    ? [
                          ...totalAttendeeList,
                          {
                              label: selectedAttendee.label,
                              value: selectedAttendee.value,
                              signature: ""
                          }
                      ].sort((a, b) => (a.label > b.label ? 1 : -1))
                    : totalAttendeeList
            },
            () => {
                this.updateFormData();
            }
        );
    };

    fileClickTrigger = event => {
        document.getElementById("file_toolboxtalk").click();
    };

    onFileChange = async event => {
        const {
            target: { files }
        } = event;
        const { files: stateFiles } = this.state;
        for (let i = 0; i < files.length; i++) {
            if (files[i].type.search(/image/) >= 0) {
                if (this.abortSignal.aborted) {
                    break;
                }
                const img = await imageLoad(URL.createObjectURL(files[i]), files[i].name, files[i].type, this.abortSignal);
                stateFiles.push({ file: img.file, id: Number(new Date()) + i });
            } else {
                stateFiles.push({ file: files[i], id: Number(new Date()) + i });
            }
        }
        this.setState(
            {
                files: stateFiles
            },
            () => this.updateFiles()
        );
    };

    removeItem = id => {
        const { files } = this.state;
        this.setState(
            {
                files: files.filter(file => file.id !== Number(id))
            },
            () => this.updateFiles()
        );
    };

    updateFormData = () => {
        const { updateFormData, updateAttendeeData } = this.props;
        const { formData, form_content, attendeeList, pageType } = this.state;
        updateFormData({
            ...formData,
            form_content:
                form_content &&
                form_content.map(content => ({
                    id: pageType === "detail" ? content.id || undefined : undefined,
                    element: content.element,
                    response: content.response || null,
                    comment: content.comment,
                    relevant_date: content.relevant_date || null
                }))
        });
        updateAttendeeData(
            attendeeList.map(attendee => ({
                ...attendee,
                attendee: attendee.isLagacy ? attendee.value || null : undefined,
                attendee_entity: !attendee.isLagacy ? attendee.value || null : undefined,
                id: attendee.id || undefined,
                removed: attendee.isRemoved || false
            }))
        );
    };

    updateFiles = () => {
        const { files } = this.state;
        const { updateFiles } = this.props;
        updateFiles(files);
    };

    downloadImage = event => {
        const {
            currentTarget: { value }
        } = event;
        const { downloadImage } = this.props;
        downloadImage(value);
    };

    render() {
        const { type, jobDetail, isDisabled } = this.props;
        const { modal, selectedAttendee } = this.state;
        return (
            <>
                <ToolboxTalk
                    {...this.state}
                    type={type}
                    jobDetail={jobDetail}
                    isDisabled={isDisabled}
                    onResponseChange={this.onResponseChange}
                    onCommentChange={this.onCommentChange}
                    onDateChange={this.onDateChange}
                    onFormDateChange={this.onFormDateChange}
                    onAttendeesChange={this.onAttendeesChange}
                    openAttendeeSignModal={this.openAttendeeSignModal}
                    removeAttendee={this.removeAttendee}
                    onAddOther={this.onAddOther}
                    fileClickTrigger={this.fileClickTrigger}
                    onFileChange={this.onFileChange}
                    removeItem={this.removeItem}
                    downloadImage={this.downloadImage}
                />
                <SignModal modal={modal} toggle={this.signToggle} saveAttendee={this.saveAttendee} selectedAttendee={selectedAttendee} />
            </>
        );
    }
}

Container.defaultProps = {
    jobDetail: {},
    formDetail: {}
};

export default Container;
