import React from "react";
import { MDBCard, MDBCardHeader, MDBCardBody, MDBRow, MDBCol } from "louis-mdbreact";
import styles from "shared/details.scss";
import ValidSelect from "components/ValidSelect";

const PrestartDetailCard = props => {
    const { onChangeAttendees, prestart_attendees, isDisabled, prestart_date, onChangeValue } = props;
    return (
        <MDBCard className={styles.card}>
            <MDBCardHeader>Prestart Details</MDBCardHeader>
            <MDBCardBody>
                <MDBRow>
                    <MDBCol className="mb-2">
                        <label htmlFor="prestart_attendees">Attendees</label>
                        <ValidSelect
                            options={props.attendeesList}
                            isMulti
                            onChange={onChangeAttendees}
                            isClearable={false}
                            value={prestart_attendees}
                            isDisabled={isDisabled}
                            name="prestart_attendees"
                        />
                    </MDBCol>
                </MDBRow>
                {prestart_attendees.length > 0 && (
                    <MDBRow>
                        <MDBCol>
                            <label htmlFor="prestart_date">Date</label>
                            <input
                                className="form-control"
                                type="date"
                                name="prestart_date"
                                id="prestart_date"
                                value={prestart_date}
                                onChange={onChangeValue}
                                disabled={!isDisabled}
                            />
                        </MDBCol>
                    </MDBRow>
                )}
            </MDBCardBody>
        </MDBCard>
    );
};

export default PrestartDetailCard;
