import React, { useContext, useEffect, useState } from "react";
import scheduleStyle from "./styles.scss";
import { useSelector } from "react-redux";
import { PublicHolidayDot } from "components/HumanResources/CustomComponent";
import { Stack, Tooltip, Button } from "@mui/material";
import { axiosInstance } from "shared/axiosInst";
import { RosterContext, RosterCalendarContext } from "../TaskContext";

const DateList = ({ date }) => {
    const { holidays } = useSelector(state => state.humanResources);
    const { minions } = useSelector(state => state.user);
    const { isInRostering, currentBranch } = useContext(RosterContext);
    const [holiday, setHoliday] = useState(false);
    const { setAssignedList } = useContext(RosterCalendarContext);
    const [isAutofillable, setIsAutofillable] = useState(false);
    useEffect(() => {
        const holiday = holidays.find(holiday => holiday.holiday_date === date.d);
        setHoliday(holiday);
        setIsAutofillable(!(date.isPast || date.isWeekend || holiday) && isInRostering && minions.length > 0);
    }, [holidays, date, isInRostering, minions]);
    const onLoadLastRostering = async date => {
        try {
            const response = await axiosInstance.post(`fire-akl/maintenance/assigned_roster/copy_roster_forward/`, {
                target_date: date,
                reporting_region: currentBranch
            });
            setAssignedList(current => [...current, ...response.data]);
        } catch (error) {
            console.log(error.response);
        }
    };
    return (
        <th
            className={
                holiday
                    ? scheduleStyle.holiday
                    : date.calendarDay.includes("Today")
                    ? scheduleStyle.today
                    : date.currentWeek
                    ? scheduleStyle.currentWeek
                    : undefined
            }
        >
            {holiday ? (
                <Tooltip title={holiday?.holiday_description}>
                    <Stack direction="row" spacing={1} justifyContent="center" alignItems="center">
                        <span>{date.str}</span>
                        <PublicHolidayDot />
                    </Stack>
                </Tooltip>
            ) : (
                <span>{date.str}</span>
            )}

            <span className={scheduleStyle.calendarDay}>{date.calendarDay}</span>
            {isAutofillable && (
                <Button
                    variant="contained"
                    color="secondary"
                    sx={{ padding: "0px 4px", fontSize: "0.7rem" }}
                    onClick={() => onLoadLastRostering(date.d)}
                >
                    Auto fill
                </Button>
            )}
        </th>
    );
};

export default DateList;
