import React, { useCallback, useEffect, useState, memo } from "react";
import styles from "shared/details.scss";
import ArrowBackButton from "components/ArrowBackButton";
import history from "shared/history";
import { useSelector, useDispatch } from "react-redux";
import {
    Grid,
    TextField,
    Typography,
    RadioGroup,
    FormControlLabel,
    Radio,
    Card,
    CardContent,
    Button,
    List,
    ListItem,
    ListItemIcon,
    IconButton,
    ListItemText,
    Switch,
    Stack,
    Chip,
    FormHelperText
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import CloseIcon from "@mui/icons-material/Close";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import { axiosInstance } from "shared/axiosInst";
import Select from "react-select";
import dayjs from "shared/dayjs";
import { Calendar } from "react-calendar";
import calendarStyles from "./styles.scss";
import { Label, PublicHolidayInfo, StyledBadge, PublicHolidayDotInCalendar } from "components/HumanResources/CustomComponent";
import { actionCreators as hrActions } from "redux/modules/humanResources";
import { actionCreators as userActions } from "redux/modules/user";
import { flatten } from "shared/Utils";
import { Helmet } from "react-helmet";

const LeaveRequest = () => {
    const returnToList = () => {
        history.goBack();
    };
    const dispatch = useDispatch();
    const {
        user,
        humanResources: { totalHolidays }
    } = useSelector(state => state);
    const [leaveType, setLeaveType] = useState([]);
    const [leaves, setLeaves] = useState([]);
    const [numberOfDays, setNumberOfDays] = useState();
    const [userList, setUserList] = useState([]);
    const today = new Date();
    const [activeDate, setActiveDate] = useState(today);
    const defaultValues = {
        employee: "",
        leave_days: [],
        leave_type: 1,
        notes: ""
    };
    const {
        handleSubmit,
        control,
        watch,
        setValue,
        formState: { errors }
    } = useForm({ defaultValues });
    const [leaveDays, currentEmployee] = watch(["leave_days", "employee"]);
    const { fields, remove, update, replace } = useFieldArray({
        control,
        name: "leave_days"
    });

    useEffect(() => {
        const getLeaveType = async () => {
            const leaveType = await dispatch(hrActions.getLeaveType(currentEmployee));
            setLeaveType(leaveType);
        };
        getLeaveType();
    }, [dispatch, currentEmployee]);

    useEffect(() => {
        const getCurrentUsers = async () => {
            const salaryStaff = await dispatch(userActions.getSalaryStaff());
            const filteredSalaryStaff = salaryStaff?.filter(staff => staff.employed_by === 3 && staff.end_date === null);
            if (user.isAdmin || user.isSLT) {
                setUserList(filteredSalaryStaff?.map(user => ({ id: user.user, name: `${user.first_name} ${user.last_name}` })));
            } else {
                const currentUsers = flatten([], user.direct_report, "direct_report");
                currentUsers.unshift({ id: user.userId, name: user.welcomeName });
                const filteredUserList = currentUsers.filter(user => filteredSalaryStaff?.find(usr => usr.user === user.id));
                filteredUserList.length === 1 && filteredUserList[0].id === user.userId && setValue("employee", user.userId);
                setUserList(filteredUserList);
            }
        };
        getCurrentUsers();
    }, [user, dispatch, setValue]);

    useEffect(() => {
        const loadRequestedLeaves = async () => {
            const leaves = await axiosInstance.get(
                `${user.currentCompany}hr/leave_request/?request_state=1&request_state=2&request_state=3&employee=${currentEmployee}${
                    currentEmployee !== user.userId ? "&list_all=true" : ""
                }`
            );
            setLeaves(leaves.data);
            replace([]);
        };
        loadRequestedLeaves();
    }, [user.currentCompany, currentEmployee, replace, user.userId]);

    useEffect(() => {
        const halfDays = leaveDays.filter(day => day.proportion_of_day === "0.50").length;
        setNumberOfDays(leaveDays.length - halfDays * 0.5);
    }, [leaveDays]);

    const removeDay = useCallback(
        selectedDay => {
            setValue(
                "leave_days",
                leaveDays.filter(day => {
                    return selectedDay.getTime() !== day.leave_date.getTime();
                })
            );
        },
        [setValue, leaveDays]
    );

    const setToday = e => {
        setActiveDate(today);
    };

    const onSubmit = async data => {
        const submitData = {
            ...data,
            leave_type: Number(data.leave_type),
            leave_days: data.leave_days.map(record => ({ ...record, leave_date: dayjs(record.leave_date).format("YYYY-MM-DD") }))
        };
        try {
            if (submitData.leave_days?.length === 0) {
                alert("Please select at least a day you want to get off.");
            } else {
                const submitResponse = await axiosInstance.post(`${user.currentCompany}hr/leave_request/`, submitData);
                if (submitResponse.data?.id) {
                    history.push("/humanresources/leaverequest/");
                }
            }
        } catch (error) {
            console.log(error.response);
        }
    };
    return (
        <div className={styles.container}>
            <Helmet>
                <title>HR | Leave Request</title>
            </Helmet>
            <h2 className={styles.title}>
                <ArrowBackButton onClick={returnToList} />
                <span>LEAVE REQUEST</span>
            </h2>
            <form className="needs-validation" onSubmit={handleSubmit(onSubmit)} noValidate>
                <Card variant="outlined">
                    <CardContent>
                        {userList.length === 0 ? (
                            <>
                                <Typography>You may need to check you are on the right company, otherwise please contact IT support.</Typography>
                            </>
                        ) : (
                            <>
                                <Grid container spacing={2} sx={{ alignItems: "flex-start" }}>
                                    <Grid item xs={12} md={7}>
                                        <Label>Name</Label>
                                        <Controller
                                            control={control}
                                            rules={{ required: { value: true, message: "This is required field." } }}
                                            name="employee"
                                            render={({ field: { onChange, value }, rest }) => (
                                                <Select
                                                    menuPosition="fixed"
                                                    options={userList}
                                                    getOptionLabel={option => option.name}
                                                    getOptionValue={option => option.id}
                                                    value={userList.find(user => user.id === value)}
                                                    onChange={value => onChange(value.id)}
                                                    {...rest}
                                                />
                                            )}
                                        />
                                        {errors?.employee?.type === "required" && <FormHelperText error>{errors?.employee?.message}</FormHelperText>}
                                    </Grid>
                                    <Grid container spacing={2} item xs={12} md={7}>
                                        <Grid item xs={12}>
                                            <Stack direction="row" spacing={1}>
                                                <Label>Leave Calendar</Label>
                                                {dayjs(today).format("YYYY-MM") !== dayjs(activeDate).format("YYYY-MM") ? (
                                                    <Chip label="TODAY" size="small" clickable onClick={setToday} color="primary" />
                                                ) : (
                                                    ""
                                                )}
                                            </Stack>
                                            <Calendar
                                                calendarType="US"
                                                className={calendarStyles.container}
                                                tileClassName={({ _, date, view }) => {
                                                    const selected = leaveDays.find(day => day.leave_date.getTime() === date.getTime());
                                                    return view === "month" && selected ? calendarStyles.selected : "";
                                                }}
                                                activeStartDate={activeDate}
                                                onActiveStartDateChange={e => {
                                                    setActiveDate(e.activeStartDate);
                                                }}
                                                view="month"
                                                tileDisabled={({ activeStartDate, date, view }) => {
                                                    const formattedDate = dayjs(date).format("YYYY-MM-DD");
                                                    const sumittedDays = leaves
                                                        .filter(
                                                            leave =>
                                                                leave.request_state !== 4 && leave.request_state !== 5 && leave.request_state !== 6
                                                        )
                                                        .find(leave => {
                                                            return leave.leave_days.find(day => {
                                                                return formattedDate === day.leave_date && day.proportion_of_day !== "0.50";
                                                            });
                                                        });
                                                    const turnedIntoFullday = leaves.filter(leave =>
                                                        leave.leave_days.find(
                                                            day => formattedDate === day.leave_date && day.proportion_of_day === "0.50"
                                                        )
                                                    );
                                                    const matchedHoliday = !!totalHolidays.find(day => day.holiday_date === formattedDate);
                                                    return (
                                                        turnedIntoFullday.length >= 2 ||
                                                        sumittedDays ||
                                                        date.getDay() === 0 ||
                                                        date.getDay() === 6 ||
                                                        matchedHoliday
                                                    );
                                                }}
                                                tileContent={({ activeStartDate, date, view }) => {
                                                    const formattedDate = dayjs(date).format("YYYY-MM-DD");
                                                    const matchedHoliday = totalHolidays.find(day => day.holiday_date === formattedDate);
                                                    return matchedHoliday ? <PublicHolidayDotInCalendar /> : "";
                                                }}
                                                onClickDay={value => {
                                                    const hasBeenSelected = leaveDays.find(day => {
                                                        return day.leave_date.getTime() === value.getTime();
                                                    });
                                                    if (hasBeenSelected) {
                                                        removeDay(hasBeenSelected.leave_date);
                                                    } else {
                                                        const halfDay = leaves.find(leave =>
                                                            leave.leave_days.find(date => date.leave_date === dayjs(value).format("YYYY-MM-DD"))
                                                        );
                                                        const newLeaveDays = [
                                                            ...leaveDays,
                                                            { leave_date: value, proportion_of_day: halfDay ? "0.50" : "1.00", isHalf: !!halfDay }
                                                        ];
                                                        newLeaveDays.sort((a, b) => a.leave_date.getTime() - b.leave_date.getTime());
                                                        setValue("leave_days", newLeaveDays);
                                                    }
                                                }}
                                            />
                                            <PublicHolidayInfo />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Label>Leave Type</Label>
                                            <Controller
                                                rules={{ required: true }}
                                                control={control}
                                                name="leave_type"
                                                render={({ field }) => (
                                                    <RadioGroup {...field} row>
                                                        {leaveType
                                                            ?.filter(leave => leave.current_type)
                                                            .map(leave => (
                                                                <FormControlLabel
                                                                    key={leave.id}
                                                                    value={leave.id}
                                                                    control={<Radio />}
                                                                    label={leave.name}
                                                                />
                                                            ))}
                                                    </RadioGroup>
                                                )}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} md={5}>
                                        <StyledBadge badgeContent={numberOfDays} color="secondary">
                                            <Label>Selected days</Label>
                                        </StyledBadge>
                                        <List dense sx={{ width: "100%" }}>
                                            {fields.map((day, index) => (
                                                <ListItem
                                                    key={day.leave_date}
                                                    secondaryAction={
                                                        <IconButton
                                                            onClick={_ => {
                                                                remove(index);
                                                            }}
                                                            edge="end"
                                                            aria-label="remove"
                                                        >
                                                            <CloseIcon />
                                                        </IconButton>
                                                    }
                                                >
                                                    <ListItemIcon>
                                                        <FormControlLabel
                                                            sx={{ marginBottom: 0 }}
                                                            control={
                                                                <Switch
                                                                    checked={day.proportion_of_day === "1.00"}
                                                                    disabled={day.isHalf}
                                                                    onChange={() => {
                                                                        update(index, {
                                                                            leave_date: day.leave_date,
                                                                            proportion_of_day: day.proportion_of_day === "1.00" ? "0.50" : "1.00"
                                                                        });
                                                                    }}
                                                                    size="small"
                                                                />
                                                            }
                                                            label={
                                                                <Typography sx={{ fontSize: "0.8rem" }}>
                                                                    {day.proportion_of_day === "1.00" ? "Full" : "Half"} Day
                                                                </Typography>
                                                            }
                                                        />
                                                    </ListItemIcon>
                                                    <ListItemText id={day.leave_date} primary={dayjs(day.leave_date).format("DD/MM/YYYY")} />
                                                </ListItem>
                                            ))}
                                        </List>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Label>Notes</Label>
                                        <Controller
                                            control={control}
                                            name="notes"
                                            render={({ field }) => <TextField fullWidth multiline rows={6} {...field} />}
                                        />
                                    </Grid>
                                </Grid>
                            </>
                        )}
                    </CardContent>
                </Card>
                {userList.length > 0 && (
                    <Grid container sx={{ justifyContent: "flex-end", "& button": { my: 2, mx: 0.5 } }}>
                        <Button onClick={returnToList} variant="contained" color="inherit">
                            cancel
                        </Button>
                        <LoadingButton type="submit" variant="contained">
                            submit
                        </LoadingButton>
                    </Grid>
                )}
            </form>
        </div>
    );
};

export default memo(LeaveRequest);
