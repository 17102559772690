import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import PropsType from "prop-types";
import styles from "./styles.scss";
import Header from "components/Header";
import Auth from "components/Auth";

import Jobs from "components/Jobs";
import JobsRequested from "components/Jobs/JobsRequested";
import JobDetail from "components/Jobs/JobDetail";
import JobView from "components/Jobs/JobView";
import PLReport from "components/Jobs/PLReport";

import DownloadReport from "components/DownloadReport";

import HRRoute from "./HRRoute";
import CrmRoute from "./CrmRoute";

import ErrorPage from "components/ErrorPage";
import { MDBContainer, MDBRow, MDBCol } from "louis-mdbreact";
import Snackbar from "@mui/material/Snackbar";
import { ThemeProvider, useTheme, StyledEngineProvider } from "@mui/material/styles";
import BHAG from "components/Reports/BHAG";
import USerRoute from "./UserRoute";
import OperationRoute from "./OperationRoute";
import StockRoute from "./StockRoute";
import TaskRoute from "./TaskRoute";
import Dashboard from "components/User/Dashboard";

const App = props => {
    const theme = useTheme();
    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
                {props.isLoggedIn ? (
                    <PrivateRoute permissions={props.permissions} isSLT={props.isSLT} currentCompany={props.currentCompany} />
                ) : (
                    <PublicRoute />
                )}
                <Snackbar
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left"
                    }}
                    open={props.serviceWorkerUpdated}
                    className={styles.reloadNotif}
                    message={`A new version is updated.`}
                    action={
                        <button
                            size="sm"
                            className={styles.reloadBtn}
                            onClick={() => {
                                const registrationWaiting = props.registration.waiting;
                                if (registrationWaiting) {
                                    registrationWaiting.postMessage({ type: "SKIP_WAITING" });

                                    registrationWaiting.addEventListener("statechange", e => {
                                        if (e.target.state === "activated") {
                                            window.location.reload();
                                        }
                                    });
                                } else {
                                    window.location.reload();
                                }
                            }}
                        >
                            Reload
                        </button>
                    }
                />
            </ThemeProvider>
        </StyledEngineProvider>
    );
};

App.propsType = {
    isLoggedIn: PropsType.bool.isRequired
};

const PrivateRoute = props => (
    <>
        <MDBContainer fluid={true} className={styles.container}>
            <Header />
            <MDBRow>
                <MDBCol md="12" className={styles.main}>
                    <Switch>
                        <Route exact path="/" component={Dashboard} />
                        {/** Jobs */}
                        <Route path="/jobs">
                            <Switch>
                                <Route exact path="/jobs" component={Jobs} />
                                <Route exact path="/jobs/requested" component={JobsRequested} />
                                <Route path="/jobs/new" render={history => <JobDetail history={history} type="new" />} />
                                {props.isSLT ? (
                                    <Route exact path="/jobs/plreport" component={PLReport} />
                                ) : (
                                    <Redirect from="/jobs/plreport" to="/jobs" />
                                )}
                                <Route exact path="/jobs/:id" component={JobView} />
                                <Route
                                    exact
                                    path="/jobs/edit/:id"
                                    render={history =>
                                        props.permissions.job === 4 ? <JobDetail history={history} type="detail" /> : <Redirect to="/jobs" />
                                    }
                                />
                                {/* <Route path="/jobs/requested/:id" render={history => <JobDetail history={history} type="requested" />} /> */}
                            </Switch>
                        </Route>

                        {/** Tasks */}
                        {/* <Route path="/tasks">
                            <Switch>
                                <Route path="/tasks/dayworks" component={Dayworks} />
                            </Switch>
                        </Route> */}

                        {/** operations */}
                        {/** Tasks */}
                        <Route path="/tasks">
                            <TaskRoute permissions={props.permissions} />
                        </Route>
                        <Route path="/operations">
                            <OperationRoute permissions={props.permissions} />
                        </Route>
                        {props.permissions.hr === undefined && <Redirect from="/humanresources" to="/jobs" />}
                        <Route path="/humanresources">
                            <HRRoute />
                        </Route>
                        <Route path="/reports">
                            <Switch>
                                <Route exact path="/reports" component={BHAG} />
                            </Switch>
                        </Route>
                        {props.permissions.crm === 0 && <Redirect from="/crm" to="/jobs" />}
                        <Route path="/crm">
                            <CrmRoute />
                        </Route>
                        <Route path="/user">
                            <USerRoute />
                        </Route>
                        {/** Stocks */}
                        {props.permissions.stock === 0 && <Redirect from="/stock" to="/jobs" />}
                        <Route path="/stock">
                            <StockRoute />
                        </Route>
                        <Route component={ErrorPage} />
                    </Switch>
                </MDBCol>
            </MDBRow>
            <DownloadReport />
        </MDBContainer>
    </>
);

const PublicRoute = props => (
    <Switch>
        <Route component={Auth} />
    </Switch>
);

export default App;
