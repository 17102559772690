import React, { useCallback, useEffect, useState } from "react";
import styles from "shared/listStyles.scss";
import {
    Card,
    CardContent,
    FormControlLabel,
    List,
    TextField,
    Box,
    CircularProgress,
    Typography,
    Button,
    Grid,
    Radio,
    RadioGroup,
    FormGroup,
    Checkbox
} from "@mui/material";
import ListItemContainer from "./ListItemContainer";
import { axiosInstance } from "shared/axiosInst";
import MoodIcon from "@mui/icons-material/Mood";
import SentimentSatisfiedIcon from "@mui/icons-material/SentimentSatisfied";
import SentimentDissatisfiedIcon from "@mui/icons-material/SentimentDissatisfied";
import SentimentVeryDissatisfiedIcon from "@mui/icons-material/SentimentVeryDissatisfied";
import SickOutlinedIcon from "@mui/icons-material/SickOutlined";
import MoodBadIcon from "@mui/icons-material/MoodBad";
import CelebrationIcon from "@mui/icons-material/Celebration";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { QuoteContext } from "../CrmContext";
import { Helmet } from "react-helmet";
import SummaryTable from "./SummaryTable";
import { getSelectedList, quoteBaseURL } from "./quoteUtils";
import Select from "react-select";
import IOSSwitch from "components/IOSSwitch";
import { useParams } from "react-router-dom";
import { Label } from "components/HumanResources/CustomComponent";
import { useDispatch } from "react-redux";
import { actionCreators as userActions } from "redux/modules/user";
let timeout = null;

const Quotes = () => {
    const params = useParams();
    const [hitListFilters, setHitListFilters] = useState(
        JSON.parse(localStorage.getItem("hitListFilters")) || {
            expandNote: false,
            summaryOpen: false,
            lettingSoon: false,
            sortingValue: "win",
            companiesList: [
                { name: "Linings", shortName: "GL", checked: true },
                { name: "Fire", shortName: "GF", checked: true },
                { name: "Active Fire", shortName: "GAF", checked: true }
            ]
        }
    );

    const [stages, setStages] = useState([
        {
            title: "Stage 4",
            matchedTitle: "stage_four",
            icon: <MoodIcon color="success" />,
            notIncluded: false,
            data: [],
            open: true,
            inSummary: true
        },
        {
            title: "Stage 3",
            matchedTitle: "stage_three",
            icon: <SentimentSatisfiedIcon color="primary" />,
            notIncluded: false,
            data: [],
            open: false,
            inSummary: true
        },
        {
            title: "Stage 2",
            matchedTitle: "stage_two",
            icon: <SentimentDissatisfiedIcon color="warning" />,
            notIncluded: false,
            data: [],
            open: false,
            inSummary: true
        },
        {
            title: "Stage 1",
            matchedTitle: "stage_one",
            icon: <SentimentVeryDissatisfiedIcon color="error" />,
            notIncluded: false,
            data: [],
            open: false,
            inSummary: true
        },
        {
            title: "On Hold",
            matchedTitle: "stage_hold",
            icon: <MoodBadIcon color="action" />,
            notIncluded: true,
            data: [],
            open: false,
            inSummary: true
        },
        {
            title: "Won (calendar month)",
            matchedTitle: "stage_won",
            icon: <CelebrationIcon color="warning" />,
            notIncluded: true,
            data: [],
            open: false,
            inSummary: true
        },
        {
            title: "Stage Lost (calendar month)",
            matchedTitle: "stage_lost",
            icon: <SickOutlinedIcon color="error" />,
            notIncluded: true,
            data: [],
            open: false,
            inSummary: false
        }
    ]);
    const [companiesList, setCompaniesList] = useState(hitListFilters.companiesList);
    const setOpen = title => {
        setStages(stages.map(stage => (title === stage.title ? { ...stage, open: !stage.open } : { ...stage, open: false })));
    };
    const [regionList, setRegionList] = useState(hitListFilters.regionList || []);
    const [clientList, setClientList] = useState(hitListFilters.clientList || []);
    const [loading, setLoading] = useState(true);
    const [searchText, setSearchText] = useState(params.id || "");
    const [search, setSearch] = useState(params.id || "");
    const [expandNote, setExpandNote] = useState(hitListFilters.expandNote);
    const [summaryOpen, setSummaryOpen] = useState(hitListFilters.summaryOpen || false);
    const [lettingSoon, setLettingSoon] = useState(hitListFilters.lettingSoon || false);
    const [quoteStatusList, setQuoteStatusList] = useState([]);
    const [sortingValue, setSortingValue] = useState(hitListFilters.sortingValue || "win");
    const [users, setUsers] = useState([]);
    const dispatch = useDispatch();
    const onSearchChange = event => {
        setLoading(true);
        setSearchText(event.target.value);
        timeout && clearTimeout(timeout);
        timeout = setTimeout(() => {
            setLoading(false);
            setSearch(event.target.value);
            timeout = null;
        }, 500);
    };

    useEffect(() => {
        (async () => {
            setLoading(true);
            const statusListResponse = await axiosInstance.get(`${quoteBaseURL}status_list/`);
            const statusList = statusListResponse.data;
            setQuoteStatusList(statusList.filter(status => status[1] !== "Not Quoted" && status[1] !== "New Quote request"));

            const regionListResponse = await axiosInstance.get(`${quoteBaseURL}region_list/`);
            const regionList = regionListResponse.data.map(data => ({
                id: data[0],
                name: data[1],
                shortName: data[2],
                current: data[3],
                checked: false
            }));
            const clientsResponse = await axiosInstance.get(`${quoteBaseURL}clients/`);
            setClientList(currentClientList => {
                return clientsResponse.data.map(client => ({ ...client, checked: !!currentClientList.find(current => current.id === client.id) }));
            });
            setRegionList(regionList);
            // setLoading(false);
            const users = await dispatch(userActions.getGlobalUsers());
            setUsers(
                users.filter(
                    user => user.active && String(user.name_string).search(/Carters|Global Fire|IT Info|Integration|Linings|Ricoh|Supervisor/) === -1
                )
            );
        })();
    }, [dispatch]);

    useEffect(() => {
        const newHitListFilter = {
            companiesList,
            expandNote,
            summaryOpen,
            regionList,
            lettingSoon,
            sortingValue,
            clientList: clientList.filter(client => client.checked)
        };
        setHitListFilters(newHitListFilter);
        localStorage.setItem("hitListFilters", JSON.stringify(newHitListFilter));
    }, [companiesList, expandNote, summaryOpen, regionList, lettingSoon, clientList, sortingValue]);

    const onCompanyListChange = event => {
        // console.log(event.target.value);
        const value = event.target.value;
        const newCompaniesList = companiesList.map(company => {
            const matched = company.shortName === value;
            return { ...company, checked: matched ? !company.checked : company.checked };
        });
        setCompaniesList(newCompaniesList);
    };

    const onRegionListChange = option => {
        const newRegionList = regionList.map(region => {
            const matched = option.find(opt => opt.name === region.name);
            return { ...region, checked: !!matched };
        });
        setRegionList(newRegionList);
    };

    const onClientChange = option => {
        const newClientList = clientList.map(client => {
            const matched = option.find(opt => opt.legal_name === client.legal_name);
            return { ...client, checked: !!matched };
        });
        setClientList(newClientList);
    };

    const onSortingChange = event => {
        setSortingValue(event.target.value);
    };

    const fetchData = useCallback(async () => {
        if (companiesList.length > 0 && regionList.length > 0 && clientList.length > 0) {
            setLoading(true);
            const checkedCompanies = getSelectedList(companiesList)
                .map(company => `company=${company.shortName}`)
                .join("&");
            const checkedRegions = getSelectedList(regionList)
                .map(region => `region=${region.shortName}`)
                .join("&");
            const checkedClients = getSelectedList(clientList)
                .map(client => `client=${client.id}`)
                .join("&");
            const getStr = str => {
                if (str === "") {
                    return str;
                } else {
                    return `${str}&`;
                }
            };
            const filter = `${getStr(checkedCompanies)}${getStr(checkedRegions)}${getStr(checkedClients)}sorting=${sortingValue}${
                lettingSoon ? "&letting_soon=true" : ""
            }`;
            try {
                const res = await axiosInstance.get(`${quoteBaseURL}quote_stage_list/?${filter}`);
                const data = res.data;
                setStages(current => {
                    return current.map(cur => ({ ...cur, data: data[cur.matchedTitle] }));
                });
                setLoading(false);
            } catch (error) {
                console.log(error);
                setLoading(false);
            }
        }
    }, [companiesList, regionList, clientList, sortingValue, lettingSoon]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    return (
        <QuoteContext.Provider value={{ fetchData, quoteStatusList, setStages, users }}>
            <Helmet>
                <title>Global Portal | Hit List</title>
            </Helmet>
            <div className={styles.container} style={{ paddingBottom: "70px" }}>
                <div className={styles.titleWrap}>
                    <h2 className={`${styles.taskTitle}`}>Hit List</h2>
                    <div className={styles.switchWrap}>
                        <div className={`${styles.listSwitch} custom-control custom-switch`}>
                            <input
                                type="checkbox"
                                className="custom-control-input"
                                id="letting-soon"
                                name="letting-soon"
                                checked={lettingSoon}
                                onChange={e => setLettingSoon(e.target.checked)}
                                readOnly
                            />
                            <label className="custom-control-label" htmlFor="letting-soon">
                                LETTING SOON & HIGH PRIORITY
                            </label>
                        </div>
                    </div>
                </div>
                <Card variant="outlined">
                    <CardContent
                        sx={{
                            padding: "8px 10px",
                            paddingBottom: "8px !important"
                        }}
                    >
                        <Grid container>
                            <Grid item sm={12} xs={12} md={4} p={0.5} zIndex={7}>
                                <Label component="legend">Company</Label>
                                <FormGroup row>
                                    {companiesList.map(company => (
                                        <FormControlLabel
                                            key={company.shortName}
                                            onChange={onCompanyListChange}
                                            value={company.shortName}
                                            control={<Checkbox checked={company.checked} disabled={loading} />}
                                            label={company.name}
                                            labelPlacement="end"
                                        />
                                    ))}
                                </FormGroup>
                                {/* <Select
                                    menuPosition="fixed"
                                    options={companiesList}
                                    isMulti
                                    value={companiesList.filter(company => company.checked)}
                                    onChange={onCompanyListChange}
                                    getOptionValue={option => option.shortName}
                                    getOptionLabel={option => option.name}
                                /> */}
                            </Grid>
                            <Grid item sm={12} xs={12} md={4} p={0.5} zIndex={6}>
                                <Label component="legend">Region</Label>
                                <Select
                                    menuPosition="fixed"
                                    isMulti
                                    value={regionList?.filter(region => region.checked)}
                                    onChange={onRegionListChange}
                                    options={regionList}
                                    getOptionValue={option => option}
                                    getOptionLabel={option => `${option.name} (${option.shortName})`}
                                />
                            </Grid>
                            <Grid item sm={12} xs={12} md={4} p={0.5} zIndex={5}>
                                <Label component="legend">Client</Label>
                                <Select
                                    menuPosition="fixed"
                                    isMulti
                                    value={clientList?.filter(client => client.checked)}
                                    onChange={onClientChange}
                                    options={clientList}
                                    getOptionValue={option => option}
                                    getOptionLabel={option => `${option.legal_name} ${option.known_as ? `(${option.known_as})` : ""}`}
                                />
                            </Grid>
                            <Grid item sm={12} xs={12} md={6} p={0.5}>
                                <Label component="legend">Sort by</Label>
                                <RadioGroup
                                    row
                                    aria-labelledby="sort-by-radio-group"
                                    name="sort-by-radio-group"
                                    value={sortingValue}
                                    onChange={onSortingChange}
                                >
                                    <FormControlLabel value="win" control={<Radio disabled={loading} />} label="Win Chance" />
                                    <FormControlLabel value="value" control={<Radio disabled={loading} />} label="Value" />
                                    <FormControlLabel value="client" control={<Radio disabled={loading} />} label="Client" />
                                </RadioGroup>
                            </Grid>
                            <Grid item sm={12} xs={12} md={6} p={0.5}>
                                <Label component="legend">Search</Label>
                                <TextField sx={{ minWidth: "320px" }} fullWidth size="small" value={searchText || ""} onChange={onSearchChange} />
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
                <Box paddingTop="10px">
                    <Button
                        fullWidth
                        variant="outlined"
                        onClick={() => {
                            setSummaryOpen(!summaryOpen);
                        }}
                        endIcon={summaryOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                    >
                        Summary
                    </Button>
                    {summaryOpen && (
                        <SummaryTable
                            stages={stages.filter(stage => stage.inSummary).filter(stage => !stage.notIncluded)}
                            nonStages={stages.filter(stage => stage.inSummary).filter(stage => stage.notIncluded)}
                            searchText={search}
                            companiesList={companiesList}
                        />
                    )}
                </Box>
                <Box
                    padding="12px 25px"
                    display="flex"
                    justifyContent="flex-end"
                    position="sticky"
                    top="67px"
                    zIndex="4"
                    sx={{ pointerEvents: "none" }}
                >
                    <FormControlLabel
                        sx={{ pointerEvents: "all" }}
                        control={
                            <IOSSwitch
                                checked={expandNote}
                                onClick={() => {
                                    setExpandNote(!expandNote);
                                }}
                            />
                        }
                        label={
                            <Typography component="span">
                                EXPAND{" "}
                                <Box component="span" display={{ xs: "none", sm: "none", md: "inline" }}>
                                    ALL NOTES
                                </Box>
                            </Typography>
                        }
                    />
                </Box>
                {loading ? (
                    <Box sx={{ display: "flex", width: "100%", marginTop: "30px", justifyContent: "center" }}>
                        <CircularProgress />
                    </Box>
                ) : (
                    <List sx={{ width: "100%" }} component="ul">
                        {stages?.map(stage => {
                            const data = stage.data?.reduce((prev, current) => {
                                prev.push({ ...current, noteOpen: current.noteOpen === undefined ? expandNote : current.noteOpen });
                                if (current.follow_up_note || current.follow_up_notes.length > 0) {
                                    prev.push({
                                        ...current,
                                        isExpandable: true,
                                        noteOpen: current.noteOpen === undefined ? expandNote : current.noteOpen
                                    });
                                }
                                return prev;
                            }, []);
                            return (
                                <ListItemContainer
                                    key={stage.title}
                                    title={stage.title}
                                    icon={stage.icon}
                                    stageData={data}
                                    open={stage.open}
                                    setOpen={setOpen}
                                    searchText={search}
                                />
                            );
                        })}
                    </List>
                )}
            </div>
        </QuoteContext.Provider>
    );
};

export default Quotes;
