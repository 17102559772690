import { DialogContent } from "@mui/material";
import DialogContainer from "components/DialogContainer";
import React from "react";
import RequestedLeave from "../LeaveForm/RequestedLeave";
import { useSelector } from "react-redux";

const LeaveItemDialog = ({ open, handleClose, index }) => {
    const { leaveRequest } = useSelector(state => state.humanResources);
    return (
        <DialogContainer
            fullWidth
            fullScreen={false}
            maxWidth="md"
            title={`${leaveRequest.leave_type_string} (${leaveRequest.request_state_string})`}
            open={open}
            handleClose={handleClose}
        >
            <DialogContent>
                <RequestedLeave index={index} />
            </DialogContent>
        </DialogContainer>
    );
};

export default LeaveItemDialog;
