import React, { useState, useEffect } from "react";
import { Grid, TextField, MenuItem, FormHelperText } from "@mui/material";
import { useFormContext, Controller, useWatch } from "react-hook-form";
import { useDispatch } from "react-redux";
import { actionCreators as taskActions } from "redux/modules/tasks";
import { Label } from "components/HumanResources/CustomComponent";

const ReasonItem = () => {
    const dispatch = useDispatch();
    const {
        control,
        formState: { errors }
    } = useFormContext();
    const [reasons, setReasons] = useState([]);
    const currentReason = useWatch({ control, name: "reason" });
    useEffect(() => {
        dispatch(taskActions.getRejectReasons()).then(value => {
            setReasons(value);
        });
    }, [dispatch]);
    return (
        <Grid direction="column" item container spacing={1}>
            <Grid item>
                <Label>Reason</Label>
                <Controller
                    control={control}
                    name="reason"
                    render={({ field }) => (
                        <TextField size="small" fullWidth select label="Common Reason" InputLabelProps={{ sx: { zIndex: 0 } }} {...field}>
                            <MenuItem value="0">Select...</MenuItem>
                            {reasons?.map(reason => (
                                <MenuItem key={reason.id} value={reason.id}>
                                    {reason.name}
                                </MenuItem>
                            ))}
                        </TextField>
                    )}
                />
            </Grid>
            <Grid item>
                <Controller
                    control={control}
                    name="reason_free_text"
                    rules={{ required: currentReason === 0 }}
                    render={({ field }) => (
                        <TextField
                            fullWidth
                            error={errors?.reason_free_text?.type === "required"}
                            InputLabelProps={{ shrink: true, sx: { zIndex: 0 } }}
                            label="More Information"
                            multiline
                            rows={2}
                            {...field}
                        />
                    )}
                />
                {errors?.reason_free_text?.type === "required" && (
                    <FormHelperText error>Either Common Reason or More information has to be provided.</FormHelperText>
                )}
            </Grid>
        </Grid>
    );
};

export default ReasonItem;
