import React, { useEffect, useState } from "react";
import { Card, CardContent, Grid, Stack, Box } from "@mui/material";
import styles from "shared/listStyles.scss";
import detailStyles from "shared/details.scss";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import WindowedSelect from "react-windowed-select";
import { useDispatch, useSelector } from "react-redux";
import { actionCreators as crmActions } from "redux/modules/crm";
import { actionCreators as jobActions } from "redux/modules/jobs";
import { actionCreators as taskActions } from "redux/modules/tasks";
import { LoadingButton } from "@mui/lab";
import { useForm, Controller, FormProvider } from "react-hook-form";
import dayjs from "dayjs";
import { approvalJobSortingList, getCurrentPayPeriod } from "shared/Utils";
import { getSlimJobFilteredByBranch } from "redux/reducers";
import { TableVirtuoso } from "react-virtuoso";
import { MDBTable } from "louis-mdbreact";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { axiosInstance } from "shared/axiosInst";
import ListItem from "./ListItem";
import { ModalContext } from "../TaskContext";
import { Label } from "components/HumanResources/CustomComponent";
import EditModal from "../TimeApprove/EditModal";
import MJSFilter from "./MJSFilter";

const EARLY_HOUR = 6;
const LATE_HOUR = 17;
const FORTY_FIVE_MINUTES = 2700000;

const TableComponents = {
    Table: props => <MDBTable {...props} small className={detailStyles.detailResponsiveTable} style={{ marginTop: "20px" }} />,
    TableRow: props => {
        const startHour = dayjs(props.item.starting_timestamp).get("h");
        const endHour = dayjs(props.item.ending_timestamp).get("h");
        let isNight = false;
        if ((startHour > 0 && startHour < EARLY_HOUR) || (endHour > LATE_HOUR + 1 && endHour < 24)) {
            isNight = true;
        }
        const theme = createTheme({
            palette: {
                mode: isNight ? "dark" : "light"
            }
        });
        const diff = dayjs(props.item.ending_timestamp).diff(props.item.starting_timestamp);
        const longLunch = props.item.mjs_id === 6 && diff > FORTY_FIVE_MINUTES;
        return (
            <ThemeProvider theme={theme}>
                <tr
                    className={`${detailStyles.addItemContainer} ${props["data-index"] % 2 === 0 ? detailStyles.darkerLine : ""} ${
                        isNight ? detailStyles.night : ""
                    } ${longLunch ? "amber lighten-3" : ""}`}
                    {...props}
                />
            </ThemeProvider>
        );
    }
};

const TimeLogs = () => {
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up("sm"));
    const dispatch = useDispatch();
    const state = useSelector(state => state);
    const jobList = getSlimJobFilteredByBranch(state);
    const {
        crm: { entitiesList }
    } = state;
    const [workerList, setWorkerList] = useState();
    const [items, setItems] = useState([]);
    const [modalData, setModalData] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const currentPeriod = getCurrentPayPeriod();
    const methods = useForm({
        defaultValues: {
            start: dayjs(currentPeriod.start),
            end: dayjs(currentPeriod.end),
            worker: "",
            startingJob: "",
            endingJob: "",
            startingMJS: "",
            endingMJS: ""
        }
    });
    const {
        control,
        handleSubmit,
        formState: { errors }
    } = methods;
    useEffect(() => {
        (async () => {
            await dispatch(crmActions.getSlimEntity(`?relationships=1&assignments=2`));
            await dispatch(jobActions.getBranchList());
            await dispatch(jobActions.getSlimJobs(false, false));
            await dispatch(taskActions.getRejectReasons());
            await dispatch(taskActions.getSlimMaintenanceJob(false, true));
        })();
    }, [dispatch]);

    useEffect(() => {
        if (entitiesList?.length > 0) {
            const groupedList = entitiesList.reduce(
                (groupedList, current) => {
                    current.current_employee ? groupedList[0].options.push(current) : groupedList[1].options.push(current);
                    return groupedList;
                },
                [
                    { label: "Current", options: [] },
                    { label: "Non-current", options: [] }
                ]
            );
            setWorkerList(groupedList);
        }
    }, [entitiesList]);

    const onSubmit = async data => {
        setIsLoading(true);
        const { start, end, worker, jobStarting, jobEnding, startingMJS, endingMJS } = data;
        const res = await axiosInstance.get(
            `fire-akl/maintenance/time_tracking/slim_everything/?next_revision__isnull=true&starting_timestamp__date__gte=${dayjs(start).format(
                "YYYY-MM-DD"
            )}&ending_timestamp__date__lte=${dayjs(end).format("YYYY-MM-DD")}${worker ? `&worker__entity=${worker.id}` : ""}${
                jobStarting ? `&starting_job=${jobStarting.id}` : ""
            }${jobEnding ? `&ending_job=${jobEnding.id}` : ""}${startingMJS ? `&starting_mjs=${startingMJS.id}` : ""}${
                endingMJS ? `&ending_mjs=${endingMJS.id}` : ""
            }`
        );
        setItems(res.data.length > 0 ? res.data.sort((a, b) => approvalJobSortingList(a, b)) : [{ detail: "No data" }]);
        setIsLoading(false);
    };

    return (
        <div className={styles.container} style={{ paddingBottom: "60px" }}>
            <div className={styles.titleWrap}>
                <h2 className={`${styles.taskTitle}`}>TIME LOGS</h2>
            </div>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <FormProvider {...methods}>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Card variant="outlined">
                            <CardContent>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={12} md={6}>
                                        <Label>Dates</Label>
                                        <Stack direction={matches ? "row" : "column"} alignItems="flex-start" spacing={2}>
                                            <Controller
                                                control={control}
                                                name="start"
                                                rules={{ required: true }}
                                                render={({ field }) => (
                                                    <DatePicker
                                                        slotProps={{
                                                            actionBar: { actions: ["clear", "today", "accept"] },
                                                            textField: {
                                                                size: "small",
                                                                fullWidth: true,
                                                                InputLabelProps: { shrink: true },
                                                                error: errors?.start?.type === "required",
                                                                helperText: errors?.start?.type === "required" && "Required"
                                                            }
                                                        }}
                                                        format="DD/MM/YYYY"
                                                        label="Start"
                                                        {...field}
                                                    />
                                                )}
                                            />

                                            {matches && (
                                                <Box
                                                    component="p"
                                                    sx={{
                                                        paddingTop: "9px",
                                                        fontWeight: 400,
                                                        fontSize: "1rem",
                                                        lineHeight: 1.5,
                                                        letterSpacing: "0.00938em"
                                                    }}
                                                >
                                                    &#8211;
                                                </Box>
                                            )}
                                            <Controller
                                                control={control}
                                                name="end"
                                                rules={{ required: true }}
                                                render={({ field }) => (
                                                    <DatePicker
                                                        slotProps={{
                                                            actionBar: { actions: ["clear", "today", "accept"] },
                                                            textField: { size: "small", fullWidth: true, InputLabelProps: { shrink: true } }
                                                        }}
                                                        format="DD/MM/YYYY"
                                                        label="End"
                                                        {...field}
                                                    />
                                                )}
                                            />
                                        </Stack>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6} sx={{ zIndex: 7 }}>
                                        <Label>Worker (optional)</Label>
                                        <Controller
                                            control={control}
                                            name="worker"
                                            render={({ field }) => (
                                                <WindowedSelect
                                                    {...field}
                                                    formatGroupLabel={data => <div style={{ color: "#2196f3" }}>{data.label}</div>}
                                                    options={workerList}
                                                    isClearable
                                                    getOptionLabel={option => option.name}
                                                    getOptionValue={option => option.id}
                                                    menuPosition="fixed"
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6} sx={{ zIndex: 6 }}>
                                        <Label>Starting Job (optional)</Label>
                                        <Controller
                                            control={control}
                                            name="jobStarting"
                                            render={({ field }) => (
                                                <WindowedSelect
                                                    menuPosition="fixed"
                                                    isClearable
                                                    options={jobList || []}
                                                    getOptionValue={option => option.id}
                                                    getOptionLabel={option => `${option.id}-${option.short_region} (${option.job_name})`}
                                                    {...field}
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6} sx={{ zIndex: 5 }}>
                                        <Label>Ending Job (optional)</Label>
                                        <Controller
                                            control={control}
                                            name="jobEnding"
                                            render={({ field }) => (
                                                <WindowedSelect
                                                    menuPosition="fixed"
                                                    isClearable
                                                    options={jobList || []}
                                                    getOptionValue={option => option.id}
                                                    getOptionLabel={option => `${option.id}-${option.short_region} (${option.job_name})`}
                                                    {...field}
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <MJSFilter />
                                    <Grid item xs={12} sm={12} md={12} justifyContent="flex-end" display="flex">
                                        <LoadingButton loading={isLoading} type="submit" variant="contained">
                                            Search
                                        </LoadingButton>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    </form>
                </FormProvider>
            </LocalizationProvider>
            <ModalContext.Provider value={{ modalData, setModalData }}>
                <TableVirtuoso
                    data={items}
                    components={TableComponents}
                    useWindowScroll
                    fixedHeaderContent={index => (
                        <tr key={index}>
                            <th>Worker</th>
                            <th>Job</th>
                            <th width="30%">MJS</th>
                            <th>Supervisor</th>
                            <th>Date</th>
                            <th>Time</th>
                            <th width="120" className="text-center">
                                Details
                            </th>
                        </tr>
                    )}
                    itemContent={(index, list) => {
                        return list.detail === "No data" ? (
                            <td colSpan="7" style={{ textAlign: "center" }}>
                                No Data
                            </td>
                        ) : (
                            <ListItem key={list.id} list={list} index={index} isLog={true} />
                        );
                    }}
                />

                {modalData && (
                    <EditModal
                        isLog
                        open={!!modalData}
                        toggle={() => {
                            setModalData(null);
                        }}
                    />
                )}
            </ModalContext.Provider>
        </div>
    );
};

export default TimeLogs;
