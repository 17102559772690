import React, { useEffect, useState } from "react";
import styles from "shared/details.scss";
import ArrowBackButton from "components/ArrowBackButton";
import { Label } from "components/HumanResources/CustomComponent";
import { CircularProgress, Stack, TextField } from "@mui/material";
import JobDetailTable from "components/Jobs/JobDetailTable";
import ListItem from "../TimeApprove/ListItem";
import { actionCreators as taskActions } from "redux/modules/tasks";
import { useDispatch } from "react-redux";
import { Helmet } from "react-helmet";
const OutlinedTextField = ({ label, ...rest }) => {
    return <TextField variant="outlined" InputLabelProps={{ shrink: true }} size="small" type="date" fullWidth label={label} {...rest} />;
};

const JobView = ({ listType }) => {
    const dispatch = useDispatch();
    const [timesheet, setTimesheet] = useState();
    const [title, setTitle] = useState("");

    useEffect(() => {
        if (listType) {
            dispatch(taskActions.getTimeCloudList(listType)).then(value => {
                setTimesheet(value[0]);
            });
        }
    }, [dispatch, listType]);
    useEffect(() => {
        if (timesheet) {
            setTitle(listType === "job" ? `${timesheet?.job}-${timesheet?.region_short_string}` : `${timesheet?.entity_name_string}`);
        }
    }, [timesheet, listType]);
    return (
        <>
            <h2>
                <Helmet>
                    <title>Tasks | Timesheet {title}</title>
                </Helmet>
                <div className={`${styles.title}`}>
                    <ArrowBackButton />
                    <span>TIMESHEET ({title})</span>
                </div>
            </h2>
            {listType === "job" && (
                <>
                    <Label>Job Info</Label>
                    <JobDetailTable
                        jobDetail={{
                            id: 7833,
                            client_string: "FE Construction",
                            job_name: "1 1 Trimmer Rd - Flat Bush",
                            internal_qs_string: "Daniel Nguyen",
                            internal_supervisor_string: "Linings *TEST* Supervisor *TEST*"
                        }}
                    />
                </>
            )}
            <Label>Period</Label>
            <Stack direction="row" spacing={1}>
                <OutlinedTextField label="Start" />
                <OutlinedTextField label="End" />
            </Stack>
            <div className={styles.card}>
                <Stack direction="row" spacing={1}>
                    <Label>{listType === "job" ? "Workers" : "Jobs"}</Label>
                    {!timesheet && <CircularProgress size={20} />}
                </Stack>
                {timesheet?.entries ? (
                    listType === "job" ? (
                        <ListItem
                            item={timesheet.entries.map(entry => {
                                return {
                                    ...entry,
                                    job: timesheet.job,
                                    region_short_string: timesheet.region_short_string,
                                    job_name_string: timesheet.job_name_string
                                };
                            })}
                        />
                    ) : (
                        <ListItem
                            item={timesheet.entries.map(entry => {
                                return {
                                    ...entry,
                                    entity: timesheet.entity,
                                    entity_name_string: timesheet.entity_name_string
                                };
                            })}
                        />
                    )
                ) : (
                    ""
                )}
            </div>
        </>
    );
};

export default JobView;
