import { set, get, del } from "idb-keyval";

function loadTableByKey(key) {
    return async () => {
        const observation = await get(key);
        return observation;
    };
}

function addValuesByKey(key, data) {
    return async () => {
        const observation = await get(key);
        if (JSON.stringify(observation) !== JSON.stringify(data)) {
            await set(key, data);
        }
    };
}

function deleteTableByKey(key) {
    return async () => {
        del(key);
    };
}

// export

const dbActions = {
    loadTableByKey,
    addValuesByKey,
    deleteTableByKey
};

export { dbActions };
