import React from "react";
import styles from "shared/details.scss";
import { MDBCard, MDBCardHeader, MDBCardBody, MDBRow, MDBCol } from "louis-mdbreact";

const InternalContactDetailCard = props => {
    const { internal_qs, onChangeValue, isDisabled, qsUserData, internal_supervisor, supervisorData, internal_surveyor, surveyorData } = props;
    return (
        <MDBCard className={styles.card}>
            <MDBCardHeader>Internal Contact Details</MDBCardHeader>
            <MDBCardBody>
                <MDBRow>
                    <MDBCol>
                        <label htmlFor="internal_qs">Internal QS</label>
                        <select
                            className="browser-default custom-select"
                            id="internal_qs"
                            name="internal_qs"
                            value={internal_qs || ""}
                            onChange={onChangeValue}
                            disabled={!isDisabled}
                        >
                            <option value="">Select...</option>
                            {qsUserData?.map(qs => (
                                <option key={qs.id} value={qs.id}>
                                    {qs.name_string}
                                </option>
                            ))}
                        </select>
                    </MDBCol>
                </MDBRow>
                <MDBRow className={styles.formRow}>
                    <MDBCol>
                        <label htmlFor="internal_supervisor">Internal Supervisor</label>
                        <select
                            className="browser-default custom-select"
                            id="internal_supervisor"
                            name="internal_supervisor"
                            value={internal_supervisor || ""}
                            onChange={onChangeValue}
                            disabled={!isDisabled}
                        >
                            <option value="">Select...</option>
                            {supervisorData.map(supervisor => (
                                <option key={supervisor.id} value={supervisor.id}>
                                    {supervisor.name_string}
                                </option>
                            ))}
                        </select>
                    </MDBCol>
                </MDBRow>
                <MDBRow className={styles.formRow}>
                    <MDBCol>
                        <label htmlFor="internal_surveyor">Internal Surveyor</label>
                        <select
                            className="browser-default custom-select"
                            id="internal_surveyor"
                            name="internal_surveyor"
                            value={internal_surveyor || ""}
                            onChange={onChangeValue}
                            disabled={!isDisabled}
                        >
                            <option value="">Select...</option>
                            {surveyorData.map(surveyor => (
                                <option key={surveyor.id} value={surveyor.id}>
                                    {surveyor.name_string}
                                </option>
                            ))}
                        </select>
                    </MDBCol>
                </MDBRow>
            </MDBCardBody>
        </MDBCard>
    );
};

export default InternalContactDetailCard;
