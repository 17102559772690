import React from "react";
import styles from "shared/details.scss";
import { MDBCard, MDBCardHeader, MDBCardBody, MDBRow, MDBCol, MDBTable, MDBTableHead, MDBTableBody, MDBInputGroup } from "louis-mdbreact";

const RetentionDetailCard = props => {
    const { sendingData, onChangeValue, isDisabled } = props;
    return (
        <MDBCard className={styles.card}>
            <MDBCardHeader>Retention Details</MDBCardHeader>
            <MDBCardBody>
                <MDBRow>
                    <MDBCol sm="6" className="mb-2">
                        <label htmlFor="defect_liability_period">Defect & Liability Period</label>
                        <input
                            type="number"
                            inputMode="numeric"
                            min="0"
                            max="31"
                            className="form-control"
                            id="defect_liability_period"
                            name="dl_period"
                            value={sendingData.dl_period || ""}
                            onChange={onChangeValue}
                            disabled={!isDisabled}
                        />
                        <div className="invalid-feedback">Please provide a valid date</div>
                    </MDBCol>
                    <MDBCol sm="6" className="mb-2">
                        <label htmlFor="claim_date">Claim Date</label>
                        <input
                            type="number"
                            inputMode="numeric"
                            min="0"
                            max="31"
                            className="form-control"
                            id="claim_date"
                            name="claim_date"
                            value={sendingData.claim_date || ""}
                            onChange={onChangeValue}
                            disabled={!isDisabled}
                            required
                        />
                        <div className="invalid-feedback">Please provide a valid date</div>
                    </MDBCol>
                </MDBRow>
                <MDBRow>
                    <MDBCol>
                        <MDBTable responsive small>
                            <MDBTableHead>
                                <tr>
                                    <th className={styles.textCentre}>Maximum Value</th>
                                    <th className={styles.textCentre}>Retained Percentage</th>
                                </tr>
                            </MDBTableHead>
                            <MDBTableBody>
                                {["one", "two", "three"].map(num => (
                                    <tr key={num}>
                                        <td>
                                            <MDBInputGroup
                                                prepend="$"
                                                inputs={
                                                    <input
                                                        className="form-control"
                                                        type="number"
                                                        inputMode="decimal"
                                                        min="0"
                                                        max="1000000"
                                                        step="0.01"
                                                        name={`retention_threshold_${num}`}
                                                        onChange={onChangeValue}
                                                        value={sendingData[`retention_threshold_${num}`] && sendingData[`retention_threshold_${num}`]}
                                                        disabled={!isDisabled}
                                                        required
                                                    />
                                                }
                                            />
                                        </td>
                                        <td>
                                            <MDBInputGroup
                                                append="%"
                                                inputs={
                                                    <input
                                                        className="form-control"
                                                        type="number"
                                                        inputMode="decimal"
                                                        min="0"
                                                        max="99"
                                                        step="0.01"
                                                        name={`retention_percentage_${num}`}
                                                        onChange={onChangeValue}
                                                        value={
                                                            sendingData[`retention_percentage_${num}`] && sendingData[`retention_percentage_${num}`]
                                                        }
                                                        disabled={!isDisabled}
                                                        required
                                                    />
                                                }
                                            />
                                        </td>
                                    </tr>
                                ))}
                            </MDBTableBody>
                        </MDBTable>
                    </MDBCol>
                </MDBRow>
            </MDBCardBody>
        </MDBCard>
    );
};

export default RetentionDetailCard;
