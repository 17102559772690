import React, { Component } from "react";
import JobView from "./presenter";
import { formatter } from "shared/Utils";
import SiteDiaryModal from "components/modals/SiteDiaryModal";
import CompleteJobDialog from "./CompleteJobDialog";
import { Helmet } from "react-helmet";
import AdjustWorkSiteModal from "./AdjustWorkSiteModal";

class Container extends Component {
    constructor(props) {
        super(props);
        const { isMobile } = props;
        const collapsedArray = isMobile
            ? new Array(16).fill(false)
            : [...new Array(16)].map((_, i) => {
                  switch (i) {
                      case 0:
                      case 1:
                      case 2:
                          return true;
                      default:
                          return false;
                  }
              });
        this.state = {
            isLoading: true,
            diaryModal: false,
            completeOpen: false,
            title: "",
            isLBP: false,
            jobsheetLoaded: false,
            detailLoaded: false,
            isCollapsed: collapsedArray
        };
    }

    componentDidMount = () => {
        window.scrollTo(0, 0);
        const {
            match: { params },
            getDetailJob,
            permissions,
            getQAList,
            getGlobalUsers,
            getLogList,
            getFollowupList
        } = this.props;
        getDetailJob(params.id, "view");
        if (permissions.operations > 0) {
            getQAList(params.id, true);
            getQAList(params.id, false);
        }

        getGlobalUsers();
        getLogList(params.id);
        getFollowupList(params.id);
    };

    componentDidUpdate = prevProps => {
        const {
            detailData,
            permissions,
            match: { params },
            getQAList,
            regions,
            userId,
            roles: { isSupervisor },
            setJobView
        } = this.props;
        if (detailData !== prevProps.detailData) {
            setJobView(true);
            const canHideJob = userId === detailData.internal_supervisor && isSupervisor && !detailData.user_hidden_job;
            const canUnHideJob =
                (userId === detailData.internal_supervisor && isSupervisor && detailData.user_hidden_job) || detailData.user_hidden_job;
            const region = regions && regions.find(reg => reg.id === detailData.region);
            this.setState({
                detailLoaded: true,
                isLoading: false,
                pageId: detailData.id,
                title: `${localStorage.getItem("companyPrefix")}-${detailData.id}${region ? `-${region.region_short_code}` : ""}`,
                totalCost: formatter.format(detailData.job_services.reduce((a, b) => a + Number(b.cost), 0)),
                totalPay: formatter.format(detailData.job_services.reduce((a, b) => a + Number(b.pay_supplier), 0)),
                isLBP: !!detailData.job_services.find(jobService => jobService.service === 10),
                region,
                canHideJob,
                canUnHideJob
            });
        }

        if (permissions !== prevProps.permissions) {
            if (permissions.operations > 0) {
                getQAList(params.id, true);
                getQAList(params.id, false);
            }
        }
    };

    componentWillUnmount = () => {
        const { removeQAList, setJobView } = this.props;
        setJobView(false);
        removeQAList();
    };

    returnToList = () => {
        const { history } = this.props;
        history.push("/jobs/");
    };

    generateBluesheet = event => {
        event.preventDefault();
        const { generateBluesheet, detailData } = this.props;
        generateBluesheet(detailData.id);
    };

    completeClose = _ => {
        const { completeOpen } = this.state;
        this.setState({ completeOpen: !completeOpen });
    };

    completeJob = date => {
        const { markComplete, detailData } = this.props;
        markComplete({ id: detailData.id, date });
    };

    toggleCollapsed = (idx, isNew = null) => {
        const { isCollapsed } = this.state;
        isCollapsed[idx] = isNew ? true : !isCollapsed[idx];
        this.setState({
            isCollapsed
        });
    };

    setHiddenJob = () => {
        const { setHiddenJob, detailData } = this.props;
        setHiddenJob(detailData.id);
    };

    clearHiddenJob = () => {
        const { clearHiddenJob, detailData } = this.props;
        clearHiddenJob(detailData.id);
    };

    updateTimecloud = () => {
        const { updateTimecloud, detailData } = this.props;
        updateTimecloud(detailData.id);
    };

    onClickWorkSite = event => {
        this.setState(prevState => ({
            isWorkSiteModal: !prevState.isWorkSiteModal
        }));
    };

    fileDownload = (id, isLarge) => {
        const { fileDownload } = this.props;
        if (isLarge) {
            const answer = window.confirm(
                "This file is quite large. We suggest you don't download it on mobile connection. Do you want to continue?"
            );
            if (!answer) {
                return false;
            }
        }
        fileDownload(id);
    };

    toggleDiary = () => {
        const { diaryModal } = this.state;
        this.setState({
            diaryModal: !diaryModal
        });
    };

    downloadPLReport = format => {
        const { downloadPLReport, detailData } = this.props;
        downloadPLReport({ format, job: [detailData.id] });
    };

    render = () => {
        const {
            detailData,
            permissions,
            category,
            priceType,
            service,
            qsCheckList,
            serviceSubRate,
            product,
            jobsheetListData,
            QAList,
            PreQAList,
            roles,
            userId
        } = this.props;
        const { diaryModal, completeOpen, title, isWorkSiteModal } = this.state;
        return (
            <>
                <Helmet>
                    <title>
                        Jobs | {title} {`>`} {detailData?.job_name || ""}
                    </title>
                </Helmet>
                <JobView
                    {...this.state}
                    roles={roles}
                    category={category}
                    priceType={priceType}
                    service={service}
                    product={product}
                    serviceSubRate={serviceSubRate}
                    qsCheckList={qsCheckList}
                    detailData={detailData}
                    permissions={permissions}
                    jobsheetListData={jobsheetListData}
                    QAList={QAList}
                    userId={userId}
                    PreQAList={PreQAList}
                    returnToList={this.returnToList}
                    generateBluesheet={this.generateBluesheet}
                    toggleCollapsed={this.toggleCollapsed}
                    fileDownload={this.fileDownload}
                    toggleDiary={this.toggleDiary}
                    downloadPLReport={this.downloadPLReport}
                    completeClose={this.completeClose}
                    onClickWorkSite={this.onClickWorkSite}
                    setHiddenJob={this.setHiddenJob}
                    clearHiddenJob={this.clearHiddenJob}
                />
                <SiteDiaryModal diaryModal={diaryModal} selectedJob={detailData && detailData.id} toggleDiary={this.toggleDiary} />
                <CompleteJobDialog open={completeOpen} handleClose={this.completeClose} markComplete={this.completeJob} />
                {isWorkSiteModal && (
                    <AdjustWorkSiteModal
                        jobId={detailData.id}
                        workCoords={{ lng: detailData.longitude, lat: detailData.latitude }}
                        jobAddress={detailData.main_address_full}
                        modal={isWorkSiteModal}
                        toggle={this.onClickWorkSite}
                    />
                )}
            </>
        );
    };
}

export default Container;
