import React, { memo } from "react";
import { TableRow, TableCell } from "@mui/material";
import AdminCalendarTile from "./AdminCalendarTile";

const AdminCalendarRow = ({ row, datePeriod, events }) => {
    const matchedEvents = events.find(event => event.id === row.id)?.dates;
    return (
        <TableRow>
            <TableCell
                component="th"
                scope="row"
                sx={{
                    borderRight: "1px solid #cacaca",
                    position: "sticky",
                    left: 0,
                    background: "white",
                    zIndex: 2
                }}
            >
                {row.first_name} {row.last_name}
            </TableCell>
            {datePeriod.map(date => (
                <AdminCalendarTile key={date.id} date={date} userId={row.id} events={matchedEvents} />
            ))}
        </TableRow>
    );
};

export default memo(AdminCalendarRow);
