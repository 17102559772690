import React, { memo } from "react";
import CustomColoredBadge from "components/CustomColoredBadge";
import { getColor } from "shared/Utils";

const RolesList = props => {
    const { element } = props;
    return (
        <li>
            {element.associated_score ? (
                <CustomColoredBadge
                    badgeContent={element.associated_score}
                    customcolor={getColor(element.associated_score - 1)}
                >
                    {element.name}
                </CustomColoredBadge>
            ) : (
                element.name
            )}
        </li>
    );
};

export default memo(RolesList);
