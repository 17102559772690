import PropTypes from "prop-types";
import React, { Component } from "react";

class FileDrop extends Component {
    static isIE = () =>
        typeof window !== "undefined" &&
        (window.navigator.userAgent.indexOf("MSIE") !== -1 ||
            window.navigator.appVersion.indexOf("Trident/") > 0);

    static eventHasFiles = event => {
        // In most browsers this is an array, but in IE11 it's an Object :(
        let hasFiles = false;
        if (event.dataTransfer) {
            const types = event.dataTransfer.types;
            // eslint-disable-next-line no-unused-vars
            for (const keyOrIndex in types) {
                if (types[keyOrIndex] === "Files") {
                    hasFiles = true;
                    break;
                }
            }
        }
        return hasFiles;
    };

    static propTypes = {
        name: PropTypes.string,
        className: PropTypes.string,
        targetClassName: PropTypes.string,
        draggingOverFrameClassName: PropTypes.string,
        draggingOverTargetClassName: PropTypes.string,
        onDragOver: PropTypes.func,
        onDragLeave: PropTypes.func,
        onDrop: PropTypes.func,
        onClick: PropTypes.func,
        dropEffect: PropTypes.oneOf(["copy", "move", "link", "none"]),
        frame: (props, propName, componentName) => {
            const prop = props[propName];
            if (prop == null) {
                return new Error(
                    "Warning: Required prop `" +
                        propName +
                        "` was not specified in `" +
                        componentName +
                        "`"
                );
            }
            if (prop !== document && !(prop instanceof HTMLElement)) {
                return new Error(
                    "Warning: Prop `" +
                        propName +
                        "` must be one of the following: document, HTMLElement!"
                );
            }
        },
        onFrameDragEnter: PropTypes.func,
        onFrameDragLeave: PropTypes.func,
        onFrameDrop: PropTypes.func
    };

    static defaultProps = {
        dropEffect: "copy",
        frame: typeof window === "undefined" ? undefined : window.document,
        className: "file-drop",
        targetClassName: "file-drop-target",
        draggingOverFrameClassName: "file-drop-dragging-over-frame",
        draggingOverTargetClassName: "file-drop-dragging-over-target"
    };

    constructor(props) {
        super(props);
        this.frameDragCounter = 0;
        this.state = { draggingOverFrame: false, draggingOverTarget: false };
    }

    componentDidMount() {
        this.startFrameListeners(this.props.frame);
        this.resetDragging();
        window.addEventListener("dragover", this.handleWindowDragOverOrDrop);
        window.addEventListener("drop", this.handleWindowDragOverOrDrop);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.frame !== this.props.frame) {
            this.resetDragging();
            this.stopFrameListeners(this.props.frame);
            this.startFrameListeners(nextProps.frame);
        }
    }

    componentWillUnmount() {
        this.stopFrameListeners(this.props.frame);
        window.removeEventListener("dragover", this.handleWindowDragOverOrDrop);
        window.removeEventListener("drop", this.handleWindowDragOverOrDrop);
    }

    frameDragCounter;

    resetDragging = () => {
        this.frameDragCounter = 0;
        this.setState({ draggingOverFrame: false, draggingOverTarget: false });
    };

    handleWindowDragOverOrDrop = event => {
        // This prevents the browser from trying to load whatever file the user dropped on the window
        event.preventDefault();
    };

    handleFrameDrag = event => {
        // Only allow dragging of files
        if (!FileDrop.eventHasFiles(event)) return;

        // We are listening for events on the 'frame', so every time the user drags over any element in the frame's tree,
        // the event bubbles up to the frame. By keeping count of how many "dragenters" we get, we can tell if they are still
        // "draggingOverFrame" (b/c you get one "dragenter" initially, and one "dragenter"/one "dragleave" for every bubble)
        // This is far better than a "dragover" handler, which would be calling `setState` continuously.
        this.frameDragCounter += event.type === "dragenter" ? 1 : -1;

        if (this.frameDragCounter === 1) {
            this.setState({ draggingOverFrame: true });
            if (this.props.onFrameDragEnter) this.props.onFrameDragEnter(event);
            return;
        }

        if (this.frameDragCounter === 0) {
            this.setState({ draggingOverFrame: false });
            if (this.props.onFrameDragLeave) this.props.onFrameDragLeave(event);
            return;
        }
    };

    handleFrameDrop = event => {
        if (!this.state.draggingOverTarget) {
            this.resetDragging();
            if (this.props.onFrameDrop) this.props.onFrameDrop(event);
        }
    };

    handleDragOver = event => {
        if (FileDrop.eventHasFiles(event)) {
            this.setState({ draggingOverTarget: true });
            if (!FileDrop.isIE() && this.props.dropEffect)
                event.dataTransfer.dropEffect = this.props.dropEffect;
            if (this.props.onDragOver) this.props.onDragOver(event);
        }
    };

    handleDragLeave = event => {
        this.setState({ draggingOverTarget: false });
        if (this.props.onDragLeave) this.props.onDragLeave(event);
    };

    handleClick = event => {
        event.preventDefault();
        if (this.props.onClick) this.props.onClick(event);
    };

    handleDrop = event => {
        if (this.props.onDrop && FileDrop.eventHasFiles(event)) {
            const files = event.dataTransfer ? event.dataTransfer.files : null;
            this.props.onDrop(files, event);
        }
        this.resetDragging();
    };

    stopFrameListeners = frame => {
        if (frame) {
            frame.removeEventListener("dragenter", this.handleFrameDrag);
            frame.removeEventListener("dragleave", this.handleFrameDrag);
            frame.removeEventListener("drop", this.handleFrameDrop);
        }
    };

    startFrameListeners = frame => {
        if (frame) {
            frame.addEventListener("dragenter", this.handleFrameDrag);
            frame.addEventListener("dragleave", this.handleFrameDrag);
            frame.addEventListener("drop", this.handleFrameDrop);
        }
    };

    render() {
        const {
            name,
            children,
            className,
            targetClassName,
            draggingOverFrameClassName,
            draggingOverTargetClassName
        } = this.props;
        const { draggingOverTarget, draggingOverFrame } = this.state;

        let fileDropTargetClassName = targetClassName;
        if (draggingOverFrame) fileDropTargetClassName += ` ${draggingOverFrameClassName}`;
        if (draggingOverTarget) fileDropTargetClassName += ` ${draggingOverTargetClassName}`;

        return (
            <button
                name={name}
                className={className}
                onDragOver={this.handleDragOver}
                onDragLeave={this.handleDragLeave}
                onDrop={this.handleDrop}
                onClick={this.handleClick}
            >
                <div className={fileDropTargetClassName}>{children}</div>
            </button>
        );
    }
}

export default FileDrop;
