import React, { memo, useState, useRef, useEffect, useCallback } from "react";
import mapboxgl from "mapbox-gl";
import { SearchBox } from "@mapbox/search-js-react";
import { Button, DialogContent, Grid, DialogActions, Stack, Typography } from "@mui/material";
import { axiosInstance } from "shared/axiosInst";
import DialogContainer from "components/DialogContainer";
import { useSelector, useDispatch } from "react-redux";
import { actionCreators as jobActions } from "redux/modules/jobs";
import { Label } from "components/HumanResources/CustomComponent";
import { LoadingButton } from "@mui/lab";
import markerImg from "../../../images/marker.png";
import jobMarkerImg from "../../../images/marker_job.png";

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_KEY;
let marker;

const markerEl = document.createElement("img");
markerEl.src = markerImg;

const jobMarkerEl = document.createElement("img");
jobMarkerEl.src = jobMarkerImg;

const defaultLocation = { lat: -37.22442, lng: 175.006497 };

const AdjustWorkSiteModal = ({ jobId, workCoords, jobAddress, modal, toggle }) => {
    const dispatch = useDispatch();
    const {
        user: { currentCompany }
    } = useSelector(state => state);
    const map = useRef(null);
    const [newWorkCoords, setNewWorkCoords] = useState(workCoords);
    const [newJobAddress, setNewJobAddress] = useState(jobAddress);
    const [isSearching, setIsSearching] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [mapContainer, setMapContainer] = useState(null);
    const [isMoved, setIsMoved] = useState(false);

    const dragEnd = useCallback(event => {
        const {
            target: { _lngLat }
        } = event;
        setIsMoved(true);
        setNewWorkCoords({ lng: _lngLat.lng, lat: _lngLat.lat });
    }, []);

    const replacedString = txt => {
        const reg = /\d+\//;
        return String(txt).replace(reg, "");
    };

    const submitCoords = event => {
        const { lng, lat } = newWorkCoords;
        setIsSubmitting(true);
        axiosInstance.patch(`${currentCompany}job/job/${jobId}/update_worksite_location/`, { latitude: lat, longitude: lng }).then(() => {
            setIsSubmitting(false);
            dispatch(jobActions.setLngLat({ latitude: lat, longitude: lng }));
            toggle({ latitude: lat, longitude: lng });
        });
    };

    const onInterceptSearch = searchText => {
        return replacedString(searchText);
    };

    const onResetLocation = () => {
        const { lng, lat } = workCoords;
        setNewWorkCoords({ lng, lat });
        setIsMoved(false);
    };

    const onAddressLocation = () => {
        const { longitude, latitude } = newJobAddress;
        setNewWorkCoords({ lng: longitude, lat: latitude });
        setIsMoved(true);
    };

    const onClickCurrentLocation = () => {
        setIsSearching(true);
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                position => {
                    const {
                        coords: { longitude, latitude }
                    } = position;
                    setNewWorkCoords({ lng: longitude, lat: latitude });
                    if (newJobAddress.lat === defaultLocation.lat) {
                        setNewJobAddress({ lng: longitude, lat: latitude });
                    }
                    setIsSearching(false);
                    setIsMoved(true);
                },
                error => {
                    setIsSearching(false);
                    alert(error.code);
                }
            );
        } else {
            setIsSearching(false);
            alert("Geolocation is not supported by this browser.");
        }
    };

    const onRetrieve = features => {
        const center = features.features[0].geometry.coordinates;
        setNewWorkCoords({
            lat: center[1],
            lng: center[0]
        });
    };

    const addMarker = useCallback(
        center => {
            marker?.off("dragend", dragEnd);
            marker?.remove();
            marker = new mapboxgl.Marker(markerEl, { draggable: true, anchor: "bottom", offset: [0, 4] }).setLngLat(center).addTo(map.current);
            marker.on("dragend", dragEnd);
        },
        [dragEnd]
    );

    const addJobMarker = center => {
        new mapboxgl.Marker(jobMarkerEl, { anchor: "bottom", offset: [0, 4] }).setLngLat(center).addTo(map.current);
    };

    const createMap = useCallback(
        (center, markerCnt = null, zoom = 17) => {
            if (map.current) {
                map.current.flyTo({ center, essential: true, zoom });
                if (center.lat !== defaultLocation.lat) {
                    addMarker(center);
                }
            } else {
                map.current = new mapboxgl.Map({
                    container: mapContainer,
                    style: "mapbox://styles/mapbox/streets-v12",
                    center: center,
                    zoom
                });
                if (center.lat !== defaultLocation.lat) {
                    addJobMarker(markerCnt);
                    addMarker(center);
                }
            }
        },
        [addMarker, mapContainer]
    );

    useEffect(() => {
        if (mapContainer) {
            const addressString = `${newJobAddress.number} ${newJobAddress.road_name} ${newJobAddress.suburb} ${newJobAddress.post_code} ${newJobAddress.country}`;
            const replacedAddress = replacedString(addressString);
            const getMap = async () => {
                let addressGeoCode;
                let workCoords = newWorkCoords;
                let zoom = 17;
                if (!newJobAddress.latitude) {
                    const addressGeoCodeResponse = await axiosInstance.get(
                        `https://api.mapbox.com/geocoding/v5/mapbox.places/${replacedAddress}.json?access_token=${mapboxgl.accessToken}&limit=1&country=NZ`
                    );
                    addressGeoCode = {
                        lat: addressGeoCodeResponse.data.features[0]?.center[1] || defaultLocation.lat,
                        lng: addressGeoCodeResponse.data.features[0]?.center[0] || defaultLocation.lng
                    };

                    setNewJobAddress(current => ({
                        ...current,
                        longitude: addressGeoCode.lng,
                        latitude: addressGeoCode.lat
                    }));
                    if (!newWorkCoords.lat) {
                        workCoords = addressGeoCode;
                        setNewWorkCoords(addressGeoCode);
                    }
                } else {
                    addressGeoCode = { lat: newJobAddress.latitude, lng: newJobAddress.longitude };
                    if (!newWorkCoords.lat) {
                        workCoords = addressGeoCode;
                        setNewWorkCoords(addressGeoCode);
                    }
                }
                if (workCoords.lat === defaultLocation.lat) {
                    zoom = 7;
                } else {
                    zoom = 17;
                }
                createMap(workCoords, addressGeoCode, zoom);
            };
            // if (!newJobAddress.latitude && !newWorkCoords.lat) {
            //     onClickCurrentLocation();
            // }
            getMap();
        }
    }, [mapContainer, newJobAddress, newWorkCoords, createMap]);

    useEffect(() => {
        return () => {
            marker?.remove();
            map?.current?.remove();
            map.current = null;
        };
    }, []);

    return (
        <DialogContainer open={modal} handleClose={toggle} maxWidth="md" fullScreen={false} title={`Adjust Work Site Address`}>
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item sm={12} xs={12} md={12}>
                        <Typography variant="subtitle2">
                            Drag "work site" marker to adjust work site location or click current location if you are already on ths site.
                        </Typography>
                    </Grid>
                    <Grid item sm={12} xs={12} md={12}>
                        <Label>search Address</Label>
                        <SearchBox
                            options={{ country: "NZ", placeholder: "Search address ..." }}
                            accessToken={mapboxgl.accessToken}
                            value={""}
                            theme={{ variables: { border: "1px solid #bccbd7", boxShadow: "none" }, cssText: `.Results {z-index:2000}` }}
                            interceptSearch={onInterceptSearch}
                            onRetrieve={onRetrieve}
                        />
                    </Grid>
                    <Grid item sm={12} xs={12} md={12}>
                        <Stack spacing={1} direction="row" justifyContent="flex-start">
                            {jobAddress.latitude !== workCoords.latitude && (
                                <Button color="warning" variant="contained" size="small" onClick={onAddressLocation}>
                                    Go to job address
                                </Button>
                            )}
                            <LoadingButton color="secondary" variant="contained" size="small" onClick={onClickCurrentLocation} loading={isSearching}>
                                current location
                            </LoadingButton>
                            {isMoved && (
                                <Button color="error" variant="contained" size="small" onClick={onResetLocation}>
                                    reset location
                                </Button>
                            )}
                        </Stack>
                    </Grid>
                    <Grid item sm={12} xs={12} md={12}>
                        <div
                            style={{ width: "100%", height: "300px" }}
                            className="map-container"
                            ref={el => {
                                setMapContainer(el);
                            }}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button color="inherit" onClick={toggle}>
                    Cancel
                </Button>
                <LoadingButton loading={isSubmitting} onClick={submitCoords}>
                    set location
                </LoadingButton>
            </DialogActions>
        </DialogContainer>
    );
};

export default memo(AdjustWorkSiteModal);
