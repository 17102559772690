import React, { Fragment, useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import styles from "shared/details.scss";
import { makeStyles } from "@mui/styles";
import { MDBBtnGroup, MDBBtn, MDBIcon } from "louis-mdbreact";
import { useFormContext, Controller, useWatch } from "react-hook-form";
import AsyncSelect from "react-select/async";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import VaildSelect, { SelectLabel } from "components/ValidSelect";
import { Box } from "@mui/material";

const useStyles = makeStyles({
    root: {
        background: "white"
    }
});

const UNCOMMON_YELLOW = "#fff176";

const GoodsItem = ({ list, jobNames, skuDatabase, index, remove, prepend }) => {
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up("sm"));
    const {
        control,
        formState: { errors }
    } = useFormContext();
    const [isDestinationLocal, setIsDestinationLocal] = useState(false);
    const [isUncommonSKU, setIsUncommonSKU] = useState(false);
    const currentDestination = useWatch({ name: `transferList[${index}].destination_job` });
    useEffect(() => {
        if (currentDestination && currentDestination < 100) {
            setIsDestinationLocal(true);
        } else {
            setIsDestinationLocal(false);
        }
    }, [currentDestination]);

    const classes = useStyles();
    const idealStyles = {
        option: (styles, { data, isSelected }) => {
            return {
                ...styles,
                backgroundColor: isSelected ? "#2684FF" : data?.uncommon_sku ? UNCOMMON_YELLOW : ""
            };
        }
    };
    const warningStyles = {
        control: provided => ({
            ...provided,
            borderColor: "red"
        }),
        ...idealStyles
    };

    const filterData = inputValue => {
        return skuDatabase.filter(i => i.description.toLowerCase().includes(inputValue.toLowerCase()));
    };

    const promiseOptions = inputValue =>
        new Promise(resolve => {
            setTimeout(() => {
                resolve(filterData(inputValue));
            }, 1000);
        });
    return (
        <tr key={list.idx} className={styles.addItemContainer} style={{ backgroundColor: isUncommonSKU ? UNCOMMON_YELLOW : "" }}>
            <td data-th="Transfer Details">
                <Grid container spacing={1}>
                    <Grid item xs={12} md={5}>
                        <Controller
                            name={`transferList[${index}].source_job`}
                            control={control}
                            rules={{ required: true }}
                            render={({ field: { value, onChange }, ...rest }) => (
                                <Box sx={{ position: "relative" }}>
                                    <VaildSelect
                                        styles={errors?.transferList?.[index]?.source_job?.type === "required" ? warningStyles : idealStyles}
                                        options={jobNames}
                                        title="From Location"
                                        value={jobNames.find(name => name.value === value)}
                                        onChange={option => onChange(option.value)}
                                        {...rest}
                                    />
                                </Box>
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} md={5}>
                        <Controller
                            name={`transferList[${index}].sku`}
                            control={control}
                            rules={{ required: true }}
                            defaultValue={""}
                            render={({ field: { onChange }, field }) => (
                                <>
                                    {skuDatabase.length > 0 ? (
                                        <Box sx={{ position: "relative" }}>
                                            <AsyncSelect
                                                inputId="sku"
                                                styles={errors?.transferList?.[index]?.sku?.type === "required" ? warningStyles : idealStyles}
                                                cacheOptions
                                                defaultOptions
                                                components={{ DropdownIndicator: null }}
                                                onChange={option => {
                                                    onChange(option.id);
                                                    setIsUncommonSKU(option.uncommon_sku);
                                                }}
                                                getOptionLabel={option => option.description}
                                                getOptionValue={option => option.id}
                                                loadOptions={promiseOptions}
                                                placeholder="Search..."
                                            />
                                            <SelectLabel title="SKU" />
                                        </Box>
                                    ) : (
                                        <TextField
                                            error={errors?.transferList?.[index]?.sku?.type === "required"}
                                            className={classes.root}
                                            fullWidth
                                            size="small"
                                            {...field}
                                        />
                                    )}
                                </>
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} md={2}>
                        <Controller
                            name={`transferList[${index}].quantity`}
                            control={control}
                            rules={{ required: true }}
                            defaultValue={""}
                            render={({ field }) => (
                                <TextField
                                    error={errors?.transferList?.[index]?.quantity?.type === "required"}
                                    type="number"
                                    label="Quantity"
                                    InputLabelProps={{ shrink: true, sx: { zIndex: 0 } }}
                                    className={classes.root}
                                    fullWidth
                                    size="small"
                                    {...field}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} md={5}>
                        <Controller
                            name={`transferList[${index}].destination_job`}
                            control={control}
                            rules={{ required: true }}
                            render={({ field: { value, onChange }, ...rest }) => (
                                <Box sx={{ position: "relative" }}>
                                    <VaildSelect
                                        styles={errors?.transferList?.[index]?.destination_job?.type === "required" ? warningStyles : idealStyles}
                                        options={jobNames}
                                        title="To Location"
                                        menuPlacement={matches ? "auto" : "top"}
                                        value={jobNames.find(name => name.value === value)}
                                        onChange={option => onChange(option.value)}
                                        {...rest}
                                    />
                                </Box>
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} md={isDestinationLocal ? 4 : 7}>
                        <Controller
                            name={`transferList[${index}].pickup_by`}
                            control={control}
                            defaultValue={""}
                            render={({ field }) => (
                                <TextField
                                    label="Pickup By"
                                    InputLabelProps={{ shrink: true, sx: { zIndex: 0 } }}
                                    className={classes.root}
                                    fullWidth
                                    size="small"
                                    {...field}
                                />
                            )}
                        />
                    </Grid>
                    {isDestinationLocal && (
                        <Grid item xs={12} md={isDestinationLocal ? 3 : 6}>
                            <Controller
                                name={`transferList[${index}].directed_to`}
                                control={control}
                                defaultValue={""}
                                render={({ field }) => (
                                    <TextField
                                        label="User of Consumable"
                                        InputLabelProps={{ shrink: true, sx: { zIndex: 0 } }}
                                        className={classes.root}
                                        fullWidth
                                        size="small"
                                        {...field}
                                    />
                                )}
                            />
                        </Grid>
                    )}
                </Grid>
            </td>
            <td className={styles.textCentre} data-th="Action">
                <MDBBtnGroup className={styles.buttonGroup}>
                    <MDBBtn color="danger" size="sm" name="remove" className={styles.buttonSize} onClick={() => remove(index)}>
                        <MDBIcon far icon="trash-alt" />
                    </MDBBtn>
                    <MDBBtn color="primary" size="sm" name="copy" className={styles.buttonSize} onClick={() => prepend(index)}>
                        <MDBIcon far icon="copy" />
                    </MDBBtn>
                </MDBBtnGroup>
            </td>
        </tr>
    );
};

export default GoodsItem;
