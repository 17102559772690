import React, { useState, useMemo, useEffect } from "react";
import styles from "shared/listStyles.scss";
import leaveStyles from "./styles.scss";
import CreateNewButton from "components/CreateNewButton";
import { useSelector, useDispatch } from "react-redux";
import { Chip, Stack } from "@mui/material";
import { MDBBtnGroup, MDBBtn, MDBIcon, MDBDataTable } from "louis-mdbreact";
import { LeaveIcon } from "../CustomComponent";
import { Link } from "react-router-dom";
import { axiosInstance } from "shared/axiosInst";
import { actionCreators as userActions } from "redux/modules/user";
import { ThemeProvider } from "@mui/material/styles";
import theme from "../LeaveItem/ColorCode";
import Loading from "components/Loading";
import dayjs from "shared/dayjs";
import { Helmet } from "react-helmet";

const LeaveList = () => {
    const { currentCompany, direct_report } = useSelector(state => state.user);
    const [searchText, setSearchText] = useState(sessionStorage.getItem("search") || "");
    const [leaveList, setLeaveList] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [globalUsers, setGlobalUsers] = useState([]);
    const [activePage] = useState(sessionStorage.getItem("activePage") ? Number(sessionStorage.getItem("activePage")) - 1 : 0);
    const [listStatus, setListStatus] = useState(JSON.parse(localStorage.getItem("leaveList")) || {});
    const onSearch = searchText => {
        sessionStorage.setItem("search", searchText);
        setSearchText(searchText);
    };
    const dispatch = useDispatch();

    useEffect(() => {
        const getLeaves = async () => {
            setIsLoading(true);
            const globalUsers = await dispatch(userActions.getGlobalUsers());
            setGlobalUsers(globalUsers);
            const leavesResponse = await axiosInstance.get(`${currentCompany}hr/leave_request/${!listStatus.isMine ? "?list_all=true" : ""}`);
            setLeaveList(leavesResponse.data);
            setIsLoading(false);
        };
        getLeaves();
    }, [dispatch, currentCompany, listStatus.isMine]);

    const onPageChange = pageInfo => {
        sessionStorage.setItem("activePage", pageInfo.activePage);
    };

    const changeListStatus = () => {
        const leaveListStatus = { ...listStatus, isMine: !listStatus.isMine };
        localStorage.setItem("leaveList", JSON.stringify(leaveListStatus));
        setListStatus(leaveListStatus);
    };

    const getRequestCancelled = stateStr => {
        return stateStr === "Declined" || stateStr === "Withdrawn" || stateStr === "Cancelled";
    };

    const data = useMemo(
        () => ({
            columns: [
                { label: "No.", field: "leave_number", sort: "asc" },
                { label: "Name", field: "employee_name", sort: "asc" },
                { label: "Leave Dates", field: "leave_dates", sort: "disabled" },
                { label: "Requested Date", field: "requested_date", sort: "asc" },
                { label: "Requested By", field: "requested_by", sort: "asc" },
                { label: "Number of Days", field: "number_of_days", sort: "asc" },
                { label: "Leave Type", field: "leave_type", sort: "asc" },
                { label: "Leave State", field: "leave_state", sort: "asc" },
                { label: "Actions", field: "actions", sort: "disabled" }
            ],
            rows: leaveList.map(leave => {
                const leaveDays = leave.leave_days;
                const halfDays = leaveDays.filter(day => day.proportion_of_day === "0.50").length;
                const numberOfDays = leaveDays.length - halfDays * 0.5;
                let rowClassName;
                if (getRequestCancelled(leave.request_state_string)) {
                    rowClassName = "pink lighten-4";
                } else if (leave.request_state_string === "Approved") {
                    rowClassName = "lime lighten-3";
                }
                return {
                    leave_number: leave.id,
                    employee_name: leave.employee_name,
                    leave_dates: (
                        <Stack direction="row" flexWrap="wrap">
                            {leaveDays.map(day => (
                                <Chip
                                    key={day.id}
                                    sx={{ margin: "3px" }}
                                    size="small"
                                    color={leave.leave_type_string}
                                    icon={<LeaveIcon leaveProportion={day.proportion_of_day} />}
                                    label={dayjs(day.leave_date).format("DD/MM/YYYY")}
                                />
                            ))}
                        </Stack>
                    ),
                    requested_date: <span searchvalue={Number(new Date(leave.submitted_at))}>{dayjs(leave.submitted_at).format("DD/MM/YYYY")}</span>,
                    requested_by: globalUsers.find(user => user.id === leave.submitted_by).name_string,
                    number_of_days: numberOfDays,
                    searchable: `${dayjs(leave.submitted_at).format("DD/MM/YYYY")}`,
                    leave_type: leave.leave_type_string,
                    leave_state: leave.request_state_string,
                    actions: (
                        <MDBBtnGroup>
                            <Link to={`/humanresources/leaverequest/${leave.id}`}>
                                <MDBBtn color="primary" className={`${styles.buttonSize} ${styles.noRound}`}>
                                    <MDBIcon icon="info-circle" />
                                </MDBBtn>
                            </Link>
                        </MDBBtnGroup>
                    ),
                    rowClassName
                };
            })
        }),
        [leaveList, globalUsers]
    );

    return (
        <div className={styles.container}>
            <Helmet>
                <title>HR | {listStatus.isMine ? "MY" : "All"} LEAVES</title>
            </Helmet>
            <div className={styles.titleWrap}>
                <h2 className={`${styles.taskTitle}`}>{listStatus.isMine ? "MY" : "All"} LEAVES</h2>
                {direct_report?.length > 0 && (
                    <div className={styles.switchWrap}>
                        <div className={`${styles.listSwitch} custom-control custom-switch`}>
                            <input
                                type="checkbox"
                                className="custom-control-input"
                                id="mine"
                                name="isMine"
                                checked={listStatus?.isMine || false}
                                onChange={changeListStatus}
                                readOnly
                            />
                            <label className="custom-control-label" htmlFor="mine">
                                MINE
                            </label>
                        </div>
                    </div>
                )}
                <CreateNewButton />
            </div>
            {isLoading ? (
                <Loading loadingType="list" />
            ) : (
                <ThemeProvider theme={theme}>
                    <MDBDataTable
                        entriesOptions={[10, 20, 50, 100, 500]}
                        entries={20}
                        responsive
                        borderless
                        striped
                        responsiveSm
                        pagesAmount={5}
                        data={data}
                        sortRows={["requested_date"]}
                        searchText={searchText}
                        initialPage={activePage}
                        onSearch={onSearch}
                        onPageChange={onPageChange}
                        className={`${leaveStyles.dataTable} ${styles.dataTable} mt-3`}
                    />
                </ThemeProvider>
            )}
        </div>
    );
};

export default LeaveList;
