import React, { useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { actionCreators as userActions } from "redux/modules/user";
import Chip from "@mui/material/Chip";
import { MDBIcon } from "louis-mdbreact";
import styles from "./styles.scss";

const ReplaceFileList = ({ file, makeReplace, fileChange }) => {
    const fileInput = useRef();
    return (
        <li>
            <span>
                <span className={styles.textBox} onClick={e => fileInput.current.click()}>
                    {file.new_file ? file.new_file.name : "Search a new file..."}
                </span>
                <input
                    type="file"
                    hidden
                    ref={fileInput}
                    onChange={event => fileChange({ new_file: event.target.files[0], timestamp: file.timestamp, listType: "replace" })}
                />{" "}
                <span className="purple-text mr-1">{`- ${file.file_category_string}`}</span>
            </span>
            <Chip className="mr-1 my-1" size="small" color="primary" label="Search" onClick={e => fileInput.current.click()} />
            <Chip className="my-1" size="small" color="secondary" label="Cancel" onClick={() => makeReplace(file.timestamp)} />
        </li>
    );
};

const IdleFileList = ({ file, fileDownload, makeReplace }) => {
    return (
        <li>
            <span onClick={e => fileDownload(file.id, file.large_file)}>
                <MDBIcon far={!file.large_file} icon="file-alt" />{" "}
                <span
                    style={{
                        wordBreak: "break-all",
                        cursor: "pointer"
                    }}
                >
                    {file.original_file_name}
                </span>{" "}
                <span className="purple-text mr-1">{`- ${file.file_category_string}`}</span>
            </span>
            <Chip className="my-1" size="small" color="primary" label="Replace" onClick={() => makeReplace(file.timestamp)} />
        </li>
    );
};

const NewFileList = ({ file, removeList, fileChange, selectCategory }) => {
    const dispatch = useDispatch();
    const { file_category, permissions } = useSelector(state => state.user);
    useEffect(() => {
        if (!file_category) {
            dispatch(userActions.getFileCategory());
        }
    }, [dispatch, file_category]);

    const fileInput = useRef();
    return (
        <li className={styles.newFile}>
            <input
                type="file"
                hidden
                ref={fileInput}
                onChange={event => fileChange({ new_file: event.target.files[0], timestamp: file.timestamp, listType: "new" })}
            />
            <span className={styles.textBox} onClick={e => fileInput.current.click()}>
                {file.new_file ? file.new_file.name : "Search a file..."}
            </span>{" "}
            <select
                className={`mr-1 custom-select custom-select-sm ${styles.inlineFormControl}`}
                onChange={event => selectCategory(event.target.value, file.timestamp)}
                value={file.new_file_category}
            >
                <option value="">file category...</option>
                {file_category &&
                    file_category
                        .filter(cate => cate.name !== "Variation" && (permissions.job === 1 ? !cate.type_is_sensitive : cate))
                        .map(category => (
                            <option key={category.id} value={category.id}>
                                {category.name}
                            </option>
                        ))}
            </select>
            <Chip className="mr-1 my-1" size="small" color="primary" label="Search" onClick={e => fileInput.current.click()} />
            <Chip className="my-1" size="small" color="secondary" label="Remove" onClick={() => removeList(file.timestamp)} />
        </li>
    );
};

export { ReplaceFileList, IdleFileList, NewFileList };
