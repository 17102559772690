import { connect } from "react-redux";
import Container from "./container";
import { actionCreators as jobActions } from "redux/modules/jobs";
import { getSlimJobFilteredByBranch } from "redux/reducers";

const mapStateToProps = (state, ownProps) => {
    const {
        user: { permissions }
    } = state;
    return {
        jobList: getSlimJobFilteredByBranch(state),
        permissions,
        currentRegion: localStorage.getItem("currentRegion")
    };
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        getJobRequested: isMine => {
            dispatch(jobActions.getJobRequested(isMine));
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Container);
