import React, { useState, useEffect } from "react";
import styles from "./styles.scss";
import detailStyles from "shared/details.scss";

import Drawer from "@mui/material/Drawer";
import { MDBContainer, MDBIcon, MDBInputGroup, MDBTable, MDBTableHead, MDBTableBody } from "louis-mdbreact";
import PendingButton from "components/PendingButton";
import CreatableInputOnly from "./CreatableInputOnly";
import { axiosInstance } from "shared/axiosInst";

let signal = axiosInstance.CancelToken.source();

function DownloadReport(props) {
    const {
        // companies,
        isSLT,
        isJobPLAccess,
        isAllPLAccess,
        permissions,
        generateBluesheet,
        downloadPLReport,
        downloadRegionalPLReport,
        getSummarysheet,
        generateJobsheet,
        downloadDiary,
        downloadQAReport,
        downloadOperationalForm,
        downloadOrder,
        downloadMyGIBOrders
    } = props;
    const initCurrentCompany = localStorage.getItem("currentCompany")?.split("/")[0] || "fire-akl";
    // const initCurrentPrefix = localStorage.getItem("companyPrefix");
    const [isDrawerOpen, setDrawerOpen] = useState(false);
    const [companyValue] = useState(initCurrentCompany);
    // const [currentPrefix, setPrefix] = useState(initCurrentPrefix);
    const [documentPrefix, setDocumentPrefix] = useState("");
    const [reportId, setReportId] = useState("");
    const [period, setPeriod] = useState({ period_start: "", period_end: "" });
    const [reportType, setReportType] = useState(2);
    const [subOptionType, setSubOptionType] = useState("");
    const [isPending, setPending] = useState(false);
    const [isMultiple, setIsMultiple] = useState(false);
    const [subOption, setSubOption] = useState([]);
    // const currentCompanyRef = useRef(null);
    const documentType = [
        {
            value: 1,
            label: "P&L Report (Job)",
            options: [
                { value: "pdf", label: "PDF" },
                { value: "xlsx", label: "Excel" }
            ],
            onlySLT: !(isSLT || isJobPLAccess || isAllPLAccess),
            hasMultiple: true
        },
        {
            value: 12,
            label: "P&L Report (Regional)",
            options: [
                { value: "current", label: "Current" },
                { value: "fy", label: "Financial Year" },
                { value: "period", label: "Period" }
            ],
            onlySLT: !(isSLT || isAllPLAccess),
            hasMultiple: false
        },
        { value: 2, label: "Bluesheet", app: "jobs", hasMultiple: false },
        { value: 9, label: "H&S Report", prefix: "FORM", app: "operation", hasMultiple: true }
    ];

    const downloadReport = event => {
        event.preventDefault();
        setPending(true);
        const reportIdNumber =
            typeof reportId === "string"
                ? Number(reportId)
                : reportId.map(id => {
                      if (Number(reportType) === 11) {
                          if (subOptionType === "jobs") {
                              return Number(id.value);
                          } else {
                              return String(id.value);
                          }
                      } else {
                          return Number(id.value);
                      }
                  });
        switch (Number(reportType)) {
            case 1:
                downloadPLReport({ format: subOptionType, job: reportIdNumber }, `${companyValue}/`).then(completeDownloadPLReport);
                return;
            case 2:
                generateBluesheet(reportIdNumber, `${companyValue}/`).then(completeDownload);
                return;
            case 3:
                getSummarysheet({ id: reportIdNumber }, `${companyValue}/`).then(completeDownload);
                return;
            case 4:
                generateJobsheet(reportIdNumber, `${companyValue}/`).then(completeDownload);
                return;
            case 5:
                downloadDiary(reportIdNumber, `${companyValue}/`).then(completeDownload);
                return;
            case 6:
            case 7:
            case 8:
                downloadQAReport(reportIdNumber, `${companyValue}/`).then(completeDownload);
                return;
            case 9:
                downloadOperationalForm(reportIdNumber, `${companyValue}/`).then(completeDownload);
                return;
            case 10:
                if (companyValue === "linings-akl") {
                    downloadOrder(reportIdNumber).then(completeDownload);
                } else {
                    alert("There is PO document at only Auckland company");
                    setPending(false);
                }
                return;
            case 11:
                if (companyValue === "linings-akl") {
                    downloadMyGIBOrders({ [`${subOptionType}`]: [...reportIdNumber] }).then(completeDownload);
                } else {
                    alert("There is MyGIBOrder document at only Auckland company");
                    setPending(false);
                }
                return;
            case 12:
                let obj = {};
                switch (subOptionType) {
                    case "current":
                        obj = {};
                        break;
                    case "fy":
                        obj = { fy: Number(reportId) };
                        break;
                    case "period":
                        obj = {
                            period_start: period.period_start,
                            period_end: period.period_end || undefined
                        };
                        break;
                    default:
                        break;
                }
                downloadRegionalPLReport(obj, signal, `${companyValue}/`).then(completeDownload);
                return;
            default:
        }
    };

    const completeDownload = response => {
        setPending(false);

        if (response?.status === 500) {
            alert(`There is no ${documentType.find(type => type.value === Number(reportType)).label} for ${reportId}`);
        } else {
            closeDrawer();
            setReportId("");
        }
    };

    const completeDownloadPLReport = response => {
        setPending(false);
        if (String(response).search(/timeout/) > 0) {
            alert(
                "No response was received from the server before timeout, if you requested a large number of jobs please try again with fewer otherwise please contact the IT Team."
            );
        }
        if (response?.message === "completed") {
            closeDrawer();
            setReportId("");
        } else if (response?.status === 500) {
            alert(`There is no ${documentType.find(type => type.value === Number(reportType)).label} for ${reportId}`);
        }
    };

    const closeDrawer = event => {
        setDrawerOpen(false);
    };

    const changeReportType = event => {
        const {
            target: { value }
        } = event;
        const currentDocumentType = documentType.find(type => type.value === Number(value));
        setReportType(value);
        setReportId("");
        setDocumentPrefix(currentDocumentType.prefix);
        setIsMultiple(currentDocumentType.hasMultiple);
        setSubOption(currentDocumentType.options || []);
        setSubOptionType(currentDocumentType.options ? currentDocumentType.options[0].value : null);
        // const currentCompany = companyList.find(
        //     company => company.value === currentCompanyRef.current.value
        // );
        // setPrefix(value === "10" ? "" : currentCompany.prefix);
    };

    useEffect(() => {
        return () => {
            signal.cancel("cancelled by user");
            signal = axiosInstance.CancelToken.source();
        };
    }, []);

    return (
        <div className={styles.downloadReportContainer}>
            <button type="button" onClick={() => setDrawerOpen(!isDrawerOpen)} className={styles.reportButtonRoot} aria-label="Report download">
                <MDBIcon icon="file-alt" /> REPORT
            </button>
            <Drawer
                classes={{ paperAnchorBottom: styles.drawerRoot }}
                anchor={"bottom"}
                variant="persistent"
                open={isDrawerOpen}
                onClose={closeDrawer}
            >
                <form onSubmit={downloadReport}>
                    <MDBContainer fluid>
                        <button type="button" className={styles.closeBtn} onClick={closeDrawer}>
                            <MDBIcon icon="times" />
                        </button>
                        <h4>Download Report</h4>
                        <MDBTable small className={detailStyles.detailResponsiveTable}>
                            <MDBTableHead>
                                <tr className={detailStyles.textCentre}>
                                    {/* {companyList.length > 1 ? <th>Company</th> : undefined} */}
                                    <th colSpan={subOption?.length > 0 ? "2" : "0"}>
                                        {`Report Type${subOption?.length > 0 ? " (Sub Option)" : ""}`}
                                    </th>
                                    <th width="30%">
                                        {Number(reportType) === 12 ? (subOptionType === "fy" ? "financial Year" : "Period") : "Report ID"}
                                    </th>
                                    <th width="110px">Action</th>
                                </tr>
                            </MDBTableHead>
                            <MDBTableBody>
                                <tr className={detailStyles.addItemContainer}>
                                    {/* {companyList.length > 1 ? (
                                        <td data-th="Company">
                                            <select
                                                id="company"
                                                className="custom-select"
                                                onChange={onCompanyChange}
                                                value={companyValue}
                                                ref={currentCompanyRef}
                                            >
                                                {companyList.map(company => {
                                                    return (
                                                        <option
                                                            key={company.id}
                                                            value={company.value}
                                                            prefix={company.prefix}
                                                        >
                                                            {`${company.prefix} - ${company.label}`}
                                                        </option>
                                                    );
                                                })}
                                            </select>
                                        </td>
                                    ) : undefined} */}
                                    <td data-th="Report Type">
                                        <select id="reportType" className="custom-select" value={reportType} onChange={changeReportType}>
                                            {documentType
                                                .filter(type => !type.onlySLT)
                                                .filter(type => permissions[type.app] !== 0)
                                                .map(type => (
                                                    <option key={type.value} value={type.value}>
                                                        {type.label}
                                                    </option>
                                                ))}
                                        </select>
                                    </td>
                                    {subOption.length ? (
                                        <td data-th="Sub Options">
                                            <select
                                                id="sub-option"
                                                className="custom-select"
                                                value={subOptionType}
                                                onChange={event => {
                                                    setSubOptionType(event.target.value);
                                                }}
                                            >
                                                {subOption.map(type => (
                                                    <option key={type.value} value={type.value}>
                                                        {type.label}
                                                    </option>
                                                ))}
                                            </select>
                                        </td>
                                    ) : undefined}
                                    <td data-th={Number(reportType) === 12 ? (subOptionType === "fy" ? "financial Year" : "Period") : "Report ID"}>
                                        {Number(reportType) === 12 ? (
                                            subOptionType === "fy" ? (
                                                <MDBInputGroup
                                                    inputs={
                                                        <>
                                                            {isMultiple ? (
                                                                <CreatableInputOnly setReportId={setReportId} reportId={reportId} />
                                                            ) : (
                                                                <input
                                                                    id="reportId"
                                                                    type="number"
                                                                    step={1}
                                                                    value={reportId}
                                                                    onChange={e => setReportId(e.target.value)}
                                                                    className="form-control"
                                                                    required
                                                                />
                                                            )}
                                                        </>
                                                    }
                                                />
                                            ) : (
                                                <MDBInputGroup
                                                    inputs={
                                                        <>
                                                            {isMultiple ? (
                                                                <CreatableInputOnly setReportId={setReportId} reportId={reportId} />
                                                            ) : (
                                                                <>
                                                                    <input
                                                                        id="startPeriod"
                                                                        type="month"
                                                                        step={1}
                                                                        value={period.period_start}
                                                                        onChange={e =>
                                                                            setPeriod({
                                                                                ...period,
                                                                                period_start: e.target.value
                                                                            })
                                                                        }
                                                                        className="form-control"
                                                                        disabled={subOptionType === "current"}
                                                                        required
                                                                    />
                                                                    <input
                                                                        id="endPeriod"
                                                                        type="month"
                                                                        step={1}
                                                                        value={period.period_end}
                                                                        disabled={subOptionType === "current"}
                                                                        onChange={e =>
                                                                            setPeriod({
                                                                                ...period,
                                                                                period_end: e.target.value
                                                                            })
                                                                        }
                                                                        className="form-control"
                                                                    />
                                                                </>
                                                            )}
                                                        </>
                                                    }
                                                />
                                            )
                                        ) : (
                                            <MDBInputGroup
                                                prepend={documentPrefix}
                                                inputs={
                                                    <>
                                                        {isMultiple ? (
                                                            <CreatableInputOnly setReportId={setReportId} reportId={reportId} />
                                                        ) : (
                                                            <input
                                                                id="reportId"
                                                                type="number"
                                                                step={1}
                                                                value={reportId}
                                                                onChange={e => setReportId(e.target.value)}
                                                                className="form-control"
                                                                required
                                                            />
                                                        )}
                                                    </>
                                                }
                                            />
                                        )}
                                    </td>
                                    <td data-th="Action">
                                        <PendingButton type="submit" pendingLoading={isPending} className="m-0 btn btn-success btn-sm">
                                            Download
                                        </PendingButton>
                                    </td>
                                </tr>
                            </MDBTableBody>
                        </MDBTable>
                    </MDBContainer>
                </form>
            </Drawer>
        </div>
    );
}

export default DownloadReport;
