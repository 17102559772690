import React from "react";
import { NavLink, Link } from "react-router-dom";
import styles from "shared/headNavStyles.scss";
import { MDBIcon } from "louis-mdbreact";

const StockDependPermission = props => {
    const { permissionLevel, toggleNavbar } = props;
    switch (permissionLevel) {
        case 4:
            return (
                <>
                    <li>
                        <NavLink exact activeClassName={styles.active} to={`/stock/transferred`} onClick={toggleNavbar}>
                            <MDBIcon icon="exchange-alt" /> <span>Transfer</span>
                        </NavLink>
                        <Link className={styles.new} to={`/stock/transferred/new`} onClick={toggleNavbar}>
                            <MDBIcon far icon="plus-square" />
                        </Link>
                    </li>
                    {/* <li>
                        <NavLink
                            exact
                            activeClassName={styles.active}
                            to={`/stock/reports`}
                            onClick={toggleNavbar}
                        >
                            <MDBIcon far icon="file-alt" /> <span>Reports</span>
                        </NavLink>
                    </li> */}
                </>
            );
        case 3:
            return (
                <>
                    <li>
                        <NavLink exact activeClassName={styles.active} to={`/stock/transferred`} onClick={toggleNavbar}>
                            <MDBIcon icon="align-left" /> <span>Transfer</span>
                        </NavLink>
                        <Link className={styles.new} to={`/stock/transferred/new`} onClick={toggleNavbar}>
                            <MDBIcon far icon="plus-square" />
                        </Link>
                    </li>
                </>
            );
        default:
            return (
                <>
                    <li>
                        <NavLink exact activeClassName={styles.active} to={`/stock/transferred`} onClick={toggleNavbar}>
                            <MDBIcon icon="align-left" /> <span>Transfer</span>
                        </NavLink>
                    </li>
                </>
            );
    }
};

export default StockDependPermission;
