import React from "react";
import { TableCell, IconButton, Stack } from "@mui/material";
import { getCompanyColour } from "shared/Utils";
import detailStyles from "shared/details.scss";
import itemStyles from "./styles.scss";

import SubdirectoryArrowRightIcon from "@mui/icons-material/SubdirectoryArrowRight";
import KeyboardReturnIcon from "@mui/icons-material/KeyboardReturn";
import PushPinIcon from "@mui/icons-material/PushPin";
import dayjs from "dayjs";

const NoteTable = ({ followUpNotes, note = null, onAddNoteClick, company = "GL" }) => {
    return (
        <TableCell
            colSpan={8}
            className={detailStyles.expandedRow}
            sx={{ position: "relative", paddingRight: "20px", backgroundColor: "rgba(0 ,0 ,0, 0.05)" }}
        >
            <IconButton
                onClick={onAddNoteClick}
                size="small"
                sx={{
                    position: "absolute",
                    bottom: "12px",
                    right: "18px",
                    backgroundColor: "#af52bf",
                    color: "white",
                    "&:hover": { backgroundColor: "#9c27b0" }
                }}
            >
                <KeyboardReturnIcon />
            </IconButton>
            <Stack flexDirection="row" gap={1}>
                <SubdirectoryArrowRightIcon sx={{ marginTop: "-5px", color: "gray" }} />
                <ul className={itemStyles.noteList}>
                    {note && (
                        <li>
                            <p>{note}</p>
                        </li>
                    )}
                    {followUpNotes.map(note => (
                        <li key={note.created_at}>
                            <span style={{ color: getCompanyColour(company).color }}>
                                {note.created_by} ({dayjs(note.created_at).format("DD/MM/YYYY")}){" "}
                                {note.pinned && <PushPinIcon fontSize="17" sx={{ transform: "rotate(45deg)", color: "red" }} />}
                            </span>
                            <p>{note.note_text}</p>
                        </li>
                    ))}
                </ul>
            </Stack>
        </TableCell>
    );
};

export default NoteTable;
