import React from "react";
import styles from "shared/details.scss";
import history from "shared/history";
import ArrowBackButton from "components/ArrowBackButton";
import { Grid, Card, CardContent, Button } from "@mui/material";
import RequestedLeave from "./RequestedLeave";
import { Helmet } from "react-helmet";

const LeaveForm = () => {
    const returnToList = () => {
        history.push("/humanresources");
    };

    return (
        <div className={styles.container}>
            <Helmet>
                <title>HR | Requested Leave</title>
            </Helmet>
            <h2 className={styles.title}>
                <ArrowBackButton onClick={returnToList} />
                <span>REQUESTED LEAVE</span>
            </h2>
            <Card variant="outlined">
                <CardContent>
                    <RequestedLeave />
                </CardContent>
            </Card>
            <Grid container sx={{ justifyContent: "flex-end", "& button": { my: 2, mx: 0.5 } }}>
                <Button onClick={returnToList} variant="contained">
                    List
                </Button>
            </Grid>
        </div>
    );
};

export default LeaveForm;
