import React, { Suspense, lazy, useEffect, useState } from "react";
import { Button, CircularProgress, Grid } from "@mui/material";
import { axiosInstance } from "shared/axiosInst";
import { useRouteMatch } from "react-router";
import { InvoiceContext, DebtorsContext } from "../CrmContext";

const InvoiceDetailModal = lazy(() => import("./InvoiceDetailModal"));
const InvoiceOverview = lazy(() => import("./InvoiceOverview"));

const InvoiceCard = () => {
    const {
        params: { id }
    } = useRouteMatch();
    const [invoice, setInvoice] = useState({});
    const [overdueInvoice, setOverdueInvoice] = useState({});
    const [isLoading, setIsLoading] = useState(true);

    const [open, setOpen] = useState(false);
    useEffect(() => {
        const getOutstandingInvoice = async () => {
            try {
                const overdueRes = await axiosInstance.get(`crm/outstanding_invoice/?overdue=true&client=${id}`);
                const overdueInvoiceDetails = Object.keys(overdueRes.data.invoice_details).map(data => {
                    return { invoice_guid: data, ...overdueRes.data.invoice_details[data] };
                });
                setOverdueInvoice({ ...overdueRes.data, invoice_details: overdueInvoiceDetails });

                const res = await axiosInstance.get(`crm/outstanding_invoice/?overdue=false&client=${id}`);
                const invoiceDetails = Object.keys(res.data.invoice_details).map(data => {
                    return { invoice_guid: data, ...res.data.invoice_details[data] };
                });
                setInvoice({ ...res.data, invoice_details: invoiceDetails });
                setIsLoading(false);
            } catch (error) {
                setInvoice({});
                setOverdueInvoice({});
                setIsLoading(false);
                console.log(error);
            } finally {
                setIsLoading(false);
            }
        };
        getOutstandingInvoice();
    }, [id]);

    const onSeeAllClick = () => {
        setOpen(current => !current);
    };

    const updateList = ({ newNoteResponseData, newNoteData }) => {
        const updateAllList = current => ({
            ...current,
            invoice_details: current.invoice_details.map(cur =>
                cur.invoice_guid === newNoteResponseData.invoice_guid ? { ...cur, notes: [newNoteData, ...cur.notes] } : cur
            )
        });
        setInvoice(updateAllList);
        setOverdueInvoice(updateAllList);
    };

    const updateListForJobFollowup = ({ item }) => {
        const updateAllList = current => ({
            ...current,
            invoice_details: current.invoice_details.map(cur => (cur.invoice_guid === item.invoice_guid ? item : cur))
        });
        setInvoice(updateAllList);
        setOverdueInvoice(updateAllList);
    };

    return isLoading ? (
        <CircularProgress size={27} sx={{ marginBottom: "10px" }} />
    ) : (
        <Grid container sx={{ marginBottom: "18px" }} spacing={1}>
            <DebtorsContext.Provider value={{ updateList, updateListForJobFollowup }}>
                <InvoiceContext.Provider value={{ overdueInvoice, invoice }}>
                    {overdueInvoice.balance_outstanding !== undefined && (
                        <Grid item sm={12} lg={12} xs={12} sx={{ display: "flex", justifyContent: "flex-end" }}>
                            <Button variant="contained" size="small" onClick={onSeeAllClick}>
                                See all invoices
                            </Button>
                        </Grid>
                    )}
                    {overdueInvoice.balance_outstanding > 0 ? (
                        <Suspense fallback={<div>Loading ...</div>}>
                            <InvoiceOverview invoice={overdueInvoice} isDetailOpen={false} isOverdue />
                        </Suspense>
                    ) : (
                        ""
                    )}
                    {open && (
                        <Suspense fallback={<div>Loading ...</div>}>
                            <InvoiceDetailModal open={open} toggle={onSeeAllClick} />
                        </Suspense>
                    )}
                </InvoiceContext.Provider>
            </DebtorsContext.Provider>
        </Grid>
    );
};

export default InvoiceCard;
