import React, { useEffect, useState } from "react";
import {
    ListItemButton,
    ListItem,
    ListItemText,
    Divider,
    Collapse,
    Box,
    ListItemIcon,
    TableBody,
    Table,
    TableContainer,
    Paper,
    TableHead,
    TableRow,
    TableCell,
    Button
} from "@mui/material";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import styles from "./styles.scss";
import detailStyles from "shared/details.scss";
import TotalValueTable from "./TotalValueTable";
import { getFilteredStageData, getTotalValues } from "./quoteUtils";
import { TableVirtuoso } from "react-virtuoso";
import StageItemContainer from "./StageItemContainer";
import { StageItemContext } from "../CrmContext";
import { copyToClipboard, formatter } from "shared/Utils";

const TableComponents = {
    Scroller: React.forwardRef((props, ref) => <TableContainer component={Paper} sx={{ overflow: "hidden" }} {...props} ref={ref} />),
    Table: props => (
        <Table {...props} className={`${detailStyles.detailResponsiveTable} ${styles.responsiveTable}`} style={{ borderCollapse: "separate" }} />
    ),
    TableHead: React.forwardRef((props, ref) => <TableHead {...props} ref={ref} />),
    TableRow: TableRow,
    TableBody: React.forwardRef((props, ref) => <TableBody {...props} ref={ref} />)
};

const ListItemContainer = ({ title, icon, stageData, open, setOpen, searchText }) => {
    const handleClick = () => {
        setOpen(title);
    };

    const [stageList, setStageList] = useState(stageData);
    const [totalValues, setTotalValues] = useState([]);

    useEffect(() => {
        const filteredData = getFilteredStageData({ searchText, stageData });
        setStageList(filteredData);
    }, [searchText, stageData]);

    useEffect(() => {
        setTotalValues(getTotalValues(stageList));
    }, [stageList]);
    const copyList = e => {
        e.preventDefault();
        const str = `Quote No.\tClient\tName\tValue\tChance\tSent Date\tQS`;
        const list = stageList
            .filter(stage => !stage.isExpandable)
            .map(
                stage =>
                    `${stage.quote_number} ${stage.quote_version} (${stage.region})\t${stage.client_name}\t${stage.job_name}\t${formatter.format(
                        stage.quote_value
                    )}\t${stage.win_chance} %\t${stage.sent_date || "-"}\t${stage.qs}`
            );
        copyToClipboard(str + "\r" + list.join("\r"));
    };
    // need to get followupopen, isExpandable at this level to get expandable item controlled.
    return (
        <StageItemContext.Provider value={{ setStageList }}>
            <ListItemButton
                component="li"
                onClick={handleClick}
                sx={{
                    "&:hover": { background: "white" },
                    position: "sticky",
                    top: "55px",
                    background: "white",
                    borderBottom: open ? "1px solid rgba(0, 0, 0, 0.1)" : "none",
                    zIndex: 3
                }}
            >
                <ListItem component="div" sx={{ paddingLeft: 0 }}>
                    <ListItemIcon sx={{ minWidth: "40px" }}>{icon}</ListItemIcon>
                    <ListItemText
                        sx={{
                            display: "flex",
                            gap: "5px",
                            alignItems: "center"
                        }}
                        primaryTypographyProps={{ fontSize: "20px" }}
                        primary={title}
                        secondaryTypographyProps={{ fontSize: "13px" }}
                        secondary={`| ${stageList.filter(stage => !stage.isExpandable).length} quotes`}
                    />
                </ListItem>
                {open ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse component="li" unmountOnExit in={open} timeout={{ appear: 0, enter: 0, exit: 0 }}>
                <Box padding={2} sx={{ backgroundColor: "rgba(0, 0, 0, 0.05)" }}>
                    <Button size="small" onClick={copyList}>
                        copy list
                    </Button>
                    <TableVirtuoso
                        useWindowScroll
                        style={{ height: "500px" }}
                        data={stageList}
                        context={{
                            getIsExpanded: 1
                        }}
                        components={TableComponents}
                        fixedHeaderContent={() => (
                            <TableRow>
                                <TableCell width="16%">Quote No.</TableCell>
                                <TableCell width="27%">Client</TableCell>
                                <TableCell width="31%">Name/Address</TableCell>
                                <TableCell width={title !== "Won (calendar month)" ? "8%" : "13%"} sx={{ textAlign: "right" }}>
                                    Value
                                </TableCell>
                                {title !== "Won (calendar month)" && (
                                    <TableCell value="5%" sx={{ textAlign: "right" }}>
                                        Chance
                                    </TableCell>
                                )}
                                <TableCell width="7%">Sent Date</TableCell>
                                <TableCell width="4%" sx={{ textAlign: "center" }}>
                                    QS
                                </TableCell>
                                <TableCell width="112px">Actions</TableCell>
                            </TableRow>
                        )}
                        itemContent={(index, stage) => <StageItemContainer title={title} key={index} index={index} stage={stage} />}
                    />
                    <TotalValueTable totalValues={totalValues} />
                </Box>
            </Collapse>
            <Divider component="li" />
        </StageItemContext.Provider>
    );
};

export default ListItemContainer;
