import React from "react";
import withListItems from "./hoc/withListItems";
import { Checkbox, Typography } from "@mui/material";
import { MDBTable, MDBTableBody, MDBTableHead } from "louis-mdbreact";
import styles from "shared/details.scss";
import ListItem from "./ListItem";
import { uuidv4 } from "shared/Utils";

const ListItems = ({ listItems, onChange, onTotalCheckboxChange, isLog }) => {
    return (
        <MDBTable small striped className={styles.detailResponsiveTable} style={isLog ? { marginTop: "20px" } : {}}>
            <MDBTableHead>
                <tr>
                    {!isLog && (
                        <th width="10">
                            <Checkbox
                                id={uuidv4()}
                                sx={{ padding: 0 }}
                                onChange={onTotalCheckboxChange}
                                checked={listItems.reduce((t, c) => t && c.checked, true)}
                                indeterminate={!listItems.every((val, i, arr) => val.checked === arr[0].checked)}
                            />
                        </th>
                    )}
                    <th>Worker</th>
                    <th>Job</th>
                    <th width="30%">MJS</th>
                    <th>Supervisor</th>
                    <th>Date</th>
                    <th>Time</th>
                    <th width="120" className={isLog ? "text-center" : ""}>
                        {isLog ? "Details" : "Action"}
                    </th>
                </tr>
            </MDBTableHead>
            <MDBTableBody>
                {listItems.length === 0 && (
                    <tr>
                        <td colSpan={8}>
                            <Typography textAlign="center">No data</Typography>
                        </td>
                    </tr>
                )}
                {listItems.map((list, index) => (
                    <ListItem key={list.id} list={list} index={index} onChange={onChange} isLog={isLog} />
                ))}
            </MDBTableBody>
        </MDBTable>
    );
};

export default withListItems(ListItems);
