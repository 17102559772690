import React from "react";
import styles from "shared/details.scss";
import PropTypes from "prop-types";
import { MDBCard, MDBCardHeader, MDBCardBody, MDBRow, MDBCol } from "louis-mdbreact";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";

import ArrowBackButton from "components/ArrowBackButton";
import Loading from "components/Loading";
import SubmitButton from "components/SubmitButton";
import ValidSelect from "components/ValidSelect";
import ToolboxTalk from "components/Ops/ToolboxTalk";
import SSSPCheck from "components/Ops/SSSPCheck";
import SiteInspection from "components/Ops/SiteInspection";
import RecipientsForm from "components/RecipientsForm";
import JobDetailTable from "components/Jobs/JobDetailTable";
import FileUploadBackdrop from "components/FileUploadBackdrop";
import { Table, TableBody, TableCell, TableRow } from "@mui/material";

const HealthSafetyDetail = ({
    type,
    title,
    isLoading,
    isValidated,
    jobList,
    formDetail,
    onJobChange,
    job,
    isDisabled,
    jobDetail,
    jobLoading,
    cancelRef,
    returnToList,
    pending,
    formType,
    onFormTypeChange,
    updateFormData,
    updateAttendeeData,
    updateToolboxFiles,
    updateSSSPFiles,
    submitForms,
    subInfo,
    setupValid,
    mailList,
    assigningData,
    progress
}) => {
    return (
        <div className={styles.container}>
            <h2 className={styles.title}>
                <ArrowBackButton />
                <span>{title}</span>
            </h2>
            {isLoading ? (
                <Loading loadingType="detail" />
            ) : (
                <form className={`needs-validation ${isValidated ? "was-validated" : ""}`} onSubmit={submitForms} noValidate>
                    <MDBCard style={{ zIndex: 3 }}>
                        <MDBCardHeader>Job Details</MDBCardHeader>
                        <MDBCardBody>
                            <MDBRow>
                                <MDBCol className="mb-3">
                                    <label htmlFor="job_list">
                                        Job{" "}
                                        {jobLoading && (
                                            <div className="spinner-border text-primary spinner-border-sm" role="status">
                                                <span className="sr-only">Loading...</span>
                                            </div>
                                        )}
                                    </label>
                                    <ValidSelect
                                        id="job_list"
                                        name="job_list"
                                        options={jobList}
                                        onChange={onJobChange}
                                        value={job}
                                        isDisabled={!isDisabled}
                                        isValid={isValidated && job}
                                    />

                                    {jobDetail && <JobDetailTable jobDetail={jobDetail} />}
                                </MDBCol>
                            </MDBRow>
                        </MDBCardBody>
                    </MDBCard>
                    <MDBCard className={styles.card}>
                        <MDBCardHeader>{type === "detail" ? "Form Details" : "Forms"}</MDBCardHeader>
                        <MDBCardBody>
                            {type === "detail" ? (
                                <MDBRow>
                                    <MDBCol>
                                        <Table size="small">
                                            <TableBody>
                                                <TableRow>
                                                    <TableCell width="30%">Submitted By</TableCell>
                                                    <TableCell width="70%">{formDetail.form_submitted_by_name}</TableCell>
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                    </MDBCol>
                                </MDBRow>
                            ) : (
                                <MDBRow>
                                    {formType
                                        ?.filter(type => type.current_type)
                                        .map(fType => (
                                            <MDBCol key={fType.id} sm="4">
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            name={fType.name}
                                                            checked={fType.checked}
                                                            onChange={e => onFormTypeChange(e, fType.id)}
                                                        />
                                                    }
                                                    label={fType.name}
                                                />
                                            </MDBCol>
                                        ))}
                                </MDBRow>
                            )}
                        </MDBCardBody>
                    </MDBCard>
                    {formType
                        .filter(fType => fType.checked)
                        .map(fType => {
                            if (fType.id === 1) {
                                return (
                                    <SSSPCheck
                                        key={fType.id}
                                        type={fType}
                                        assignedData={assigningData.find(data => data && data.form_type === 1)}
                                        isDisabled={isDisabled}
                                        jobDetail={jobDetail}
                                        formDetail={formDetail}
                                        updateFormData={updateFormData}
                                        updateFiles={updateSSSPFiles}
                                    />
                                );
                            } else if (fType.id === 3) {
                                return (
                                    <ToolboxTalk
                                        key={fType.id}
                                        type={fType}
                                        assignedData={assigningData.find(data => data && data.form_type === 3)}
                                        isDisabled={isDisabled}
                                        jobDetail={jobDetail}
                                        formDetail={formDetail}
                                        updateFormData={updateFormData}
                                        updateAttendeeData={updateAttendeeData}
                                        updateFiles={updateToolboxFiles}
                                    />
                                );
                            } else if (fType.id === 2) {
                                return (
                                    <SiteInspection
                                        key={fType.id}
                                        type={fType}
                                        assignedData={assigningData.find(data => data && data.form_type === 2)}
                                        isDisabled={isDisabled}
                                        jobDetail={jobDetail}
                                        formDetail={formDetail}
                                        updateFormData={updateFormData}
                                    />
                                );
                            } else {
                                return (
                                    <SSSPCheck
                                        key={fType.id}
                                        type={fType}
                                        assignedData={assigningData.find(data => data && data.form_type === fType.id)}
                                        isDisabled={isDisabled}
                                        jobDetail={jobDetail}
                                        formDetail={formDetail}
                                        updateFormData={updateFormData}
                                    />
                                );
                            }
                        })}
                    {type === "new" && jobDetail ? (
                        <RecipientsForm jobInfo={jobDetail.id} supervisor={jobDetail.internal_supervisor} subInfo={subInfo} setupValid={setupValid} />
                    ) : undefined}
                    <MDBRow>
                        <MDBCol>
                            <div className={`${styles.card} float-right`}>
                                <button type="button" ref={cancelRef} className="btn btn-blue-grey" onClick={returnToList}>
                                    {type === "new" ? "Cancel" : "List"}
                                </button>{" "}
                                {!isDisabled && <SubmitButton type={type} pendingLoading={pending} emailAddress={mailList.length > 0} />}
                            </div>
                        </MDBCol>
                    </MDBRow>
                </form>
            )}
            <FileUploadBackdrop pendingLoading={pending} progress={progress} />
        </div>
    );
};

HealthSafetyDetail.propTypes = {
    type: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    isLoading: PropTypes.bool.isRequired,
    isValidated: PropTypes.bool,
    jobList: PropTypes.array,
    onJobChange: PropTypes.func,
    onTaskChange: PropTypes.func,
    jobDetail: PropTypes.object,
    jobLoading: PropTypes.bool
};

HealthSafetyDetail.defaultProps = {
    assigningData: [],
    storedAttendees: []
};

export default HealthSafetyDetail;
