import React, { useEffect } from "react";
import styles from "./styles.scss";
import CircularProgress from "@mui/material/CircularProgress";
import { MenuItem, TextField } from "@mui/material";

const BranchSelect = ({ currentBranch, branchList, getBranchList, setCurrentBranch }) => {
    useEffect(() => {
        getBranchList();
    }, [getBranchList]);

    const setBranchArea = event => {
        const {
            target: { value }
        } = event;

        localStorage.setItem("currentBranch", value);
        setCurrentBranch(value);
    };

    return (
        <div className={styles.branchSelect}>
            <TextField select label="Branch Area" fullWidth size="small" value={branchList?.length ? currentBranch : ""} onChange={setBranchArea}>
                {branchList
                    .sort((a, b) => a.ordering - b.ordering)
                    .map(area => (
                        <MenuItem key={area.id} value={String(area.id)}>
                            {area.area_name}
                        </MenuItem>
                    ))}
            </TextField>
            {branchList.length === 0 && (
                <div className={styles.loading}>
                    <CircularProgress size={31} />
                </div>
            )}
        </div>
    );
};

export default BranchSelect;
