import React, { useContext, useEffect, useState, memo, useMemo } from "react";
import { Checkbox, IconButton, Stack, Chip, Typography } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import { MDBBtn, MDBIcon, MDBBtnGroup } from "louis-mdbreact";
import dayjs from "shared/dayjs";
import styles from "shared/details.scss";
import { ModalContext } from "../TaskContext";
import { formatDateToTime, getDistance, timeDiff, uuidv4 } from "shared/Utils";
import { useSelector, useDispatch } from "react-redux";
import { axiosInstance } from "shared/axiosInst";
import { actionCreators as taskActions } from "redux/modules/tasks";
import LoginIcon from "@mui/icons-material/Login";
import LogoutIcon from "@mui/icons-material/Logout";
import LunchDiningIcon from "@mui/icons-material/LunchDining";
import ColoredChip from "./ColoredChip";
import Decisions from "./Decisions";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import RoleDot from "components/modals/RosteringModal/RoleDot";

const EARLY_HOUR = 6;
const LATE_HOUR = 17;
const FORTY_FIVE_MINUTES = 2700000;

const ListItem = ({ list, index, onChange, isTravel = false }) => {
    const [mode, setMode] = useState("light");
    const [longLunch, setLongLunch] = useState(null);
    const [overTime, setOverTime] = useState({ start: false, end: false });
    const theme = useMemo(
        () =>
            createTheme({
                palette: {
                    mode
                }
            }),
        [mode]
    );
    useEffect(() => {
        const startHour = dayjs(list.starting_timestamp).get("h");
        const startMin = dayjs(list.starting_timestamp).get("m");
        const endHour = dayjs(list.ending_timestamp).get("h");
        const endMin = dayjs(list.ending_timestamp).get("m");
        if ((startHour > 0 && startHour < EARLY_HOUR) || (endHour > LATE_HOUR + 1 && endHour < 24)) {
            setMode("dark");
        }
        if (startHour === EARLY_HOUR || (startHour === LATE_HOUR && startMin > 0)) {
            setOverTime(current => ({ ...current, start: true }));
        }
        if (endHour === EARLY_HOUR || (endHour === LATE_HOUR && endMin > 0)) {
            setOverTime(current => ({ ...current, end: true }));
        }
    }, [list.starting_timestamp, list.ending_timestamp]);
    const { setModalData, setRejectData } = useContext(ModalContext);
    const dispatch = useDispatch();
    const [isDecided, setIsDecided] = useState(false);
    const {
        user: { currentCompany, userId, manager },
        tasks: { timecloudAction }
    } = useSelector(state => state);
    const onApprove = id => {
        axiosInstance
            .post(`${currentCompany}maintenance/time_tracking_decisions/approve/`, {
                time_entry: [id]
            })
            .then(value => {
                dispatch(taskActions.getTimeCloudList());
            });
    };
    useEffect(() => {
        if (list.decisions?.length > 0) {
            if (list.decisions.find(decision => decision.decision === 2)) {
                const decisions = list.decisions.find(decision => decision.decision === 2);
                setIsDecided(timecloudAction?.find(action => action.id === decisions.decision));
                return;
            } else {
                const decisions = list.decisions.find(
                    decision => decision.decision_maker === userId || decision.decision_maker === manager?.manager_id
                );
                if (timecloudAction?.length > 0 && decisions) {
                    setIsDecided(timecloudAction?.find(action => action.id === decisions.decision));
                }
            }
        }
    }, [list.decisions, userId, timecloudAction, manager.manager_id]);

    useEffect(() => {
        if (list.mjs_id === 6) {
            const diff = dayjs(list.ending_timestamp).diff(list.starting_timestamp);
            if (diff > FORTY_FIVE_MINUTES) {
                setLongLunch(true);
            } else {
                setLongLunch(false);
            }
        } else {
            setLongLunch(null);
        }
    }, [list.starting_timestamp, list.ending_timestamp, list.mjs_id]);

    const onDetailClick = id => {
        setModalData(null);
        axiosInstance.get(`${currentCompany}maintenance/time_tracking/${id}/`).then(value => {
            setModalData(value.data);
        });
    };
    return (
        <ThemeProvider theme={theme}>
            <tr className={`${styles.addItemContainer} ${mode === "dark" ? styles.night : ""} ${longLunch ? "amber lighten-3" : ""}`}>
                <td data-th="Select">
                    {!isDecided && (
                        <Checkbox
                            id={uuidv4()}
                            checked={list.checked}
                            onChange={e => {
                                onChange(index);
                            }}
                            sx={{ padding: 0 }}
                        />
                    )}
                </td>
                <td data-th="Worker">{list.worker_name}</td>
                <td data-th="Job">
                    {isTravel ? (
                        <Stack direction="column" spacing={0.2}>
                            <span>
                                <Typography variant="button" color="green">
                                    from
                                </Typography>{" "}
                                {list.job_id ? `${list.job_id}-${list.job_region} (${list.job_name})` : "-"}
                            </span>
                            <span>
                                <Typography variant="button" color="green">
                                    to
                                </Typography>{" "}
                                {list.end_job_id ? `${list.end_job_id}-${list.end_job_region} (${list.end_job_name})` : "-"}
                            </span>
                        </Stack>
                    ) : (
                        `${list.job_id}-${list.job_region} (${list.job_name})`
                    )}
                    <Stack direction="row" spacing={1}>
                        {list.start_job_punch_proximity && <ColoredChip icon={<LoginIcon />} distance={list.start_job_punch_proximity} />}
                        {list.end_job_punch_proximity && <ColoredChip icon={<LogoutIcon />} distance={list.end_job_punch_proximity} />}
                    </Stack>
                </td>
                <td data-th="MJS">
                    {`MJS-${list.mjs_id} (${list.mjs_note})`} {longLunch !== null && <LunchDiningIcon color={longLunch ? "error" : "success"} />}
                </td>
                <td data-th="Supervisor">{list.supervisor_name || "-"}</td>
                <td data-th="Date">{list.starting_timestamp ? dayjs(list.starting_timestamp).format("DD/MM/YYYY") : "-"}</td>
                <td data-th={isTravel ? "Job Distance (punch)" : "Time"}>
                    {isTravel ? (
                        `${getDistance(list.inter_job_distance)} (${getDistance(list.inter_punch_distance)})`
                    ) : list.starting_timestamp ? (
                        <div>
                            <Stack direction="row" alignItems="center">
                                <span>{timeDiff(list.starting_timestamp, list.ending_timestamp)}</span>{" "}
                                {list.previous_revision ? <RoleDot color="primary" sx={{ marginLeft: 0.7 }} /> : ""}
                            </Stack>
                            <Stack direction="row" spacing={0.5}>
                                {list.starting_timestamp && (
                                    <Chip
                                        size="small"
                                        color={overTime.start ? "warning" : "default"}
                                        label={formatDateToTime(list.starting_timestamp)}
                                    />
                                )}
                                <span>~</span>
                                {list.ending_timestamp && (
                                    <Chip size="small" color={overTime.end ? "warning" : "default"} label={formatDateToTime(list.ending_timestamp)} />
                                )}
                            </Stack>
                        </div>
                    ) : list.total_hours ? (
                        `${list.total_hours} hrs`
                    ) : (
                        "-"
                    )}
                </td>
                <td data-th={"Action"} className={""}>
                    {isDecided ? (
                        <div>
                            <IconButton
                                color={isDecided.id === 1 ? "success" : "error"}
                                sx={{ width: "inherit !important" }}
                                onClick={_ => {
                                    onDetailClick(list.id);
                                }}
                            >
                                <InfoIcon />
                            </IconButton>{" "}
                            {`${isDecided.name}${isDecided.id === 1 ? "d" : "ed"}`}
                        </div>
                    ) : (
                        <MDBBtnGroup className={styles.buttonGroup}>
                            <MDBBtn
                                className={`${styles.buttonSize}`}
                                size="sm"
                                color="primary"
                                onClick={_ => {
                                    onDetailClick(list.id);
                                }}
                            >
                                <MDBIcon far icon="edit" />
                            </MDBBtn>
                            <MDBBtn
                                onClick={_ => {
                                    onApprove(list.id);
                                }}
                                className={`${styles.buttonSize}`}
                                size="sm"
                                color="secondary"
                            >
                                <MDBIcon icon="check" />
                            </MDBBtn>
                            <MDBBtn className={`${styles.buttonSize}`} size="sm" color="danger" onClick={_ => setRejectData(list)}>
                                <MDBIcon icon="times" />
                            </MDBBtn>
                        </MDBBtnGroup>
                    )}

                    <Decisions decisions={list.decisions} />
                </td>
            </tr>
        </ThemeProvider>
    );
};

export default memo(ListItem);
