import React from "react";
import { MDBDropdown, MDBDropdownToggle, MDBDropdownMenu, MDBDropdownItem, MDBIcon } from "louis-mdbreact";
import { Link } from "react-router-dom";
import styles from "./styles.scss";
import { useSelector } from "react-redux";

const CreateNewButton = () => {
    const { permissions, isResidentialSupervisorTeamLeader, isCommercialSupervisorTeamLeader } = useSelector(state => state.user);
    return (
        <div className={styles.newButton}>
            {permissions.job === 3 ||
            permissions.job === 4 ||
            permissions.operations === 3 ||
            permissions.operations === 4 ||
            permissions.ordering === 3 ||
            permissions.ordering === 4 ||
            permissions.pricing === 4 ||
            permissions.stock === 3 ||
            permissions.stock === 4 ||
            permissions.hr ? (
                <MDBDropdown>
                    <MDBDropdownToggle color="secondary" size="sm" className={styles.dropdownToggle}>
                        <MDBIcon icon="plus" />
                    </MDBDropdownToggle>
                    <MDBDropdownMenu basic color="info">
                        {permissions.job === 4 && !isResidentialSupervisorTeamLeader && !isCommercialSupervisorTeamLeader && (
                            <MDBDropdownItem className={styles.dropDownItem}>
                                <Link className={styles.link} to={`/jobs/new`}>
                                    New Job
                                </Link>
                            </MDBDropdownItem>
                        )}
                        {/* {(permissions.maintenance === 3 || permissions.maintenance === 4) && (
                            <MDBDropdownItem className={styles.dropDownItem}>
                                <Link className={styles.link} to={`/tasks/new`}>
                                    New MJS
                                </Link>
                            </MDBDropdownItem>
                        )} */}
                        {/* {permissions.budget >= 4 && (
                            <MDBDropdownItem>
                                <Link
                                    className={styles.link}
                                    to={`/budget/new`}>
                                    New Budget
                                </Link>
                            </MDBDropdownItem>
                        )} */}
                        {/* {(permissions.budget === 3 || permissions.budget === 4) && (
                            <MDBDropdownItem className={styles.dropDownItem}>
                                <Link className={styles.link} to={`/budget/sheets/new`}>
                                    New Job Sheet
                                </Link>
                            </MDBDropdownItem>
                        )} */}
                        {(permissions.operations === 3 || permissions.operations === 4) && (
                            <>
                                {/* <MDBDropdownItem className={styles.dropDownItem}>
                                    <Link className={styles.link} to={`/operations/diary/new`}>
                                        New Site Diary
                                    </Link>
                                </MDBDropdownItem> */}
                                {/* <MDBDropdownItem className={styles.dropDownItem}>
                                    <Link className={styles.link} to={`/operations/observations/new`}>
                                        New Observation
                                    </Link>
                                </MDBDropdownItem> */}
                                {/* <MDBDropdownItem className={styles.dropDownItem}>
                                    <Link className={styles.link} to={`/operations/preqa/new`}>
                                        New Pre QA
                                    </Link>
                                </MDBDropdownItem> */}
                                {/* <MDBDropdownItem className={styles.dropDownItem}>
                                    <Link className={styles.link} to={`/operations/qa/new`}>
                                        New QA
                                    </Link>
                                </MDBDropdownItem> */}
                                <MDBDropdownItem className={styles.dropDownItem}>
                                    <Link className={styles.link} to={`/operations/health/new`}>
                                        New H&S
                                    </Link>
                                </MDBDropdownItem>
                            </>
                        )}
                        {(permissions.crm === 3 || permissions.crm === 4) && (
                            <MDBDropdownItem className={styles.dropDownItem}>
                                <Link className={styles.link} to={`/crm/entities/new`}>
                                    New Entity
                                </Link>
                            </MDBDropdownItem>
                        )}
                        {/* {(permissions.ordering === 3 || permissions.ordering === 4) && (
                            <MDBDropdownItem className={styles.dropDownItem}>
                                <Link className={styles.link} to={`/material/order/new`}>
                                    New Order
                                </Link>
                            </MDBDropdownItem>
                        )} */}
                        {/* {permissions.pricing === 4 && (
                            <>
                                <MDBDropdownItem className={styles.dropDownItem}>
                                    <Link className={styles.link} to={`/material/price/new`}>
                                        New Price Book
                                    </Link>
                                </MDBDropdownItem>
                                {isAdmin && (
                                    <MDBDropdownItem className={styles.dropDownItem}>
                                        <Link className={styles.link} to={`/material/pricelog/new`}>
                                            New Price Log
                                        </Link>
                                    </MDBDropdownItem>
                                )}
                            </>
                        )} */}
                        <MDBDropdownItem className={styles.dropDownItem}>
                            <Link className={styles.link} to={`/humanresources/leaverequest/new`}>
                                Request Leave
                            </Link>
                        </MDBDropdownItem>
                        {(permissions.stock === 3 || permissions.stock === 4) && (
                            <MDBDropdownItem className={styles.dropDownItem}>
                                <Link className={styles.link} to={`/stock/transferred/new`}>
                                    New Transfer
                                </Link>
                            </MDBDropdownItem>
                        )}
                        {/* {permissions.budget >= 4 && (
                            <MDBDropdownItem>
                                <Link
                                    className={styles.link}
                                    to={`/budget/claims/new`}>
                                    New Claim
                                </Link>
                            </MDBDropdownItem>
                        )} */}
                    </MDBDropdownMenu>
                </MDBDropdown>
            ) : (
                ""
            )}
        </div>
    );
};

export default CreateNewButton;
