import React, { useEffect, useState } from "react";
import styles from "shared/details.scss";
import { ModalContext } from "../TaskContext";
import RejectModal from "../TimeApprove/RejectModal";
import EditModal from "../TimeApprove/EditModal";
import JobView from "./JobView";
import { useDispatch } from "react-redux";
import { actionCreators as taskActions } from "redux/modules/tasks";
import { actionCreators as jobActions } from "redux/modules/jobs";
import { actionCreators as crmActions } from "redux/modules/crm";
import { Helmet } from "react-helmet";

const TimeView = props => {
    const {
        location: { hash }
    } = props;
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(taskActions.getSlimMaintenanceJob(true, true));
        dispatch(jobActions.getSlimJobs(false, true));
        dispatch(crmActions.getSlimEntity("?relationships=2"));
    }, [dispatch]);
    const [rejectData, setRejectData] = useState(null);
    const [modalData, setModalData] = useState(null);
    const [listType, setListType] = useState("");

    useEffect(() => {
        setListType(hash === "#jobview" ? "job" : "worker");
    }, [hash]);

    return (
        <div className={styles.container}>
            <Helmet>
                <title>Tasks | Timesheets</title>
            </Helmet>
            <ModalContext.Provider value={{ listType, rejectData, setRejectData, modalData, setModalData }}>
                <JobView listType={listType} />
                {modalData && (
                    <EditModal
                        open={!!modalData}
                        toggle={() => {
                            setModalData(null);
                        }}
                    />
                )}
                {rejectData && (
                    <RejectModal
                        open={!!rejectData}
                        toggle={() => {
                            setRejectData(null);
                        }}
                    />
                )}
            </ModalContext.Provider>
        </div>
    );
};

export default TimeView;
