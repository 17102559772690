import { Stack } from "@mui/material";
import React from "react";
import NoFoodIcon from "@mui/icons-material/NoFood";

const NoLunchArea = lunchableEntry => {
    return (
        <Stack direction="row" alignItems="center" justifyContent="space-between" marginBottom={1}>
            <NoFoodIcon color="error" />
            {/* <Button variant="contained" size="small">
                Add lunch
            </Button> */}
        </Stack>
    );
};

export default NoLunchArea;
