import React from "react";
import { MDBBtn, MDBIcon } from "louis-mdbreact";
import styles from "shared/details.scss";

const AddItem = props => (
    <tr className={styles.addItemContainer}>
        <td data-th="File">{props.file.name}</td>
        <td className={styles.textCentre} data-th="Action">
            <MDBBtn
                className={styles.buttonSize}
                color="danger"
                size="sm"
                onClick={() => props.removeItem(props.id)}
            >
                <MDBIcon icon="trash-alt" />
            </MDBBtn>
        </td>
    </tr>
);

const FileItem = props => (
    <tr className={styles.addItemContainer}>
        <td data-th="File">{props.original_file_name}</td>
        <td className={styles.textCentre} data-th="Action">
            <MDBBtn
                className={styles.buttonSize}
                color="success"
                size="sm"
                name="success"
                value={props.id}
                onClick={props.downloadImage}
            >
                <MDBIcon icon="file-download" />
            </MDBBtn>
        </td>
    </tr>
);

export { AddItem, FileItem };
