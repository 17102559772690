import React, { useState, useEffect } from "react";
import styles from "shared/details.scss";
import ArrowBackButton from "components/ArrowBackButton";
import { useDispatch } from "react-redux";
import { actionCreators as stockActions } from "redux/modules/stock";
import Loading from "components/Loading";
import { MDBRow, MDBCol, MDBTable, MDBTableBody, MDBTableHead, MDBBtn } from "louis-mdbreact";
import dayjs from "shared/dayjs";
import ReportTableRow from "./ReportTableRow";
import history from "shared/history";
import { axiosInstance } from "shared/axiosInst";
import { Helmet } from "react-helmet";

let signal = axiosInstance.CancelToken.source();

const ReportView = props => {
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(true);
    const [pending, setPending] = useState(false);
    const [reportDetail, setReportDetail] = useState({});
    const {
        match: {
            params: { id }
        }
    } = props;
    useEffect(() => {
        dispatch(stockActions.getReportDetail(id)).then(value => {
            setReportDetail(value);
            setIsLoading(false);
        });
        return () => {
            signal.cancel("cancelled by user.");
            signal = axiosInstance.CancelToken.source();
            setPending(false);
        };
    }, [dispatch, id]);

    const downloadStockReport = event => {
        setPending(true);
        dispatch(stockActions.downloadReport(id, signal)).then(value => {
            if (value.message === "complete") {
                setPending(false);
            }
        });
    };

    const returnToList = () => {
        history.goBack();
    };

    return (
        <div className={styles.container}>
            <Helmet>
                <title>Stock | Report Details</title>
            </Helmet>
            <h2 className={`${styles.title} align-middle`}>
                <ArrowBackButton />
                REPORT DETAILS
            </h2>
            {isLoading ? (
                <Loading loadingType="detail" />
            ) : (
                <>
                    <MDBTable small borderless striped>
                        <MDBTableBody>
                            <tr>
                                <th width="30%" className="orange-text">
                                    Report ID
                                </th>
                                <td width="70%">{reportDetail.id}</td>
                            </tr>
                            <tr>
                                <th className="orange-text">Generated Date</th>
                                <td>{dayjs(reportDetail.created_at).format("DD/MM/YYYY")}</td>
                            </tr>
                            <tr>
                                <th className="orange-text">Created by</th>
                                <td>{reportDetail.created_by_string}</td>
                            </tr>
                        </MDBTableBody>
                    </MDBTable>
                    <h4 className="mt-4" style={{ fontSize: "1rem" }}>
                        Priced Transfer
                    </h4>
                    <MDBTable className={`${styles.detailResponsiveTable}`} small>
                        <MDBTableHead>
                            <tr className="light-green lighten-3">
                                <th width="20%">Jobs</th>
                                <th width="40%">Description</th>
                                <th>Quantity</th>
                                <th>Price</th>
                                <th>Created Date</th>
                                <th width="50">Details</th>
                            </tr>
                        </MDBTableHead>
                        <MDBTableBody>
                            {reportDetail.priced_movement_records.map(priced => (
                                <ReportTableRow key={priced.stock_movement} priced={priced} />
                            ))}
                        </MDBTableBody>
                    </MDBTable>
                    <MDBRow>
                        <MDBCol>
                            <div className={`${styles.card} float-right`}>
                                <MDBBtn color="blue-grey" onClick={returnToList}>
                                    List
                                </MDBBtn>{" "}
                                <MDBBtn type="button" color="primary" disabled={pending} onClick={downloadStockReport}>
                                    {pending ? (
                                        <>
                                            wait{" "}
                                            <div className="spinner-border spinner-border-sm text-white" role="status">
                                                <span className="sr-only">Loading...</span>
                                            </div>
                                        </>
                                    ) : (
                                        "download"
                                    )}
                                </MDBBtn>
                            </div>
                        </MDBCol>
                    </MDBRow>
                </>
            )}
        </div>
    );
};

export default ReportView;
