import React, { useEffect, useState } from "react";
import { Divider, Grid, Typography, Stack } from "@mui/material";
import { Label } from "components/HumanResources/CustomComponent";
import dayjs from "shared/dayjs";
import styles from "shared/details.scss";
import { formatDateToTime, getDistance, timeDiff } from "shared/Utils";
import ArrowBackButton from "components/ArrowBackButton";
import ColoredChip from "./ColoredChip";
import LoginIcon from "@mui/icons-material/Login";
import LogoutIcon from "@mui/icons-material/Logout";
import Decisions from "./Decisions";
import LunchDiningIcon from "@mui/icons-material/LunchDining";

const FORTY_FIVE_MINUTES = 2700000;

const TimecloudDetail = props => {
    const { modalData, toggle, isDone } = props;
    const [isLongLunch, setIsLongLunch] = useState(false);
    useEffect(() => {
        const diff = dayjs(modalData.ending_timestamp).diff(modalData.starting_timestamp);
        setIsLongLunch(diff > FORTY_FIVE_MINUTES);
    }, [modalData.starting_timestamp, modalData.ending_timestamp]);
    return (
        <Grid item xs={12} sm={12} md={12}>
            <h2 className={`${styles.title} align-middle`}>
                <ArrowBackButton isSmall onClick={toggle} />
                <span>Details ({modalData.worker_name})</span>
                <div style={{ fontSize: "1rem" }}>
                    <Decisions decisions={modalData.decisions} />
                </div>
            </h2>
            {isDone ? (
                <Typography variant="subtitle1" component="p" textAlign="center">
                    Please select Time Entry you want to modify.
                </Typography>
            ) : (
                <>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={4}>
                            <Label sx={{ marginBottom: 0 }}>Job</Label>
                            {getJobItem(
                                { id: modalData.starting_job, text: modalData.starting_job_name, distance: modalData.start_job_punch_proximity },
                                { id: modalData.ending_job, text: modalData.ending_job_name, distance: modalData.end_job_punch_proximity },
                                false
                            )}
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} sx={{ bgcolor: modalData.starting_mjs === 6 && isLongLunch ? "#ffe082" : "" }}>
                            <Label sx={{ marginBottom: 0 }}>MJS</Label>{" "}
                            {modalData.starting_mjs === 6 && <LunchDiningIcon color={isLongLunch ? "error" : "success"} fontSize="small" />}
                            {getJobItem(
                                { id: modalData.starting_mjs, text: modalData.starting_mjs_note },
                                { id: modalData.ending_mjs, text: modalData.ending_mjs_note },
                                true
                            )}
                        </Grid>
                        <Grid item xs={12} sm={12} md={4}>
                            <Label sx={{ marginBottom: 0 }}>Supervisor</Label>
                            <Typography variant="body2">{modalData.supervisor_name || "-"}</Typography>
                        </Grid>
                        <Grid item xs={12} sm={4} md={4}>
                            <Label sx={{ marginBottom: 0 }}>Date</Label>
                            <Typography variant="body2">
                                {modalData.starting_timestamp || modalData.ending_timestamp
                                    ? dayjs(modalData.starting_timestamp || modalData.ending_timestamp).format("DD/MM/YYYY")
                                    : "-"}
                            </Typography>
                        </Grid>
                        {modalData.starting_mjs === 302 ? (
                            <>
                                <Grid item xs={6} sm={4} md={4}>
                                    <Label sx={{ marginBottom: 0 }}>Job Distance</Label>
                                    <Typography variant="body2">{getDistance(modalData.inter_job_distance)}</Typography>
                                </Grid>
                                <Grid item xs={6} sm={4} md={4}>
                                    <Label sx={{ marginBottom: 0 }}>Punch Distance</Label>
                                    <Typography variant="body2">{getDistance(modalData.inter_punch_distance)}</Typography>
                                </Grid>
                            </>
                        ) : (
                            <>
                                <Grid item xs={6} sm={4} md={4}>
                                    <Label sx={{ marginBottom: 0 }}>Time</Label>
                                    <Typography variant="body2">
                                        {modalData.starting_timestamp
                                            ? `${formatDateToTime(modalData.starting_timestamp)} ~ ${formatDateToTime(modalData.ending_timestamp)}`
                                            : "-"}
                                    </Typography>
                                </Grid>
                                <Grid item xs={6} sm={4} md={4}>
                                    <Label sx={{ marginBottom: 0 }}>Total Time</Label>
                                    <Typography variant="body2">{timeDiff(modalData.starting_timestamp, modalData.ending_timestamp)}</Typography>
                                </Grid>
                            </>
                        )}
                    </Grid>
                    <Divider sx={{ marginTop: "16px" }} />
                </>
            )}
        </Grid>
    );
};

const getJobItem = (start, end, isMJS) => {
    return start.id === end.id ? (
        <Stack direction={{ xs: "column", sm: "row", md: "column", lg: "column", xl: "row" }} spacing={1}>
            <Typography variant="body2" component="span">
                {start.id ? `${isMJS ? "MJS-" : ""}${start.id} (${start.text})` : "-"}
            </Typography>
            <Stack direction="row" spacing={1}>
                {start.distance && <ColoredChip icon={<LoginIcon />} distance={start.distance} />}
                {end.distance && <ColoredChip icon={<LogoutIcon />} distance={end.distance} />}
            </Stack>
        </Stack>
    ) : (
        <>
            <div>
                <Typography variant="button" component="span">
                    start :{" "}
                </Typography>
                <Typography variant="body2" component="span">
                    {start.id ? `${isMJS ? "MJS-" : ""}${start.id} (${start.text})` : "-"}{" "}
                    {start.distance && <ColoredChip icon={<LoginIcon />} distance={start.distance} />}
                </Typography>
            </div>
            <div>
                <Typography variant="button" component="span">
                    end :{" "}
                </Typography>
                <Typography variant="body2" component="span">
                    {end.id ? `${isMJS ? "MJS-" : ""}${end.id} (${end.text})` : "-"}{" "}
                    {end.distance && <ColoredChip icon={<LogoutIcon />} distance={end.distance} />}
                </Typography>
            </div>
        </>
    );
};

export default TimecloudDetail;
