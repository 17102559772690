import React, { Component } from "react";
import styles from "shared/modalStyles.scss";
import { SketchField, Tools } from "react-sketch-louis";
import { dataURLtoFile } from "shared/Utils";
import { axiosInstance } from "shared/axiosInst";
import DialogContainer from "components/DialogContainer";
import { Button, DialogActions, DialogContent } from "@mui/material";
import EmptyBackground from "images/empty_background.png";

class Container extends Component {
    constructor(props) {
        super(props);
        this.state = {
            canUndo: false,
            attendee_name: "",
            imageURL: "",
            isImageLoading: false,
            isSetBackground: false
        };
        this.sketch = {};
    }

    componentDidMount() {
        window.addEventListener("resize", this.onResize);
        window.addEventListener("orientationchange", this.onResize, false);
    }

    componentDidUpdate(prevProps, prevState) {
        const { modal, selectedAttendee, currentCompany } = this.props;

        if (prevProps.modal !== modal && !modal) {
            this.setState({
                isSetBackground: false
            });
        }

        if (this.sketch && this.sketch.setBackgroundFromDataUrl && !this.state.isSetBackground && modal) {
            this.sketch.setBackgroundFromDataUrl(EmptyBackground, {
                originX: "left",
                originY: "top"
            });

            this.setState({
                isSetBackground: true
            });
        }

        if (selectedAttendee !== prevProps.selectedAttendee && modal) {
            if (typeof selectedAttendee.signature === "object") {
                this.setState(
                    {
                        isImageLoading: true,
                        imageURL: ""
                    },
                    () => {
                        const reader = new FileReader();
                        reader.addEventListener("load", e => {
                            this.setState({
                                imageURL: e.target.result,
                                isImageLoading: false
                            });
                        });
                        reader.readAsDataURL(selectedAttendee.signature);
                    }
                );
            } else if (typeof selectedAttendee.signature === "number") {
                this.setState(
                    {
                        imageURL: "",
                        isImageLoading: true
                    },
                    () => {
                        axiosInstance
                            .get(`${currentCompany}operations/stored_file/get_public_download/${selectedAttendee.signature}/`)
                            .then(response => {
                                this.setState({
                                    imageURL: response.data.download_link,
                                    isImageLoading: false
                                });
                            });
                    }
                );
            } else {
                this.setState({
                    imageURL: "",
                    isImageLoading: false
                });
            }
            this.setState({
                attendee_name: selectedAttendee.attendee_name
            });
        }
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.onResize);
        window.removeEventListener("orientationchange", this.onResize, false);
    }

    sketchChange = () => {
        const prev = this.state.canUndo;
        const now = this.sketch.canUndo();
        if (prev !== now) {
            this.setState({ canUndo: now });
        }
    };

    saveAttendeeInfo = event => {
        const { saveAttendee, selectedAttendee } = this.props;
        const { canUndo, attendee_name } = this.state;
        event.preventDefault();
        if (!selectedAttendee.label && !attendee_name) {
            alert("Name is required.");
            return false;
        }

        if (!canUndo) {
            alert("Signature is required.");
            return false;
        }

        this.setState({
            attendee_name: ""
        });
        const timestamp = selectedAttendee.timestamp || Number(new Date());
        saveAttendee({
            ...selectedAttendee,
            attendee_name,
            signature: dataURLtoFile(this.sketch.toDataURL(), `${selectedAttendee.label || attendee_name}_${timestamp}.png`, true),
            signature_url: this.sketch.toDataURL(),
            timestamp
        });
    };

    onResize = event => {
        const modalBody = document.querySelector(".MuiDialogContent-root");
        const modalWidth = modalBody ? modalBody.clientWidth : 0;
        this.setState({
            canvasHeight: (modalWidth - 32) * 0.5
        });
    };

    onNameChange = event => {
        const {
            target: { value }
        } = event;
        this.setState({ attendee_name: value });
    };

    render() {
        const { modal, toggle, selectedAttendee } = this.props;
        const { attendee_name, imageURL, canvasHeight, isImageLoading } = this.state;
        return (
            <DialogContainer
                open={modal}
                handleClose={toggle}
                maxWidth="md"
                fullWidth={true}
                fullScreen={false}
                title="Attendee Signature"
                isLoading={isImageLoading}
                TransitionProps={{
                    onEntered: () => {
                        this.onResize();
                    }
                }}
            >
                <DialogContent>
                    <label htmlFor="attendee_name">Name</label>
                    <input
                        type="text"
                        className="form-control form-control-sm mb-2"
                        value={selectedAttendee.value ? undefined : attendee_name}
                        name="attendee_name"
                        id="attendee_name"
                        onChange={this.onNameChange}
                        required
                        defaultValue={selectedAttendee.value ? selectedAttendee.label : undefined}
                        disabled={selectedAttendee.value || selectedAttendee.signature}
                    />
                    <label>Signature{selectedAttendee.signature ? " (signed)" : ""}</label>
                    {selectedAttendee.signature ? (
                        <img
                            width="100%"
                            className={styles.formControlStyle}
                            src={imageURL}
                            alt={`${selectedAttendee.label || selectedAttendee.attendee_name} signature`}
                        />
                    ) : (
                        <SketchField
                            name="sketch"
                            className={`canvas-area ${styles.formControlStyle}`}
                            ref={c => (this.sketch = c)}
                            onChange={this.sketchChange}
                            width="100%"
                            height={canvasHeight}
                            tool={Tools.Pencil}
                            lineWidth={7}
                        />
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={toggle}>{selectedAttendee.signature ? "Close" : "Cancel"}</Button>
                    {!selectedAttendee.signature && (
                        <Button color="primary" type="button" onClick={this.saveAttendeeInfo}>
                            Save
                        </Button>
                    )}
                </DialogActions>
            </DialogContainer>
        );
    }
}

Container.defaultProps = {
    selectedAttendee: {}
};

export default Container;
