import React from "react";
import Card from "@mui/material/Card";
import { makeStyles } from "@mui/styles";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";

const useStyles = makeStyles({
    root: { backgroundColor: "#e9ebef" },
    title: { fontSize: "1.2rem", fontWeight: "300" }
});

const CardContainer = ({ children, title }) => {
    const classes = useStyles();
    return (
        <Card>
            <CardHeader title={title} classes={classes} />
            <CardContent>{children}</CardContent>
        </Card>
    );
};

export default CardContainer;
