import React, { useEffect, useState } from "react";
import styles from "shared/listStyles.scss";
import detailStyles from "shared/details.scss";
import { useSelector, useDispatch } from "react-redux";
import CreateNewButton from "components/CreateNewButton";
import { MDBCol, MDBRow, MDBCard, MDBCardHeader, MDBCardBody } from "louis-mdbreact";
import { useForm, Controller } from "react-hook-form";
import SubmitButton from "components/SubmitButton";
import WindowedSelect from "react-windowed-select";
import { actionCreators as jobActions } from "redux/modules/jobs";
import { Helmet } from "react-helmet";

const PLReport = props => {
    const {
        user: { companies },
        jobs: { jobList }
    } = useSelector(state => state);

    const dispatch = useDispatch();
    const [isPending, setIsPending] = useState(false);
    const initCurrentCompany = localStorage.getItem("currentCompany").split("/")[0];
    const [currentPrefix, setCurrentPrefix] = useState(localStorage.getItem("companyPrefix"));
    const [inputValue, setInputValue] = useState("");
    const companyList = JSON.parse(companies)
        .map(company => {
            if (company.company_string) {
                const value = company.company_string.split(" ")[1].toLowerCase();
                return {
                    id: company.id,
                    label: company.region_string,
                    value: value === "company-tmpl" ? "" : value,
                    prefix: company.company_prefix,
                    brand: company.brand
                };
            } else {
                return {};
            }
        })
        .filter(company => company.brand === 2);

    const defaultValues = {
        company: initCurrentCompany,
        format: "pdf",
        job: []
    };

    const {
        register,
        handleSubmit,
        control,
        watch,
        setValue,
        reset,
        getValues,
        formState: { errors }
    } = useForm({ defaultValues });

    const currentCompany = watch("company");

    useEffect(() => {
        dispatch(jobActions.getSlimJobs(false, true, "", `${currentCompany}/`));
        setValue("job", []);
    }, [dispatch, currentCompany, setValue]);

    useEffect(() => {
        setCurrentPrefix(companyList.find(com => currentCompany === com.value).prefix);
    }, [companyList, currentCompany]);

    const formats = [
        { value: "pdf", name: "PDF" },
        { value: "xlsx", name: "Excel" }
    ];

    const onSubmit = data => {
        setIsPending(true);
        dispatch(jobActions.downloadPLReport({ format: data.format, job: data.job.map(j => Number(j.id)) }, `${currentCompany}/`)).then(value => {
            if (String(value).search(/timeout/) > 0) {
                alert(
                    "No response was received from the server before timeout, if you requested a large number of jobs please try again with fewer otherwise please contact the IT Team."
                );
            }

            if (value?.message === "completed") {
                reset(defaultValues);
            }
            setIsPending(false);
        });
    };

    const createOption = label => {
        if (typeof label === "object") {
            return label;
        } else {
            return { id: label };
        }
    };

    const handleInputChange = inputValue => {
        setInputValue(inputValue);
    };

    const handleBlur = event => {
        const {
            target: { value: lastValue }
        } = event;
        const jobs = getValues("job") || [];
        if (lastValue) {
            setValue("job", [...jobs, createOption(lastValue)]);
        }
    };
    const handleKeyDown = event => {
        const existedJob = jobList.filter(
            list => String(list.id + list.job_name.toLowerCase() + list.resource_string.toLowerCase()).search(inputValue.toLowerCase()) >= 0
        );
        if (existedJob.length === 0) {
            const jobs = getValues("job") || [];
            switch (event.key) {
                case "Enter":
                case "Tab":
                    setValue("job", [...jobs, createOption(inputValue)]);
                    setInputValue("");
                    event.preventDefault();
                    break;
                default:
                    return;
            }
        } else {
            return;
        }
    };

    return (
        <div className={styles.container}>
            <Helmet>
                <title>Jobs | P&L Report</title>
            </Helmet>
            <div className={styles.titleWrap}>
                <h2 className={`${styles.taskTitle}`}>P&L Reports</h2>
                <CreateNewButton />
            </div>
            <MDBRow>
                <MDBCol>
                    <form onSubmit={handleSubmit(onSubmit)} className={props.isValid ? "was-validated" : undefined} noValidate>
                        <MDBCard>
                            <MDBCardHeader>Report Detail</MDBCardHeader>
                            <MDBCardBody>
                                <MDBRow>
                                    <MDBCol sm="6">
                                        <label htmlFor="company">Company</label>
                                        <select className="custom-select" {...register("company")} defaultValue="">
                                            {companyList.map(com => (
                                                <option key={com.id} value={com.value} prefix={com.prefix}>
                                                    {com.label}
                                                </option>
                                            ))}
                                        </select>
                                    </MDBCol>
                                    <MDBCol sm="6">
                                        <label htmlFor="format">Format</label>
                                        <select id="format" className="custom-select" {...register("format", { required: true })} defaultValue="">
                                            <option value="" disabled>
                                                Select report format...
                                            </option>
                                            {formats.map(type => (
                                                <option key={type.value} value={type.value}>
                                                    {type.name}
                                                </option>
                                            ))}
                                        </select>
                                        {errors?.format?.type === "required" && <div className="invalid-feedback d-block">Required</div>}
                                    </MDBCol>
                                </MDBRow>
                            </MDBCardBody>
                        </MDBCard>
                        <MDBCard className={detailStyles.card}>
                            <MDBCardHeader>Jobs</MDBCardHeader>
                            <MDBCardBody>
                                <Controller
                                    name="job"
                                    control={control}
                                    render={({ field }) => (
                                        <WindowedSelect
                                            options={jobList}
                                            inputValue={inputValue}
                                            inputId="plreport"
                                            isClearable
                                            isMulti
                                            onInputChange={handleInputChange}
                                            onKeyDown={handleKeyDown}
                                            placeholder="Select or add new ..."
                                            getOptionLabel={option =>
                                                `${currentPrefix}${option.id}${option.job_name ? ` - ${option.job_name}` : ""} ${
                                                    option.resource_string ? `(${option.resource_string})` : ""
                                                }`
                                            }
                                            getOptionValue={option => option.id}
                                            {...field}
                                            onBlur={handleBlur}
                                        />
                                    )}
                                />
                                <span className="figure-caption">
                                    Please select job number or if the number is not on the list press tab or enter to add new after typing number.
                                </span>
                            </MDBCardBody>
                        </MDBCard>
                        <MDBRow>
                            <MDBCol>
                                <div className={`${detailStyles.card} float-right`}>
                                    <SubmitButton type="new" pendingLoading={isPending} submitText="Download" />
                                </div>
                            </MDBCol>
                        </MDBRow>
                    </form>
                </MDBCol>
            </MDBRow>
        </div>
    );
};

export default PLReport;
