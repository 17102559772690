import { Container, Sprite, TextStyle, Text, Graphics } from "pixi.js";
import { Linear, gsap, Elastic, Back } from "gsap";

import Boat from "images/boat.png";
import Dwayne from "images/staff/dwayne.png";
import CurrentWave from "images/current.png";
import Banana from "images/banana_long.png";
import Rope from "images/rope.png";
import BananaCurrent from "images/banana_current.png";
import Body01 from "images/body_01.png";
import Body02 from "images/body_02.png";
import Body03 from "images/body_03.png";
import Body04 from "images/body_04.png";

import PeopleList from "./PeopleList";

const TALE_OF_CURRENT = 50;
const SPEED = 2;
const BANANA_WIDTH = 418;
const BOAT_WIDTH = 455;
const PEOPLE_PER_BANANA = 4;
const WAVING_DURATION = 0.5;
const MONTHS = ["APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OCT", "NOV", "DEC", "JAN", "FEB", "MAR"];
const TEXT_STYLE = new TextStyle({
    fontFamily: "Roboto",
    fontSize: 17,
    fontStyle: "italic",
    fontWeight: "bold",
    fill: "#FFFFFF",
    wordWrap: true,
    lineJoin: "round",
    padding: 10,
    align: "center"
});
const INIT_BOAT_POSITION_Y = 300;

const staffPosition = [
    { anchorX: 1, anchorY: 0.7, x: 285, y: -21, faceX: -36, faceY: -40, bodySprite: Body01 },
    { anchorX: 1, anchorY: 0.5, x: 226, y: -38, faceX: -40, faceY: -20, bodySprite: Body02 },
    { anchorX: 1, anchorY: 0.5, x: 174, y: -35, faceX: -45, faceY: -23, bodySprite: Body03 },
    { anchorX: 1, anchorY: 0.7, x: 105, y: -19, faceX: -36, faceY: -40, bodySprite: Body04 }
];

let nowLoad = true;
let oldProgressMonths = 0;

class Boats {
    constructor(bhag) {
        this.bhag = bhag;
        this.container = new Container();
        this.mask = new Graphics();
        this.mask.beginFill(0xffffff);
        this.mask.drawRect(0, -400, window.innerWidth, 450);
        this.mask.endFill();
        this.container.addChild(this.mask);
        this.mask.name = "mask";

        this.tweens = [];
        this.createHead();
        const currentBHAG = bhag[0];
        this.createBoats(currentBHAG.progress_months);
    }

    createHead = () => {
        this.boatContainer = new Container();
        const BoatSprite = new Sprite.from(Boat);
        const DwayneSprite = new Sprite.from(Dwayne);
        const CurrentSprite = new Sprite.from(CurrentWave);
        this.container.addChild(this.boatContainer);
        this.boatContainer.mask = this.mask;
        this.boatContainer.addChild(BoatSprite);
        this.boatContainer.addChild(DwayneSprite);
        this.boatContainer.addChild(CurrentSprite);
        BoatSprite.anchor.set(0, 1);
        BoatSprite.y = 33;
        DwayneSprite.anchor.set(0.5, 1);
        DwayneSprite.rotation = -0.1;
        DwayneSprite.x = 240;
        DwayneSprite.y = -87;
        CurrentSprite.x = -TALE_OF_CURRENT;
        CurrentSprite.y = -16;

        gsap.to(BoatSprite, {
            rotation: -0.02,
            duration: 0.6,
            repeatRefresh: true,
            repeat: -1,
            yoyo: true
        });

        gsap.to(DwayneSprite, {
            rotation: 0.1,
            duration: 0.6,
            repeatRefresh: true,
            repeat: -1,
            yoyo: true,
            ease: Linear.easeNone
        });

        gsap.to(CurrentSprite, {
            x: -45,
            duration: 0.1,
            repeatRefresh: true,
            repeat: -1,
            yoyo: true
        });

        this.tweens.push(BoatSprite);
        this.tweens.push(DwayneSprite);
        this.tweens.push(CurrentSprite);
    };

    createBoats = progressMonths => {
        this.bananas = new Container();
        this.boatContainer.addChild(this.bananas);
        const bananaLength = Math.ceil(Number(progressMonths));
        for (let i = 0; i < bananaLength; i++) {
            // const dividedValue = Math.round(((progressMonths - i) * 10) / 3);
            const dividedValue = Math.ceil((progressMonths - i) * PEOPLE_PER_BANANA);
            const banana = this.addNewBanana({
                name: i >= MONTHS.length ? "YAY!" : MONTHS[i],
                peopleNumber: dividedValue >= PEOPLE_PER_BANANA ? PEOPLE_PER_BANANA : dividedValue < 0 ? 0 : dividedValue,
                bananaNum: i,
                isFirst: true
            });
            banana.x = -(BANANA_WIDTH - 14) * i - BANANA_WIDTH;
            this.bananas.addChild(banana);
            // this.bananas.children.sort((a, b) => a.x - b.x);
            // this.bananas.y = -4;
        }
    };

    addNewBanana = progress => {
        const bananaContainer = new Container();
        const BananaSprite = new Sprite.from(Banana);
        const ropeSprite = new Sprite.from(Rope);
        const currentSprite = new Sprite.from(BananaCurrent);
        BananaSprite.anchor.set(0, 0.5);
        BananaSprite.x = -40;
        BananaSprite.y = -27;
        bananaContainer.addChild(BananaSprite);
        bananaContainer.x = -BANANA_WIDTH;
        // bananaContainer.y = 212;

        bananaContainer.addChild(currentSprite);
        currentSprite.anchor.set(1, 0.5);
        currentSprite.x = 265;
        currentSprite.y = 20;

        bananaContainer.addChild(ropeSprite);
        ropeSprite.anchor.set(1, 0);
        ropeSprite.x = BANANA_WIDTH + 4;
        ropeSprite.y = -2;
        this.boatContainer.addChild(bananaContainer);
        const name = new Text(progress.name, TEXT_STYLE);
        bananaContainer.addChild(name);
        name.rotation = -0.2;
        name.x = -34;
        name.y = -72;
        for (let i = 0; i < progress.peopleNumber; i++) {
            const staff = this.addNewStaff({
                currentNum: progress.bananaNum * PEOPLE_PER_BANANA + i,
                iterator: i,
                isFirst: progress.isFirst,
                oldBananaLength: progress.oldBananaLength,
                PeopleCount: progress.PeopleCount
            });
            bananaContainer.addChild(staff);
        }

        gsap.to(currentSprite, {
            x: 260,
            duration: 0.1,
            repeatRefresh: true,
            repeat: -1,
            yoyo: true
        });

        gsap.to(currentSprite.scale, {
            x: 1.1,
            duration: WAVING_DURATION,
            repeatRefresh: true,
            repeat: -1,
            yoyo: true
        });

        gsap.to(ropeSprite, {
            rotation: 0.1,
            duration: WAVING_DURATION,
            repeatRefresh: true,
            repeat: -1,
            yoyo: true
        });
        gsap.to(BananaSprite, {
            rotation: -0.03,
            duration: WAVING_DURATION,
            repeatRefresh: true,
            repeat: -1,
            yoyo: true
        });

        this.tweens.push(currentSprite);
        this.tweens.push(currentSprite.scale);
        this.tweens.push(ropeSprite);
        this.tweens.push(BananaSprite);

        return bananaContainer;
    };

    addNewStaff = process => {
        const { currentNum, iterator, isFirst, oldBananaLength, PeopleCount } = process;
        const { anchorX, anchorY, x, y, faceX, faceY, bodySprite: staffBody } = staffPosition[iterator];
        const staff = new Container();
        const list = PeopleList();
        const face = new Sprite.from(require(`images/staff/${list[currentNum].img}`));
        const bodySprite = new Sprite.from(staffBody);
        bodySprite.anchor.set(anchorX, anchorY);
        staff.addChild(bodySprite);
        staff.x = x;
        staff.y = y;

        staff.addChild(face);
        staff.name = "staff";
        face.width = 150;
        face.height = 157;
        face.anchor.set(0.5, 0.5);
        face.x = faceX;
        face.y = faceY;

        gsap.fromTo(
            face,
            {
                rotation: -0.1,
                duration: WAVING_DURATION,
                repeatRefresh: true,
                repeat: -1,
                yoyo: true
            },
            {
                rotation: 0.1,
                duration: WAVING_DURATION,
                repeatRefresh: true,
                repeat: -1,
                yoyo: true
            }
        );

        gsap.to(staff, {
            rotation: 0.1,
            duration: WAVING_DURATION,
            repeatRefresh: true,
            repeat: -1,
            yoyo: true
        });

        if (!isFirst) {
            gsap.fromTo(
                staff.scale,
                { x: 0, y: 0 },
                {
                    duration: 1,
                    x: 1,
                    y: 1,
                    delay: (currentNum - oldBananaLength * PEOPLE_PER_BANANA + PeopleCount) * 0.5,
                    ease: Elastic.easeOut
                }
            );

            this.tweens.push(staff.scale);
        }

        return staff;
    };

    changeBanana = BHAG => {
        let PeopleCount = 0;
        const { children } = this.bananas;
        const { progress_months } = BHAG[0];
        const oldBananaLength = children.length;
        const bananaLength = Math.ceil(Number(progress_months));
        if (Number(progress_months) > Number(oldProgressMonths)) {
            for (let i = 0; i < bananaLength; i++) {
                const dividedValue = Math.ceil((progress_months - i) * PEOPLE_PER_BANANA);
                const peopleNumber = dividedValue >= PEOPLE_PER_BANANA ? PEOPLE_PER_BANANA : dividedValue < 0 ? 0 : dividedValue;
                if (i < oldBananaLength) {
                    const moving = children[i];
                    const currentStaff = moving.children.filter(ch => ch.name === "staff");
                    for (let j = currentStaff.length; j < peopleNumber; j++) {
                        const staff = this.addNewStaff({
                            currentNum: i * PEOPLE_PER_BANANA + j,
                            iterator: j,
                            isFirst: true
                        });
                        moving.addChild(staff);
                        gsap.fromTo(
                            staff.scale,
                            { x: 0, y: 0 },
                            {
                                x: 1,
                                y: 1,
                                duration: 1,
                                delay: PeopleCount * 0.5,
                                ease: Elastic.easeOut
                            }
                        );
                        this.tweens.push(staff.scale);
                        PeopleCount++;
                    }
                } else {
                    const banana = this.addNewBanana({
                        name: i >= MONTHS.length ? "YAY!" : MONTHS[i],
                        peopleNumber: dividedValue >= PEOPLE_PER_BANANA ? PEOPLE_PER_BANANA : dividedValue < 0 ? 0 : dividedValue,
                        bananaNum: i,
                        isFirst: false,
                        oldBananaLength,
                        PeopleCount
                    });
                    gsap.fromTo(
                        banana,
                        { y: INIT_BOAT_POSITION_Y, rotation: -0.5 },
                        {
                            y: 0,
                            rotation: 0,
                            duration: 1.5,
                            delay: i - oldBananaLength,
                            ease: Back.easeOut
                        }
                    );
                    banana.x = -(BANANA_WIDTH - 14) * i - BANANA_WIDTH;
                    this.tweens.push(banana);

                    this.bananas.addChild(banana);
                }
                // this.bananas.children.sort((a, b) => a.x - b.x);
            }
        } else if (Number(progress_months) < Number(oldProgressMonths)) {
            for (let i = 0; i < oldBananaLength; i++) {
                if (i >= bananaLength) {
                    const removedChild = this.bananas.getChildAt(i);
                    gsap.to(removedChild, {
                        y: INIT_BOAT_POSITION_Y,
                        rotation: -0.5,
                        duration: 0.7,
                        delay: (oldBananaLength - i) * 0.5,
                        onComplete: () => {
                            this.bananas.removeChild(removedChild);
                        }
                    });
                } else {
                    const dividedValue = Math.ceil((progress_months - i) * PEOPLE_PER_BANANA);
                    const peopleNumber = dividedValue >= PEOPLE_PER_BANANA ? PEOPLE_PER_BANANA : dividedValue < 0 ? 0 : dividedValue;
                    const moving = children[i];
                    const currentStaff = moving.children.filter(ch => ch.name === "staff");
                    for (let j = currentStaff.length; j > 0; j--) {
                        if (peopleNumber < j) {
                            moving.removeChildAt(j + 3);
                        }
                    }
                }
            }
        }

        oldProgressMonths = progress_months;
    };

    resize = (width, height) => {
        this.width = width;
        this.mask.width = width;
    };

    tick = () => {
        this.boatContainer.x += SPEED;
        if (this.boatContainer.position.x > window.innerWidth + this.boatContainer.width - BANANA_WIDTH) {
            this.boatContainer.position.x = -BOAT_WIDTH;
            nowLoad = true;
        }
        if (this.boatContainer.position.x > this.boatContainer.width - 435 + window.innerWidth / 2 && nowLoad) {
            this.changeBanana(this.bhag);
            nowLoad = false;
        }
    };

    changeBHAG = bhag => {
        this.bhag = bhag;
    };

    remove = () => {
        this.boatContainer.destroy(true);
        for (let i = 0; i < this.tweens.length; i++) {
            const tween = this.tweens[i];
            gsap.killTweensOf(tween);
        }
        this.mask.clear();
        this.mask.destroy();
    };
}

export default Boats;
