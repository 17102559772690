import { connect } from "react-redux";
import Container from "./container";
import { actionCreators as crmActions } from "redux/modules/crm";

const mapStateToProps = (state, ownProps) => {
    const {
        user: { permissions, isLeadingHand },
        crm: { entitiesList, entityCommons, commonItems }
    } = state;

    return {
        isLeadingHand,
        entitiesList,
        permissions,
        commonItems,
        entityCommons
    };
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        getSlimEntity: filter => dispatch(crmActions.getSlimEntity(filter)),
        getEntityCommons: () => dispatch(crmActions.getEntityCommons()),
        getAttrCommons: () => dispatch(crmActions.getAttrCommons())
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Container);
