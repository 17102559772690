import React, { Component } from "react";
import Auth from "./presenter";

class Container extends Component {
    constructor(props) {
        super(props);

        this.state = {
            username: "",
            password: "",
            isReady: false,
            isShaking: false,
            isLoading: false
        };

        this.onAnimationEnd = this.onAnimationEnd.bind(this);
    }

    componentDidMount() {
        setTimeout(() => {
            this.setState({
                isReady: true
            });
        }, 500);
    }

    componentDidUpdate(prevProps) {
        const { notFoundUser } = this.props;
        if (notFoundUser !== prevProps.notFoundUser) {
            this.setState({
                isShaking: true,
                isLoading: false
            });
        }
    }

    onAnimationEnd(event) {
        if (event.target.name === "loginBtn") {
            this.setState({
                isShaking: false
            });
        }
    }

    render() {
        const { username, password, isReady, isShaking, isLoading } = this.state;
        const { welcomeName } = this.props;
        return (
            <Auth
                isShaking={isShaking}
                isLoading={isLoading}
                isReady={isReady}
                welcomeName={welcomeName}
                usernameValue={username}
                passwordValue={password}
                handleInputChange={this._handleInputChange}
                handleSubmit={this._handleSubmit}
                onAnimationEnd={this.onAnimationEnd}
            />
        );
    }

    _handleInputChange = event => {
        const {
            target: { value, name }
        } = event;
        this.setState({
            [name]: value
        });
    };

    _handleSubmit = event => {
        this.setState({ isLoading: true });
        event.preventDefault();
        const { userLogin } = this.props;
        const { username, password } = this.state;
        if (username === "" || password === "") {
            alert("Please enter username or password");
            return;
        }
        userLogin(String(username).toLowerCase(), password);
    };
}

export default Container;
