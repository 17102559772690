import React from "react";
import styles from "shared/details.scss";
import { MDBCard, MDBCardHeader, MDBCardBody, MDBTable, MDBTableBody } from "louis-mdbreact";
import { Checkbox } from "@mui/material";

const QSChecklistCard = props => {
    const { qsCheckData, onChangeValue, qs_checklist, isDisabled } = props;
    return (
        <MDBCard className={styles.card}>
            <MDBCardHeader>QS Checklist</MDBCardHeader>
            <MDBCardBody>
                <MDBTable small>
                    <MDBTableBody>
                        {qsCheckData
                            .filter(check => check.active)
                            .map((check, i) => (
                                <tr key={check.id}>
                                    <td>
                                        <label className={styles.qsChecklist}>
                                            <Checkbox
                                                name={`${check.id}_qs_checklist`}
                                                onChange={onChangeValue}
                                                checked={qs_checklist.length > 0 && qs_checklist[i].completed}
                                                disabled={!isDisabled}
                                            />{" "}
                                            {check.name}
                                        </label>
                                    </td>
                                </tr>
                            ))}
                    </MDBTableBody>
                </MDBTable>
            </MDBCardBody>
        </MDBCard>
    );
};

export default QSChecklistCard;
