import React, { useEffect, useState, useContext, memo } from "react";
import { Avatar, Tooltip, TableCell, Typography, Box, IconButton } from "@mui/material";
import { stringAvatar, getCompanyColour, formatter } from "shared/Utils";
import { MDBBtn, MDBBtnGroup, MDBIcon } from "louis-mdbreact";
import styles from "shared/listStyles.scss";
import { QuoteContext } from "../CrmContext";
import { StageItemContext } from "../CrmContext";
import WinChanceModal from "./WinChanceModal";
import dayjs from "shared/dayjs";

import CommentIcon from "@mui/icons-material/Comment";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import withNoteModal from "./hoc/withNoteModal";

const StageItem = ({ stage, index, onAddNoteClick }) => {
    const { fetchData } = useContext(QuoteContext);
    const { setStageList } = useContext(StageItemContext);
    const [companyURL, setCompanyURL] = useState("");
    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        switch (stage.company) {
            case "GL":
                setCompanyURL("quote");
                break;
            case "GF":
                setCompanyURL("firequote");
                break;
            case "GAF":
                setCompanyURL("activefirequote");
                break;
            default:
                break;
        }
    }, [stage]);

    const onEditClick = () => {
        setIsOpen(!isOpen);
    };

    const onUpdate = () => {
        onEditClick();
        fetchData();
    };

    const onFollowUpOpenClick = () => {
        setStageList(current => current.map((cur, i) => (i === index || i === index + 1 ? { ...cur, noteOpen: !cur.noteOpen } : cur)));
    };
    return (
        <>
            <TableCell data-th="Quote No." sx={{ color: getCompanyColour(stage.company).color, minWidth: "15%" }}>
                <Box display="flex" flexDirection="row" alignItems="center" gap="0.5rem" width="100%">
                    <img src={getCompanyColour(stage.company).icon} alt={stage.company} height="22px" width="22px" />
                    <Typography component="span">
                        {stage.quote_number} {stage.quote_version}
                    </Typography>
                    <Typography component="span" variant="caption">
                        ({stage.region})
                    </Typography>
                </Box>
            </TableCell>
            <TableCell data-th="Client">
                <Typography component="span">{stage.client_name}</Typography>
                {stage.follow_up_notes.length > 0 || stage.follow_up_note ? (
                    <IconButton color={stage.noteOpen ? "primary" : "default"} onClick={onFollowUpOpenClick}>
                        <CommentIcon />
                    </IconButton>
                ) : (
                    <Tooltip title="Add New Note">
                        <IconButton color="secondary" onClick={onAddNoteClick}>
                            <AddCircleIcon />
                        </IconButton>
                    </Tooltip>
                )}
            </TableCell>
            <TableCell data-th="Name/Address">
                <Typography>{stage.job_name}</Typography>
                <Typography variant="caption" color="gray">
                    {stage.job_address && stage.job_name !== stage.job_address ? ` (${stage.job_address})` : ""}
                </Typography>
            </TableCell>
            <TableCell data-th="Quote Value" sx={{ textAlign: "right" }}>
                <Typography>{formatter.format(stage.quote_value)}</Typography>
            </TableCell>
            {stage.status_name !== "Won" && (
                <TableCell data-th="Win Chance" sx={{ textAlign: "right" }}>
                    <Typography>{stage.win_chance} %</Typography>
                </TableCell>
            )}
            <TableCell data-th="Sent Date">{stage.sent_date !== "None" ? dayjs(stage.sent_date).format("DD/MM/YYYY") : "-"}</TableCell>
            <TableCell data-th="QS" sx={{ textAlign: "center" }}>
                {stage.qs !== "None None" && (
                    <Tooltip title={stage.qs} placement="top" disableInteractive>
                        <Avatar {...stringAvatar(stage.qs)} sx={{ display: "inline-flex", width: 32, height: 32, fontSize: "1rem" }} />
                    </Tooltip>
                )}
            </TableCell>
            <TableCell data-th="Actions">
                <MDBBtnGroup>
                    <MDBBtn color="secondary" className={`${styles.buttonSize} ${styles.noRound}`} onClick={onEditClick}>
                        <MDBIcon icon="edit" />
                    </MDBBtn>
                    <a href={`https://interim.gl.co.nz/admin/quotemaster/${companyURL}/${stage.id}/change/`} target="_blank" rel="noreferrer">
                        <MDBBtn color="primary" className={`${styles.buttonSize} ${styles.noRound}`}>
                            <MDBIcon icon="info-circle" />
                        </MDBBtn>
                    </a>
                </MDBBtnGroup>
            </TableCell>
            {isOpen && <WinChanceModal open={isOpen} toggle={onEditClick} onUpdate={onUpdate} stage={stage} onAddNoteClick={onAddNoteClick} />}
        </>
    );
};

export default withNoteModal(memo(StageItem));
