import React from "react";
import styles from "./styles.scss";
import WindowedSelect, { components } from "react-windowed-select";

const VaildSelect = props => {
    const {
        options,
        onChange,
        value,
        isValid,
        isDisabled = true,
        name,
        isMulti,
        isClearable,
        placeholder = "Select...",
        subLabel,
        title = "",
        ...rest
    } = props;
    let Option = "";
    if (subLabel) {
        Option = props => {
            return (
                <components.Option {...props}>
                    <div>
                        {props.data.label}
                        <span style={{ fontSize: "0.75rem" }} className="grey-text">
                            {" "}
                            {props.data.subLabel}
                        </span>
                    </div>
                </components.Option>
            );
        };
    }
    return (
        <div className={styles.selectRequired}>
            <WindowedSelect
                options={options}
                name={name}
                id={name}
                inputId={name}
                onChange={onChange}
                value={value}
                isMulti={isMulti}
                isClearable={isClearable}
                placeholder={placeholder}
                components={subLabel && { Option }}
                styles={{
                    control: base => {
                        if (isValid !== undefined) {
                            return {
                                ...base,
                                "&:hover": {
                                    borderColor: isValid ? "hsl(0,0%,80%)" : "#dc3545"
                                },
                                borderColor: isValid ? "hsl(0,0%,80%)" : "#dc3545"
                            };
                        } else {
                            return {
                                ...base
                            };
                        }
                    },
                    multiValue: (base, state) => {
                        return state.data.isFixed ? { ...base, backgroundColor: "gray" } : base;
                    },
                    multiValueLabel: (base, state) => {
                        return state.data.isFixed ? { ...base, color: "white", paddingRight: 6 } : base;
                    },
                    multiValueRemove: (base, state) => {
                        return state.data.isFixed ? { ...base, display: "none" } : base;
                    },
                    option: base => {
                        return {
                            ...base,
                            width: "100%",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis"
                        };
                    }
                }}
                isDisabled={!isDisabled}
                {...rest}
            />
            {title && <SelectLabel title={title} />}
            {/* to make select required */}
            {isValid !== undefined ? (
                <>
                    <input
                        tabIndex={-1}
                        autoComplete="off"
                        className={`${styles.requiredInput} form-control`}
                        value={value}
                        onChange={onChange}
                        required
                        disabled={!isDisabled}
                    />
                    <div className="invalid-feedback">Required</div>
                </>
            ) : null}
        </div>
    );
};

const SelectLabel = ({ title }) => {
    return (
        <label
            style={{
                position: "absolute",
                top: "-5px",
                left: "10px",
                padding: "0 7px",
                lineHeight: "0.75rem",
                background: "#fff",
                color: `rgba(0, 0, 0, 0.6)`,
                fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
                fontWeight: 400,
                fontSize: "0.75rem"
            }}
        >
            {title}
        </label>
    );
};

export { SelectLabel };

export default VaildSelect;
