import React from "react";
import { NavLink, Link } from "react-router-dom";
import { MDBIcon } from "louis-mdbreact";
import styles from "shared/headNavStyles.scss";

const JobDependPermission = props => {
    const { permissionLevel, toggleNavbar, isSLT } = props;
    switch (permissionLevel) {
        case 4:
            return (
                <>
                    <li>
                        <NavLink exact activeClassName={styles.active} to={`/jobs`} onClick={toggleNavbar}>
                            <MDBIcon icon="align-left" /> <span>Jobs</span>
                        </NavLink>
                        <Link className={styles.new} to={`/jobs/new`} onClick={toggleNavbar}>
                            <MDBIcon far icon="plus-square" />
                        </Link>
                    </li>
                    {/* <li>
                        <NavLink
                            to={`/jobs/requested`}
                            activeClassName={styles.active}
                            onClick={toggleNavbar}
                        >
                            <MDBIcon far icon="bell" /> <span>Requested</span>
                        </NavLink>
                        {isQS && (
                            <Link className={styles.new} to={`/jobs/new`} onClick={toggleNavbar}>
                                <MDBIcon far icon="plus-square" />
                            </Link>
                        )}
                    </li> */}
                    {isSLT && (
                        <li>
                            <NavLink to={`/jobs/plreport`} activeClassName={styles.active} onClick={toggleNavbar}>
                                <MDBIcon far icon="file-alt" /> <span>P&L Report</span>
                            </NavLink>
                        </li>
                    )}
                </>
            );
        case 3:
            return (
                <>
                    <li>
                        <NavLink exact activeClassName={styles.active} to={`/jobs`} onClick={toggleNavbar}>
                            <MDBIcon icon="align-left" /> <span>Jobs</span>
                        </NavLink>
                    </li>
                    {/* <li>
                        <NavLink
                            to={`/jobs/requested`}
                            activeClassName={styles.active}
                            onClick={toggleNavbar}
                        >
                            <MDBIcon far icon="bell" /> <span>Requested</span>
                        </NavLink>
                        <Link className={styles.new} to={`/jobs/new`} onClick={toggleNavbar}>
                            <MDBIcon far icon="plus-square" />
                        </Link>
                    </li> */}
                    {isSLT && (
                        <li>
                            <NavLink to={`/jobs/plreport`} activeClassName={styles.active} onClick={toggleNavbar}>
                                <MDBIcon far icon="file-alt" /> <span>P&L Report</span>
                            </NavLink>
                        </li>
                    )}
                </>
            );
        default:
            return (
                <>
                    <li>
                        <NavLink exact to={`/jobs`} activeClassName={styles.active} onClick={toggleNavbar}>
                            <MDBIcon icon="align-left" /> <span>Jobs</span>
                        </NavLink>
                    </li>
                    {isSLT && (
                        <li>
                            <NavLink to={`/jobs/plreport`} activeClassName={styles.active} onClick={toggleNavbar}>
                                <MDBIcon far icon="file-alt" /> <span>P&L Report</span>
                            </NavLink>
                        </li>
                    )}
                </>
            );
    }
};

export default JobDependPermission;
