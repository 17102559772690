import React from "react";
import { Route, Switch } from "react-router-dom";
import ChangePassword from "components/User/ChangePassword";
import Dashboard from "components/User/Dashboard";

const USerRoute = () => (
    <Switch>
        <Route exact path="/user/changepassword" component={ChangePassword} />
        <Route exact path="/user/dashboard" component={Dashboard} />
    </Switch>
);

export default USerRoute;
