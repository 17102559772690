import React, { useState, useEffect } from "react";
import styles from "shared/details.scss";
import goodsStyles from "./styles.scss";
import { useDispatch, useSelector } from "react-redux";
import Button from "@mui/material/Button";
import { FormProvider, useForm, useFieldArray } from "react-hook-form";
import { actionCreators as jobActions } from "redux/modules/jobs";
import { actionCreators as materialActions } from "redux/modules/material";
import { actionCreators as stockActions } from "redux/modules/stock";
import { MDBTable, MDBTableBody, MDBTableHead, MDBBtn, MDBRow, MDBCol } from "louis-mdbreact";
import GoodsItem from "./GoodsItem";
import history from "shared/history";
import SubmitButton from "components/SubmitButton";
import { Helmet } from "react-helmet";

const TransferGoods = () => {
    const dispatch = useDispatch();
    const {
        user: { homeRegion }
    } = useSelector(state => state);
    const defaultTransfer = {
        description: "",
        source_job: homeRegion,
        pickup_by: "",
        quantity: "",
        sku: "", // temp value
        destination_job: "",
        directed_to: ""
    };
    const method = useForm({
        defaultValues: {
            transferList: [defaultTransfer]
        }
    });
    const [jobNames, setJobNames] = useState([]);
    const [skuDatabase, setSkuDatabase] = useState([]);
    const [isPending, setIsPending] = useState(false);
    const { control, handleSubmit, getValues } = method;
    const { fields, prepend, remove } = useFieldArray({
        control,
        name: "transferList",
        keyName: "idx"
    });

    useEffect(() => {
        const loadJobList = async () => {
            const jobList = await dispatch(jobActions.getSlimJobs(false, true));
            const regions = JSON.parse(localStorage.getItem("regions"));
            setJobNames(
                jobList.map(job => ({
                    value: job.id,
                    label: `${job.id}-${regions.find(region => region.id === job.region).region_short_code} (${job.job_name})`
                }))
            );
            const skuList = await dispatch(materialActions.getSlimSkuDatabase());
            setSkuDatabase([...skuList.filter(list => !list.uncommon_sku), ...skuList.filter(list => list.uncommon_sku)]);
        };
        loadJobList();
    }, [dispatch]);

    const returnToList = () => {
        history.goBack();
    };

    const addTransfer = () => {
        prepend(defaultTransfer);
    };

    const onSubmit = data => {
        if (data.transferList.length > 0) {
            setIsPending(true);
            Promise.all(data.transferList.map(transfer => dispatch(stockActions.submitTransfer(transfer)))).then(values => {
                history.push("/stock/transferred");
            });
        }
    };

    return (
        <div className={styles.container}>
            <Helmet>
                <title>Stock | New Transfer</title>
            </Helmet>
            <h2 className={styles.title}>NEW TRANSFER</h2>
            <div className={`${goodsStyles.stickyButtonContainer} clearfix`}>
                <div className={`${goodsStyles.stickyButton} float-right`}>
                    <Button size="small" color="primary" onClick={addTransfer} variant="contained" className="mb-2">
                        add new line
                    </Button>
                </div>
            </div>
            <FormProvider {...method}>
                <form onSubmit={handleSubmit(onSubmit)} noValidate>
                    <MDBTable small className={styles.detailResponsiveTable}>
                        <MDBTableHead>
                            <tr className="orange lighten-3">
                                <th className="text-center">Transfer Details</th>
                                <th width="100" className="text-center">
                                    Actions
                                </th>
                            </tr>
                        </MDBTableHead>
                        <MDBTableBody>
                            {fields.map((list, i) => (
                                <GoodsItem
                                    key={list.idx}
                                    list={list}
                                    index={i}
                                    jobNames={jobNames}
                                    skuDatabase={skuDatabase}
                                    remove={index => remove(index)}
                                    prepend={index => {
                                        prepend({
                                            ...getValues(`transferList[${index}]`),
                                            quantity: "",
                                            description: ""
                                        });
                                    }}
                                />
                            ))}
                        </MDBTableBody>
                    </MDBTable>
                    <MDBRow>
                        <MDBCol>
                            <div className={`${styles.card} float-right`}>
                                <MDBBtn color="blue-grey" onClick={returnToList}>
                                    Cancel
                                </MDBBtn>{" "}
                                <SubmitButton type="new" pendingLoading={isPending} />
                            </div>
                        </MDBCol>
                    </MDBRow>
                </form>
            </FormProvider>
        </div>
    );
};

export default TransferGoods;
