import axios from "axios";
import Cookies from "js-cookie";
import { parseJwt } from "shared/Utils";
import { actionCreators as userActions } from "redux/modules/user";

const API_URL = process.env.REACT_APP_API_URL;

const axiosInstance = axios.create({
    headers: {
        Authorization: `Bearer ${Cookies.get("acc_token")}`
    },
    baseURL: API_URL
});

axiosInstance.CancelToken = axios.CancelToken;
axiosInstance.isCancel = axios.isCancel;

const tokenFunc = (dispatch, history) => {
    let isAlreadyFetchingAccessToken = false;

    // This is the list of waiting requests that will retry after the JWT refresh complete
    let subscribers = [];
    axiosInstance.interceptors.request.use(
        config => {
            const { acc_token } = Cookies.get();
            const current = new Date();
            if (!acc_token || Number(parseJwt(acc_token)) - Number(current) < 50000) {
                const response = resetTokenAndReattemptRequest(config);
                return response.then(data => {
                    return data;
                });
            }
            return config;
        },
        error => Promise.reject(error)
    );

    axiosInstance.interceptors.response.use(
        function (response) {
            // Any status code that lie within the range of 2xx cause this function to trigger
            // Do something with response data
            return response;
        },
        function (error) {
            const errorResponse = error.response;
            if (errorResponse) {
                if (errorResponse.status === 404) {
                    history.push("/404/");
                }
                if (errorResponse.status === 400) {
                    if (errorResponse.config.url.match(/address_geocode/)) {
                        return Promise.reject(error);
                    }
                    alert(JSON.stringify(errorResponse.data));
                    console.log(errorResponse.data);
                    return Promise.reject(error);
                }
            }
            // If the error is due to other reasons, we just throw it back to axios
            return Promise.reject(error);
        }
    );

    async function resetTokenAndReattemptRequest(config) {
        try {
            //const { response: errorResponse } = error;
            const resetToken = await Cookies.get("ref_token");
            if (!resetToken) {
                dispatch(userActions.logOut());
                //return Promise.reject(error);
            }
            const retryOriginalRequest = new Promise(resolve => {
                addSubscriber(access_token => {
                    config.headers.Authorization = `Bearer ${access_token}`;
                    return resolve(config);
                });
            });

            if (!isAlreadyFetchingAccessToken) {
                isAlreadyFetchingAccessToken = true;
                const response = await axios({
                    method: "post",
                    url: `${API_URL}token/refresh/`,
                    Authorization: `Bearer ${resetToken}`,
                    data: {
                        refresh: resetToken
                    }
                });
                if (!response.data) {
                    dispatch(userActions.logOut());
                    window.location.replace("/");
                    //return Promise.reject(error);
                }
                const newToken = response.data.access;
                Cookies.set("acc_token", newToken, {
                    expires: parseJwt(newToken),
                    path: "/",
                    secure: true,
                    sameSite: "Lax"
                });
                Object.assign(axiosInstance.defaults, {
                    headers: { Authorization: `Bearer ${newToken}` }
                });
                isAlreadyFetchingAccessToken = false;
                onAccessTokenFetched(newToken);
            }
            return retryOriginalRequest;
        } catch (err) {
            return Promise.reject(err);
        }
    }

    function onAccessTokenFetched(access_token) {
        // When the refresh is successful, it should return config
        subscribers.forEach(callback => callback(access_token));
        subscribers = [];
    }

    function addSubscriber(callback) {
        subscribers.push(callback);
    }
};

export { axiosInstance, API_URL };
export default tokenFunc;
