const UPDATE_SERVICEWORKER = "UPDATE_SERVICEWORKER";

const initialState = {
    serviceWorkerUpdated: false
};

function updateServiceWorker(registration) {
    return {
        type: UPDATE_SERVICEWORKER,
        registration
    };
}

function reducer(state = initialState, action) {
    const { registration } = action;
    switch (action.type) {
        case UPDATE_SERVICEWORKER: {
            return {
                ...state,
                serviceWorkerUpdated: true,
                registration
            };
        }
        default:
            return state;
    }
}

export { updateServiceWorker };

export default reducer;
