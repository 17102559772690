import React from "react";
import NoteFileItem from "./NoteFileItem";

const NoteFileList = ({ files = [] }) => {
    return (
        <span>
            {files?.map(file => (
                <NoteFileItem key={file.id} file={file} />
            ))}
        </span>
    );
};

export default NoteFileList;
