import React from "react";
import { MDBBtn } from "louis-mdbreact";
import DialogContainer from "components/DialogContainer";
import { DialogActions, DialogContent } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
    root: {
        flexDirection: "column"
    }
});

const ConfirmModal = props => {
    const { modal, toggle, title } = props;
    const classes = useStyles();
    const arrColors = ["default", "secondary", "primary"];
    return (
        <DialogContainer
            open={modal}
            handleClose={e => toggle(e, 0)}
            maxWidth="sm"
            fullScreen={false}
            title={title || "Confirm"}
        >
            <DialogContent>{props.message}</DialogContent>
            <DialogActions className={classes.root}>
                {props.buttons.map((button, i) => (
                    <MDBBtn
                        key={button.func_id}
                        style={{ width: "100%" }}
                        className="mx-0"
                        color={button.colour || arrColors[i]}
                        onClick={e => toggle(e, button.func_id)}
                    >
                        {button.name}
                    </MDBBtn>
                ))}
            </DialogActions>
        </DialogContainer>
    );
};

export default ConfirmModal;
