import React from "react";
import { MDBCard, MDBCardBody, MDBCardHeader, MDBTable, MDBTableBody, MDBTableHead, MDBBtn } from "louis-mdbreact";
import { AddItem, FileItem } from "components/Ops/AddItem";
import styles from "shared/details.scss";
import Questions from "./Questions";

const SSSPCheck = ({
    isLoading,
    type,
    form_content,
    onResponseChange,
    onCommentChange,
    onDateChange,
    pageType,
    isDisabled,
    onSSSPFileChange,
    onFileChange,
    removeItem,
    removeFormItem,
    files,
    files_list,
    response_file,
    downloadImage,
    fileClickTrigger,
    onRelavantDateTimeChange,
    formData
    // addSignature
}) => {
    return (
        <>
            <MDBCard className={styles.card}>
                <MDBCardHeader>
                    {type.name}{" "}
                    {isLoading ? (
                        <div className="spinner-border spinner-border-sm text-primary" role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                    ) : undefined}
                </MDBCardHeader>
                <MDBCardBody>
                    {/* {type.id === 1 && (
                        <>
                            <label>Tasks the job will involve</label>
                            <div>
                                {jobDetail.service_id &&
                                    jobDetail.service_id.map(service => (
                                        <span className={styles.serviceInvolve} key={service}>
                                            {taskType && taskType.find(ser => ser.id === service).name}
                                        </span>
                                    ))}
                            </div>
                            <hr />
                        </>
                    )} */}
                    {form_content && (
                        <MDBTable small className={styles.detailResponsiveTable}>
                            <MDBTableHead>
                                <tr className={styles.textCentre}>
                                    <th>Question</th>
                                    <th width="20%">Y/N</th>
                                </tr>
                            </MDBTableHead>
                            <MDBTableBody>
                                {form_content
                                    .filter(element => {
                                        if (element.form_element_string === "Is the person operating the EWP qualified to use it?") {
                                            return Number(form_content[0].response) === 1;
                                        }
                                        return element.visibility;
                                    })

                                    .map(element => (
                                        <Questions
                                            key={element.element}
                                            element={element}
                                            onCommentChange={onCommentChange}
                                            onRelavantDateTimeChange={onRelavantDateTimeChange}
                                            isDisabled={isDisabled}
                                            onResponseChange={onResponseChange}
                                            onDateChange={onDateChange}
                                            // addSignature={addSignature}
                                        />
                                    ))}
                            </MDBTableBody>
                        </MDBTable>
                    )}
                    {type.log_type_form && (
                        <MDBCard className={styles.card}>
                            <MDBCardHeader>
                                Files
                                <div className="float-right">
                                    <input
                                        type="file"
                                        className="d-none"
                                        multiple
                                        id={`file_ops_form_${type.id}`}
                                        name="formFiles"
                                        onChange={onFileChange}
                                        aria-describedby="form_file_input"
                                    />
                                    <MDBBtn size="sm" color="primary" onClick={() => fileClickTrigger(`file_ops_form_${type.id}`)} name="formFiles">
                                        Add File(s)
                                    </MDBBtn>
                                </div>
                            </MDBCardHeader>
                            <MDBCardBody>
                                <MDBTable small className={styles.detailResponsiveTable}>
                                    <MDBTableHead>
                                        <tr className={styles.textCentre}>
                                            <th>File</th>
                                            <th width="100px">Action</th>
                                        </tr>
                                    </MDBTableHead>
                                    <MDBTableBody>
                                        {files_list?.map(file => (
                                            <FileItem key={file.id} {...file} downloadImage={downloadImage} />
                                        ))}
                                        {formData?.formFiles?.map(file => {
                                            return <AddItem key={file.id} {...file} removeItem={removeFormItem} />;
                                        })}
                                    </MDBTableBody>
                                </MDBTable>
                            </MDBCardBody>
                        </MDBCard>
                    )}
                </MDBCardBody>
            </MDBCard>
            {pageType === "detail" && type.id === 1 ? (
                <MDBCard className={styles.card}>
                    <MDBCardHeader>
                        Response Files
                        {!isDisabled && (
                            <div className="float-right">
                                <input
                                    type="file"
                                    className="d-none"
                                    multiple
                                    id="file_ssspcheck"
                                    name="ssspcheckFile"
                                    onChange={onSSSPFileChange}
                                    aria-describedby="file_input_ssspcheck"
                                />
                                <MDBBtn size="sm" color="primary" onClick={() => fileClickTrigger("file_ssspcheck")} name="ssspcheckFile">
                                    Add File(s)
                                </MDBBtn>
                            </div>
                        )}
                    </MDBCardHeader>
                    <MDBCardBody>
                        <MDBTable small className={styles.detailResponsiveTable}>
                            <MDBTableHead>
                                <tr className={styles.textCentre}>
                                    <th>File</th>
                                    <th width="100px">Action</th>
                                </tr>
                            </MDBTableHead>
                            <MDBTableBody>
                                {response_file.map(file => (
                                    <FileItem key={file.id} {...file} downloadImage={downloadImage} />
                                ))}
                                {files.map(file => {
                                    return <AddItem key={file.id} {...file} removeItem={removeItem} />;
                                })}
                            </MDBTableBody>
                        </MDBTable>
                    </MDBCardBody>
                </MDBCard>
            ) : undefined}
        </>
    );
};

SSSPCheck.defaultProps = {
    jobDetail: { service_id: [] }
};

export default SSSPCheck;
