import React from "react";
import ValidSelect from "components/ValidSelect";

const SelectComponent = ({ label, options, onAttributeChanged }) => {
    return (
        <React.Fragment>
            <label>{label}</label>
            <ValidSelect
                options={options}
                name={String(label).toLowerCase()}
                getOptionLabel={option => option.name}
                getOptionValue={option => option.id}
                value=""
                onChange={option => onAttributeChanged(option, `${String(label).toLowerCase()}`)}
            />
        </React.Fragment>
    );
};

export default SelectComponent;
