import React from "react";
import styles from "./styles.scss";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { MDBCard, MDBCardHeader, MDBCardBody } from "louis-mdbreact";
import PropTypes from "prop-types";

const Loading = props => {
    if (props.loadingType === "detail") {
        return <DetailLoading />;
    } else if (props.loadingType === "component") {
        return <ComponentLoading />;
    } else if (props.loadingType === "list") {
        return <ListLoading />;
    } else {
        return <DefaultLoading />;
    }
};

const DetailLoading = () => (
    <div className={styles.container}>
        <MDBCard>
            <MDBCardHeader>
                <Skeleton count={1} width={`20%`} />
            </MDBCardHeader>
            <MDBCardBody>
                <Skeleton count={1} width={`100%`} />
                <Skeleton count={1} width={`80%`} />
                <Skeleton count={1} width={`90%`} />
            </MDBCardBody>
        </MDBCard>
        <MDBCard className={styles.cardContainer}>
            <MDBCardHeader>
                <Skeleton count={1} width={`30%`} />
            </MDBCardHeader>
            <MDBCardBody>
                <Skeleton count={1} width={`70%`} />
                <Skeleton count={1} width={`80%`} />
                <Skeleton count={1} width={`40%`} />
                <Skeleton count={1} />
            </MDBCardBody>
        </MDBCard>
        <MDBCard className={styles.cardContainer}>
            <MDBCardHeader>
                <Skeleton count={1} width={`20%`} />
            </MDBCardHeader>
            <MDBCardBody>
                <Skeleton count={1} width={`50%`} />
                <Skeleton count={1} width={`60%`} />
                <Skeleton count={1} />
                <Skeleton count={1} width={`30%`} />
                <Skeleton count={1} width={`100%`} />
            </MDBCardBody>
        </MDBCard>
        <MDBCard className={styles.cardContainer}>
            <MDBCardHeader>
                <Skeleton count={1} width={`40%`} />
            </MDBCardHeader>
            <MDBCardBody>
                <Skeleton count={1} width={`100%`} />
                <Skeleton count={1} width={`80%`} />
                <Skeleton count={1} width={`90%`} />
            </MDBCardBody>
        </MDBCard>
        <MDBCard className={styles.cardContainer}>
            <MDBCardHeader>
                <Skeleton count={1} width={`10%`} />
            </MDBCardHeader>
            <MDBCardBody>
                <Skeleton count={1} width={`70%`} />
                <Skeleton count={1} width={`80%`} />
                <Skeleton count={1} width={`40%`} />
                <Skeleton count={1} />
            </MDBCardBody>
        </MDBCard>
        <MDBCard className={styles.cardContainer}>
            <MDBCardHeader>
                <Skeleton count={1} width={`30%`} />
            </MDBCardHeader>
            <MDBCardBody>
                <Skeleton count={1} width={`60%`} />
                <Skeleton count={1} width={`50%`} />
                <Skeleton count={1} />
                <Skeleton count={1} width={`30%`} />
                <Skeleton count={1} width={`100%`} />
            </MDBCardBody>
        </MDBCard>
    </div>
);

const ComponentLoading = () => (
    <div className={styles.container}>
        <MDBCard className={styles.flatCard}>
            <MDBCardHeader>
                <Skeleton count={1} width={`20%`} />
            </MDBCardHeader>
            <MDBCardBody>
                <Skeleton count={1} width={`100%`} />
                <Skeleton count={1} width={`80%`} />
                <Skeleton count={1} width={`90%`} />
                <Skeleton count={1} width={`60%`} />
                <Skeleton count={1} width={`80%`} />
                <Skeleton count={1} width={`100%`} />
            </MDBCardBody>
        </MDBCard>
    </div>
);
const DefaultLoading = () => (
    <div className={styles.container}>
        <Skeleton count={1} width={`100%`} />
        <Skeleton count={1} width={`80%`} />
        <Skeleton count={1} width={`90%`} />
        <Skeleton count={1} width={`60%`} />
        <Skeleton count={1} width={`80%`} />
        <Skeleton count={1} width={`100%`} />
    </div>
);

const ListLoading = () => (
    <div className={styles.container}>
        <Skeleton count={1} height={30} />
        <hr />
        <div className={styles.listContainer}>
            <div className={styles.list}>
                <Skeleton count={1} width={`100%`} />
                <Skeleton count={1} width={`80%`} />
                <Skeleton count={1} width={`90%`} />
            </div>
            <div className={styles.button}>
                <Skeleton circle={false} height={40} width={40} />
            </div>
        </div>
        <hr />
        <div className={styles.listContainer}>
            <div className={styles.list}>
                <Skeleton count={1} width={`90%`} />
                <Skeleton count={1} width={`100%`} />
                <Skeleton count={1} width={`70%`} />
            </div>
            <div className={styles.button}>
                <Skeleton circle={false} height={40} width={40} />
            </div>
        </div>
        <hr />
        <div className={styles.listContainer}>
            <div className={styles.list}>
                <Skeleton count={1} width={`60%`} />
                <Skeleton count={1} width={`80%`} />
                <Skeleton count={1} width={`100%`} />
            </div>
            <div className={styles.button}>
                <Skeleton circle={false} height={40} width={40} />
            </div>
        </div>
        <hr />
        <div className={styles.listContainer}>
            <div className={styles.list}>
                <Skeleton count={1} width={`100%`} />
                <Skeleton count={1} width={`80%`} />
                <Skeleton count={1} width={`90%`} />
            </div>
            <div className={styles.button}>
                <Skeleton circle={false} height={40} width={40} />
            </div>
        </div>
        <hr />
        <div className={styles.listContainer}>
            <div className={styles.list}>
                <Skeleton count={1} width={`90%`} />
                <Skeleton count={1} width={`100%`} />
                <Skeleton count={1} width={`70%`} />
            </div>
            <div className={styles.button}>
                <Skeleton circle={false} height={40} width={40} />
            </div>
        </div>
        <hr />
        <div className={styles.listContainer}>
            <div className={styles.list}>
                <Skeleton count={1} width={`60%`} />
                <Skeleton count={1} width={`80%`} />
                <Skeleton count={1} width={`100%`} />
            </div>
            <div className={styles.button}>
                <Skeleton circle={false} height={40} width={40} />
            </div>
        </div>
        <hr />
        <div className={styles.listContainer}>
            <div className={styles.list}>
                <Skeleton count={1} width={`90%`} />
                <Skeleton count={1} width={`100%`} />
                <Skeleton count={1} width={`70%`} />
            </div>
            <div className={styles.button}>
                <Skeleton circle={false} height={40} width={40} />
            </div>
        </div>
        <hr />
        <div className={styles.listContainer}>
            <div className={styles.list}>
                <Skeleton count={1} width={`60%`} />
                <Skeleton count={1} width={`80%`} />
                <Skeleton count={1} width={`100%`} />
            </div>
            <div className={styles.button}>
                <Skeleton circle={false} height={40} width={40} />
            </div>
        </div>
        <hr />
        <div className={styles.listContainer}>
            <div className={styles.list}>
                <Skeleton count={1} width={`100%`} />
                <Skeleton count={1} width={`80%`} />
                <Skeleton count={1} width={`90%`} />
            </div>
            <div className={styles.button}>
                <Skeleton circle={false} height={40} width={40} />
            </div>
        </div>
        <hr />
        <div className={styles.listContainer}>
            <div className={styles.list}>
                <Skeleton count={1} width={`90%`} />
                <Skeleton count={1} width={`100%`} />
                <Skeleton count={1} width={`70%`} />
            </div>
            <div className={styles.button}>
                <Skeleton circle={false} height={40} width={40} />
            </div>
        </div>
    </div>
);

Loading.propTypes = {
    loadingType: PropTypes.string
};

export default Loading;
