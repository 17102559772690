import React, { useEffect, useState } from "react";
import styles from "shared/listStyles.scss";
import { Grid, Stack, List } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { actionCreators as taskActions } from "redux/modules/tasks";
import { actionCreators as jobActions } from "redux/modules/jobs";
import { actionCreators as crmActions } from "redux/modules/crm";
import ListItems from "./ListItems";
import RejectModal from "./RejectModal";
import EditModal from "./EditModal";
import { ModalContext } from "../TaskContext";
import { Helmet } from "react-helmet";
import MissingMJS from "./MissingMJS";
import CreateNewButton from "components/CreateNewButton";
import ListContainer from "./ListContainer";
import { LoadingButton } from "@mui/lab";
import TravelTimeLists from "./TravelTimeLists";
import { approvalJobSortingList, approvalWorkerSortingList } from "shared/Utils";

const TimeApprove = () => {
    const dispatch = useDispatch();
    const {
        tasks: { timecloudList, travelTimeList }
    } = useSelector(state => state);
    const approvalListSetting = JSON.parse(localStorage.getItem("approvalList"));
    const [listType, setListType] = useState(approvalListSetting ? approvalListSetting.type : "job");
    const [rejectData, setRejectData] = useState(false);
    const [modalData, setModalData] = useState(null);

    const [approveList, setApproveList] = useState([]);
    // const [teamApprovalList, setTeamApprovalList] = useState([]);
    const [missingList, setMissingList] = useState([]);
    // const [teamMissingList, setTeamMissingList] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        // dispatch(taskActions.getSlimMaintenanceJob(false, true));
        dispatch(jobActions.getSlimJobs(false, true));
        dispatch(crmActions.getSlimEntity("?relationships=1"));
        dispatch(taskActions.getTimecloudAction());
        dispatch(taskActions.getRejectReasons());
    }, [dispatch]);

    useEffect(() => {
        setIsLoading(true);
        const getList = async () => {
            await dispatch(taskActions.getTimeCloudList());
            setIsLoading(false);
        };
        getList();
        // return () => {
        //     dispatch(taskActions.removeTimeCloudList());
        // };
    }, [dispatch]);

    useEffect(() => {
        if (timecloudList?.length > 0) {
            setApproveList(
                getGroupList(
                    timecloudList
                        .filter(list => list.mjs_id)
                        .sort((a, b) => (listType === "job" ? approvalJobSortingList(a, b) : approvalWorkerSortingList(a, b))),
                    listType
                )
            );
        }
    }, [timecloudList, listType]);

    // useEffect(() => {
    //     if (teamTimeCloudList?.length > 0) {
    //         setTeamApprovalList(
    //             getGroupList(
    //                 teamTimeCloudList
    //                     .filter(list => list.mjs_id)
    //                     .sort((a, b) => (listType === "job" ? approvalJobSortingList(a, b) : approvalWorkerSortingList(a, b))),
    //                 listType
    //             )
    //         );
    //     }
    // }, [teamTimeCloudList, listType]);

    useEffect(() => {
        setMissingList(timecloudList?.filter(list => !list.mjs_id).sort(approvalWorkerSortingList));
        // setTeamMissingList(teamTimeCloudList?.filter(list => !list.mjs_id).sort(approvalWorkerSortingList));
    }, [timecloudList]);
    return (
        <div style={{ paddingBottom: "60px" }} className={styles.container}>
            <Helmet>
                <title>Tasks | Time Entry Approvals</title>
            </Helmet>
            <ModalContext.Provider value={{ listType, rejectData, setRejectData, modalData, setModalData }}>
                <CreateNewButton />
                <Grid container direction="column" spacing={2} flexWrap="nowrap">
                    <Grid item>
                        {missingList?.length > 0 && (
                            <>
                                <div className={styles.titleWrap}>
                                    <h2 className={`${styles.taskTitle}`}>MISSING MJS</h2>
                                </div>

                                {missingList?.length > 0 && <MissingMJS items={missingList} />}
                            </>
                        )}
                    </Grid>
                    <Grid item>
                        <div className={styles.titleWrap}>
                            <Stack direction="row" alignItems="flex-start" spacing={1}>
                                <h2 className={`${styles.taskTitle}`}>APPROVALS</h2>
                                <LoadingButton
                                    loading={isLoading}
                                    variant="outlined"
                                    size="small"
                                    onClick={_ => {
                                        const newListType = listType === "job" ? "worker" : "job";
                                        setApproveList([]);
                                        // setTeamApprovalList([]);
                                        localStorage.setItem("approvalList", JSON.stringify({ type: newListType }));
                                        setListType(newListType);
                                    }}
                                >
                                    By {listType === "job" ? "worker" : "job"}
                                </LoadingButton>
                            </Stack>
                        </div>
                        {approveList?.length > 0 && (
                            <>
                                <List component="div" sx={{ marginBottom: "20px" }}>
                                    {approveList?.map((list, index) => (
                                        <ListContainer
                                            key={index}
                                            title={listType === "job" ? `${list.job_id}-${list.job_region} (${list.job_name})` : list.worker_name}
                                        >
                                            <ListItems item={list.entries} />
                                        </ListContainer>
                                    ))}
                                </List>
                            </>
                        )}
                    </Grid>
                    {travelTimeList?.length > 0 && (
                        <Grid item>
                            <div className={styles.titleWrap}>
                                <h2 className={`${styles.taskTitle}`}>Travels</h2>
                            </div>

                            {travelTimeList?.length > 0 && <TravelTimeLists item={travelTimeList} />}
                        </Grid>
                    )}
                </Grid>
                {modalData && (
                    <EditModal
                        open={!!modalData}
                        toggle={() => {
                            setModalData(null);
                        }}
                    />
                )}
                {rejectData && (
                    <RejectModal
                        open={!!rejectData}
                        toggle={() => {
                            setRejectData(null);
                        }}
                    />
                )}
            </ModalContext.Provider>
        </div>
    );
};

const getGroupList = (list, type) => {
    const groupList = list.reduce((total, current) => {
        const matchingJob = type === "job" ? total.find(to => to.job_id === current.job_id) : total.find(to => to.worker_id === current.worker_id);
        if (matchingJob) {
            matchingJob.entries.push(current);
        } else {
            type === "job"
                ? total.push({
                      job_id: current.job_id,
                      job_name: current.job_name,
                      job_region: current.job_region,
                      entries: [current]
                  })
                : total.push({ worker_id: current.worker_id, worker_name: current.worker_name, entries: [current] });
        }
        return total;
    }, []);
    return groupList;
};

export default TimeApprove;
