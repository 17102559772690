import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import dayjs from "shared/dayjs";
import { Typography, Chip } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(theme => ({
    dates: {
        fontSize: "0.8rem"
    }
}));

const DueDate = ({ time, collapsed = false }) => {
    const classes = useStyles();
    const setTime = dayjs(time);
    const current = dayjs(new Date());
    const relTime = setTime.endOf("day").fromNow();
    const diff = setTime.diff(current, "days", true);
    let relativeTimeString;
    if (diff > 0 && diff < 1) {
        relativeTimeString = "Today";
    } else if (diff > 1 && diff < 2) {
        relativeTimeString = "Tomorrow";
    } else {
        relativeTimeString = relTime;
    }
    return (
        <div>
            {!collapsed && (
                <Typography color={diff < -1 ? "error" : "primary"} component="span" className={classes.dates}>
                    <span className="d-none d-sm-inline-block">Due Date</span> {setTime.format("Do MMM YYYY")}{" "}
                </Typography>
            )}
            <Chip
                size="small"
                variant="outlined"
                icon={<FiberManualRecordIcon />}
                color={diff < -1 ? "error" : "primary"}
                label={relativeTimeString}
            />
        </div>
    );
};

export default DueDate;
