const PeopleList = () => {
    const people = [
        { img: "juedie.png", gender: "female" },
        { img: "craig.png", gender: "male" },
        { img: "dan.png", gender: "male" },
        { img: "steven.png", gender: "male" },
        { img: "lance.png", gender: "male" },
        { img: "janene.png", gender: "female" },
        { img: "tomo.png", gender: "male" },
        { img: "alex.png", gender: "male" },
        { img: "alka.png", gender: "female" },
        { img: "ariel.png", gender: "male" },
        { img: "baice.png", gender: "male" },
        { img: "deane.png", gender: "male" },
        { img: "deise.png", gender: "female" },
        { img: "denis.png", gender: "male" },
        { img: "errol.png", gender: "male" },
        { img: "fernanda.png", gender: "female" },
        { img: "felipe.png", gender: "male" },
        { img: "gabriel.png", gender: "male" },
        { img: "giulia.png", gender: "female" },
        { img: "howlin.png", gender: "male" },
        { img: "jade.png", gender: "male" },
        { img: "jerome.png", gender: "male" },
        { img: "jerwin.png", gender: "male" },
        { img: "julito.png", gender: "male" },
        { img: "jurie.png", gender: "male" },
        { img: "juzer.png", gender: "male" },
        { img: "kalros.png", gender: "male" },
        { img: "lenise.png", gender: "female" },
        { img: "lucas.png", gender: "male" },
        { img: "marcelo.png", gender: "male" },
        { img: "neil.png", gender: "male" },
        { img: "pamela.png", gender: "female" },
        { img: "paul.png", gender: "male" },
        { img: "phil.png", gender: "male" },
        { img: "renz.png", gender: "male" },
        { img: "renzo.png", gender: "male" },
        { img: "ruby.png", gender: "female" },
        { img: "shela.png", gender: "female" },
        { img: "tati.png", gender: "female" },
        { img: "thiago.png", gender: "male" },
        { img: "tom.png", gender: "male" },
        { img: "vinicius.png", gender: "male" },
        { img: "ben.png", gender: "male" },
        { img: "rafaela.png", gender: "female" },
        { img: "daniel.png", gender: "male" }
    ];
    const duplicatedPeopleList = [];
    for (let i = 0; i < 4; i++) {
        duplicatedPeopleList.push(...people);
    }
    return duplicatedPeopleList;
};

export default PeopleList;
