import React, { Component } from "react";
import OperationalFormModal from "./presenter";
import { axiosInstance } from "shared/axiosInst";
import FileUploadBackdrop from "components/FileUploadBackdrop";

class Container extends Component {
    constructor(props) {
        super(props);

        this.state = {
            queue: [],
            downloading: false
        };
    }

    componentDidUpdate = prevProps => {
        const { formModal, selectedJob, getOperationalFormList, operationalFormList } = this.props;
        if (formModal && formModal !== prevProps.formModal) {
            getOperationalFormList(selectedJob);
            this.setState({
                isLoading: true,
                checkTotal: false,
                elementChecked: undefined,
                mailList: [],
                isValid: undefined
            });
        }
        if (!formModal && prevProps.formModal) {
            this.setState({
                isLoading: true,
                checkTotal: false,
                elementChecked: undefined,
                mailList: [],
                isValid: undefined
            });
        }

        if (operationalFormList !== prevProps.operationalFormList) {
            this.setState({
                isLoading: false,
                nonLogList: operationalFormList.nonLogList.map(list => ({
                    ...list,
                    checked: false,
                    pending: false
                })),
                logList: operationalFormList.logList.map(list => ({
                    ...list,
                    checked: false,
                    pending: false
                }))
            });
        }
    };

    onCheckTotal = event => {
        const { nonLogList, logList } = this.state;
        const {
            target: { checked }
        } = event;
        this.setState(
            {
                checkTotal: checked,
                nonLogList: nonLogList.map(list => ({ ...list, checked: checked })),
                logList: logList.map(list => ({ ...list, checked: checked }))
            },
            () => this.findCheckedList()
        );
    };

    setupValid = valid => {
        this.setState({ mailList: valid.mailList, isValid: valid.isValid });
    };

    onOperationFormListCheck = (event, type) => {
        const {
            target: { name, checked }
        } = event;
        const splitedName = name.split("_")[1];
        this.setState(
            {
                [type]: this.state[type].map(list => (list.id === Number(splitedName) ? { ...list, checked: checked } : list))
            },
            () => {
                this.findCheckedList();
                const { nonLogList, logList } = this.state;
                this.setState({
                    checkTotal: !nonLogList.find(list => list.checked === false) && !logList.find(list => list.checked === false)
                });
            }
        );
    };

    signal = axiosInstance.CancelToken.source();

    downloadOperationalForm = e => {
        e.preventDefault();
        const {
            currentTarget: { value }
        } = e;
        const { downloadOperationalForm } = this.props;
        const { nonLogList, logList, queue } = this.state;
        queue.push(Number(value));
        this.setState(
            {
                nonLogList: nonLogList.map(list => ({
                    ...list,
                    pending: queue.find(que => que === list.id)
                })),
                logList: logList.map(list => ({
                    ...list,
                    pending: queue.find(que => que === list.id)
                })),
                downloading: true
            },
            () => {
                downloadOperationalForm([Number(value)], this.signal).then(value => {
                    if (value) {
                        if (value.message === "completed") {
                            queue.shift();
                            this.setState({
                                nonLogList: nonLogList.map(list => ({
                                    ...list,
                                    pending: queue.find(que => que === list.id)
                                })),
                                logList: logList.map(list => ({
                                    ...list,
                                    pending: queue.find(que => que === list.id)
                                })),
                                downloading: false
                            });
                        }
                    }
                });
            }
        );
    };

    findCheckedList = () => {
        const { nonLogList, logList } = this.state;
        this.setState({
            checkedList: [
                ...nonLogList.filter(list => list.checked).map(list => list.id),
                ...logList.filter(list => list.checked).map(list => list.id)
            ],
            elementChecked: !!nonLogList.find(list => list.checked) || !!logList.find(list => list.checked)
        });
    };

    fileDownload = id => {
        const { fileDownload } = this.props;
        fileDownload(id);
    };

    componentWillUnmount = () => {
        this.signal.cancel("Downloading(s) is cancelled by user.");
        document.body.style = undefined;
    };

    render = () => {
        const { formModal, toggleForm, selectedJob, isReady } = this.props;
        const { downloading } = this.state;
        return (
            <>
                <OperationalFormModal
                    {...this.state}
                    formModal={formModal}
                    selectedJob={selectedJob}
                    isReady={isReady}
                    toggleForm={toggleForm}
                    onCheckTotal={this.onCheckTotal}
                    onOperationFormListCheck={this.onOperationFormListCheck}
                    setupValid={this.setupValid}
                    downloadOperationalForm={this.downloadOperationalForm}
                    fileDownload={this.fileDownload}
                />
                <FileUploadBackdrop pendingLoading={downloading} comment="DOWNLOADING FORM..." />
            </>
        );
    };
}

export default Container;
