import React, { memo } from "react";
import { getColor } from "shared/Utils";
import dayjs from "shared/dayjs";

const AttributeList = props => {
    const companiesList = JSON.parse(localStorage.getItem("allCompanies"));
    const { element } = props;
    const regions = JSON.parse(localStorage.getItem("regions"));
    const isPending = element.pending && element.name === "Client";
    return (
        <li key={element.id} style={{ color: isPending ? "#d40000" : "" }}>
            {console.log(companiesList, "element", element)}
            {element.name} {isPending ? "(Pending)" : ""}
            {element.company ? `(${companiesList.find(company => company.id === element.company)?.company_prefix})` : ""}
            {element.companies?.length > 0
                ? `(${companiesList
                      .filter(company => element.companies.find(com => com === company.id))
                      .map(company => company.company_prefix)
                      .join(", ")})`
                : ""}
            {element.start_date || element.end_date ? (
                <span>{`${element.start_date ? dayjs(element.start_date).format("DD/MM/YYYY") : ""} ~ ${
                    element.end_date ? dayjs(element.end_date).format("DD/MM/YYYY") : ""
                }`}</span>
            ) : (
                ""
            )}
            {element.regions ? <span>{element.regions.map(reg => regions.find(region => region.id === reg).region_short_code).join(", ")}</span> : ""}
            {element.expiration_date ? <span>{` ~ ${dayjs(element.expiration_date).format("DD/MM/YYYY")}`}</span> : ""}
            {element.rating ? <span style={getColor(element.rating - 1)}>{element.rating}</span> : ""}
        </li>
    );
};

export default memo(AttributeList);
