import React from "react";
import Dialog from "@mui/material/Dialog";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import { makeStyles } from "@mui/styles";
import CircularProgress from "@mui/material/CircularProgress";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

const useStyles = makeStyles(theme => ({
    appBar: {
        position: "relative",
        background: "#f47920",
        boxShadow: "none"
    },
    title: {
        marginLeft: theme.spacing(0),
        flex: 1,
        display: "flex",
        alignItems: "center"
    },
    progress: {
        marginLeft: theme.spacing(1),
        color: "white"
    }
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const DialogContainer = ({
    title,
    open,
    handleClose,
    children,
    fullScreen = true,
    isLoading = false,
    fullWidth = true,
    maxWidth = "xl",
    closeOutside = true,
    ...rest
}) => {
    const classes = useStyles();
    const theme = useTheme();
    const usefullScreen = useMediaQuery(theme.breakpoints.down("sm"));
    return (
        <Dialog
            scroll="body"
            fullScreen={usefullScreen || fullScreen}
            maxWidth={maxWidth}
            fullWidth={fullWidth}
            open={open}
            onClose={closeOutside ? handleClose : null}
            TransitionComponent={Transition}
            {...rest}
        >
            <AppBar className={classes.appBar}>
                <Toolbar>
                    <Typography variant="h6" className={classes.title}>
                        <span>{title}</span> {isLoading && <CircularProgress size={24} className={classes.progress} />}
                    </Typography>
                    <IconButton color="inherit" onClick={handleClose} aria-label="close">
                        <CloseIcon />
                    </IconButton>
                </Toolbar>
            </AppBar>
            {children}
        </Dialog>
    );
};

export default DialogContainer;
