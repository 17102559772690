import React, { memo, useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { actionCreators as crmActions } from "redux/modules/crm";
import { actionCreators as userActions } from "redux/modules/user";
import { MDBRow, MDBCol, MDBBtn, MDBCard, MDBCardHeader, MDBCardBody } from "louis-mdbreact";
import ValidSelect from "components/ValidSelect";
import styles from "shared/details.scss";
import Loading from "components/Loading";

const Entity = ({ type, getSendingData, entityDetail, onEditClick, inJob, parent }) => {
    const dispatch = useDispatch();
    const { entityCommons, entityBaseData } = useSelector(state => state.crm);
    const [isLoading, setIsLoading] = useState(true);
    const [isValidated, setIsValidated] = useState(false);
    const { entity_type, responsibility } = entityCommons || {};
    const { entityData, groupassociationData } = entityBaseData || {};
    const [globalUsers, setGlobalUSers] = useState([]);
    const [sendingData, setSendingData] = useState(
        entityDetail
            ? {
                  ...entityDetail,
                  group_associations: entityDetail.group_association_details,
                  nzbn: entityDetail.nzbn || "",
                  parent: entityDetail.parent && {
                      id: entityDetail.parent,
                      name: entityDetail.parent_name_string
                  }
              }
            : {
                  entity_type: inJob === "contact" ? 5 : 1,
                  name: "",
                  short_name: "",
                  parent: "",
                  nzbn: "",
                  group_associations: "",
                  responsibilities: "",
                  gru: ""
              }
    );

    useEffect(() => {
        if (parent && inJob === "contact" && entityData) {
            setSendingData(currentSendingData => ({
                ...currentSendingData,
                parent: entityData.find(entity => entity.id === parent.value)
            }));
        }
    }, [parent, inJob, entityData]);

    useEffect(() => {
        if (!entityCommons) {
            dispatch(crmActions.getEntityCommons());
        }
    }, [dispatch, entityCommons, entityBaseData]);

    useEffect(() => {
        if (!entityBaseData) {
            dispatch(crmActions.getNewEntityBaseData());
        }
    }, [dispatch, entityBaseData]);

    useEffect(() => {
        if (entityCommons && entityBaseData) {
            setIsLoading(false);
        }
    }, [entityCommons, entityBaseData]);

    useEffect(() => {
        (async () => {
            const users = await dispatch(userActions.getSupervisors());
            setGlobalUSers(users.filter(user => user.active));
        })();
    }, [dispatch]);

    const makeSendingData = useCallback(sendingData => {
        return {
            ...sendingData,
            parent: sendingData.parent ? sendingData.parent.id : "",
            parent_name_string: sendingData.parent
                ? `${sendingData.parent.name}${sendingData.parent.short_name ? ` (${sendingData.parent.short_name})` : ""}`
                : undefined,
            responsibilities: sendingData.responsibilities ? sendingData.responsibilities.map(res => res.id) : undefined,
            group_associations: sendingData.group_associations ? sendingData.group_associations.map(association => association.id) : undefined,
            nzbn: sendingData.nzbn || undefined,
            pending: false
        };
    }, []);

    useEffect(() => {
        const goodToGo = !!(sendingData.name && sendingData.entity_type);
        type === "new" && getSendingData("entity", makeSendingData(sendingData), goodToGo);
    }, [sendingData, type, getSendingData, makeSendingData]);

    const onChangeValue = event => {
        const {
            target: { name, value }
        } = event;
        const newSendingData = { ...sendingData, [name]: value };
        setSendingData(newSendingData);
    };

    const onSelectChange = (name, option) => {
        let value = "";
        switch (name) {
            case "parent":
                value = option || "";
                break;
            case "default_followup_linings":
                value = option || null;
                break;
            case "gru":
                value = option || null;
                break;
            default:
                value = option || [];
                break;
        }
        const newSendingData = {
            ...sendingData,
            [name]: value
        };
        setSendingData(newSendingData);
    };

    const submitEntity = event => {
        setIsValidated(true);
        const goodToGo = !!(sendingData.name && sendingData.entity_type);
        if (goodToGo) {
            dispatch(crmActions.submitEntity(makeSendingData(sendingData))).then(value => {
                if (value && value.id) {
                    onEditClick(0);
                }
            });
        } else {
            alert("Please write down all required field.");
        }
        // console.log(sendingData);
    };

    return (
        <>
            {isLoading ? (
                <Loading loadingType="component" />
            ) : (
                <MDBCard className={`${styles.card} ${type === "view" ? styles.flatCard : ""} ${isValidated ? "was-validated" : ""}`}>
                    {type !== "view" && <MDBCardHeader>Entity Detail</MDBCardHeader>}
                    <MDBCardBody>
                        <MDBRow>
                            <MDBCol sm="6" className="mb-2">
                                <label htmlFor="entity_type">Entity Type</label>
                                <select
                                    id="entity_type"
                                    className="browser-default custom-select form-control"
                                    onChange={onChangeValue}
                                    name="entity_type"
                                    value={sendingData.entity_type}
                                    required
                                >
                                    {entity_type &&
                                        entity_type.map(entity => (
                                            <option key={entity.id} value={entity.id}>
                                                {entity.name}
                                            </option>
                                        ))}
                                </select>
                                <div className="invalid-feedback">Required</div>
                            </MDBCol>
                            <MDBCol sm="6" className="mb-2">
                                <label htmlFor="parent">Parent</label>
                                <ValidSelect
                                    options={entityData.filter(entity => entity.entity_type !== "Individual")}
                                    onChange={option => onSelectChange("parent", option)}
                                    value={sendingData.parent}
                                    isClearable
                                    getOptionValue={option => option.id}
                                    getOptionLabel={option => `${option.name} ${option.short_name ? `(${option.short_name})` : ""}`}
                                    name="parent"
                                />
                            </MDBCol>
                        </MDBRow>
                        <MDBRow>
                            <MDBCol sm="4" className="mb-2">
                                <label htmlFor="name">Name</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="name"
                                    name="name"
                                    value={sendingData.name}
                                    onChange={onChangeValue}
                                    required
                                />
                                <div className="invalid-feedback">Required</div>
                            </MDBCol>
                            <MDBCol sm="4" className="mb-2">
                                <label htmlFor="short_name">Short Name</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="short_name"
                                    name="short_name"
                                    value={sendingData.short_name}
                                    onChange={onChangeValue}
                                />
                            </MDBCol>
                            <MDBCol sm="4" className="mb-2">
                                <label htmlFor="nzbn">NZBN</label>
                                <input type="text" className="form-control" id="nzbn" name="nzbn" value={sendingData.nzbn} onChange={onChangeValue} />
                            </MDBCol>
                        </MDBRow>
                        <MDBRow>
                            <MDBCol sm="6" className="mb-2">
                                <label htmlFor="group_associations">Group Association</label>
                                <ValidSelect
                                    options={groupassociationData}
                                    isMulti
                                    onChange={option => onSelectChange("group_associations", option)}
                                    value={sendingData.group_associations}
                                    getOptionLabel={option => option.name}
                                    getOptionValue={option => option.id}
                                    // isDisabled={type !== "detail"}
                                    name="group_associations"
                                />
                            </MDBCol>
                            <MDBCol sm="6" className="mb-2">
                                <label htmlFor="responsibilities">Responsibilities</label>
                                <ValidSelect
                                    options={responsibility}
                                    isMulti
                                    onChange={option => onSelectChange("responsibilities", option)}
                                    value={sendingData.responsibilities}
                                    getOptionLabel={option => option.name}
                                    getOptionValue={option => option.id}
                                    // isDisabled={type !== "detail"}
                                    name="responsibilities"
                                />
                            </MDBCol>
                            <MDBCol sm="6" className="mb-2">
                                <label htmlFor="default_followup_linings">Default Followup</label>
                                <ValidSelect
                                    options={globalUsers}
                                    isClearable
                                    onChange={option => onSelectChange("default_followup_linings", option?.id || null)}
                                    value={globalUsers.find(user => user.id === sendingData.default_followup_linings)}
                                    getOptionLabel={option => option.name_string}
                                    getOptionValue={option => option.id}
                                    // isDisabled={type !== "detail"}
                                    name="default_followup_linings"
                                />
                            </MDBCol>
                            <MDBCol sm="6" className="mb-2">
                                <label htmlFor="gru">Team leader</label>
                                <ValidSelect
                                    options={globalUsers}
                                    isClearable
                                    onChange={option => onSelectChange("gru", option?.id || null)}
                                    value={globalUsers.find(user => user.id === sendingData.gru) || null}
                                    getOptionLabel={option => option.name_string}
                                    getOptionValue={option => option.id}
                                    // isDisabled={type !== "detail"}
                                    name="gru"
                                />
                            </MDBCol>
                        </MDBRow>
                        {type === "view" && (
                            <MDBRow>
                                <MDBCol>
                                    <div className={`${styles.card} float-right`}>
                                        <MDBBtn type="button" color="blue-grey" onClick={() => onEditClick(0)}>
                                            cancel
                                        </MDBBtn>{" "}
                                        <MDBBtn type="button" color="primary" onClick={submitEntity}>
                                            Save
                                        </MDBBtn>
                                    </div>
                                </MDBCol>
                            </MDBRow>
                        )}
                    </MDBCardBody>
                </MDBCard>
            )}
        </>
    );
};

export default memo(Entity);
