import React, { memo, useState } from "react";
import { Collapse, CardContent, CardActions } from "@mui/material";
import styles from "./styles.scss";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import { makeStyles } from "@mui/styles";
import Button from "@mui/material/Button";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import NoteFileList from "./NoteFileList";
import detailStyles from "shared/details.scss";

const useStyles = makeStyles(theme => ({
    root: {
        border: "1px solid rgba(0, 0, 0, 0.2)"
    }
}));

const Reply = ({ replies = [], count, opened = false }) => {
    const classes = useStyles();
    const [checked, setChecked] = useState(opened);
    const replyCount = count || replies.length;
    return (
        <>
            <CardActions>
                <Button startIcon={checked ? <ExpandLessIcon /> : <ExpandMoreIcon />} onClick={() => setChecked(!checked)} aria-label="show more">
                    {`${replyCount} ${replyCount > 1 ? "replies" : "reply"}`}
                </Button>
            </CardActions>
            <Collapse in={checked}>
                <CardContent style={{ paddingTop: 0 }}>
                    {replies?.map(reply => (
                        <div className={styles.replyItem} key={reply.id}>
                            <Alert icon={false} severity="info" className={classes.root}>
                                <AlertTitle>From {reply.created_by_name}</AlertTitle>
                                <div className={detailStyles.preWrap}>{reply.note}</div>
                                {reply.files_list?.length > 0 && <NoteFileList files={reply.files_list} />}
                            </Alert>
                        </div>
                    ))}
                </CardContent>
            </Collapse>
        </>
    );
};

export default memo(Reply);
