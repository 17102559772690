import React, { useState, memo } from "react";
import { MDBRow, MDBCol } from "louis-mdbreact";
import { FormControlLabel, Checkbox, Button, Card, CardContent } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Controller, useForm } from "react-hook-form";
import Select from "react-select";
import { useSelector, useDispatch } from "react-redux";
import { actionCreators as jobActions } from "redux/modules/jobs";
import { axiosInstance } from "shared/axiosInst";
import { getFileData } from "shared/Utils";
import styles from "./styles.scss";
import detailsStyles from "shared/details.scss";
import FileDrop from "components/FileDrop";
import NoteFileList from "./NoteFileList";
import NoteFileItem from "./NoteFileItem";
import CircularProgress from "@mui/material/CircularProgress";

const useStyles = makeStyles(theme => ({
    root: { position: "relative", background: "#f2f6fa", overflow: "inherit" }
}));

const CreateNote = ({ remove, note, title, pageId, onEditClick }) => {
    const dispatch = useDispatch();
    const classes = useStyles();
    const {
        user: { globalUsers, currentCompany },
        jobs: { detailData }
    } = useSelector(state => state);
    const [hasFollowUp] = useState(title === "Follow Ups");
    const wasFollowup = hasFollowUp || !!note.follow_up;
    const [isEdit] = useState(note.edit || false);
    const [isReply] = useState(note.reply || false);
    const [files, setFiles] = useState([]);
    const [isSubmiting, setIsSubmiting] = useState(false);
    let defaultValues = {
        note: "",
        job: pageId,
        check_follow: !isReply ? hasFollowUp : false,
        follow_up: !isReply && {
            assigned_to: detailData.internal_supervisor,
            due_date: undefined,
            also_notify: []
        }
    };
    if (isEdit) {
        if (hasFollowUp) {
            defaultValues = {
                ...defaultValues,
                id: note.note,
                note: note.note_text,
                check_follow: true,
                follow_up: {
                    note: note.note,
                    assigned_to: note.assigned_to,
                    due_date: note.due_date,
                    also_notify: note.also_notify
                }
            };
        } else {
            defaultValues = {
                ...defaultValues,
                ...note,
                check_follow: !!note.follow_up,
                follow_up: note.follow_up || {
                    assigned_to: detailData.internal_supervisor,
                    due_date: undefined,
                    also_notify: []
                }
            };
        }
    }
    const {
        control,
        register,
        handleSubmit,
        watch,
        formState: { errors }
    } = useForm({
        defaultValues
    });

    const isFollowUp = watch("check_follow");

    const filesAdded = event => {
        const {
            target: { files: targetFiles }
        } = event;
        Object.keys(targetFiles).forEach((file, i) => {
            setFiles(currentFile => [...currentFile, { new_file: targetFiles[file], idx: Number(new Date()) + i, file_category: 14 }]);
        });
    };

    const fileSubmit = async files => {
        return await Promise.all(
            files.map(async file => {
                const fd = getFileData(file, "approved_job", pageId);
                return axiosInstance.post(`${currentCompany}job/stored_file/`, fd).then(value => value.data.id);
            })
        );
    };

    const onSubmit = async data => {
        setIsSubmiting(true);
        if (isEdit) {
            const associated_files = await fileSubmit(files);
            const submitData = isFollowUp
                ? {
                      ...data,
                      files_list: undefined,
                      edit: undefined,
                      check_follow: undefined,
                      associated_files,
                      follow_up: {
                          note: wasFollowup ? data.id : undefined,
                          also_notify: data.follow_up.also_notify,
                          assigned_to: data.follow_up.assigned_to,
                          due_date: data.follow_up.due_date
                      }
                  }
                : {
                      ...data,
                      files_list: undefined,
                      follow_up: undefined,
                      edit: undefined,
                      associated_files
                  };
            axiosInstance.put(`${currentCompany}job/job_note/${data.id}/`, submitData).then(value => {
                const responseData = value.data;
                if (responseData) {
                    setIsSubmiting(false);
                    onEditClick(defaultValues.id);
                    dispatch(jobActions.getFollowupList(`?note__job=${pageId}`));
                    dispatch(jobActions.getLogList(`?job=${pageId}`));
                }
            });
        } else if (isReply) {
            const associated_files = await fileSubmit(files);
            const original = Number(String(note.note).split("_")[0]);
            const submitData = {
                ...data,
                original,
                follow_up: undefined,
                associated_files
            };
            axiosInstance.post(`${currentCompany}job/job_note/`, submitData).then(value => {
                const responseData = value.data;
                if (responseData) {
                    setIsSubmiting(false);
                    dispatch(jobActions.getFollowupList(`?note__job=${pageId}`));
                    dispatch(jobActions.getLogList(`?job=${pageId}`));
                }
                remove(note.idx);
            });
        } else {
            const associated_files = await fileSubmit(files);
            const submitData = isFollowUp ? { ...data, associated_files } : { ...data, follow_up: undefined, associated_files };
            axiosInstance.post(`${currentCompany}job/job_note/`, submitData).then(value => {
                const responseData = value.data;
                if (responseData) {
                    setIsSubmiting(false);
                    dispatch(jobActions.getFollowupList(`?note__job=${pageId}`));
                    dispatch(jobActions.getLogList(`?job=${pageId}`));
                }
                remove(note.idx);
            });
        }
    };

    const fileClickTrigger = e => {
        document.getElementById(`file_input_${note.idx || note.note}`).click();
    };

    const onDeleteFile = idx => {
        setFiles(files.filter(file => file.idx !== idx));
    };

    return (
        <Card className={`${isReply ? styles.replyFormContainer : ""} mb-2 ${classes.root}`} variant="outlined">
            <CardContent>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <MDBRow>
                        <MDBCol size="12">
                            <textarea
                                className={`form-control ${errors.note?.type === "required" ? "is-invalid" : ""}`}
                                rows="3"
                                {...register("note", { required: true })}
                            />
                            {isFollowUp ? (
                                <MDBRow>
                                    <MDBCol sm="4" className="mt-2">
                                        <label className={styles.noteLabel} htmlFor={`action-date-${note.idx}`}>
                                            Action Date
                                        </label>
                                        <input
                                            id={`action-date-${note.idx}`}
                                            className={`form-control ${errors.follow_up?.due_date?.type === "required" ? "is-invalid" : ""}`}
                                            type="date"
                                            {...register("follow_up.due_date", { required: true })}
                                        />
                                    </MDBCol>
                                    <MDBCol sm="4" className="mt-2">
                                        <label className={styles.noteLabel} htmlFor={`assign-to-${note.idx}`}>
                                            Primary Responsibility
                                        </label>
                                        <Controller
                                            control={control}
                                            name="follow_up.assigned_to"
                                            render={({ field: { onChange, value } }) => (
                                                <Select
                                                    id={`assign-to-${note.idx}`}
                                                    options={globalUsers}
                                                    getOptionValue={option => option.id}
                                                    getOptionLabel={option => option.name_string}
                                                    placeholder="Assign to..."
                                                    onChange={option => onChange(option.id)}
                                                    value={globalUsers.find(user => user.id === value)}
                                                />
                                            )}
                                        />
                                    </MDBCol>
                                    <MDBCol sm="4" className="mt-2">
                                        <label className={styles.noteLabel} htmlFor={`notify-${note.idx}`}>
                                            Inform
                                        </label>
                                        <Controller
                                            control={control}
                                            name="follow_up.also_notify"
                                            render={({ field: { onChange, value } }) => (
                                                <Select
                                                    id={`notify-${note.idx}`}
                                                    isMulti
                                                    options={globalUsers}
                                                    getOptionValue={option => option.id}
                                                    getOptionLabel={option => option.name_string}
                                                    placeholder="Notify..."
                                                    onChange={option => onChange(option?.map(opt => opt.id) || [])}
                                                    value={value?.map(val => globalUsers.find(user => user.id === val))}
                                                />
                                            )}
                                        />
                                    </MDBCol>
                                </MDBRow>
                            ) : (
                                ""
                            )}
                        </MDBCol>
                    </MDBRow>
                    <MDBRow>
                        <MDBCol className="mt-2">
                            <input
                                type="file"
                                className="d-none"
                                multiple
                                id={`file_input_${note.idx || note.note}`}
                                aria-describedby={`file_input`}
                                onChange={filesAdded}
                            />
                            <FileDrop
                                className={detailsStyles.fileDropContainer}
                                targetClassName={detailsStyles.fileDropTarget}
                                draggingOverFrameClassName={detailsStyles.draggingOverFrame}
                                draggingOverTargetClassName={detailsStyles.draggingOverTarget}
                                onClick={fileClickTrigger}
                                onDrop={(files, event) => {
                                    filesAdded({
                                        ...event,
                                        target: {
                                            ...event.target,
                                            files
                                        }
                                    });
                                }}
                            >
                                Drop some files here or click to select
                            </FileDrop>
                        </MDBCol>
                    </MDBRow>
                    <MDBRow>
                        <MDBCol>
                            {isEdit && <NoteFileList files={note.files_list} />}
                            {files.map(file => (
                                <NoteFileItem key={file.idx} file={file} deleteFile={onDeleteFile} />
                            ))}
                        </MDBCol>
                    </MDBRow>
                    <MDBRow>
                        <MDBCol className="mt-2" size="12">
                            {!isReply && (
                                <FormControlLabel
                                    control={
                                        <Controller
                                            control={control}
                                            name="check_follow"
                                            render={({ field: { value, onChange, ref } }) => (
                                                <Checkbox
                                                    disabled={note.edit && hasFollowUp}
                                                    color="primary"
                                                    checked={value}
                                                    onChange={onChange}
                                                    inputRef={ref}
                                                />
                                            )}
                                        />
                                    }
                                    label="Follow up"
                                    className="mb-0"
                                />
                            )}
                            <div className="float-right">
                                {!isSubmiting && (
                                    <Button
                                        className="mr-1"
                                        variant="outlined"
                                        size="small"
                                        color="secondary"
                                        onClick={() => {
                                            if (isEdit) {
                                                onEditClick(defaultValues.id);
                                            } else if (isReply) {
                                                remove(note.note);
                                            } else {
                                                remove(note.idx);
                                            }
                                        }}
                                    >
                                        {isEdit || isReply ? "cancel" : "remove"}
                                    </Button>
                                )}
                                <Button type="submit" variant="outlined" size="small" color="primary" disabled={isSubmiting}>
                                    {isSubmiting ? (
                                        <>
                                            <span>submiting</span>
                                            <CircularProgress className="ml-2" color="inherit" size={18} />
                                        </>
                                    ) : isEdit ? (
                                        "change"
                                    ) : (
                                        "save"
                                    )}
                                </Button>
                            </div>
                        </MDBCol>
                    </MDBRow>
                </form>
            </CardContent>
        </Card>
    );
};

export default memo(CreateNote);
