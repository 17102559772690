import React from "react";
import { MDBBtn, MDBIcon, MDBRow, MDBCol } from "louis-mdbreact";
import styles from "shared/details.scss";
import dayjs from "shared/dayjs";
import Rating from "@mui/material/Rating";
import Chip from "@mui/material/Chip";
import ValidSelect from "components/ValidSelect";
import { Box, MenuItem, Stack, TextField } from "@mui/material";

const AttributeElement = ({
    parent,
    element,
    onRatingChanged,
    onRegionChange,
    onRemoveAttribute,
    regions,
    onInputChange,
    type,
    submitLink,
    wasValidated,
    onApproveClick,
    administration,
    companiesList,
    onCompaniesChange
}) => {
    return (
        <React.Fragment key={element.id}>
            <tr className={styles.addItemContainer}>
                {parent !== "relationship" && (
                    <td data-th="Name">
                        {element.name === "Miscellaneous" ? (
                            <MDBRow>
                                <MDBCol>{element.name}</MDBCol>
                                <MDBCol>
                                    <input
                                        type="text"
                                        className="form-control form-control-sm"
                                        name="role_detail"
                                        value={element.role_detail}
                                        onChange={event => onInputChange(event, "role", element.id)}
                                    />
                                </MDBCol>
                            </MDBRow>
                        ) : (
                            element.name
                        )}
                    </td>
                )}
                {parent === "assignment" && (
                    <td data-th="Regions">
                        <Stack sx={{ width: "100%" }} direction={{ xs: "column", sm: "row" }} gap={1}>
                            <Box sx={{ width: "100%" }}>
                                <ValidSelect
                                    options={regions}
                                    isMulti
                                    name="regions"
                                    onChange={option => onRegionChange(option, element.id)}
                                    isValid={wasValidated && element.regions.length > 0}
                                    value={element.regions}
                                    getOptionLabel={option => `${option.region_name} (${option.region_short_code})`}
                                    getOptionValue={option => option.id}
                                />
                            </Box>
                            {element.name === "Maintenance" && (
                                <Box sx={{ width: "100%", position: "relative" }}>
                                    <ValidSelect
                                        title="Companies"
                                        options={companiesList}
                                        isMulti
                                        name="companies"
                                        onChange={option =>
                                            onCompaniesChange(
                                                option.map(opt => opt.id),
                                                element.id
                                            )
                                        }
                                        isValid={wasValidated && element.companies.length > 0}
                                        value={companiesList.filter(company => element.companies?.find(com => com === company.id))}
                                        getOptionLabel={option => `${option.company_string?.split("-")[0]}`}
                                        getOptionValue={option => option.id}
                                    />
                                </Box>
                            )}
                        </Stack>
                    </td>
                )}
                {parent === "relationship" && (
                    <React.Fragment>
                        <td
                            data-th="Name"
                            style={{
                                color: element.name === "Client" && element.pending ? "#d40000" : ""
                            }}
                        >
                            {element.name}{" "}
                            {element.name === "Client" && element.pending && administration ? (
                                <Chip
                                    color="primary"
                                    size="small"
                                    label="APPROVE"
                                    className={styles.newButtonInView}
                                    onClick={() => onApproveClick(element)}
                                />
                            ) : (
                                ""
                            )}
                        </td>
                        <td data-th="End Date">
                            {element.end_date && !element.isChanged ? (
                                dayjs(element.end_date).format("DD/MM/YYYY")
                            ) : (
                                <Stack sx={{ width: "100%" }} direction={{ xs: "column", sm: "row" }} gap={1}>
                                    <TextField
                                        fullWidth
                                        size="small"
                                        inputProps={{ sx: { padding: "7.5px 10px" } }}
                                        type="date"
                                        name="end_date"
                                        value={element.end_date}
                                        onChange={event => onInputChange(event, "relationship", element.timestamp)}
                                    />
                                    {element.name === "Employee" && (
                                        <TextField
                                            fullWidth
                                            label="Company"
                                            select
                                            name="company"
                                            value={element.company || ""}
                                            onChange={event => onInputChange(event, "relationship", element.timestamp)}
                                            InputLabelProps={{ shrink: true, sx: { zIndex: 0 } }}
                                            inputProps={{ sx: { padding: "7.5px 10px" } }}
                                        >
                                            {companiesList.map(company => (
                                                <MenuItem key={company.id} value={company.id}>
                                                    {company.company_string.split("-")[0]}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    )}
                                </Stack>
                            )}
                        </td>
                    </React.Fragment>
                )}
                {parent === "skill" && (
                    <td data-th="Key">
                        <Rating name={`${parent}-${element.id}`} value={element.rating} onChange={onRatingChanged} />
                    </td>
                )}
                {parent === "qualification" && (
                    <>
                        <td data-th="Reference">
                            <input
                                type="text"
                                className="form-control form-control-sm"
                                onChange={event => onInputChange(event, "qualification", element.id)}
                                value={element.qualification_reference}
                                name="qualification_reference"
                            />
                        </td>
                        <td data-th="Expiration">
                            <input
                                type="date"
                                className="form-control form-control-sm"
                                onChange={event => onInputChange(event, "qualification", element.id)}
                                name="expiration_date"
                                value={element.expiration_date}
                            />
                        </td>
                    </>
                )}
                <td data-th="Action" className="text-center">
                    {type === "view" ? (
                        <SubmitButton parent={parent} element={element} submitLink={submitLink} onRemoveAttribute={onRemoveAttribute} />
                    ) : (
                        <MDBBtn
                            className={styles.buttonSize}
                            color="danger"
                            size="sm"
                            name="remove"
                            onClick={e => {
                                onRemoveAttribute(parent, element);
                            }}
                        >
                            <MDBIcon icon="trash-alt" />
                        </MDBBtn>
                    )}
                </td>
            </tr>
        </React.Fragment>
    );
};

const SubmitButton = ({ parent, element, submitLink, onRemoveAttribute }) => {
    const isPlus = !element.originalId;
    let isPencil = false;
    if (parent !== "role" && element.originalId) {
        isPencil = true;
        if (parent === "relationship" && element.end_date && !element.isChanged) {
            isPencil = false;
        }
    } else {
        isPencil = false;
    }
    let isRemove = true;
    if (parent === "relationship" && element.end_date && !element.isChanged) {
        isRemove = false;
    }
    const plusButton = (
        <MDBBtn
            className={styles.buttonSize}
            color="primary"
            size="sm"
            name="add"
            onClick={e => {
                submitLink(parent, element);
            }}
        >
            <MDBIcon icon="save" />
        </MDBBtn>
    );
    const pencilButton = (
        <MDBBtn
            className={styles.buttonSize}
            color="secondary"
            size="sm"
            name="remove"
            onClick={e => {
                submitLink(parent, element);
            }}
        >
            {element.isLoading ? (
                <div className="spinner-border spinner-border-sm text-white" role="status">
                    <span className="sr-only">Loading...</span>
                </div>
            ) : (
                <MDBIcon icon="pencil-alt" />
            )}
        </MDBBtn>
    );
    const removeButton = (
        <MDBBtn
            className={styles.buttonSize}
            color="danger"
            size="sm"
            name="remove"
            onClick={e => {
                onRemoveAttribute(parent, element);
            }}
        >
            <MDBIcon icon="trash-alt" />
        </MDBBtn>
    );
    return (
        <React.Fragment>
            {isPlus && plusButton}
            {isPencil && pencilButton}
            {isRemove && removeButton}
        </React.Fragment>
    );
};

export default AttributeElement;
