import React from "react";
import Loading from "components/Loading";
import styles from "shared/listStyles.scss";
import healthSafetyStyles from "./styles.scss";
import CreateNewButton from "components/CreateNewButton";
import ListSwitch from "components/ListSwitch";
import { Button, Tooltip } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { MDBDataTable, MDBIcon, MDBBtn, MDBBtnGroup, MDBCard, MDBCardHeader, MDBCardBody, MDBRow, MDBCol } from "louis-mdbreact";
import { removeDoubleGap } from "shared/Utils";
import { Link } from "react-router-dom";
import dayjs from "dayjs";

const HealthSafety = props => {
    const {
        normalView: { healthSafety },
        downloadOperationalForm,
        toggleChangeView,
        healthSafetyFilters,
        formType,
        filterData,
        HSAllList = [],
        jobList = []
    } = props;
    const hasfilter = Object.keys(healthSafetyFilters).reduce((a, current) => {
        if (current !== "isOpen") {
            return !!healthSafetyFilters[current] || a;
        } else {
            return a || false;
        }
    }, false);
    const data = !healthSafety
        ? {
              columns: [
                  { label: "No.", field: "id", sort: "asc" },
                  { label: "Date", field: "date", sort: "asc" },
                  { label: "Job", field: "job_no", sort: "asc" },
                  { label: "Client", field: "client", sort: "asc" },
                  { label: "Site", field: "site", sort: "asc" },
                  { label: "Type", field: "form_type_string", sort: "asc" },
                  { label: "Supervisor", field: "supervisor", sort: "asc" },
                  { label: "QS", field: "qs", sort: "asc" },
                  { label: "Actions", field: "actions", sort: "disabled" }
              ],
              rows:
                  !props.loading &&
                  HSAllList.map(list => ({
                      id: list.id,
                      job_no: <span searchvalue={Number(list.job)}>{list.job}</span>,
                      date: `${dayjs(list.form_submitted_datetime).format("DD/MM/YYYY")}`,
                      search: `${list.job} ${list.id} ${list.supervisor_name_string || ""} ${list.form_submitted_by_name || ""}`,
                      client: list.client_name || "-",
                      site: removeDoubleGap(list.job_name || "-"),
                      form_type_string: list.form_type_string || "-",
                      supervisor: (
                          <Tooltip title={`SUBMIITED BY: ${list.form_submitted_by_name}`}>
                              <span>{list.supervisor_name_string || "-"}</span>
                          </Tooltip>
                      ),
                      qs: list.qs_name_string || "-",
                      actions: (
                          <MDBBtnGroup>
                              <Link to={`/operations/health/${list.id}`}>
                                  <MDBBtn color="primary" className={`${styles.buttonSize} ${styles.noRound}`}>
                                      <MDBIcon icon="info-circle" />
                                  </MDBBtn>
                              </Link>
                              <MDBBtn
                                  color="success"
                                  value={list.id}
                                  onClick={downloadOperationalForm}
                                  className={`${styles.buttonSize} ${styles.noRound}`}
                              >
                                  {list.pending ? (
                                      <div className="spinner-border spinner-border-sm" role="status">
                                          <span className="sr-only">Loading...</span>
                                      </div>
                                  ) : (
                                      <MDBIcon icon="file-download" />
                                  )}
                              </MDBBtn>
                          </MDBBtnGroup>
                      ),
                      rowClassName: list.qa_report_sent ? "yellow lighten-2" : ""
                  }))
          }
        : {
              columns: [
                  { label: "No.", field: "job_no", sort: "asc" },
                  { label: "Client", field: "client", sort: "asc" },
                  { label: "Site", field: "site", sort: "asc" },
                  { label: "Supervisor", field: "supervisor", sort: "asc" },
                  { label: "QS", field: "qs", sort: "asc" },
                  { label: "Details", field: "details", sort: "disabled" }
              ],
              rows:
                  !props.loading &&
                  jobList.map(job => ({
                      job_no: <span searchvalue={Number(job.id)}>{job.id}</span>,
                      search: `${job.id}`,
                      client: removeDoubleGap(job.client_string || job.resource_string || "-"),
                      site: removeDoubleGap(job.job_name || "-"),
                      supervisor: job.internal_supervisor_string || "-",
                      qs: job.internal_qs_string || "-",
                      details: (
                          <MDBBtnGroup>
                              <MDBBtn color="primary" className={`${styles.buttonSize} ${styles.noRound}`} onClick={props.toggleForm} value={job.id}>
                                  <MDBIcon icon="info-circle" />
                              </MDBBtn>
                          </MDBBtnGroup>
                      )
                  }))
          };
    return (
        <div className={styles.container}>
            <div className={styles.titleWrap}>
                <h2 className={`${styles.taskTitle}`}>
                    <MDBBtn type="button" color="primary" className={styles.buttonSize} onClick={toggleChangeView} size="sm">
                        <MDBIcon icon={healthSafety ? "align-justify" : "layer-group"} />
                    </MDBBtn>
                    {!healthSafety && (
                        <MDBIcon
                            className="orange-text"
                            style={{ cursor: "pointer" }}
                            onClick={props.toggleFilters}
                            icon="cog"
                            spin={healthSafetyFilters.isOpen}
                            fixed
                        />
                    )}
                    {!healthSafety ? "HEALTH & SAFETY" : `${props.titleName} (JOB)`}{" "}
                </h2>
                <CreateNewButton />
                {healthSafety && <ListSwitch isMine={props.isMine} isCurrent={props.isCurrent} changeList={props.changeList} listType="H&S" />}
            </div>
            {!healthSafety && hasfilter && !healthSafetyFilters.isOpen && (
                <div className="mb-4" style={{ marginTop: "-20px" }}>
                    <Button startIcon={<CloseIcon />} size="small" color="secondary" onClick={props.clearFilters}>
                        Clear filters
                    </Button>
                </div>
            )}
            {!healthSafety && healthSafetyFilters.isOpen ? (
                <MDBCard className="mb-5">
                    <MDBCardHeader>
                        Health & Safety Filter
                        <span className="float-right">
                            <Button className="ml-2" startIcon={<CloseIcon />} size="small" color="secondary" onClick={props.clearFilters}>
                                Clear filters
                            </Button>
                        </span>
                    </MDBCardHeader>
                    <MDBCardBody>
                        <MDBRow>
                            <MDBCol className="mb-2" sm="4">
                                <label htmlFor="form_type">Form Type</label>
                                <select
                                    className="custom-select custom-select-sm"
                                    value={healthSafetyFilters.form_type || ""}
                                    onChange={props.onChangeSetting}
                                    name="form_type"
                                    id="form_type"
                                >
                                    <option value="">Select...</option>
                                    {formType &&
                                        formType.map(type => (
                                            <option key={type.id} value={type.id}>
                                                {type.name}
                                            </option>
                                        ))}
                                </select>
                            </MDBCol>
                            <MDBCol className="mb-2" sm="4">
                                <label htmlFor="job__internal_qs">QS</label>
                                <select
                                    className="custom-select custom-select-sm"
                                    value={healthSafetyFilters.job__internal_qs || ""}
                                    onChange={props.onChangeSetting}
                                    name="job__internal_qs"
                                    id="job__internal_qs"
                                >
                                    <option value="">Select...</option>
                                    {filterData?.qsUsers.map(user => (
                                        <option key={user.id} value={user.id}>
                                            {user.name_string}
                                        </option>
                                    ))}
                                </select>
                            </MDBCol>
                            <MDBCol className="mb-2" sm="4">
                                <label htmlFor="job__internal_supervisor">Supervisor</label>
                                <select
                                    className="custom-select custom-select-sm"
                                    value={healthSafetyFilters.job__internal_supervisor || ""}
                                    onChange={props.onChangeSetting}
                                    name="job__internal_supervisor"
                                    id="job__internal_supervisor"
                                >
                                    <option value="">Select...</option>
                                    {filterData?.supervisors.map(user => (
                                        <option key={user.id} value={user.id}>
                                            {user.name_string}
                                        </option>
                                    ))}
                                </select>
                            </MDBCol>
                        </MDBRow>
                    </MDBCardBody>
                </MDBCard>
            ) : undefined}
            {props.loading ? (
                <Loading loadingType="list" />
            ) : (
                <MDBDataTable
                    entriesOptions={[10, 20, 50, 100, 500]}
                    entries={20}
                    responsive
                    borderless
                    striped
                    responsiveSm
                    pagesAmount={5}
                    data={data}
                    sortRows={["job_no"]}
                    searchText={props.searchText}
                    initialPage={props.activePage}
                    onSearch={props.onSearch}
                    onPageChange={props.onPageChange}
                    className={`${!healthSafety ? healthSafetyStyles.dataTable : styles.jobsDataTable} ${styles.dataTable}`}
                />
            )}
        </div>
    );
};

export default HealthSafety;
