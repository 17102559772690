import { createTheme } from "@mui/material/styles";
import { stringToColor } from "shared/Utils";
import { blueGrey } from "@mui/material/colors";

const getWorkerTheme = services => {
    const colorScheme = services.reduce((result, item) => {
        let colorCode = {};
        switch (item.name) {
            case "Penetrations - Plumbing":
                colorCode = { main: "#66bb6a", dark: "#66bb6a", contrastText: "#fff" };
                break;
            case "Penetrations - Electrical":
                colorCode = { main: "#ef5350", dark: "#ef5350", contrastText: "#fff" };
                break;
            case "Penetrations - Data Cables":
                colorCode = { main: "#29b6f6", dark: "#29b6f6", contrastText: "#fff" };
                break;
            case "Penetrations - Fire Cables":
                colorCode = { main: "#ffee58", dark: "#ffee58", contrastText: "#000" };
                break;
            default:
                colorCode = { main: stringToColor(item.name), dark: stringToColor(item.name), contrastText: "#fff" };
                break;
        }
        result[item.name] = colorCode;
        return result;
    }, {});
    return createTheme({
        palette: { ...colorScheme, black: { main: blueGrey[800], dark: blueGrey[900], contrastText: "#fff" } }
    });
};

export { getWorkerTheme };
