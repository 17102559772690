import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { MDBBtn, MDBBtnGroup } from "louis-mdbreact";
import { actionCreators as jobActions } from "redux/modules/jobs";
import styles from "./styles.scss";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { ReplaceFileList, IdleFileList, NewFileList } from "./FileList";
import FileUploadBackdrop from "components/FileUploadBackdrop";
import { Box, ToggleButton, ToggleButtonGroup } from "@mui/material";

const getCategory = list => {
    if (list.length === 0) return [];
    return list.reduce(
        (acc, { file_category, file_category_string }) => {
            if (!acc.seen.has(file_category)) {
                acc.seen.add(file_category);
                acc.result.push({ file_category, file_category_string });
            }
            return acc;
        },
        { seen: new Set(), result: [{ file_category: "", file_category_string: "see all" }] }
    ).result;
};

const FileComponent = ({ detailData, fileDownload }) => {
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up("sm"));
    const dispatch = useDispatch();
    const [canUpload, setCanUpload] = useState(false);
    const [isUploading, setIsUploading] = useState(false);
    const [progress, setProgress] = useState(0);
    const [newFileList, setNewFileList] = useState([]);
    const [fileList, setFileList] = useState(detailData.file.map((f, i) => ({ ...f, timestamp: Number(new Date()) + i, mode: "idle" })));
    const [selectedCategory, setSelectedCategory] = useState("");
    const [fileCategory, setFileCategory] = useState(getCategory(fileList));

    useEffect(() => {
        setCanUpload(!!fileList.find(file => file.mode === "replace") || newFileList.length > 0);
    }, [newFileList, fileList]);

    const addFile = event => {
        setNewFileList([...newFileList, { timestamp: Number(new Date()), new_file: "", file_category: 0, mode: "new" }]);
    };

    const makeReplace = timestamp => {
        setFileList(
            fileList.map(file =>
                file.timestamp === timestamp
                    ? {
                          ...file,
                          mode: file.mode === "replace" ? "idle" : "replace",
                          new_file: file.mode === "replace" ? "" : file.new_file
                      }
                    : file
            )
        );
    };

    const removeList = timestamp => {
        setNewFileList(newFileList.filter(file => file.timestamp !== timestamp));
    };

    const fileChange = ({ new_file, timestamp, listType = null }) => {
        const [fileListState, setFileListState] = listType === "new" ? [newFileList, setNewFileList] : [fileList, setFileList];
        setFileListState(fileListState.map(f => (f.timestamp === timestamp ? { ...f, new_file } : f)));
    };

    const selectCategory = (file_category, timestamp) => {
        setNewFileList(newFileList.map(file => (file.timestamp === timestamp ? { ...file, file_category } : file)));
    };

    const submitFiles = event => {
        event.preventDefault();
        const replaceFilesData = fileList
            .filter(file => file.mode === "replace")
            .map(file => ({
                new_file: file.new_file,
                file_category: file.file_category,
                originalId: file.id || undefined
            }));
        const newFilesData = newFileList.map(file => ({
            new_file: file.new_file,
            file_category: file.file_category
        }));
        const filesData = [...replaceFilesData, ...newFilesData];
        if (filesData.find(file => !file.new_file) || filesData.find(file => file.file_category === 0)) {
            alert("Please choose file(s) you want to upload or check file category if it is a new file.");
        } else {
            setIsUploading(true);
            dispatch(
                jobActions.postFiles({
                    files: filesData,
                    jobType: "approved_job",
                    jobId: detailData.id,
                    uploadProgress: progress => {
                        setProgress(progress);
                    }
                })
            ).then(value => {
                const filteredList = fileList.filter(file => !(file.mode === "replace"));
                const newList = value.map((val, i) => ({
                    ...val.data,
                    timestamp: Number(new Date()) + i
                }));
                const newFilteredList = [...newList, ...filteredList];
                setFileCategory(getCategory(newFilteredList));
                setNewFileList([]);
                setFileList(newFilteredList);
                setIsUploading(false);
            });
        }
    };
    return (
        <div>
            <h4 className="mb-0" style={{ fontSize: "1rem" }}>
                Files{" "}
                <MDBBtnGroup size="sm">
                    <MDBBtn color="primary" onClick={addFile} disabled={isUploading}>
                        ADD File
                    </MDBBtn>
                    {canUpload ? (
                        <>
                            {isUploading ? (
                                <MDBBtn color="secondary">
                                    <div className="spinner-border spinner-border-sm text-white" role="status">
                                        <span className="sr-only">Loading...</span>
                                    </div>
                                </MDBBtn>
                            ) : (
                                <MDBBtn color="secondary" onClick={submitFiles}>
                                    upload all
                                </MDBBtn>
                            )}
                        </>
                    ) : (
                        ""
                    )}
                </MDBBtnGroup>
            </h4>
            {fileCategory.length > 0 && (
                <Box marginY={2}>
                    <ToggleButtonGroup
                        orientation={`${matches ? "horizontal" : "vertical"}`}
                        color="primary"
                        size="small"
                        fullWidth
                        value={selectedCategory}
                        exclusive
                        onChange={(e, newCategory) => {
                            setSelectedCategory(newCategory);
                        }}
                    >
                        {fileCategory.map(category => (
                            <ToggleButton size="small" key={category.file_category} value={category.file_category}>
                                {category.file_category_string}
                            </ToggleButton>
                        ))}
                    </ToggleButtonGroup>
                </Box>
            )}
            {newFileList.length > 0 && (
                <ul className={`${styles.fileListContainer} mb-2`}>
                    {newFileList.map(f => (
                        <NewFileList key={f.timestamp} file={f} removeList={removeList} fileChange={fileChange} selectCategory={selectCategory} />
                    ))}
                </ul>
            )}
            <ul className={`${styles.fileListContainer} mb-0`}>
                {fileList
                    .filter(file => !selectedCategory || file.file_category === selectedCategory)
                    .map(f => {
                        if (f.mode === "replace") {
                            return (
                                <ReplaceFileList
                                    key={f.timestamp}
                                    file={f}
                                    fileDownload={fileDownload}
                                    makeReplace={makeReplace}
                                    fileChange={fileChange}
                                />
                            );
                        } else {
                            return <IdleFileList key={f.timestamp} file={f} fileDownload={fileDownload} makeReplace={makeReplace} />;
                        }
                    })}
            </ul>
            <FileUploadBackdrop pendingLoading={isUploading} progress={progress} />
        </div>
    );
};

export default FileComponent;
