import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { routerMiddleware } from "connected-react-router";
import history from "shared/history";
import { composeWithDevTools } from "redux-devtools-extension";
import Cookies from "js-cookie";
import { axiosInstance } from "shared/axiosInst";
import reducer from "redux/reducers";
//import { parseJwt } from "shared/Utils";

const env = process.env.NODE_ENV;

const applyTokenMiddleware = store => next => async action => {
    const { ref_token, acc_token } = Cookies.get();
    axiosInstance.defaults.headers.Authorization = `Bearer ${acc_token}`;
    if (!ref_token) {
        Cookies.remove("acc_token", { path: "/" });
        store.getState().user = {
            ...store.getState().user,
            isLoggedIn: false,
            acc_token: ""
        };
    }

    // const current = new Date();
    // if (acc_token && Number(parseJwt(acc_token)) - Number(current) > 700) {
    //     axiosInstance
    //         .post(
    //             "token/refresh/",
    //             { refresh: ref_token },
    //             {
    //                 headers: {
    //                     Authorization: `Bearer ${ref_token}`
    //                 }
    //             }
    //         )
    //         .then(response => {
    //             const {
    //                 data: { access }
    //             } = response;
    //             Cookies.set("acc_token", access, {
    //                 expires: parseJwt(access),
    //                 path: "/",
    //                 secure: true,
    //                 sameSite: "Lax"
    //             });
    //         });
    // }
    next(action);
};

const middlewares = [thunk, routerMiddleware(history), applyTokenMiddleware];

if (env === "development") {
    const { logger } = require("redux-logger");
    middlewares.push(logger);
}

// if the evironment is development, add DevTool
let store;
if (env === "development") {
    store = createStore(reducer, composeWithDevTools(applyMiddleware(...middlewares)));
} else {
    store = createStore(reducer, applyMiddleware(...middlewares));
}

export default store;
