import React, { useEffect, useState, useCallback, memo } from "react";
import { MDBRow, MDBCol } from "louis-mdbreact";
import CollapsedView from "components/CollapsedView";
import styles from "shared/details.scss";
import { useSelector } from "react-redux";
import history from "shared/history";

import CreateNote from "./CreateNote";
import Note from "./Note";

const CommunicationsLog = ({ log, onEditClick, remove, title, pageId, onReplyClick }) => {
    if (log.edit) {
        return <CreateNote note={log} onEditClick={onEditClick} title={title} pageId={pageId} />;
    } else if (log.reply) {
        return (
            <CreateNote
                note={log}
                onEditClick={onEditClick}
                title={title}
                pageId={pageId}
                remove={remove}
            />
        );
    } else {
        return (
            <Note log={log} title={title} onEditClick={onEditClick} onReplyClick={onReplyClick} />
        );
    }
};

const CommunicationsLogs = ({ idx, isCollapsed, toggleCollapsed, title, pageId }) => {
    const [createdNoteList, setCreatedNoteList] = useState([]);
    const {
        jobs: { logList, followupList }
    } = useSelector(state => state);
    const [logs, setLogs] = useState([]);
    useEffect(() => {
        if (logList?.length > 0 || followupList?.length > 0) {
            setLogs(
                title === "Follow Ups"
                    ? followupList?.map(list => ({ ...list, edit: false }))
                    : logList?.map(list => ({ ...list, edit: false }))
            );
        }
    }, [logList, followupList, title]);

    useEffect(() => {
        if (history.location.hash) {
            const elem = document.querySelector(history.location.hash);
            elem && elem.scrollIntoView();
        }
    }, []);
    const onAddNoteClick = useCallback(() => {
        setCreatedNoteList(current => [{ idx: Number(new Date()) }, ...current]);
    }, []);

    const remove = useCallback(idx => {
        setCreatedNoteList(current => current.filter(cur => cur.idx !== idx));
    }, []);
    const removeReply = noteNum => {
        setLogs(current => current.filter(cur => cur.note !== noteNum));
    };
    const onEditClick = useCallback(
        id => {
            setLogs(current =>
                current.map(log => {
                    const selected = title === "Follow Ups" ? log.note === id : log.id === id;
                    if (selected) {
                        return { ...log, edit: !log.edit };
                    } else {
                        return log;
                    }
                })
            );
        },
        [title]
    );
    const onReplyClick = noteNum => {
        const newLogs = [].concat(logs);
        const index = newLogs.findIndex(l => {
            return l.note === noteNum;
        });
        newLogs.splice(index + 1, 0, {
            note: `${noteNum}_${Number(new Date())}`,
            reply: true,
            edit: false
        });
        setLogs(newLogs);
    };
    return (
        <MDBRow className={`${styles.card} mt-3`}>
            <MDBCol>
                <CollapsedView
                    idx={idx}
                    toggleCollapsed={toggleCollapsed}
                    isCollapsed={isCollapsed}
                    title={title}
                    onAddNoteClick={e => {
                        onAddNoteClick();
                        toggleCollapsed(idx, true);
                    }}
                    creatable={true}
                >
                    {createdNoteList.map((note, index) => (
                        <CreateNote
                            key={note.idx}
                            note={note}
                            index={index}
                            remove={remove}
                            title={title}
                            pageId={pageId}
                        />
                    ))}
                    {logs?.map(log => (
                        <CommunicationsLog
                            key={log.id || log.note}
                            log={log}
                            onEditClick={onEditClick}
                            title={title}
                            pageId={pageId}
                            remove={removeReply}
                            onReplyClick={onReplyClick}
                        />
                    ))}
                </CollapsedView>
            </MDBCol>
        </MDBRow>
    );
};

export default memo(CommunicationsLogs);
