import React from "react";
import { TableCell } from "@mui/material";
import itemStyles from "./styles.scss";

import withNoteModal from "./hoc/withNoteModal";
import NoteTable from "./NoteTable";

const ExpandedStageItem = ({ stage, onAddNoteClick }) => {
    return stage.noteOpen ? (
        <NoteTable note={stage.follow_up_note} followUpNotes={stage.follow_up_notes} company={stage.company} onAddNoteClick={onAddNoteClick} />
    ) : (
        <TableCell className={itemStyles.noData} colSpan={8} sx={{ padding: 0, margin: 0, lineHeight: 0, border: "none", height: "1px" }}></TableCell>
    );
};

export default withNoteModal(ExpandedStageItem);
