import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import Diary from "components/Ops/Diary";
import DiaryDetail from "components/Ops/DiaryDetail";
import PreQA from "components/Ops/PreQA";
import PreQaDetail from "components/Ops/PreQADetail";
import QualityAssurance from "components/Ops/QualityAssurance";
import HealthSafety from "components/Ops/HealthSafety";
import HealthSafetyDetail from "components/Ops/HealthSafetyDetail";

const OperationRoute = ({ permissions }) => {
    return (
        <Switch>
            <Route exact path="/operations" component={HealthSafety} />
            <Route exact path="/operations/diary" component={Diary} />
            <Route path="/operations/diary/new" key="new" component={DiaryDetail} />
            <Route path="/operations/diary/:id" key="edit" component={DiaryDetail} />
            <Route exact path="/operations/preqa" component={PreQA} />
            <Route path="/operations/preqa/new" key="pre_new" render={props => <PreQaDetail {...props} title="Pre QA" />} />
            <Route path="/operations/preqa/:id" key="pre_edit" render={props => <PreQaDetail {...props} title="Pre QA" />} />
            <Route exact path="/operations/qa" component={QualityAssurance} />
            <Route path="/operations/qa/new" key="qa_new" render={props => <PreQaDetail {...props} title="QA" />} />
            <Route path="/operations/qa/:id" key="qa_edit" render={props => <PreQaDetail {...props} title="QA" />} />
            <Route exact path="/operations/health" component={HealthSafety} />
            {permissions.operations === 4 || permissions.operations === 3 ? (
                <Route path="/operations/health/new" key="new" component={HealthSafetyDetail} />
            ) : (
                <Redirect from="/operations/health/new" to="/operations/health" />
            )}
            <Route exact path="/operations/health/:id" key="edit" component={HealthSafetyDetail} />
        </Switch>
    );
};

export default OperationRoute;
