import React, { Component } from "react";
import TimesheetView from "./presenter";
import dayjs from "shared/dayjs";
import { axiosInstance } from "shared/axiosInst";
import { getLastPayPeriod } from "shared/Utils";
import { Helmet } from "react-helmet";

class Container extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            sendLoading: false,
            selectedWorker: "",
            startDateRange: getLastPayPeriod().start,
            endDateRange: getLastPayPeriod().end,
            listLoading: false,
            total_hours: 0,
            total_hours_by_job: [],
            showAdminSetting: false,
            payrollPeriod: getLastPayPeriod()
        };
    }

    static defaultProps = {
        permissions: {
            tasks: 0
        },
        isAdmin: false
    };

    componentDidMount = () => {
        const { timesheetWorkers, getTimesheetWorkers } = this.props;
        if (!timesheetWorkers) {
            getTimesheetWorkers();
        } else {
            this.setState({ isLoading: false });
        }
    };

    componentDidUpdate = prevProps => {
        const { timesheetWorkers, timesheetList } = this.props;
        const { selectedWorker } = this.state;
        if (prevProps.timesheetWorkers !== timesheetWorkers) {
            this.setState({ isLoading: false });
        }

        if (prevProps.timesheetList !== timesheetList) {
            const total_hours_by_job = this.getTotalHoursByJob(
                timesheetList
                    ? timesheetList.map(item => ({
                          job_id: item.job,
                          hours: Number(item.hours_worked)
                      }))
                    : []
            );
            let total_overtime_hours;
            let total_overtime_hours_by_job;
            if (!selectedWorker.is_subcontractor) {
                total_overtime_hours =
                    timesheetList &&
                    timesheetList.reduce((total, list) => {
                        return (Number(total) + Number(list.overtime_hours_worked)).toFixed(2);
                    }, "0");
                total_overtime_hours_by_job = this.getTotalHoursByJob(
                    timesheetList
                        ? timesheetList.map(item => ({
                              job_id: item.job,
                              hours: Number(item.overtime_hours_worked)
                          }))
                        : []
                );
            } else {
                total_overtime_hours = null;
                total_overtime_hours_by_job = null;
            }

            const total_hours =
                timesheetList &&
                timesheetList.reduce((total, list) => {
                    return (Number(total) + Number(list.hours_worked)).toFixed(2);
                }, "0");
            this.setState({
                total_hours,
                total_hours_by_job,
                total_overtime_hours,
                total_overtime_hours_by_job,
                listLoading: false
            });
        }
    };

    getTotalHoursByJob = arrTotalHours => {
        return arrTotalHours.reduce((acc, cur) => {
            const pertinent = acc.find(a => cur.job_id === a.job_id);
            if (pertinent) {
                pertinent.hours += cur.hours;
            } else {
                acc.push({
                    job_id: cur.job_id,
                    hours: cur.hours
                });
            }
            return acc;
        }, []);
    };

    onWorkerChange = option => {
        this.setState(
            {
                selectedWorker: option
            },
            () => this.getTimesheet()
        );
    };

    onDateChange = event => {
        const {
            target: { name, value }
        } = event;
        this.setState(
            {
                [name]: value
            },
            () => this.getTimesheet()
        );
    };

    getTimesheet = () => {
        const { getTimesheetList, removeTimesheetList } = this.props;
        const { selectedWorker, startDateRange, endDateRange } = this.state;
        const dateNumbers = {
            start: Number(new Date(startDateRange)),
            end: Number(new Date(endDateRange))
        };
        const dateRegex = /^(19[0-9]{2}|2[0-9]{3})-(0[1-9]|1[012])-([123]0|[012][1-9]|31)$/;
        if (
            selectedWorker &&
            startDateRange &&
            endDateRange &&
            dateNumbers.start > 0 &&
            dateNumbers.end > 0 &&
            dateNumbers.end - dateNumbers.start >= 0 &&
            dateRegex.test(startDateRange) &&
            dateRegex.test(endDateRange)
        ) {
            this.setState({
                listLoading: true
            });
            removeTimesheetList();
            getTimesheetList({
                selectedWorker: selectedWorker.value,
                startDateRange,
                endDateRange
            });
        }
    };

    timeToggle = event => {
        const {
            target: { value }
        } = event;
        const { timesheetList } = this.props;
        const { selectedWorker } = this.state;
        const selectedList = timesheetList.find(list => list.id === Number(value));
        const loggedRequests = { startDateRange: "", endDateRange: "" };
        const id = selectedList.maintenance_job;
        const worker = {
            id: value,
            maintenance_job: id,
            entity: selectedWorker.value,
            name: selectedWorker.label,
            work_date: dayjs(selectedList.work_date).format("YYYY-MM-DD"),
            hours_worked: selectedList.hours_worked,
            cost_rate: selectedList.cost_rate,
            overtime_hours_worked: selectedList.overtime_hours_worked
        };

        const { getTimesheetInfo } = this.props;
        getTimesheetInfo("modify", worker, { ...loggedRequests, id });
    };

    toggleAdmin = event => {
        this.setState({ showAdminSetting: !this.state.showAdminSetting });
    };

    sendingPayroll = event => {
        const {
            currentTarget: { name }
        } = event;

        const {
            payrollPeriod: { start, end }
        } = this.state;
        const { currentCompany } = this.props;
        if (!start || !end) {
            alert("Please put dates in the period");
            return false;
        }

        let url;
        const prefixUrl = currentCompany === "linings-wbop/" ? "maintenance_staff_pay" : "staff_pay";
        if (name === "report") {
            url = `crm/${prefixUrl}/job_cost/${start}/${end}/`;
        } else if (name === "summary") {
            url = `crm/${prefixUrl}/payroll_summary/${start}/${end}/`;
        } else if (name === "subcontractor_payroll") {
            url = `crm/${prefixUrl}/subcontractor_job_cost/${start}/${end}/`;
        }

        this.setState({
            sendLoading: true
        });

        axiosInstance.post(url).then(values => {
            if (values.status === 204) {
                this.setState({
                    sendLoading: false,
                    payrollPeriod: getLastPayPeriod()
                });
            }
        });
    };

    onPeriodChange = event => {
        const {
            target: { name, value }
        } = event;
        this.setState({
            payrollPeriod: {
                ...this.state.payrollPeriod,
                [name]: value
            }
        });
    };

    componentWillUnmount() {
        const { removeTimesheetList } = this.props;
        removeTimesheetList();
    }

    render = () => {
        const { timesheetWorkers, timesheetList, permissions, isAdmin } = this.props;
        return (
            <>
                <Helmet>
                    <title>Tasks | Timesheet</title>
                </Helmet>
                <TimesheetView
                    {...this.state}
                    permissions={permissions}
                    isAdmin={isAdmin}
                    timesheetWorkers={timesheetWorkers}
                    timesheetList={timesheetList}
                    onWorkerChange={this.onWorkerChange}
                    onDateChange={this.onDateChange}
                    toggleAdmin={this.toggleAdmin}
                    timeToggle={this.timeToggle}
                    sendingPayroll={this.sendingPayroll}
                    onPeriodChange={this.onPeriodChange}
                />
            </>
        );
    };
}

export default Container;
