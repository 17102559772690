import React from "react";
import { NavLink, Link } from "react-router-dom";
import styles from "shared/headNavStyles.scss";
import { MDBIcon } from "louis-mdbreact";

const OperationDependPermission = props => {
    const { permissionLevel, toggleNavbar } = props;
    switch (permissionLevel) {
        case 4:
        case 3:
            return (
                <>
                    {/* <li>
                        <NavLink exact activeClassName={styles.active} to={`/operations/diary`} onClick={toggleNavbar}>
                            <MDBIcon far icon="edit" /> <span>Site Diary</span>
                        </NavLink>
                        <Link className={styles.new} to={`/operations/diary/new`} onClick={toggleNavbar}>
                            <MDBIcon far icon="plus-square" />
                        </Link>
                    </li>
                    <li>
                        <NavLink exact activeClassName={styles.active} to={`/operations/preqa`} onClick={toggleNavbar}>
                            <MDBIcon far icon="check-circle" /> <span>Pre QA</span>
                        </NavLink>
                        <Link className={styles.new} to={`/operations/preqa/new`} onClick={toggleNavbar}>
                            <MDBIcon far icon="plus-square" />
                        </Link>
                    </li>
                    <li>
                        <NavLink exact activeClassName={styles.active} to={`/operations/qa`} onClick={toggleNavbar}>
                            <MDBIcon far icon="calendar-check" /> <span>QA Report</span>
                        </NavLink>
                        <Link className={styles.new} to={`/operations/qa/new`} onClick={toggleNavbar}>
                            <MDBIcon far icon="plus-square" />
                        </Link>
                    </li> */}
                    <li>
                        <NavLink exact activeClassName={styles.active} to={`/operations/health`} onClick={toggleNavbar}>
                            <MDBIcon icon="hard-hat" /> <span>H&S Report</span>
                        </NavLink>
                        <Link className={styles.new} to={`/operations/health/new`} onClick={toggleNavbar}>
                            <MDBIcon far icon="plus-square" />
                        </Link>
                    </li>
                </>
            );
        default:
            return (
                <li>
                    <NavLink exact activeClassName={styles.active} to={`/operations/health`} onClick={toggleNavbar}>
                        <MDBIcon icon="hard-hat" /> <span>H&S Report</span>
                    </NavLink>
                </li>
            );
    }
};

export default OperationDependPermission;
