import React, { Component } from "react";
import QAReportModal from "./presenter";
import { axiosInstance } from "shared/axiosInst";

class Container extends Component {
    constructor(props) {
        super(props);

        this.state = {
            queue: []
        };
    }

    componentDidUpdate = prevProps => {
        const { QAModal, selectedJob, getQAList, QAList } = this.props;
        if (QAModal && QAModal !== prevProps.QAModal) {
            getQAList(selectedJob);
            this.setState({
                isLoading: true,
                checkTotal: false,
                elementChecked: undefined,
                mailList: [],
                isValid: undefined
            });
        }
        if (!QAModal && prevProps.QAModal) {
            this.setState({
                isLoading: true,
                checkTotal: false,
                elementChecked: undefined,
                mailList: [],
                isValid: undefined
            });
        }

        if (QAList !== prevProps.QAList) {
            this.setState({
                isLoading: false,
                QAList: QAList.map(list => ({ ...list, checked: false, pending: false }))
            });
        }
    };

    onCheckTotal = event => {
        const { QAList } = this.state;
        const {
            target: { checked }
        } = event;
        this.setState(
            {
                checkTotal: checked,
                QAList: QAList.map(list => ({ ...list, checked: checked }))
            },
            () => this.findCheckedList()
        );
    };

    onQAListCheck = event => {
        const { QAList } = this.state;
        const {
            target: { name, checked }
        } = event;
        const splitedName = name.split("_")[1];
        this.setState(
            {
                QAList: QAList.map(list =>
                    list.id === Number(splitedName) ? { ...list, checked: checked } : list
                )
            },
            () => {
                this.findCheckedList();
                const { QAList } = this.state;
                this.setState({
                    checkTotal: !QAList.find(list => list.checked === false)
                });
            }
        );
    };

    signal = axiosInstance.CancelToken.source();

    downloadQAReport = e => {
        e.preventDefault();
        const {
            currentTarget: { value }
        } = e;
        const { downloadQAReport } = this.props;
        const { QAList, queue } = this.state;
        queue.push(Number(value));
        this.setState(
            {
                QAList: QAList.map(list => ({
                    ...list,
                    pending: queue.find(que => que === list.id)
                }))
            },
            () => {
                downloadQAReport([Number(value)], this.signal).then(value => {
                    if (value) {
                        if (value.message === "completed") {
                            queue.shift();
                            this.setState({
                                QAList: QAList.map(list => ({
                                    ...list,
                                    pending: queue.find(que => que === list.id)
                                }))
                            });
                        }
                    }
                });
            }
        );
    };

    findCheckedList = () => {
        const { QAList } = this.state;
        this.setState({
            checkedList: QAList.filter(list => list.checked).map(list => list.id),
            elementChecked: !!QAList.find(list => list.checked)
        });
    };

    setupValid = valid => {
        this.setState({ mailList: valid.mailList, isValid: valid.isValid });
    };

    componentWillUnmount = () => {
        this.signal.cancel("Downloading(s) is cancelled by user.");
        document.body.style = undefined;
    };

    render = () => {
        const { QAModal, toggleQA, isPre, selectedJob } = this.props;
        return (
            <QAReportModal
                {...this.state}
                QAModal={QAModal}
                selectedJob={selectedJob}
                isPre={isPre}
                toggleQA={toggleQA}
                onCheckTotal={this.onCheckTotal}
                onQAListCheck={this.onQAListCheck}
                setupValid={this.setupValid}
                downloadQAReport={this.downloadQAReport}
            />
        );
    };
}

export default Container;
