import React, { memo, useEffect, useState } from "react";
import { MDBBtn } from "louis-mdbreact";
import Chip from "@mui/material/Chip";
import styles from "shared/details.scss";
import collapseStyles from "./style.scss";
import { Collapse } from "react-collapse";
import { DotLottieReact } from "@lottiefiles/dotlottie-react";
import flame from "./Animation - 1742862650974.lottie";

const CollapsedView = props => {
    const [dotlottie, setDotlottie] = useState(null);
    const dotLottieRefCallback = dotLottie => {
        setDotlottie(dotLottie);
    };
    const {
        toggleCollapsed,
        isCollapsed,
        callBack,
        title,
        btnName = "",
        underReview = false,
        idx,
        keyInfo,
        editable,
        creatable,
        onEditClick,
        onAddNoteClick,
        isEdit,
        isLoading
    } = props;
    useEffect(() => {
        if (isCollapsed && dotlottie) {
            dotlottie.play();
        } else {
            dotlottie?.pause();
        }
    }, [isCollapsed, dotlottie]);
    return (
        <>
            <h3 className="mb-3" style={{ fontSize: "1.25rem" }}>
                <span className={collapseStyles.title} style={{ cursor: "pointer" }} onClick={e => toggleCollapsed(idx)}>
                    <DotLottieReact
                        style={{ width: "30px", height: "30px", position: "absolute", top: "-8px", left: "5px" }}
                        src={flame}
                        loop
                        autoplay={isCollapsed}
                        dotLottieRefCallback={dotLottieRefCallback}
                    />
                    {title} {/* <span className={`${collapseStyles.flame} ${isCollapsed ? collapseStyles.isPlay : ""}`}></span> {title}{" "} */}
                    {underReview && <span style={{ fontSize: "0.9rem" }}>(Under Review)</span>}
                </span>
                {editable && <Chip color="primary" size="small" label={isEdit ? "CANCEL" : "EDIT"} onClick={() => onEditClick(idx)} />}
                {creatable && <Chip classes={{ root: styles.newButtonInView }} size="small" label={"ADD"} onClick={onAddNoteClick} />}
                {btnName && (
                    <div className="float-right">
                        <MDBBtn color="primary" size="sm" name={btnName} className="mb-0 mt-0 mr-0" onClick={callBack} disabled={isLoading}>
                            {isLoading ? (
                                <div className="spinner-border spinner-border-sm" role="status">
                                    <span className="sr-only">Loading...</span>
                                </div>
                            ) : (
                                btnName
                            )}
                        </MDBBtn>
                    </div>
                )}
            </h3>
            <div
                className={`${styles.keyInfo} ${!isCollapsed ? styles.keyInfoShow : styles.keyInfoHide} ${
                    underReview ? styles.keyInfoUnderReview : ""
                }`}
            >
                {keyInfo &&
                    keyInfo
                        .filter(info => info.content)
                        .map((info, i) => (
                            <p key={i} className="mb-0">
                                <span className="orange-text d-inline-block">{info.title && `${info.title} : `}</span> {info.content}
                            </p>
                        ))}
            </div>
            <Collapse isOpened={isCollapsed} theme={{ collapse: styles.heightTransition }}>
                {props.children}
            </Collapse>
            <hr />
        </>
    );
};

export default memo(CollapsedView);
