import { connect } from "react-redux";
import Container from "./container";
import { withRouter } from "react-router";
import { actionCreators as crmActions } from "redux/modules/crm";
import { actionCreators as userActions } from "redux/modules/user";
import { getEntityDetailMergedStrings } from "redux/reducers";

const mapStateToProps = (state, ownProps) => {
    const {
        user: { permissions, isAdmin, isQS, isSLT, globalUsers },
        router: {
            location: { query }
        }
    } = state;
    return {
        query,
        permissions,
        isAdmin,
        isQS,
        isSLT,
        globalUsers,
        entitiesData: getEntityDetailMergedStrings(state)
    };
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        getEntityDetail: (id, fromDelete) => dispatch(crmActions.getEntityDetail(id, fromDelete)),
        getEntityCommons: () => dispatch(crmActions.getEntityCommons()),
        getTaxCommons: () => dispatch(crmActions.getTaxCommons()),
        getContactCommons: () => dispatch(crmActions.getContactCommons()),
        getAttrCommons: () => dispatch(crmActions.getAttrCommons()),
        getWorkerAttrCommons: () => dispatch(crmActions.getWorkerAttrCommons()),
        deleteLink: (urlStr, entityId, dataId) => dispatch(crmActions.deleteLink(urlStr, entityId, dataId)),
        submitEntity: entityData => dispatch(crmActions.submitEntity(entityData)),
        submitLink: (urlStr, entityId, linkedData, notToLoad) => dispatch(crmActions.submitLink(urlStr, entityId, linkedData, notToLoad)),
        deleteFullEntity: data => dispatch(crmActions.deleteFullEntity(data)),
        getSubList: (id, types, signal) => dispatch(crmActions.getSubList(id, types, signal)),
        getGlobalUsers: company => dispatch(userActions.getGlobalUsers(company))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Container));
