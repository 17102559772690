import { combineReducers } from "redux";
import history from "shared/history";
import { connectRouter } from "connected-react-router";
import user from "redux/modules/user";
import operations, * as fromOperations from "redux/modules/operations";
import jobs, * as fromJobs from "redux/modules/jobs";
import crm, * as fromCRM from "redux/modules/crm";
import tasks from "redux/modules/tasks";
import stock from "redux/modules/stock";
import material from "redux/modules/material";
import reports from "redux/modules/reports";
import humanResources from "redux/modules/humanResources";
import serviceWorker from "redux/modules/serviceWorker";

const reducer = combineReducers({
    user,
    jobs,
    serviceWorker,
    operations,
    humanResources,
    reports,
    crm,
    stock,
    material,
    tasks,
    router: connectRouter(history)
});

export default reducer;
//Jobs
export const getServiceRateName = state => fromJobs.getServiceRateName(state.jobs);
export const getClientContact = state => fromJobs.getClientContact(state.jobs, state.user);
export const getSlimJobsListByBreakdown = state => fromJobs.getSlimJobsListByBreakdown(state.jobs);
export const getSlimJobListByDiary = state => fromJobs.getSlimJobListByDiary(state.user, state.jobs);
export const getSlimJobListNoDiary = state => fromJobs.getSlimJobListNoDiary(state.user, state.jobs);
export const getInternalStaffList = state => fromJobs.getInternalStaffList(state.jobs);
export const getBranchListByOrderFiltered = state => fromJobs.getBranchListByOrderFiltered(state.jobs);
export const getSlimJobFilteredByBranch = state => fromJobs.getSlimJobFilteredByBranch(state.jobs);

//Operations
export const getContactListForSelect = state => fromOperations.getContactListForSelect(state.operations);
export const getQAAllListAddingSpinner = state => fromOperations.getQAAllListAddingSpinner(state.operations);
export const getAttendeesForSelect = state => fromOperations.getAttendeesForSelect(state.operations);

//CRM
export const getWorkerCommonRefined = state => fromCRM.getWorkerCommonRefined(state.crm);
export const getTaxCommonrefined = state => fromCRM.getTaxCommonrefined(state.crm);
export const getEntityDetailMergedStrings = state => fromCRM.getEntityDetailMergedStrings(state.crm, state.user);
export const getWorkers = state => fromCRM.getWorkers(state.crm, state.user);
