import React from "react";
import styles from "./styles.scss";
import AsyncCreatableSelect from "react-select/async-creatable";
import { MDBCard, MDBCardHeader, MDBCardBody, MDBBtn, MDBRow, MDBCol, MDBBtnGroup, MDBIcon } from "louis-mdbreact";

const RecipientsForm = props => {
    const {
        contactList,
        isValid,
        mailList,
        emailChange,
        hasSendBtn,
        hasNotes,
        onClickSend,
        onClickDownload,
        onNotesChange,
        isLeadingHand,
        inDetail,
        detailTitle,
        notes
    } = props;
    if (isLeadingHand && !hasSendBtn) {
        return (
            <MDBRow className="mt-2">
                <MDBCol>This report will be sent to supervisor.</MDBCol>
            </MDBRow>
        );
    }
    if (contactList) {
        return (
            <MDBCard className={`${styles.card} ${!hasSendBtn || inDetail ? "mt-3" : "mb-3"}`}>
                <MDBCardHeader>{detailTitle || "Recipients"}</MDBCardHeader>
                <MDBCardBody>
                    <MDBRow>
                        <MDBCol sm="12" className={hasSendBtn ? styles.withBtn : ""}>
                            {isLeadingHand ? (
                                "This report will be sent to supervisor."
                            ) : (
                                <>
                                    <AsyncCreatableSelect
                                        inputId="qaReportEmailAddress"
                                        cacheOptions
                                        isClearable
                                        isMulti
                                        classNamePrefix="recipient-input"
                                        defaultOptions={contactList}
                                        // value={props.emailAdress}
                                        onChange={emailChange}
                                        onInputChange={str => str.replace(/\s+/g, "")}
                                        loadOptions={inputValues => {
                                            return new Promise(resolve => {
                                                setTimeout(() => {
                                                    resolve(contactList.filter(i => i.label.includes(inputValues)));
                                                }, 500);
                                            });
                                        }}
                                        styles={{
                                            control: base => {
                                                if (isValid !== undefined) {
                                                    return {
                                                        ...base,
                                                        "&:hover": {
                                                            borderColor: isValid && mailList.length > 0 ? "hsl(0,0%,80%)" : "#dc3545"
                                                        },
                                                        borderColor: isValid && mailList.length > 0 ? "hsl(0,0%,80%)" : "#dc3545"
                                                    };
                                                } else {
                                                    return {
                                                        ...base
                                                    };
                                                }
                                            },
                                            option: base => {
                                                return {
                                                    ...base,
                                                    width: "100%",
                                                    whiteSpace: "nowrap",
                                                    overflow: "hidden",
                                                    textOverflow: "ellipsis"
                                                };
                                            },
                                            placeholder: base => {
                                                return {
                                                    ...base,
                                                    overflow: "hidden",
                                                    textOverflow: "ellipsis",
                                                    whiteSpace: "nowrap",
                                                    width: "100%",
                                                    pointerEvents: "none"
                                                };
                                            },
                                            input: base => {
                                                return {
                                                    ...base,
                                                    alignItems: "center",
                                                    display: "flex"
                                                };
                                            }
                                        }}
                                        placeholder="Search..."
                                    />
                                    {isValid !== undefined && (
                                        <div
                                            className="invalid-feedback"
                                            style={{
                                                display: isValid && mailList.length > 0 ? "none" : "block"
                                            }}
                                        >
                                            Invalid email address
                                        </div>
                                    )}
                                </>
                            )}
                            {hasSendBtn && (
                                <MDBBtnGroup className={styles.fitBtn}>
                                    <MDBBtn type="button" className={styles.buttonSize} onClick={onClickSend} color="primary">
                                        {props.isLoading ? (
                                            <div className="spinner-border spinner-border-sm" role="status">
                                                <span className="sr-only">Loading...</span>
                                            </div>
                                        ) : (
                                            <MDBIcon far icon="envelope" />
                                        )}
                                    </MDBBtn>
                                    <MDBBtn type="button" className={styles.buttonSize} onClick={onClickDownload} color="success">
                                        {props.isPending ? (
                                            <div className="spinner-border spinner-border-sm" role="status">
                                                <span className="sr-only">Loading...</span>
                                            </div>
                                        ) : (
                                            <MDBIcon icon="file-download" />
                                        )}
                                    </MDBBtn>
                                </MDBBtnGroup>
                            )}
                        </MDBCol>
                    </MDBRow>
                    {hasNotes && isValid && mailList ? (
                        <MDBRow>
                            <MDBCol size="12" className="mt-2">
                                <label htmlFor="notes">Notes</label>
                                <textarea id="notes" name="notes" className="form-control" rows="3" value={notes} onChange={onNotesChange} />
                            </MDBCol>
                        </MDBRow>
                    ) : undefined}
                </MDBCardBody>
            </MDBCard>
        );
    } else {
        return <span></span>;
    }
};

export default RecipientsForm;
