import React from "react";
import { NavLink } from "react-router-dom";
import styles from "./styles.scss";
import { MDBBtn, MDBIcon } from "louis-mdbreact";

const ErrorPage = props => {
    return (
        <div className={styles.errorContainer}>
            <MDBIcon className={styles.errorIcon} far icon="frown-open" />
            <h1 className={styles.errorTitle}>404</h1>
            <p>The page you're looking for can't be found.</p>
            <NavLink to="/">
                <MDBBtn color="primary">Go home</MDBBtn>
            </NavLink>
        </div>
    );
};

export default ErrorPage;
