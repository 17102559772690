import React, { Component } from "react";
import Diary from "./presenter";
import SiteDiaryModal from "components/modals/SiteDiaryModal";
import { Helmet } from "react-helmet";

class Container extends Component {
    constructor(props) {
        super(props);
        const isMine = localStorage.getItem("isMineJob") === "true" || localStorage.getItem("isMineJob") === null;
        const isCurrent = localStorage.getItem("isCurrentJob") === "true" || localStorage.getItem("isCurrentJob") === null;

        const normalView = JSON.parse(localStorage.getItem("normalView")) || {};
        if (normalView.diary === undefined) {
            normalView.diary = true;
        }
        this.state = {
            loading: true,
            diaryModal: false,
            selectedJob: "",
            isMine,
            isCurrent,
            normalView,
            titleName: this.setTitleName(isMine, isCurrent),
            searchText: sessionStorage.getItem("diarySearch") || "",
            activePage: sessionStorage.getItem("diaryActivePage") ? Number(sessionStorage.getItem("diaryActivePage")) - 1 : 0
        };
    }

    componentDidMount = () => {
        const { getDiaryList } = this.props;
        const { isMine, isCurrent, normalView } = this.state;
        getDiaryList(isMine, isCurrent, normalView.diary);
    };

    componentDidUpdate = (prevProps, prevState) => {
        const { diaryList } = this.props;
        if (diaryList !== prevProps.diaryList) {
            this.setState({
                loading: false
            });
        }
    };

    setTitleName = (isMine, isCurrent) => {
        const title = `${isMine ? "my " : ""}${isCurrent ? "current " : ""}Site Diary`;

        return title.toUpperCase();
    };

    changeList = event => {
        const {
            target: { name, checked }
        } = event;
        const { getDiaryList } = this.props;
        localStorage.setItem([`${name}Job`], checked);
        this.setState(
            {
                [name]: checked,
                loading: true
            },
            () => {
                const { isMine, isCurrent } = this.state;
                getDiaryList(isMine, isCurrent);
                this.setState({
                    titleName: this.setTitleName(isMine, isCurrent)
                });
            }
        );
    };

    toggleDiary = e => {
        const { diaryModal } = this.state;
        this.setState({
            diaryModal: !diaryModal,
            selectedJob: e && e.currentTarget.value
        });
    };

    toggleChangeView = e => {
        const { normalView, isMine, isCurrent } = this.state;
        const { getDiaryList } = this.props;
        this.setState({ normalView: { ...normalView, diary: !normalView.diary }, loading: true }, () => {
            const { normalView } = this.state;
            localStorage.setItem("normalView", JSON.stringify(normalView));
            getDiaryList(isMine, isCurrent, normalView.diary);
        });
    };

    onSearch = searchText => {
        this.setState({
            searchText
        });
        sessionStorage.setItem("diarySearch", searchText);
    };

    onPageChange = pageInfo => {
        this.setState({
            activePage: pageInfo.activePage
        });
        sessionStorage.setItem("diaryActivePage", pageInfo.activePage);
    };

    render() {
        const { diaryModal, selectedJob } = this.state;
        const { permissions, diaryList, prefixText, userId } = this.props;
        return (
            <>
                <Helmet>
                    <title>Operations | Site Diaries</title>
                </Helmet>
                <Diary
                    {...this.state}
                    permissions={permissions}
                    diaryList={diaryList}
                    prefixText={prefixText}
                    userId={userId}
                    toggleDiary={this.toggleDiary}
                    changeList={this.changeList}
                    toggleChangeView={this.toggleChangeView}
                    onSearch={this.onSearch}
                    onPageChange={this.onPageChange}
                />
                <SiteDiaryModal diaryModal={diaryModal} selectedJob={selectedJob} toggleDiary={this.toggleDiary} />
            </>
        );
    }
}

export default Container;
