import { connect } from "react-redux";
import Container from "./container";
import { actionCreators as userActions } from "redux/modules/user";

const mapStateToProps = (state, ownProps) => {
    const {
        user: { isLoggedIn, permissions, isSLT },
        serviceWorker: { serviceWorkerUpdated, registration }
    } = state;

    return {
        isLoggedIn,
        permissions,
        isSLT,
        serviceWorkerUpdated,
        registration
    };
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        checkPermission: () => {
            dispatch(userActions.checkPermission());
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Container);
