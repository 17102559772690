// import
import { axiosInstance } from "shared/axiosInst";
import { getFileData, downloadFile } from "shared/Utils";
import { actionCreators as jobActions } from "redux/modules/jobs";
import { actionCreators as userAction } from "redux/modules/user";
import pLimit from "p-limit";
import { limitRequest } from "shared/Utils";

// valiables

// actions

const SET_DIARY_LIST = "SET_DIARY_LIST";
const SET_QA_ELEMENT = "SET_QA_ELEMENT";
const SET_ACTIVE_DATES = "SET_ACTIVE_DATES";
const SET_PRE_QA_ELEMENT = "SET_PRE_QA_ELEMENT";
const SET_DIARY_DETAIL = "SET_DIARY_DETAIL";
const SET_PRE_QA_LIST = "SET_PRE_QA_LIST";
const SET_QA_LIST = "SET_QA_LIST";
const SET_QA_DETAIL = "SET_QA_DETAIL";
const SET_QA_ALL_LIST = "SET_QA_ALL_LIST";
const SET_CONTACT_LIST = "SET_CONTACT_LIST";
const REMOVE_CONTACT_LIST = "REMOVE_CONTACT_LIST";
const REMOVE_QA_LIST = "REMOVE_QA_LIST";
const REMOVE_QA_ALL_LIST = "REMOVE_QA_ALL_LIST";
const SET_ATTENDEES = "SET_ATTENDEES";
const SET_OPERATIONAL_FORM_LIST = "SET_OPERATIONAL_FORM_LIST";
const SET_FILTER_DATA = "SET_FILTER_DATA";

// API actions

function getDiaryList(isMine, isCurrent, normalView = true) {
    return async (dispatch, getState) => {
        const {
            user: { currentCompany }
        } = getState();
        let url = "";

        if (!normalView) {
            url = `${currentCompany}operations/site_diary/slim/`;
        } else {
            switch (isMine) {
                case true:
                    url = isCurrent ? `${currentCompany}job/slim_job/site_diary/my_current/` : `${currentCompany}job/slim_job/site_diary/my/`;
                    break;
                case false:
                    url = isCurrent ? `${currentCompany}job/slim_job/site_diary/current/` : `${currentCompany}job/slim_job/site_diary/`;
                    break;
                default:
                    url = `${currentCompany}job/slim_job/site_diary/`;
            }
        }
        const diaryListResponse = await axiosInstance.get(url);
        dispatch(setDiaryList(diaryListResponse.data));
    };
}

function getQAAllList(isMine, isCurrent, normalView, isPre) {
    return async (dispatch, getState) => {
        const {
            user: { currentCompany, resourcesList }
        } = getState();
        let resources;
        if (resourcesList.length === 0) {
            resources = await dispatch(userAction.getResources());
        } else {
            resources = resourcesList;
        }
        if (!normalView) {
            const preQAAllResponse = await axiosInstance.get(`${currentCompany}operations/qa_report/${isPre ? "slim_other" : "slim_main"}/`);
            dispatch(
                setQAAllList(
                    preQAAllResponse.data.map(list => ({
                        ...list,
                        resource_string: resources.find(res => res.client_id === list.resource).legal_name
                    }))
                )
            );
        } else {
            dispatch(jobActions.getSlimJobs(isMine, isCurrent));
        }
    };
}

function getOpsCommons(hasToRefresh = false) {
    return async () => {
        const urls = [
            // "common-fire/operations/delivery_status_option/",
            "common-fire/operations/labour_type/",
            // "common-fire/operations/notification_type/",
            "common-fire/operations/qa_report_type/",
            "common-fire/operations/weather_type/",
            "common-fire/operations/qa_response_option/",
            "common-fire/operations/form_type/"
        ];
        await Promise.all(urls.map(url => axiosInstance.get(url))).then(values => {
            const [labourType, qaReportType, weatherType, qaResponseOption, formType] = values;
            // localStorage.setItem("deliveryStatusOption", JSON.stringify(deliveryStatusOption.data));
            localStorage.setItem("labourType", JSON.stringify(labourType.data));
            // localStorage.setItem("notificationType", JSON.stringify(notificationType.data));
            localStorage.setItem("qaReportType", JSON.stringify(qaReportType.data));
            localStorage.setItem("weatherType", JSON.stringify(weatherType.data));
            localStorage.setItem("qaResponseOption", JSON.stringify(qaResponseOption.data));
            localStorage.setItem("formType", JSON.stringify(formType.data));
        });

        if (hasToRefresh) {
            window.location.reload();
        }
    };
}

function getQAElements(id) {
    return async (dispatch, getState) => {
        const qaElementReponse = await axiosInstance.get(`common-fire/operations/qa_report_type_element_link/by_qa_type/${id}/`);
        dispatch(setQAElements(qaElementReponse.data));
    };
}

function getActiveDates(id) {
    return async (dispatch, getState) => {
        const {
            user: { currentCompany }
        } = getState();
        const dateResponse = await axiosInstance.get(`${currentCompany}operations/site_diary/slim_by_job/${id}/`);
        const datesOnActive = dateResponse.data;

        dispatch(setActiveDates(datesOnActive));
    };
}

function postDiary(submitData, to_client_file = []) {
    return async (dispatch, getState) => {
        const {
            user: { currentCompany }
        } = getState();
        try {
            let postDiaryResponse;
            if (submitData.id) {
                postDiaryResponse = await axiosInstance.put(`${currentCompany}operations/site_diary/${submitData.id}/`, {
                    ...submitData
                });
            } else {
                postDiaryResponse = await axiosInstance.post(`${currentCompany}operations/site_diary/`, submitData);
            }

            const diaryData = await postDiaryResponse.data;
            if (submitData.items && submitData.items.length > 0) {
                return Promise.all(
                    submitData.items.map(async file => {
                        const fd = getFileData(file, "site_diary", diaryData.id);
                        return await axiosInstance.post(`${currentCompany}operations/stored_file/`, fd);
                    })
                ).then(genericFileValues => {
                    return { message: "upload ended" };
                });
            } else {
                return { message: "upload ended" };
            }
        } catch (error) {
            console.log(error);
            return error;
        }
    };
}

function getPreQAElement() {
    return async (dispatch, getState) => {
        const qaTypes = JSON.parse(localStorage.getItem("qaReportType"));
        const preQA = qaTypes.find(type => type.name === "Pre QA");
        const qaElementReponse = await axiosInstance.get(`common-fire/operations/qa_report_type_element_link/by_qa_type/${preQA.id}/`);

        const preQAElement = qaElementReponse.data;

        dispatch(setPreQAElement(preQAElement));
    };
}

function postQA(submitData, files = []) {
    return async (dispatch, getState) => {
        const {
            user: { currentCompany }
        } = getState();
        try {
            return Promise.all(
                submitData.map(data => {
                    if (data.id) {
                        return axiosInstance.put(`${currentCompany}operations/qa_report/${data.id}/`, data);
                    } else {
                        return axiosInstance.post(`${currentCompany}operations/qa_report/`, data);
                    }
                })
            ).then(async values => {
                return Promise.all(
                    values.map(async value => {
                        const qa_items = value.data.qa_items;
                        return await Promise.all(
                            qa_items.map(async item => {
                                const selectedFile = files.find(file => file.report_element === item.report_element);
                                if (selectedFile) {
                                    const fd = selectedFile.new_file.map(fi => ({
                                        note: fi.note,
                                        data: getFileData({ new_file: fi.f }, "qa_report", value.data.id)
                                    }));
                                    return await axiosInstance.put(`${currentCompany}operations/qa_report_element_link/${item.id}/`, {
                                        ...item,
                                        associated_files: await Promise.all(
                                            fd.map(formData =>
                                                axiosInstance.post(`${currentCompany}operations/stored_file/`, formData.data).then(fileValues => {
                                                    return {
                                                        stored_file: fileValues.data.id,
                                                        note: formData.note
                                                    };
                                                })
                                            )
                                        )
                                    });
                                } else {
                                    return undefined;
                                }
                            })
                        );
                    })
                ).then(async returnValues => {
                    const preQAId = JSON.parse(localStorage.getItem("qaReportType")).find(type => type.name === "Pre QA").id;

                    const submitedQA = values.find(value => value.data.qa_type === Number(preQAId));
                    return {
                        qa: values.map(value => value.data.id),
                        submitedQA: submitedQA ? true : false
                    };
                });
            });
        } catch (error) {
            console.log(error);
            return error;
        }
    };
}

function getDiaryDetail(id) {
    return async (dispatch, getState) => {
        const {
            user: { currentCompany, resourcesList }
        } = getState();
        let resources;
        if (resourcesList.length === 0) {
            resources = await dispatch(userAction.getResources());
        } else {
            resources = resourcesList;
        }
        const diaryDetailResponse = await axiosInstance.get(`${currentCompany}operations/site_diary/${id}/`);
        const diaryDetail = {
            ...diaryDetailResponse.data,
            resource_string: resources.find(res => res.id === diaryDetailResponse.data.resource_id).legal_name
        };

        dispatch(setDiaryDetail(diaryDetail));
    };
}

function getQAList(id, isPre) {
    return async (dispatch, getState) => {
        const {
            user: { currentCompany }
        } = getState();
        const QAListResponse = await axiosInstance.get(
            `${currentCompany}operations/qa_report/${isPre ? "slim_other" : "slim_main"}/${id ? id + "/" : ""}`
        );
        const QAList = QAListResponse.data;
        if (isPre) {
            dispatch(setPreQAList(QAList));
        } else {
            dispatch(setQAList(QAList));
        }
    };
}

function getQaDetail(id) {
    return async (dispatch, getState) => {
        const {
            user: { currentCompany }
        } = getState();
        const QaDetailResponse = await axiosInstance.get(`${currentCompany}operations/qa_report/${id}/`);
        const QaDetail = QaDetailResponse.data;
        dispatch(setQaDetail(QaDetail));
    };
}

function getContactList(id) {
    return async (dispatch, getState) => {
        const {
            user: { currentCompany }
        } = getState();
        const contactResponse = await axiosInstance.get(`${currentCompany}operations/job_operational_contact/by_job/${id}/`);
        const contactList = contactResponse.data;

        dispatch(setContactList(contactList));
    };
}

function submitEmail(submitData, isPre) {
    return async (dispatch, getState) => {
        const {
            user: { currentCompany }
        } = getState();
        try {
            const emailResponse = await axiosInstance.post(`${currentCompany}operations/qa_report/${isPre ? "send_pre_qa" : "send_qa"}/`, {
                qa: submitData.qa,
                recipient: await Promise.all(
                    submitData.emailAddress.map(async addr => {
                        if (typeof addr === "object") {
                            return await axiosInstance
                                .post(`${currentCompany}operations/job_operational_contact/`, addr)
                                .then(emailValue => emailValue.data.email_address);
                        } else {
                            return await addr;
                        }
                    })
                ).then(emailValues => emailValues)
            });
            const emailResponseData = emailResponse.data;
            return emailResponseData;
        } catch (error) {
            console.log(error);
            return error;
        }
    };
}

function submitFormEmail(submitData) {
    return async (dispatch, getState) => {
        const {
            user: { currentCompany }
        } = getState();
        try {
            const emailResponse = await axiosInstance.post(`${currentCompany}operations/operational_form/send_form/`, {
                form: submitData.form,
                recipient: await Promise.all(
                    submitData.emailAddress.map(async addr => {
                        if (typeof addr === "object") {
                            return await axiosInstance
                                .post(`${currentCompany}operations/job_operational_contact/`, addr)
                                .then(emailValue => emailValue.data.email_address);
                        } else {
                            return await addr;
                        }
                    })
                ).then(emailValues => emailValues)
            });
            const emailResponseData = emailResponse.data;
            return emailResponseData;
        } catch (error) {
            console.log(error);
            return error;
        }
    };
}

function submitDiaryEmail(submitData) {
    return async (dispatch, getState) => {
        const {
            user: { currentCompany }
        } = getState();
        try {
            const emailResponse = await axiosInstance.post(`${currentCompany}operations/site_diary/send_site_diary/`, {
                diary: submitData.diary,
                recipient: await Promise.all(
                    submitData.emailAddress.map(async addr => {
                        if (typeof addr === "object") {
                            return await axiosInstance
                                .post(`${currentCompany}operations/job_operational_contact/`, addr)
                                .then(emailValue => emailValue.data.email_address);
                        } else {
                            return await addr;
                        }
                    })
                ).then(emailValues => emailValues)
            });
            const emailResponseData = emailResponse.data;
            return emailResponseData;
        } catch (error) {
            console.log(error);
            return error;
        }
    };
}

function getFormElement(id) {
    return async () => {
        const formElementsResponse = await axiosInstance.get(`common-fire/operations/form_type_element_link/by_form_type/${id}/`);
        const data = formElementsResponse.data;
        return data.filter(d => d.current_element).map(d => ({ ...d, id: "", element: d.id }));
    };
}

function getFormDetail(id) {
    return async (dispatch, getState) => {
        const {
            user: { currentCompany }
        } = getState();
        const formDetailResponse = await axiosInstance.get(`${currentCompany}operations/operational_form/${id}/`);
        const data = formDetailResponse.data;
        return data;
    };
}

function getAttendees() {
    return async (dispatch, getState) => {
        const attendeesResponse = await axiosInstance.get(`crm/slim_current_entity/?relationships=1&assignments=2&assignments=1&assignments=3`);
        const lagacyAttendeesResponse = await axiosInstance.get(`crm/slim_contact/internal_employees/`);
        const data = attendeesResponse.data;
        const lagacyData = lagacyAttendeesResponse.data.map(lagacy => ({ ...lagacy, isLagacy: true }));
        dispatch(setAttendees([...data, ...lagacyData]));
    };
}

function downloadQAReport(ids, signal = null) {
    return async (dispatch, getState) => {
        const {
            user: { currentCompany }
        } = getState();
        const response = await axiosInstance.post(
            `${currentCompany}operations/qa_report/download_qa_report/`,
            {
                qa: ids
            },
            { responseType: "blob", cancelToken: signal && signal.token }
        );

        const disposition = response.request.getResponseHeader("Content-Disposition");
        const url = window.URL.createObjectURL(new Blob([response.data], { type: "application/pdf" }));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", disposition.split('"')[1]);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        return { message: "completed", ids };
    };
}

function downloadOperationalForm(ids, signal = null, tempCompany = null) {
    return async (_, getState) => {
        const {
            user: { currentCompany }
        } = getState();
        try {
            const response = await axiosInstance.post(
                `${tempCompany ? tempCompany : currentCompany}operations/operational_form/download_form/`,
                {
                    form: ids
                },
                { responseType: "blob", cancelToken: signal && signal.token }
            );
            downloadFile(response);

            return { message: "completed", ids };
        } catch (error) {
            if (error?.response.status === 504) {
                alert("It takes too long to download, please try again or select less reports.");
                return { message: "completed" };
            } else {
                return error.response;
            }
        }
    };
}

function downloadDiary(ids, signal = null, tempCompany = null) {
    return async (dispatch, getState) => {
        const {
            user: { currentCompany }
        } = getState();

        try {
            const response = await axiosInstance.post(
                `${tempCompany ? tempCompany : currentCompany}operations/site_diary/download_site_diary/`,
                {
                    diary: ids
                },
                { responseType: "blob", cancelToken: signal && signal.token }
            );

            downloadFile(response);

            return { message: "completed", ids };
        } catch (error) {
            if (error?.response.status === 504) {
                alert("It takes too long to download, please try again or select less reports.");
                return { message: "completed" };
            } else {
                return error.response;
            }
        }
    };
}

function fileDownload(idx) {
    return async (dispatch, getState) => {
        const {
            user: { currentCompany }
        } = getState();
        const response = await axiosInstance.get(`${currentCompany}operations/stored_file/get_public_download/${idx}/`);
        const linkurl = response.data.download_link;
        const link = document.createElement("a");
        link.href = linkurl;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };
}

function getHSAllList(isMine, isCurrent, normalView, filters = {}) {
    return async (dispatch, getState) => {
        const {
            user: { currentCompany }
        } = getState();
        if (!normalView) {
            const filter = Object.keys(filters).reduce((total, current) => {
                if (current !== "isOpen") {
                    if (filters[current] !== false && filters[current] !== "" && filters[current] !== undefined) {
                        total += `${total === "" ? "?" : "&"}${current}=${filters[current]}`;
                    }
                }
                return total;
            }, "");
            const reportsResponse = await axiosInstance.get(`${currentCompany}operations/operational_form/slim/${filter}`);
            dispatch(setQAAllList(reportsResponse.data));
        } else {
            dispatch(jobActions.getSlimJobs(isMine, isCurrent));
        }
    };
}

function getFilterData() {
    return async (dispatch, _) => {
        const urls = [`users/qs_users/fire/`, `users/supervisor_users/fire/`];
        await Promise.all(urls.map(url => axiosInstance.get(url)))
            .then(values => {
                const [qsUsersRes, supervisorsRes] = values;
                dispatch(setFilterData({ qsUsers: qsUsersRes.data, supervisors: supervisorsRes.data }));
            })
            .catch(error => {
                alert("Something went wrong. Please try again later.");
            });
    };
}

function submitFile({ currentCompany, formData, currentLoadedSize, totalFileSize, progressFunc, limit }) {
    return limit(() =>
        axiosInstance
            .post(`${currentCompany}operations/stored_file/`, formData.data, {
                onUploadProgress: progressEvent => {
                    const { loaded, total } = progressEvent;
                    if (total === loaded) {
                        currentLoadedSize = currentLoadedSize + total;
                    }
                    progressFunc && progressFunc(Math.round((currentLoadedSize / totalFileSize) * 100));
                }
            })
            .then(fileValues => {
                return {
                    stored_file: fileValues.data.id,
                    note: formData.note
                };
            })
    );
}

function getOperationalFormList(id) {
    return async (dispatch, getState) => {
        const {
            user: { currentCompany }
        } = getState();
        const nonLogResponse = await axiosInstance.get(`${currentCompany}operations/operational_form/slim_non_log/${id}/`);
        const nonLogList = nonLogResponse.data;
        const logResponse = await axiosInstance.get(`${currentCompany}operations/operational_form/slim_log/${id}/`);
        const logList = logResponse.data;

        dispatch(setOperationalFomList({ nonLogList, logList }));
    };
}

function submitOperationalForm({ datas, attendeeList = [], SSSPFiles = [], toolboxFiles = [] }, uploadProgress = null) {
    return async (dispatch, getState) => {
        const {
            user: { currentCompany }
        } = getState();
        try {
            // const toolboxFilesConverter = async files => {
            //     return await Promise.all(files.map(async file => ({ result: await blobToBase64(file.file), fileName: file.file.name })));
            // };
            // const convertedFiles = await toolboxFilesConverter(toolboxFiles);
            // const transferData = {
            //     type: "submitOperationForm",
            //     forms: datas,
            //     SSSPFiles,
            //     toolboxFiles: convertedFiles,
            //     attendeeList
            // };
            // console.log(transferData);
            // return { message: "upload ended", responses: [{ fakeid: 1 }] };

            const operationForms = await Promise.all(
                datas.map(async data => {
                    return data.id
                        ? await axiosInstance.put(`${currentCompany}operations/operational_form/${data.id}/`, data)
                        : await axiosInstance.post(`${currentCompany}operations/operational_form/`, data);
                })
            );

            const limit = pLimit(limitRequest);

            const responses = await Promise.all(
                operationForms.map(async form => {
                    const response = form.data;
                    if (response.form_type === 3) {
                        const signatureFileSize =
                            attendeeList.length > 0
                                ? attendeeList.reduce((total, attendee) => {
                                      return typeof attendee.signature === "object" ? total + attendee.signature.size : total;
                                  }, 0)
                                : 0;
                        const toolboxFileSize = toolboxFiles.length > 0 ? toolboxFiles.reduce((total, file) => total + file.file.size, 0) : 0;
                        const totalFileSize = signatureFileSize + toolboxFileSize;
                        let currentLoadedSize = 0;
                        const attendeeReponse = await Promise.all(
                            attendeeList.map(async attendee => {
                                if (attendee.id) {
                                    return await axiosInstance.put(`${currentCompany}operations/operational_form_attendee/${attendee.id}/`, attendee);
                                } else {
                                    return await axiosInstance.post(`${currentCompany}operations/operational_form_attendee/`, {
                                        ...attendee,
                                        form: response.id,
                                        signature: await axiosInstance
                                            .post(
                                                `${currentCompany}operations/stored_file/`,
                                                getFileData({ new_file: attendee.signature }, "operational_form", response.id),
                                                {
                                                    onUploadProgress: progressEvent => {
                                                        const { loaded, total } = progressEvent;
                                                        if (loaded === total) {
                                                            currentLoadedSize = currentLoadedSize + total;
                                                        }
                                                        uploadProgress && uploadProgress(Math.round((currentLoadedSize / totalFileSize) * 100));
                                                    }
                                                }
                                            )
                                            .then(value => value.data.id)
                                    });
                                }
                            })
                        );
                        const fileReponse = await Promise.all(
                            toolboxFiles.map(file =>
                                limit(() =>
                                    axiosInstance.post(
                                        `${currentCompany}operations/stored_file/`,
                                        getFileData({ new_file: file.file }, "operational_form", response.id),
                                        {
                                            onUploadProgress: progressEvent => {
                                                const { loaded, total } = progressEvent;
                                                if (loaded === total) {
                                                    currentLoadedSize = currentLoadedSize + total;
                                                }
                                                uploadProgress && uploadProgress(Math.round((currentLoadedSize / totalFileSize) * 100));
                                            }
                                        }
                                    )
                                )
                            )
                        );
                        if (fileReponse && attendeeReponse) {
                            return response;
                        }
                    } else if (response.form_type === 1) {
                        if (SSSPFiles.length > 0) {
                            const totalFileSize = SSSPFiles.reduce((total, file) => total + file.file.size, 0);
                            let currentLoadedSize = 0;
                            await axiosInstance.put(`${currentCompany}operations/operational_form/${response.id}/`, {
                                ...response,
                                form_response_file: await Promise.all(
                                    SSSPFiles.filter(file => file.file).map(file =>
                                        limit(() =>
                                            axiosInstance
                                                .post(
                                                    `${currentCompany}operations/stored_file/`,
                                                    getFileData({ new_file: file.file }, "operational_form", response.id),
                                                    {
                                                        onUploadProgress: progressEvent => {
                                                            const { loaded, total } = progressEvent;
                                                            if (loaded === total) {
                                                                currentLoadedSize = currentLoadedSize + total;
                                                            }
                                                            uploadProgress && uploadProgress(Math.round((currentLoadedSize / totalFileSize) * 100));
                                                        }
                                                    }
                                                )
                                                .then(value => value.data.id)
                                        )
                                    )
                                )
                            });
                        }
                        return response;
                    } else {
                        return response;
                    }
                })
            );
            const hasFile = datas.find(data => data.formFiles?.length > 0);
            if (hasFile) {
                await Promise.all(
                    responses.map(async (response, i) => {
                        const totalFileSize = datas[i]?.formFiles?.reduce((total, file) => total + file.file.size, 0);
                        let currentLoadedSize = 0;
                        const fd =
                            datas[i]?.formFiles?.map(file => ({
                                note: "",
                                data: getFileData({ new_file: file.file }, "operational_form", response.id)
                            })) || [];
                        return await Promise.allSettled(
                            fd.map(async formData => {
                                return await submitFile({
                                    currentCompany,
                                    formData,
                                    currentLoadedSize,
                                    totalFileSize,
                                    uploadProgress,
                                    limit
                                });
                            })
                        );
                    })
                );
            }
            return { message: "upload ended", responses };
        } catch (error) {
            return error;
        }
    };
}

// action creators

function setDiaryList(diaryList) {
    return {
        type: SET_DIARY_LIST,
        diaryList
    };
}

function setQAElements(qaElement) {
    return {
        type: SET_QA_ELEMENT,
        qaElement
    };
}

function setActiveDates(datesOnActive) {
    return {
        type: SET_ACTIVE_DATES,
        datesOnActive
    };
}

function setPreQAElement(preQAElement) {
    return {
        type: SET_PRE_QA_ELEMENT,
        preQAElement
    };
}

function setDiaryDetail(diaryDetail) {
    return {
        type: SET_DIARY_DETAIL,
        diaryDetail
    };
}

function setPreQAList(PreQAList) {
    return {
        type: SET_PRE_QA_LIST,
        PreQAList
    };
}

function setQAList(QAList) {
    return {
        type: SET_QA_LIST,
        QAList
    };
}

function setQaDetail(QaDetail) {
    return {
        type: SET_QA_DETAIL,
        QaDetail
    };
}

function setContactList(contactList) {
    return {
        type: SET_CONTACT_LIST,
        contactList
    };
}

function setQAAllList(QAAllList) {
    return {
        type: SET_QA_ALL_LIST,
        QAAllList
    };
}

function removeContactList() {
    return {
        type: REMOVE_CONTACT_LIST
    };
}

function removeQAList() {
    return {
        type: REMOVE_QA_LIST
    };
}

function removeQAAllList() {
    return {
        type: REMOVE_QA_ALL_LIST
    };
}

function setAttendees(attendees) {
    return {
        type: SET_ATTENDEES,
        attendees
    };
}

function setOperationalFomList(operationalFormList) {
    return {
        type: SET_OPERATIONAL_FORM_LIST,
        operationalFormList
    };
}

function setFilterData(filterData) {
    return {
        type: SET_FILTER_DATA,
        filterData
    };
}

// initial state
const initialState = {};
// reducer
function reducer(state = initialState, action) {
    switch (action.type) {
        case SET_DIARY_LIST:
            return applyDiaryList(state, action);
        case SET_QA_ELEMENT:
            return applyQAElement(state, action);
        case SET_ACTIVE_DATES:
            return applyActiveDates(state, action);
        case SET_PRE_QA_ELEMENT:
            return applyPreQAElement(state, action);
        case SET_DIARY_DETAIL:
            return applyDiaryDetail(state, action);
        case SET_QA_LIST:
            return applyQAList(state, action);
        case SET_PRE_QA_LIST:
            return applyPreQAList(state, action);
        case SET_QA_DETAIL:
            return applyQaDetail(state, action);
        case SET_CONTACT_LIST:
            return applyContactList(state, action);
        case SET_ATTENDEES:
            return applyAttendees(state, action);
        case SET_QA_ALL_LIST:
            return applyQAAllList(state, action);
        case SET_OPERATIONAL_FORM_LIST:
            return applyOperationalFormList(state, action);
        case REMOVE_CONTACT_LIST:
            return {
                ...state,
                contactList: undefined
            };
        case REMOVE_QA_LIST:
            return {
                ...state,
                QAList: undefined,
                PreQAList: undefined
            };
        case SET_FILTER_DATA:
            return {
                ...state,
                filterData: action.filterData
            };
        default:
            return state;
    }
}

// reducer function

function applyDiaryList(state, action) {
    const { diaryList } = action;
    return {
        ...state,
        diaryList
    };
}

function applyQAElement(state, action) {
    const { qaElement } = action;
    return {
        ...state,
        qaElement
    };
}

function applyActiveDates(state, action) {
    const { datesOnActive } = action;
    return {
        ...state,
        datesOnActive
    };
}

function applyPreQAElement(state, action) {
    const { preQAElement } = action;
    return {
        ...state,
        preQAElement
    };
}

function applyDiaryDetail(state, action) {
    const { diaryDetail } = action;
    return {
        ...state,
        diaryDetail
    };
}

function applyQAList(state, action) {
    const { QAList } = action;
    return {
        ...state,
        QAList
    };
}

function applyPreQAList(state, action) {
    const { PreQAList } = action;
    return {
        ...state,
        PreQAList
    };
}

function applyQaDetail(state, action) {
    const { QaDetail } = action;
    return {
        ...state,
        QaDetail
    };
}

function applyContactList(state, action) {
    const { contactList } = action;
    return {
        ...state,
        contactList
    };
}

function applyQAAllList(state, action) {
    const { QAAllList } = action;
    return {
        ...state,
        QAAllList
    };
}

function applyAttendees(state, action) {
    const { attendees } = action;
    return {
        ...state,
        attendees
    };
}

function applyOperationalFormList(state, action) {
    const { operationalFormList } = action;
    return {
        ...state,
        operationalFormList
    };
}

//export
const actionCreators = {
    getDiaryList,
    getOpsCommons,
    getQAElements,
    getActiveDates,
    postDiary,
    getPreQAElement,
    postQA,
    getDiaryDetail,
    getQAList,
    getQaDetail,
    getContactList,
    submitEmail,
    submitFormEmail,
    submitDiaryEmail,
    removeContactList,
    fileDownload,
    removeQAList,
    getQAAllList,
    downloadQAReport,
    getFormElement,
    getAttendees,
    getFilterData,
    getHSAllList,
    removeQAAllList,
    submitOperationalForm,
    downloadOperationalForm,
    downloadDiary,
    getFormDetail,
    getOperationalFormList
};

export { actionCreators };

export default reducer;

export const getContactListForSelect = operationState => {
    const { contactList } = operationState;
    return contactList && contactList.map(list => ({ label: list.email_address, value: list.id }));
};

export const getQAAllListAddingSpinner = operationState => {
    const { QAAllList } = operationState;
    return QAAllList && QAAllList.map(list => ({ ...list, pending: false }));
};

export const getAttendeesForSelect = operationState => {
    const { attendees } = operationState;
    return (
        attendees &&
        attendees.map(attendee => ({
            label: attendee.isLagacy ? `${attendee.first_name} ${attendee.last_name}` : attendee.name,
            isLagacy: attendee.isLagacy,
            value: attendee.id,
            signature: "",
            attendee_name: ""
        }))
    );
};
