import React from "react";
import styles from "shared/details.scss";
import {
    MDBCard,
    MDBCardHeader,
    MDBCardBody,
    MDBRow,
    MDBCol,
    MDBTable,
    MDBTableHead,
    MDBTableBody,
    MDBBtn,
    MDBTableFoot,
    MDBIcon,
    MDBDropdown,
    MDBDropdownToggle,
    MDBDropdownMenu,
    MDBDropdownItem
} from "louis-mdbreact";
import dayjs from "shared/dayjs";
import VaildSelect from "components/ValidSelect";
import Loading from "components/Loading";

const TimesheetView = props => {
    return (
        <div className={styles.container}>
            <h2 className={styles.title}>
                {props.permissions.maintenance === 4 && props.isAdmin && (
                    <MDBIcon
                        className="green-text"
                        style={{ cursor: "pointer" }}
                        onClick={props.toggleAdmin}
                        spin={props.showAdminSetting}
                        icon="cog"
                        fixed
                    />
                )}
                <span>TIME SHEET VIEW</span>
            </h2>
            {props.isLoading ? (
                <Loading loadingType="detail" />
            ) : (
                <>
                    {props.showAdminSetting && (
                        <MDBCard className={styles.card}>
                            <MDBCardHeader>
                                Payroll
                                <span className="float-right">
                                    <MDBDropdown>
                                        <MDBDropdownToggle size="sm" caret={!props.sendLoading} color="primary">
                                            {props.sendLoading ? (
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        alignItems: "center"
                                                    }}
                                                >
                                                    Wait
                                                    <div style={{ marginLeft: "0.2rem" }} className="spinner-border spinner-border-sm" role="status">
                                                        <span className="sr-only">Loading...</span>
                                                    </div>
                                                </div>
                                            ) : (
                                                "send"
                                            )}
                                        </MDBDropdownToggle>
                                        <MDBDropdownMenu right basic color="primary">
                                            <MDBDropdownItem onClick={props.sendingPayroll} name="report">
                                                Payroll Report
                                            </MDBDropdownItem>
                                            <MDBDropdownItem onClick={props.sendingPayroll} name="summary">
                                                Payroll Summary
                                            </MDBDropdownItem>
                                            <MDBDropdownItem onClick={props.sendingPayroll} name="subcontractor_payroll">
                                                SC Payroll Report
                                            </MDBDropdownItem>
                                        </MDBDropdownMenu>
                                    </MDBDropdown>
                                </span>
                            </MDBCardHeader>
                            <MDBCardBody>
                                <MDBRow>
                                    <MDBCol>
                                        <label htmlFor="startDateRange">Payroll Period</label>
                                        <MDBRow>
                                            <MDBCol sm="6">
                                                <div className="input-group mb-3">
                                                    <div className="input-group-prepend">
                                                        <span className="input-group-text">from</span>
                                                    </div>
                                                    <input
                                                        type="date"
                                                        className="form-control"
                                                        name="start"
                                                        value={props.payrollPeriod.start}
                                                        onChange={props.onPeriodChange}
                                                    />
                                                </div>
                                            </MDBCol>
                                            <MDBCol sm="6">
                                                <div className="input-group mb-3">
                                                    <div className="input-group-prepend">
                                                        <span className="input-group-text">to</span>
                                                    </div>
                                                    <input
                                                        type="date"
                                                        className="form-control"
                                                        name="end"
                                                        value={props.payrollPeriod.end}
                                                        onChange={props.onPeriodChange}
                                                    />
                                                </div>
                                            </MDBCol>
                                        </MDBRow>
                                    </MDBCol>
                                </MDBRow>
                            </MDBCardBody>
                        </MDBCard>
                    )}
                    <MDBCard className={styles.card}>
                        <MDBCardHeader>Time Sheet Detail</MDBCardHeader>
                        <MDBCardBody>
                            <MDBRow>
                                <MDBCol className="mb-2">
                                    <label>Worker</label>
                                    <VaildSelect
                                        options={props.timesheetWorkers}
                                        onChange={props.onWorkerChange}
                                        formatOptionLabel={({ label, value }) => {
                                            const newLabel = `<span>${label}</span> <span style="font-size:0.8rem; color:grey;"}>(${value})</span>`;
                                            return <span dangerouslySetInnerHTML={{ __html: newLabel }} />;
                                        }}
                                        value={props.selectedWorker}
                                    />
                                </MDBCol>
                            </MDBRow>
                            <MDBRow>
                                <MDBCol>
                                    <label htmlFor="startDateRange">Date Range</label>
                                    <MDBRow>
                                        <MDBCol sm="6">
                                            <div className="input-group mb-3">
                                                <div className="input-group-prepend">
                                                    <span className="input-group-text">from</span>
                                                </div>
                                                <input
                                                    type="date"
                                                    className="form-control"
                                                    name="startDateRange"
                                                    value={props.startDateRange}
                                                    onChange={props.onDateChange}
                                                />
                                            </div>
                                        </MDBCol>
                                        <MDBCol sm="6">
                                            <div className="input-group mb-3">
                                                <div className="input-group-prepend">
                                                    <span className="input-group-text">to</span>
                                                </div>
                                                <input
                                                    type="date"
                                                    className="form-control"
                                                    name="endDateRange"
                                                    value={props.endDateRange}
                                                    onChange={props.onDateChange}
                                                />
                                            </div>
                                        </MDBCol>
                                    </MDBRow>
                                </MDBCol>
                            </MDBRow>
                        </MDBCardBody>
                    </MDBCard>
                    <MDBCard className={styles.card}>
                        <MDBCardHeader>
                            Time sheet List{" "}
                            {props.listLoading && (
                                <div className="spinner-border spinner-border-sm text-primary" role="status">
                                    <span className="sr-only">Loading...</span>
                                </div>
                            )}
                        </MDBCardHeader>
                        <MDBCardBody>
                            <MDBTable small className={styles.detailResponsiveTable}>
                                <MDBTableHead>
                                    <tr className={styles.textCentre}>
                                        <th>Date</th>
                                        <th>Job No.</th>
                                        <th>Client/Site</th>
                                        <th width="30%">Description</th>
                                        <th>MJS No.</th>
                                        <th>Hours</th>
                                        {props.selectedWorker.is_subcontractor ? <th>Rates</th> : <th>Overtime</th>}
                                        <th width="70px">Action</th>
                                    </tr>
                                </MDBTableHead>
                                <MDBTableBody>
                                    {props.timesheetList &&
                                        props.timesheetList.map(list => (
                                            <tr key={list.id} className={`${styles.addItemContainer} ${styles.textCentre}`}>
                                                <td data-th="Date">{dayjs(list.work_date).format("DD/MM/YYYY")}</td>
                                                <td data-th="Job No.">{list.job}</td>
                                                <td data-th="Client/Site">
                                                    {list.client_string || list.resource_string}
                                                    {list.job_name_string ? (
                                                        <>
                                                            <br />
                                                            <span
                                                                style={{
                                                                    fontSize: "0.8rem",
                                                                    color: "grey"
                                                                }}
                                                            >
                                                                {list.job_name_string}
                                                            </span>
                                                        </>
                                                    ) : (
                                                        ""
                                                    )}
                                                </td>
                                                <td className={styles.ellipsis} data-th="Description">
                                                    <span>{list.notes}</span>
                                                    <span className={styles.hoverText}>{list.notes}</span>
                                                </td>
                                                <td data-th="MJS No.">{list.maintenance_job || "-"}</td>
                                                <td data-th="Hours">{list.hours_worked || "-"}</td>
                                                {props.selectedWorker.is_subcontractor ? (
                                                    <td data-th="Rates">{list.cost_rate || "-"}</td>
                                                ) : (
                                                    <td data-th="Overtime">{list.overtime_hours_worked || "-"}</td>
                                                )}
                                                <td data-th="Action">
                                                    {list.locked ? (
                                                        "Allocated"
                                                    ) : (
                                                        <MDBBtn color="primary" size="sm" value={list.id} onClick={props.timeToggle}>
                                                            Edit
                                                        </MDBBtn>
                                                    )}
                                                </td>
                                            </tr>
                                        ))}
                                </MDBTableBody>
                                <MDBTableFoot>
                                    {props.total_hours_by_job.length > 0 && (
                                        <tr className={styles.textCentre}>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td>
                                                {props.total_hours_by_job.map(hour => (
                                                    <span className="d-block" key={hour.job_id}>{`Hours : ${hour.hours} (${hour.job_id})`}</span>
                                                ))}
                                            </td>
                                            <td>
                                                {props.total_overtime_hours_by_job &&
                                                    props.total_overtime_hours_by_job.map(hour => (
                                                        <span className="d-block" key={hour.job_id}>{`Hours : ${hour.hours} (${hour.job_id})`}</span>
                                                    ))}
                                            </td>
                                            <td></td>
                                        </tr>
                                    )}
                                    <tr className={styles.textCentre}>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td>Total: {props.total_hours}</td>
                                        <td>{props.total_overtime_hours && `Total: ${props.total_overtime_hours}`}</td>
                                        <td></td>
                                    </tr>
                                </MDBTableFoot>
                            </MDBTable>
                        </MDBCardBody>
                    </MDBCard>
                </>
            )}
        </div>
    );
};

export default TimesheetView;
