import { connect } from "react-redux";
import Container from "./container";
import { actionCreators as operationActions } from "redux/modules/operations";
import { getAttendeesForSelect } from "redux/reducers";
import { withRouter } from "react-router";

const mapStateToProps = (state, ownProps) => {
    const {
        user: { permissions }
    } = state;
    return { attendees: getAttendeesForSelect(state), permissions };
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        getFormElement: id => dispatch(operationActions.getFormElement(id)),
        getAttendees: () => dispatch(operationActions.getAttendees()),
        updateFormData: data => ownProps.updateFormData(data),
        updateFiles: files => ownProps.updateFiles(files),
        downloadImage: id => dispatch(operationActions.fileDownload(id))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Container));
