// imports
import { axiosInstance } from "shared/axiosInst";
import { actionCreators as userActions } from "redux/modules/user";
import { actionCreators as jobActions } from "redux/modules/jobs";
import { getCommonObj, getNameStrings } from "shared/Utils";

// variables

// actions
const SET_SLIM_ENTITIES = "SET_SLIM_ENTITIES";
const SET_NEW_ENTITY_BASE_DATA = "SET_NEW_ENTITY_BASE_DATA";
const SET_ENTITY_DETAIL = "SET_ENTITY_DETAIL";
const SET_ATTR_COMMONS = "SET_ATTR_COMMONS";
const SET_ENTITY_COMMONS = "SET_ENTITY_COMMONS";
const SET_TAX_COMMONS = "SET_TAX_COMMONS";
const SET_CONTACT_COMMONS = "SET_CONTACT_COMMONS";
const SET_WORKERS_ATTR_COMMONS = "SET_WORKERS_ATTR_COMMONS";
const SET_WORKERS_LIST = "SET_WORKERS_LIST";
const SET_SKINNY_ROLE = "SET_SKINNY_ROLE";

// action creators

function setNewEntityBaseData(entityBaseData) {
    return {
        type: SET_NEW_ENTITY_BASE_DATA,
        entityBaseData
    };
}

function setSlimEntity(entitiesList) {
    return {
        type: SET_SLIM_ENTITIES,
        entitiesList
    };
}

function setEntityDetail(entitiesData) {
    return {
        type: SET_ENTITY_DETAIL,
        entitiesData
    };
}

function setAttrCommons(commonItems) {
    return {
        type: SET_ATTR_COMMONS,
        commonItems
    };
}

function setEntityCommons(entityCommons) {
    return {
        type: SET_ENTITY_COMMONS,
        entityCommons
    };
}

function setTaxCommons(taxCommons) {
    return {
        type: SET_TAX_COMMONS,
        taxCommons
    };
}

function setContactCommons(contactCommons) {
    return {
        type: SET_CONTACT_COMMONS,
        contactCommons
    };
}

function setWorkerAttrCommons(workersAttrCommons) {
    return {
        type: SET_WORKERS_ATTR_COMMONS,
        workersAttrCommons
    };
}

function setWorkersList(workerList) {
    return {
        type: SET_WORKERS_LIST,
        workerList
    };
}

function setSkinnyRole(skinnyRoleList, skinnyRoleFilter) {
    return {
        type: SET_SKINNY_ROLE,
        skinnyRoleList,
        skinnyRoleFilter
    };
}

// function

// API actions

function getSlimEntity(filter = "") {
    return async dispatch => {
        const response = await axiosInstance.get(`crm/slim_entity/${filter}`);
        const slimEntity = await response.data;
        dispatch(setSlimEntity(slimEntity));
    };
}

function getSlimCurrentEntity(filter = "") {
    return async () => {
        const response = await axiosInstance.get(`crm/slim_entity/${filter}`);
        return await response.data;
    };
}

function getSkinnyRole(filter = "") {
    return async (dispatch, getState) => {
        const {
            crm: { skinnyRoleList, skinnyRoleFilter }
        } = getState();
        const getSkinnyList = async () => {
            const response = await axiosInstance.get(`crm/skinny_role/${filter}`);
            const list = response.data;
            dispatch(setSkinnyRole(list, filter));
            return list;
        };
        try {
            let list;
            if (!skinnyRoleList) {
                list = await getSkinnyList();
            } else {
                if (skinnyRoleFilter !== filter) {
                    list = await getSkinnyList();
                } else {
                    list = skinnyRoleList;
                }
            }
            return await list;
        } catch (error) {
            console.log(error.response);
        }
    };
}

function getEntityCommons() {
    return async (dispatch, getState) => {
        const {
            crm: { entityCommons }
        } = getState();
        if (entityCommons) {
            return;
        }
        const urlPrefix = `common/crm/`;
        const urls = [`${urlPrefix}entity_type/`, `${urlPrefix}responsibility/`];
        const dataList = await Promise.all(urls.map(url => axiosInstance.get(url)));
        const newCommonItems = getCommonObj(urls, dataList);
        dispatch(setEntityCommons(newCommonItems));
    };
}

function getTaxCommons() {
    return async (dispatch, getState) => {
        const {
            crm: { taxCommons }
        } = getState();
        if (taxCommons) {
            return;
        }
        const urlPrefix = `common/crm/`;
        const urls = [`${urlPrefix}tax_type/`, `${urlPrefix}tax_specification/`];
        const dataList = await Promise.all(urls.map(url => axiosInstance.get(url)));
        const newCommonItems = getCommonObj(urls, dataList);
        dispatch(setTaxCommons(newCommonItems));
    };
}

function getContactCommons() {
    return async (dispatch, getState) => {
        const {
            crm: { contactCommons }
        } = getState();
        if (contactCommons) {
            return;
        }
        const urlPrefix = `common/crm/`;
        const urls = [`${urlPrefix}location_type/`, `${urlPrefix}contact_type/`];
        const dataList = await Promise.all(urls.map(url => axiosInstance.get(url)));
        const newCommonItems = getCommonObj(urls, dataList);
        dispatch(setContactCommons(newCommonItems));
    };
}

function getAttrCommons() {
    return async (dispatch, getState) => {
        const {
            crm: { commonItems }
        } = getState();
        const urlPrefix = `common/crm/`;
        if (commonItems) {
            return;
        }
        const urls = [`${urlPrefix}assignment/`, `${urlPrefix}relationship/`];

        const dataList = await Promise.all(urls.map(url => axiosInstance.get(url)));
        const newCommonItems = getCommonObj(urls, dataList);
        newCommonItems.roles = JSON.parse(localStorage.getItem("linings-service")) || dispatch(jobActions.getJobCommons(true));
        newCommonItems.regions = JSON.parse(localStorage.getItem("regions")) || dispatch(userActions.getUserCommons(true));
        dispatch(setAttrCommons(newCommonItems));
    };
}

function getWorkerAttrCommons() {
    return async (dispatch, getState) => {
        const {
            crm: { workersAttrCommons }
        } = getState();
        const urlPrefix = `common/crm/`;
        if (workersAttrCommons) {
            return;
        }
        const urls = [`${urlPrefix}skill/`, `${urlPrefix}qualification/`];

        const dataList = await Promise.all(urls.map(url => axiosInstance.get(url)));
        const newCommonItems = getCommonObj(urls, dataList);
        dispatch(setWorkerAttrCommons(newCommonItems));
    };
}

function getNewEntityBaseData() {
    return async (dispatch, getState) => {
        const {
            user: { currentCompany },
            crm: { entitiesList }
        } = getState();
        let urls;
        if (currentCompany === "fire-wbop/") {
            return;
        }
        if (entitiesList) {
            urls = [`crm/association_group/`];
        } else {
            urls = [`crm/association_group/`, `crm/slim_entity/`];
        }

        await Promise.all(urls.map(url => axiosInstance.get(url))).then(values => {
            const [groupassociationResponse, entityResponse] = values;
            dispatch(
                setNewEntityBaseData({
                    entityData: entitiesList || entityResponse.data,
                    groupassociationData: groupassociationResponse.data
                })
            );
        });
    };
}

function getEntityDetail(id, fromDelete) {
    return async (dispatch, getState) => {
        try {
            const { data } = await axiosInstance.get(`crm/entity/${id}/`);
            const { data: entity_note } = await axiosInstance.get(`crm/entity_note/?entity=${id}`);
            const { data: children } = await axiosInstance.get(`crm/slim_entity/?parent=${id}`);
            const completedDetail = { ...data, entity_note, children };
            if (fromDelete) {
                return completedDetail;
            } else {
                dispatch(setEntityDetail(completedDetail));
            }
        } catch (error) {
            console.log(error.response);
        }
    };
}

function submitFullEntity(entityDatas, inJob = null) {
    return async (dispatch, getState) => {
        try {
            const entityResponse = await dispatch(submitEntity(entityDatas.entity));
            if (entityResponse.id) {
                const entityId = entityResponse.id;
                if (inJob === "client" && !entityDatas.relationship.find(rel => rel.name === "Client")) {
                    entityDatas.relationship.push({
                        current: true,
                        name: "Client",
                        relationship: 4,
                        pending: false
                    });
                }
                await Promise.all(
                    entityDatas.Email.map(mail => dispatch(submitLink("entity_email_address", entityId, mail, true))),
                    entityDatas.Location.map(loc => dispatch(submitLink("entity_location_link", entityId, loc, true))),
                    entityDatas["Phone Number"].map(pho => dispatch(submitLink("entity_phone_number", entityId, pho, true))),
                    entityDatas.notes.map(note => dispatch(submitLink("entity_note", entityId, note, true))),
                    entityDatas.tax.map(tx => dispatch(submitLink("entity_tax_specification_link", entityId, tx, true))),
                    entityDatas.role.map(rl => dispatch(submitLink("entity_role_link", entityId, rl, true))),
                    entityDatas.assignment.map(assign => dispatch(submitLink("entity_assignment_link", entityId, assign, true))),
                    entityDatas.relationship.map(rel => dispatch(submitLink("entity_relationship_link", entityId, rel, true))),
                    entityDatas.skill.map(skl => dispatch(submitLink("entity_skill_link", entityId, skl, true))),
                    entityDatas.qualification.map(qual => dispatch(submitLink("entity_qualification_link", entityId, qual, true)))
                );
                if (inJob === "client") {
                    await dispatch(
                        jobActions.addNewClient({
                            value: entityResponse.id,
                            label: entityResponse.name,
                            searchText: `${entityResponse.group_associations.join(", ")} ${entityResponse.parent_name_string}`,
                            entityType: entityResponse.entity_type,
                            pending: false
                        })
                    );
                } else if (inJob === "contact") {
                    await dispatch(
                        jobActions.addNewclientContactDetail({
                            newClientContactData: entityResponse
                        })
                    );
                }
                return entityResponse;
            } else {
                throw new Error("Entity ID not found.");
            }
        } catch (error) {
            console.log(error);
            return error.response;
        }
    };
}

function submitEntity(entityData) {
    return async (dispatch, getState) => {
        const {
            crm: { entityBaseData, entityCommons }
        } = getState();
        try {
            let response;
            const { entity_type } = entityCommons;
            if (entityData.id) {
                response = await axiosInstance.put(`crm/entity/${entityData.id}/`, entityData);
                const responseData = await response.data;
                dispatch(setEntityDetail(responseData, responseData));
                if (entityBaseData) {
                    dispatch(
                        setNewEntityBaseData({
                            ...entityBaseData,
                            entityData: entityBaseData.entityData.map(entity =>
                                entity.id === responseData.id
                                    ? {
                                          id: responseData.id,
                                          name: responseData.name,
                                          short_name: responseData.short_name,
                                          entity_type: entity_type.find(type => type.id === responseData.entity_type).name
                                      }
                                    : entity
                            )
                        })
                    );
                }
            } else {
                response = await axiosInstance.post(`crm/entity/`, entityData);
                const responseData = await response.data;
                if (entityBaseData) {
                    dispatch(
                        setNewEntityBaseData({
                            ...entityBaseData,
                            entityData: [
                                ...entityBaseData.entityData,
                                {
                                    id: responseData.id,
                                    name: responseData.name,
                                    short_name: responseData.short_name,
                                    entity_type: entity_type.find(type => type.id === responseData.entity_type).name
                                }
                            ]
                        })
                    );
                }
            }
            const responseData = await response.data;
            return responseData;
        } catch (error) {
            console.log(error);
            return error.response;
        }
    };
}

function submitLink(urlStr, entityId, linkedData, notToLoad = false) {
    return async dispatch => {
        let response;
        if (linkedData.id) {
            response = await axiosInstance.put(`crm/${urlStr}/${linkedData.id}/`, {
                entity: entityId,
                ...linkedData
            });
        } else {
            response = await axiosInstance.post(`crm/${urlStr}/`, {
                entity: entityId,
                ...linkedData
            });
        }
        const responseData = await response.data;
        if (responseData.id && !notToLoad) {
            dispatch(getEntityDetail(entityId));
        } else {
            return await responseData;
        }
    };
}

function submitAttribute(data) {
    return async (dispatch, getState) => {
        const { sendingData, names, entityId } = data;
        try {
            const responses = Object.keys(sendingData).map(async key => {
                const promises = sendingData[key].map(ele => {
                    return dispatch(
                        submitLink(
                            names.find(name => name.name === key).url,
                            entityId,
                            {
                                ...ele,
                                [key]: ele.id,
                                id: ele.originalId || undefined,
                                regions: key === "assignment" ? ele.regions.map(reg => reg.id) : undefined,
                                end_date: key === "relationship" ? ele.end_date || undefined : undefined,
                                expiration_date: key === "qualification" ? ele.expiration_date || undefined : undefined,
                                pending: false
                            },
                            true
                        )
                    );
                });

                return Promise.all(promises);
            });
            Promise.all(responses).then(value => {
                dispatch(getEntityDetail(entityId));
            });
        } catch (error) {
            console.log(error.response);
        }
    };
}

function deleteFullEntity(entityData) {
    return async (dispatch, getState) => {
        const {
            crm: { entityBaseData }
        } = getState();
        const entityId = entityData.id;
        await Promise.all(
            entityData.email_addresses.map(mail => dispatch(deleteLink("entity_email_address", entityId, mail.id, true))),
            entityData.location_details.map(loc => dispatch(deleteLink("entity_location_link", entityId, loc.id, true))),
            entityData.phone_numbers.map(pho => dispatch(deleteLink("entity_phone_number", entityId, pho.id, true))),
            entityData.entity_note.map(note => dispatch(deleteLink("entity_note", entityId, note.id, true))),
            entityData.tax_spec_details.map(tx => dispatch(deleteLink("entity_tax_specification_link", entityId, tx.id, true))),
            entityData.role_details.map(rl => dispatch(deleteLink("entity_role_link", entityId, rl.id, true))),
            entityData.assignment_details.map(assign => dispatch(deleteLink("entity_assignment_link", entityId, assign.id, true))),
            entityData.relationship_details.map(rel => dispatch(deleteLink("entity_relationship_link", entityId, rel.id, true))),
            entityData.skill_details.map(skl => dispatch(deleteLink("entity_skill_link", entityId, skl.id, true))),
            entityData.qualification_details.map(qual => dispatch(deleteLink("entity_qualification_link", entityId, qual.id, true)))
        );
        await dispatch(deleteEntity(entityId));
        if (entityBaseData) {
            dispatch(
                setNewEntityBaseData({
                    ...entityBaseData,
                    entityData: entityBaseData.entityData.filter(entity => entity.id !== entityId)
                })
            );
        }
        return { message: "deleted" };
    };
}

function deleteEntity(entityId) {
    return async () => {
        try {
            await axiosInstance.delete(`crm/entity/${entityId}/`);
        } catch (error) {
            console.log(error.response);
        }
    };
}

function deleteLink(urlStr, entityId, dataId, noToLoad = false) {
    return async (dispatch, getState) => {
        const response = await axiosInstance.delete(`crm/${urlStr}/${dataId}/`);
        if (response && !noToLoad) {
            dispatch(getEntityDetail(entityId));
        }
    };
}

function getMaintenanceStaffPay(isCurrent) {
    return async (dispatch, getState) => {
        try {
            const response = await axiosInstance.get(`crm/staff_pay/${isCurrent ? "current/" : ""}`);
            return response.data;
        } catch (error) {
            console.log(error.response);
        }
    };
}

function submitMaintenanceStaffPay(payrateData, isEdit) {
    return async (dispatch, getState) => {
        try {
            const response = isEdit
                ? await axiosInstance.put(`crm/staff_pay/${payrateData.staff_member}/`, payrateData)
                : await axiosInstance.post(`crm/staff_pay/`, payrateData);
            return { ...response.data, timestamp: payrateData.timestamp };
        } catch (error) {
            console.log(error.response);
            alert("Creating/Editing a staff pay rate is failed. Please try agin.");
            return error.response;
        }
    };
}

function getSubList(id, { isClient, isJobsheet, isClientQS }, signal = null) {
    return async (dispatch, getState) => {
        const {
            user: { currentCompany }
        } = getState();
        const subLists = [];
        try {
            const regions = JSON.parse(localStorage.getItem("regions")) || dispatch(userActions.getUserCommons(true));
            if (isClient) {
                const jobListResponse = await axiosInstance.get(`${currentCompany}job/slim_job/?client=${id}`, { cancelToken: signal.token });
                const jobList = jobListResponse.data;
                jobList.length > 0 &&
                    subLists.push({
                        name: "Jobs",
                        list: jobList.map(job => ({
                            ...job,
                            number: `${job.id}-${regions.find(reg => reg.id === job.region).region_short_code}`
                        }))
                    });
            }
            // if (isJobsheet) {
            //     const jobsheetListResponse = await axiosInstance.get(`${currentCompany}budget/slim_job_sheet/?subbie=${id}`, {
            //         cancelToken: signal.token
            //     });
            //     const jobsheetList = jobsheetListResponse.data;
            //     jobsheetList.length > 0 && subLists.push({ name: "Job Sheets", list: jobsheetList });
            // }
            if (isClientQS) {
                const jobListResponse = await axiosInstance.get(`${currentCompany}job/slim_job/?client_qs=${id}`, {
                    cancelToken: signal.token
                });
                const jobList = jobListResponse.data;
                jobList.length > 0 &&
                    subLists.push({
                        name: "Jobs",
                        list: jobList.map(job => ({
                            ...job,
                            number: `${job.id}-${regions.find(reg => reg.id === job.region).region_short_code}`
                        }))
                    });
            }
            return subLists;
        } catch (error) {
            return error.response;
        }
    };
}

function getworkersList({ assignment, job, roles = [] }) {
    return async (dispatch, getState) => {
        const {
            user: { currentCompany }
        } = getState();
        const role = roles.reduce((total, rl) => `${total}&role=${rl}`, "");
        const workerList =
            currentCompany !== "fire-wbop/"
                ? await dispatch(getSkinnyRole(`?assignment=${assignment}&job=${job}${role}`))
                : await axiosInstance.get(`crm/slim_contact/internal_subcontractor/`);
        const workerListByCompany = currentCompany !== "fire-wbop/" ? workerList : workerList.data;
        dispatch(setWorkersList(workerListByCompany));
        if (currentCompany !== "fire-wbop/") {
            return workerList.map(contractor => ({
                value: contractor.id,
                label: `${contractor.name} ${contractor.short_name ? ` - ${contractor.short_name}` : ""}`,
                subLabel: contractor.email_address[0],
                roles: contractor.roles
            }));
        } else {
            return workerList.data
                .filter(contractor => contractor.first_name)
                .map(contractor => ({
                    value: contractor.id,
                    label: `${contractor.first_name} ${contractor.last_name} ${contractor.known_as ? ` - ${contractor.known_as}` : ""}`,
                    subLabel: contractor.email_address
                }));
        }
    };
}

function sendEmail(data, signal) {
    return async () => {
        try {
            const response = await axiosInstance.post(`crm/send_email/`, data, {
                cancelToken: signal.token
            });
            return response.status;
        } catch (error) {
            console.log(error.response);
        }
    };
}

function getEntityAssociationLink(filter) {
    return async () => {
        try {
            const response = await axiosInstance.get(`crm/entity_system_association_link/${filter}`);
            return response.data;
        } catch (error) {
            console.log(error.response);
        }
    };
}

// initial states

const initialState = {};

// reducer

function reducer(state = initialState, action) {
    switch (action.type) {
        case SET_NEW_ENTITY_BASE_DATA:
            return applyNewEntityBaseData(state, action);
        case SET_SLIM_ENTITIES:
            return applSlimEntity(state, action);
        case SET_ENTITY_DETAIL:
            return applyEntityDetail(state, action);
        case SET_ATTR_COMMONS:
            return applyAttrCommons(state, action);
        case SET_ENTITY_COMMONS:
            return applyEntityCommons(state, action);
        case SET_TAX_COMMONS:
            return applyTaxCommons(state, action);
        case SET_CONTACT_COMMONS:
            return applyContactCommons(state, action);
        case SET_WORKERS_ATTR_COMMONS:
            return applyWorkerAttrCommons(state, action);
        case SET_WORKERS_LIST:
            return applyWorkersList(state, action);
        case SET_SKINNY_ROLE:
            return applySkinnyRole(state, action);
        default:
            return state;
    }
}

// reducer function

function applyNewEntityBaseData(state, action) {
    const { entityBaseData } = action;
    return {
        ...state,
        entityBaseData
    };
}

function applSlimEntity(state, action) {
    const { entitiesList } = action;
    return {
        ...state,
        entitiesList
    };
}

function applyEntityDetail(state, action) {
    const { entitiesData } = action;
    const { entitiesData: currentData } = state;
    return {
        ...state,
        entitiesData: { ...currentData, ...entitiesData }
    };
}

function applyAttrCommons(state, action) {
    const { commonItems } = action;
    return {
        ...state,
        commonItems
    };
}

function applyEntityCommons(state, action) {
    const { entityCommons } = action;
    return {
        ...state,
        entityCommons
    };
}

function applyTaxCommons(state, action) {
    const { taxCommons } = action;
    return {
        ...state,
        taxCommons
    };
}

function applyContactCommons(state, action) {
    const { contactCommons } = action;
    return {
        ...state,
        contactCommons
    };
}

function applyWorkerAttrCommons(state, action) {
    const { workersAttrCommons } = action;
    return {
        ...state,
        workersAttrCommons
    };
}

function applyWorkersList(state, action) {
    const { workerList } = action;
    return {
        ...state,
        workerList
    };
}

function applySkinnyRole(state, action) {
    const { skinnyRoleList, skinnyRoleFilter } = action;
    return {
        ...state,
        skinnyRoleList,
        skinnyRoleFilter
    };
}

// export

const actionCreators = {
    getNewEntityBaseData,
    getSlimEntity,
    getSlimCurrentEntity,
    getEntityDetail,
    getAttrCommons,
    getTaxCommons,
    getEntityCommons,
    getContactCommons,
    getWorkerAttrCommons,
    submitEntity,
    submitLink,
    submitFullEntity,
    deleteFullEntity,
    deleteEntity,
    deleteLink,
    submitAttribute,
    getMaintenanceStaffPay,
    submitMaintenanceStaffPay,
    getSubList,
    getworkersList,
    sendEmail,
    getEntityAssociationLink,
    getSkinnyRole
};

export { actionCreators };

// reducer export
export default reducer;

export const getWorkerCommonRefined = crmState => {
    const { workersAttrCommons, commonItems } = crmState;
    const { roles } = commonItems || {};
    return workersAttrCommons && commonItems
        ? {
              ...workersAttrCommons,
              skill: workersAttrCommons.skill.map(skl => {
                  return {
                      ...skl,
                      name: `${skl.name}${skl.linked_service ? ` (${roles.find(ro => ro.id === skl.linked_service).name})` : ""}`
                  };
              })
          }
        : {};
};

export const getTaxCommonrefined = crmState => {
    const { taxCommons } = crmState;
    const tax_specification = taxCommons
        ? taxCommons.tax_specification.map(spec => ({
              ...spec,
              name: `${taxCommons.tax_type.find(type => type.id === spec.tax_type).name} (${spec.tax_rate}%)`
          }))
        : [];
    return taxCommons
        ? {
              ...taxCommons,
              tax_specification
          }
        : { tax_specification: [] };
};

export const getEntityDetailMergedStrings = (crmState, userState) => {
    const { permissions, isQS } = userState;
    const { entitiesData, commonItems, entityCommons, contactCommons, workersAttrCommons } = crmState;
    const taxCommons = getTaxCommonrefined(crmState);
    if (entitiesData && commonItems && entityCommons && taxCommons && contactCommons && workersAttrCommons) {
        const {
            assignment_details,
            email_addresses,
            location_details,
            phone_numbers,
            qualification_details,
            relationship_details,
            responsibilities,
            role_details,
            skill_details,
            tax_spec_details
        } = entitiesData;
        const { assignment, relationship, roles: commonRoles } = commonItems;
        const { responsibility, entity_type } = entityCommons;
        const { contact_type, location_type } = contactCommons;
        const { qualification, skill } = workersAttrCommons;
        const { tax_specification } = taxCommons;
        const skills = isQS ? [] : getNameStrings(skill_details, null, skill, "skill");
        const temp_role = getNameStrings(role_details, null, commonRoles, "role");
        return {
            ...entitiesData,
            entity_type_string: entity_type.find(type => type.id === Number(entitiesData.entity_type)).name,
            assignment_details: getNameStrings(assignment_details, null, assignment, "assignment"),
            email_addresses: getNameStrings(email_addresses, "contact_types", contact_type),
            location_details: getNameStrings(location_details, "location_types", location_type),
            phone_numbers: getNameStrings(phone_numbers, "contact_types", contact_type),
            qualification_details: isQS ? [] : getNameStrings(qualification_details, null, qualification, "qualification"),
            relationship_details: getNameStrings(relationship_details, null, relationship, "relationship"),
            responsibilities: getNameStrings(responsibilities, null, responsibility),
            role_details: temp_role.map(role => {
                const filteredSkills = skills.filter(skill => skill.linked_service === role.role);
                return {
                    ...role,
                    associated_score:
                        permissions.crm === 4 && filteredSkills.length > 0
                            ? (filteredSkills.reduce((a, b) => a + b.rating, 0) / filteredSkills.length).toFixed(2)
                            : undefined
                };
            }),
            skill_details: skills.map(skill => {
                if (permissions.crm !== 4) {
                    return { ...skill, rating: null };
                } else {
                    return skill;
                }
            }),
            tax_spec_details: getNameStrings(tax_spec_details, null, tax_specification, "tax_specification")
        };
    } else {
        return {};
    }
};

export const getWorkers = (crmState, userState) => {
    const { workerList } = crmState;
    const { currentCompany } = userState;
    if (currentCompany !== "fire-wbop/") {
        return workerList
            ? workerList.map(contractor => ({
                  value: contractor.id,
                  label: `${contractor.name} ${contractor.short_name ? ` - ${contractor.short_name}` : ""}`,
                  subLabel: contractor.email_address[0],
                  roles: contractor.roles
              }))
            : [];
    } else {
        return workerList
            ? workerList
                  .filter(contractor => contractor.first_name)
                  .map(contractor => ({
                      value: contractor.id,
                      label: `${contractor.first_name} ${contractor.last_name} ${contractor.known_as ? ` - ${contractor.known_as}` : ""}`,
                      subLabel: contractor.email_address
                  }))
            : [];
    }
};
