import { connect } from "react-redux";
import { withRouter } from "react-router";
import Container from "./container";
import { actionCreators as JobActions } from "redux/modules/jobs";
import { actionCreators as operationActions } from "redux/modules/operations";
import { getSlimJobListNoDiary } from "redux/reducers";

const mapStateToProps = (state, ownProps) => {
    const {
        user: { companyPrefix, permissions, currentCompany, resourcesList },
        operations: { diaryDetail },
        router: {
            location: { query }
        }
    } = state;
    return {
        resourcesList,
        currentCompany,
        permissions,
        query,
        companyPrefix,
        diaryDetail,
        business_category: JSON.parse(localStorage.getItem("category")),
        jobList: getSlimJobListNoDiary(state),
        labourType: JSON.parse(localStorage.getItem("labourType")),
        weatherType: JSON.parse(localStorage.getItem("weatherType")),
        deliveryStatusOption: JSON.parse(localStorage.getItem("deliveryStatusOption")),
        notificationType: JSON.parse(localStorage.getItem("notificationType"))
    };
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        getSlimJobsNoDiary: date => dispatch(JobActions.getSlimJobsNoDiary(date)),
        getOpsCommons: bool => dispatch(operationActions.getOpsCommons(bool)),
        postDiary: (submitData, to_client_file) =>
            dispatch(operationActions.postDiary(submitData, to_client_file)),
        getDiaryDetail: id => dispatch(operationActions.getDiaryDetail(id)),
        fileDownload: id => dispatch(operationActions.fileDownload(id))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Container));
