import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { actionCreators } from "redux/modules/tasks";
import SelectMJS from "./SelectMJS";

const MJSFilter = () => {
    const dispatch = useDispatch();
    const { jobList } = useSelector(state => state.tasks);

    useEffect(() => {
        dispatch(actionCreators.getSlimMaintenanceJob(false, false));
    }, [dispatch]);

    return (
        <>
            <SelectMJS title="Starting MJS" name="startingMJS" job="jobStarting" zIndex={4} jobList={jobList} />
            <SelectMJS title="Ending MJS" name="endingMJS" job="jobEnding" zIndex={3} jobList={jobList} />
        </>
    );
};

export default MJSFilter;
