import React from "react";
import Loading from "components/Loading";
import styles from "shared/listStyles.scss";
import { Link } from "react-router-dom";
import CreateNewButton from "components/CreateNewButton";
import { MDBDataTable, MDBIcon, MDBBtn, MDBBtnGroup } from "louis-mdbreact";
import { CogButton } from "components/FiltersContainer";

import { Helmet } from "react-helmet";
import BranchSelect from "components/BranchSelect";
import JobFilters from "./JobFilters";

const Jobs = ({
    permissions,
    adminSetting,
    onChangeSetting,
    jobList: propsJobList,
    loading,
    prefixText,
    toggleAdmin,
    titleName,
    isMine,
    changeList,
    isCurrent,
    currentRegion,
    searchText,
    activePage,
    onSearch,
    onPageChange
}) => {
    const jobList =
        adminSetting.isMaintenance && (permissions.job === 4 || permissions.job === 3)
            ? propsJobList.filter(job => job.business_category_name === "Maintenance")
            : propsJobList;
    const data = {
        columns: [
            { label: "No.", field: "job_no", sort: "asc" },
            { label: "Client", field: "client", sort: "asc" },
            { label: "Site", field: "site", sort: "asc" },
            { label: "Supervisor", field: "supervisor", sort: "asc" },
            { label: "QS", field: "qs", sort: "asc" },
            { label: "Surveyor", field: "surveyor", sort: "asc" },
            { label: "Actions", field: "actions" }
        ],
        rows:
            !loading &&
            jobList.map(job => ({
                job_no: `${prefixText}-${job.id}${job.short_region ? `-${job.short_region}` : ""}`,
                client: job.client_string || "-",
                site: job.job_name || "-",
                supervisor: job.internal_supervisor_string || "-",
                qs: job.internal_qs_string || "-",
                surveyor: job.internal_surveyor_string || "-",
                actions: (
                    <MDBBtnGroup>
                        <Link to={`/jobs/${job.id}`}>
                            <MDBBtn
                                color="primary"
                                className={`${styles.buttonSize} ${styles.noRound} ${job.linings_on_job ? styles.liningsOnJob : ""}`}
                            >
                                {job.linings_on_job ? <MDBIcon icon="home" /> : <MDBIcon icon="info-circle" />}
                            </MDBBtn>
                        </Link>
                    </MDBBtnGroup>
                ),
                rowClassName: job.business_category_name === "Maintenance" ? "pink accent-1" : job.under_review ? "light-blue lighten-4" : undefined
            }))
    };
    return (
        <div className={styles.container}>
            <Helmet>
                <title>Jobs | List</title>
            </Helmet>
            <div className={styles.titleWrap}>
                <h2 className={`${styles.taskTitle}`}>
                    <CogButton toggleOpen={toggleAdmin} isOpen={adminSetting.isAdminOpen} /> {titleName}
                </h2>
                <CreateNewButton />
                <div className={styles.switchWrap}>
                    <div className={`${styles.listSwitch} custom-control custom-switch`}>
                        <input
                            type="checkbox"
                            className="custom-control-input"
                            id="myjob"
                            name="isMine"
                            checked={isMine}
                            onChange={changeList}
                            readOnly
                        />
                        <label className="custom-control-label" htmlFor="myjob">
                            MY JOBS
                        </label>
                    </div>
                    <div className={`${styles.listSwitch} custom-control custom-switch`}>
                        <input
                            type="checkbox"
                            className="custom-control-input"
                            id="current"
                            name="isCurrent"
                            checked={isCurrent}
                            onChange={changeList}
                            readOnly
                        />
                        <label className="custom-control-label" htmlFor="current">
                            CURRENT
                        </label>
                    </div>
                </div>
            </div>
            <JobFilters isOpen={adminSetting.isAdminOpen} filters={adminSetting} setFilters={onChangeSetting} />
            {currentRegion === "Auckland" && <BranchSelect />}
            {loading ? (
                <Loading loadingType="list" />
            ) : (
                <MDBDataTable
                    entriesOptions={[10, 20, 50, 100, 500]}
                    entries={20}
                    responsive
                    borderless
                    striped
                    responsiveSm
                    pagesAmount={5}
                    data={data}
                    searchText={searchText}
                    initialPage={activePage}
                    onSearch={onSearch}
                    onPageChange={onPageChange}
                    className={`${styles.jobsDataTable} ${styles.dataTable} mt-3`}
                />
            )}
        </div>
    );
};

Jobs.defaultProps = {
    jobList: []
};

export default Jobs;
