import React, { Component } from "react";
import Entity from "./presenter";
import { Helmet } from "react-helmet";

class Container extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            filterSetting: JSON.parse(localStorage.getItem("entityFilter")) || {
                isOpen: false,
                pending: false,
                entity_type: "0",
                relationships: "0",
                assignments: "0"
            },
            searchText: sessionStorage.getItem("crmSearch") || "",
            activePage: sessionStorage.getItem("crmActivePage") ? Number(sessionStorage.getItem("crmActivePage")) - 1 : 0
        };
    }

    componentDidMount = () => {
        const { getSlimEntity, getEntityCommons, getAttrCommons } = this.props;
        getSlimEntity(this.getFilter());
        getEntityCommons();
        getAttrCommons();
        window.scrollTo(0, 0);
    };

    componentDidUpdate = prevProps => {
        const { entitiesList } = this.props;
        if (entitiesList !== prevProps.entitiesList) {
            this.setState({
                loading: false
            });
        }
    };

    onSearch = searchText => {
        sessionStorage.setItem("crmSearch", searchText);
        this.setState({
            searchText
        });
    };

    onPageChange = pageInfo => {
        sessionStorage.setItem("crmActivePage", pageInfo.activePage);
    };

    toggleFilter = () => {
        const { filterSetting } = this.state;
        this.setState(
            {
                filterSetting: {
                    ...filterSetting,
                    isOpen: !filterSetting.isOpen
                }
            },
            () => {
                const { filterSetting } = this.state;
                localStorage.setItem("entityFilter", JSON.stringify(filterSetting));
            }
        );
    };

    changeList = event => {
        const {
            target: { name, value }
        } = event;

        const { filterSetting } = this.state;
        const { getSlimEntity } = this.props;

        this.setState(
            {
                filterSetting: {
                    ...filterSetting,
                    [name]: value
                },
                loading: true
            },
            () => {
                const { filterSetting } = this.state;
                localStorage.setItem("entityFilter", JSON.stringify(filterSetting));
                getSlimEntity(this.getFilter());
            }
        );
    };

    getFilter = () => {
        const { filterSetting } = this.state;
        let filter = "?";
        let i;
        for (i in filterSetting) {
            filter +=
                filterSetting[i] !== "0" && filterSetting[i] !== "undefined" && filterSetting[i] !== false && i !== "isOpen"
                    ? `${i}=${filterSetting[i]}&`
                    : "";
        }

        return filter;
    };

    clearFilters = () => {
        const { filterSetting } = this.state;
        this.setState(
            {
                filterSetting: {
                    isOpen: filterSetting.isOpen,
                    pending: filterSetting.pending,
                    entity_type: "0",
                    relationships: "0",
                    assignments: "0"
                },
                loading: true
            },
            () => {
                const { filterSetting } = this.state;
                const { getSlimEntity } = this.props;
                localStorage.setItem("entityFilter", JSON.stringify(filterSetting));
                getSlimEntity(this.getFilter());
            }
        );
    };

    render() {
        const { entitiesList, permissions, entityCommons, commonItems } = this.props;
        return (
            <>
                <Helmet>
                    <title>CRM | Entities</title>
                </Helmet>
                <Entity
                    {...this.state}
                    entitiesList={entitiesList}
                    entityCommons={entityCommons}
                    commonItems={commonItems}
                    permissions={permissions}
                    toggleFilter={this.toggleFilter}
                    changeList={this.changeList}
                    onSearch={this.onSearch}
                    onPageChange={this.onPageChange}
                    clearFilters={this.clearFilters}
                />
            </>
        );
    }
}

export default Container;
