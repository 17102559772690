import React from "react";
import styles from "shared/details.scss";
import Loading from "components/Loading";
import {
    MDBCard,
    MDBCardHeader,
    MDBCardBody,
    MDBRow,
    MDBCol,
    MDBTableBody,
    MDBTable,
    MDBBtn,
    MDBTableHead,
    MDBInputGroup,
    MDBIcon
} from "louis-mdbreact";
import VaildSelect from "components/ValidSelect";
import { Checkbox } from "@mui/material";
import SubmitButton from "components/SubmitButton";
import ArrowBackButton from "components/ArrowBackButton";

const DiaryDetail = props => {
    const { sendingData, permissions, pendingLoading, type } = props;
    let isDisabled = true;
    switch (type) {
        case "new":
            if (permissions.operations === 4 || permissions.operations === 3) {
                isDisabled = false;
            } else {
                isDisabled = true;
            }
            break;
        case "detail":
            if (permissions.operations === 4 || (permissions.operations === 3 && props.isEditable)) {
                isDisabled = false;
            } else {
                isDisabled = true;
            }
            break;
        default:
            isDisabled = true;
            break;
    }
    return (
        <div className={styles.container}>
            <h2 className={styles.title}>
                {props.type !== "new" && <ArrowBackButton />}
                <span>{props.title}</span>
            </h2>
            {props.isLoading ? (
                <Loading loadingType="detail" />
            ) : (
                <form onSubmit={props.diarySubmit} className={`needs-validation ${props.isValidated ? "was-validated" : undefined}`} noValidate>
                    <MDBCard>
                        <MDBCardHeader>Diary Details</MDBCardHeader>
                        <MDBCardBody>
                            <MDBRow>
                                <MDBCol className="mb-2">
                                    <label htmlFor="diary_date">Diary Date</label>
                                    <input
                                        type="date"
                                        name="diary_date"
                                        id="diary_date"
                                        className="form-control"
                                        value={sendingData.diary_date}
                                        onChange={props.onValueChange}
                                        required
                                        disabled={props.type === "detail"}
                                    />
                                    <div className="invalid-feedback">Required</div>
                                </MDBCol>
                            </MDBRow>
                            <MDBRow>
                                <MDBCol>
                                    <label htmlFor="job_list">
                                        Job{" "}
                                        {props.jobDetailLoading && (
                                            <div className="spinner-border spinner-border-sm text-primary" role="status">
                                                <span className="sr-only">Loading...</span>
                                            </div>
                                        )}
                                    </label>
                                    <VaildSelect
                                        id="job_list"
                                        options={props.jobList}
                                        onChange={props.onJobChange}
                                        value={sendingData.job}
                                        isDisabled={!isDisabled}
                                        isValid={props.isValidated && sendingData.job}
                                    />
                                    {props.jobDetailDiary && (
                                        <MDBRow className="mt-3">
                                            <MDBCol>
                                                <MDBTable small>
                                                    <MDBTableBody>
                                                        <tr>
                                                            <th width="30%">Client</th>
                                                            <td width="70%">{props.jobDetailDiary.resource_string || "-"}</td>
                                                        </tr>
                                                        <tr>
                                                            <th>Project Title</th>
                                                            <td>{props.jobDetailDiary.job_name || "-"}</td>
                                                        </tr>
                                                        <tr>
                                                            <th>QS</th>
                                                            <td>{props.jobDetailDiary.internal_qa_string || "-"}</td>
                                                        </tr>
                                                        <tr>
                                                            <th>Supervisor</th>
                                                            <td>{props.jobDetailDiary.internal_supervisor_string || "-"}</td>
                                                        </tr>
                                                    </MDBTableBody>
                                                </MDBTable>
                                            </MDBCol>
                                        </MDBRow>
                                    )}
                                </MDBCol>
                            </MDBRow>
                            <MDBRow className="mt-2">
                                <MDBCol>
                                    <label htmlFor="activity_on_job">
                                        <Checkbox
                                            id="activity_on_job"
                                            name="activity_on_job"
                                            checked={sendingData.activity_on_job}
                                            onChange={props.onActivityChange}
                                            disabled={isDisabled}
                                        />{" "}
                                        Activity on Job
                                    </label>
                                </MDBCol>
                            </MDBRow>
                        </MDBCardBody>
                    </MDBCard>
                    {sendingData.activity_on_job && (
                        <>
                            <MDBCard className={styles.card}>
                                <MDBCardHeader>Site Details</MDBCardHeader>
                                <MDBCardBody>
                                    <MDBRow>
                                        {sendingData.primary_labour &&
                                            sendingData.primary_labour.map(type => (
                                                <MDBCol key={type.labour_type} className="mb-2" sm="3">
                                                    <label htmlFor={type.name}>{type.name}</label>
                                                    <input
                                                        type="number"
                                                        inputMode="numeric"
                                                        min="0"
                                                        step="1"
                                                        id={type.name}
                                                        name={type.name}
                                                        className="form-control form-control-sm"
                                                        value={type.number_of_workers}
                                                        onChange={props.onLabourChange}
                                                        disabled={isDisabled}
                                                    />
                                                </MDBCol>
                                            ))}
                                        <MDBCol sm="6">
                                            <label htmlFor="weather">Weather</label>
                                            <MDBRow>
                                                {props.weatherTime.map(weather => (
                                                    <MDBCol className="mb-2" sm="6" key={weather.id}>
                                                        <MDBInputGroup
                                                            prepend={weather.time}
                                                            containerClassName="input-group-sm"
                                                            inputs={
                                                                <select
                                                                    name={weather.name}
                                                                    value={sendingData[`${weather.name}`] || undefined}
                                                                    disabled={isDisabled}
                                                                    onChange={props.onValueChange}
                                                                    className="browser-default custom-select custom-select-sm"
                                                                >
                                                                    {props.weatherType.map(type => (
                                                                        <option key={type.id} value={type.id}>
                                                                            {type.name}
                                                                        </option>
                                                                    ))}
                                                                </select>
                                                            }
                                                        />
                                                    </MDBCol>
                                                ))}
                                            </MDBRow>
                                        </MDBCol>
                                    </MDBRow>
                                    <MDBRow>
                                        <MDBCol sm="12">
                                            <label htmlFor="labour_note">Labour Note</label>
                                            <textarea
                                                rows="3"
                                                id="labour_note"
                                                className="form-control form-control-sm mb-2"
                                                name="labour_note"
                                                value={sendingData.labour_note}
                                                onChange={props.onValueChange}
                                                disabled={isDisabled}
                                            ></textarea>
                                        </MDBCol>
                                    </MDBRow>
                                </MDBCardBody>
                            </MDBCard>
                            <MDBCard className={styles.card}>
                                <MDBCardHeader>Site Plans</MDBCardHeader>
                                <MDBCardBody>
                                    <label htmlFor="site_report" className="mt-2">
                                        Site Report
                                    </label>
                                    <textarea
                                        rows="3"
                                        id="site_report"
                                        className="form-control form-control-sm"
                                        name="site_report"
                                        value={sendingData.site_report}
                                        onChange={props.onValueChange}
                                        disabled={isDisabled}
                                    ></textarea>
                                    <label htmlFor="short_term_plan" className="mt-2">
                                        Short Term Plans
                                    </label>
                                    <textarea
                                        rows="3"
                                        id="short_term_plan"
                                        className="form-control form-control-sm"
                                        name="short_term_plan"
                                        value={sendingData.short_term_plan}
                                        onChange={props.onValueChange}
                                        disabled={isDisabled}
                                    ></textarea>
                                    <label htmlFor="medium_term_plan" className="mt-2">
                                        Medium Term Plans
                                    </label>
                                    <textarea
                                        rows="3"
                                        id="medium_term_plan"
                                        className="form-control form-control-sm"
                                        name="medium_term_plan"
                                        value={sendingData.medium_term_plan}
                                        onChange={props.onValueChange}
                                        disabled={isDisabled}
                                    ></textarea>
                                </MDBCardBody>
                            </MDBCard>
                        </>
                    )}
                    <MDBCard className={styles.card}>
                        <MDBCardHeader>
                            Diary Notes
                            {props.permissionLevel !== 1 && (
                                <span className="float-right">
                                    <MDBBtn color="primary" size="sm" name="add" onClick={props.addItem}>
                                        Add File
                                    </MDBBtn>
                                </span>
                            )}
                        </MDBCardHeader>
                        <MDBCardBody>
                            <label htmlFor="diary_note">Note</label>
                            <textarea
                                rows="3"
                                id="diary_note"
                                className="form-control form-control-sm"
                                name="diary_note"
                                value={sendingData.diary_note}
                                onChange={props.onValueChange}
                                disabled={isDisabled}
                            ></textarea>
                            <MDBTable className={styles.detailResponsiveTable}>
                                <MDBTableHead>
                                    <tr className={styles.textCentre}>
                                        <th width="50%">Image</th>
                                        <th width="10%">Action</th>
                                    </tr>
                                </MDBTableHead>
                                <MDBTableBody>
                                    {sendingData.files_list &&
                                        sendingData.files_list.map((file, i) => {
                                            return (
                                                <FileItem
                                                    key={i}
                                                    permission={props.permission}
                                                    fileName={file.original_file_name}
                                                    downloadImage={props.downloadImage}
                                                    id={file.id}
                                                />
                                            );
                                        })}
                                    {props.items.map((item, i) => {
                                        return (
                                            <AddItem
                                                key={i}
                                                fileInput={props.fileInput}
                                                file_category={item.file_category}
                                                file_type={props.fileCategory}
                                                fileName={item.new_file.name}
                                                onGenericFileChange={props.onGenericFileChange}
                                                idx={item.time}
                                                removeItem={props.removeItem}
                                            />
                                        );
                                    })}
                                </MDBTableBody>
                            </MDBTable>
                        </MDBCardBody>
                    </MDBCard>
                    <MDBRow>
                        <MDBCol>
                            <div className={`${styles.card} float-right`}>
                                {!pendingLoading && (
                                    <MDBBtn type="button" color="blue-grey" onClick={props.returnToList}>
                                        {props.type === "new" ? "Cancel" : "List"}
                                    </MDBBtn>
                                )}{" "}
                                {!isDisabled && <SubmitButton type={type} pendingLoading={pendingLoading} />}
                            </div>
                        </MDBCol>
                    </MDBRow>
                </form>
            )}
        </div>
    );
};

const AddItem = props => (
    <tr className={styles.addItemContainer}>
        <td data-th="File">
            <div className="custom-file">
                <input
                    type="file"
                    className="custom-file-input"
                    id={`input_${props.idx}`}
                    name="new_file"
                    ref={props.fileInput}
                    onChange={e => {
                        props.onGenericFileChange(e, props.idx);
                    }}
                />
                <label className={`custom-file-label ${styles.fileNameLabel}`} htmlFor={`input_${props.idx}`} id={`input_${props.idx}-label`}>
                    {props.fileName || "Choose file"}
                </label>
            </div>
        </td>
        <td className={styles.textCentre} data-th="Action">
            <MDBBtn
                className={styles.buttonSize}
                color="danger"
                size="sm"
                name="remove"
                onClick={e => {
                    props.removeItem(e, props.idx);
                }}
            >
                <MDBIcon icon="trash-alt" />
            </MDBBtn>
        </td>
    </tr>
);

const FileItem = props => (
    <tr className={styles.addItemContainer}>
        <td data-th="File">
            <input type="text" className="form-control" id={`file_name__${props.id}`} value={props.fileName} readOnly />
        </td>
        <td className={styles.textCentre} data-th="Action">
            <MDBBtn className={styles.buttonSize} color="success" size="sm" name="success" value={props.id} onClick={props.downloadImage}>
                <MDBIcon icon="arrow-down" />
            </MDBBtn>
        </td>
    </tr>
);

export default DiaryDetail;
