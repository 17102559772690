import React, { memo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { actionCreators as jobActions } from "redux/modules/jobs";
import Typography from "@mui/material/Typography";
import CardContainer from "./CardContainer";
import CollapsedNote from "components/Jobs/JobView/CollapsedNote";

const FollowupList = ({ listTitle, listURL }) => {
    const {
        user: { userId }
    } = useSelector(state => state);
    const dispatch = useDispatch();
    const [assignedList, setAssignedList] = useState([]);
    useEffect(() => {
        const getNotesList = async () => {
            const assignedList = await dispatch(jobActions.getFollowupList(`?${listURL}=${userId}`));
            setAssignedList(assignedList?.filter(log => !log.completed_by));
        };

        getNotesList();
    }, [dispatch, userId, listURL]);

    return (
        <CardContainer title={listTitle}>
            {assignedList.length > 0 ? (
                <ul>
                    {assignedList.map((log, i) => (
                        <li key={log.note}>
                            <CollapsedNote log={log} type="dashboard" />
                        </li>
                    ))}
                </ul>
            ) : (
                <Typography align="center" className="my-1" color="textSecondary" component="p">
                    No follow up
                </Typography>
            )}
        </CardContainer>
    );
};

export default memo(FollowupList);
