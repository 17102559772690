import React, { useState, memo } from "react";
import { Avatar, Divider, Menu, MenuItem } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { actionCreators as userActions } from "redux/modules/user";
import history from "shared/history";
import { stringAvatar } from "shared/Utils";

const AvatarMenu = ({ toggleNavbar, totalLength }) => {
    const dispatch = useDispatch();
    const { user } = useSelector(state => state);
    const welcomeName = localStorage.getItem("wName") || user.welcomeName || "Global Fire";
    const [anchorEl, setAnchorEl] = useState(null);
    const onAvataClick = e => {
        setAnchorEl(e.currentTarget);
    };
    const onDashboardClick = () => {
        setAnchorEl(null);
        toggleNavbar(false);
        history.push("/user/dashboard");
    };

    const onChangePasswordClick = () => {
        setAnchorEl(null);
        toggleNavbar(false);
        history.push("/user/changepassword");
    };
    const onAvataMenuClose = () => {
        setAnchorEl(null);
        toggleNavbar(false);
    };

    const logout = () => {
        toggleNavbar(false);
        dispatch(userActions.logOut());
    };
    return (
        <>
            <Avatar className="mx-1" {...stringAvatar(welcomeName)} onClick={onAvataClick} />
            <Menu anchorEl={anchorEl} open={!!anchorEl} onClose={onAvataMenuClose}>
                <MenuItem onClick={onDashboardClick}>Dashboard</MenuItem>
                <MenuItem onClick={onChangePasswordClick}>Change password</MenuItem>
                <Divider />
                <MenuItem onClick={logout}>Logout</MenuItem>
            </Menu>
        </>
    );
    // return (
    //     <Badge badgeContent={totalLength} color="error" overlap="circular" variant="dot">
    //         <Avatar className="mx-1" {...stringAvatar(welcomeName)} onClick={onAvataClick} />
    //         <Menu anchorEl={anchorEl} open={!!anchorEl} onClose={onAvataMenuClose}>
    //             <MenuItem onClick={onDashboardClick}>
    //                 <Badge badgeContent={totalLength} color="error">
    //                     Dashboard
    //                 </Badge>
    //             </MenuItem>
    //             <MenuItem onClick={onChangePasswordClick}>Change password</MenuItem>
    //             <Divider />
    //             <MenuItem onClick={logout}>Logout</MenuItem>
    //         </Menu>
    //     </Badge>
    // );
};

export default memo(AvatarMenu);
