import React from "react";

const SubmitButton = props => {
    const { type, pendingLoading, submitText = "submit" } = props;
    let submitButton = "";
    if (pendingLoading) {
        submitButton = (
            <>
                Wait{" "}
                <div className="spinner-border spinner-border-sm" role="status">
                    <span className="sr-only">Loading...</span>
                </div>
            </>
        );
    } else {
        if (type === "new" && props.emailAddress) {
            submitButton = `${submitText}/send`;
        } else if (type === "new") {
            submitButton = submitText;
        } else {
            submitButton = "change";
        }
    }

    return (
        <button type="submit" className="btn btn-primary" disabled={pendingLoading}>
            {submitButton}
        </button>
    );
};

export default SubmitButton;
