import React, { useState, useMemo, useEffect } from "react";
import styles from "shared/listStyles.scss";
import stockStyles from "./styles.scss";
// import detailStyles from "shared/details.scss";
import { useDispatch } from "react-redux";
import { MDBDataTable, MDBBtn, MDBIcon } from "louis-mdbreact";
import { actionCreators as stockActions } from "redux/modules/stock";

import Loading from "components/Loading";
import dayjs from "shared/dayjs";
import { Link } from "react-router-dom";
import { removeDoubleGap, THRESHOLD_DATE } from "shared/Utils";
import CreateNewButton from "components/CreateNewButton";
import { Helmet } from "react-helmet";
import { CogButton } from "components/FiltersContainer";
import TransferFilters from "./TransferFilters";

const TransferredList = () => {
    const defaultFilters = {
        isFilterOpen: false,
        created_by: "",
        source_job: "",
        destination_job: "",
        status: "",
        showOld: false
    };

    const getFilterString = newFilters => {
        let filter = "?";
        let i;
        for (i in newFilters) {
            filter += i === "showOld" && JSON.stringify(newFilters["showOld"]) === "false" ? `created_at__gte=${THRESHOLD_DATE}&` : "";
            filter +=
                newFilters[i] !== "" &&
                newFilters[i] !== "0" &&
                newFilters[i] !== "undefined" &&
                i !== "showOld" &&
                i !== "isFilterOpen" &&
                i !== "status"
                    ? `${i}=${newFilters[i]}&`
                    : "";
        }
        return filter;
    };
    const dispatch = useDispatch();
    const [transferList, setTransferList] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [searchText, setSearchText] = useState(sessionStorage.getItem("jobSearch") || "");
    const [filters, setFilters] = useState(JSON.parse(localStorage.getItem("stockFilters")) || defaultFilters);
    const [filterString, setFilterString] = useState(getFilterString(filters));
    const [activePage] = useState(sessionStorage.getItem("jobActivePage") ? Number(sessionStorage.getItem("jobActivePage")) - 1 : 0);
    useEffect(() => {
        const getDatas = async () => {
            setIsLoading(true);
            const transferList = await dispatch(
                stockActions.getTransferList({
                    statusFilter: filters.status,
                    filter: filterString
                })
            );
            setTransferList(transferList);
            setIsLoading(false);
        };
        getDatas();
    }, [dispatch, filterString, filters.status]);
    useEffect(() => {
        setFilterString(getFilterString(filters));
    }, [filters]);
    const onSearch = searchText => {
        sessionStorage.setItem("jobSearch", searchText);
        setSearchText(searchText);
    };

    const onPageChange = pageInfo => {
        sessionStorage.setItem("jobActivePage", pageInfo.activePage);
    };

    const toggleFilter = _ => {
        const newFilters = { ...filters, isFilterOpen: !filters.isFilterOpen };
        setFilters(newFilters);
        localStorage.setItem("stockFilters", JSON.stringify(newFilters));
    };

    const data = useMemo(
        () => ({
            columns: [
                { label: "ID", field: "id", sort: "asc" },
                { label: "Date", field: "created_at", sort: "asc" },
                { label: "From", field: "source_job", sort: "asc" },
                { label: "SKU", field: "sku", sort: "asc" },
                { label: "Quantity", field: "quantity", sort: "asc" },
                { label: "To", field: "destination_job", sort: "asc" },
                { label: "Logged By", field: "created_by", sort: "asc" },
                { label: "Pickup By", field: "pickup" },
                { label: "Action", field: "action" }
            ],
            rows: transferList.map(list => ({
                id: String(list.id),
                created_at: <span searchvalue={Number(new Date(list.created_at))}>{dayjs(list.created_at).format("DD/MM/YYYY")}</span>,
                search: `${dayjs(list.created_at).format("DD/MM/YYYY")} ${list.quantity}`,
                source_job: removeDoubleGap(list.source_job_string),
                sku: list.sku_string,
                quantity: list.quantity,
                destination_job: removeDoubleGap(list.destination_job_string),
                created_by: list.created_by_string,
                pickup: list.pickup_by || "-",
                rowClassName: list.uncommon_sku ? "yellow lighten-3" : "",
                action: (
                    <Link to={`/stock/transferred/${list.id}`}>
                        <MDBBtn color="primary" className={`${styles.buttonSize} ${styles.noRound}`}>
                            <MDBIcon icon="info-circle" />
                        </MDBBtn>
                    </Link>
                )
            }))
        }),
        [transferList]
    );

    return (
        <div className={styles.container}>
            <Helmet>
                <title>Stock | Transfer List</title>
            </Helmet>
            <div className={styles.titleWrap}>
                <h2 className={`${styles.taskTitle}`}>
                    <CogButton isOpen={filters.isFilterOpen} toggleOpen={toggleFilter} />
                    TRANSFER LIST
                </h2>
                <CreateNewButton />
            </div>
            <TransferFilters filters={filters} setFilters={setFilters} isOpen={filters.isFilterOpen} />
            {isLoading ? (
                <Loading loadingType="list" />
            ) : (
                <MDBDataTable
                    entriesOptions={[10, 20, 50, 100, 500]}
                    entries={20}
                    responsive
                    borderless
                    striped
                    responsiveSm
                    pagesAmount={5}
                    data={data}
                    sortRows={["created_at"]}
                    searchText={searchText}
                    initialPage={activePage}
                    onSearch={onSearch}
                    onPageChange={onPageChange}
                    className={`${styles.dataTable} ${stockStyles.dataTable} mt-3`}
                />
            )}
        </div>
    );
};

export default TransferredList;
