// imports
import { axiosInstance } from "shared/axiosInst";

// variables
const env = process.env.NODE_ENV;

// actions
// const SET_SUPERVISOR_ACTIVITIY = "SET_SUPERVISOR_ACTIVITIY";
const SET_BHAG = "SET_BHAG";

// action creators
// function setSupervisorActivity(supervisorActivity) {
//     return {
//         type: SET_SUPERVISOR_ACTIVITIY,
//         supervisorActivity
//     };
// }

function setBHAG(bhag) {
    return {
        type: SET_BHAG,
        bhag
    };
}

// function

// API actions

// function getSupervisorActivity() {
//     return async (dispatch, getState) => {
//         const {
//             user: { currentCompany },
//             reports: { supervisorActivity }
//         } = getState();
//         try {
//             if (env === "development") {
//                 return [
//                     {
//                         id: 6,
//                         first_name: "Hamish",
//                         last_name: "Funaki",
//                         face_filename: "Hamish_Funaki.png",
//                         job_sheet_value: "40877.36",
//                         board_area: null,
//                         cove_length: null
//                     },

//                     {
//                         id: 8,
//                         first_name: "Sean",
//                         last_name: "Lee",
//                         face_filename: "Sean_Lee.png",
//                         job_sheet_value: "68502.20",
//                         board_area: null,
//                         cove_length: null
//                     },

//                     {
//                         id: 69,
//                         first_name: "Paulo",
//                         last_name: "Silvestre",
//                         face_filename: "Paulo_Silvestre.png",
//                         job_sheet_value: "16660.00",
//                         board_area: null,
//                         cove_length: null
//                     },

//                     {
//                         id: 11,
//                         first_name: "Jeff",
//                         last_name: "Braga",
//                         face_filename: "Jeff_Braga.png",
//                         job_sheet_value: "39506.53",
//                         board_area: null,
//                         cove_length: null
//                     },

//                     {
//                         id: 44,
//                         first_name: "Junior",
//                         last_name: "Aguillar",
//                         face_filename: "Junior_Aguillar.png",
//                         job_sheet_value: "23080.00",
//                         board_area: null,
//                         cove_length: null
//                     },

//                     {
//                         id: 15,
//                         first_name: "Patrick",
//                         last_name: "Jardim",
//                         face_filename: "Patrick_Jardim.png",
//                         job_sheet_value: "220.00",
//                         board_area: "2350.080",
//                         cove_length: null
//                     },
//                     {
//                         id: 87,
//                         first_name: "Pedro",
//                         last_name: "Costa",
//                         face_filename: "Pedro_Costa.png",
//                         job_sheet_value: "15100.00",
//                         board_area: "246.960",
//                         cove_length: null
//                     },
//                     {
//                         id: 16,
//                         first_name: "Pedro",
//                         last_name: "Mancuso",
//                         face_filename: "Pedro_Mancuso.png",
//                         job_sheet_value: "32000.00",
//                         board_area: "10159.920",
//                         cove_length: null
//                     },

//                     {
//                         id: 17,
//                         first_name: "Renan",
//                         last_name: "Koch",
//                         face_filename: "Renan_Koch.png",
//                         job_sheet_value: "1600.00",
//                         board_area: null,
//                         cove_length: null
//                     },
//                     {
//                         id: 65,
//                         first_name: "Rhys",
//                         last_name: "Riding",
//                         face_filename: "Rhys_Riding.png",
//                         job_sheet_value: "5800.00",
//                         board_area: null,
//                         cove_length: null
//                     },
//                     {
//                         id: 89,
//                         first_name: "Steve",
//                         last_name: "Merritt",
//                         face_filename: "Steve_Merritt.png",
//                         job_sheet_value: "7000.00",
//                         board_area: null,
//                         cove_length: null
//                     },
//                     {
//                         id: 18,
//                         first_name: "Thiago",
//                         last_name: "Baptista",
//                         face_filename: "Thiago_Baptista.png",
//                         job_sheet_value: null,
//                         board_area: "217.800",
//                         cove_length: null
//                     },
//                     {
//                         id: 20,
//                         first_name: "Vini",
//                         last_name: "Koch",
//                         face_filename: "Vini_Koch.png",
//                         job_sheet_value: "23304.78",
//                         board_area: "599.040",
//                         cove_length: null
//                     }
//                 ];
//             } else {
//                 const response = await axiosInstance.get(`${currentCompany}reports/supervisor_activity/`);
//                 if (response.status === 200) {
//                     dispatch(setSupervisorActivity(response.data));
//                     return response.data;
//                 } else {
//                     return supervisorActivity || [];
//                 }
//             }
//         } catch (error) {
//             return supervisorActivity || [];
//         }
//     };
// }

function getBHAG() {
    return async (dispatch, getState) => {
        const {
            user: { currentCompany },
            reports: { bhag }
        } = getState();

        try {
            const url = env === "development" ? `${currentCompany}reports/bhag_goals/fake_data/` : `${currentCompany}reports/bhag_goals/all_regions/`;
            const response = await axiosInstance.get(url);
            if (response.status === 200) {
                dispatch(setBHAG(response.data));
                return response.data;
            } else {
                return bhag || [];
            }
        } catch (error) {
            return bhag || [];
        }
    };
}

// initial states

const initialState = {};

// reducer

function reducer(state = initialState, action) {
    switch (action.type) {
        // case SET_SUPERVISOR_ACTIVITIY:
        //     return applySupervisorActivity(state, action);
        case SET_BHAG:
            return applyBHAG(state, action);
        default:
            return state;
    }
}

// reducer function

// function applySupervisorActivity(state, action) {
//     const { supervisorActivity } = action;
//     return { ...state, supervisorActivity };
// }

function applyBHAG(state, action) {
    const { bhag } = action;
    return { ...state, bhag };
}

// export

const actionCreators = {
    // getSupervisorActivity,
    getBHAG
};

export { actionCreators };

// reducer export
export default reducer;
