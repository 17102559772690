import React, { useState } from "react";
import { ListItemButton, ListItemText, Collapse, Divider, Box } from "@mui/material";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";

const ListContainer = ({ title, children }) => {
    const [open, setOpen] = useState(false);
    const handleClick = () => {
        setOpen(!open);
    };
    return (
        <>
            <ListItemButton onClick={handleClick}>
                {open ? <ExpandLess /> : <ExpandMore />}
                <ListItemText
                    primary={
                        <Box component="span" sx={{ fontSize: "1.4rem" }}>
                            {title}
                        </Box>
                    }
                />
            </ListItemButton>
            <Collapse in={open} timeout={0}>
                <Divider />
                <Box component="div" sx={{ padding: "15px", background: "#f8f8f8" }}>
                    {open ? children : ""}
                </Box>
            </Collapse>
            <Divider />
        </>
    );
};

export default ListContainer;
