import React, { Component } from "react";
import App from "./presenter";
import * as serviceWorker from "serviceWorker";

class Container extends Component {
    constructor(props) {
        super(props);
        const { checkPermission, permissions, isLoggedIn } = this.props;
        this.state = { isReady: isLoggedIn ? false : true };
        if (permissions.job === 0 && isLoggedIn) {
            checkPermission();
        }
    }

    componentDidMount() {
        document.addEventListener("visibilitychange", event => {
            if (document.visibilityState === "visible") {
                serviceWorker.register({ immediate: true });
            }
        });
    }

    componentDidUpdate = prevProps => {
        const { permissions } = this.props;
        if (permissions !== prevProps.permissions) {
            this.setState({ isReady: true });
        }
    };

    render() {
        const { isLoggedIn, permissions, serviceWorkerUpdated, registration, isSLT, currentCompany } = this.props;
        const { isReady } = this.state;
        return isReady ? (
            <App
                isLoggedIn={isLoggedIn}
                isSLT={isSLT}
                permissions={permissions}
                serviceWorkerUpdated={serviceWorkerUpdated}
                registration={registration}
                currentCompany={currentCompany}
            />
        ) : (
            <div></div>
        );
    }
}

export default Container;
