import React, { useState, useContext } from "react";
import AddNoteModal from "../AddNoteModal";
import { QuoteContext } from "components/Crm/CrmContext";
import { axiosInstance } from "shared/axiosInst";
import { errorMessage, quoteBaseURL } from "../quoteUtils";

const withNoteModal = Component => props => {
    const { stage, title } = props;
    const { setStages, expandNote, users } = useContext(QuoteContext);
    const [addNoteOpen, setAddNoteOpen] = useState(false);
    const onAddNoteClick = () => {
        setAddNoteOpen(!addNoteOpen);
    };

    const onSubmit = async submitData => {
        try {
            const newNote = await axiosInstance.post(`${quoteBaseURL}add_follow_up_note/`, submitData);
            noteUpdate({ data: newNote.data, id: stage.id });
        } catch (error) {
            alert(errorMessage);
        }
    };

    const noteUpdate = newNoteData => {
        setAddNoteOpen(!addNoteOpen);
        setStages(current =>
            current.map(cur =>
                cur.title === title
                    ? {
                          ...cur,
                          data: cur.data.map(da =>
                              da.id === newNoteData.id
                                  ? {
                                        ...da,
                                        follow_up_notes: stage.follow_up_notes ? [...stage.follow_up_notes, newNoteData.data] : [newNoteData.data],
                                        noteOpen: expandNote
                                    }
                                  : da
                          )
                      }
                    : cur
            )
        );
    };

    return (
        <>
            <Component {...props} onAddNoteClick={onAddNoteClick} />
            {addNoteOpen && <AddNoteModal open={addNoteOpen} toggle={onAddNoteClick} onSubmit={onSubmit} stage={stage} users={users} />}
        </>
    );
};
export default withNoteModal;
