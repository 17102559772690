import React, { memo } from "react";
import { MDBRow, MDBCol, MDBInputGroup } from "louis-mdbreact";
import styles from "shared/details.scss";
import { Stack } from "@mui/material";

const Questions = ({ element, onCommentChange, onRelavantDateTimeChange, isDisabled, onResponseChange, onDateChange }) => {
    const formResponseOptions = JSON.parse(localStorage.getItem("qaResponseOption"));
    const isChosen = Number(element.response) === 1 || Number(element.response) === 2 || element.limit_responses_to.length === 0;
    const showDate = element.include_relevant_date && isChosen;
    const showTime = element.include_relevant_time && isChosen;
    const showFreeResponse = element.include_free_response && isChosen;
    // const showSignature = element.include_signature;
    return (
        <tr className={styles.addItemContainer} key={element.element}>
            <td
                colSpan={element.limit_responses_to.length === 0 && (element.include_free_response || element.include_relevant_date) ? "2" : "1"}
                data-th="Question"
            >
                <MDBRow>
                    <MDBCol middle md="7" sm="12">
                        {element.form_element_string}{" "}
                        {/* {showSignature && (
                            <Button
                                size="small"
                                variant="outlined"
                                onClick={addSignature}
                                name={element.element}
                                color="primary"
                                disabled={isDisabled}
                            >
                                Signature
                            </Button>
                        )} */}
                    </MDBCol>
                    {showDate || showTime ? (
                        <MDBCol className="mt-1" md="5" sm="12">
                            <Stack direction={{ xs: "column", sm: "column", md: "row" }} spacing={1}>
                                {showDate && (
                                    <MDBInputGroup
                                        size="sm"
                                        prepend="Date"
                                        inputs={
                                            <input
                                                className="form-control form-control-sm"
                                                type="date"
                                                name={element.element}
                                                value={element.relevant_date || ""}
                                                onChange={onRelavantDateTimeChange}
                                                disabled={isDisabled}
                                            />
                                        }
                                    />
                                )}
                                {showTime && (
                                    <MDBInputGroup
                                        size="sm"
                                        prepend="Time"
                                        inputs={
                                            <input
                                                className="form-control form-control-sm"
                                                type="time"
                                                name={element.element}
                                                value={element.relevant_time || ""}
                                                onChange={onRelavantDateTimeChange}
                                                disabled={isDisabled}
                                            />
                                        }
                                    />
                                )}
                            </Stack>
                        </MDBCol>
                    ) : undefined}
                </MDBRow>
                <MDBRow>
                    {showFreeResponse ? (
                        element.form_element_string.includes("If yes, please provide the start and finish date") ? (
                            <>
                                <MDBCol className="mt-1" md="6">
                                    <MDBInputGroup
                                        size="sm"
                                        prepend="Start Date"
                                        inputs={
                                            <>
                                                <input
                                                    type="date"
                                                    className="form-control form-control-sm"
                                                    name={`${element.element}_start`}
                                                    value={element.start}
                                                    onChange={onDateChange}
                                                    disabled={isDisabled}
                                                />
                                            </>
                                        }
                                    />
                                </MDBCol>
                                <MDBCol className="mt-1" md="6">
                                    <MDBInputGroup
                                        size="sm"
                                        prepend="End Date"
                                        inputs={
                                            <>
                                                <input
                                                    type="date"
                                                    className="form-control form-control-sm"
                                                    name={`${element.element}_end`}
                                                    value={element.end}
                                                    onChange={onDateChange}
                                                    disabled={isDisabled}
                                                />
                                            </>
                                        }
                                    />
                                </MDBCol>
                            </>
                        ) : (
                            <MDBCol className="mt-1" sm="12">
                                <textarea
                                    className="form-control form-control-sm"
                                    rows="2"
                                    name={element.element}
                                    value={element.comment}
                                    onChange={onCommentChange}
                                    disabled={isDisabled}
                                />
                            </MDBCol>
                        )
                    ) : undefined}
                </MDBRow>
            </td>
            {element.limit_responses_to.length !== 0 && (
                <td data-th="Y/N">
                    <MDBRow>
                        {element.limit_responses_to.map(response => (
                            <MDBCol style={{ textAlign: "center" }} key={response} size={`${12 / element.limit_responses_to.length}`}>
                                <div className="custom-control custom-radio custom-control-inline">
                                    <input
                                        type="radio"
                                        className="custom-control-input"
                                        checked={response === Number(element.response)}
                                        name={element.element}
                                        value={response}
                                        id={`option_${element.element}_${response}`}
                                        onChange={onResponseChange}
                                        required
                                        disabled={isDisabled}
                                    />
                                    <label htmlFor={`option_${element.element}_${response}`} className="custom-control-label">
                                        {formResponseOptions.find(option => option.id === response).name}
                                    </label>
                                </div>
                            </MDBCol>
                        ))}
                    </MDBRow>
                </td>
            )}
        </tr>
    );
};

export default memo(Questions);
