import React from "react";
import { MDBCard, MDBCardHeader, MDBCardBody, MDBRow, MDBCol, MDBBtn } from "louis-mdbreact";
import styles from "shared/details.scss";

const JobDetailCard = props => {
    const { permissions, type, jobId, isDisabled, quote_identifier, onChangeValue, price_type, business_category, priceType, jobType } = props;
    return (
        <MDBCard className={styles.card}>
            <MDBCardHeader>
                <span className="float-left">Job Details</span>
                {type === "detail" && permissions.operations === 4 ? (
                    <span className="float-right">
                        <MDBBtn color="primary" size="sm" onClick={props.toggleDiary}>
                            Site diary
                        </MDBBtn>
                    </span>
                ) : (
                    ""
                )}
            </MDBCardHeader>
            <MDBCardBody>
                <MDBRow>
                    {type !== "new" ? (
                        <MDBCol className="mb-2" sm="6">
                            <label htmlFor="job_no">Job No.</label>
                            <input type="text" className="form-control" id="job_no" name="job_no" value={jobId} readOnly disabled={!isDisabled} />
                        </MDBCol>
                    ) : (
                        ""
                    )}
                    <MDBCol className="mb-2" md={type !== "new" ? "6" : "4"} sm="6">
                        <label htmlFor="quote_identifier">Quote No.</label>
                        <input
                            type="text"
                            className="form-control"
                            id="quote_identifier"
                            name="quote_identifier"
                            value={quote_identifier}
                            onChange={onChangeValue}
                            disabled={!isDisabled}
                        />
                    </MDBCol>
                    <MDBCol className="mb-2" md={type !== "new" ? "6" : "4"} sm="6">
                        <label htmlFor="price_type">Price Type.</label>
                        <select
                            ref={props.priceTypeRef}
                            className="browser-default custom-select"
                            id="price_type"
                            name="price_type"
                            value={price_type}
                            onChange={onChangeValue}
                            disabled={!isDisabled}
                        >
                            {priceType?.map(type => (
                                <option key={type.id} value={type.id}>
                                    {type.name}
                                </option>
                            ))}
                        </select>
                    </MDBCol>
                    <MDBCol className="mb-2" md={type !== "new" ? "6" : "4"} sm="6">
                        <label htmlFor="business_category">Job Type.</label>
                        <select
                            className="browser-default custom-select"
                            id="business_category"
                            name="business_category"
                            value={business_category}
                            onChange={onChangeValue}
                            disabled={!isDisabled}
                            required
                            ref={props.jobTypeRef}
                        >
                            {jobType?.map(job => (
                                <option key={job.id} value={job.id}>
                                    {job.name}
                                </option>
                            ))}
                        </select>
                    </MDBCol>
                </MDBRow>
            </MDBCardBody>
        </MDBCard>
    );
};

export default JobDetailCard;
