import { connect } from "react-redux";
import { withRouter } from "react-router";
import Container from "./container";
import { actionCreators as OperationActions } from "redux/modules/operations";

const mapStateToProps = (state, ownProps) => {
    const {
        user: { permissions, companyPrefix, userId },
        operations: { diaryList }
    } = state;
    return {
        prefixText: companyPrefix,
        userId,
        permissions,
        diaryList
    };
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        getDiaryList: (isMine, isCurrent, normalView) =>
            dispatch(OperationActions.getDiaryList(isMine, isCurrent, normalView))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Container));
