import React, { Fragment } from "react";
import { TableCell, Typography } from "@mui/material";
import { formatter } from "shared/Utils";

const SummaryTableCell = ({ stage }) => {
    return (
        <Fragment>
            <TableCell align="right">
                <Typography component="p" variant="subtitle2">
                    {formatter.format(stage.totalValue)}
                </Typography>
                {!stage.isWon && (
                    <Typography color="green" variant="body2">
                        ({formatter.format(stage.probalbleValue)})
                    </Typography>
                )}
            </TableCell>
        </Fragment>
    );
};

export default SummaryTableCell;
