import React, { useState, useEffect, useCallback } from "react";
import styles from "./styles.scss";
import { actionCreators as reportActions } from "redux/modules/reports";
import { useDispatch } from "react-redux";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import CanvasBHAG from "./CanvasBHAG";
import Loading from "components/Loading";
// import logo from "images/logo.svg";
// import MenuSelect from "components/Reports/MenuSelect";
import { Helmet } from "react-helmet";

const INTERVAL_TIME = 120000;
// const INTERVAL_TIME = 10000;

const BHAG = () => {
    const dispatch = useDispatch();
    const [bhag, setBhag] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isFullScreen, setIsFullScreen] = useState(false);

    useEffect(() => {
        const loadBHAG = () => {
            dispatch(reportActions.getBHAG()).then(bhag => {
                setBhag(bhag);
                setIsLoading(false);
            });
        };
        loadBHAG();
        const interval = setInterval(() => {
            loadBHAG();
        }, INTERVAL_TIME);
        return () => {
            clearInterval(interval);
        };
    }, [dispatch]);

    const handle = useFullScreenHandle();
    const reportChange = useCallback(state => {
        setIsFullScreen(state);
    }, []);

    return (
        <div className={styles.container}>
            <Helmet>
                <title>Reports | BHAG</title>
            </Helmet>
            {isLoading ? (
                <Loading />
            ) : (
                <React.Fragment>
                    {/* <MenuSelect pageNum="/reports/bhag" /> */}
                    <FullScreen handle={handle} onChange={reportChange}>
                        <CanvasBHAG width={window.innerWidth} height={window.innerHeight} bhag={bhag} />
                        <div className={`${styles.titleContainer} ${isFullScreen ? styles.fullScreen : ""}`}>
                            {/* <div className={styles.logo}>
                                <img src={logo} alt="Global Fire Logo" />
                            </div> */}
                            <h1 className={styles.title}>
                                <p>BHAG ({bhag[0].financial_year})</p>
                            </h1>
                        </div>
                        <p className={`${styles.attribution} ${isFullScreen ? styles.fullScreen : ""} text-right`}>
                            <a href="https://www.vecteezy.com/free-vector/traffic" target="_blank" rel="noopener noreferrer">
                                Traffic
                            </a>
                            ,{" "}
                            <a href="https://www.vecteezy.com/free-vector/building" target="_blank" rel="noopener noreferrer">
                                Building
                            </a>
                            ,{" "}
                            <a href="https://www.vecteezy.com/free-vector/home" target="_blank" rel="noopener noreferrer">
                                Home
                            </a>
                            ,{" "}
                            <a href="https://www.vecteezy.com/free-vector/damascus" target="_blank" rel="noopener noreferrer">
                                Damascus
                            </a>
                            ,{" "}
                            <a href="https://www.vecteezy.com/free-vector/auckland" target="_blank" rel="noopener noreferrer">
                                Auckland
                            </a>
                            ,{" "}
                            <a href="https://www.vecteezy.com/free-vector/locomotive" target="_blank" rel="noopener noreferrer">
                                Locomotive
                            </a>
                            ,{" "}
                            <a href="https://www.vecteezy.com/free-vector/couple" target="_blank" rel="noopener noreferrer">
                                Couple
                            </a>
                            ,{" "}
                            <a href="https://www.vecteezy.com/free-vector/train-conductor" target="_blank" rel="noopener noreferrer">
                                Train Conductor
                            </a>{" "}
                            Vectors by Vecteezy
                            <br />
                            <a href="https://www.freepik.com/free-photos-vectors/cartoon" target="_blank" rel="noopener noreferrer">
                                Cartoon vector
                            </a>{" "}
                            created by freepik
                        </p>
                    </FullScreen>
                    <Box position="fixed" bottom="10px" right="10px">
                        <Button variant="outlined" color="primary" onClick={handle.enter}>
                            Enter fullscreen
                        </Button>
                    </Box>
                </React.Fragment>
            )}
        </div>
    );
};

export default BHAG;
