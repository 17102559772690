import React from "react";
import styles from "shared/modalStyles.scss";
import Calendar from "react-calendar";
import dayjs from "shared/dayjs";
import DialogContainer from "components/DialogContainer";
import { DialogContent } from "@mui/material";

const SiteDiaryModal = props => {
    return (
        <DialogContainer
            open={props.diaryModal}
            handleClose={props.toggleDiary}
            maxWidth="sm"
            fullWidth={true}
            fullScreen={false}
            title="Site Diary Calendar"
        >
            <DialogContent>
                <Calendar
                    className={styles.customCalendar}
                    onChange={props.onCalendarChange}
                    value={props.selectedDate}
                    calendarType="US"
                    tileClassName={data => {
                        const activeDate =
                            props.datesOnActive &&
                            props.datesOnActive.find(
                                active =>
                                    active.diary_date === dayjs(data.date).format("YYYY-MM-DD")
                            );
                        return activeDate ? styles.activeCalendar : undefined;
                    }}
                />
            </DialogContent>
        </DialogContainer>
    );
};

export default SiteDiaryModal;
