import React from "react";
import { Route, Switch } from "react-router-dom";
import TimesheetView from "components/Tasks/TimesheetView";
import Rostering from "components/Tasks/Roster";
// import Timesheet from "components/Tasks/Timesheet";
import TimeView from "components/Tasks/TimeView";
import TimeApprove from "components/Tasks/TimeApprove";
import Assignments from "components/Tasks/Assignments";
import TimeLogs from "components/Tasks/TimeLogs";

const TaskRoute = ({ permissions }) => (
    <Switch>
        <Route exact path="/tasks" component={Rostering} />
        {/* <Route exact path="/tasks" component={Tasks} />
        <Route exact path="/tasks/requested" component={TasksRequested} />
        <Route exact path="/tasks/timesheet" component={TimesheetView} /> */}
        <Route exact path="/tasks/approve" component={TimeApprove} />
        <Route exact path="/tasks/timelogs" component={TimeLogs} />
        {/* {permissions.maintenance === 4 || permissions.maintenance === 3 ? (
            <Route path="/tasks/new" key="new" render={props => <TaskDetail type="new" />} />
        ) : (
            <Redirect from="/tasks/new" to="/tasks" />
        )} */}
        {/* <Route path="/tasks/dayworks" component={Dayworks} /> */}
        <Route path="/tasks/rostering" component={Rostering} />
        <Route path="/tasks/assignments" component={Assignments} />
        <Route path="/tasks/timesheet" component={TimesheetView} />
        {/* <Route exact path="/tasks/:id" key="detail" render={props => <TaskDetail type="detail" />} />
        <Route path="/tasks/requested/:id" key="requested" render={props => <TaskDetail type="requested" />} />
        <Route exact path="/tasks/:id" key="detail" render={props => <TaskDetail type="detail" />} />
        <Route exact path="/tasks/requested/:id" key="requested" render={props => <TaskDetail type="requested" />} /> */}
        <Route exact path="/tasks/timesheet/:id" component={TimeView} />
    </Switch>
);

export default TaskRoute;
