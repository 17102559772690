import React, { useEffect, useState } from "react";
import { FiltersContainer } from "components/FiltersContainer";
import { Label } from "components/HumanResources/CustomComponent";
import { Grid, Stack, Checkbox, FormControlLabel } from "@mui/material";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import { actionCreators as jobActions } from "redux/modules/jobs";
import { actionCreators as userActions } from "redux/modules/user";

const regions = JSON.parse(localStorage.getItem("regions"));
const statusList = [{ value: "unsynced/", label: "Unsynced" }];

const TransferFilters = ({ isOpen, filters, setFilters }) => {
    const {
        user: { permissions }
    } = useSelector(state => state);
    const dispatch = useDispatch();
    const [hasFilter, setHasFilter] = useState(false);
    const [jobList, setJobList] = useState([]);
    const [supervisors, setSupervisors] = useState([]);
    useEffect(() => {
        const filter = Object.keys(filters).reduce((total, current) => {
            if (current === "isFilterOpen") return false;
            return filters[current] || total;
        }, false);
        setHasFilter(filter);
    }, [filters]);
    useEffect(() => {
        const getDatas = async () => {
            const jobList = await dispatch(jobActions.getSlimJobs(false, true));
            setJobList(
                jobList.map(job => ({
                    value: job.id,
                    label: `${job.id}-${regions.find(region => region.id === job.region).region_short_code} (${job.job_name})`
                }))
            );
            const supervisorList = await dispatch(userActions.getSupervisors());
            setSupervisors(supervisorList);
        };
        if (!regions) {
            dispatch(userActions.getUserCommons(true));
        }
        getDatas();
    }, [dispatch]);

    const onValueChange = ({ event, filterName }) => {
        const value = event.target.value === "on" ? event.target.checked : event.target.value;
        const newFilter = { ...filters, [filterName]: value };
        setFilters(newFilter);
        localStorage.setItem("stockFilters", JSON.stringify(newFilter));
    };

    const clearFilters = () => {
        const newFilter = { isFilterOpen: isOpen, showOld: false };
        setFilters(newFilter);
        localStorage.setItem("stockFilters", JSON.stringify(newFilter));
    };

    return (
        <FiltersContainer isOpen={isOpen} hasFilter={hasFilter} clearFilters={clearFilters} title="Stock Filters">
            <Grid container spacing={1}>
                <Grid item sm={6} xs={12} md={6}>
                    <Label>From</Label>
                    <Select
                        inputId="source_job"
                        options={jobList}
                        isClearable
                        menuPosition="fixed"
                        value={jobList.find(list => list.value === filters.source_job) || ""}
                        onChange={option => {
                            onValueChange({
                                event: {
                                    target: {
                                        value: option?.value || ""
                                    }
                                },
                                filterName: "source_job"
                            });
                        }}
                    />
                </Grid>
                <Grid item sm={6} xs={12} md={6}>
                    <Label>To</Label>
                    <Select
                        inputId="destination_job"
                        options={jobList}
                        isClearable
                        menuPosition="fixed"
                        value={jobList.find(list => list.value === filters.destination_job) || ""}
                        onChange={option => {
                            onValueChange({
                                event: {
                                    target: {
                                        value: option?.value || ""
                                    }
                                },
                                filterName: "destination_job"
                            });
                        }}
                    />
                </Grid>
                <Grid item sm={6} xs={12} md={6}>
                    <Label>Logged By</Label>
                    <Select
                        inputId="created_by"
                        options={supervisors}
                        isClearable
                        menuPosition="fixed"
                        value={
                            supervisors.find(supervisor => {
                                return supervisor.id === filters.created_by;
                            }) || ""
                        }
                        getOptionLabel={option => option?.name_string}
                        getOptionValue={option => option?.id}
                        onChange={option => {
                            onValueChange({
                                event: {
                                    target: {
                                        value: option?.id || ""
                                    }
                                },
                                filterName: "created_by"
                            });
                        }}
                    />
                </Grid>
                {permissions.stock === 4 && (
                    <Grid item sm={6} xs={12} md={6}>
                        <Label>Status</Label>
                        <Select
                            inputId="status"
                            options={statusList}
                            isClearable
                            menuPosition="fixed"
                            value={statusList.find(status => status.value === filters.status)}
                            onChange={option => {
                                onValueChange({
                                    event: {
                                        target: {
                                            value: option?.value || ""
                                        }
                                    },
                                    filterName: "status"
                                });
                            }}
                        />
                    </Grid>
                )}
                <Grid item xs={12}>
                    <Label>Others</Label>
                    <Stack direction={{ md: "row", xs: "column" }} gap={1}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={filters.uncommon_sku || false}
                                    onChange={event => {
                                        onValueChange({ event, filterName: "uncommon_sku" });
                                    }}
                                />
                            }
                            label="Uncommon SKU"
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={filters.myob_record_exists || false}
                                    onChange={event => {
                                        onValueChange({ event, filterName: "myob_record_exists" });
                                    }}
                                />
                            }
                            label="Show All MYOB record"
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={filters.showOld || false}
                                    onChange={event => {
                                        onValueChange({ event, filterName: "showOld" });
                                    }}
                                />
                            }
                            label="Show old transfers"
                        />
                    </Stack>
                </Grid>
            </Grid>
        </FiltersContainer>
    );
};

export default TransferFilters;
