import React, { useState, useEffect, useContext } from "react";
import { Grid, Stack, Box, IconButton, CircularProgress } from "@mui/material";
import WindowedSelect from "react-windowed-select";
import RequestedItemList from "./RequestedItemList";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { useDispatch, useSelector } from "react-redux";
import { RosterCalendarContext, RosterContext } from "../TaskContext";
import { actionCreators as userActions } from "redux/modules/user";

const RequestedItem = () => {
    const {
        jobs: { jobList },
        tasks: { rosterList }
    } = useSelector(state => state);
    const dispatch = useDispatch();
    const { setAddedJobList, addedJobList, loading } = useContext(RosterContext);
    const { assignedList } = useContext(RosterCalendarContext);
    const [addingJob, setAddingJob] = useState();
    // const [leaves, setLeaves] = useState([]);
    const [refinedJobList, setRefinedJobList] = useState([]);
    const { nextWorkingDate } = useSelector(state => state.humanResources);
    const [nextWorkingDateList, setNextWorkDateList] = useState([]);

    useEffect(() => {
        if (nextWorkingDate && assignedList?.length > 0) {
            setNextWorkDateList(assignedList.filter(list => list.assigned_date === nextWorkingDate));
        }
    }, [nextWorkingDate, assignedList]);

    useEffect(() => {
        const regions = JSON.parse(localStorage.getItem("regions")) || dispatch(userActions.getUserCommons(true));
        const refinedJobList = jobList?.map(job => ({
            ...job,
            short_region: regions.find(reg => reg.id === job.region).region_short_code
        }));
        setRefinedJobList(refinedJobList);
    }, [jobList, dispatch]);

    useEffect(() => {
        if (loading) {
            setAddingJob(null);
        }
    }, [loading]);

    const onAddJobClick = () => {
        if (!addingJob) {
            alert("Please select a job or leave you want to roster.");
            return;
        }
        const alreadyAdded = addedJobList?.find(added => added.id === addingJob?.id) || rosterList?.find(list => list.id === addingJob?.id);
        if (!!alreadyAdded) {
            alert("This job has been added.");
            return;
        }
        setAddedJobList(current => [...current, { ...addingJob, isAdded: true }]);
    };

    return (
        <Grid container item xs={12} sm={12} md={12} lg={2} spacing={1}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
                <Stack direction="row" justifyContent="flex-start" spacing={1} sx={{ position: "relative", paddingRight: "40px" }}>
                    <WindowedSelect
                        styles={{
                            container: (baseStyles, state) => ({
                                ...baseStyles,
                                width: "100%"
                            }),
                            option: (baseStyles, state) => ({
                                ...baseStyles,
                                fontSize: "0.9rem",
                                background: state.isSelected ? "#2684FF" : state.isFocused ? "#DEEBFF" : state.data?.id < 300 ? "#fff9c4" : ""
                            }),
                            menuPortal: baseStyles => ({
                                ...baseStyles,
                                zIndex: 10
                            }),
                            menu: (baseStyles, state) => ({
                                ...baseStyles,
                                width: "calc(100% + 40px)",
                                zIndex: 10
                            })
                        }}
                        menuPosition="fixed"
                        value={addingJob}
                        onChange={option => setAddingJob(option)}
                        options={refinedJobList || []}
                        getOptionLabel={option => `${`${option.id}-${option.short_region} - ${option.job_name}`}`}
                        getOptionValue={option => option.id}
                    />
                    <Box sx={{ position: "absolute", top: "3px", right: 0, width: "34px" }}>
                        {loading ? (
                            <CircularProgress size={32} />
                        ) : (
                            <IconButton size="small" color="primary" variant="contained" onClick={onAddJobClick}>
                                <AddCircleIcon />
                            </IconButton>
                        )}
                    </Box>
                </Stack>
            </Grid>
            <RequestedItemList nextWorkingDateList={nextWorkingDateList} />
        </Grid>
    );
};

export default RequestedItem;
