import React, { memo } from "react";
import { Chip } from "@mui/material";
import { makeStyles } from "@mui/styles";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import { useDispatch } from "react-redux";
import { actionCreators as jobActions } from "redux/modules/jobs";

const fileStyle = makeStyles(theme => ({
    root: {
        marginTop: theme.spacing(0.5),
        marginRight: theme.spacing(0.5),
        "& .MuiChip-label": {
            [theme.breakpoints.down("sm")]: {
                maxWidth: "260px"
            }
        }
    }
}));

const NoteFileItem = ({ file, deleteFile = null }) => {
    const dispatch = useDispatch();
    const classes = fileStyle();
    const onFileClick = () => {
        dispatch(jobActions.fileDownload(file.id));
    };
    return (
        <Chip
            className={classes.root}
            variant="outlined"
            size="small"
            color={deleteFile ? "primary" : "default"}
            label={file?.original_file_name || file?.new_file?.name}
            onClick={deleteFile ? null : onFileClick}
            onDelete={
                deleteFile === null
                    ? null
                    : () => {
                          deleteFile(file?.idx);
                      }
            }
            icon={<DescriptionOutlinedIcon />}
        />
    );
};

export default memo(NoteFileItem);
