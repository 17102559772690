import React, { memo, useState, useEffect } from "react";
import { MDBCard, MDBCardHeader, MDBCardBody, MDBBtn } from "louis-mdbreact";
import styles from "shared/details.scss";
import NoteList from "./NoteList";

const Notes = ({ type, getSendingData }) => {
    const [sendingNotes, setSendingNotes] = useState([]);
    // const [isValidated, setIsValidated] = useState();

    // const submitNotes = event => {
    //     event.preventDefault();
    //     setIsValidated(true);
    // };

    useEffect(() => {
        const validated = sendingNotes.reduce((bool, note) => bool && note.note !== "", true);
        type === "new" && getSendingData("notes", sendingNotes, validated);
    }, [sendingNotes, getSendingData, type]);

    const addNote = event => {
        setSendingNotes([
            ...sendingNotes,
            { timestamp: Number(new Date()), note: "", pinned: false }
        ]);
    };

    const onEdit = (timestamp, event) => {
        const { name, value } = event;
        const newNotes = sendingNotes.map(note =>
            note.timestamp === timestamp ? { ...note, [name]: value } : note
        );
        setSendingNotes(newNotes);
    };

    const onDelete = event => {
        const {
            currentTarget: { name }
        } = event;
        const newNotes = sendingNotes.filter(note => Number(name) !== note.timestamp);
        setSendingNotes(newNotes);
    };

    return (
        <MDBCard className={styles.card}>
            <MDBCardHeader>
                Notes
                <div className="position-absolute" style={{ right: "15px", top: "9px" }}>
                    <MDBBtn color="primary" size="sm" name="note" onClick={addNote}>
                        add note
                    </MDBBtn>
                </div>
            </MDBCardHeader>
            <MDBCardBody>
                {sendingNotes.map(note => (
                    <NoteList
                        key={note.timestamp}
                        timestamp={note.timestamp}
                        content={note.note}
                        pinned={note.pinned}
                        onEdit={onEdit}
                        onDelete={onDelete}
                        type="new"
                    />
                ))}
            </MDBCardBody>
        </MDBCard>
    );
};

export default memo(Notes);
