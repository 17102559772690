import React from "react";
import styles from "shared/modalStyles.scss";
import { MDBTable, MDBTableHead, MDBTableBody, MDBBtn, MDBBtnGroup, MDBIcon } from "louis-mdbreact";
import { Link } from "react-router-dom";
import dayjs from "shared/dayjs";
import Checkbox from "@mui/material/Checkbox";
import RecipientsForm from "components/RecipientsForm";
import DialogContainer from "components/DialogContainer";
import { DialogContent } from "@mui/material";

const QAReportModal = props => {
    return (
        <DialogContainer
            open={props.QAModal || false}
            handleClose={props.toggleQA}
            fullScreen={false}
            maxWidth="md"
            title={props.isPre ? "Pre QA List" : "QA Report List"}
            isLoading={props.isLoading}
        >
            <DialogContent className={styles.card}>
                {props.elementChecked && (
                    <RecipientsForm
                        hasSendBtn={true}
                        toggleQA={props.toggleQA}
                        jobInfo={props.selectedJob}
                        subInfo={{ qa: props.checkedList, submitedQA: props.isPre }}
                        setupValid={props.setupValid}
                    />
                )}
                <MDBTable small striped className={styles.responsiveTable}>
                    <MDBTableHead>
                        <tr>
                            <th width="1%">
                                <label htmlFor="check_total" className="mb-0">
                                    <Checkbox
                                        sx={{ padding: "0" }}
                                        onChange={props.onCheckTotal}
                                        checked={props.checkTotal}
                                        name="check_total"
                                        id="check_total"
                                    />
                                </label>
                            </th>
                            <th width="6%">ID</th>
                            <th width="18%">Date</th>
                            <th>Area</th>
                            <th width="20%">QA Type</th>
                            <th width="80px">Items</th>
                            <th width="80px">Images</th>
                            <th className="text-center" width="90px">
                                Actions
                            </th>
                        </tr>
                    </MDBTableHead>
                    <MDBTableBody>
                        {props.QAList &&
                            props.QAList.map(qa => (
                                <tr
                                    key={qa.id}
                                    className={qa.qa_report_sent ? "yellow lighten-2" : undefined}
                                >
                                    <td data-th="Sending" className="align-middle">
                                        <label htmlFor={`check_${qa.id}`} className="mb-0">
                                            <Checkbox
                                                sx={{ padding: "0" }}
                                                onChange={props.onQAListCheck}
                                                name={`check_${qa.id}`}
                                                id={`check_${qa.id}`}
                                                checked={qa.checked}
                                            />
                                        </label>
                                    </td>
                                    <td data-th="ID" className="align-middle">
                                        {qa.id}
                                    </td>
                                    <td data-th="Date" className="align-middle">
                                        {dayjs(qa.date).format("DD/MM/YYYY")}
                                    </td>
                                    <td data-th="Area" className="align-middle">
                                        {qa.area}
                                    </td>
                                    <td data-th="QA Type" className="align-middle">
                                        {qa.qa_type_string}
                                    </td>
                                    <td data-th="Items" className="align-middle">
                                        {qa.qa_item_count}
                                    </td>
                                    <td data-th="Images" className="align-middle">
                                        {qa.qa_item_image_count}
                                    </td>
                                    <td data-th="Actions" className="align-middle">
                                        <MDBBtnGroup className={styles.buttonGroup}>
                                            <Link
                                                to={`/operations/${
                                                    props.isPre
                                                        ? qa.qa_type_string === "Pre QA"
                                                            ? "preqa"
                                                            : "observations"
                                                        : "qa"
                                                }/${qa.id}`}
                                            >
                                                <MDBBtn
                                                    size="sm"
                                                    color="primary"
                                                    className={styles.buttonSize}
                                                >
                                                    <MDBIcon icon="info-circle" />
                                                </MDBBtn>
                                            </Link>
                                            <MDBBtn
                                                color="success"
                                                size="sm"
                                                value={qa.id}
                                                onClick={props.downloadQAReport}
                                                className={styles.buttonSize}
                                            >
                                                {qa.pending ? (
                                                    <div
                                                        className="spinner-border spinner-border-sm"
                                                        role="status"
                                                    >
                                                        <span className="sr-only">Loading...</span>
                                                    </div>
                                                ) : (
                                                    <MDBIcon icon="file-download" />
                                                )}
                                            </MDBBtn>
                                        </MDBBtnGroup>
                                    </td>
                                </tr>
                            ))}
                    </MDBTableBody>
                </MDBTable>
            </DialogContent>
        </DialogContainer>
    );
};

export default QAReportModal;
