import React from "react";
import { MDBCard, MDBCardHeader, MDBCardBody, MDBRow, MDBCol, MDBBtn } from "louis-mdbreact";
import styles from "shared/details.scss";
import ValidSelect from "components/ValidSelect";

const ClientContactDetail = props => {
    const { resource, client, permissions, addNewClient, client_qs_entity, onClientContactChange, clientContact } = props;
    return (
        <MDBCard className={styles.card}>
            <MDBCardHeader>
                <span className="float-left">Client Contact Details</span>
                {(resource !== "" || client !== "") && permissions.job !== 1 ? (
                    <span className="float-right">
                        <MDBBtn color="primary" size="sm" name="newContact" onClick={addNewClient}>
                            New Contact
                        </MDBBtn>
                    </span>
                ) : (
                    ""
                )}
            </MDBCardHeader>
            <MDBCardBody>
                <MDBRow>
                    <MDBCol>
                        <label htmlFor="client_qs">Client QS</label>
                        <ValidSelect
                            options={clientContact}
                            placeholder="Search contact..."
                            value={client_qs_entity || ""}
                            onChange={onClientContactChange}
                        />
                    </MDBCol>
                </MDBRow>
                {/* <MDBRow className={styles.formRow}>
                    <MDBCol>
                        <label htmlFor="fire_engineer">Fire Engineer</label>
                        <select
                            className="browser-default custom-select"
                            id="fire_engineer"
                            name="fire_engineer"
                            value={sendingDataFireEngineer || ""}
                            onChange={onChangeValue}
                            disabled={!isDisabled || isNewClient}
                        >
                            <option disabled value="">
                                Select...
                            </option>
                            {baseDataFireEngineer?.map(engineer => (
                                <option key={engineer.value} value={engineer.value}>
                                    {engineer.label}
                                </option>
                            ))}
                        </select>
                    </MDBCol>
                </MDBRow> */}
            </MDBCardBody>
        </MDBCard>
    );
};

export default ClientContactDetail;
