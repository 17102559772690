import { connect } from "react-redux";
import Container from "./container";
import { withRouter } from "react-router";
import { actionCreators as operationActions } from "redux/modules/operations";
// import { getContactListForSelect } from "redux/reducers";

const mapStateToProps = (state, ownProps) => {
    const {
        user: { isMobile },
        operations: { operationalFormList }
    } = state;
    const { selectedJob } = ownProps;
    return {
        isMobile,
        selectedJob,
        operationalFormList
    };
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        getOperationalFormList: id => dispatch(operationActions.getOperationalFormList(id)),
        downloadOperationalForm: (ids, signal) =>
            dispatch(operationActions.downloadOperationalForm(ids, signal)),
        fileDownload: id => dispatch(operationActions.fileDownload(id))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Container));
