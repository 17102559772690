import React, { useState, useEffect } from "react";
import styles from "shared/details.scss";
import ArrowBackButton from "components/ArrowBackButton";
import { useSelector, useDispatch } from "react-redux";
import Loading from "components/Loading";
import { MDBTable, MDBTableBody, MDBRow, MDBCol } from "louis-mdbreact";
import { actionCreators as stockActions } from "redux/modules/stock";
import { actionCreators as jobActions } from "redux/modules/jobs";
import { actionCreators as materialActions } from "redux/modules/material";
import dayjs from "shared/dayjs";
import TextField from "@mui/material/TextField";
import { makeStyles } from "@mui/styles";
import { Controller, useForm } from "react-hook-form";
import AsyncSelect from "react-select/async";
import VaildSelect from "components/ValidSelect";
import { Helmet } from "react-helmet";

const UNCOMMON_YELLOW = "#fff176";

const useStyles = makeStyles({
    root: {
        background: "white"
    }
});

const idealStyles = {
    option: (styles, { data, isSelected }) => {
        return {
            ...styles,
            backgroundColor: isSelected ? "#2684FF" : data?.uncommon_sku ? UNCOMMON_YELLOW : ""
        };
    }
};
const warningStyles = {
    control: provided => ({
        ...provided,
        borderColor: "red"
    }),
    ...idealStyles
};

const TransferredView = props => {
    const {
        user: { permissions }
    } = useSelector(state => state);
    const {
        history,
        match: {
            params: { id }
        }
    } = props;
    const dispatch = useDispatch();
    const classes = useStyles();
    const [isLoading, setIsLoading] = useState(true);
    const [details, setDetails] = useState({});
    const [editMode, setEditMode] = useState(false);
    const [jobNames, setJobNames] = useState([]);
    const [skuDatabase, setSkuDatabase] = useState([]);
    const [isUncommonSKU, setIsUncommonSKU] = useState(false);
    const {
        handleSubmit,
        control,
        register,
        formState: { errors },
        reset
    } = useForm({
        defaultValues: {
            id: "",
            destination_job: "",
            source_job: "",
            sku: "",
            quantity: ""
        }
    });
    useEffect(() => {
        dispatch(stockActions.getTransferDetail(id)).then(value => {
            setDetails(value);
            setIsLoading(false);
            reset({
                id: value.id,
                destination_job: value.destination_job,
                source_job: value.source_job,
                sku: value.sku,
                quantity: value.quantity
            });
            setIsUncommonSKU(value.uncommon_sku);
        });
        const loadJobList = async () => {
            const jobList = await dispatch(jobActions.getSlimJobs(false, true));
            const regions = JSON.parse(localStorage.getItem("regions"));
            setJobNames(
                jobList.map(job => ({
                    value: job.id,
                    label: `${job.id}-${regions.find(region => region.id === job.region).region_short_code} (${job.job_name})`
                }))
            );
            const skuList = await dispatch(materialActions.getSlimSkuDatabase());
            setSkuDatabase([...skuList.filter(list => !list.uncommon_sku), ...skuList.filter(list => list.uncommon_sku)]);
        };
        loadJobList();
    }, [dispatch, id, reset]);

    const returnToList = () => {
        history.goBack();
    };
    const onEditClick = () => {
        setEditMode(true);
    };
    const onSubmit = data => {
        setIsLoading(true);
        dispatch(stockActions.modifyTransfer(data)).then(value => {
            setDetails(value);
            setIsLoading(false);
            setEditMode(false);
        });
    };
    const filterData = inputValue => {
        return skuDatabase.filter(i => i.description.toLowerCase().includes(inputValue.toLowerCase()));
    };
    const promiseOptions = inputValue =>
        new Promise(resolve => {
            setTimeout(() => {
                resolve(filterData(inputValue));
            }, 1000);
        });
    return (
        <div className={styles.container}>
            <Helmet>
                <title>
                    Stock | Transfer {">"} {details?.sku_string || ""}
                </title>
            </Helmet>
            <h2 className={`${styles.title} align-middle`}>
                <ArrowBackButton />
                TRANSFER DETAILS
            </h2>
            {isLoading ? (
                <Loading loadingType="detail" />
            ) : (
                <form onSubmit={handleSubmit(onSubmit)}>
                    <MDBRow>
                        <MDBCol>
                            <h3 className="mb-3" style={{ fontSize: "1.25rem" }}>
                                From
                            </h3>
                            <input type="hidden" defaultValue={Number(details.id)} {...register("id")} />
                            {/* <input type="hidden" defaultValue={Number(details.source_job)} {...register("source_job")} />
                            <input type="hidden" defaultValue={Number(details.destination_job)} {...register("destination_job")} /> */}
                            <MDBTable small borderless striped>
                                <MDBTableBody>
                                    <tr>
                                        <th width="30%" className="orange-text">
                                            Date
                                        </th>
                                        <td width="70%">{dayjs(details.created_at).format("DD/MM/YYYY")}</td>
                                    </tr>
                                    <tr>
                                        <th className="orange-text">Job</th>
                                        <td>
                                            {editMode ? (
                                                <Controller
                                                    name={`source_job`}
                                                    control={control}
                                                    rules={{ required: true }}
                                                    render={({ field: { value, onChange }, ...rest }) => (
                                                        <VaildSelect
                                                            styles={errors?.source_job?.type === "required" ? warningStyles : idealStyles}
                                                            options={jobNames}
                                                            value={jobNames.find(name => name.value === value)}
                                                            onChange={option => onChange(option.value)}
                                                            {...rest}
                                                        />
                                                    )}
                                                />
                                            ) : (
                                                details.source_job_string
                                            )}
                                        </td>
                                    </tr>
                                    <tr style={{ backgroundColor: isUncommonSKU ? UNCOMMON_YELLOW : "" }}>
                                        <th className="orange-text">SKU</th>
                                        <td>
                                            {/* {details.sku_string} */}
                                            {editMode ? (
                                                <Controller
                                                    name={`sku`}
                                                    control={control}
                                                    rules={{ required: true }}
                                                    render={({ field: { onChange, value }, field }) => (
                                                        <>
                                                            {skuDatabase.length > 0 ? (
                                                                <AsyncSelect
                                                                    inputId="sku"
                                                                    styles={errors?.sku?.type === "required" ? warningStyles : idealStyles}
                                                                    cacheOptions
                                                                    defaultOptions
                                                                    components={{ DropdownIndicator: null }}
                                                                    onChange={option => {
                                                                        onChange(option.id);
                                                                        setIsUncommonSKU(option.uncommon_sku);
                                                                    }}
                                                                    value={skuDatabase.find(sku => sku.id === value)}
                                                                    getOptionLabel={option => option.description}
                                                                    getOptionValue={option => option.id}
                                                                    loadOptions={promiseOptions}
                                                                    placeholder="Search..."
                                                                />
                                                            ) : (
                                                                <TextField
                                                                    error={errors?.sku?.type === "required"}
                                                                    className={classes.root}
                                                                    fullWidth
                                                                    size="small"
                                                                    {...field}
                                                                />
                                                            )}
                                                        </>
                                                    )}
                                                />
                                            ) : (
                                                details.sku_string
                                            )}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th className="orange-text">Quantity</th>
                                        <td>
                                            {editMode ? (
                                                <Controller
                                                    name="quantity"
                                                    defaultValue={details.quantity}
                                                    control={control}
                                                    render={({ field }) => (
                                                        <TextField
                                                            type="number"
                                                            fullWidth
                                                            variant="outlined"
                                                            size="small"
                                                            className={classes.root}
                                                            {...field}
                                                        />
                                                    )}
                                                />
                                            ) : (
                                                details.quantity
                                            )}
                                        </td>
                                    </tr>
                                </MDBTableBody>
                            </MDBTable>
                        </MDBCol>
                    </MDBRow>
                    <MDBRow>
                        <MDBCol>
                            <h3 className="mb-3" style={{ fontSize: "1.25rem" }}>
                                To
                            </h3>
                            <MDBTable small borderless striped>
                                <MDBTableBody>
                                    <tr>
                                        <th width="30%" className="orange-text">
                                            Job
                                        </th>
                                        <td>
                                            {editMode ? (
                                                <Controller
                                                    name={`destination_job`}
                                                    control={control}
                                                    rules={{ required: true }}
                                                    render={({ field: { value, onChange }, ...rest }) => (
                                                        <VaildSelect
                                                            styles={errors?.destination_job?.type === "required" ? warningStyles : idealStyles}
                                                            options={jobNames}
                                                            value={jobNames.find(name => name.value === value)}
                                                            onChange={option => onChange(option.value)}
                                                            {...rest}
                                                        />
                                                    )}
                                                />
                                            ) : (
                                                details.destination_job_string
                                            )}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th className="orange-text">Logged By</th>
                                        <td>{details.created_by_string}</td>
                                    </tr>
                                    <tr>
                                        <th className="orange-text">Pickup By</th>
                                        <td>{details.pickup_by || "-"}</td>
                                    </tr>
                                    {details.directed_to ? (
                                        <tr>
                                            <th className="orange-text">User of Consumable</th>
                                            <td>{details.directed_to}</td>
                                        </tr>
                                    ) : undefined}
                                </MDBTableBody>
                            </MDBTable>
                        </MDBCol>
                    </MDBRow>
                    {/* {permissions.stock === 4 && <PricedComponent paramId={id} pricing_record={details.pricing_record_exists} />} */}
                    <MDBRow style={{ display: editMode ? "none" : "block" }}>
                        <MDBCol>
                            <div className={`${styles.card} float-right`}>
                                <button type="button" className="btn btn-blue-grey" onClick={returnToList}>
                                    List
                                </button>{" "}
                                {permissions.stock === 4 && !details.myob_record_exists ? (
                                    <button type="button" className="btn btn-primary" onClick={onEditClick}>
                                        Edit
                                    </button>
                                ) : (
                                    ""
                                )}
                            </div>
                        </MDBCol>
                    </MDBRow>
                    <MDBRow style={{ display: editMode ? "block" : "none" }}>
                        <MDBCol>
                            <div className={`${styles.card} float-right`}>
                                <button type="button" className="btn btn-blue-grey" onClick={() => setEditMode(false)}>
                                    Cancel
                                </button>{" "}
                                <button type="submit" className="btn btn-primary">
                                    submit
                                </button>
                            </div>
                        </MDBCol>
                    </MDBRow>
                </form>
            )}
        </div>
    );
};

export default TransferredView;
