import { Stack, Tooltip } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import FiberManualRecordOutlinedIcon from "@mui/icons-material/FiberManualRecordOutlined";
import dayjs from "dayjs";

const LENGTH_OF_DECISIONS = 2;
const Decisions = props => {
    const [decisions, setDecisions] = useState(Array(LENGTH_OF_DECISIONS).fill({}));
    const { timecloudAction, rejectReasons } = useSelector(state => state.tasks);
    useEffect(() => {
        if (timecloudAction) {
            setDecisions(current =>
                current.map(
                    (cur, index) =>
                        ({ ...props.decisions[index], name: timecloudAction.find(action => action.id === props.decisions[index]?.decision)?.name } ||
                        {})
                )
            );
        }
    }, [props.decisions, timecloudAction]);

    return (
        <Stack padding={0.5} direction="row" justifyContent="center" spacing={0}>
            {decisions.map((decision, index) => {
                if (decision.id) {
                    return (
                        <Tooltip
                            key={index}
                            disableInteractive
                            title={
                                <>
                                    <p style={{ margin: 0, padding: 0 }}>{`${decision.name}${decision.decision === 1 ? "d" : "ed"} by ${
                                        decision.decision_maker_name
                                    } at ${dayjs(decision.decision_made_at).format("DD/MM/YYYY")}`}</p>
                                    {(decision.reason || decision.reason_free_text) && (
                                        <p style={{ margin: 0, padding: 0 }}>
                                            <strong>REASON : </strong>
                                            {rejectReasons?.find(reason => reason.id === decision.reason)?.name || decision.reason_free_text}
                                        </p>
                                    )}
                                </>
                            }
                        >
                            <FiberManualRecordIcon style={{ cursor: "pointer" }} color={decision.decision === 1 ? "success" : "error"} fontSize="1" />
                        </Tooltip>
                    );
                } else {
                    return <FiberManualRecordOutlinedIcon key={index} fontSize="1" color="disabled" />;
                }
            })}
        </Stack>
    );
};

export default Decisions;
