import { Texture, Container, Sprite, Text, TextStyle } from "pixi.js";
import { numberWithCommas } from "shared/Utils";
import { gsap, Linear } from "gsap";

import Sign from "images/buoy.png";
import Current from "images/buoy_current.png";

const SPEED = 2;
const FLAG_WIDTH = 130;
const SIGN_WIDTH = 174;
const GAP_FROM_BUOY = 23;
const GAP_FROM_FLAG_CENTER = 12;

const TEXT_STYLE = new TextStyle({
    fontFamily: "Roboto",
    fontSize: 15,
    fontWeight: "bold",
    fill: "#FFFFFF",
    lineJoin: "round",
    align: "center"
});

let currentText = 0;
const SIGN_TEXTS = [
    { info: "Goal", value: "area_target" },
    { info: "Protected", value: "completed_area" }
];

// const specialSign = Texture.from(NewSign);

class Signs {
    constructor(bhag) {
        this.bhag = bhag[0];
        this.container = new Container();
        this.createSign();
        // this.createLance();
    }

    createSign = () => {
        this.signContainer = new Container();
        this.currentContainer = new Container();
        const normalSign = Texture.from(Sign, { willReadFrequently: true });
        const signCurrent = Texture.from(Current, { willReadFrequently: true });
        this.container.addChild(this.signContainer);
        this.signSprite = new Sprite(normalSign);
        this.signContainer.addChild(this.signSprite);
        this.signContainer.x = window.innerWidth + SIGN_WIDTH / 2;
        this.signContainer.y = -90;
        const signText = new Text(SIGN_TEXTS[currentText].info, TEXT_STYLE);
        signText.x = (FLAG_WIDTH - signText.width) / 2 + GAP_FROM_FLAG_CENTER;
        signText.y = -125;
        this.signContainer.addChild(signText);
        const bhagText = new Text(`${numberWithCommas(this.bhag[SIGN_TEXTS[currentText].value])} lives`, TEXT_STYLE);
        bhagText.x = (FLAG_WIDTH - bhagText.width) / 2 + GAP_FROM_FLAG_CENTER;
        bhagText.y = -92;
        this.signContainer.addChild(bhagText);
        this.signSprite.anchor.set(0.2, 1);
        const currentSprite = new Sprite(signCurrent);
        this.currentContainer.addChild(currentSprite);
        this.currentContainer.x = window.innerWidth + GAP_FROM_BUOY;
        this.currentContainer.y = -94;
        this.container.addChild(this.currentContainer);
        gsap.fromTo(this.signContainer, { rotation: -0.05 }, { rotation: 0.05, yoyo: true, duration: 1.5, repeat: -1, ease: Linear.easeInOut });
    };

    changeValue = bhag => {
        this.bhag = bhag[0];
    };

    remove = () => {
        this.currentContainer.destroy(true);
        this.signContainer.destroy(true);
        this.container.destroy(true);
        this.container = null;
    };

    tick = () => {
        if (this.signContainer.x <= -this.signContainer.width) {
            this.signContainer.x = window.innerWidth + SIGN_WIDTH / 2;
            this.currentContainer.x = window.innerWidth + GAP_FROM_BUOY;
            currentText += 1;
            if (currentText > 1) {
                currentText = 0;
            }
            this.signContainer.children.forEach((child, i) => {
                if (i === 1) {
                    child.text = SIGN_TEXTS[currentText].info;
                    child.x = (FLAG_WIDTH - child.width) / 2 + GAP_FROM_FLAG_CENTER;
                }
                if (i === 2) {
                    child.text = `${numberWithCommas(this.bhag[SIGN_TEXTS[currentText].value])} lives`;
                    child.x = (FLAG_WIDTH - child.width) / 2 + GAP_FROM_FLAG_CENTER;
                }
            });
        }
        this.signContainer.x -= SPEED;
        this.currentContainer.x -= SPEED;

        // if (this.lance.x <= -this.signContainer.width) {
        //     this.lance.x = window.innerWidth;
        // }
        // this.lance.x -= SPEED;
    };

    resize = (width, height) => {};
}

export default Signs;
