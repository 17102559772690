import React from "react";
import { NavLink, Link } from "react-router-dom";
import { MDBIcon } from "louis-mdbreact";
import styles from "shared/headNavStyles.scss";
import CorporateFareOutlinedIcon from "@mui/icons-material/CorporateFareOutlined";
import { useSelector } from "react-redux";

const CRMDependPermission = props => {
    const { toggleNavbar } = props;
    const {
        user: { permissions, isSLT, isPayrollRate, isAdmin, isQS }
    } = useSelector(state => state);
    switch (permissions.crm) {
        case 4:
            return (
                <>
                    <li>
                        <NavLink exact activeClassName={styles.active} to={`/crm/entities`} onClick={toggleNavbar}>
                            <i>
                                <CorporateFareOutlinedIcon fontSize="small" />
                            </i>{" "}
                            <span>Entity</span>
                        </NavLink>
                        <Link className={styles.new} to={`/crm/entities/new`} onClick={toggleNavbar}>
                            <MDBIcon far icon="plus-square" />
                        </Link>
                    </li>
                    <li>
                        <NavLink exact activeClassName={styles.active} to={`/crm/merchant`} onClick={toggleNavbar}>
                            <span>- Merchant</span>
                        </NavLink>
                    </li>
                    <li>
                        <NavLink exact activeClassName={styles.active} to={`/crm/client`} onClick={toggleNavbar}>
                            <span>- Client</span>
                        </NavLink>
                    </li>
                    <li>
                        <NavLink exact activeClassName={styles.active} to={`/crm/employee`} onClick={toggleNavbar}>
                            <span>- Employee</span>
                        </NavLink>
                    </li>
                    {/* {(isSLT || isQS) && (
                        <li>
                            <NavLink exact activeClassName={styles.active} to={`/crm/debtors`} onClick={toggleNavbar}>
                                <MDBIcon icon="dollar-sign" /> <span>Debtors</span>
                            </NavLink>
                        </li>
                    )} */}
                    {(isSLT || isAdmin) && (
                        <li>
                            <NavLink exact activeClassName={styles.active} to={`/crm/email`} onClick={toggleNavbar}>
                                <MDBIcon far icon="envelope" /> <span>Send Email</span>
                            </NavLink>
                        </li>
                    )}
                    {isPayrollRate ? (
                        <li>
                            <NavLink exact activeClassName={styles.active} to={`/crm/payrate`} onClick={toggleNavbar}>
                                <MDBIcon icon="search-dollar" /> <span>Staff Pay Rate</span>
                            </NavLink>
                        </li>
                    ) : (
                        ""
                    )}
                    {(isSLT || isQS || isAdmin) && (
                        <li>
                            <NavLink exact activeClassName={styles.active} to={`/crm/quotes`} onClick={toggleNavbar}>
                                <MDBIcon icon="comment-dollar" /> <span>Hit List</span>
                            </NavLink>
                        </li>
                    )}
                </>
            );
        case 3:
            return (
                <>
                    <li>
                        <NavLink exact activeClassName={styles.active} to={`/crm/entities`} onClick={toggleNavbar}>
                            <i>
                                <CorporateFareOutlinedIcon fontSize="small" />
                            </i>{" "}
                            <span>Entity</span>
                        </NavLink>
                        <Link className={styles.new} to={`/crm/entities/new`} onClick={toggleNavbar}>
                            <MDBIcon far icon="plus-square" />
                        </Link>
                    </li>
                    <li>
                        <NavLink exact activeClassName={styles.active} to={`/crm/merchant`} onClick={toggleNavbar}>
                            <span>- Merchant</span>
                        </NavLink>
                    </li>
                    <li>
                        <NavLink exact activeClassName={styles.active} to={`/crm/client`} onClick={toggleNavbar}>
                            <span>- Client</span>
                        </NavLink>
                    </li>
                    <li>
                        <NavLink exact activeClassName={styles.active} to={`/crm/employee`} onClick={toggleNavbar}>
                            <span>- Employee</span>
                        </NavLink>
                    </li>
                    {/* {(isSLT || isQS) && (
                        <li>
                            <NavLink exact activeClassName={styles.active} to={`/crm/debtors`} onClick={toggleNavbar}>
                                <MDBIcon icon="dollar-sign" /> <span>Debtors</span>
                            </NavLink>
                        </li>
                    )} */}
                    {(isSLT || isAdmin) && (
                        <li>
                            <NavLink exact activeClassName={styles.active} to={`/crm/email`} onClick={toggleNavbar}>
                                <MDBIcon far icon="envelope" /> <span>Send Email</span>
                            </NavLink>
                        </li>
                    )}
                    {(isSLT || isQS || isAdmin) && (
                        <li>
                            <NavLink exact activeClassName={styles.active} to={`/crm/quotes`} onClick={toggleNavbar}>
                                <MDBIcon icon="comment-dollar" /> <span>Hit List</span>
                            </NavLink>
                        </li>
                    )}
                </>
            );
        case 1:
            return (
                <>
                    <li>
                        <NavLink exact activeClassName={styles.active} to={`/crm/entities`} onClick={toggleNavbar}>
                            <i>
                                <CorporateFareOutlinedIcon fontSize="small" />
                            </i>{" "}
                            <span>Entity</span>
                        </NavLink>
                    </li>
                    <li>
                        <NavLink exact activeClassName={styles.active} to={`/crm/merchant`} onClick={toggleNavbar}>
                            <span>- Merchant</span>
                        </NavLink>
                    </li>
                    <li>
                        <NavLink exact activeClassName={styles.active} to={`/crm/client`} onClick={toggleNavbar}>
                            <span>- Client</span>
                        </NavLink>
                    </li>
                    <li>
                        <NavLink exact activeClassName={styles.active} to={`/crm/employee`} onClick={toggleNavbar}>
                            <span>- Employee</span>
                        </NavLink>
                    </li>
                    {/* {(isSLT || isQS) && (
                        <li>
                            <NavLink exact activeClassName={styles.active} to={`/crm/debtors`} onClick={toggleNavbar}>
                                <MDBIcon icon="dollar-sign" /> <span>Debtors</span>
                            </NavLink>
                        </li>
                    )} */}
                    {(isSLT || isAdmin) && (
                        <li>
                            <NavLink exact activeClassName={styles.active} to={`/crm/email`} onClick={toggleNavbar}>
                                <MDBIcon far icon="envelope" /> <span>Send Email</span>
                            </NavLink>
                        </li>
                    )}
                    {(isSLT || isQS || isAdmin) && (
                        <li>
                            <NavLink exact activeClassName={styles.active} to={`/crm/quotes`} onClick={toggleNavbar}>
                                <MDBIcon icon="comment-dollar" /> <span>Hit List</span>
                            </NavLink>
                        </li>
                    )}
                </>
            );
        default:
            return (
                <>
                    <li>
                        <NavLink exact activeClassName={styles.active} to={`/crm/entities`} onClick={toggleNavbar}>
                            <i>
                                <CorporateFareOutlinedIcon fontSize="small" />
                            </i>{" "}
                            <span>Entity</span>
                        </NavLink>
                    </li>
                    <li>
                        <NavLink exact activeClassName={styles.active} to={`/crm/merchant`} onClick={toggleNavbar}>
                            <span>- Merchant</span>
                        </NavLink>
                    </li>
                    <li>
                        <NavLink exact activeClassName={styles.active} to={`/crm/client`} onClick={toggleNavbar}>
                            <span>- Client</span>
                        </NavLink>
                    </li>
                    <li>
                        <NavLink exact activeClassName={styles.active} to={`/crm/employee`} onClick={toggleNavbar}>
                            <span>- Employee</span>
                        </NavLink>
                    </li>
                    {/* {(isSLT || isQS) && (
                        <li>
                            <NavLink exact activeClassName={styles.active} to={`/crm/debtors`} onClick={toggleNavbar}>
                                <MDBIcon icon="dollar-sign" /> <span>Debtors</span>
                            </NavLink>
                        </li>
                    )} */}
                    {(isSLT || isAdmin) && (
                        <li>
                            <NavLink exact activeClassName={styles.active} to={`/crm/email`} onClick={toggleNavbar}>
                                <MDBIcon far icon="envelope" /> <span>Send Email</span>
                            </NavLink>
                        </li>
                    )}
                    {(isSLT || isQS || isAdmin) && (
                        <li>
                            <NavLink exact activeClassName={styles.active} to={`/crm/quotes`} onClick={toggleNavbar}>
                                <MDBIcon icon="comment-dollar" /> <span>Hit List</span>
                            </NavLink>
                        </li>
                    )}
                </>
            );
    }
};

export default CRMDependPermission;
