import { connect } from "react-redux";
import Container from "./container";
import { withRouter } from "react-router";

import { actionCreators as JobActions } from "redux/modules/jobs";
import { actionCreators as operationsActions } from "redux/modules/operations";
import { getSlimJobListByDiary } from "redux/reducers";

const mapStateToProps = (state, ownProps) => {
    const {
        user: { permissions, currentCompany, resourcesList },
        operations: { qaElement, QaDetail },
        router: {
            location: { query }
        }
    } = state;
    const { title } = ownProps;
    return {
        resourcesList,
        title,
        currentCompany,
        query,
        QaDetail,
        baseData: {
            jobList: getSlimJobListByDiary(state),
            qaElement
            // qaResponseOption: JSON.parse(localStorage.getItem("qaResponseOption")).filter(option => option.current_status)
        },

        permissions
    };
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        getSlimJobsForQA: () => dispatch(JobActions.getSlimJobsForQA()),
        getQAElements: id => dispatch(operationsActions.getQAElements(id)),
        postQA: (submitdata, files, recipients) => dispatch(operationsActions.postQA(submitdata, files, recipients)),
        getQaDetail: id => dispatch(operationsActions.getQaDetail(id)),
        fileDownload: id => dispatch(operationsActions.fileDownload(id)),
        getOpsCommons: () => dispatch(operationsActions.getOpsCommons(true))
    };
};

const mergeProps = (mapProps, dispatchProps, ownProps) => {
    const { getOpsCommons } = dispatchProps;
    const qaResponseOption = JSON.parse(localStorage.getItem("qaResponseOption")) || getOpsCommons();
    return {
        ...mapProps,
        ...dispatchProps,
        ...ownProps,
        baseData: {
            ...mapProps.baseData,
            qaResponseOption: qaResponseOption.filter(option => option.current_status)
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(withRouter(Container));
