import React, { useEffect, useState, useContext } from "react";

import { Stack, Button } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import { useSelector, useDispatch } from "react-redux";
import { axiosInstance } from "shared/axiosInst";
import { actionCreators as taskActions } from "redux/modules/tasks";
import { ModalContext } from "components/Tasks/TaskContext";

const withListItems =
    WrappedList =>
    ({ item, isLog = false }) => {
        const {
            user: { currentCompany, userId, manager }
        } = useSelector(state => state);
        const dispatch = useDispatch();
        const { setRejectData } = useContext(ModalContext);
        const [listItems, setListItems] = useState([]);
        const onTotalCheckboxChange = e => {
            setListItems(
                listItems.map(list => {
                    let checked = e.currentTarget.checked;
                    if (list.decisions.length > 0) {
                        if (list.decisions.find(decision => decision.decision === 2)) {
                            checked = false;
                        } else {
                            const decisions = list.decisions.find(
                                decision => decision.decision_maker === userId || decision.decision_maker === manager?.manager_id
                            );
                            if (decisions) {
                                checked = false;
                            }
                        }
                    }
                    return { ...list, checked };
                })
            );
        };

        useEffect(() => {
            setListItems(item.map(list => ({ ...list, checked: false })));
        }, [item]);

        const onChange = index => {
            setListItems(listItems.map((list, idx) => ({ ...list, checked: idx === index ? !list.checked : list.checked })));
        };

        const onApproveClick = () => {
            const selected = listItems.filter(list => list.checked);
            let approvingList = [];
            if (selected.length === 0) {
                approvingList = listItems.filter(list => {
                    if (list.decisions.length > 0) {
                        if (list.decisions.find(decision => decision.decision === 2)) {
                            return false;
                        } else {
                            const decisions = list.decisions.find(
                                decision => decision.decision_maker === userId || decision.decision_maker === manager?.manager_id
                            );
                            if (decisions) {
                                return false;
                            }
                        }
                    }
                    return true;
                });
            } else {
                approvingList = selected;
            }

            approvingList.length > 0 &&
                axiosInstance
                    .post(`${currentCompany}maintenance/time_tracking_decisions/approve/`, {
                        time_entry: approvingList.map(list => list.id)
                    })
                    .then(value => {
                        dispatch(taskActions.getTimeCloudList());
                    });
        };

        const onRejectClick = () => {
            const selected = listItems.filter(list => list.checked);
            setRejectData(selected);
        };

        return (
            <>
                <WrappedList onChange={onChange} onTotalCheckboxChange={onTotalCheckboxChange} listItems={listItems} isLog={isLog} />
                {!isLog && (
                    <Stack direction="row-reverse" spacing={1}>
                        {(userId === 1 || userId === 2) && (
                            <Button variant="contained" color="error" size="small" onClick={onRejectClick} startIcon={<CheckIcon />}>
                                Reject {listItems.find(list => list.checked) ? "Selected" : "all"}
                            </Button>
                        )}
                        {listItems.find(list => list.checked) && (
                            <Button variant="contained" color="secondary" size="small" onClick={onApproveClick} startIcon={<CheckIcon />}>
                                Approve Selected
                            </Button>
                        )}
                    </Stack>
                )}
            </>
        );
    };

export default withListItems;
