import React, { Component } from "react";
import QualityAssurance from "./presenter";
import QAReportModal from "components/modals/QAReportModal";
import { axiosInstance } from "shared/axiosInst";
import { Helmet } from "react-helmet";

class Container extends Component {
    constructor(props) {
        super(props);
        const isMine = localStorage.getItem("isMineJob") === "true" || localStorage.getItem("isMineJob") === null;
        const isCurrent = localStorage.getItem("isCurrentJob") === "true" || localStorage.getItem("isCurrentJob") === null;
        const normalView = JSON.parse(localStorage.getItem("normalView")) || {};
        if (normalView.QA === undefined) {
            normalView.QA = true;
        }
        this.state = {
            loading: true,
            isMine,
            isCurrent,
            QAModal: false,
            normalView,
            queue: [],
            titleName: this.setTitleName(isMine, isCurrent),
            searchText: sessionStorage.getItem("qaSearch") || "",
            activePage: sessionStorage.getItem("qaActivePage") ? Number(sessionStorage.getItem("qaActivePage")) - 1 : 0
        };
    }

    componentDidMount = () => {
        const { getQAAllList } = this.props;
        const { isMine, isCurrent, normalView } = this.state;
        getQAAllList(isMine, isCurrent, normalView.QA, false);
        window.scrollTo(0, 0);
    };

    componentDidUpdate = prevProps => {
        const { jobList, QAAllList } = this.props;
        if (jobList !== prevProps.jobList) {
            this.setState({
                loading: false
            });
        }

        if (QAAllList !== prevProps.QAAllList) {
            this.setState({
                loading: false,
                QAAllList
            });
        }
    };

    setTitleName = (isMine, isCurrent) => {
        const title = `${isMine ? "my " : ""}${isCurrent ? "current " : ""}QA`;

        return title.toUpperCase();
    };

    changeList = event => {
        const {
            target: { name, checked }
        } = event;
        const { getQAAllList } = this.props;
        localStorage.setItem([`${name}Job`], checked);
        this.setState(
            {
                [name]: checked,
                loading: true
            },
            () => {
                const { isMine, isCurrent, normalView } = this.state;
                getQAAllList(isMine, isCurrent, normalView.QA, false);
                this.setState({
                    titleName: this.setTitleName(isMine, isCurrent)
                });
            }
        );
    };

    toggleQA = event => {
        this.setState({
            QAModal: !this.state.QAModal,
            selectedJob: event && event.currentTarget.value
        });
    };

    toggleChangeView = e => {
        const { normalView, isMine, isCurrent } = this.state;
        const { getQAAllList } = this.props;
        this.setState({ normalView: { ...normalView, QA: !normalView.QA }, loading: true }, () => {
            const { normalView } = this.state;
            localStorage.setItem("normalView", JSON.stringify(normalView));
            getQAAllList(isMine, isCurrent, normalView.QA, false);
        });
    };

    signal = axiosInstance.CancelToken.source();

    downloadQAReport = e => {
        e.preventDefault();
        const {
            currentTarget: { value }
        } = e;
        const { downloadQAReport } = this.props;
        const { QAAllList, queue } = this.state;
        queue.push(Number(value));
        this.setState(
            {
                QAAllList: QAAllList.map(list => ({
                    ...list,
                    pending: queue.find(que => que === list.id)
                }))
            },
            () => {
                downloadQAReport([Number(value)], this.signal)
                    .then(value => {
                        if (value) {
                            if (value.message === "completed") {
                                queue.shift();
                                this.setState({
                                    QAAllList: QAAllList.map(list => ({
                                        ...list,
                                        pending: queue.find(que => que === list.id)
                                    }))
                                });
                            }
                        }
                    })
                    .catch(error => alert(error));
            }
        );
    };

    componentWillUnmount() {
        this.signal.cancel("Downloading(s) is cancelled by user.");
    }

    onSearch = searchText => {
        this.setState({
            searchText
        });
        sessionStorage.setItem("qaSearch", searchText);
    };

    onPageChange = pageInfo => {
        this.setState({
            activePage: pageInfo.activePage
        });
        sessionStorage.setItem("qaActivePage", pageInfo.activePage);
    };

    render = () => {
        const { jobList, prefixText, permissions, userId } = this.props;
        const { selectedJob, QAModal } = this.state;
        return (
            <>
                <Helmet>
                    <title>Operations | QA</title>
                </Helmet>
                <QualityAssurance
                    {...this.state}
                    jobList={jobList}
                    userId={userId}
                    permissions={permissions}
                    prefixText={prefixText}
                    toggleQA={this.toggleQA}
                    changeList={this.changeList}
                    toggleChangeView={this.toggleChangeView}
                    downloadQAReport={this.downloadQAReport}
                    onSearch={this.onSearch}
                    onPageChange={this.onPageChange}
                />
                <QAReportModal QAModal={QAModal} isPre={false} toggleQA={this.toggleQA} selectedJob={selectedJob} />
            </>
        );
    };
}

export default Container;
