import { connect } from "react-redux";
import Container from "./container";
import { actionCreators as crmActions } from "redux/modules/crm";
import { withRouter } from "react-router";
// import { getCommonItemsRefined } from "redux/reducers";

const mapStateToProps = (state, ownProps) => {
    const {
        crm: { newClientData },
        user: { permissions, isAdmin }
    } = state;
    return {
        permissions,
        isAdmin,
        newClientData: newClientData || ""
        // commonItems: getCommonItemsRefined(state)
    };
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        submitFullEntity: (data, inJob) => dispatch(crmActions.submitFullEntity(data, inJob)),
        switchScreen: entity => ownProps.switchScreen(entity)

        // getCRMCommons: () => {
        //     dispatch(crmActions.getCRMCommons());
        // }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Container));
