import React, { Fragment, useState, useEffect, Suspense } from "react";
import { Grid, Typography, List, ListItem, ListItemText, Divider, ListItemButton, Button, Stack } from "@mui/material";
import dayjs from "shared/dayjs";
import { Calendar } from "react-calendar";
import calendarStyles from "../LeaveRequest/styles.scss";
import { Label, PublicHolidayDotInCalendar, PublicHolidayInfo, StyledBadge } from "components/HumanResources/CustomComponent";
import CircleIcon from "@mui/icons-material/Circle";
import ContrastIcon from "@mui/icons-material/Contrast";
import { blue } from "@mui/material/colors";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { actionCreators as hrActions } from "redux/modules/humanResources";
import { actionCreators as userActions } from "redux/modules/user";
import Loading from "components/Loading";
import { axiosInstance } from "shared/axiosInst";
import { flatten } from "shared/Utils";

const ManagerComponent = React.lazy(() => import("./ManagerComponent"));

const RequestedLeave = ({ index }) => {
    const {
        humanResources: { leaveRequest, totalHolidays },
        user: { currentCompany, direct_report, userId }
    } = useSelector(state => state);
    const dispatch = useDispatch();
    const params = useParams();
    const id = index || params.id;
    const [numberOfDays, setNumberOfDays] = useState(0);
    const [activeStartDate, setActiveStartDate] = useState(new Date());
    const [cancellable, setCancellable] = useState(false);
    const [globalUsers, setGlobalUsers] = useState([]);
    useEffect(() => {
        const getLeaveData = async () => {
            await dispatch(hrActions.getLeaveRequest(id));
            const globalUsers = await dispatch(userActions.getGlobalUsers());
            setGlobalUsers(globalUsers);
        };
        getLeaveData();
    }, [dispatch, id]);

    useEffect(() => {
        if (leaveRequest.leave_days?.length > 0) {
            setActiveStartDate(new Date(leaveRequest.leave_days[0].leave_date));
            const halfDays = leaveRequest.leave_days.filter(day => day.proportion_of_day === "0.50").length;
            setNumberOfDays(leaveRequest.leave_days.length - halfDays * 0.5);
        }
        const currentUsers = flatten([], direct_report, "direct_report");
        const isCancelled = leaveRequest.request_state === 4 || leaveRequest.request_state === 5 || leaveRequest.request_state === 6;
        const isMine = leaveRequest.employee === userId;
        const isManager = !!currentUsers.find(user => user.id === leaveRequest.employee);
        const isLater = leaveRequest.id ? Number(new Date(leaveRequest.leave_days[0]?.leave_date)) > Number(new Date()) : false;
        let cancellable;
        if (isMine) {
            cancellable = !isCancelled && isLater;
        } else {
            if (isManager) {
                cancellable = true;
            } else {
                cancellable = false;
            }
        }
        setCancellable(cancellable);
    }, [leaveRequest, direct_report, userId]);

    const changeActiveDay = date => {
        setActiveStartDate(new Date(date));
    };

    const onCancelClick = async event => {
        const response = await axiosInstance.post(`${currentCompany}hr/leave_request/${leaveRequest.id}/record_decision/`, {
            decision: 2
        });
        dispatch(hrActions.setLeaveRequest(response.data));
    };

    return leaveRequest.id === Number(id) ? (
        <Grid container spacing={2} sx={{ alignItems: "flex-start" }}>
            <Grid item xs={12}>
                <Label>Name</Label>
                <Typography>{leaveRequest.employee_name}</Typography>
            </Grid>
            <Grid container spacing={2} item xs={12} md={8}>
                <Grid item xs={12}>
                    <Label>Leave Calendar</Label>
                    <Calendar
                        calendarType="US"
                        tileClassName={({ _, date, view }) => {
                            const selected = leaveRequest.leave_days.find(day => {
                                return day.leave_date === dayjs(date).format("YYYY-MM-DD");
                            });
                            return view === "month" && selected ? calendarStyles.selected : "";
                        }}
                        onActiveStartDateChange={e => setActiveStartDate(e.activeStartDate)}
                        activeStartDate={activeStartDate}
                        className={calendarStyles.container}
                        tileContent={({ activeStartDate, date, view }) => {
                            const formattedDate = dayjs(date).format("YYYY-MM-DD");
                            const matchedHoliday = totalHolidays.find(day => day.holiday_date === formattedDate);
                            return matchedHoliday ? <PublicHolidayDotInCalendar /> : "";
                        }}
                        view="month"
                    />
                    <PublicHolidayInfo />
                </Grid>
                <Grid item xs={6}>
                    <Label>Leave Type</Label>
                    <Typography>{leaveRequest.leave_type_string}</Typography>
                </Grid>
                <Grid item xs={6}>
                    <Label>Leave State</Label>
                    <Stack direction="row" alignItems="center" spacing={1}>
                        <Typography>{leaveRequest.request_state_string}</Typography>
                        {cancellable && (
                            <Button variant="contained" size="small" color="error" onClick={onCancelClick}>
                                Cancel
                            </Button>
                        )}
                    </Stack>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Label>Requested Date</Label>
                    <Typography>{dayjs(leaveRequest.submitted_at).format("DD/MM/YYYY")}</Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Label>Requested By</Label>
                    <Typography>{globalUsers.find(user => user.id === leaveRequest.submitted_by)?.name_string}</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Label>Notes</Label>
                    <Typography sx={{ whiteSpace: "pre-wrap" }}>{leaveRequest.notes}</Typography>
                </Grid>
                <Suspense
                    fallback={
                        <Grid item xs={12}>
                            Loading...
                        </Grid>
                    }
                >
                    <ManagerComponent leaveDetail={leaveRequest} />
                </Suspense>
            </Grid>
            <Grid item xs={12} md={4}>
                <StyledBadge badgeContent={numberOfDays} color="secondary">
                    <Label>Selected days</Label>
                </StyledBadge>
                <List dense sx={{ width: "100%" }}>
                    {leaveRequest.leave_days.map(day => (
                        <Fragment key={day.leave_date}>
                            <ListItem
                                disablePadding
                                sx={{ bgcolor: day.proportion_of_day === "1.00" ? blue["A400"] : blue["A100"], color: "info.contrastText" }}
                                secondaryAction={
                                    <>
                                        {day.proportion_of_day === "1.00" ? <CircleIcon fontSize="small" /> : <ContrastIcon fontSize="small" />}
                                        <Typography sx={{ marginLeft: "5px" }} variant="overline">
                                            {day.proportion_of_day === "1.00" ? "Full" : "Half"} Day
                                        </Typography>
                                    </>
                                }
                            >
                                <ListItemButton onClick={_ => changeActiveDay(day.leave_date)}>
                                    <ListItemText id={day.leave_date} primary={dayjs(day.leave_date).format("DD/MM/YYYY")} />
                                </ListItemButton>
                            </ListItem>
                            <Divider component="li" />
                        </Fragment>
                    ))}
                </List>
            </Grid>
        </Grid>
    ) : (
        <Loading loadingType="component" />
    );
};

export default RequestedLeave;
