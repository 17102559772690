import { Container, Sprite, Texture } from "pixi.js";
import PeopleList from "./PeopleList";
import ManBody from "images/floating_body_man.png";
import WomanBody from "images/floating_body_woman.png";
import { gsap } from "gsap";

const PEOPLE_NUMBER = 3;
const AREA_HEIGHT = 50;
const PEOPLE_LIST = PeopleList();
const PEOPLE_ID = [];

class ExtraDeco {
    constructor() {
        this.container = new Container();
        this.tweens = [];
        this.createDeco();
    }

    createDeco = () => {
        for (let i = 0; i < PEOPLE_NUMBER; i++) {
            const peopleContainer = new Container();
            const { faceTexture, bodyTexture } = this.getRandomFaceTexture();
            const bodySprite = new Sprite(bodyTexture);
            const face = new Sprite(faceTexture);
            face.name = "face";
            bodySprite.name = "body";
            peopleContainer.addChild(bodySprite);
            peopleContainer.addChild(face);
            this.container.addChild(peopleContainer);
            face.width = 150;
            face.height = 157;
            face.x = 39;
            face.y = 95;
            face.anchor.set(0.5, 1);
            peopleContainer.x = window.innerWidth / (PEOPLE_NUMBER * 2) + (window.innerWidth / PEOPLE_NUMBER) * i;
            peopleContainer.y = Math.floor(Math.random() * AREA_HEIGHT) + 1;
            gsap.to(peopleContainer, { y: peopleContainer.y + 10, duration: 1 + 0.1 * (i + 1), yoyo: true, repeat: -1 });
        }
        this.container.children.sort((a, b) => a.y - b.y);
    };

    getRandomFaceTexture = () => {
        // to avoid getting the same person on the screen
        const getRandomNumber = () => {
            let randomNumber = Math.floor((Math.random() * PEOPLE_LIST.length) / 4) + 1;
            if (PEOPLE_ID.find(id => id === randomNumber)) {
                return getRandomNumber();
            } else {
                if (PEOPLE_ID.length > PEOPLE_NUMBER) {
                    PEOPLE_ID.shift();
                }
                PEOPLE_ID.push(randomNumber);
                return randomNumber;
            }
        };
        const randomNumber = getRandomNumber();
        const pickedOne = PEOPLE_LIST[randomNumber];
        const faceTexture = Texture.from(require(`images/staff/${pickedOne.img}`));
        const bodyTexture = Texture.from(pickedOne.gender === "male" ? ManBody : WomanBody);
        return { faceTexture, bodyTexture };
    };

    resize = (width, height) => {
        this.width = width;
    };

    tick = () => {
        for (let i = 0; i < PEOPLE_NUMBER; i++) {
            const child = this.container.getChildAt(i);
            child.x -= 1;
            if (child.x <= -child.width) {
                child.x = window.innerWidth;
                const { faceTexture, bodyTexture } = this.getRandomFaceTexture();

                const face = child.getChildByName("face");
                const body = child.getChildByName("body");
                face.texture = faceTexture;
                body.texture = bodyTexture;
            }
        }
    };

    remove = () => {};
}

export default ExtraDeco;
