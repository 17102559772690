import React, { Component } from "react";
import { Application } from "pixi.js-legacy";
import { gsap } from "gsap";
import { PixiPlugin } from "gsap/PixiPlugin.js";

import styles from "./styles.scss";

import Background from "./Background";
import Boats from "./Boats";
// import Train from "./Train";
import Signs from "./Signs";
import ExtraDeco from "./ExtraDeco";

const BOAT_POSITION_Y = 750;
const DECO_POSITION_Y = 810;
const SIGN_POSITION_Y = 1050;
class CanvasBHAG extends Component {
    constructor(props) {
        super(props);
        const { width, height } = this.props;
        this.app = new Application({
            width,
            height,
            antialias: true,
            resizeThrottle: 100,
            resizeTo: window,
            forceCanvas: !!window.navigator.platform.match("Linux")
        });

        gsap.registerPlugin(PixiPlugin);
    }

    componentDidMount = () => {
        this.init();
    };

    init = () => {
        const { bhag } = this.props;
        this.app.renderer.backgroundColor = 0xffffff;
        this.gameCanvas.appendChild(this.app.view);

        this.app.start();
        this.background = new Background();
        this.boats = new Boats(bhag);
        // this.train = new Train(bhag);
        // this.road = new Road();
        this.sign = new Signs(bhag);
        this.extraDeco = new ExtraDeco();

        this.app.stage.addChild(this.background.container);
        this.app.stage.addChild(this.boats.container);
        this.boats.container.position.set(0, BOAT_POSITION_Y);

        // this.app.stage.addChild(this.train.container);
        // this.train.container.position.set(0, BOAT_POSITION_Y);

        this.app.stage.addChild(this.extraDeco.container);
        this.extraDeco.container.position.set(0, DECO_POSITION_Y);

        this.app.stage.addChild(this.sign.container);
        this.sign.container.position.set(0, SIGN_POSITION_Y);
        this.app.ticker.maxFPS = 60;
        this.app.ticker.add(this.background.tick);
        this.app.ticker.add(this.boats.tick);
        // this.app.ticker.add(this.train.tick);
        this.app.ticker.add(this.sign.tick);
        this.app.ticker.add(this.extraDeco.tick);

        this.app.renderer.on("resize", this.resize);
    };

    resize = (width, height) => {
        this.background.resize(width, height);
        this.boats.resize(width, height);
        // this.road.resize(width, height);
        this.sign.resize(width, height);
    };

    componentDidUpdate(prevProps) {
        const { bhag } = this.props;
        if (prevProps.bhag !== bhag) {
            this.boats.changeBHAG(bhag);
            this.sign.changeValue(bhag);
            // this.train.changeBHAG(bhag);
        }
    }

    componentWillUnmount() {
        this.app.ticker.remove(this.background.tick);
        this.app.ticker.remove(this.boats.tick);
        // this.app.ticker.remove(this.road.tick);
        this.app.ticker.remove(this.sign.tick);
        // this.train.remove();
        this.app.stage.removeListener();
        this.app.stage.removeChildren();
        this.gameCanvas.removeChild(this.app.view);
        this.app.renderer.off("resize", this.resize);
        this.app.stop();
        this.app.destroy(true);
        this.background = null;
        this.boats = null;
        // this.road = null;
        this.sign = null;
        // this.train = null;
        this.app = null;
    }

    /**
     * Give 'gameCanvas' node reference from the DOM.
     */
    bindCanvas = node => (this.gameCanvas = node);

    render = () => <div className={styles.gameCanvas} ref={this.bindCanvas} />;
}

export default CanvasBHAG;
