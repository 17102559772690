import React, { memo, useState, useEffect, useMemo } from "react";
import AttributeElement from "components/Crm/EntityDetail/AttributeElement";
import SelectComponent from "components/Crm/EntityDetail/SelectComponent";
import { MDBCard, MDBCardHeader, MDBCardBody, MDBRow, MDBCol, MDBTable, MDBTableHead, MDBTableBody, MDBBtn } from "louis-mdbreact";
import dayjs from "shared/dayjs";
import { useSelector, useDispatch } from "react-redux";
import { actionCreators as crmActions } from "redux/modules/crm";

import styles from "shared/details.scss";

const Attributes = ({ type, getSendingData, entityDetail, onEditClick, wasValidated: fromParentValidated, inJob }) => {
    const [wasValidated, setIsValidated] = useState(fromParentValidated);
    const companiesList = JSON.parse(localStorage.getItem("allCompanies")).filter(
        company => company.display_region === "True" && company.region_string === "Auckland"
    );
    useEffect(() => {
        setIsValidated(fromParentValidated);
    }, [fromParentValidated]);

    const dispatch = useDispatch();
    const { commonItems } = useSelector(state => state.crm);
    const { permissions } = useSelector(state => state.user);
    const { roles, assignment: assignments, regions, relationship: relationships } = commonItems || {};

    const [sendingData, setSendingData] = useState({
        role: [],
        assignment: [],
        relationship: []
    });

    const administration = useMemo(() => permissions.crm === 4, [permissions]);
    useEffect(() => {
        if (inJob === "client" && relationships) {
            setSendingData(sendingData => ({
                ...sendingData,
                relationship: [
                    ...sendingData.relationship,
                    {
                        ...relationships.find(rel => rel.name === "Client"),
                        end_date: "",
                        timestamp: Number(new Date())
                    }
                ]
            }));
        }
    }, [relationships, inJob]);

    useEffect(() => {
        dispatch(crmActions.getAttrCommons());
    }, [dispatch]);

    const names = useMemo(() => {
        return [
            { idx: 1, url: "entity_role_link", name: "role" },
            { idx: 2, url: "entity_assignment_link", name: "assignment" },
            { idx: 3, url: "entity_relationship_link", name: "relationship" }
        ];
    }, []);

    useEffect(() => {
        const getAttributes = () => {
            const { role_details, assignment_details, relationship_details } = entityDetail;
            const attributesResponse = [role_details, assignment_details, relationship_details];
            setSendingData(sending => {
                return attributesResponse.reduce((obj, item, i) => {
                    return {
                        ...obj,
                        [names[i].name]: item.map(it => ({
                            ...it,
                            id: it[names[i].name],
                            originalId: it.id,
                            end_date: names[i].name === "relationship" ? it.end_date || "" : undefined,
                            timestamp: names[i].name === "relationship" ? Number(new Date()) + it.id : undefined,
                            regions:
                                i === 1
                                    ? it.regions.map(region => ({
                                          id: region,
                                          region_name: regions.find(re => re.id === region).region_name,
                                          region_short_code: regions.find(re => re.id === region).region_short_code
                                      }))
                                    : undefined
                        }))
                    };
                }, sending);
            });
        };
        if (entityDetail && entityDetail.id) {
            getAttributes();
        }
    }, [dispatch, entityDetail, names, regions]);

    useEffect(() => {
        type === "new" &&
            Object.keys(sendingData).forEach(key => {
                const validated =
                    key === "assignment"
                        ? sendingData.assignment.reduce((bool, ele) => !!(bool && ele.regions && ele.regions.length > 0), true)
                        : true;
                getSendingData(
                    key,
                    sendingData[key].map(ele => {
                        return {
                            ...ele,
                            [key]: ele.id,
                            id: undefined,
                            pending: false,
                            regions: key === "assignment" ? ele.regions && ele.regions.map(reg => reg.id) : undefined,
                            end_date: ele.end_date || undefined
                        };
                    }),
                    validated
                );
            });
    }, [sendingData, type, getSendingData, administration]);

    const onAttributeChanged = (option, selectedState) => {
        let newOption = {};
        switch (selectedState) {
            case "role":
                newOption = { ...option, role_detail: "" };
                break;
            case "assignment":
                newOption = { ...option, regions: [] };
                break;
            case "relationship":
                newOption = { ...option, end_date: "", timestamp: Number(new Date()) }; //start_date:""
                break;
            default:
                break;
        }
        const newSendingData = {
            ...sendingData,
            [selectedState]: [...sendingData[selectedState], newOption]
        };
        setSendingData(newSendingData);
    };

    const submitLink = (parent, element) => {
        if (parent === "relationship") {
            setSendingData({
                ...sendingData,
                [parent]: sendingData[parent].map(ele => (ele.timestamp === element.timestamp ? { ...ele, isLoading: true, pending: false } : ele))
            });
        } else {
            setSendingData({
                ...sendingData,
                [parent]: sendingData[parent].map(ele => (ele.id === element.id ? { ...ele, isLoading: true } : ele))
            });
        }
        dispatch(
            crmActions.submitLink(names.find(name => name.name === parent).url, entityDetail.id, {
                ...element,
                [parent]: element.id,
                id: element.originalId || undefined,
                regions: parent === "assignment" ? element.regions.map(reg => reg.id) : undefined,
                end_date: parent === "relationship" ? element.end_date || undefined : undefined,
                pending: false
            })
        );
    };

    const onInputChange = (event, attributeName, id) => {
        const {
            target: { name, value }
        } = event;
        setSendingData({
            ...sendingData,
            [attributeName]: sendingData[attributeName].map(attr =>
                attributeName === "relationship"
                    ? attr.timestamp === id
                        ? { ...attr, [name]: value, isChanged: true }
                        : attr
                    : attr.id === id
                    ? { ...attr, [name]: value }
                    : attr
            )
        });
    };

    const onRemoveAttribute = (parent, element) => {
        if (element.originalId) {
            if (parent === "relationship") {
                dispatch(
                    crmActions.submitLink(names.find(name => name.name === parent).url, entityDetail.id, {
                        ...element,
                        [parent]: element.id,
                        id: element.originalId,
                        regions: undefined,
                        end_date: dayjs().format("YYYY-MM-DD"),
                        pending: false
                    })
                );
            } else {
                const confirm = window.confirm("Are you sure you delete this item completely?");
                if (confirm) {
                    dispatch(crmActions.deleteLink(names.find(name => name.name === parent).url, entityDetail.id, element.originalId));
                    const newSendingData = {
                        ...sendingData,
                        [parent]: sendingData[parent].filter(ele =>
                            parent === "relationship" ? ele.timestamp !== element.timestamp : ele.id !== element.id
                        )
                    };
                    setSendingData(newSendingData);
                }
            }
        } else {
            const newSendingData = {
                ...sendingData,
                [parent]: sendingData[parent].filter(ele => (parent === "relationship" ? ele.timestamp !== element.timestamp : ele.id !== element.id))
            };
            setSendingData(newSendingData);
        }
    };

    const onRegionChange = (option, id) => {
        const { assignment } = sendingData;
        setSendingData({
            ...sendingData,
            assignment: assignment.map(assign => (assign.id === id ? { ...assign, regions: option } : assign))
        });
    };

    const onCompaniesChange = (option, id) => {
        const { assignment } = sendingData;
        setSendingData({
            ...sendingData,
            assignment: assignment.map(assign => (assign.id === id ? { ...assign, companies: option } : assign))
        });
    };

    const onApproveClick = element => {
        dispatch(
            crmActions.submitLink("entity_relationship_link", entityDetail.id, {
                ...element,
                relationship: element.id,
                id: element.originalId || undefined,
                end_date: element.end_date || undefined,
                pending: false
            })
        );
    };

    const submitAttribute = event => {
        event.preventDefault();
        setIsValidated(true);
        const validated = sendingData.assignment.reduce((bool, ele) => !!(bool && ele.regions.length > 0), true);
        if (validated) {
            dispatch(crmActions.submitAttribute({ sendingData, names, entityId: entityDetail.id }));
            onEditClick(2);
        } else {
            alert("Please white down all required field.");
        }
    };

    return (
        <div className={type === "view" && wasValidated ? "was-validated" : ""}>
            <MDBCard className={`${styles.card} ${type === "view" ? styles.flatCard : ""}`}>
                <MDBCardHeader>Current Attributes</MDBCardHeader>
                <MDBCardBody>
                    <MDBRow>
                        <MDBCol size="12" className="mb-2">
                            <SelectComponent label="Relationship" options={relationships} onAttributeChanged={onAttributeChanged} />
                            {sendingData.relationship.length > 0 && (
                                <MDBTable small className={styles.detailResponsiveTable}>
                                    <MDBTableHead>
                                        <tr>
                                            <th width="30%">Name</th>
                                            <th>End Date</th>
                                            <th width="100px" className="text-center">
                                                Action
                                            </th>
                                        </tr>
                                    </MDBTableHead>
                                    <MDBTableBody>
                                        {sendingData.relationship.map(rel => (
                                            <AttributeElement
                                                companiesList={companiesList}
                                                type={type}
                                                key={rel.timestamp}
                                                parent="relationship"
                                                element={rel}
                                                administration={administration}
                                                onApproveClick={onApproveClick}
                                                onInputChange={onInputChange}
                                                onRemoveAttribute={onRemoveAttribute}
                                                submitLink={submitLink}
                                            />
                                        ))}
                                    </MDBTableBody>
                                </MDBTable>
                            )}
                        </MDBCol>
                        <MDBCol size="12" className="mb-2">
                            <SelectComponent
                                label="Role (Linings only)"
                                options={roles && roles.filter(role => !sendingData.role.find(selected => selected.id === role.id))}
                                onAttributeChanged={onAttributeChanged}
                            />
                            {sendingData.role.length > 0 && (
                                <MDBTable small className={styles.detailResponsiveTable}>
                                    <MDBTableHead>
                                        <tr>
                                            <th>Name</th>
                                            <th width="100px" className="text-center">
                                                Action
                                            </th>
                                        </tr>
                                    </MDBTableHead>
                                    <MDBTableBody>
                                        {sendingData.role.map(rl => (
                                            <AttributeElement
                                                type={type}
                                                key={rl.id}
                                                parent="role"
                                                element={rl}
                                                onInputChange={onInputChange}
                                                onRemoveAttribute={onRemoveAttribute}
                                                submitLink={submitLink}
                                            />
                                        ))}
                                    </MDBTableBody>
                                </MDBTable>
                            )}
                        </MDBCol>
                        <MDBCol size="12" className="mb-2">
                            <SelectComponent
                                label="Assignment"
                                options={
                                    assignments &&
                                    assignments.filter(assignment => !sendingData.assignment.find(selected => assignment.id === selected.id))
                                }
                                onAttributeChanged={onAttributeChanged}
                            />
                            {sendingData.assignment.length > 0 && (
                                <MDBTable small className={styles.detailResponsiveTable}>
                                    <MDBTableHead>
                                        <tr>
                                            <th width="30%">Assignment</th>
                                            <th>Regions</th>
                                            <th width="100px" className="text-center">
                                                Action
                                            </th>
                                        </tr>
                                    </MDBTableHead>
                                    <MDBTableBody>
                                        {sendingData.assignment.map(assign => (
                                            <AttributeElement
                                                companiesList={companiesList}
                                                key={assign.id}
                                                type={type}
                                                parent="assignment"
                                                wasValidated={wasValidated}
                                                element={assign}
                                                onRegionChange={onRegionChange}
                                                regions={regions}
                                                onInputChange={onInputChange}
                                                onRemoveAttribute={onRemoveAttribute}
                                                submitLink={submitLink}
                                                onCompaniesChange={onCompaniesChange}
                                            />
                                        ))}
                                    </MDBTableBody>
                                </MDBTable>
                            )}
                        </MDBCol>
                    </MDBRow>
                </MDBCardBody>
            </MDBCard>
            {type === "view" && (
                <MDBRow>
                    <MDBCol>
                        <div className={`${styles.card} float-right`}>
                            <MDBBtn type="button" color="blue-grey" onClick={() => onEditClick(2)}>
                                cancel
                            </MDBBtn>{" "}
                            <MDBBtn type="button" color="primary" onClick={submitAttribute}>
                                Save
                            </MDBBtn>
                        </div>
                    </MDBCol>
                </MDBRow>
            )}
        </div>
    );
};

export default memo(Attributes);
