import React, { Component } from "react";
import SiteDiaryModal from "./presenter";
import dayjs from "shared/dayjs";

class Container extends Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedDate: new Date()
        };
    }

    componentDidUpdate = prevProps => {
        const { diaryModal, isMobile, selectedJob, getActiveDates } = this.props;
        if (diaryModal && diaryModal !== prevProps.diaryModal) {
            getActiveDates(selectedJob);
        }
        if (diaryModal) {
            document.body.style = `overflow:hidden; ${!isMobile && "padding-right:15px !important"}`;
        } else {
            document.body.style = undefined;
        }
    };

    componentWillUnmount = () => {
        document.body.style = undefined;
    };

    onCalendarChange = e => {
        const { history, selectedJob, datesOnActive, permissions } = this.props;
        const selectedDate = dayjs(e).format("YYYY-MM-DD");
        const isActiveDate = datesOnActive.find(activeDate => activeDate.diary_date === selectedDate);

        if (isActiveDate) {
            history.push(`/operations/diary/${isActiveDate.id}`);
        } else {
            if (permissions.operations === 3 || permissions.operations === 4) {
                history.push(`/operations/diary/new?job=${selectedJob}&date=${selectedDate}`);
            }
        }
    };

    render = () => {
        const { diaryModal, toggleDiary, datesOnActive } = this.props;
        return (
            <SiteDiaryModal
                {...this.state}
                diaryModal={diaryModal}
                datesOnActive={datesOnActive}
                toggleDiary={toggleDiary}
                onCalendarChange={this.onCalendarChange}
            />
        );
    };
}

export default Container;
