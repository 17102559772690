import React, { useContext } from "react";
import Select from "react-select";
import { RequestListContext, RosterContext } from "../TaskContext";
import Message from "./Message";

const JobSelction = ({ jobList }) => {
    const { setSelectedItem, selectedItem } = useContext(RosterContext);
    const { setMessage } = useContext(RequestListContext);

    return (
        <Select
            options={jobList}
            isClearable
            value={selectedItem}
            // menuIsOpen
            getOptionLabel={option => option.job_name}
            onChange={option => {
                if (option === null) {
                    setSelectedItem();
                    setMessage("");
                    return;
                }
                setSelectedItem(jobList.find(list => list.id === option.id));
                setMessage(<Message list={option} />);
            }}
            placeholder="Select Jobs"
            getOptionValue={option => option.id}
        />
    );
};

export default JobSelction;
