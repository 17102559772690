import { connect } from "react-redux";
import Container from "./container";
import { actionCreators as userActions } from "redux/modules/user";

const mapStateToProps = state => {
    const {
        user: { welcomeName, notFoundUser }
    } = state;
    return {
        welcomeName,
        notFoundUser
    };
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        userLogin: (username, password) => {
            dispatch(userActions.login(username, password));
        }
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Container);
