// imports
import { axiosInstance } from "shared/axiosInst";
import { downloadFile } from "shared/Utils";
// variables

// actions
// const SET_TRANSFER_LIST = "SET_TRANSFER_LIST";
// const SET_REPORT_LIST = "SET_REPORT_LIST";
const SET_IS_PRICED = "SET_IS_PRICED";

// API actions

function submitTransfer(data) {
    return async (_, getState) => {
        const {
            user: { currentCompany }
        } = getState();
        try {
            const response = await axiosInstance.post(`${currentCompany}stock/stock_movement/`, data);
            return response.data;
        } catch (error) {
            console.log(error.response);
            return "";
        }
    };
}

function modifyTransfer(data) {
    return async (_, getState) => {
        const {
            user: { currentCompany }
        } = getState();
        try {
            const response = await axiosInstance.put(`${currentCompany}stock/stock_movement/${data.id}/`, data);
            return response.data;
        } catch (error) {
            console.log(error.response);
            return "";
        }
    };
}

function getTransferList(filters) {
    return async (_, getState) => {
        const {
            user: { currentCompany }
        } = getState();
        try {
            const { statusFilter = "", filter = "" } = filters;
            const response = await axiosInstance.get(`${currentCompany}stock/stock_movement/${statusFilter}${filter}`);
            let transferList = response.data;
            if (filter.includes("myob_record_exists=true")) {
            } else {
                transferList = transferList.filter(list => !list.myob_record_exists);
            }
            if (filter.includes("uncommon_sku=true")) {
                transferList = transferList.filter(list => list.uncommon_sku);
            }
            return transferList;
        } catch (error) {
            console.log(error.response);
        }
    };
}

function getTransferDetail(id) {
    return async (_, getState) => {
        const {
            user: { currentCompany }
        } = getState();
        try {
            const response = await axiosInstance.get(`${currentCompany}stock/stock_movement/${id}/`);
            return response.data;
        } catch (error) {
            console.log(error.response);
        }
    };
}

function getTransferPrice(transferId) {
    return async (dispatch, getState) => {
        const {
            user: { currentCompany, permissions }
        } = getState();
        try {
            if (permissions.stock === 4) {
                const priceReponse = await axiosInstance.get(`${currentCompany}stock/price_movement/${transferId}/`);
                dispatch(setIsPriced(true));
                return priceReponse.data;
            } else {
                return "";
            }
        } catch (error) {
            if (error.response.status === 404) {
                dispatch(setIsPriced(false));
            }
            return error.response;
        }
    };
}

function getReportList() {
    return async (_, getState) => {
        const {
            user: { currentCompany }
        } = getState();

        try {
            const reportReponse = await axiosInstance.get(`${currentCompany}stock/report_movement/slim_report_list/`);
            const reports = reportReponse.data;
            return reports;
        } catch (error) {
            console.log(error.response);
        }
    };
}

function getReportDetail(id) {
    return async (_, getState) => {
        const {
            user: { currentCompany }
        } = getState();

        try {
            const reportReponse = await axiosInstance.get(`${currentCompany}stock/report_movement/${id}/`);
            const reports = reportReponse.data;
            return reports;
        } catch (error) {
            console.log(error.response);
        }
    };
}

function submitPrice(data) {
    return async (_, getState) => {
        const {
            user: { currentCompany }
        } = getState();
        try {
            const response = await axiosInstance.post(`${currentCompany}stock/price_movement/`, data);
            return response.data;
        } catch (error) {
            alert(error.response);
        }
    };
}

function generateReport(data) {
    return async (_, getState) => {
        const {
            user: { currentCompany }
        } = getState();
        try {
            const response = await axiosInstance.post(`${currentCompany}stock/report_movement/`, data);
            return response.data;
        } catch (error) {
            console.log(error.response);
        }
    };
}

function downloadReport(id, signal) {
    return async (_, getState) => {
        const {
            user: { currentCompany }
        } = getState();
        try {
            const response = await axiosInstance.post(
                `${currentCompany}stock/report_movement/${id}/download_report/`,
                {},
                {
                    responseType: "blob",
                    timeout: 50000,
                    cancelToken: signal.token
                }
            );

            downloadFile(response);
            return { message: "complete" };
        } catch (error) {
            console.log(error.response);
        }
    };
}

// action creators
// function setTransferList(transferList) {
//     return {
//         type: SET_TRANSFER_LIST,
//         transferList
//     };
// }

function setIsPriced(isPriced) {
    return {
        type: SET_IS_PRICED,
        isPriced
    };
}

// initial states

const initialState = { transferList: [], reportList: [], isPriced: false };

// reducer

function reducer(state = initialState, action) {
    switch (action.type) {
        case SET_IS_PRICED:
            const { isPriced } = action;
            return {
                ...state,
                isPriced
            };
        default:
            return state;
    }
}

// reducer function
// function applyTransferList(state, action) {
//     const { transferList } = action;
//     return {
//         ...state,
//         transferList
//     };
// }

// export

const actionCreators = {
    submitTransfer,
    modifyTransfer,
    getTransferList,
    getTransferDetail,
    getTransferPrice,
    submitPrice,
    generateReport,
    getReportList,
    getReportDetail,
    downloadReport,
    setIsPriced
};

export { actionCreators };

// reducer export
export default reducer;
