import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import styles from "shared/listStyles.scss";
import calendarStyles from "./styles.scss";
import { Chip, Stack } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import theme from "../LeaveItem/ColorCode";
import { actionCreators as hrActions } from "redux/modules/humanResources";
import AdminCalendar from "./AdminCalendar";
import { Helmet } from "react-helmet";

const LeaveCalendar = () => {
    const dispatch = useDispatch();
    const [leaveType, setLeaveType] = useState([]);

    useEffect(() => {
        const getLeaveType = async () => {
            const leaveTypes = await dispatch(hrActions.getLeaveType());
            setLeaveType(leaveTypes);
        };
        getLeaveType();
    }, [dispatch]);

    return (
        <ThemeProvider theme={theme}>
            <Helmet>
                <title>HR | Leave Calendar</title>
            </Helmet>
            <div className={styles.container}>
                <div className={styles.titleWrap}>
                    <h2 className={`${styles.taskTitle}`}>LEAVE CALENDAR</h2>
                </div>

                <div className={calendarStyles.typeContainer}>
                    <Stack direction="row" flexWrap="wrap" justifyContent="center" alignItems="center">
                        {leaveType?.map(type => (
                            <Chip key={type.id} sx={{ margin: "2px" }} color={type.name} label={type.name} size="small" />
                        ))}
                    </Stack>
                </div>
                <AdminCalendar />
            </div>
        </ThemeProvider>
    );
};

export default LeaveCalendar;
