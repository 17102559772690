import React, { useContext, lazy, Suspense, useState } from "react";
import { Chip, IconButton, Stack, Tooltip } from "@mui/material";
import scheduleStyle from "./styles.scss";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { RosterContext, RosterCalendarContext } from "../TaskContext";
import { axiosInstance } from "shared/axiosInst";
import CancelIcon from "@mui/icons-material/Cancel";
import { useSelector } from "react-redux";
const AnnotationModal = lazy(() => import("./AnnotationModal"));

const uuidPattern = /[a-f0-9]{8}-[a-f0-9]{4}-4[a-f0-9]{3}-[a-f0-9]{4}-[a-f0-9]{12}/;

const WorkerDateListItem = ({ worker, date }) => {
    const { userId } = useSelector(state => state.user);
    const { selectedItem, isInRostering } = useContext(RosterContext);
    const { setAssignedList, assignedList } = useContext(RosterCalendarContext);
    const [isOpen, setIsOpen] = useState(false);
    const [selectedJob, setSelectedJob] = useState("");
    const onAddClick = async selectedDate => {
        const existedJob = assignedList?.find(list => {
            let matchedId = false;
            if (String(selectedItem.id).match(uuidPattern)) {
                matchedId = list.labour_request === null;
            } else {
                matchedId = list.labour_request === selectedItem.id;
            }
            return (
                (list.job ? list.job === selectedItem.job : list.leave_type === selectedItem.leave_type) &&
                list.assigned_date === selectedDate &&
                list.assigned_labour === worker.id &&
                matchedId
            );
        });
        if (!existedJob) {
            const isUUID = String(selectedItem.id).match(uuidPattern);
            const newItem = await axiosInstance.post(`fire-akl/maintenance/assigned_roster/`, {
                labour_request: isUUID ? undefined : selectedItem.id,
                assigned_date: selectedDate,
                job: selectedItem.id,
                assigned_labour: worker.id,
                leave_type: selectedItem.leave_type || undefined
            });
            setAssignedList([...assignedList, { ...newItem.data, my: userId === selectedItem.internal_supervisor_id || userId === worker.gru }]);
        }
    };

    const onDelectClick = (jobId, date, labour_request) => {
        const existedJob = assignedList?.find(
            list => list.job === jobId && list.assigned_date === date && list.assigned_labour === worker.id && list.labour_request === labour_request
        );
        axiosInstance
            .patch(`fire-akl/maintenance/assigned_roster/${existedJob.id}/remove_assignment/`)
            .catch(error => {
                alert("Something went wrong, try again later. If it keeps occuring, please contact IT team.");
                console.log(error);
            })
            .finally(() => {
                setAssignedList(assignedList.filter(job => job.id !== existedJob.id));
            });
    };
    return (
        <td
            key={date.d}
            data-date={date.d}
            className={
                date.calendarDay.includes("Sunday") || date.calendarDay.includes("Saturday")
                    ? scheduleStyle.weekend
                    : assignedList.filter(job => job.assigned_date === date.d && job.assigned_labour === worker.id).length === 0
                    ? scheduleStyle.empty
                    : ""
            }
        >
            <Stack direction="row" flexWrap="wrap">
                {isInRostering && (
                    <IconButton disabled={!selectedItem} size="small" color="secondary" onClick={() => onAddClick(date.d)} sx={{ padding: "1px" }}>
                        <AddCircleIcon />
                    </IconButton>
                )}
                {assignedList?.map(
                    job =>
                        job.assigned_date === date.d &&
                        job.assigned_labour === worker.id && (
                            <Tooltip
                                key={job.id}
                                disableInteractive
                                title={
                                    job.job_address && (
                                        <>
                                            <p style={{ margin: 0, padding: 0 }}>
                                                <strong style={{ color: "rgb(130, 177, 255)" }}>ADDR</strong> : {job.job_address}
                                            </p>
                                            {job.job_name !== job.job_address ? (
                                                <p style={{ margin: 0, padding: 0 }}>
                                                    <strong style={{ color: "rgb(130, 177, 255)" }}>NAME</strong> : {job.job_name}
                                                </p>
                                            ) : (
                                                ""
                                            )}
                                            {job.staff_instruction ? (
                                                <p style={{ margin: 0, padding: 0 }}>
                                                    <strong style={{ color: "rgb(130, 177, 255)" }}>INSTRUCTION</strong> : {job.staff_instruction}
                                                </p>
                                            ) : (
                                                ""
                                            )}
                                        </>
                                    )
                                }
                            >
                                <Chip
                                    variant={"outlined"}
                                    size="small"
                                    color={job.leave_type ? "error" : job.my ? "secondary" : "default"}
                                    clickable={!job.leave_type}
                                    onClick={() => {
                                        if (!job.leave_type) {
                                            setSelectedJob(job);
                                            setIsOpen(true);
                                        }
                                    }}
                                    onDelete={() => onDelectClick(job.job, date.d, job.labour_request)}
                                    deleteIcon={<CancelIcon />}
                                    label={
                                        job.leave_type
                                            ? `${job.leave_type_note}` //(${job.leave_type})
                                            : `${job.job}-${job.region_short_code || job.short_region} (${job.job_name})`
                                    }
                                    sx={{ margin: "1px", maxWidth: 150 }}
                                />
                            </Tooltip>
                        )
                )}
            </Stack>
            {isOpen && (
                <Suspense fallback={<div />}>
                    <AnnotationModal
                        open={isOpen}
                        selectedJob={selectedJob}
                        toggle={() => {
                            setIsOpen(false);
                        }}
                    />
                </Suspense>
            )}
        </td>
    );
};

export default WorkerDateListItem;
