import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { actionCreators as crmActions } from "redux/modules/crm";
import styles from "shared/listStyles.scss";
import detailStyles from "shared/details.scss";
import { MDBBtn, MDBBtnGroup, MDBIcon, MDBInputGroup } from "louis-mdbreact";
import CircularProgress from "@mui/material/CircularProgress";

import CreateNewButton from "components/CreateNewButton";
import dayjs from "shared/dayjs";
import Select from "react-select";
import { Helmet } from "react-helmet";

const Payrate = () => {
    const { entitiesList } = useSelector(state => state.crm);
    const [staffList, setStaffList] = useState([]);
    const [entityList, setEntityList] = useState(entitiesList);
    const [isLoading, setIsLoading] = useState(true);
    const payFilter = localStorage.getItem("payFilter") ? JSON.parse(localStorage.getItem("payFilter")) : { isCurrent: true };
    const [filter, setFilter] = useState(payFilter);
    const payRateRef = useRef();
    const dispatch = useDispatch();
    useEffect(() => {
        const fetchList = async () => {
            const list = await dispatch(crmActions.getMaintenanceStaffPay(filter.isCurrent));
            setStaffList(
                list.map((staff, i) => ({
                    ...staff,
                    timestamp: Number(new Date()) + i,
                    mode: "idle"
                }))
            );
            setIsLoading(false);
            await dispatch(crmActions.getSlimEntity("?relationships=1&assignments=2"));
        };
        fetchList();
    }, [dispatch, filter.isCurrent]);

    useEffect(() => {
        setEntityList(entitiesList);
    }, [entitiesList]);
    useEffect(() => {}, [staffList]);

    const addNewRate = event => {
        setStaffList([
            {
                timestamp: Number(new Date()),
                pay_rate: "",
                name: "",
                staff_member: "",
                mode: "new"
            },
            ...staffList
        ]);
    };
    const onStaffChange = (option, timestamp) => {
        setStaffList(
            staffList.map(list =>
                list.timestamp === timestamp
                    ? {
                          ...list,
                          option
                      }
                    : list
            )
        );
    };

    const changeRate = (value, timestamp) => {
        setStaffList(
            staffList.map(list =>
                list.timestamp === timestamp
                    ? {
                          ...list,
                          pay_rate: value
                      }
                    : list
            )
        );
    };

    const onChangeMode = (isEdit, timestamp) => {
        setStaffList(
            staffList.map(list =>
                list.timestamp === timestamp
                    ? isEdit
                        ? {
                              ...list,
                              mode: isEdit ? "edit" : "idle",
                              option: isEdit ? { id: list.staff_member, name: list.name } : "",
                              oldValue: isEdit ? list : ""
                          }
                        : list.oldValue
                    : list
            )
        );
    };

    const filterChange = () => {
        const newFilter = { ...filter, isCurrent: !filter.isCurrent };
        setFilter(newFilter);
        localStorage.setItem("payFilter", JSON.stringify(newFilter));
    };

    const submitPayRate = async (timestamp, isEdit) => {
        const selectedData = staffList.find(staff => staff.timestamp === timestamp);
        if (!selectedData.option || !selectedData.pay_rate) {
            alert('Please make sure "Name" and "Pay Rate" fields are written');
            document.getElementById(`rateInput_${timestamp}`).focus();
            return;
        }

        setStaffList(staffList.map(staff => (staff.timestamp === timestamp ? { ...staff, isLoading: true } : staff)));
        try {
            const response = await dispatch(
                crmActions.submitMaintenanceStaffPay(
                    {
                        ...selectedData,
                        name: selectedData.option.name,
                        staff_member: selectedData.option.id,
                        option: undefined,
                        oldValue: undefined,
                        last_updated: undefined,
                        mode: undefined
                    },
                    isEdit
                )
            );

            if (response.staff_member) {
                setStaffList(currentStaffList =>
                    currentStaffList.map(staff =>
                        staff.timestamp === response.timestamp
                            ? {
                                  ...response,
                                  mode: "idle",
                                  isLoading: false
                              }
                            : staff
                    )
                );
            } else {
                if (isEdit) {
                    setStaffList(currentStaffList =>
                        currentStaffList.map(staff =>
                            staff.timestamp === timestamp
                                ? {
                                      ...staff,
                                      mode: "idle",
                                      isLoading: false
                                  }
                                : staff
                        )
                    );
                } else {
                    setStaffList(currentStaffList => currentStaffList.filter(list => list.timestamp !== timestamp));
                }
            }
        } catch (error) {
            console.log(error.response);
        }
    };

    const customStyles = {
        control: provided => ({
            ...provided,
            minHeight: "31px",
            height: "31px"
        }),

        valueContainer: provided => ({
            ...provided,
            height: "30px",
            padding: "0 6px"
        }),

        input: provided => ({
            ...provided,
            margin: "0px"
        }),

        indicatorsContainer: provided => ({
            ...provided,
            height: "31px"
        })
    };

    return (
        <div className={styles.container}>
            <Helmet>
                <title>CRM | Staff Pay Rate</title>
            </Helmet>
            <div className={styles.titleWrap}>
                <h2 className={`${styles.taskTitle}`}>Staff Pay Rate {isLoading && <CircularProgress size={28} className="ml-1" />}</h2>
                <CreateNewButton />
                <div className={styles.switchWrap}>
                    <div className={`${styles.listSwitch} custom-control custom-switch`}>
                        <input
                            type="checkbox"
                            className="custom-control-input"
                            id="current"
                            name="isCurrent"
                            checked={filter.isCurrent}
                            onChange={filterChange}
                            readOnly
                        />
                        <label className="custom-control-label" htmlFor="current">
                            CURRENT
                        </label>
                    </div>
                </div>
            </div>
            <MDBBtn color="primary" size="sm" className="ml-0" onClick={addNewRate}>
                <MDBIcon icon="plus" /> add new rate
            </MDBBtn>
            <table className={`table table-sm table-borderless table-striped ${detailStyles.detailResponsiveTable}`}>
                <thead>
                    <tr>
                        <th width="40%">Entity</th>
                        <th>Pay Rate</th>
                        <th width="15%">Last Updated</th>
                        <th width="150px" className="text-center">
                            Actions
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {staffList.length > 0 ? (
                        staffList.map(list =>
                            list.mode === "idle" ? (
                                <tr key={list.timestamp} className={detailStyles.addItemContainer}>
                                    <td data-th="Entity">{list.name}</td>
                                    <td data-th="Pay Rate">$ {list.pay_rate}</td>
                                    <td data-th="Last Updated">{dayjs(list.last_updated).format("DD/MM/YYYY")}</td>
                                    <td data-th="Actions" className="text-center">
                                        <MDBBtnGroup className={detailStyles.buttonGroup}>
                                            <MDBBtn className={styles.buttonSize} color="info" onClick={e => onChangeMode(true, list.timestamp)}>
                                                <MDBIcon icon="exchange-alt" />
                                            </MDBBtn>
                                        </MDBBtnGroup>
                                    </td>
                                </tr>
                            ) : (
                                <tr key={list.timestamp} className={detailStyles.addItemContainer}>
                                    {list.mode === "edit" ? (
                                        <td data-th="Entity">{list.name}</td>
                                    ) : (
                                        <td data-th="Entity">
                                            <Select
                                                options={entityList.filter(
                                                    entity =>
                                                        !staffList.find(staff =>
                                                            staff.option ? staff.option.id === entity.id : staff.staff_member === entity.id
                                                        )
                                                )}
                                                onChange={option => onStaffChange(option, list.timestamp)}
                                                value={list.option}
                                                getOptionValue={option => option.id}
                                                getOptionLabel={option => option.name}
                                                styles={customStyles}
                                            />
                                        </td>
                                    )}
                                    <td data-th="Pay Rate">
                                        <MDBInputGroup
                                            prepend="$"
                                            size="sm"
                                            inputs={
                                                <input
                                                    ref={payRateRef}
                                                    type="number"
                                                    className="form-control"
                                                    min="0"
                                                    step="0.01"
                                                    inputMode="decimal"
                                                    onChange={e => changeRate(e.target.value, list.timestamp)}
                                                    id={`rateInput_${list.timestamp}`}
                                                    value={list.pay_rate}
                                                />
                                            }
                                        />
                                    </td>
                                    <td data-th="Last Updated">{dayjs(list.last_updated).format("DD/MM/YYYY")}</td>
                                    <td data-th="Actions" className="text-center">
                                        <MDBBtnGroup className={detailStyles.buttonGroup}>
                                            {list.mode === "edit" ? (
                                                <>
                                                    <MDBBtn
                                                        className={styles.buttonSize}
                                                        color="info"
                                                        onClick={e => !list.isLoading && submitPayRate(list.timestamp, true)}
                                                    >
                                                        {list.isLoading ? <LoadingSpinner /> : <MDBIcon icon="exchange-alt" />}
                                                    </MDBBtn>
                                                    <MDBBtn
                                                        className={styles.buttonSize}
                                                        color="secondary"
                                                        onClick={e => onChangeMode(false, list.timestamp)}
                                                    >
                                                        <MDBIcon icon="undo" />
                                                    </MDBBtn>
                                                </>
                                            ) : (
                                                <>
                                                    <MDBBtn
                                                        className={styles.buttonSize}
                                                        color="primary"
                                                        onClick={e => !list.isLoading && submitPayRate(list.timestamp, false)}
                                                    >
                                                        {list.isLoading ? <LoadingSpinner /> : <MDBIcon far icon="save" />}
                                                    </MDBBtn>
                                                    <MDBBtn
                                                        className={styles.buttonSize}
                                                        onClick={e => setStaffList(staffList.filter(staff => staff.timestamp !== list.timestamp))}
                                                        color="danger"
                                                    >
                                                        <MDBIcon far icon="trash-alt" />
                                                    </MDBBtn>
                                                </>
                                            )}
                                        </MDBBtnGroup>
                                    </td>
                                </tr>
                            )
                        )
                    ) : (
                        <tr>
                            <td colSpan="5" className="text-center">
                                No Staff
                            </td>
                        </tr>
                    )}
                </tbody>
            </table>
            <p className="mb-5">{staffList.filter(staff => staff.mode === "idle").length} maintenance staff pay rate</p>
        </div>
    );
};

const LoadingSpinner = () => (
    <div className="spinner-border spinner-border-sm text-white" role="status">
        <span className="sr-only">Loading...</span>
    </div>
);

export default Payrate;
