import React, { useEffect, useState } from "react";
import styles from "shared/listStyles.scss";
import { Grid } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { actionCreators as crmActions } from "redux/modules/crm";
import { actionCreators as taskActions } from "redux/modules/tasks";
import { Helmet } from "react-helmet";
// import { ThemeProvider } from "@mui/material/styles";
import RosterCalendar from "./RosterCalendar";
import { RosterContext, RosterCalendarContext } from "../TaskContext";
// import { getRosteringServices } from "shared/Utils";
// import { getWorkerTheme } from "./WorkerTheme";

import RequestedItem from "./RequestedItem";
import BranchSelect from "./BranchSelect";

const Roster = () => {
    const dispatch = useDispatch();
    const { isRosterer, companyPrefix } = useSelector(state => state.user);
    // const services = JSON.parse(localStorage.getItem("service"));
    const [selectedItem, setSelectedItem] = useState();
    const [requestedJobList, setRequestedJobList] = useState([]);
    const [assignedList, setAssignedList] = useState([]);
    const [loading, setLoading] = useState(false);

    const [addedJobList, setAddedJobList] = useState([]);

    const [currentBranch, setCurrentBranch] = useState("");
    // const workerTheme = getWorkerTheme(services);
    const [isInRostering] = useState(true);

    useEffect(() => {
        if (currentBranch) {
            dispatch(
                crmActions.getSkinnyRole(
                    `?assignment=2&reporting_area=${currentBranch}&entity_type=5&relationship_type=1&global_company=${companyPrefix}`
                )
            );
            dispatch(taskActions.getRosterList(`my_current/?region__fire_areas=${currentBranch}`));
            setSelectedItem();
            setAddedJobList([]);
        }
    }, [dispatch, currentBranch, isRosterer, companyPrefix]);

    return (
        <div className={styles.container}>
            {/* <ThemeProvider theme={workerTheme}> */}
            <RosterContext.Provider
                value={{
                    addedJobList,
                    setSelectedItem,
                    setAddedJobList,
                    selectedItem,
                    // services,
                    requestedJobList,
                    setRequestedJobList,
                    setCurrentBranch,
                    currentBranch,
                    isInRostering,
                    loading,
                    setLoading
                }}
            >
                <Helmet>
                    <title>Tasks | Rostering Planner</title>
                </Helmet>
                <div className={styles.titleWrap} style={{ display: "flex", width: "100%", justifyContent: "space-between" }}>
                    <h2 className={`${styles.taskTitle}`}>Rostering Planner</h2>
                    {/* <Stack direction="row" flexWrap="wrap" justifyContent={{ md: "flex-end", sm: "flex-end", xs: "flex-start" }}>
                            {getRosteringServices(services).map(service => (
                                <Chip key={service.id} color={`${service.name}`} size="small" sx={{ margin: "2px" }} label={service.name} />
                            ))}
                        </Stack> */}
                </div>
                <Grid container alignItems="flex-start" spacing={2}>
                    <Grid item sm={12} md={12} lg={12}>
                        <BranchSelect />
                    </Grid>
                    <RosterCalendarContext.Provider value={{ setAssignedList, assignedList, isRosterer }}>
                        <RequestedItem />
                        <Grid container item xs={12} sm={12} md={12} lg={10}>
                            <RosterCalendar />
                        </Grid>
                    </RosterCalendarContext.Provider>
                </Grid>
            </RosterContext.Provider>
            {/* </ThemeProvider> */}
        </div>
    );
};

export default Roster;
