import { deepPurple, blue, lime, yellow, red, pink, cyan, grey, orange, lightGreen } from "@mui/material/colors";
import { createTheme } from "@mui/material/styles";
const theme = createTheme({
    palette: {
        Annual: {
            main: lightGreen["500"],
            light: lightGreen["400"],
            dark: lightGreen["600"],
            contrastText: "#fff"
        },
        Sick: {
            main: deepPurple["500"],
            light: deepPurple["400"],
            dark: deepPurple["600"],
            contrastText: "#fff"
        },
        Bereavement: {
            main: blue["500"],
            light: blue["400"],
            dark: blue["600"],
            contrastText: "#fff"
        },
        Parental: {
            main: lime["900"],
            light: lime["800"],
            dark: lime["900"],
            contrastText: "#fff"
        },
        "Public Holiday": {
            main: yellow["A400"],
            light: yellow["A200"],
            dark: yellow["A700"],
            contrastText: "#000"
        },
        "Leave Without Pay": {
            main: red["500"],
            light: red["400"],
            dark: red["600"],
            contrastText: "#fff"
        },
        "ACC - Non Work": {
            main: pink["200"],
            light: pink["100"],
            dark: pink["300"],
            contrastText: "#fff"
        },
        "ACC - Work Related": {
            main: cyan["A200"],
            light: cyan["A100"],
            dark: cyan["A400"],
            contrastText: "#000"
        },
        "No Show": {
            main: grey["900"],
            light: grey["800"],
            dark: grey["900"],
            contrastText: "#fff"
        },
        "Other (See Note)": {
            main: cyan["900"],
            light: cyan["800"],
            dark: cyan["900"],
            contrastText: "#fff"
        },
        "Away on Business": {
            main: orange["200"],
            light: orange["100"],
            dark: orange["300"],
            contrastText: "#000"
        }
    }
});

export default theme;
