import Chip from "@mui/material/Chip";
import { red, orange, green } from "@mui/material/colors";
import { getDistance } from "shared/Utils";
import { useTheme } from "@mui/material/styles";

const ColoredChip = ({ distance, ...rest }) => {
    const theme = useTheme();
    let brightness = 100;
    if (theme.palette.mode === "dark") {
        brightness = 500;
    }
    let chipColor = green[brightness];
    if (distance <= 0.25) {
        chipColor = green[brightness];
    } else if (distance > 0.25 && distance <= 0.5) {
        chipColor = orange[brightness];
    } else if (distance > 0.5) {
        chipColor = red[brightness];
    } else {
        chipColor = red[brightness];
    }
    return <Chip sx={{ backgroundColor: chipColor }} size="small" label={`${getDistance(distance)}`} {...rest} />;
};

export default ColoredChip;
