import React, { memo } from "react";
import { Stack } from "@mui/material";
// import RoleDot from "components/modals/RosteringModal/RoleDot";
// import { RosterContext } from "../TaskContext";
import { getDateList } from "shared/Utils";
import WorkerDateListItem from "./WorkerDateListItem";

const WorkerList = ({ worker, dateRange, totalHours = 0 }) => {
    // const { services } = useContext(RosterContext);
    return (
        <>
            <th>
                <Stack direction="row" spacing={0.3} flexWrap="wrap" alignItems="center">
                    <span>{`${worker.name} ${totalHours > 0 ? `(${totalHours})` : ""}`}</span>
                    {/* {worker.roles.map(opt => (
                        <Tooltip key={opt} title={services.find(ser => ser.id === opt).name} disableInteractive>
                            <RoleDot color={services.find(ser => ser.id === opt).name} />
                        </Tooltip>
                    ))} */}
                </Stack>
            </th>
            {getDateList(dateRange).map(date => (
                <WorkerDateListItem worker={worker} key={date.d} date={date} />
            ))}
        </>
    );
};

export default memo(WorkerList);
