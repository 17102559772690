import React from "react";
import styles from "../styles.scss";
import { useSelector } from "react-redux";
import { MDBRow } from "louis-mdbreact";
// import { Link } from "react-router-dom";
import FollowupList from "./FollowupList";
import CreateNewButton from "components/CreateNewButton";
import Grid from "@mui/material/Grid";
import { Helmet } from "react-helmet";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
// import { Tooltip } from "@mui/material";

const Dashboard = () => {
    const {
        user: { welcomeName }
    } = useSelector(state => state);
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down("sm"));
    return (
        <div className={styles.dashContainer}>
            <Helmet>
                <title>Fire Portal | Dashboard</title>
            </Helmet>
            <MDBRow>
                {/* <div className={styles.menuContainer}>
                    <ul className={styles.menu}>
                        {menuList?.map(list =>
                            list.visibility ? (
                                <li key={list.name}>
                                    <Link to={list.link}>
                                        <MDBIcon className={styles[list.style]} icon={list.icon} />
                                        <span>{list.name}</span>
                                    </Link>
                                </li>
                            ) : (
                                ""
                            )
                        )}
                    </ul>
                </div> */}
                <div className={styles.contentContainer}>
                    {/* {permissions.maintenance >= 3 && isSupervisor && <RequestLabourButton />} */}
                    <CreateNewButton />
                    <h2 className="mb-3" style={{ display: "flex", flexDirection: matches ? "column" : "row", gap: "0.5rem" }}>
                        <span>Hi,</span> <span>{welcomeName}</span>
                        {/* {permissions.maintenance >= 3 || isSupervisor ? <TimecloudInfoButton /> : ""} */}
                    </h2>
                    <Grid container spacing={2}>
                        {/* {permissions.ordering && (
                            <Grid item xs={12}>
                                <UpcomingDelivery />
                            </Grid>
                        )} */}
                        <Grid item md={6} sm={12} xs={12}>
                            <FollowupList listURL="assigned_to" listTitle="Assigned Notes" />
                        </Grid>
                        <Grid item md={6} sm={12} xs={12}>
                            <FollowupList listURL="also_notify" listTitle="Notified Notes" />
                        </Grid>
                    </Grid>
                </div>
            </MDBRow>
        </div>
    );
};

export default Dashboard;
