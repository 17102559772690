import React from "react";
import styles from "shared/details.scss";
import Loading from "components/Loading";
import { MDBRow, MDBCol } from "louis-mdbreact";
import SubmitButton from "components/SubmitButton";
import ArrowBackButton from "components/ArrowBackButton";
import ClientDetailCard from "./ClientDetailCard";
import ClientContactDetail from "./ClientContactDetail";
import JobDetailCard from "./JobDetailCard";
import PrestartDetailCard from "./PrestartDetailCard";
import ProjectDetailCard from "./ProjectDetailCard";
import InternalContactDetailCard from "./InternalContactDetailCard";
// import ServiceDetailCard from "./ServiceDetailCard";
import AdminDetailCard from "./AdminDetailCard";
import RetentionDetailCard from "./RetentionDetailCard";
import FileDetailCard from "./FileDetailCard";
import QSChecklistCard from "./QSChecklistCard";

// TODO : need to check if it's working fine after refactoring
const JobDetail = props => {
    const {
        isLoading,
        isValidated,
        baseData,
        onClientChange,
        // onServiceChange,
        isNewClient,
        sendingData,
        clientContact,
        onChangeValue,
        onDetailSubmit,
        type,
        permissions,
        isDisabled,
        isResourceValid,
        generateBluesheet,
        onChangeMainAddress,
        onEditAddress,
        items,
        forwardRef,
        pendingLoading,
        onChangeAttendees,
        currentRegion,
        onNewAddress,
        addressData,
        main_address,
        onClientContactChange,
        addNewClient,
        attendeesList,
        addItem,
        onFileChange
    } = props;

    return (
        <div className={styles.container}>
            <h2 className={styles.title}>
                {type !== "new" && <ArrowBackButton />}
                <span>{props.title}</span>
            </h2>
            {isLoading ? (
                <Loading loadingType="detail" />
            ) : (
                <form onSubmit={onDetailSubmit} className={`needs-validation ${isValidated ? "was-validated" : ""}`} noValidate>
                    <ClientDetailCard
                        type={type}
                        generateBluesheet={generateBluesheet}
                        clients={baseData.clients}
                        client={sendingData.client}
                        resource={sendingData.resource}
                        onClientChange={onClientChange}
                        isResourceValid={isResourceValid}
                        isDisabled={isDisabled}
                        addNewClient={addNewClient}
                    />
                    <ClientContactDetail
                        resource={sendingData.resource}
                        client={sendingData.client}
                        permissions={permissions}
                        isPendingClientQSEntity={sendingData.isPendingClientQSEntity}
                        client_qs={sendingData.client_qs}
                        onChangeValue={onChangeValue}
                        isDisabled={isDisabled}
                        isNewClient={isNewClient}
                        clientContact={clientContact}
                        onClientContactChange={onClientContactChange}
                        client_qs_entity={sendingData.client_qs_entity}
                        sendingDataFireEngineer={sendingData.fire_engineer}
                        baseDataFireEngineer={baseData.fireEngineer}
                        addNewClient={addNewClient}
                    />
                    <JobDetailCard
                        permissions={permissions}
                        type={type}
                        jobId={sendingData.id}
                        isDisabled={isDisabled}
                        quote_identifier={sendingData.quote_identifier}
                        onChangeValue={onChangeValue}
                        price_type={sendingData.price_type}
                        priceType={baseData?.priceType || []}
                        business_category={sendingData.business_category}
                        jobType={baseData?.jobType || []}
                    />
                    {type === "detail" ? (
                        <PrestartDetailCard
                            attendeesList={attendeesList}
                            onChangeAttendees={onChangeAttendees}
                            prestart_attendees={sendingData.prestart_attendees}
                            isDisabled={isDisabled}
                            prestart_date={sendingData.prestart_date}
                            onChangeValue={onChangeValue}
                        />
                    ) : undefined}
                    <ProjectDetailCard
                        job_name={sendingData.job_name}
                        onChangeValue={onChangeValue}
                        isDisabled={isDisabled}
                        sub_address_identifier={sendingData.sub_address_identifier}
                        currentRegion={currentRegion}
                        sendingDataRegion={sendingData.region}
                        baseDataRegions={baseData.regions}
                        onNewAddress={onNewAddress}
                        addressData={addressData}
                        onChangeMainAddress={onChangeMainAddress}
                        main_address={main_address}
                        isValidated={isValidated}
                        onEditAddress={onEditAddress}
                        job_notes={sendingData.job_notes}
                        permissions={permissions}
                        type={type}
                        linings_on_job={sendingData.linings_on_job}
                    />
                    {type === "detail" && (
                        <AdminDetailCard
                            difference_signed_off_approved={sendingData.difference_signed_off_approved}
                            difference_emailed_date={sendingData.difference_emailed_date}
                            legacy_job_id={sendingData.legacy_job_id}
                            final_bill_date={sendingData.final_bill_date}
                            onChangeValue={onChangeValue}
                            isDisabled={isDisabled}
                        />
                    )}
                    <InternalContactDetailCard
                        internal_qs={sendingData.internal_qs}
                        onChangeValue={onChangeValue}
                        isDisabled={isDisabled}
                        qsUserData={baseData?.qsUserData || []}
                        internal_supervisor={sendingData.internal_supervisor}
                        internal_surveyor={sendingData.internal_surveyor}
                        supervisorData={baseData?.supervisorData || []}
                        surveyorData={baseData?.surveyorData || []}
                    />
                    {/* <ServiceDetailCard
                        price_type={sendingData.price_type}
                        isValidated={isValidated}
                        job_services={sendingData.job_services}
                        serviceData={baseData.serviceData}
                        onServiceChange={onServiceChange}
                        isDisabled={isDisabled}
                    /> */}
                    <RetentionDetailCard sendingData={sendingData} onChangeValue={onChangeValue} isDisabled={isDisabled} />
                    <FileDetailCard
                        permissions={permissions}
                        items={items}
                        isDisabled={isDisabled}
                        addItem={addItem}
                        fileCategory={baseData.fileCategory}
                        onFileChange={onFileChange}
                    />
                    <QSChecklistCard
                        qsCheckData={baseData.qsCheckData}
                        onChangeValue={onChangeValue}
                        qs_checklist={sendingData.qs_checklist}
                        isDisabled={isDisabled}
                    />
                    {/* {type === "requested" && permissions.job === 4 && !isQS ? (
                        <MDBCard className={styles.card}>
                            <MDBCardBody>
                                <MDBRow>
                                    <MDBCol sm="6">
                                        <MDBBtn block color="primary" onClick={props.requestApprove}>
                                            Approve Job Request
                                        </MDBBtn>
                                    </MDBCol>
                                    <MDBCol sm="6">
                                        <MDBBtn block color="danger" onClick={props.requestReject}>
                                            Reject Job Request
                                        </MDBBtn>
                                    </MDBCol>
                                </MDBRow>
                            </MDBCardBody>
                        </MDBCard>
                    ) : (
                        ""
                    )} */}
                    <MDBRow>
                        <MDBCol>
                            <div className={`${styles.card} float-right`}>
                                <button type="button" ref={forwardRef} className="btn btn-blue-grey" onClick={props.returnToList}>
                                    Cancel
                                </button>{" "}
                                <SubmitArea type={type} pendingLoading={pendingLoading} permissionLevel={permissions.job} />
                            </div>
                        </MDBCol>
                    </MDBRow>
                </form>
            )}
        </div>
    );
};

const SubmitArea = props => {
    const { type, permissionLevel, pendingLoading } = props;
    switch (permissionLevel) {
        case 4:
            return <SubmitButton type={type} pendingLoading={pendingLoading} />;
        case 3:
            if (type === "detail") {
                return "";
            } else {
                return <SubmitButton type={type} pendingLoading={pendingLoading} />;
            }
        default:
            return "";
    }
};

export default JobDetail;
