import React from "react";
import { Button, Card, CardContent, CardHeader, IconButton } from "@mui/material";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import SettingsIcon from "@mui/icons-material/Settings";
import CloseIcon from "@mui/icons-material/Close";

const filtersTheme = createTheme({
    components: {
        MuiCard: {
            styleOverrides: {
                root: { marginBottom: "0.5rem" }
            }
        },
        MuiCardHeader: {
            styleOverrides: {
                title: { fontSize: "1.1rem" },
                root: { background: "rgba(0,0,0,.03)", borderBottom: "1px solid rgba(0,0,0,.125)" }
            }
        }
    }
});

const CogButton = ({ isOpen, toggleOpen }) => {
    return (
        <IconButton color="warning" onClick={toggleOpen}>
            <SettingsIcon
                sx={
                    isOpen
                        ? {
                              animation: "spin 2s linear infinite",
                              "@keyframes spin": {
                                  "0%": {
                                      transform: "rotate(0deg)"
                                  },
                                  "100%": {
                                      transform: "rotate(360deg)"
                                  }
                              }
                          }
                        : {}
                }
            />
        </IconButton>
    );
};

const FiltersContainer = ({ isOpen, hasFilter, title, clearFilters, children }) => {
    return (
        <ThemeProvider theme={filtersTheme}>
            {hasFilter && !isOpen && (
                <div className="mb-4" style={{ marginTop: "-20px" }}>
                    <Button startIcon={<CloseIcon />} size="small" color="secondary" onClick={clearFilters}>
                        Clear filters
                    </Button>
                </div>
            )}
            {isOpen ? (
                <Card variant="outlined" sx={{ marginBottom: "2rem" }}>
                    <CardHeader
                        title={title}
                        action={
                            <Button startIcon={<CloseIcon />} size="small" color="secondary" onClick={clearFilters}>
                                Clear filters
                            </Button>
                        }
                    />
                    <CardContent>{children}</CardContent>
                </Card>
            ) : undefined}
        </ThemeProvider>
    );
};

export { FiltersContainer, CogButton };
