import { axiosInstance } from "shared/axiosInst";
import { getDateRange, getNextWorkingDay } from "shared/Utils";
import dayjs from "shared/dayjs";
import holidays from "shared/holidays.json";

// valiables

// actions

const SET_LEAVE_TYPE = "SET_LEAVE_TYPE";
const SET_DECISION_TYPE = "SET_DECISION_TYPE";
const SET_LEAVE_REQUEST = "SET_LEAVE_REQUEST";
const SET_LEAVE_CALENDAR = "SET_LEAVE_CALENDAR";
const SET_HOLIDAY = "SET_HOLIDAY";
const SET_NEXT_WORKING_DATE = "SET_NEXT_WORKING_DATE";
const SET_CURRENT_WEEK_HOLIDAYS = "SET_CURRENT_WEEK_HOLIDAYS";
const SET_TOTAL_HOLIDAYS = "SET_TOTAL_HOLIDAYS";

// API actions

function getLeaveRequest(id) {
    return async (dispatch, getState) => {
        const {
            user: { currentCompany }
        } = getState();

        try {
            const leaveResponse = await axiosInstance.get(`${currentCompany}hr/leave_request/${id}/`);
            const leaveData = leaveResponse.data;
            dispatch(setLeaveRequest(leaveData));
        } catch (error) {
            console.log(error.response);
        }
    };
}

function getLeaveType(currentEmployee = null) {
    return async (dispatch, getState) => {
        const {
            humanResources: { leaveType },
            user: { direct_report, isAdmin, isSLT }
        } = getState();
        function getFilteredLeaveType(leaveTypeData) {
            // SLT members can see all leave type.
            const filteredType = leaveTypeData.filter(type => (isSLT ? type : type.standard_type));
            // admin, SLT and staffs who has direct report(means managers) can see all leave type if they request it for somebody else.
            if (direct_report?.length > 0 || isAdmin || isSLT) {
                return leaveTypeData;
            } else {
                return filteredType;
            }
        }
        try {
            if (leaveType.length === 0) {
                const leaveTypeResponse = await axiosInstance.get(`common/hr/leave_type/`);
                const leaveTypeData = leaveTypeResponse.data;
                dispatch(setLeaveType(leaveTypeData));
                return getFilteredLeaveType(leaveTypeData);
            } else {
                return getFilteredLeaveType(leaveType);
            }
        } catch (error) {
            console.log(error);
        }
    };
}

function getDecisionType() {
    return async (dispatch, getState) => {
        const {
            humanResources: { decisionType }
        } = getState();

        try {
            if (decisionType.length === 0) {
                const decisionTypeResponse = await axiosInstance.get(`common/hr/decision_type/`);
                const decisionTypeData = decisionTypeResponse.data;
                dispatch(setDecisionType(decisionTypeData));
                return decisionTypeData;
            } else {
                return decisionType;
            }
        } catch (error) {
            console.log(error.response);
        }
    };
}

function getLeaveCalendar(filterData) {
    return async (dispatch, getState) => {
        const {
            user: { currentCompany }
        } = getState();
        const {
            period: { startDate, endDate },
            isAllStaff
        } = filterData;
        try {
            const eventsResponse = await axiosInstance.get(
                `${currentCompany}hr/leave_day/${isAllStaff ? "all_leave_calendar_dates" : "leave_calendar_dates"}/${
                    startDate ? `?start_date=${startDate}` : ""
                }${endDate ? `&end_date=${endDate}` : ""}`
            );
            const events = eventsResponse.data;
            dispatch(setLeaveCalendar(events));
        } catch (error) {
            console.log(error.response);
            dispatch(setLeaveCalendar([]));
        }
    };
}

function getStaffLeaveEvents({ startDate, endDate }) {
    return async (_, getState) => {
        const {
            user: { currentCompany }
        } = getState();
        const start = startDate.format("YYYY-MM-DD");
        const end = endDate.format("YYYY-MM-DD");
        try {
            const staffLeaveEventsResponse = await axiosInstance.get(
                `${currentCompany}hr/leave_day/salary_leave_calendar/?start_date=${start}&end_date=${end}`
            );
            return staffLeaveEventsResponse.data;
        } catch (error) {
            console.log(error.response);
            return [];
        }
    };
}

function getHolidays(filters) {
    return async (dispatch, getState) => {
        const {
            humanResources: { currentWeekHolidays }
        } = getState();
        try {
            const { currentBranch, formattedStartDate, formattedEndDate } = filters;
            const currentDateRange = getDateRange(true);
            const holidayResponse = await axiosInstance.get(
                `common/hr/global_holiday/holidays_by_reporting_area/${currentBranch}/?holiday_date__gte=${formattedStartDate}&holiday_date__lte=${formattedEndDate}`
            );
            const holidays = holidayResponse.data;
            let currentHolidays = [];
            if (currentWeekHolidays) {
                currentHolidays = currentWeekHolidays;
            } else {
                if (currentDateRange.startDate.format("YYYY-MM-DD") === formattedStartDate) {
                    currentHolidays = holidays;
                    dispatch(setCurrentWeekHolidays(currentHolidays));
                } else {
                    const formattedStartDate = currentDateRange.startDate.format("YYYY-MM-DD");
                    const formattedEndDate = currentDateRange.endDate.format("YYYY-MM-DD");
                    const holidayResponse = await axiosInstance.get(
                        `common/hr/global_holiday/holidays_by_reporting_area/${currentBranch}/?holiday_date__gte=${formattedStartDate}&holiday_date__lte=${formattedEndDate}`
                    );
                    currentHolidays = holidayResponse.data;
                    dispatch(setCurrentWeekHolidays(currentHolidays));
                }
                const currentHour = new Date().getHours();
                const TURN_OVER = 10;
                const nextDay = currentHour >= TURN_OVER ? dayjs().add(1, "day") : dayjs();
                const nextWorkingDate = getNextWorkingDay(nextDay, currentHolidays);
                dispatch(setNextWorkingDate(nextWorkingDate));
            }
            dispatch(setHolidays(holidays));
        } catch (error) {
            console.log("error : ", error);
        }
    };
}

function getHolidaysByPeriod(filters) {
    return async (dispatch, getState) => {
        const {
            humanResources: { currentWeekHolidays }
        } = getState();
        try {
            const { currentBranch, formattedStartDate, formattedEndDate } = filters;
            const currentDateRange = getDateRange(true);
            const holidayResponse = await axiosInstance.get(
                `common/hr/global_holiday/holidays_by_reporting_area/${currentBranch}/?holiday_date__gte=${formattedStartDate}&holiday_date__lte=${formattedEndDate}`
            );
            const holidays = holidayResponse.data;
            let currentHolidays = [];
            if (currentWeekHolidays) {
                currentHolidays = currentWeekHolidays;
            } else {
                if (currentDateRange.startDate.format("YYYY-MM-DD") === formattedStartDate) {
                    currentHolidays = holidays;
                    dispatch(setCurrentWeekHolidays(currentHolidays));
                } else {
                    const formattedStartDate = currentDateRange.startDate.format("YYYY-MM-DD");
                    const formattedEndDate = currentDateRange.endDate.format("YYYY-MM-DD");
                    const holidayResponse = await axiosInstance.get(
                        `common/hr/global_holiday/holidays_by_reporting_area/${currentBranch}/?holiday_date__gte=${formattedStartDate}&holiday_date__lte=${formattedEndDate}`
                    );
                    currentHolidays = holidayResponse.data;
                    dispatch(setCurrentWeekHolidays(currentHolidays));
                }
                const currentHour = new Date().getHours();
                const TURN_OVER = 10;
                const nextDay = currentHour >= TURN_OVER ? dayjs().add(1, "day") : dayjs();
                const nextWorkingDate = getNextWorkingDay(nextDay, currentHolidays);
                dispatch(setNextWorkingDate(nextWorkingDate));
            }
            dispatch(setHolidays(holidays));
        } catch (error) {
            console.log("error : ", error);
        }
    };
}

function getTotalHolidayByRegion(region) {
    return async dispatch => {
        try {
            const holidayResponse = await axiosInstance.get(`common/hr/global_holiday/holidays_by_region/${region}/`);
            dispatch(setTotalHolidays(holidayResponse.data));
            return holidayResponse.data;
        } catch (error) {
            console.log(error);
        }
    };
}

// action creators

function setLeaveType(leaveType) {
    return {
        type: SET_LEAVE_TYPE,
        leaveType
    };
}

function setDecisionType(decisionType) {
    return {
        type: SET_DECISION_TYPE,
        decisionType
    };
}

function setLeaveRequest(leaveRequest) {
    return {
        type: SET_LEAVE_REQUEST,
        leaveRequest
    };
}

function setLeaveCalendar(leaveCalendar) {
    return {
        type: SET_LEAVE_CALENDAR,
        leaveCalendar
    };
}

function setHolidays(holidays) {
    return {
        type: SET_HOLIDAY,
        holidays
    };
}

function setNextWorkingDate(nextWorkingDate) {
    return {
        type: SET_NEXT_WORKING_DATE,
        nextWorkingDate
    };
}

function setCurrentWeekHolidays(currentWeekHolidays) {
    return {
        type: SET_CURRENT_WEEK_HOLIDAYS,
        currentWeekHolidays
    };
}

function setTotalHolidays(totalHolidays) {
    return {
        type: SET_TOTAL_HOLIDAYS,
        totalHolidays
    };
}

// initial state
const initialState = {
    leaveType: [],
    decisionType: [],
    leaveRequest: {},
    leaveCalendar: [],
    holidays,
    totalHolidays: []
};
// reducer
function reducer(state = initialState, action) {
    switch (action.type) {
        case SET_LEAVE_TYPE:
            return applyLeaveType(state, action);
        case SET_DECISION_TYPE:
            return applyDecisionType(state, action);
        case SET_LEAVE_REQUEST:
            return applyLeaveRequest(state, action);
        case SET_LEAVE_CALENDAR:
            return applyLeaveCalendar(state, action);
        case SET_HOLIDAY:
            return applyHoliday(state, action);
        case SET_TOTAL_HOLIDAYS:
            return applyTotalHolidays(state, action);
        case SET_NEXT_WORKING_DATE:
            return {
                ...state,
                nextWorkingDate: action.nextWorkingDate
            };
        case SET_CURRENT_WEEK_HOLIDAYS:
            return {
                ...state,
                currentWeekHolidays: action.currentWeekHolidays
            };
        default:
            return state;
    }
}

// reducer function
function applyLeaveType(state, action) {
    const { leaveType } = action;
    return {
        ...state,
        leaveType
    };
}

function applyDecisionType(state, action) {
    const { decisionType } = action;
    return {
        ...state,
        decisionType
    };
}

function applyLeaveRequest(state, action) {
    const { leaveRequest } = action;
    const { leaveCalendar } = state;
    if (leaveCalendar.length > 0) {
        const newLeaveCalendar = leaveCalendar.map(leave => {
            const matchedRequest = leaveRequest.leave_days.find(day => day.id === leave.id);
            if (matchedRequest) {
                return {
                    ...leave,
                    request_state_string: leaveRequest.request_state_string
                };
            } else {
                return leave;
            }
        });
        return {
            ...state,
            leaveRequest,
            leaveCalendar: newLeaveCalendar
        };
    } else {
        return {
            ...state,
            leaveRequest
        };
    }
}

function applyLeaveCalendar(state, action) {
    const { leaveCalendar } = action;
    return {
        ...state,
        leaveCalendar
    };
}

function applyHoliday(state, action) {
    const { holidays } = action;
    return {
        ...state,
        holidays
    };
}

function applyTotalHolidays(state, action) {
    const { totalHolidays } = action;
    return {
        ...state,
        totalHolidays
    };
}

//export
const actionCreators = {
    getLeaveType,
    getDecisionType,
    getLeaveRequest,
    setLeaveRequest,
    getLeaveCalendar,
    getStaffLeaveEvents,
    getHolidays,
    getTotalHolidayByRegion,
    getHolidaysByPeriod
};

export { actionCreators };

export default reducer;
