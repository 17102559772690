import React from "react";
import PropTypes from "prop-types";
import styles from "./styles.scss";
import { MDBInput, MDBBtn } from "louis-mdbreact";
import CircularProgress from "@mui/material/CircularProgress";

const Auth = props => {
    return (
        <div className={`${styles.basicBack} ${styles.liningsBack}`}>
            <div className={`${styles.loginContainer} ${props.isReady ? styles.entered : ""}`}>
                <h1 className={styles.title}>
                    <svg width="100%" height="100%" viewBox="0 0 65 101" version="1.1" xmlns="http://www.w3.org/2000/svg">
                        <path d="M46.178,37.256c-6.074,20.504 -19.56,25.106 -19.56,25.106c-0.774,0.15 -4.801,0.302 -2.386,-8.023c2.415,-8.323 11.256,-14.862 11.256,-14.862c-17.057,10.919 -20.254,22.71 -20.254,22.71c-1.465,3.729 -2.15,7.329 -2.33,10.673c-0.766,-0.185 -7.734,-2.189 -5.175,-15.311c0,0 -21.213,27.613 9.988,40.951c0,0 -0.437,-2.58 0.245,-5.661c0.806,-2.879 2.028,-4.797 3.164,-6.044c0.785,-0.804 1.739,-1.539 2.898,-2.154c-3.745,14.102 1.991,12.647 1.991,12.647c4.651,-1.917 2.744,-13.301 2.744,-13.301c-2.058,-14.927 5.763,-22.998 5.763,-22.998c6.905,-8.166 16.203,-9.765 16.203,-9.765l-3.726,2.537c-18.143,13.536 -9.804,28.514 -9.804,28.514c5.783,13.424 8.653,10.421 8.653,10.421c4.81,-4.461 -3.499,-15.877 -3.499,-15.877c10.782,9.125 9.17,24.015 9.17,24.015l8.434,-14.817c3.428,-6.206 4.282,-11.791 4.282,-11.791c1.569,-11.103 -5.702,-17.715 -5.702,-17.715c0.691,2.195 -0.25,10.069 -0.25,10.069c-1.408,9.481 -5.898,8.321 -5.898,8.321c-1.472,-0.16 -2.396,-2.089 -2.396,-2.089c-2.55,-3.936 0.256,-13.033 0.256,-13.033c2.253,-5.778 3.66,-11.635 3.66,-11.635c3.817,-14.256 -0.808,-24.829 -0.808,-24.829c-5.706,-14.508 -21.297,-23.002 -21.297,-23.002c20.441,21.251 14.378,36.943 14.378,36.943" />
                    </svg>
                    <p>Fire Portal</p>
                </h1>
                <p className={styles.welcome}>{props.welcomeName ? `Welcome back, ${props.welcomeName}!` : `Welcome!!`}</p>
                <form onSubmit={props.handleSubmit} autoComplete="off">
                    <MDBInput
                        label="USERNAME"
                        value={props.usernameValue}
                        onChange={props.handleInputChange}
                        className={styles.input}
                        name="username"
                        type="email"
                        autoComplete="true"
                    />
                    <MDBInput
                        label="PASSWORD"
                        className={styles.input}
                        value={props.passwordValue}
                        onChange={props.handleInputChange}
                        name="password"
                        type="password"
                        autoComplete="true"
                    />
                    <div className={styles.buttonContainer}>
                        <MDBBtn
                            className={props.isShaking ? "animated shake" : ""}
                            onAnimationEnd={props.onAnimationEnd}
                            name="loginBtn"
                            type="submit"
                            color="orange"
                        >
                            {props.isLoading ? <CircularProgress size={19} style={{ color: "white" }} /> : "LOGIN"}
                        </MDBBtn>
                    </div>
                </form>
            </div>
        </div>
    );
};

Auth.propTypes = {
    usernameValue: PropTypes.string.isRequired,
    passwordValue: PropTypes.string.isRequired,
    handleInputChange: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired
};

export default Auth;
