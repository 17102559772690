/* eslint-disable no-restricted-globals */
import React, { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import "react-calendar/dist/Calendar.css";
import App from "components/App";
import "reset-css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "bootstrap-css-only/css/bootstrap.min.css";
import "louis-mdbreact/dist/css/mdb.css";
import store from "redux/configureStore";
import history from "./shared/history";
import tokenFunc from "shared/axiosInst";
import { updateServiceWorker } from "redux/modules/serviceWorker";
import { ConnectedRouter } from "connected-react-router";
import * as serviceWorker from "./serviceWorker";
import { datadogRum } from "@datadog/browser-rum";

tokenFunc(store.dispatch, history);

const onUpdate = registration => {
    store.dispatch(updateServiceWorker(registration));
};

const container = document.getElementById("root");
const root = createRoot(container);
root.render(
    <Provider store={store}>
        <ConnectedRouter history={history}>
            <StrictMode>
                <App />
            </StrictMode>
        </ConnectedRouter>
    </Provider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register({ onUpdate, immediate: false });

const url = location.host;
const isLocalHost = url.search(/localhost/);

if (isLocalHost !== 0) {
    const name = localStorage.getItem("wName");
    const APPLICATION_ID = process.env.REACT_APP_DATADOG_APPLICATION_ID;
    const CLIENT_TOKEN = process.env.REACT_APP_DATADOG_CLIENT_TOKEN;
    const version = process.env.REACT_APP_VERSION;
    const env = process.env.NODE_ENV;
    datadogRum.setUser({ name });
    datadogRum.init({
        applicationId: APPLICATION_ID,
        clientToken: CLIENT_TOKEN,
        site: "datadoghq.com",
        service: "global-fire-portal",
        version,
        sessionSampleRate: 100,
        sessionReplaySampleRate: 90,
        trackUserInteractions: true,
        trackResources: true,
        trackLongTasks: true,
        env,
        trackInteractions: true,
        defaultPrivacyLevel: "allow",
        beforeSend: (event, context) => {
            // collect a RUM resource's response headers
            if (event.type === "resource" && event.resource.type === "fetch") {
                event.context = { ...event.context, responseHeaders: context.response.headers };
            }
        }
    });

    // datadogRum.startSessionReplayRecording();
}
