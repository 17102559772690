import React, { useContext, useEffect, useMemo, useState } from "react";
import { Card, CardContent, Stack, Typography, Box, Badge, IconButton } from "@mui/material";
// import ServiceRoster from "./ServiceRoster";
import { RequestListContext, RosterContext } from "../TaskContext";
import CloseIcon from "@mui/icons-material/Close";
import { useDispatch } from "react-redux";
import { actionCreators as userActions } from "redux/modules/user";
import Message from "./Message";
// import { CustomChip } from "./CustomChip";

const JobCard = ({ list, jobList, removeAddedItem, nextWorkingDateList }) => {
    const dispatch = useDispatch();
    const regions = JSON.parse(localStorage.getItem("regions")) || dispatch(userActions.getUserCommons(true));
    const { setSelectedItem, selectedItem } = useContext(RosterContext);
    const { setMessage } = useContext(RequestListContext);
    const totalCount = useMemo(
        () =>
            list.service_roster?.reduce((total, current) => {
                return current.requested_headcount + total;
            }, 0),
        [list.service_roster]
    );
    const [currentCount, setCurrentCount] = useState(list.assigned_staff_count);

    useEffect(() => {
        if (nextWorkingDateList.length > 0) {
            const filtered = nextWorkingDateList.filter(assignment => assignment.labour_request === list.id);
            setCurrentCount(filtered.length);
        }
    }, [list.id, nextWorkingDateList]);

    const onChipClick = id => {
        if (selectedItem?.id === id) {
            setSelectedItem();
            setMessage("");
        } else {
            setSelectedItem(jobList.find(list => list.id === id));
            setMessage(<Message list={list} nextWorkingDateList={nextWorkingDateList} />);
        }
    };

    return (
        <Card
            key={list.id}
            variant="outlined"
            onClick={event => {
                event.preventDefault();
                onChipClick(list.id);
            }}
            sx={{
                cursor: "pointer",
                border: `${list.selected ? "1px solid rgb(237, 114, 48)" : ""}`,
                background: `${list.selected ? "#e3f2fd" : currentCount >= totalCount ? "#c8e6c9" : ""}`,
                margin: "3px"
            }}
        >
            <CardContent
                sx={{
                    position: "relative",
                    paddingBottom: "16px !important",
                    paddingTop: "",
                    paddingRight: list.job === 1 ? "40px !important" : "16px"
                }}
            >
                {/* {(list.saturday_request || list.late_request || list.unconfirmed_request) && (
                    <>
                        <Stack direction="row" spacing={0.5} sx={{ marginBottom: "4px" }}>
                            {list.saturday_request && <CustomChip color="warning">Saturday</CustomChip>}
                            {list.late_request && <CustomChip color="info">Late</CustomChip>}
                            {list.unconfirmed_request && <CustomChip color="black">Unconfirmed</CustomChip>}
                        </Stack>
                    </>
                )} */}
                {/* {(list.request_message || list.staff_instruction) && (
                    <Badge
                        sx={{ position: "absolute", top: "10px", right: "10px" }}
                        color="warning"
                        variant="dot"
                        anchorOrigin={{
                            vertical: "top",
                            horizontal: "left"
                        }}
                    />
                )} */}
                {list.isAdded && (
                    <Box position="absolute" top="8px" right="10px">
                        <IconButton
                            size="small"
                            onClick={event => {
                                event.stopPropagation();
                                removeAddedItem(list.id);
                                list.selected && onChipClick(list.id);
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                    </Box>
                )}
                <Stack direction="row" spacing={1} marginBottom={0.5} alignItems="center">
                    <Typography sx={{ fontSize: 14, margin: 0 }} color="text.secondary">
                        {list.id}-{regions.find(region => region.id === list.region).region_short_code}
                    </Typography>
                    {/* {list.service_roster?.map(roster => (
                        <ServiceRoster key={roster.id} roster={roster} />
                    ))} */}
                </Stack>

                <Typography variant="body2" paddingRight={2}>
                    {list.job_name}
                </Typography>
                <Badge
                    sx={{ position: "absolute", bottom: "15px", right: "16px" }}
                    color="info"
                    badgeContent={currentCount}
                    anchorOrigin={{
                        vertical: "top",
                        horizontal: "left"
                    }}
                />
            </CardContent>
        </Card>
    );
};

export default JobCard;
