import React, { Component } from "react";
import HealthSafety from "./presenter";
import OperationalFormModal from "components/modals/OperationalFormModal";
import { axiosInstance } from "shared/axiosInst";
import { Helmet } from "react-helmet";

class Container extends Component {
    constructor(props) {
        super(props);
        const isMine = props.isLeadingHand ? true : localStorage.getItem("isMineJob") === "true" || localStorage.getItem("isMineJob") === null;
        const isCurrent = props.isLeadingHand
            ? true
            : localStorage.getItem("isCurrentJob") === "true" || localStorage.getItem("isCurrentJob") === null;

        const normalView = JSON.parse(localStorage.getItem("normalView")) || {};
        if (normalView.healthSafety === undefined) {
            normalView.healthSafety = true;
        }

        this.state = {
            loading: true,
            healthSafetyFilters: JSON.parse(localStorage.getItem("healthSafetyFilters")) || { isOpen: false },
            isMine,
            isCurrent,
            titleName: this.setTitleName(isMine, isCurrent),
            HSAllList: [],
            normalView,
            queue: [],
            formModal: false,
            selectedJob: "",
            searchText: sessionStorage.getItem("healthSafetySearch") || "",
            activePage: sessionStorage.getItem("healthSafetyActivePage") ? Number(sessionStorage.getItem("healthSafetyActivePage")) - 1 : 0
        };

        this.changeList = this.changeList.bind(this);
    }

    componentDidMount() {
        const { getHSAllList, getFilterData } = this.props;
        const { isMine, isCurrent, normalView, healthSafetyFilters } = this.state;
        getFilterData();
        getHSAllList(isMine, isCurrent, normalView.healthSafety, healthSafetyFilters);
        window.scrollTo(0, 0);
    }

    componentDidUpdate(prevProps) {
        const { jobList, HSAllList } = this.props;
        if (jobList !== prevProps.jobList) {
            this.setState({
                loading: false
            });
        }

        if (HSAllList !== prevProps.HSAllList && HSAllList) {
            this.setState({
                loading: false,
                HSAllList
            });
        }
    }

    setTitleName(isMine, isCurrent) {
        const title = `${isMine ? "my " : ""}${isCurrent ? "current " : ""}Health & Safety`;

        return title.toUpperCase();
    }

    changeList(event) {
        const {
            target: { name, checked }
        } = event;
        const { getHSAllList } = this.props;
        localStorage.setItem([`${name}Job`], checked);
        this.setState(
            {
                [name]: checked,
                loading: true
            },
            () => {
                const { isMine, isCurrent, normalView } = this.state;
                getHSAllList(isMine, isCurrent, normalView.healthSafety);
                this.setState({
                    titleName: this.setTitleName(isMine, isCurrent)
                });
            }
        );
    }

    toggleForm = event => {
        this.setState({
            formModal: !this.state.formModal,
            selectedJob: event && event.currentTarget.value
        });
    };

    toggleChangeView = e => {
        const { normalView, isMine, isCurrent } = this.state;
        const { getHSAllList } = this.props;
        this.setState(
            {
                normalView: { ...normalView, healthSafety: !normalView.healthSafety },
                loading: true
            },
            () => {
                const { normalView, healthSafetyFilters } = this.state;
                localStorage.setItem("normalView", JSON.stringify(normalView));
                getHSAllList(isMine, isCurrent, normalView.healthSafety, healthSafetyFilters);
            }
        );
    };

    toggleFilters = () => {
        const { healthSafetyFilters } = this.state;
        this.setState(
            {
                healthSafetyFilters: { ...healthSafetyFilters, isOpen: !healthSafetyFilters.isOpen }
            },
            () => {
                const { healthSafetyFilters } = this.state;
                localStorage.setItem("healthSafetyFilters", JSON.stringify(healthSafetyFilters));
            }
        );
    };

    onChangeSetting = event => {
        const { name, value } = event.target;
        const { healthSafetyFilters } = this.state;
        this.setState(
            {
                healthSafetyFilters: { ...healthSafetyFilters, [name]: value },
                loading: true
            },
            () => {
                this.setFilter();
            }
        );
    };

    onHSChangeFilter = ({ name, option }) => {
        const { healthSafetyFilters } = this.state;
        this.setState(
            {
                healthSafetyFilters: { ...healthSafetyFilters, [name]: option?.id },
                loading: true
            },
            () => {
                this.setFilter();
            }
        );
    };

    clearFilters = () => {
        const { healthSafetyFilters } = this.state;
        this.setState(
            {
                healthSafetyFilters: { isOpen: healthSafetyFilters.isOpen },
                loading: true
            },
            () => {
                this.setFilter();
            }
        );
    };

    setFilter = () => {
        const { healthSafetyFilters, isMine, isCurrent, normalView } = this.state;
        const { getHSAllList } = this.props;
        localStorage.setItem("healthSafetyFilters", JSON.stringify(healthSafetyFilters));
        getHSAllList(isMine, isCurrent, normalView.healthSafety, healthSafetyFilters);
    };

    signal = axiosInstance.CancelToken.source();

    downloadOperationalForm = e => {
        e.preventDefault();
        const {
            currentTarget: { value }
        } = e;
        const { downloadOperationalForm } = this.props;
        const { HSAllList, queue } = this.state;
        queue.push(Number(value));
        this.setState(
            {
                HSAllList: HSAllList.map(list => ({
                    ...list,
                    pending: queue.find(que => que === list.id)
                }))
            },
            () => {
                downloadOperationalForm([Number(value)], this.signal)
                    .then(value => {
                        if (value) {
                            if (value.message === "completed") {
                                queue.shift();
                                this.setState({
                                    HSAllList: HSAllList.map(list => ({
                                        ...list,
                                        pending: queue.find(que => que === list.id)
                                    }))
                                });
                            }
                        }
                    })
                    .catch(error => alert(error));
            }
        );
    };

    onSearch = searchText => {
        this.setState({
            searchText
        });
        sessionStorage.setItem("healthSafetySearch", searchText);
    };

    onPageChange = pageInfo => {
        this.setState({
            activePage: pageInfo.activePage
        });
        sessionStorage.setItem("healthSafetyActivePage", pageInfo.activePage);
    };

    componentWillUnmount() {
        this.setState({
            formModal: false
        });
        const { removeQAAllList } = this.props;
        this.signal.cancel("Downloading(s) is cancelled by user.");
        removeQAAllList();
    }

    render() {
        const { jobList, permissions, userId, formType, filterData } = this.props;
        const { selectedJob, formModal } = this.state;
        return (
            <>
                <Helmet>
                    <title>Operations | Health & Safety</title>
                </Helmet>
                <HealthSafety
                    {...this.state}
                    jobList={jobList}
                    userId={userId}
                    permissions={permissions}
                    formType={formType}
                    filterData={filterData}
                    toggleForm={this.toggleForm}
                    changeList={this.changeList}
                    toggleChangeView={this.toggleChangeView}
                    downloadOperationalForm={this.downloadOperationalForm}
                    onSearch={this.onSearch}
                    onPageChange={this.onPageChange}
                    toggleFilters={this.toggleFilters}
                    onChangeSetting={this.onChangeSetting}
                    onHSChangeFilter={this.onHSChangeFilter}
                    clearFilters={this.clearFilters}
                />
                <OperationalFormModal formModal={formModal} isPre={true} selectedJob={selectedJob} toggleForm={this.toggleForm} />
            </>
        );
    }
}

export default Container;
