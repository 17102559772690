import React, { useEffect, useState } from "react";
import styles from "shared/details.scss";
import { MDBCard, MDBCardHeader, MDBCardBody, MDBBtn, MDBRow, MDBCol } from "louis-mdbreact";
import { Checkbox, Chip } from "@mui/material";
import ValidSelect from "components/ValidSelect";

const ProjectDetailCard = props => {
    const {
        job_name,
        onChangeValue,
        isDisabled,
        sub_address_identifier,
        currentRegion,
        sendingDataRegion,
        baseDataRegions,
        onNewAddress,
        addressData,
        onChangeMainAddress,
        main_address,
        isValidated,
        onEditAddress,
        job_notes,
        linings_on_job,
        permissions,
        type
    } = props;

    useEffect(() => {
        let isShowBtn = false;
        switch (type) {
            case "new":
                isShowBtn = false;
                break;
            case "detail":
                isShowBtn = permissions.job === 4 && main_address.value !== 0 ? true : false;
                break;
            case "requested":
                isShowBtn = permissions.job >= 3 && main_address.value !== 0 ? true : false;
                break;
            default:
                isShowBtn = false;
        }
        setShowBtn(isShowBtn);
    }, [type, permissions, main_address]);

    const [isShowBtn, setShowBtn] = useState(false);
    return (
        <MDBCard className={styles.card}>
            <MDBCardHeader>Project Details</MDBCardHeader>
            <MDBCardBody>
                <MDBRow>
                    <MDBCol sm="6" className="mb-2">
                        <label htmlFor="job_name">Project Title</label>
                        <input
                            type="text"
                            className="form-control"
                            id="job_name"
                            name="job_name"
                            value={job_name}
                            onChange={onChangeValue}
                            disabled={!isDisabled}
                        />
                    </MDBCol>
                    <MDBCol sm="3" className="mb-2">
                        <label htmlFor="sub_address_identifier">Unit / Lot Number</label>
                        <input
                            type="text"
                            className="form-control"
                            id="sub_address_identifier"
                            name="sub_address_identifier"
                            value={sub_address_identifier}
                            onChange={onChangeValue}
                            disabled={!isDisabled}
                        />
                    </MDBCol>
                    {currentRegion === "Auckland" && (
                        <MDBCol sm="3" className="mb-2">
                            <label htmlFor="region">Region</label>
                            <select className="browser-default custom-select" name="region" value={sendingDataRegion} onChange={onChangeValue}>
                                {baseDataRegions?.map(reg => (
                                    <option key={reg.id} value={reg.id}>
                                        {reg.region_name} ({reg.region_short_code})
                                    </option>
                                ))}
                            </select>
                        </MDBCol>
                    )}
                </MDBRow>
                <MDBRow>
                    <MDBCol sm="12">
                        <label htmlFor="main_adress">Address</label>{" "}
                        <Chip color="success" size="small" clickable aria-label="new address" onClick={onNewAddress} label="New Address" />
                        <MDBRow>
                            <MDBCol className={`${styles.withBtn} ${!isShowBtn ? styles.withoutBtn : null}`}>
                                <ValidSelect
                                    options={addressData}
                                    onChange={onChangeMainAddress}
                                    value={main_address}
                                    isValid={!isValidated || main_address ? true : false}
                                    isDisabled={isDisabled}
                                    name="main_adress"
                                />
                                {isShowBtn ? (
                                    <MDBBtn className={styles.fitBtn} color="primary" onClick={onEditAddress}>
                                        Edit
                                    </MDBBtn>
                                ) : null}
                            </MDBCol>
                        </MDBRow>
                    </MDBCol>
                </MDBRow>
                {/* {isEditAddress && (
                                <>
                                    <MDBRow className={styles.formRow}>
                                        <MDBCol sm="4">
                                            <label htmlFor="street_no">Street No.</label>
                                            <input
                                                type="number"
                                                inputMode="numeric"
                                                className="form-control"
                                                id="street_no"
                                                name="number_addr_"
                                                value={sendingData.main_address_full.number}
                                                onChange={onChangeValue}
                                                disabled={!isDisabled}
                                            />
                                        </MDBCol>
                                        <MDBCol sm="8">
                                            <label htmlFor="road_name">Street</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="road_name"
                                                name="road_name_addr_"
                                                value={sendingData.main_address_full.road_name}
                                                onChange={onChangeValue}
                                                disabled={!isDisabled}
                                                required
                                            />
                                            <div className="invalid-feedback">Required</div>
                                        </MDBCol>
                                    </MDBRow>
                                    <MDBRow className={styles.formRow}>
                                        <MDBCol sm="6">
                                            <label htmlFor="suburb">Suburb</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="suburb"
                                                name="suburb_addr_"
                                                value={sendingData.main_address_full.suburb}
                                                onChange={onChangeValue}
                                                disabled={!isDisabled}
                                            />
                                        </MDBCol>
                                        <MDBCol sm="6">
                                            <label htmlFor="town_city">City</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="town_city"
                                                name="town_city_addr_"
                                                value={sendingData.main_address_full.town_city}
                                                onChange={onChangeValue}
                                                disabled={!isDisabled}
                                            />
                                        </MDBCol>
                                    </MDBRow>
                                    <MDBRow className={styles.formRow}>
                                        <MDBCol sm="6">
                                            <label htmlFor="post_code">Postal Code</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="post_code"
                                                name="post_code_addr_"
                                                value={sendingData.main_address_full.post_code}
                                                onChange={onChangeValue}
                                                disabled={!isDisabled}
                                            />
                                        </MDBCol>
                                        <MDBCol sm="6">
                                            <label htmlFor="country">Country</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="country"
                                                name="country_addr_"
                                                value={sendingData.main_address_full.country}
                                                onChange={onChangeValue}
                                                disabled={!isDisabled}
                                                required
                                            />
                                            <div className="invalid-feedback">Required</div>
                                        </MDBCol>
                                    </MDBRow>
                                </>
                            )} */}
                <MDBRow className={styles.formRow}>
                    <MDBCol>
                        <label htmlFor="job_notes">Notes</label>
                        <div>
                            <textarea
                                onChange={onChangeValue}
                                value={job_notes}
                                name="job_notes"
                                className="form-control"
                                id="job_notes"
                                rows="5"
                                disabled={!isDisabled}
                            />
                        </div>
                    </MDBCol>
                </MDBRow>
                <MDBRow className={styles.formRow}>
                    <MDBCol>
                        <label htmlFor="linings_on_job">
                            <Checkbox
                                name="linings_on_job"
                                id="linings_on_job"
                                onChange={onChangeValue}
                                checked={linings_on_job}
                                disabled={!isDisabled}
                            />{" "}
                            Linings on job
                        </label>
                    </MDBCol>
                </MDBRow>
            </MDBCardBody>
        </MDBCard>
    );
};

export default ProjectDetailCard;
