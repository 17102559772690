import { connect } from "react-redux";
import Container from "./container";
import { actionCreators as userActions } from "redux/modules/user";

const mapStateToProps = (state, ownProps) => {
    const {
        user: { companies, currentCompany }
    } = state;
    const { isShow } = ownProps;
    const companyList = JSON.parse(companies)
        .map(company => {
            if (company.company_string) {
                const value = company.company_string.split(" ")[1].toLowerCase();
                return {
                    id: company.id,
                    label: company.region_string,
                    value,
                    prefix: company.company_prefix,
                    brand: company.brand
                };
            } else {
                return {};
            }
        })
        .filter(company => company.brand === 2);
    return {
        companyList,
        currentCompany,
        isShow
    };
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        switchCompany: company => {
            dispatch(userActions.switchCompany(company));
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Container);
