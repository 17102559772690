import React, { Component } from "react";
import SwitchCompany from "./presenter";

class Container extends Component {
    constructor(props) {
        super(props);
        if (props.currentCompany === "/") {
            this.state = { companyValue: "company-tmpl" };
        } else {
            this.state = { companyValue: props.currentCompany.split("/")[0] };
        }

        this.onChange = this.onChange.bind(this);
    }

    onChange(event) {
        const index = event.target.selectedIndex;
        const options = event.target.childNodes[index];
        const companyName = event.target.options[index].text;
        const companyValue = event.target.value;
        const prefix = options.getAttribute("prefix");
        this.setState({
            companyValue
        });
        this.props.switchCompany({ companyValue, companyName, prefix });
    }

    render() {
        const { companyList, isShow } = this.props;
        const { companyValue } = this.state;
        return (
            <SwitchCompany
                companyList={companyList}
                onChange={this.onChange}
                companyValue={companyValue}
                isShow={isShow}
            />
        );
    }
}

export default Container;
