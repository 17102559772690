import React, { useEffect, useContext, useState } from "react";
import { Grid, Snackbar, Typography, IconButton, useMediaQuery } from "@mui/material";
import { useSelector } from "react-redux";
import { RosterContext } from "../TaskContext";
import JobCard from "./JobCard";
import { Virtuoso } from "react-virtuoso";
import { RequestListContext } from "../TaskContext";
import CloseIcon from "@mui/icons-material/Close";
import JobSelction from "./JobSelection";

import { useTheme } from "@mui/styles";

const RequestedItemList = ({ nextWorkingDateList }) => {
    const { selectedItem, addedJobList, setRequestedJobList, requestedJobList, setAddedJobList } = useContext(RosterContext);
    const {
        tasks: { rosterList }
    } = useSelector(state => state);
    const theme = useTheme();
    const match = useMediaQuery(theme.breakpoints.up("md"));
    const [totalList, setTotalList] = useState([]);
    const [message, setMessage] = useState("");

    useEffect(() => {
        if (rosterList?.length > 0) {
            setRequestedJobList(rosterList);
        } else {
            setRequestedJobList([]);
        }
    }, [rosterList, setRequestedJobList]);

    useEffect(() => {
        setTotalList([...addedJobList, ...requestedJobList]);
    }, [addedJobList, requestedJobList]);

    useEffect(() => {
        setRequestedJobList(current =>
            current.map(list => (list.id === selectedItem?.id ? { ...list, selected: !list.selected } : { ...list, selected: false }))
        );
        setAddedJobList(current =>
            current.map(list => (list.id === selectedItem?.id ? { ...list, selected: !list.selected } : { ...list, selected: false }))
        );
    }, [selectedItem, setRequestedJobList, setAddedJobList]);

    const removeAddedItem = id => {
        setAddedJobList(addedJobList.filter(list => list.id !== id));
    };

    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        setMessage("");
    };

    return (
        <RequestListContext.Provider value={{ setMessage }}>
            <Snackbar
                open={!!message}
                anchorOrigin={{ vertical: "top", horizontal: "left" }}
                onClose={handleClose}
                message={message}
                sx={{ maxWidth: 500 }}
                transitionDuration={{ appear: 0, exit: 0 }}
                action={
                    <IconButton
                        sx={{ position: "absolute", top: "5px", right: "5px" }}
                        size="small"
                        aria-label="close"
                        color="inherit"
                        onClick={handleClose}
                    >
                        <CloseIcon fontSize="small" />
                    </IconButton>
                }
            />
            <Grid sx={{ zIndex: 3 }} item xs={12} sm={12} md={12} lg={12}>
                <Typography variant="subtitle1" color="text.secondary" gutterBottom>
                    Jobs
                </Typography>

                {match ? (
                    <Virtuoso
                        style={{ width: "100%", height: "calc(100vh - 280px)" }}
                        data={totalList}
                        itemContent={(index, list) => (
                            <JobCard
                                key={index}
                                list={list}
                                jobList={totalList}
                                removeAddedItem={removeAddedItem}
                                nextWorkingDateList={nextWorkingDateList}
                            />
                        )}
                    />
                ) : (
                    <JobSelction jobList={totalList} removeAddedItem={removeAddedItem} />
                    // <Select options={totalList} getOptionLabel={option => option.job_name} getOptionValue={option => option.id} />
                )}
            </Grid>
        </RequestListContext.Provider>
    );
};

export default RequestedItemList;
