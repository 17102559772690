import React, { useState, memo } from "react";
import { useSelector } from "react-redux";
import TableCell from "@mui/material/TableCell";
import dayjs from "shared/dayjs";
import theme from "../LeaveItem/ColorCode";
import { Button, Tooltip } from "@mui/material";
import LeaveItemDialog from "../LeaveItem/LeaveItemDialog";
// import { flatten } from "shared/Utils";

const weekendsColor = "#f0f0f0";
const todayColor = "#e3f2fd";

const AdminCalendarTile = ({ date, events, userId }) => {
    const {
        humanResources: { totalHolidays }
        // user: { direct_report, isSLT, userId: currentUserId }
    } = useSelector(state => state);
    // const currentUsers = flatten([], direct_report, "direct_report");
    const [open, setOpen] = useState(false);
    const [leaveId, setLeaveId] = useState("");
    let colorCode = "";
    if (dayjs(date.full).isToday()) {
        colorCode = todayColor;
    } else if (dayjs(date.full).day() === 0 || dayjs(date.full).day() === 6) {
        colorCode = weekendsColor;
    } else if (totalHolidays.find(holiday => holiday.holiday_date === date.full)) {
        colorCode = theme.palette["Public Holiday"].main;
    }
    const matchedEvents = events?.filter(event => event.leave_date === date.full && event.request_state <= 3);
    const onEventClick = event => {
        const {
            currentTarget: { value }
        } = event;
        setLeaveId(value);
        setOpen(true);
        // if (isSLT || currentUsers.find(currentUser => currentUser.id === userId) || currentUserId === userId) {
        //     setOpen(true);
        // }
    };
    const handleClose = event => {
        setOpen(false);
    };
    return (
        <>
            <TableCell
                align="center"
                sx={{
                    borderRight: "1px solid rgba(224, 224, 224, 1)",
                    padding: "0px",
                    background: colorCode,
                    lineHeight: 1
                }}
            >
                {matchedEvents?.map(matched => (
                    <Tooltip key={matched.id} disableInteractive title={`${matched.leave_type_string}/${matched.request_state_string}`}>
                        <Button
                            color={matched.leave_type_string}
                            variant="contained"
                            disableElevation
                            sx={{
                                minWidth: "100%",
                                width: "100%",
                                padding: matched.proportion_of_day > 0.5 ? "16px 4px" : "8px 4px",
                                borderRadius: 0
                            }}
                            value={matched.id}
                            onClick={onEventClick}
                        ></Button>
                    </Tooltip>
                ))}
            </TableCell>
            {open ? <LeaveItemDialog open={open} handleClose={handleClose} index={leaveId} /> : ""}
        </>
    );
};

export default memo(AdminCalendarTile);
