import React from "react";
import styles from "shared/headNavStyles.scss";
import Navigation from "components/Navigation";
import MainNavigation from "components/MainNavigation";
import dots from "images/3x3dot.svg";
import AvatarMenu from "components/AvatarMenu";
import Indicators from "components/Indicators";

const Header = props => {
    const version = process.env.REACT_APP_VERSION;
    const {
        permissions,
        isOpen,
        onTransitionEnd,
        isShow,
        pathname,
        toggleNavbar,
        toggleMainNav,
        timeToggle,
        isSLT,
        isAdmin,
        logout,
        isMobile,
        menuList
    } = props;
    const isNotDashboard = pathname.split("/")[1] !== "user" && pathname.split("/")[1] !== "";
    const isDashboardMobile = !isNotDashboard && isMobile;

    return (
        <div className={styles.headerContainer}>
            <div className={`${styles.header} ${styles.headerLinings} ${isOpen && styles.entered}`} onTransitionEnd={onTransitionEnd} name="header">
                <h1 className={styles.pageTitle}>
                    <span className={styles.desktop}>
                        <span className={styles.portal}>Fire</span> Portal <span className={styles.versionNumber}>(v{version})</span>
                    </span>
                    <AvatarMenu toggleNavbar={toggleNavbar} isAvatar />
                    <span className={styles.mobile}>
                        <span>{isNotDashboard ? menuList.find(menu => pathname.includes(menu.link))?.name.toUpperCase() || "JOBS" : "USER"} </span>
                        <br />
                        <span className={styles.versionNumber}>(v{version})</span>
                    </span>
                </h1>
                <Indicators />
                <div className={`${styles.appsMenu} ${isDashboardMobile ? styles.isOpen : ""}`}>
                    <button onClick={isDashboardMobile ? toggleNavbar : toggleMainNav}>
                        {!isDashboardMobile && <img src={dots} alt="apps menu" />} Apps
                    </button>
                </div>

                <div className={`${styles.hamMenu} ${isOpen && styles.opened}`}>
                    <button onClick={toggleNavbar}>
                        <span></span>
                        <span></span>
                        <span></span>
                    </button>
                </div>
            </div>
            <MainNavigation isShowApps={props.isShowApps} toggleMainNav={toggleMainNav} toggleNavbar={toggleNavbar} logout={logout} />
            {pathname.split("/")[1] !== "reports" && pathname.split("/")[1] !== "user" && pathname.split("/")[1] !== "" ? (
                <Navigation
                    isSLT={isSLT}
                    isAdmin={isAdmin}
                    toggleNavbar={toggleNavbar}
                    isShow={isShow}
                    pathname={pathname}
                    permissions={permissions}
                    timeToggle={timeToggle}
                />
            ) : (
                ""
            )}
        </div>
    );
};

export default Header;
