// imports
import { axiosInstance } from "shared/axiosInst";
import { push } from "react-router-redux";
import _ from "lodash";
import { downloadFile, getFileData, refineTimeEntryToSlim } from "shared/Utils";
import { actionCreators as crmActions } from "redux/modules/crm";
import { actionCreators as jobActions } from "redux/modules/jobs";
import pLimit from "p-limit";
import { limitRequest } from "shared/Utils";
import dayjs from "shared/dayjs";

// variables

// actions

const SLIM_MAINTENANCE_JOB = "SLIM_MAINTENANCE_JOB";
const MAINTENANCE_REQUESTED = "MAINTENANCE_REQUESTED";
const GET_NEW_TASK = "GET_NEW_TASK";
const SET_TIMESHEET_INFO = "SET_TIMESHEET_INFO";
const SET_DETAIL_MAINTENANCE_JOB = "SET_DETAIL_MAINTENANCE_JOB";
const SET_WORKERS_LOGGED_HOURS = "SET_WORKERS_LOGGED_HOURS";
const SET_SUMMARY_SHEET = "SET_SUMMARY_SHEET";
const SET_NEW_SUMMARY_SHEET = "SET_NEW_SUMMARY_SHEET";
const ADD_SUMMARYSHEET = "ADD_SUMMARYSHEET";
const SET_DAYWORK_DATE_RANGE = "SET_DAYWORK_DATE_RANGE";
const SET_TASKS_DAYWORKS = "SET_TASKS_DAYWORKS";
const SET_DAYWORK_DETAIL = "SET_DAYWORK_DETAIL";
const SET_TASK_DETAIL_DAYWORK = "SET_TASK_DETAIL_DAYWORK";
const REMOVE_TASK_DETAIL = "REMOVE_TASK_DETAIL";
const SET_TIMESHEET_WORKERS = "SET_TIMESHEET_WORKERS";
const SET_TIMESHEET_LIST = "SET_TIMESHEET_LIST";
const REMOVE_TIMESHEET_LIST = "REMOVE_TIMESHEET_LIST";
const SET_WORK_COMPLETED = "SET_WORK_COMPLETED";
const SET_DATA_TASK_FILTER = "SET_DATA_TASK_FILTER";
const SET_ROSTER_LIST = "SET_ROSTER_LIST";
const SET_BILLING_STATE = "SET_BILLING_STATE";
const SET_REJECT_REASON = "SET_REJECT_REASON";
const SET_TIMECLOULD_LIST = "SET_TIMECLOUD_LIST";
const SET_TEAM_TIMECLOUD_LIST = "SET_TEAM_TIMECLOUD_LIST";
const SET_TIMECLOUD_ACTION = "SET_TIMECLOUD_ACTION";
const REMOVE_TIMECLOUD_LIST = "REMOVE_TIMECLOUD_LIST";
const SET_TRAVEL_TIME_LIST = "SET_TRAVEL_TIME_LIST";
const SET_TEAM_TRAVEL_TIME_LIST = "SET_TEAM_TRAVEL_TIME_LIST";
const UPDATE_TIMEENTRY_LIST = "UPDATE_TIMEENTRY_LIST";

// API actions
function getSlimMaintenanceJob(isMine, isCurrent, adminSetting = {}) {
    return async (dispatch, getState) => {
        const {
            user: { currentCompany, permissions }
        } = getState();
        const { unbilled, contra, workCompleted, pieceRate } = adminSetting;
        const filter = Object.keys(adminSetting).reduce((total, current) => {
            if (
                current === "job" ||
                current === "service" ||
                current === "dayworks" ||
                current === "status" ||
                current === "cost_type" ||
                current === "job__internal_supervisor" ||
                current === "job__internal_qs" ||
                current === "billing_state"
            ) {
                if (adminSetting[current] !== false && adminSetting[current] !== "") {
                    total += `${total === "" ? "?" : "&"}${current}=${adminSetting[current]}`;
                }
            }
            return total;
        }, "");
        let url = "";
        switch (isMine) {
            case true:
                url = isCurrent
                    ? `${currentCompany}maintenance/slim_maintenance_job/my_current/${filter}`
                    : `${currentCompany}maintenance/slim_maintenance_job/my/${filter}`;
                break;
            case false:
                url = isCurrent
                    ? `${currentCompany}maintenance/slim_maintenance_job/current/${filter}`
                    : `${currentCompany}maintenance/slim_maintenance_job/${filter}`;
                break;
            default:
                url = `${currentCompany}maintenance/slim_maintenance_job/${filter}`;
        }

        const response = await axiosInstance.get(url);
        const data = await response.data;
        const isMemberCanSeeFilters = permissions.maintenance >= 2;
        let filteredData;
        if (isMemberCanSeeFilters) {
            filteredData = data
                .filter(job => (unbilled ? job.unbilled_logged_hours : job))
                .filter(job => (contra ? job.unexplained_contra_hours : job))
                .filter(job => (workCompleted ? job.works_complete : job))
                .filter(job => (pieceRate ? job.piece_rates : job));
        } else {
            filteredData = data;
        }
        dispatch(slimMaintenanceJob(filteredData));
    };
}

function getMaintenanceRequested(isMine) {
    return (dispatch, getState) => {
        const {
            user: { currentCompany }
        } = getState();
        const url = isMine
            ? `${currentCompany}maintenance/slim_maintenance_request/my/`
            : `${currentCompany}maintenance/slim_maintenance_request/pending/`;
        axiosInstance.get(url).then(response => {
            dispatch(maintenanceRequested(response.data));
        });
    };
}

function getMaintenanceService() {
    return async (_, getState) => {
        const {
            user: { currentCompany },
            tasks: { newTaskData }
        } = getState();
        if (newTaskData?.services) {
            return newTaskData.services;
        } else {
            const serviceResponse = await axiosInstance.get(`${currentCompany}maintenance/maintenance_services/`);
            return serviceResponse.data;
        }
    };
}

function getNewTask() {
    return async (dispatch, getState) => {
        const {
            user: { currentCompany }
        } = getState();
        const urls = [
            `${currentCompany}maintenance/status/`,
            `${currentCompany}maintenance/cost_type/`,
            `${currentCompany}job/slim_job/skinny/`,
            `users/uploaded_file_category/`,
            `${currentCompany}maintenance/labour_type/`,
            `common/maintenance/billing_state_option/`
        ];

        let services = [];
        if (currentCompany === "fire-akl/") {
            services = await dispatch(getMaintenanceService());
        }
        await Promise.all(urls.map(url => axiosInstance.get(url))).then(values => {
            const [statusResponse, costTypeResponse, jobsResponse, fileCategoryResponse, labourTypeResponse, billingStateResponse] = values;
            dispatch(
                newTaskModal({
                    jobs: jobsResponse.data.map(job => ({
                        value: job.id,
                        label: `${job.id}: ${job.job_name} ${job.job_name ? "- " : ""}${job.client_string || job.resource_string}`
                    })),
                    status: statusResponse.data,
                    costType: costTypeResponse.data,
                    fileCategory: fileCategoryResponse.data,
                    labourType: labourTypeResponse.data,
                    services,
                    billingState: billingStateResponse.data.filter(state => state.current)
                })
            );
        });
    };
}

function getDataTaskFilter() {
    return async (dispatch, getState) => {
        const {
            user: { currentCompany }
        } = getState();

        const urls = [
            `${currentCompany}maintenance/status/`,
            `${currentCompany}maintenance/cost_type/`,
            `users/supervisor_users/linings/`,
            `users/qs_users/linings/`,
            `common/maintenance/billing_state_option/`
        ];

        let services = [];
        if (currentCompany === "fire-akl/") {
            services = await dispatch(getMaintenanceService());
        }
        const jobsList = await dispatch(jobActions.getSlimJobs(false, true));

        await Promise.all(urls.map(url => axiosInstance.get(url))).then(values => {
            const [statusResponse, costTypeResponse, supervisorResponse, qsResponse, billingStateResponse] = values;

            dispatch(
                setDataTaskFilter({
                    status: statusResponse.data,
                    costType: costTypeResponse.data,
                    supervisors: supervisorResponse.data,
                    qs: qsResponse.data,
                    billingState: billingStateResponse.data,
                    services,
                    jobsList
                })
            );
        });
    };
}

function postNewTask(data, newFiles = [], mjsId = 0, dataType, uploadProgress) {
    return async (dispatch, getState) => {
        const {
            user: { currentCompany }
        } = getState();
        let response;
        try {
            if (mjsId !== 0) {
                const dataUrl = dataType === "detail" ? "job" : "request";
                response = await axiosInstance.put(`${currentCompany}maintenance/maintenance_${dataUrl}/${mjsId}/`, data);
            } else {
                response = await axiosInstance.post(`${currentCompany}maintenance/maintenance_job/`, data);
            }

            const responseTaskData = await response.data;
            const limit = pLimit(limitRequest);
            if (newFiles.length > 0) {
                const totalFileSize = newFiles.reduce((total, file) => total + file.new_file.size, 0);
                let currentLoadedSize = 0;
                let mjsType;
                if (dataType === "new") {
                    //if this is new task, it depands permission level which is if permission level is 4, it goes to approved otherwise request
                    mjsType = "approved_mjs";
                } else {
                    // if this isn't new task, it depands which page came from which are detail and request.
                    mjsType = dataType === "detail" ? "approved_mjs" : "request_mjs";
                }

                const fileRes = await Promise.allSettled(
                    newFiles.map(file =>
                        limit(() =>
                            axiosInstance.post(`${currentCompany}maintenance/stored_file/`, getFileData(file, mjsType, responseTaskData.id), {
                                onUploadProgress: progressData => {
                                    const { total, loaded } = progressData;
                                    if (total === loaded) {
                                        currentLoadedSize += total;
                                    }
                                    uploadProgress && uploadProgress(Math.round((currentLoadedSize / totalFileSize) * 100));
                                }
                            })
                        )
                    )
                );
                const isRejected = fileRes.some(result => result.status === "rejected");
                if (isRejected) {
                    alert("Some files went wrong. You may want to check the files you trying to upload in the MJS just created or edited.");
                }
            }

            if (data.file && data.file.length > 0) {
                await Promise.all(
                    data.file.map(file =>
                        limit(() =>
                            axiosInstance.put(`${currentCompany}maintenance/stored_file/${file.id}/`, {
                                id: file.id,
                                file_category: file.file_category
                            })
                        )
                    )
                );
            }

            if (responseTaskData.id) {
                switch (dataType) {
                    case "detail":
                        dispatch(push("/tasks/"));
                        break;
                    case "new":
                        dispatch(push("/tasks/"));
                        break;
                    case "requested":
                        dispatch(push("/tasks/requested"));
                        break;
                    default:
                        dispatch(push("/tasks/"));
                        break;
                }
            }
        } catch (error) {
            return error;
        }
    };
}

function uploadFile(file, mjsId) {
    return async (dispatch, getState) => {
        const {
            user: { currentCompany },
            tasks: { detailData }
        } = getState();
        try {
            const fd = new FormData();
            const fileName = file.new_file.name;
            const extention = fileName.split(".").slice(-1)[0];
            const limitLength = 100;
            if (fileName.length > limitLength) {
                const newFileName = fileName.slice(0, limitLength - 5);
                file.new_file = new File([file.new_file], `${newFileName}.${extention}`, {
                    type: file.new_file.type
                });
            }
            fd.append("new_file", file.new_file);
            fd.append("file_category", file.file_category);
            fd.append("approved_mjs", mjsId);
            const uploadedFile = await axiosInstance.post(`${currentCompany}maintenance/stored_file/`, fd);
            if (detailData.id === mjsId) {
                const { file } = detailData;
                file.unshift(uploadedFile.data);
                dispatch(setDetailMaintenanceJob({ ...detailData, file }));
                return { message: "finished" };
            }
        } catch (error) {
            alert("Something went wrong. Please try again later");
        }
    };
}

function approveTaskRequest(requestData, items = []) {
    return async (dispatch, getState) => {
        const {
            user: { currentCompany }
        } = getState();
        const response = await axiosInstance.post(`${currentCompany}maintenance/maintenance_job/`, requestData);
        const responseData = await response.data;
        requestData.file.forEach(async file => {
            const { id } = file;
            file.approved_mjs = responseData.id;
            await axiosInstance.put(`${currentCompany}maintenance/stored_file/${id}/`, {
                ...file
            });
        });
        if (items.length > 0) {
            items.forEach(async file => {
                const fd = new FormData();
                const fileName = file.new_file.name;
                const extention = fileName.split(".").slice(-1)[0];
                const limitLength = 100;
                if (fileName.length > limitLength) {
                    const newFileName = fileName.slice(0, limitLength - 5);
                    file.new_file = new File([file.new_file], `${newFileName}.${extention}`, {
                        type: file.new_file.type
                    });
                }
                fd.append("new_file", file.new_file);
                fd.append("file_category", file.file_category);
                fd.append("approved_mjs", responseData.id);
                await axiosInstance.post(`${currentCompany}maintenance/stored_file/`, fd);
            });
        }
        dispatch(push("/tasks/"));
    };
}

function rejectTask(reason) {
    return async (dispatch, getState) => {
        const {
            user: { currentCompany }
        } = getState();
        const { id, reject_reason } = reason;
        await axiosInstance.patch(`${currentCompany}maintenance/maintenance_request/${id}/reject/`, { reject_reason });

        await dispatch(push("/tasks/requested"));
    };
}

function getTimesheetInfo(modalType, detailData, loggedInfo) {
    return async (dispatch, getState) => {
        const {
            user: { currentCompany }
        } = getState();
        const timesheetInfo = {};
        let workersResponse;
        let workers;
        if (currentCompany === "linings-wbop/") {
            workersResponse = await axiosInstance.get(`crm/slim_contact/internal_timesheet/`);
            workers = await workersResponse.data;
        } else {
            workersResponse = await dispatch(crmActions.getSlimCurrentEntity("?relationships=1&relationships=2&relationships=3&assignments=2"));
            workers = await workersResponse;
        }
        const jobResponse = await axiosInstance.get(`${currentCompany}maintenance/slim_maintenance_job/current/`);
        const jobListData = await jobResponse.data;
        timesheetInfo.jobs = jobListData.map(job => {
            return {
                value: job.id,
                label: `MJS-${job.id} (${job.job}) ${job.job_name_string ? `: ${job.job_name_string}` : ""} ${job.notes ? `- ${job.notes}` : ""}`,
                job_id: job.job,
                works_complete: job.works_complete
            };
        });
        timesheetInfo.workers = workers;
        timesheetInfo.modalType = modalType;
        if (detailData) {
            timesheetInfo.detailData = detailData;
            timesheetInfo.loggedInfo = loggedInfo;
        }
        dispatch(setTimesheetInfo(timesheetInfo));
    };
}

function postTimesheet(datas, params) {
    return async (dispatch, getState) => {
        const {
            user: { currentCompany },
            tasks: { workersWithLoggedHours, timesheetList, requestData },
            router: {
                location: { pathname }
            }
        } = getState();

        const taskId = pathname.match(/\d+/g) && pathname.match(/\d+/g)[0];
        await Promise.all(datas.map(data => axiosInstance.post(`${currentCompany}maintenance/maintenance_hours_log/`, data))).then(values => {
            if (taskId && values) {
                const newWorkersLoggedHours = [...workersWithLoggedHours];
                values.forEach(value => {
                    if (value.data.maintenance_job === Number(taskId)) {
                        newWorkersLoggedHours.push(value.data);
                    }
                });
                dispatch(setWorkersLoggedHours(_.orderBy(newWorkersLoggedHours, ["work_date"], ["desc"])));
            }

            if (timesheetList) {
                dispatch(getTimesheetList(requestData));
            }
        });
    };
}

function putTimesheet(data, loggedInfo) {
    return async (dispatch, getState) => {
        const {
            user: { currentCompany },
            tasks: { workersWithLoggedHours, timesheetList, requestData }
        } = getState();
        const response = await axiosInstance.put(`${currentCompany}maintenance/maintenance_hours_log/${data[0].id}/`, data[0]);
        const responseData = await response.data;
        if (responseData.id) {
            const newWorkersLoggedHours = [];
            if (workersWithLoggedHours) {
                workersWithLoggedHours.forEach(workers => {
                    if (workers.id === responseData.id) {
                        if (workers.maintenance_job === responseData.maintenance_job) {
                            newWorkersLoggedHours.push(responseData);
                        }
                    } else {
                        newWorkersLoggedHours.push(workers);
                    }
                });
                dispatch(setWorkersLoggedHours(newWorkersLoggedHours));
            }

            if (timesheetList) {
                dispatch(getTimesheetList(requestData));
            }
        }
    };
}

function getDetailMaintenanceJob(mjsId, listType) {
    return async (dispatch, getState) => {
        let {
            user: {
                permissions: { maintenance: taskPermission },
                currentCompany
            }
        } = getState();
        const dataUrl = listType === "detail" ? "job" : "request";
        const response = await axiosInstance.get(`${currentCompany}maintenance/maintenance_${dataUrl}/${mjsId}/`);
        const detailData = await response.data;
        const fileUrl = listType === "detail" ? "approved" : "request";
        const fileResponse = await axiosInstance.get(`${currentCompany}maintenance/stored_file/by_${fileUrl}_mjs/${mjsId}/`);
        const fileData = await fileResponse.data;
        detailData.file = fileData;
        if (detailData.dayworks) {
            const dayworksResponse = await axiosInstance.get(`${currentCompany}maintenance/maintenance_daywork_log/slim/by_mjs/${mjsId}/`);
            detailData.dayworksList = dayworksResponse.data.map(work => ({
                ...work,
                isOpen: false
            }));
        }

        if (listType === "detail" && taskPermission >= 2) {
            const summarySheetResponse = await axiosInstance.get(`${currentCompany}maintenance/summary_sheet/by_mjs/${mjsId}/`);
            const summarySheetData = await summarySheetResponse.data;
            dispatch(setSummarySheet(summarySheetData));

            const loggedHoursResponse = await axiosInstance.get(`${currentCompany}maintenance/maintenance_hours_log/${mjsId}/mjs/`);
            const workersWithLoggedHours = await loggedHoursResponse.data;
            dispatch(setWorkersLoggedHours(workersWithLoggedHours));
        }

        detailData.job = {
            value: detailData.job,
            label: `${detailData.job}: ${detailData.job_name_string} ${detailData.job_name_string ? "- " : ""}${
                detailData.client_string || detailData.resource_string
            }`
        };
        dispatch(setDetailMaintenanceJob(detailData));
    };
}

function fileDownload(idx) {
    return async (dispatch, getState) => {
        const {
            user: { currentCompany }
        } = getState();
        const response = await axiosInstance.get(`${currentCompany}maintenance/stored_file/get_public_download/${idx}/`);
        const linkurl = response.data.download_link;
        const link = document.createElement("a");
        link.href = linkurl;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };
}

function getWorkersLoggedHours(requestData) {
    return async (dispatch, getState) => {
        const {
            user: { currentCompany }
        } = getState();
        const { id, startDateRange, endDateRange } = requestData;

        const response = await axiosInstance.get(
            `${currentCompany}maintenance/maintenance_hours_log/${id}/mjs_date_range/${startDateRange}/${endDateRange}/`
        );
        const workersWithLoggedHours = await response.data;
        //console.log(responseData);
        dispatch(setWorkersLoggedHours(workersWithLoggedHours));
    };
}

function getNewSummarySheet(id, date) {
    return async (dispatch, getState) => {
        const {
            user: { currentCompany }
        } = getState();
        const response = await axiosInstance.get(`${currentCompany}maintenance/maintenance_hours_log/${id}/mjs_no_summary_date_before/${date}/`);
        const newSummarySheet = await response.data;

        dispatch(setNewSummaryData(newSummarySheet));
    };
}

function setNewSummarySheet(id, date) {
    return async (dispatch, getState) => {
        const {
            user: { currentCompany }
        } = getState();
        const response = await axiosInstance.post(`${currentCompany}maintenance/summary_sheet/create/${id}/${date}/`, {});
        const addingSummarySheet = await response.data;
        //console.log(newSummarySheetData);
        const loggedHoursResponse = await axiosInstance.get(`${currentCompany}maintenance/maintenance_hours_log/${id}/mjs/`);
        const workersWithLoggedHours = await loggedHoursResponse.data;
        dispatch(setWorkersLoggedHours(workersWithLoggedHours));
        dispatch(addSummarySheet(addingSummarySheet));
    };
}

function sendingEmail(id, recipient) {
    return async (dispatch, getState) => {
        const {
            user: { currentCompany }
        } = getState();
        await axiosInstance.post(`${currentCompany}maintenance/summary_sheet/send/${id}/`, recipient);
    };
}

function getSummarysheet(data, tempCompany = null) {
    return async (dispatch, getState) => {
        const {
            user: { currentCompany }
        } = getState();

        try {
            const summaryResponse = await axiosInstance.get(
                `${tempCompany ? tempCompany : currentCompany}maintenance/summary_sheet/get/${data.id}/`,
                { responseType: "blob" }
            );

            downloadFile(summaryResponse);

            return { message: "completed", id: data.id };
        } catch (error) {
            return error.response;
        }
    };
}

function getTasksDayworks() {
    return async (dispatch, getState) => {
        const {
            user: { currentCompany }
        } = getState();
        const tasksResponse = await axiosInstance.get(`${currentCompany}maintenance/slim_maintenance_job/`);
        const tasksDayworks = await tasksResponse.data;
        const filteredTasksDayworks = await tasksDayworks
            .filter(task => task.dayworks)
            .map(task => ({
                value: task.id,
                label: `MJS-${task.id} ${task.job_name_string && `: ${task.job_name_string}`}`
            }));
        dispatch(setTasksDayworks(filteredTasksDayworks));
    };
}

function postDayworks(submitData, emailAddress) {
    return async (dispatch, getState) => {
        const {
            user: { currentCompany }
        } = getState();
        const response = await Promise.all(
            submitData.datas.map(async data =>
                axiosInstance.post(`${currentCompany}maintenance/maintenance_daywork_log/`, {
                    ...data,
                    associated_files: await Promise.all(
                        data.file_list.map(file =>
                            axiosInstance
                                .post(`${currentCompany}maintenance/stored_file/`, getFileData(file, "approved_mjs", submitData.approved_mjs))
                                .then(values => {
                                    return values.data.id;
                                })
                        )
                    ),
                    file_list: undefined
                })
            )
        );

        dispatch(
            sendDayworks(
                response.map(res => res.data.id),
                emailAddress,
                submitData.approved_mjs
            )
        );
    };
}

function sendDayworks(dayworks, emailAddress, mjsId = null) {
    return async (dispatch, getState) => {
        const {
            user: { currentCompany }
        } = getState();
        if (dayworks.length === 1) {
            await axiosInstance.post(`${currentCompany}maintenance/maintenance_daywork_log/${dayworks[0]}/send_daywork/`, {
                recipient: emailAddress
            });
        } else {
            await axiosInstance.post(`${currentCompany}maintenance/maintenance_daywork_log/send_dayworks/`, {
                recipient: emailAddress,
                dayworks
            });
        }

        if (mjsId) {
            dispatch(push(`/tasks/${mjsId}`));
        }
    };
}

function getDayworkDetail(id) {
    return async (dispatch, getState) => {
        const {
            user: { currentCompany }
        } = getState();

        const dayworkDetailResponse = await axiosInstance.get(`${currentCompany}maintenance/maintenance_daywork_log/${id}/`);
        const dayworkDetailData = await dayworkDetailResponse.data;
        dispatch(setDayworkDetail(dayworkDetailData));
    };
}

function getDayworkDateRange(dayworkRange) {
    return async (dispatch, getState) => {
        const {
            user: { currentCompany }
        } = getState();
        const { id, startDateRange, endDateRange } = dayworkRange;

        const response = await axiosInstance.get(
            `${currentCompany}maintenance/maintenance_daywork_log/slim/mjs_date_range/${id}/${startDateRange}/${endDateRange}/`
        );
        const responseData = await response.data;
        dispatch(setDayworkDateRange(responseData));
    };
}

function getTaskDetailDaywork(id) {
    return async (dispatch, getState) => {
        const {
            user: { currentCompany }
        } = getState();

        const getTaskDetailResponse = await axiosInstance.get(`${currentCompany}maintenance/maintenance_job/${id}/`);
        const taskDetailDaywork = await getTaskDetailResponse.data;
        dispatch(setTaskDetailDaywork(taskDetailDaywork));
    };
}

function getTimesheetWorkers() {
    return async (dispatch, getState) => {
        const {
            user: { currentCompany },
            tasks: { timesheetWorkers }
        } = getState();
        if (!timesheetWorkers) {
            let workersResponse;
            let workers;
            if (currentCompany === "linings-wbop/") {
                workersResponse = await axiosInstance.get(`crm/slim_contact/internal_timesheet/`);
                const responseData = workersResponse.data;
                workers = responseData.map(worker => ({
                    value: worker.id,
                    label: `${worker.first_name} ${worker.last_name} ${worker.is_subcontractor ? "(SC)" : ""}`,
                    is_subcontractor: worker.is_subcontractor
                }));
            } else {
                workersResponse = await dispatch(crmActions.getSlimCurrentEntity("?relationships=1&relationships=2&relationships=3&assignments=2"));
                workers = workersResponse.map(worker => {
                    const is_subcontractor = worker.relationships.find(relationship => relationship.search("Sub Contractor") >= 0);
                    return {
                        value: worker.id,
                        label: `${worker.name} ${is_subcontractor ? "(SC)" : ""}`,
                        is_subcontractor
                    };
                });
            }

            dispatch(setTimesheetWorkers(workers));
        }
    };
}

function getTimesheetList(requestData) {
    return async (dispatch, getState) => {
        const { selectedWorker, startDateRange, endDateRange } = requestData;
        const {
            user: { currentCompany }
        } = getState();
        let timesheetResponse;
        if (currentCompany === "linings-wbop/") {
            timesheetResponse = await axiosInstance.get(
                `${currentCompany}maintenance/maintenance_hours_log/by_worker/${selectedWorker}/date_range/${startDateRange}/${endDateRange}/`
            );
        } else {
            timesheetResponse = await axiosInstance.get(
                `${currentCompany}maintenance/maintenance_hours_log/by_entity/${selectedWorker}/date_range/${startDateRange}/${endDateRange}/`
            );
        }
        const timesheetList = timesheetResponse.data;
        dispatch(setTimesheetList({ timesheetList, requestData }));
    };
}
function getRosterList(filter) {
    return async (dispatch, getState) => {
        const {
            user: { currentCompany }
        } = getState();
        const rosterResponse = await axiosInstance.get(`${currentCompany}job/slim_job/${filter}`);
        const rosterList = rosterResponse.data;

        dispatch(setRosterList(rosterList));
        // return [];
    };
}

function updateRosterList(item) {
    return async (dispatch, getState) => {
        const {
            tasks: { rosterList }
        } = getState();
        const updatedRosterList = rosterList.map(list => {
            if (list.id === item.id) {
                return item;
            } else {
                return list;
            }
        });
        dispatch(setRosterList(updatedRosterList));
    };
}

function getTaskCommons(hasToRefresh = false) {
    return async () => {
        const urls = [`/fire-akl/maintenance/status/`, `/fire-akl/maintenance/cost_type/`, `/fire-akl/maintenance/labour_type/`];
        await Promise.all(urls.map(url => axiosInstance.get(url))).then(values => {
            const [statusResponse, costTypeResponse, labourTypeResponse] = values;
            localStorage.setItem("status", JSON.stringify(statusResponse.data));
            localStorage.setItem("costType", JSON.stringify(costTypeResponse.data));
            localStorage.setItem("MJSlabourType", JSON.stringify(labourTypeResponse.data));
        });
        if (hasToRefresh) {
            window.location.reload();
        }
    };
}

function filterTimecloudList(list, userId) {
    const decisionMadeByMe = list.decisions.find(decision => decision.decision_maker === userId);
    return !decisionMadeByMe && list.decisions.length < 2;
}

function getTimeCloudList() {
    return async (dispatch, getState) => {
        const {
            user: { currentCompany, userId, isResidentialSupervisorTeamLeader, isCommercialSupervisorTeamLeader, isSLT, isRosterer, permissions }
        } = getState();
        try {
            if (permissions.maintenance >= 3) {
                const adminLevel = isResidentialSupervisorTeamLeader || isCommercialSupervisorTeamLeader || isSLT || isRosterer;
                const timecloudsResponse = await axiosInstance.get(
                    `${currentCompany}maintenance/time_tracking/slim/${adminLevel ? "" : `?starting_job__internal_supervisor=${userId}`}${
                        adminLevel ? "?" : "&"
                    }fully_approved=false`
                );
                const travelTimeResponse = await axiosInstance.get(
                    `${currentCompany}maintenance/time_tracking/slim/?starting_mjs=302${
                        adminLevel ? "" : `&ending_job__internal_supervisor=${userId}`
                    }&fully_approved=false`
                );
                const timecloudList = timecloudsResponse.data;
                const totalList = timecloudList.filter(list => list.mjs_id !== 302).filter(list => filterTimecloudList(list, userId));
                dispatch(setTimecloudList(totalList));
                dispatch(setTravelTimeList(travelTimeResponse.data.filter(list => filterTimecloudList(list, userId))));
            }
        } catch (error) {
            alert("Something went wrong. Please try again later.");
            console.log(error);
        }
    };
}

function getTimecloudAction() {
    return async (dispatch, getState) => {
        const {
            tasks: { timecloudAction }
        } = getState();
        try {
            if (!timecloudAction) {
                const response = await axiosInstance.get(`common/maintenance/time_clock_action/`);
                dispatch(setTimecloudAction(response.data.filter(action => action.current)));
            }
        } catch (error) {
            console.log(error.response);
        }
    };
}

// function submitTimecloud(entries, original_id = null) {
//     return async (dispatch, getState) => {
//         const {
//             user: { currentCompany }
//         } = getState();
//         try {
//             await Promise.allSettled(
//                 entries.map(entry => axiosInstance.put(`${currentCompany}maintenance/time_tracking/${original_id || entry.id}/`, entry))
//             );
//             dispatch(getTimeCloudList());
//         } catch (error) {
//             console.log(error);
//         }
//     };
// }

function modifyTimecloud(entries, original_id = null) {
    return async (dispatch, getState) => {
        const {
            user: { currentCompany }
        } = getState();
        try {
            const responses = await Promise.all(
                entries.map(entry =>
                    axiosInstance.patch(`${currentCompany}maintenance/time_tracking/${original_id || entry.id}/modify_entry/`, entry)
                )
            ).then(value => value.map(val => val.data));
            dispatch(updateTimeEntryList(responses));
            // dispatch(getTimeCloudList());
            return "finished";
        } catch (error) {
            console.log(error);
        }
    };
}

function getRejectReasons() {
    return async (dispatch, getState) => {
        const {
            tasks: { rejectReasons }
        } = getState();
        try {
            if (rejectReasons) return rejectReasons;
            const rejectReasonsResponse = await axiosInstance.get(`common/maintenance/time_clock_reason/`);
            const rejectReasonsData = rejectReasonsResponse.data.filter(reason => reason.current);
            dispatch(setRejectReasons(rejectReasonsData));
            return rejectReasonsData;
        } catch (error) {
            console.log(error);
            alert("Something went wrong, please try again later");
        }
    };
}

function completeWork(id) {
    return async (dispatch, getState) => {
        const {
            user: { currentCompany }
        } = getState();
        try {
            await axiosInstance.patch(`${currentCompany}maintenance/maintenance_job/${id}/works_complete/`);
            dispatch(setWorkCompleted(id));
        } catch (error) {
            console.log(error);
        }
    };
}

function updateBillingState(id, data) {
    return async (dispatch, getState) => {
        const {
            user: { currentCompany, welcomeName, userId }
        } = getState();
        try {
            await axiosInstance.patch(`${currentCompany}maintenance/maintenance_job/${id}/update_billing_state/`, data);
            dispatch(setBillingState({ ...data, welcomeName, userId }));
        } catch (error) {
            console.log(error);
        }
    };
}

function updateTimecloud(mjsId) {
    return (_, getState) => {
        const {
            user: { currentCompany }
        } = getState();
        try {
            axiosInstance.patch(`${currentCompany}maintenance/maintenance_job/${mjsId}/update_timecloud/`);
        } catch (error) {
            console.log(error.response);
        }
    };
}

function removeTimesheetList() {
    return {
        type: REMOVE_TIMESHEET_LIST
    };
}

function removeTaskDetail() {
    return dispatch => {
        dispatch(setRemoveTaskDetail());
    };
}

// action creators
function slimMaintenanceJob(jobList) {
    return {
        type: SLIM_MAINTENANCE_JOB,
        jobList
    };
}

function maintenanceRequested(jobList) {
    return {
        type: MAINTENANCE_REQUESTED,
        jobList
    };
}

function newTaskModal(newTaskData) {
    return {
        type: GET_NEW_TASK,
        newTaskData
    };
}

function setTimesheetInfo(timesheetInfo) {
    return {
        type: SET_TIMESHEET_INFO,
        timesheetInfo
    };
}

function setDetailMaintenanceJob(detailData) {
    return {
        type: SET_DETAIL_MAINTENANCE_JOB,
        detailData
    };
}

function setWorkersLoggedHours(workersWithLoggedHours) {
    return {
        type: SET_WORKERS_LOGGED_HOURS,
        workersWithLoggedHours
    };
}

function setSummarySheet(summarySheet) {
    return {
        type: SET_SUMMARY_SHEET,
        summarySheet
    };
}

function setNewSummaryData(newSummarySheet) {
    return {
        type: SET_NEW_SUMMARY_SHEET,
        newSummarySheet
    };
}

function addSummarySheet(addingSummarySheet) {
    return {
        type: ADD_SUMMARYSHEET,
        addingSummarySheet
    };
}

function setTasksDayworks(tasksDayworks) {
    return {
        type: SET_TASKS_DAYWORKS,
        tasksDayworks
    };
}

function setDayworkDateRange(dayworkDateRange) {
    return {
        type: SET_DAYWORK_DATE_RANGE,
        dayworkDateRange
    };
}

function setDayworkDetail(dayworkDetail) {
    return {
        type: SET_DAYWORK_DETAIL,
        dayworkDetail
    };
}

function setTaskDetailDaywork(taskDetailDaywork) {
    return {
        type: SET_TASK_DETAIL_DAYWORK,
        taskDetailDaywork
    };
}

function setRemoveTaskDetail() {
    return {
        type: REMOVE_TASK_DETAIL
    };
}

function setTimesheetWorkers(timesheetWorkers) {
    return {
        type: SET_TIMESHEET_WORKERS,
        timesheetWorkers
    };
}

function setTimesheetList(timesheetWithReq) {
    return {
        type: SET_TIMESHEET_LIST,
        timesheetWithReq
    };
}

function setWorkCompleted(completedId) {
    return {
        type: SET_WORK_COMPLETED,
        completedId
    };
}

function setDataTaskFilter(filterData) {
    return {
        type: SET_DATA_TASK_FILTER,
        filterData
    };
}

function setRosterList(rosterList) {
    return {
        type: SET_ROSTER_LIST,
        rosterList
    };
}
function setBillingState(data) {
    return {
        type: SET_BILLING_STATE,
        data
    };
}

function setRejectReasons(rejectReasons) {
    return {
        type: SET_REJECT_REASON,
        rejectReasons
    };
}

function setTimecloudList(timecloudList) {
    return {
        type: SET_TIMECLOULD_LIST,
        timecloudList
    };
}

// function setTeamTimecloudList(teamTimeCloudList) {
//     return {
//         type: SET_TEAM_TIMECLOUD_LIST,
//         teamTimeCloudList
//     };
// }

function setTimecloudAction(timecloudAction) {
    return {
        type: SET_TIMECLOUD_ACTION,
        timecloudAction
    };
}

function setTravelTimeList(travelTimeList) {
    return {
        type: SET_TRAVEL_TIME_LIST,
        travelTimeList
    };
}

// function setTeamTravelTimeList(teamTravelTimeList) {
//     return {
//         type: SET_TEAM_TRAVEL_TIME_LIST,
//         teamTravelTimeList
//     };
// }

function removeTimeCloudList() {
    return {
        type: REMOVE_TIMECLOUD_LIST
    };
}

function updateTimeEntryList(updatedList) {
    return {
        type: UPDATE_TIMEENTRY_LIST,
        updatedList
    };
}

// initial states

const initialState = { newSummarySheet: [], dateRange: { startDate: "", endDate: "" }, timecloudList: [], teamTimeCloudList: [] };

// reducer

function reducer(state = initialState, action) {
    switch (action.type) {
        case SLIM_MAINTENANCE_JOB:
            return applySlimMaintenanceJob(state, action);
        case GET_NEW_TASK:
            return applyGetNewTask(state, action);
        case MAINTENANCE_REQUESTED:
            return applyMaintenanceRequest(state, action);
        case SET_TIMESHEET_INFO:
            return applyTimesheetInfo(state, action);
        case SET_DETAIL_MAINTENANCE_JOB:
            return applySetDetailMaintenanceJob(state, action);
        case SET_WORKERS_LOGGED_HOURS:
            return applyWorkesLoggedHours(state, action);
        case SET_SUMMARY_SHEET:
            return applySummarySheet(state, action);
        case SET_NEW_SUMMARY_SHEET:
            return applyNewSummarySheet(state, action);
        case ADD_SUMMARYSHEET:
            return applyAddSummarSheet(state, action);
        case SET_TASKS_DAYWORKS:
            return applyTasksDayworks(state, action);
        case SET_DAYWORK_DETAIL:
            return applyDayworkDetail(state, action);
        case SET_TASK_DETAIL_DAYWORK:
            return applyTaskDetailDaywork(state, action);
        case SET_DAYWORK_DATE_RANGE:
            return applyDayworkDateRange(state, action);
        case REMOVE_TASK_DETAIL:
            return { ...state, taskDetailDaywork: undefined };
        case SET_TIMESHEET_WORKERS:
            return applyTimesheetWorkers(state, action);
        case SET_TIMESHEET_LIST:
            return applyTimesheetList(state, action);
        case SET_ROSTER_LIST:
            return applyRosterList(state, action);
        case REMOVE_TIMESHEET_LIST:
            return {
                ...state,
                timesheetList: undefined
            };
        case SET_WORK_COMPLETED:
            return applyWorkCompleted(state, action);
        case SET_DATA_TASK_FILTER:
            return applyDataTaskFilter(state, action);
        case SET_BILLING_STATE:
            return applyBillingState(state, action);
        case SET_REJECT_REASON:
            return {
                ...state,
                rejectReasons: action.rejectReasons
            };
        case SET_TIMECLOULD_LIST:
            return {
                ...state,
                timecloudList: action.timecloudList
            };
        case SET_TEAM_TIMECLOUD_LIST:
            return {
                ...state,
                teamTimeCloudList: action.teamTimeCloudList
            };
        case SET_TIMECLOUD_ACTION:
            return {
                ...state,
                timecloudAction: action.timecloudAction
            };
        case SET_TRAVEL_TIME_LIST:
            return {
                ...state,
                travelTimeList: action.travelTimeList
            };
        case SET_TEAM_TRAVEL_TIME_LIST:
            return {
                ...state,
                teamTravelTimeList: action.teamTravelTimeList
            };
        case REMOVE_TIMECLOUD_LIST:
            return {
                ...state,
                timecloudList: [],
                teamTimeCloudList: []
            };
        case UPDATE_TIMEENTRY_LIST:
            return applyTimeEntryList(state, action);
        default:
            return state;
    }
}

// reducer function

function applySlimMaintenanceJob(state, action) {
    const { jobList } = action;
    return {
        ...state,
        jobList
    };
}

function applyGetNewTask(state, action) {
    const { newTaskData } = action;
    return {
        ...state,
        newTaskData
    };
}

function applyMaintenanceRequest(state, action) {
    const { jobList } = action;
    return {
        ...state,
        jobList
    };
}

function applyTimesheetInfo(state, action) {
    const { timesheetInfo } = action;
    return {
        ...state,
        timesheetInfo
    };
}

function applySetDetailMaintenanceJob(state, action) {
    const { detailData } = action;
    return {
        ...state,
        detailData
    };
}

function applyWorkesLoggedHours(state, action) {
    const { workersWithLoggedHours } = action;
    return {
        ...state,
        workersWithLoggedHours
    };
}

function applySummarySheet(state, action) {
    const { summarySheet } = action;
    return {
        ...state,
        summarySheet
    };
}

function applyNewSummarySheet(state, action) {
    const { newSummarySheet } = action;
    return {
        ...state,
        newSummarySheet
    };
}

function applyAddSummarSheet(state, action) {
    const { addingSummarySheet } = action;
    const { summarySheet } = state;
    return {
        ...state,
        summarySheet: [addingSummarySheet, ...summarySheet],
        newSummarySheet: []
    };
}

function applyTasksDayworks(state, action) {
    const { tasksDayworks } = action;
    return {
        ...state,
        tasksDayworks
    };
}

function applyDayworkDetail(state, action) {
    const { dayworkDetail } = action;
    const {
        detailData,
        detailData: { dayworksList }
    } = state;
    const index = _.findIndex(dayworksList, list => list.id === dayworkDetail.id);
    dayworksList[index] = {
        ...dayworkDetail,
        ...dayworksList[index],
        isOpen: true
    };

    return {
        ...state,
        detailData: {
            ...detailData,
            dayworksList
        }
    };
}

function applyTaskDetailDaywork(state, action) {
    const { taskDetailDaywork } = action;
    return {
        ...state,
        taskDetailDaywork
    };
}

function applyDayworkDateRange(state, action) {
    const { dayworkDateRange } = action;
    const { detailData } = state;
    return {
        ...state,
        detailData: { ...detailData, dayworksList: dayworkDateRange }
    };
}

function applyTimesheetWorkers(state, action) {
    const { timesheetWorkers } = action;
    return {
        ...state,
        timesheetWorkers
    };
}

function applyTimesheetList(state, action) {
    const { timesheetWithReq } = action;
    return { ...state, ...timesheetWithReq };
}

function applyWorkCompleted(state, action) {
    const { completedId } = action;
    const { detailData, jobList } = state;
    return {
        ...state,
        detailData: { ...detailData, works_complete: true, works_complete_datetime: new Date() },
        jobList: jobList?.map(list => (Number(list.id) === Number(completedId) ? { ...list, works_complete: true } : list))
    };
}

function applyDataTaskFilter(state, action) {
    const { filterData } = action;
    return { ...state, filterData };
}

function applyRosterList(state, action) {
    const { rosterList } = action;
    return { ...state, rosterList };
}

function applyBillingState(state, action) {
    const { detailData } = state;
    const { data } = action;
    console.log(data);
    const workCompleted = data.billing_state_id === 3;
    const newDate = new Date();
    return {
        ...state,
        detailData: {
            ...detailData,
            billing_state: data.billing_state_id,
            billing_change_user_string: data.welcomeName,
            billing_state_last_change_user: data.userId,
            billing_state_last_change: newDate,
            works_complete: workCompleted,
            works_complete_datetime: workCompleted ? newDate : null,
            works_complete_user: workCompleted ? data.userId : "",
            works_complete_user_string: workCompleted ? data.welcomeName : ""
        }
    };
}

function applyTimeEntryList(state, action) {
    const { timecloudList, travelTimeList } = state;
    const { updatedList } = action;
    let newTimecloudList = [...timecloudList];
    let newTravelTimeList = [...travelTimeList];
    updatedList.forEach(list => {
        if (list.starting_mjs === 302) {
            newTravelTimeList = newTravelTimeList.filter(travelTime => Number(travelTime.id) !== Number(list.previous_revision));
            newTravelTimeList.push(refineTimeEntryToSlim(list));
        } else {
            newTimecloudList = newTimecloudList.filter(timeList => Number(timeList.id) !== Number(list.previous_revision));
            newTimecloudList.push(refineTimeEntryToSlim(list));
        }
    });
    return {
        ...state,
        timecloudList: newTimecloudList,
        travelTimeList: newTravelTimeList
    };
}

// export

const actionCreators = {
    getSlimMaintenanceJob,
    getNewTask,
    getMaintenanceRequested,
    postNewTask,
    getTimesheetInfo,
    postTimesheet,
    putTimesheet,
    getDetailMaintenanceJob,
    fileDownload,
    getWorkersLoggedHours,
    getNewSummarySheet,
    setNewSummarySheet,
    sendingEmail,
    getSummarysheet,
    approveTaskRequest,
    rejectTask,
    getTasksDayworks,
    postDayworks,
    getDayworkDetail,
    getDayworkDateRange,
    getTaskDetailDaywork,
    removeTaskDetail,
    sendDayworks,
    getTimesheetWorkers,
    getTimesheetList,
    removeTimesheetList,
    getTaskCommons,
    getRosterList,
    updateRosterList,
    completeWork,
    updateBillingState,
    uploadFile,
    getDataTaskFilter,
    getMaintenanceService,
    updateTimecloud,
    getTimeCloudList,
    getTimecloudAction,
    removeTimeCloudList,
    modifyTimecloud,
    // getMissingMJS,
    getRejectReasons
    // submitTimecloud
};

export { actionCreators };

// reducer export
export default reducer;

export const getRosterDataAfterRefined = taskState => {
    const { dateRange, rosterList } = taskState;
    // const labourType = JSON.parse(localStorage.getItem("service"));
    // const companyPrefix = localStorage.getItem("companyPrefix");
    // const status = [
    //     { id: 1, name: "Requested" },
    //     { id: 2, name: "Draft" },
    //     { id: 3, name: "Published" }
    // ];
    if (dateRange && rosterList) {
        const dateList = [];
        const today = new Date();
        const startOfWeek = dayjs(today).startOf("week");
        const endOfWeek = dayjs(today).endOf("week");
        const diff = dayjs(dateRange.endDate).diff(dayjs(dateRange.startDate), "days");
        for (let i = 0; i <= diff; i++) {
            const date = dayjs(dateRange.startDate).add(i, "days");
            dateList.push({
                str: `${date.format("DD/MM/YYYY")}`,
                calendarDay: date.calendar(null, {
                    sameDay: "dddd ([Today])",
                    nextDay: "dddd ([Tomorrow])",
                    nextWeek: `dddd ([${date.endOf("day").fromNow()}])`,
                    lastDay: "dddd ([Yesterday])",
                    lastWeek: `dddd ([${date.endOf("day").fromNow()}])`,
                    sameElse: `dddd ([${date.endOf("day").fromNow()}])`
                }),
                currentWeek: date.isBetween(startOfWeek, endOfWeek, null, "[]"),
                d: date.format("YYYY-MM-DD")
            });
        }
        const filteredRosterList = rosterList
            .filter(roster => !roster.withdrawn)
            .reduce((result, current) => {
                if (current.job) {
                    const selected = result.find(res => res.job === current.job);
                    if (selected) {
                        selected.requested_dates.push(current);
                    } else {
                        result.push({
                            job: current.job,
                            job_region_name: current.job_region_name,
                            job_name: current.job_name,
                            requested_dates: [current]
                        });
                    }
                } else {
                    const selected = result.find(res => res.supervisor === current.supervisor);
                    if (selected) {
                        selected.requested_dates.push(current);
                    } else {
                        result.push({
                            supervisor: current.supervisor,
                            supervisor_name: current.supervisor_name,
                            supervisor_home_region: current.supervisor_home_region,
                            requested_dates: [current]
                        });
                    }
                }
                return result;
            }, []);

        return { dateList, rosterList: filteredRosterList };
    } else {
        return { dateList: [], rosterList: [] };
    }
};

export const getTimesheetWorkersForSelect = taskState => {
    const { timesheetWorkers } = taskState;
    return timesheetWorkers
        ? timesheetWorkers.map(worker => ({
              label: `${worker.first_name} ${worker.last_name}`,
              value: worker.id
          }))
        : [];
};
