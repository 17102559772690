import React, { useState, useEffect } from "react";
import { Card, CardContent, Grid, Stack, TextField, IconButton } from "@mui/material";
import { Label } from "components/HumanResources/CustomComponent";
import WindowedSelect from "react-windowed-select";
import { timeDiff } from "shared/Utils";
import ClearIcon from "@mui/icons-material/Clear";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { useSelector } from "react-redux";
import { Controller, useFormContext, useWatch } from "react-hook-form";
import dayjs from "shared/dayjs";

const TimecloudEntry = props => {
    const { entry, remove, index, append } = props;
    const {
        tasks: { jobList: taskList = [] },
        jobs: { jobList },
        crm: { entitiesList },
        user: { isAdmin, isSLT }
    } = useSelector(state => state);
    const [totalHours, setTotalHours] = useState("");
    const { control } = useFormContext();
    const [currentClockIn, currentClockOut, currentStartJob, currentMJS] = useWatch({
        control,
        name: [
            `entryList.${index}.starting_timestamp`,
            `entryList.${index}.ending_timestamp`,
            `entryList.${index}.starting_job`,
            `entryList.${index}.starting_mjs`
        ],
        defaultValue: { starting_timestamp: entry.starting_timestamp, ending_timestamp: entry.ending_timestamp }
    });
    useEffect(() => {
        const totalHours = timeDiff(currentClockIn, currentClockOut);
        setTotalHours(totalHours);
    }, [currentClockIn, currentClockOut]);

    return (
        <Grid item sm={12}>
            <Card variant="outlined" sx={{ position: "relative" }}>
                <CardContent>
                    {index !== 0 && (
                        <IconButton
                            color="error"
                            sx={{ margin: 0, top: 5, right: 5, position: "absolute", zIndex: 4 }}
                            onClick={() => {
                                remove(index);
                            }}
                        >
                            <ClearIcon />
                        </IconButton>
                    )}
                    <Grid container spacing={1}>
                        {isSLT && (
                            <Grid item xs={12} sm={12} md={12}>
                                <Label>Worker</Label>
                                <Controller
                                    name={`entryList.${index}.worker_id`}
                                    control={control}
                                    render={({ field: { value, onChange, ...rest } }) => (
                                        <WindowedSelect
                                            menuPosition="fixed"
                                            options={entitiesList}
                                            value={entitiesList.find(list => list.id === Number(value))}
                                            onChange={option => onChange(option.id)}
                                            getOptionLabel={option => option.name}
                                            getOptionValue={option => option.id}
                                            {...rest}
                                        />
                                    )}
                                />
                            </Grid>
                        )}
                        <Grid item xs={12} sm={12} md={6}>
                            <Label>Job {currentMJS === 302 && <strong>(From ~ To)</strong>}</Label>
                            <Stack direction="column" spacing={0.5}>
                                <Controller
                                    name={`entryList.${index}.starting_job`}
                                    control={control}
                                    render={({ field: { value, onChange, ...rest } }) => (
                                        <WindowedSelect
                                            menuPosition="fixed"
                                            options={jobList}
                                            value={jobList.find(list => list.id === Number(value))}
                                            onChange={option => onChange(option.id)}
                                            getOptionLabel={option => `${option.id} - ${option.job_name}`}
                                            getOptionValue={option => option.id}
                                            {...rest}
                                        />
                                    )}
                                />
                                {currentMJS === 302 && (
                                    <Controller
                                        name={`entryList.${index}.ending_job`}
                                        control={control}
                                        render={({ field: { value, onChange, ...rest } }) => (
                                            <WindowedSelect
                                                menuPosition="fixed"
                                                options={jobList}
                                                value={jobList.find(list => list.id === Number(value))}
                                                onChange={option => onChange(option.id)}
                                                getOptionLabel={option => `${option.id} - ${option.job_name}`}
                                                getOptionValue={option => option.id}
                                                {...rest}
                                            />
                                        )}
                                    />
                                )}
                            </Stack>
                            {/* <Typography>{`GL${entry.job} (${entry.site})`}</Typography> */}
                        </Grid>
                        <Grid item xs={12} sm={12} md={6}>
                            <Label>MJS</Label>
                            <Stack direction="column" spacing={0.5}>
                                <Controller
                                    name={`entryList.${index}.starting_mjs`}
                                    control={control}
                                    render={({ field: { value, onChange, ...rest } }) => (
                                        <WindowedSelect
                                            menuPosition="fixed"
                                            options={taskList.filter(task => task.job === currentStartJob || task.id === 302)}
                                            value={taskList.find(list => list.id === Number(value))}
                                            onChange={option => onChange(option.id)}
                                            getOptionLabel={option => `MJS-${option.id} (${option.notes})`}
                                            getOptionValue={option => option.id}
                                            {...rest}
                                        />
                                    )}
                                />
                            </Stack>
                        </Grid>
                        {currentMJS === 302 ? (
                            <Grid item md={12} sm={12} xs={12} display="flex" justifyContent="flex-end">
                                <IconButton
                                    color="primary"
                                    onClick={() => {
                                        append({ ...entry, id: undefined, previous_revision: entry.id || entry.previous_revision });
                                    }}
                                >
                                    <ContentCopyIcon />
                                </IconButton>
                            </Grid>
                        ) : (
                            <>
                                <Grid item md={4} sm={4} xs={12}>
                                    <Label>Date</Label>
                                    <Controller
                                        name={`entryList.${index}.date`}
                                        control={control}
                                        render={({ field: { value, onChange, ...rest } }) => (
                                            <TextField
                                                disabled={!isAdmin}
                                                size="small"
                                                value={dayjs(value).format("YYYY-MM-DD")}
                                                onChange={e => {
                                                    onChange(dayjs(e.currentTarget.value).format("YYYY-MM-DD"));
                                                }}
                                                InputLabelProps={{ shrink: true, sx: { zIndex: 0 } }}
                                                type="Date"
                                                fullWidth
                                                {...rest}
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item md={8} sm={8} xs={12}>
                                    <Label>Time ({totalHours})</Label>
                                    <Stack direction={{ xs: "column", sm: "column", md: "row" }} spacing={1}>
                                        <Controller
                                            name={`entryList.${index}.starting_timestamp`}
                                            control={control}
                                            render={({ field: { value, onChange, ...rest } }) => (
                                                <TextField
                                                    label="Start"
                                                    size="small"
                                                    value={dayjs(value).format("HH:mm")}
                                                    onChange={e => {
                                                        const currentDate = getCurrentDate(currentClockIn, currentClockOut);
                                                        onChange(`${currentDate}T${e.currentTarget.value}`);
                                                    }}
                                                    InputLabelProps={{ shrink: true, sx: { zIndex: 0 } }}
                                                    type="time"
                                                    fullWidth
                                                    {...rest}
                                                />
                                            )}
                                        />
                                        <Controller
                                            name={`entryList.${index}.ending_timestamp`}
                                            control={control}
                                            render={({ field: { value, onChange, ...rest } }) => (
                                                <TextField
                                                    label="End"
                                                    size="small"
                                                    value={dayjs(value).format("HH:mm")}
                                                    onChange={e => {
                                                        const currentDate = getCurrentDate(currentClockIn, currentClockOut);
                                                        onChange(`${currentDate}T${e.currentTarget.value}`);
                                                    }}
                                                    InputLabelProps={{ shrink: true, sx: { zIndex: 0 } }}
                                                    type="time"
                                                    fullWidth
                                                    {...rest}
                                                />
                                            )}
                                        />
                                        <IconButton
                                            color="primary"
                                            onClick={() => {
                                                append({ ...entry, id: undefined, previous_revision: entry.id || entry.previous_revision });
                                            }}
                                        >
                                            <ContentCopyIcon />
                                        </IconButton>
                                    </Stack>
                                </Grid>
                            </>
                        )}
                    </Grid>
                </CardContent>
            </Card>
        </Grid>
    );
};

const getCurrentDate = (start, end) => {
    if (!start && !end) {
        return dayjs(new Date()).format("YYYY-MM-DD");
    }
    let currentDate = start ? start : end;
    return dayjs(currentDate).format("YYYY-MM-DD");
};

export default TimecloudEntry;
