import React, { useEffect, useState, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Card, CardContent, Typography, IconButton } from "@mui/material";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import CircularProgress from "@mui/material/CircularProgress";
import { makeStyles } from "@mui/styles";
import DueDate from "./DueDate";
import { actionCreators as jobActions } from "redux/modules/jobs";
import { axiosInstance } from "shared/axiosInst";
import FollowupNote from "./FollowupNote";
import dayjs from "shared/dayjs";
import MouseOverPopover from "./MouseOverPopover";

const useStyles = makeStyles(theme => ({
    cardContentRoot: {
        "&:last-child": {
            paddingBottom: "16px"
        }
    },
    replyCounter: {
        whiteSpace: "nowrap",
        position: "absolute",
        top: "50%",
        transform: "translateY(-12px)",
        right: "15px",
        [theme.breakpoints.down("xs")]: {
            top: "24%",
            transform: "translateY(0)"
        }
    }
}));

const CollapsedNote = ({ log, onEditClick, onReplyClick, type = null, opened = false }) => {
    const classes = useStyles();
    const [isOpen, setIsOpen] = useState(opened);
    const [noteDetail, setNoteDetail] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const {
        user: { userId, currentCompany },
        jobs: { openedLogId }
    } = useSelector(state => state);
    const dispatch = useDispatch();
    const assignedToMe = userId === log.assigned_to;
    const getLog = useCallback(async () => {
        const logResponse = await axiosInstance.get(`${currentCompany}job/job_note/${log.note}/`);
        setIsLoading(false);
        const detail = logResponse.data;
        setNoteDetail({
            ...detail,
            reply_details: detail?.reply_details.sort((a, b) => a.id - b.id)
        });
    }, [currentCompany, log.note]);
    useEffect(() => {
        if (isOpen && !noteDetail) {
            setIsLoading(true);
            getLog();
        }
    }, [isOpen, noteDetail, getLog]);
    useEffect(() => {
        const setTime = dayjs(log.due_date);
        const current = dayjs(new Date());
        const diff = setTime.diff(current, "days", true);
        if (diff < 2 && diff > 0 && type === "dashboard") {
            setIsOpen(true);
        }
    }, [log.due_date, type]);
    useEffect(() => {
        if (noteDetail?.reply_details && log.note_reply_count !== noteDetail?.reply_details.length) {
            getLog();
        }
    }, [log.note_reply_count, noteDetail?.reply_details, getLog]);
    useEffect(() => {
        if (openedLogId && openedLogId === log.note) {
            setIsOpen(true);
            dispatch(jobActions.setLogOpened(null));
        }
    }, [openedLogId, log.note, dispatch]);
    const openClick = e => {
        setIsOpen(current => !current);
    };

    return (
        <Card sx={{ position: "relative", overflow: "inherit" }} className={`${assignedToMe ? "lime lighten-5" : ""} mb-2`} variant="outlined">
            {isOpen && noteDetail ? (
                <FollowupNote log={noteDetail} type={type} openClick={openClick} onEditClick={onEditClick} onReplyClick={onReplyClick} />
            ) : (
                <CardContent className={classes.cardContentRoot}>
                    <div className="d-sm-flex align-items-center">
                        <div className="d-flex align-items-center">
                            <IconButton onClick={openClick} disabled={isLoading} size="small" color="primary">
                                {isLoading ? <CircularProgress size={24} /> : <KeyboardArrowDownOutlinedIcon />}
                            </IconButton>
                            <DueDate collapsed={true} time={log.due_date} />
                        </div>
                        <MouseOverPopover text={log.note_text} />
                    </div>
                    {log.note_reply_count > 0 && (
                        <Typography variant="button" className={classes.replyCounter}>
                            {log.note_reply_count} {`${log.note_reply_count > 1 ? "replies" : "reply"}`}
                        </Typography>
                    )}
                </CardContent>
            )}
        </Card>
    );
};

export default CollapsedNote;
