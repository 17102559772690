import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { actionCreators as hrActions } from "redux/modules/humanResources";
import dayjs from "shared/dayjs";
import { getColumns, getStartEndDate, getContainersWidth, addNewEvents } from "./AdminCalendarUtils";
import {
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    Stack,
    TableRow,
    TextField,
    Button,
    FormControl,
    InputLabel,
    Select,
    OutlinedInput,
    Box,
    Chip,
    MenuItem,
    CircularProgress,
    Typography,
    IconButton
} from "@mui/material";
import AdminCalendarRow from "./AdminCalendarRow";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP
        }
    }
};
const SUBTRACT_DAYS = 30;
const ADD_DAYS = 37;
const CELL_WIDTH = 35;
const WEEK_DAYS = 7;

const AdminCalendar = () => {
    const dispatch = useDispatch();
    const today = new Date();
    const [events, setEvents] = useState([]);
    const [selectedUsers, setSelectedUSers] = useState([]);
    const [showingUsers, setShowingUsers] = useState(events);
    const [currentDate, setCurrentDate] = useState(today);
    const [searchDate, setSearchDate] = useState("");
    const [datePeriod, setDatePeriod] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [columns, setColumns] = useState([]);

    const addColumnAndPeriod = ({ startDate, endDate, position }) => {
        const start = position === "next" ? datePeriod[0].full : startDate;
        const end = position === "prev" ? datePeriod[datePeriod.length - 1].full : endDate;
        const newColumns = getColumns({ startDate: start, endDate: end });
        setColumns(newColumns);
        const newPeriod = getStartEndDate({ startDate, endDate }).map(dt => ({
            id: dt.id,
            label: (
                <>
                    <div>{dt.dt}</div>
                    <div>{dt.day}</div>
                </>
            ),
            align: "center",
            full: dt.fullDate
        }));
        const newDatePeriod = position === "next" ? [...datePeriod, ...newPeriod] : [...newPeriod, ...datePeriod];
        setDatePeriod(newDatePeriod);
    };

    useEffect(() => {
        if (selectedUsers.length > 0) {
            setShowingUsers(selectedUsers);
        } else {
            setShowingUsers(events);
        }
    }, [selectedUsers, events]);

    useEffect(() => {
        const startDate = dayjs(currentDate).subtract(SUBTRACT_DAYS, "day");
        const endDate = dayjs(currentDate).add(ADD_DAYS, "day");
        const fetchData = async () => {
            const newColumns = getColumns({ startDate, endDate });
            setColumns(newColumns);
            setDatePeriod(
                getStartEndDate({ startDate, endDate }).map(dt => ({
                    id: dt.id,
                    label: (
                        <>
                            <div>{dt.dt}</div>
                            <div>{dt.day}</div>
                        </>
                    ),
                    align: "center",
                    full: dt.fullDate
                }))
            );
            setIsLoading(true);
            const leaveEvents = await dispatch(hrActions.getStaffLeaveEvents({ startDate, endDate }));
            setEvents(leaveEvents);
            const { tableContainer, calendarTable } = getContainersWidth();
            const positionLeft = calendarTable.clientWidth / 2 - tableContainer.clientWidth / 2;
            tableContainer.scrollTo({
                left: positionLeft
            });
            setIsLoading(false);
        };
        fetchData();
    }, [currentDate, dispatch]);

    const onSelectUser = event => {
        const {
            target: { value }
        } = event;
        setSelectedUSers(value);
    };

    const handleDelete = value => {
        setSelectedUSers(selectedUsers.filter(user => user.id !== value.id));
    };

    const onSearchDateChange = event => {
        const {
            target: { value }
        } = event;
        setSearchDate(value);
    };

    const onGoClick = event => {
        searchDate && setCurrentDate(searchDate);
    };

    const onScroll = async event => {
        const element = event.target;
        if (!isLoading) {
            if (element.scrollWidth - element.scrollLeft - element.clientWidth <= 1) {
                setIsLoading(true);
                const lastDayofPeriod = datePeriod[datePeriod.length - 1];
                const startDate = dayjs(lastDayofPeriod.full).add(1, "day");
                const endDate = dayjs(startDate).add(ADD_DAYS, "day");
                const leaveEvents = await dispatch(hrActions.getStaffLeaveEvents({ startDate, endDate }));
                addColumnAndPeriod({ startDate, endDate, position: "next" });
                setEvents(current => addNewEvents(current, leaveEvents));
                setIsLoading(false);
            } else if (element.scrollLeft === 0) {
                setIsLoading(true);
                const fisrtDayofPeriod = datePeriod[0];
                const endDate = dayjs(fisrtDayofPeriod.full).subtract(1, "day");
                const startDate = dayjs(endDate).subtract(ADD_DAYS, "day");
                const leaveEvents = await dispatch(hrActions.getStaffLeaveEvents({ startDate, endDate }));
                addColumnAndPeriod({ startDate, endDate, position: "prev" });
                setEvents(current => addNewEvents(current, leaveEvents));
                const { tableContainer } = getContainersWidth();
                tableContainer.scrollTo({
                    left: CELL_WIDTH * (ADD_DAYS + 1)
                });
                setIsLoading(false);
            }
        }
    };

    const movePeriod = direction => {
        const { tableContainer } = getContainersWidth();
        const scrollTo =
            direction === "prev" ? tableContainer.scrollLeft - CELL_WIDTH * WEEK_DAYS : tableContainer.scrollLeft + CELL_WIDTH * WEEK_DAYS;
        tableContainer.scrollTo({
            left: scrollTo
        });
    };

    return (
        <>
            <Stack
                direction={{ sm: "column", md: "row" }}
                spacing={2}
                sx={{ margin: "0.5rem", justifyContent: "space-between", alignItems: "center" }}
            >
                <FormControl sx={{ my: 1, maxWidth: 450 }} fullWidth size="small">
                    <InputLabel id="select-users">Select Users</InputLabel>
                    <Select
                        labelId="select-users"
                        id="select-user-box"
                        onChange={onSelectUser}
                        value={selectedUsers}
                        multiple
                        input={<OutlinedInput id="select-multiple-chip" label="select-users" />}
                        renderValue={selected => (
                            <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                                {selected.map(value => (
                                    <Chip
                                        key={value.id}
                                        size="small"
                                        label={`${value.first_name} ${value.last_name}`}
                                        onDelete={() => handleDelete(value)}
                                        onMouseDown={event => {
                                            event.stopPropagation();
                                        }}
                                    />
                                ))}
                            </Box>
                        )}
                        MenuProps={MenuProps}
                    >
                        {events.map(user => (
                            <MenuItem key={user.id} value={user}>
                                {user.first_name} {user.last_name}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <Stack direction="row" spacing={0.5}>
                    <IconButton onClick={() => movePeriod("prev")} disabled={isLoading}>
                        <ArrowBackIcon />
                    </IconButton>
                    <Stack direction="row" spacing={0.5}>
                        <TextField
                            label="Search Date"
                            size="small"
                            type="date"
                            onChange={onSearchDateChange}
                            value={searchDate}
                            InputLabelProps={{
                                shrink: true
                            }}
                            sx={{ display: { xs: "none", sm: "none", md: "block" } }}
                        />
                        <Button
                            variant="contained"
                            onClick={onGoClick}
                            disabled={isLoading}
                            sx={{ display: { xs: "none", sm: "none", md: "block" } }}
                        >
                            GO
                        </Button>
                        <Button variant="contained" color="secondary" disabled={isLoading} onClick={() => setCurrentDate(today)}>
                            TODAY
                        </Button>
                    </Stack>
                    <IconButton onClick={() => movePeriod("next")} disabled={isLoading}>
                        <ArrowForwardIcon />
                    </IconButton>
                </Stack>
            </Stack>
            <Paper sx={{ width: "100%", overflow: "hidden", border: "1px solid rgb(212 212 212)", position: "relative" }}>
                <TableContainer sx={{ maxHeight: 600, overflowX: isLoading ? "hidden" : "auto" }} id="table-container" onScroll={onScroll}>
                    <Table size="small" sx={{ borderCollapse: "separate", borderSpacing: 0 }} id="calendar-table">
                        <TableHead sx={{ position: "sticky", top: 0, zIndex: 3, background: "white" }}>
                            <TableRow>
                                <TableCell
                                    sx={{
                                        minWidth: 170,
                                        position: "sticky",
                                        zIndex: 3,
                                        borderRight: "1px solid #cacaca",
                                        left: 0,
                                        background: "white"
                                    }}
                                    rowSpan="2"
                                >
                                    Name
                                </TableCell>
                                {columns.map(column => (
                                    <TableCell key={column.id} align={column.align} sx={column.style} colSpan={column.colspan}>
                                        {column.label}
                                    </TableCell>
                                ))}
                            </TableRow>
                            <TableRow>
                                {datePeriod.map(column => (
                                    <TableCell
                                        key={column.id}
                                        align={column.align}
                                        sx={{
                                            fontSize: "0.7rem",
                                            lineHeight: "1rem",
                                            letterSpacing: 0,
                                            maxWidth: 32,
                                            borderRight: "1px solid rgba(224, 224, 224, 1)",
                                            padding: "3px",
                                            background: "white",
                                            minWidth: "35px"
                                        }}
                                    >
                                        {column.label}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {showingUsers.map(row => (
                                <AdminCalendarRow key={row.id} row={row} datePeriod={datePeriod} events={events} />
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                {isLoading && (
                    <Box
                        sx={{
                            width: "100%",
                            height: "100%",
                            position: "absolute",
                            top: 0,
                            left: 0,
                            zIndex: 3,
                            background: "rgba(255, 255, 255, 0.5)",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center"
                        }}
                    >
                        <Box sx={{ display: "flex", margin: "0.5rem", alignItems: "center" }}>
                            <CircularProgress size={20} />
                            <Typography variant="button" sx={{ margin: "8px 5px", color: "gray" }}>
                                Loading...
                            </Typography>
                        </Box>
                    </Box>
                )}
            </Paper>
        </>
    );
};

export default AdminCalendar;
