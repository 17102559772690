import React, { memo, useState, useEffect, useMemo } from "react";
import AttributeElement from "components/Crm/EntityDetail/AttributeElement";
import SelectComponent from "components/Crm/EntityDetail/SelectComponent";
import dayjs from "shared/dayjs";
import { MDBCard, MDBCardHeader, MDBCardBody, MDBRow, MDBCol, MDBTable, MDBTableHead, MDBTableBody, MDBBtn } from "louis-mdbreact";
import { useSelector, useDispatch } from "react-redux";
import { actionCreators as crmActions } from "redux/modules/crm";
import { getWorkerCommonRefined } from "redux/reducers";

import styles from "shared/details.scss";

const WorkerAttributes = ({ type, getSendingData, role, onEditClick, entityDetail }) => {
    const [sendingData, setSendingData] = useState({
        skill: [],
        qualification: []
    });

    const dispatch = useDispatch();
    const state = useSelector(state => state);
    const { skill: skills, qualification: qualifications } = useMemo(() => getWorkerCommonRefined(state), [state]);

    useEffect(() => {
        dispatch(crmActions.getWorkerAttrCommons());
    }, [dispatch]);

    const names = useMemo(() => {
        return [
            { idx: 1, url: "entity_skill_link", name: "skill" },
            {
                idx: 2,
                url: "entity_qualification_link",
                name: "qualification"
            }
        ];
    }, []);

    useEffect(() => {
        const getAttributes = () => {
            const { skill_details, qualification_details } = entityDetail;
            const attributesResponse = [skill_details, qualification_details];

            setSendingData(sending => {
                return attributesResponse.reduce((obj, item, i) => {
                    return {
                        ...obj,
                        [names[i].name]: item.map(it => ({
                            ...it,
                            id: it[names[i].name],
                            originalId: it.id,
                            expiration_date: names[i].name === "qualification" ? it.expiration_date || "" : undefined
                        }))
                    };
                }, sending);
            });
        };

        if (entityDetail && entityDetail.id) {
            getAttributes();
        }
    }, [dispatch, entityDetail, names]);

    useEffect(() => {
        type === "new" &&
            Object.keys({ ...sendingData }).forEach(key =>
                getSendingData(
                    key,
                    sendingData[key].map(ele => {
                        return {
                            ...ele,
                            [key]: ele.id,
                            id: undefined,
                            expiration_date: ele.expiration_date || undefined
                        };
                    }),
                    true
                )
            );
    }, [sendingData, getSendingData, type]);

    const onAttributeChanged = (option, selectedState) => {
        let newOption = {};
        switch (selectedState) {
            case "skill":
                newOption = { ...option, rating: 0, last_reviewed: "" };
                break;
            case "qualification":
                newOption = {
                    ...option,
                    qualification_reference: "",
                    expiration_date: dayjs().add(option.validity, "months").format("YYYY-MM-DD")
                };
                break;
            default:
                break;
        }
        const newSendingData = {
            ...sendingData,
            [selectedState]: [...sendingData[selectedState], newOption]
        };

        setSendingData(newSendingData);
    };

    const onRemoveAttribute = (parent, element) => {
        if (element.originalId) {
            const confirm = window.confirm("Are you sure you delete this item completely?");
            if (confirm) {
                dispatch(crmActions.deleteLink(names.find(name => name.name === parent).url, entityDetail.id, element.originalId));
                const newSendingData = {
                    ...sendingData,
                    [parent]: sendingData[parent].filter(ele => ele.id !== element.id)
                };
                setSendingData(newSendingData);
            }
        } else {
            const newSendingData = {
                ...sendingData,
                [parent]: sendingData[parent].filter(ele => ele.id !== element.id)
            };
            setSendingData(newSendingData);
        }
    };

    const submitLink = (parent, element) => {
        setSendingData({
            ...sendingData,
            [parent]: sendingData[parent].map(ele => (ele.id === element.id ? { ...ele, isLoading: true } : ele))
        });
        dispatch(
            crmActions.submitLink(names.find(name => name.name === parent).url, entityDetail.id, {
                ...element,
                [parent]: element.id,
                id: element.originalId || undefined,
                expiration_date: parent === "qualification" ? element.expiration_date || undefined : undefined
            })
        );
    };

    const onInputChange = (event, attributeName, id) => {
        const {
            target: { name, value }
        } = event;
        setSendingData({
            ...sendingData,
            [attributeName]: sendingData[attributeName].map(attr => (attr.id === id ? { ...attr, [name]: value } : attr))
        });
    };

    const onRatingChanged = event => {
        const {
            target: { name, value }
        } = event;
        const splitName = name.split("-");
        setSendingData({
            ...sendingData,
            [splitName[0]]: sendingData[splitName[0]].map(element =>
                element.id === Number(splitName[1]) ? { ...element, rating: Number(value) } : element
            )
        });
    };

    const submitWorkerAttribute = event => {
        event.preventDefault();
        dispatch(crmActions.submitAttribute({ sendingData, names, entityId: entityDetail.id }));
        onEditClick(3);
    };

    return (
        <React.Fragment>
            <MDBCard className={`${styles.card} ${type === "view" ? styles.flatCard : ""}`}>
                <MDBCardHeader>Current Worker Attributes</MDBCardHeader>
                <MDBCardBody>
                    <MDBRow>
                        <MDBCol size="12" className="mb-2">
                            <SelectComponent
                                label="Skill"
                                options={
                                    skills &&
                                    skills
                                        .filter(skill => !sendingData.skill.find(selected => selected.id === skill.id))
                                        .filter(
                                            skill =>
                                                skill.linked_service === null ||
                                                role.find(rl => (type === "view" ? rl === skill.linked_service : rl.role === skill.linked_service))
                                        )
                                }
                                onAttributeChanged={onAttributeChanged}
                            />
                            {sendingData.skill.length > 0 && (
                                <MDBTable small className={styles.detailResponsiveTable}>
                                    <MDBTableHead>
                                        <tr>
                                            <th width="30%">Name</th>
                                            <th>Key</th>
                                            <th width="100px" className="text-center">
                                                Action
                                            </th>
                                        </tr>
                                    </MDBTableHead>
                                    <MDBTableBody>
                                        {sendingData.skill.map(skl => (
                                            <AttributeElement
                                                key={skl.id}
                                                parent="skill"
                                                element={skl}
                                                type={type}
                                                onInputChange={onInputChange}
                                                onRatingChanged={onRatingChanged}
                                                onRemoveAttribute={onRemoveAttribute}
                                                submitLink={submitLink}
                                            />
                                        ))}
                                    </MDBTableBody>
                                </MDBTable>
                            )}
                        </MDBCol>
                        <MDBCol size="12" className="mb-2">
                            <SelectComponent
                                label="Qualification"
                                options={
                                    qualifications &&
                                    qualifications.filter(
                                        qualification => !sendingData.qualification.find(selected => qualification.id === selected.id)
                                    )
                                }
                                onAttributeChanged={onAttributeChanged}
                            />
                            {sendingData.qualification.length > 0 && (
                                <MDBTable small className={styles.detailResponsiveTable}>
                                    <MDBTableHead>
                                        <tr>
                                            <th>Name</th>
                                            <th width="35%">Reference</th>
                                            <th width="35%">Expiration</th>
                                            <th width="100px" className="text-center">
                                                Action
                                            </th>
                                        </tr>
                                    </MDBTableHead>
                                    <MDBTableBody>
                                        {sendingData.qualification.map(qual => (
                                            <AttributeElement
                                                key={qual.id}
                                                parent="qualification"
                                                element={qual}
                                                type={type}
                                                onInputChange={onInputChange}
                                                onRemoveAttribute={onRemoveAttribute}
                                                submitLink={submitLink}
                                            />
                                        ))}
                                    </MDBTableBody>
                                </MDBTable>
                            )}
                        </MDBCol>
                    </MDBRow>
                </MDBCardBody>
            </MDBCard>
            {type === "view" && (
                <MDBRow>
                    <MDBCol>
                        <div className={`${styles.card} float-right`}>
                            <MDBBtn type="button" color="blue-grey" onClick={() => onEditClick(3)}>
                                cancel
                            </MDBBtn>{" "}
                            <MDBBtn type="button" color="primary" onClick={submitWorkerAttribute}>
                                Save
                            </MDBBtn>
                        </div>
                    </MDBCol>
                </MDBRow>
            )}
        </React.Fragment>
    );
};

export default memo(WorkerAttributes);
