import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import LeaveList from "components/HumanResources/LeaveList";
import LeaveRequest from "components/HumanResources/LeaveRequest";
import LeaveForm from "components/HumanResources/LeaveForm";
import LeaveCalendar from "components/HumanResources/LeaveCalendar";
import LeaveReport from "components/HumanResources/LeaveReport";
import { useSelector } from "react-redux";

const HRRoute = () => {
    const { permissions } = useSelector(state => state.user);
    return (
        <Switch>
            <Route exact path="/humanresources" component={LeaveList} />
            <Route exact path="/humanresources/leaverequest" component={LeaveList} />
            <Route path="/humanresources/leaverequest/new" key="new" component={LeaveRequest} />
            <Route exact path="/humanresources/leaverequest/:id" key="view" component={LeaveForm} />
            <Route exact path="/humanresources/leavecalendar" component={LeaveCalendar} />
            {permissions.hr === 4 ? (
                <Route exact path="/humanresources/leavereport" component={LeaveReport} />
            ) : (
                <Redirect from="/humanresources/leavereport" to="/humanresources" />
            )}
        </Switch>
    );
};

export default HRRoute;
