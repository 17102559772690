import React from "react";
import { useDispatch } from "react-redux";
import { actionCreators as userActions } from "redux/modules/user";
import { Typography, Box } from "@mui/material";
import dayjs from "shared/dayjs";

const TITLE_COLOUR = "#82b1ff";

const Message = ({ list }) => {
    const dispatch = useDispatch();
    const regions = JSON.parse(localStorage.getItem("regions")) || dispatch(userActions.getUserCommons(true));
    return (
        <Box>
            <Typography gutterBottom fontSize="0.8rem">
                {list.id}-{regions.find(region => region.id === list.region).region_short_code}
                <br />({list.job_name})
            </Typography>
            <Typography fontSize="0.8rem">
                <strong style={{ color: TITLE_COLOUR }}>CLIENT</strong> : {list.client_string}
            </Typography>
            {(list.first_required_date || list.last_required_date) && (
                <Typography fontSize="0.8rem">
                    <strong style={{ color: TITLE_COLOUR }}>DATES</strong> :{" "}
                    {list.first_required_date && dayjs(list.first_required_date).format("DD/MM/YYYY")}
                    {list.first_required_date && list.last_required_date && " ~ "}
                    {list.last_required_date && dayjs(list.last_required_date).format("DD/MM/YYYY")}
                </Typography>
            )}
            <Typography fontSize="0.8rem">
                <strong style={{ color: TITLE_COLOUR }}>QS</strong> : {list.internal_qs_string || "-"}
            </Typography>
            <Typography fontSize="0.8rem">
                <strong style={{ color: TITLE_COLOUR }}>SUPERVISOR</strong> : {list.internal_supervisor_string || "-"}
            </Typography>
            <Typography fontSize="0.8rem">
                <strong style={{ color: TITLE_COLOUR }}>SURVEYOR</strong> : {list.internal_surveyor_string || "-"}
            </Typography>
            {/* {list.request_message && (
        <Typography fontSize="0.8rem">
            <strong style={{ color: TITLE_COLOUR }}>REQUEST MESSAGE</strong> : {list.request_message}
        </Typography>
    )}
    {list.staff_instruction && (
        <Typography fontSize="0.8rem">
            <strong style={{ color: TITLE_COLOUR }}>STAFF INSTRUCTION</strong> : {list.staff_instruction}
        </Typography>
    )} */}
        </Box>
    );
};

export default Message;
