import React from "react";
import {
    MDBCard,
    MDBCardBody,
    MDBCardHeader,
    MDBRow,
    MDBCol,
    MDBInputGroup,
    MDBIcon,
    MDBBtn,
    MDBTable,
    MDBTableHead,
    MDBTableBody
} from "louis-mdbreact";
import styles from "shared/details.scss";
import toolboxStyles from "./styles.scss";
import VaildSelect from "components/ValidSelect";
import { AddItem, FileItem } from "components/Ops/AddItem";

const ToolboxTalk = ({
    isLoading,
    type,
    formData: { form_date },
    isDisabled,
    form_content,
    onResponseChange,
    onCommentChange,
    onDateChange,
    onFormDateChange,
    totalAttendeeList,
    attendeeList,
    onAttendeesChange,
    openAttendeeSignModal,
    removeAttendee,
    onAddOther,
    files,
    existedFiles,
    onFileChange,
    removeItem,
    fileClickTrigger,
    downloadImage
}) => {
    const formResponseOptions = JSON.parse(localStorage.getItem("qaResponseOption"));
    return (
        <>
            <MDBCard className={styles.card}>
                <MDBCardHeader>
                    {type.name}{" "}
                    {isLoading ? (
                        <div className="spinner-border spinner-border-sm text-primary" role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                    ) : undefined}
                </MDBCardHeader>
                <MDBCardBody>
                    <MDBRow>
                        <MDBCol className="mb-2">
                            <label>Date</label>
                            <input
                                type="date"
                                className="form-control"
                                name="form_date"
                                value={form_date}
                                onChange={onFormDateChange}
                                disabled={isDisabled}
                            />
                        </MDBCol>
                    </MDBRow>
                    <label>Attendees</label>
                    <MDBRow>
                        <MDBCol className={styles.withBtn}>
                            <VaildSelect
                                options={totalAttendeeList.filter(attendee => !attendee.isLagacy)}
                                onChange={onAttendeesChange}
                                value=""
                                isDisabled={!isDisabled}
                            />
                            <MDBBtn className={styles.fitBtn} onClick={onAddOther} color="primary" disabled={isDisabled}>
                                add other
                            </MDBBtn>
                        </MDBCol>
                    </MDBRow>
                    <ul className={toolboxStyles.attendeeList}>
                        {attendeeList
                            .filter(attendee => !attendee.isRemoved)
                            .map(attendee => (
                                <li
                                    key={attendee.timestamp}
                                    className={`${attendee.id ? toolboxStyles.existedAttendee : ""} ${
                                        isDisabled ? toolboxStyles.disabledAttendee : ""
                                    }`}
                                >
                                    <div className={toolboxStyles.attendeeNameContainer}>
                                        <span
                                            className={attendee.isSubcontractor ? toolboxStyles.subcontractorName : ""}
                                            onClick={e => openAttendeeSignModal(e, attendee)}
                                        >
                                            {attendee.label || attendee.attendee_name}
                                        </span>
                                    </div>
                                    {!isDisabled && (
                                        <button type="button" onClick={() => removeAttendee(attendee)}>
                                            <MDBIcon far icon="trash-alt" />
                                        </button>
                                    )}
                                </li>
                            ))}
                    </ul>
                    <hr />
                    {form_content &&
                        form_content.map(element => (
                            <MDBRow key={element.element}>
                                <MDBCol className="mb-2">
                                    <label>{element.form_element_string}</label>
                                    <div>
                                        {element.limit_responses_to.map(response => (
                                            <div key={response} className="custom-control custom-radio custom-control-inline">
                                                <input
                                                    type="radio"
                                                    className="custom-control-input"
                                                    checked={response === Number(element.response)}
                                                    name={element.element}
                                                    value={response}
                                                    id={`option_${element.element}_${response}`}
                                                    onChange={onResponseChange}
                                                    disabled={isDisabled}
                                                    required
                                                />
                                                <label htmlFor={`option_${element.element}_${response}`} className="custom-control-label">
                                                    {formResponseOptions.find(option => option.id === response).name}
                                                </label>
                                            </div>
                                        ))}
                                    </div>
                                    {element.include_free_response ? (
                                        <textarea
                                            rows="4"
                                            className="form-control form-control-sm"
                                            name={element.element}
                                            value={element.comment}
                                            onChange={onCommentChange}
                                            disabled={isDisabled}
                                            required
                                        ></textarea>
                                    ) : undefined}
                                    {element.include_relevant_date && (element.comment || Number(element.response) === 1) ? (
                                        <MDBInputGroup
                                            size="sm"
                                            containerClassName="mt-1"
                                            prepend={element.form_element_string === "Employee issues raised" ? "Date to be resolved by" : "Date"}
                                            inputs={
                                                <>
                                                    <input
                                                        type="date"
                                                        className="form-control form-control-sm"
                                                        name={element.element}
                                                        value={element.relevant_date}
                                                        onChange={onDateChange}
                                                        disabled={isDisabled}
                                                    />
                                                </>
                                            }
                                        />
                                    ) : undefined}
                                </MDBCol>
                            </MDBRow>
                        ))}
                    <hr />
                    <MDBRow>
                        <MDBCol>
                            <label htmlFor="addFile">Files</label>
                            <div className="float-right">
                                {!isDisabled && (
                                    <div className="float-right">
                                        <input
                                            type="file"
                                            className="d-none"
                                            multiple
                                            id="file_toolboxtalk"
                                            name="toolboxtalkFile"
                                            onChange={onFileChange}
                                            aria-describedby="file_input_toolbox_talk"
                                        />
                                        <MDBBtn size="sm" color="primary" onClick={fileClickTrigger} name="toolboxtalkFile">
                                            Add File(s)
                                        </MDBBtn>
                                    </div>
                                )}
                            </div>
                            <MDBTable small className={styles.detailResponsiveTable}>
                                <MDBTableHead>
                                    <tr className={styles.textCentre}>
                                        <th>File</th>
                                        <th width="100px">Action</th>
                                    </tr>
                                </MDBTableHead>
                                <MDBTableBody>
                                    {existedFiles.map(file => (
                                        <FileItem key={file.id} {...file} downloadImage={downloadImage} />
                                    ))}
                                    {files.map(file => {
                                        return <AddItem key={file.id} {...file} removeItem={removeItem} />;
                                    })}
                                </MDBTableBody>
                            </MDBTable>
                        </MDBCol>
                    </MDBRow>
                </MDBCardBody>
            </MDBCard>
        </>
    );
};

export default ToolboxTalk;
