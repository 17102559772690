import React, { useCallback, useContext, useEffect, useState } from "react";
import { MDBTable, MDBTableBody, MDBTableHead } from "louis-mdbreact";
import styles from "shared/modalStyles.scss";
import { ModalContext } from "../TaskContext";
import { useSelector } from "react-redux";
import { formatDateToTime, getDistance, timeDiff } from "shared/Utils";
import dayjs from "shared/dayjs";
import { Label } from "components/HumanResources/CustomComponent";
import { Box, CircularProgress, Tooltip } from "@mui/material";
import { axiosInstance } from "shared/axiosInst";
import Decisions from "./Decisions";

const TimecloudHistory = () => {
    const { modalData } = useContext(ModalContext);
    const {
        tasks: { rejectReasons },
        user: { currentCompany }
    } = useSelector(state => state);
    const getRejectReason = useCallback(
        id => {
            return rejectReasons.find(reason => reason.id === id).name;
        },
        [rejectReasons]
    );
    const [historyList, setHistoryList] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    useEffect(() => {
        const getHistoryList = async (totalList, currentItem) => {
            if (currentItem) {
                totalList.push(currentItem);
                if (currentItem.previous_revision) {
                    try {
                        const prevItem = await axiosInstance.get(`${currentCompany}maintenance/time_tracking/${currentItem.previous_revision}/`);
                        await getHistoryList(totalList, prevItem.data);
                    } catch (error) {
                        console.log(error.response);
                    }
                }
            }
            return totalList;
        };

        const getHistory = async () => {
            const historyList = await getHistoryList([], { ...modalData, id: `${modalData.id} (current)` });
            setHistoryList(historyList);
            setIsLoading(false);
        };
        getHistory();
    }, [modalData, currentCompany]);
    return (
        <div>
            <Label>History</Label>
            {isLoading ? (
                <Box display="flex" alignItems="center" justifyContent="center">
                    <CircularProgress />
                </Box>
            ) : (
                <MDBTable small striped style={{ marginBottom: "0" }} className={styles.timeEntryResponsiveTable}>
                    <MDBTableHead>
                        <tr>
                            <th>ID</th>
                            <th>Site</th>
                            <th>MJS</th>
                            <th>Date</th>
                            {modalData.starting_mjs === 302 ? (
                                <>
                                    <th>Job Distance (punch)</th>
                                    <th></th>
                                </>
                            ) : (
                                <>
                                    <th>Time</th>
                                    <th>Hours</th>
                                </>
                            )}
                            <th>Reason</th>
                            <th style={{ textAlign: "center" }}>Decisions</th>
                        </tr>
                    </MDBTableHead>
                    <MDBTableBody>
                        {historyList.map(list => (
                            <tr key={list.id}>
                                <td data-th="ID">{list.id}</td>
                                <td data-th="Site">{list.ending_job_name}</td>
                                <td data-th="MJS">{list.starting_mjs ? `MJS-${list.starting_mjs} (${list.starting_mjs_note})` : "-"}</td>
                                <td data-th="Date">{dayjs(list.starting_timestamp).format("DD/MM/YYYY")}</td>
                                {modalData.starting_mjs === 302 ? (
                                    <td colSpan={2} data-th="Job Distance (punch)">{`${getDistance(list.inter_job_distance)} (${getDistance(
                                        list.inter_punch_distance
                                    )})`}</td>
                                ) : (
                                    <>
                                        <td data-th="Time">
                                            {formatDateToTime(list.starting_timestamp)} ~ {formatDateToTime(list.ending_timestamp)}
                                        </td>
                                        <td data-th="Hours">{timeDiff(list.starting_timestamp, list.ending_timestamp)}</td>
                                    </>
                                )}
                                <td data-th="reason">
                                    {list.last_modified_by_string ? (
                                        <Tooltip
                                            title={`Last Modified by ${list.last_modified_by_string} at ${dayjs(list.last_modified_at).format(
                                                "DD/MM/YYYY LT"
                                            )}`}
                                            disableInteractive
                                        >
                                            <span>{list.reason ? getRejectReason(list.reason) : list.reason_free_text || "-"}</span>
                                        </Tooltip>
                                    ) : (
                                        `${list.reason ? getRejectReason(list.reason) : list.reason_free_text || "-"}`
                                    )}
                                </td>
                                <td data-th="Decisions">
                                    <Decisions decisions={list.decisions} />
                                </td>
                            </tr>
                        ))}
                    </MDBTableBody>
                </MDBTable>
            )}
        </div>
    );
};

export default TimecloudHistory;
