import React, { Component } from "react";

import CreatableSelect from "react-select/creatable";

const components = {
    DropdownIndicator: null
};

const customStyles = {
    container: () => ({ flex: "1 1" })
};

const createOption = label => ({
    label,
    value: label
});

export default class CreatableInputOnly extends Component {
    state = {
        inputValue: ""
    };
    handleChange = value => {
        const { setReportId } = this.props;
        setReportId(value);
    };
    handleInputChange = inputValue => {
        this.setState({ inputValue: inputValue.trim() });
    };
    handleBlur = event => {
        const { setReportId, reportId } = this.props;
        const {
            target: { value: lastValue }
        } = event;
        const checkedValue = reportId || [];
        if (lastValue) {
            this.setState({
                inputValue: ""
            });
            setReportId([...checkedValue, createOption(lastValue)]);
        }
    };
    handleKeyDown = event => {
        const { inputValue } = this.state;
        const { setReportId, reportId } = this.props;
        if (!inputValue) return;
        const checkedValue = reportId || [];
        switch (event.key) {
            case "Enter":
            case "Tab":
                this.setState({
                    inputValue: ""
                });
                setReportId([...checkedValue, createOption(inputValue)]);
                event.preventDefault();
                break;
            default:
                return;
        }
    };
    render() {
        const { reportId } = this.props;
        const { inputValue } = this.state;
        return (
            <CreatableSelect
                styles={customStyles}
                components={components}
                inputValue={inputValue}
                isClearable
                isMulti
                menuIsOpen={false}
                onChange={this.handleChange}
                onInputChange={this.handleInputChange}
                onKeyDown={this.handleKeyDown}
                onBlur={this.handleBlur}
                placeholder="Type number and press tab or enter..."
                value={reportId}
            />
        );
    }
}
