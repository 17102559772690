import React from "react";
import styles from "shared/details.scss";
import { MDBTable, MDBTableBody, MDBTableHead, MDBBtn, MDBIcon } from "louis-mdbreact";

const MultiFileList = props => {
    const { element, downloadImage, togglePreviewPop, fileNoteChange, onFileRemove, names } = props;
    return (
        <tr className={styles.expandedRowContainer}>
            <td
                className={styles.expandedRow}
                style={{
                    padding: "0 20px"
                }}
                colSpan="4"
            >
                <MDBTable className="mb-0">
                    <MDBTableHead>
                        <tr>
                            <th width="30%">File Name</th>
                            <th>Note</th>
                            <th width="40px">Action</th>
                        </tr>
                    </MDBTableHead>
                    <MDBTableBody>
                        {element.associated_files &&
                            element.associated_files.map(list => (
                                <tr key={list.id} className={styles.addItemContainer}>
                                    <td data-th="File Name">{list.file_name}</td>
                                    <td data-th="Note">{list.note || "-"}</td>
                                    <td data-th="Action" className="text-center">
                                        <MDBBtn
                                            className={styles.buttonSize}
                                            name={names}
                                            value={list.stored_file}
                                            onClick={downloadImage}
                                            color="success"
                                        >
                                            <MDBIcon icon="arrow-down" />
                                        </MDBBtn>
                                    </td>
                                </tr>
                            ))}
                        {element.files &&
                            element.files.map(file => (
                                <tr key={file.id} className={styles.addItemContainer}>
                                    <td
                                        data-th="File Name"
                                        className={file.src ? styles.previewText : ""}
                                    >
                                        {file.src && (
                                            <button
                                                name={file.f.name}
                                                onClick={togglePreviewPop}
                                                value={file.src}
                                                className={styles.previewImage}
                                                type="button"
                                            >
                                                <MDBIcon
                                                    className={styles.previewIcon}
                                                    far
                                                    icon="image"
                                                />
                                                {
                                                    <img
                                                        src={file.thumb}
                                                        width="100%"
                                                        alt={file.f.name}
                                                    />
                                                }
                                            </button>
                                        )}
                                        <span
                                            style={{
                                                wordBreak: "break-all"
                                            }}
                                        >
                                            {file.f.name}{" "}
                                        </span>
                                    </td>
                                    <td data-th="Note">
                                        <input
                                            type="text"
                                            className="form-control form-control-sm"
                                            value={file.note}
                                            name={`${names}_${file.id}`}
                                            onChange={fileNoteChange}
                                        />
                                    </td>
                                    <td data-th="Action" className="text-center">
                                        <MDBBtn
                                            className={styles.buttonSize}
                                            name={names}
                                            value={file.id}
                                            onClick={onFileRemove}
                                            color="danger"
                                        >
                                            <MDBIcon icon="trash-alt" />
                                        </MDBBtn>
                                    </td>
                                </tr>
                            ))}
                    </MDBTableBody>
                </MDBTable>
            </td>
        </tr>
    );
};

export default MultiFileList;
