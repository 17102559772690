import React, { useState } from "react";
import DialogContainer from "components/DialogContainer";
import { Box, Button, DialogActions, DialogContent, TextField } from "@mui/material";
import Select from "react-select";
import { Label } from "components/HumanResources/CustomComponent";
import { useSelector } from "react-redux";
// import { axiosInstance } from "shared/axiosInst";

const AddNoteModal = ({ open, toggle, onSubmit, stage, users }) => {
    const { welcomeName } = useSelector(state => state.user);
    const [note, setNote] = useState("");
    // const [assignee, setAssignee] = useState();
    const [selectedUsers, setSelectedUsers] = useState([]);
    // const [pinned, setPinned] = useState(false);

    // useEffect(() => {
    //     if (uids && users) {
    //         (async () => {
    //             const { client_uid } = uids;
    //             const res = await axiosInstance.get(`crm/entity_system_association_link/get_default_chaser/?association_reference=${client_uid}`);
    //             const defaultUser = res.data[0]?.default_follow_up_user;
    //             if (defaultUser) {
    //                 setAssignee(users.find(user => user.id === defaultUser));
    //             }
    //         })();
    //     }
    // }, [uids, users]);

    const onAddClick = async event => {
        event.preventDefault();
        onSubmit({
            company: stage.company || "GL",
            pk: Number(stage.id),
            name: welcomeName,
            // assignee,
            notification: selectedUsers.map(user => user.email),
            note
            // pinned: hasPinned ? pinned : undefined
        });
    };
    return (
        <DialogContainer closeOutside={false} open={open} handleClose={toggle} title="Add New Note" fullScreen={false} maxWidth="lg">
            <Box component="form" onSubmit={onAddClick}>
                <DialogContent sx={{ display: "flex", flexDirection: "column", gap: "10px" }}>
                    <TextField
                        multiline
                        value={note}
                        onChange={event => {
                            setNote(event.currentTarget.value);
                        }}
                        inputProps={{ minLength: 6, style: { resize: "vertical" } }}
                        required
                        rows={5}
                        fullWidth
                    />
                    {/* {hasPinned && (
                        <>
                            <Label sx={{ marginBottom: "0" }}>Assigned to</Label>
                            <Select
                                isClearable
                                options={users}
                                menuPosition="fixed"
                                value={assignee}
                                onChange={option => setAssignee(option)}
                                getOptionValue={option => option.email}
                                getOptionLabel={option => option.name_string}
                            />
                        </>
                    )} */}
                    <Label sx={{ marginBottom: "0" }}>Notification to</Label>
                    <Select
                        isMulti
                        isClearable
                        options={users}
                        menuPosition="fixed"
                        value={selectedUsers}
                        onChange={option => setSelectedUsers(option)}
                        getOptionValue={option => option.email}
                        getOptionLabel={option => option.name_string}
                    />
                    {/* {hasPinned && (
                        <FormControlLabel
                            control={
                                <Checkbox
                                    value={pinned}
                                    onChange={() => {
                                        setPinned(!pinned);
                                    }}
                                />
                            }
                            label="Pinned"
                        />
                    )} */}
                </DialogContent>
                <DialogActions>
                    <Button color="error" variant="contained" onClick={toggle}>
                        close
                    </Button>
                    <Button color="primary" variant="contained" type="submit">
                        add
                    </Button>
                </DialogActions>
            </Box>
        </DialogContainer>
    );
};

export default AddNoteModal;
