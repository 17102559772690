import React, { useEffect, memo, useState, useCallback, Fragment } from "react";
import { MDBRow, MDBCol, MDBTable, MDBTableHead, MDBTableBody, MDBBtn, MDBIcon } from "louis-mdbreact";
import CollapsedView from "components/CollapsedView";
import styles from "shared/details.scss";
import { useDispatch } from "react-redux";
import { actionCreators as crmActions } from "redux/modules/crm";
import { axiosInstance } from "shared/axiosInst";
import { Link } from "react-router-dom";
import { formatter } from "shared/Utils";

const SubLists = ({ entitiesData }) => {
    const { relationship_details, assignment_details, responsibilities, id: entityId } = entitiesData || {};
    const dispatch = useDispatch();
    const [subLists, setSubLists] = useState([]);
    useEffect(() => {
        const signal = axiosInstance.CancelToken.source();
        let isMounted = true;
        const getTypes = () => {
            const isClient = !!relationship_details.find(relationship => relationship.name === "Client");
            const isSubcontractor = !!relationship_details.find(relationship => relationship.name.search("Sub Contractor") >= 0);
            const isJobsheet = isSubcontractor && !!assignment_details.find(assignment => assignment.name === "Job Sheet");
            const isClientQS = !!responsibilities.find(responsibility => responsibility.name === "Quantity Surveyor");
            dispatch(crmActions.getSubList(entityId, { isClient, isJobsheet, isClientQS }, signal))
                .then(lists => {
                    isMounted && setSubLists(lists);
                })
                .catch(error => console.log(error));
        };
        getTypes();
        return () => {
            isMounted = false;
            signal.cancel("cancelled by user.");
        };
    }, [relationship_details, assignment_details, entityId, responsibilities, dispatch]);

    const toggleCollapsed = useCallback(idx => {
        setSubLists(currentSubLists =>
            currentSubLists.map((subList, id) => (idx === id ? { ...subList, isCollapsed: !subList.isCollapsed } : subList))
        );
    }, []);

    return subLists && subLists.length > 0
        ? subLists.map((list, i) => (
              <MDBRow className="mt-3" key={i}>
                  <MDBCol>
                      <CollapsedView
                          toggleCollapsed={toggleCollapsed}
                          isCollapsed={list.isCollapsed}
                          idx={i}
                          title={list.name}
                          keyInfo={[{ title: list.name, content: list.list.length }]}
                      >
                          <MDBTable small borderless striped className={styles.detailResponsiveTable}>
                              {list.name === "Jobs" ? (
                                  <Fragment>
                                      <MDBTableHead>
                                          <tr className="orange lighten-3">
                                              <th>No.</th>
                                              <th>Site</th>
                                              <th>Supervisor</th>
                                              <th>QS</th>
                                              <th width="70px">Details</th>
                                          </tr>
                                      </MDBTableHead>
                                      <MDBTableBody>{list.isCollapsed && list.list.map(li => <JobList key={li.id} li={li} />)}</MDBTableBody>
                                  </Fragment>
                              ) : (
                                  <Fragment>
                                      <MDBTableHead>
                                          <tr className="orange lighten-3">
                                              <th>No.</th>
                                              <th>Client</th>
                                              <th>Site</th>
                                              <th>Supervisor</th>
                                              <th>Value</th>
                                              <th>Service</th>
                                              <th width="70px">Details</th>
                                          </tr>
                                      </MDBTableHead>
                                      <MDBTableBody>
                                          {list.isCollapsed &&
                                              list.list.map(li => (
                                                  <tr
                                                      key={li.id}
                                                      className={`${styles.addItemContainer} ${li.closed_value ? "red lighten-4" : ""} ${
                                                          li.original ? "blue lighten-4" : ""
                                                      }`}
                                                  >
                                                      <td data-th="No.">{li.id}</td>
                                                      <td data-th="Client">{li.client}</td>
                                                      <td data-th="Site">{li.site}</td>
                                                      <td data-th="Supervisor">{li.supervisor || "-"}</td>
                                                      <td data-th="Value">
                                                          {li.closed_value ? formatter.format(li.closed_value) : formatter.format(li.value)}
                                                      </td>
                                                      <td data-th="Service">{li.service}</td>
                                                      <td data-th="Details">
                                                          <Link to={`/budget/sheets/${li.id}`}>
                                                              <MDBBtn color="primary" className={`${styles.buttonSize} ${styles.noRound}`}>
                                                                  <MDBIcon icon="info-circle" />
                                                              </MDBBtn>
                                                          </Link>
                                                      </td>
                                                  </tr>
                                              ))}
                                      </MDBTableBody>
                                  </Fragment>
                              )}
                          </MDBTable>
                      </CollapsedView>
                  </MDBCol>
              </MDBRow>
          ))
        : "";
};

const JobList = ({ li }) => {
    let rowClassName;
    if (li.deposit_required) {
        rowClassName = "amber lighten-4";
    } else if (li.business_category_name === "Maintenance") {
        rowClassName = "pink lighten-4";
    } else if (li.under_review) {
        rowClassName = "light-blue lighten-4";
    } else {
        rowClassName = "";
    }

    return (
        <tr className={`${styles.addItemContainer} ${rowClassName}`}>
            <td data-th="No.">{li.number}</td>
            <td data-th="Site">{li.job_name}</td>
            <td data-th="Supervisor">{li.internal_supervisor_string}</td>
            <td data-th="QS">{li.internal_qs_string}</td>
            <td data-th="Details">
                <Link to={`/jobs/${li.id}`}>
                    <MDBBtn color="primary" className={`${styles.buttonSize} ${styles.noRound}`}>
                        {li.fire_on_job ? <MDBIcon className="orange-text" icon="fire" /> : <MDBIcon icon="info-circle" />}
                    </MDBBtn>
                </Link>
            </td>
        </tr>
    );
};

export default memo(SubLists);
