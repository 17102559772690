import React, { useContext, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { actionCreators as jobActions } from "redux/modules/jobs";
import styles from "components/BranchSelect/styles.scss";
import CircularProgress from "@mui/material/CircularProgress";
import { MenuItem, TextField } from "@mui/material";
import { actionCreators as jobsActions } from "redux/modules/jobs";
import { RosterContext } from "../TaskContext";

const BranchSelect = () => {
    const {
        jobs: { branchList },
        user: { homeRegion }
    } = useSelector(state => state);
    const dispatch = useDispatch();
    const { currentBranch, setCurrentBranch, setLoading } = useContext(RosterContext);
    useEffect(() => {
        dispatch(jobActions.getBranchList());
    }, [dispatch]);
    useEffect(() => {
        if (currentBranch) {
            (async () => {
                setLoading && setLoading(true);
                await dispatch(jobsActions.getSlimJobs(false, true, { region__fire_areas: currentBranch }));
                setLoading && setLoading(false);
            })();
        }
    }, [dispatch, currentBranch, setLoading]);
    useEffect(() => {
        if (homeRegion && branchList) {
            const userReportingArea = branchList
                .filter(branch => branch.id !== 1)
                .find(branch => branch.regions.find(region => region === homeRegion)).id;
            setCurrentBranch(userReportingArea);
        }
    }, [homeRegion, branchList, setCurrentBranch]);
    const setBranchArea = event => {
        const { value } = event.target;
        setCurrentBranch(value);
    };
    return (
        <div className={styles.branchSelect}>
            {branchList && (
                <>
                    <TextField
                        select
                        label="Branch Area"
                        fullWidth
                        size="small"
                        InputLabelProps={{ shrink: true }}
                        value={branchList?.length ? currentBranch : ""}
                        onChange={setBranchArea}
                    >
                        {branchList
                            .sort((a, b) => a.ordering - b.ordering)
                            .map(area => (
                                <MenuItem key={area.id} value={String(area.id)}>
                                    {area.area_name}
                                </MenuItem>
                            ))}
                    </TextField>
                    {branchList.length === 0 && (
                        <div className={styles.loading}>
                            <CircularProgress size={31} />
                        </div>
                    )}
                </>
            )}
        </div>
    );
};

export default BranchSelect;
