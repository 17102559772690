import React, { memo } from "react";
import { MDBCard, MDBCardHeader, MDBBtn, MDBCardBody } from "louis-mdbreact";
import ValidSelect from "components/ValidSelect";

const ClientDetailCard = props => {
    const { type, generateBluesheet, clients, client, onClientChange, isResourceValid, isDisabled, addNewClient } = props;
    return (
        <MDBCard>
            <MDBCardHeader>
                <span className="float-left">Client Details</span>
                {type === "detail" && (
                    <span className="float-right">
                        <MDBBtn color="primary" size="sm" name="add" onClick={generateBluesheet}>
                            Generate Bluesheet
                        </MDBBtn>
                    </span>
                )}
                {type === "new" && (
                    <span className="float-right">
                        <MDBBtn color="primary" size="sm" name="newClient" onClick={addNewClient}>
                            New Client
                        </MDBBtn>
                    </span>
                )}
            </MDBCardHeader>
            <MDBCardBody>
                <label htmlFor="clientSelection">Client</label>
                <ValidSelect
                    options={clients}
                    onChange={onClientChange}
                    value={client}
                    isValid={isResourceValid}
                    isDisabled={isDisabled}
                    name="clientSelection"
                />
            </MDBCardBody>
        </MDBCard>
    );
};

export default memo(ClientDetailCard);
