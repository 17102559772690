import React from "react";
import styles from "shared/headNavStyles.scss";

import JobDependPermission from "./JobDependPermission";
import TaskDependPermission from "./TaskDependPermission";
import OperationDependPermission from "./OperationDependPermission";
import HRDependPermission from "./HRDependPermission";
import CRMDependPermission from "./CRMDependPermission";
import DependingMenu from "./DependingMenu";
import StockDependPermission from "./StockDependPermission";

const Navigation = props => {
    const { isShow } = props;
    return (
        <div className={`${styles.navContainer} ${isShow ? styles.mainNavShow : ""}`}>
            <Menu {...props} />
        </div>
    );
};

const Menu = props => {
    const { pathname, permissions, toggleNavbar, isSLT, timeToggle } = props;
    if (pathname.match(/jobs/)) {
        return (
            <DependingMenu title="Jobs">
                <JobDependPermission match={pathname} isSLT={isSLT} permissionLevel={permissions.job} toggleNavbar={toggleNavbar} />
            </DependingMenu>
        );
    } else if (pathname.match(/tasks/)) {
        return (
            <DependingMenu title="Rostering">
                <TaskDependPermission match={pathname} timeToggle={timeToggle} toggleNavbar={toggleNavbar} />
            </DependingMenu>
        );
    } else if (pathname.match(/operations/)) {
        return (
            <DependingMenu title="Operations">
                <OperationDependPermission match={pathname} permissionLevel={permissions.operations} toggleNavbar={toggleNavbar} />
            </DependingMenu>
        );
    } else if (pathname.match(/humanresources/)) {
        return (
            <DependingMenu title="HR">
                <HRDependPermission toggleNavbar={toggleNavbar} />
            </DependingMenu>
        );
    } else if (pathname.match(/crm/)) {
        return (
            <DependingMenu title="CRM">
                <CRMDependPermission toggleNavbar={toggleNavbar} />
            </DependingMenu>
        );
    } else if (pathname.match(/stock/)) {
        return (
            <DependingMenu title="Stock">
                <StockDependPermission match={pathname} permissionLevel={permissions.stock} toggleNavbar={toggleNavbar} />
            </DependingMenu>
        );
    } else {
        return (
            <DependingMenu title="Jobs">
                <JobDependPermission match={pathname} isSLT={isSLT} permissionLevel={permissions.job} toggleNavbar={toggleNavbar} />
            </DependingMenu>
        );
    }
};

export default Navigation;
