// import
import { axiosInstance } from "shared/axiosInst";
import { push } from "react-router-redux";
import { filterToString, getFileData } from "shared/Utils";
import _ from "lodash";
import { actionCreators as userAction } from "redux/modules/user";
import { actionCreators as crmActions } from "redux/modules/crm";
import pLimit from "p-limit";
import { limitRequest } from "shared/Utils";

// valiables

// actions
const SLIM_JOBS = "SLIM_JOBS";
const SLIM_JOBS_NO_DIARY = "SLIM_JOBS_NO_DIARY";
const SLIM_JOBS_FOR_QA = "SLIM_JOBS_FOR_QA";
const JOB_REQUESTED = "JOB_REQUESTED";
const SET_BASE_DATA_FOR_DETAIL = "SET_BASE_DATA_FOR_DETAIL";
const SET_CLIENT_CONTACT_DETAIL = "SET_CLIENT_CONTACT_DETAIL";
const REMOVE_CLIENT_CONTACT = "REMOVE_CLIENT_CONTACT";
const SET_DETAIL_JOB = "SET_DETAIL_JOB";
const SET_NEW_CLIENT_BASE_DATA = "SET_NEW_CLIENT_BASE_DATA";
const ADD_NEW_CLIENT = "ADD_NEW_CLIENT";
const ADD_NEW_CLIENT_CONTACT_DETAIL = "ADD_NEW_CLIENT_CONTACT_DETAIL";
const CHANGE_EDITED_ADDRESS = "CHANGE_EDITED_ADDRESS";
const SET_FULL_ADDRESS = "SET_FULL_ADDRESS";
const SET_BRANCH_LIST = "SET_BRANCH_LIST";
const SET_CURRENT_BRANCH = "SET_CURRENT_BRANCH";
const SET_LNG_LAT = "SET_LNG_LAT";
const SET_FOLLOW_UP_LIST = "SET_FOLLOW_UP_LIST";
const SET_LOG_LIST = "SET_LOG_LIST";
const SET_LOG_OPENED = "SET_LOG_OPENED";
const SET_JOB_VIEW = "SET_JOB_VIEW";

// API actions
function getSlimJobs(isMine, isCurrent, filter = {}) {
    return async (dispatch, getState) => {
        const {
            user: { currentCompany }
        } = getState();
        let url = "";
        const filterString = filterToString(filter, ["isAdminOpen", "allocated"]);
        switch (isMine) {
            case true:
                url = isCurrent ? `${currentCompany}job/slim_job/my_current/${filterString}` : `${currentCompany}job/slim_job/my/${filterString}`;
                break;
            case false:
                url = isCurrent ? `${currentCompany}job/slim_job/current/${filterString}` : `${currentCompany}job/slim_job/${filterString}`;
                break;
            default:
                url = `${currentCompany}job/slim_job/${filterString}`;
        }
        const response = await axiosInstance.get(url);
        let jobList = response.data;
        if (filter.allocated !== undefined && filter.allocated !== "") {
            jobList = jobList.filter(job => {
                const isTrue = filter.allocated === "true";
                return job.allocated === isTrue;
            });
        }
        dispatch(setSlimJobs(jobList));
        return jobList;
    };
}

function getSlimJobsForQA() {
    return async (dispatch, getState) => {
        const {
            user: { currentCompany }
        } = getState();
        // if (resourcesList.length === 0) {
        //     resources = await dispatch(userAction.getResources());
        // } else {
        //     resources = resourcesList;
        // }
        const response = await axiosInstance.get(`${currentCompany}job/slim_job/`);
        const jobListForQA = response.data;
        dispatch(setSlimJobsForQA(jobListForQA));
    };
}

function getSlimJobsNoDiary(date) {
    return async (dispatch, getState) => {
        const {
            user: { currentCompany, isLeadingHand }
        } = getState();
        const response = await axiosInstance.get(`${currentCompany}job/slim_job/${isLeadingHand ? "my_no_diary" : "no_diary"}/${date}/`);
        const jobListNoDiary = response.data;
        dispatch(setSlimJobsNoDiary(jobListNoDiary));
    };
}

function getJobRequested(isMine) {
    return async (dispatch, getState) => {
        const {
            user: { currentCompany, resourcesList }
        } = getState();
        let resources;
        if (resourcesList.length === 0) {
            resources = await dispatch(userAction.getResources());
        } else {
            resources = resourcesList;
        }
        const url = isMine ? `${currentCompany}job/slim_job_request/my/` : `${currentCompany}job/slim_job_request/unapproved/`;
        const response = await axiosInstance.get(url);
        const jobList = response.data.map(list => ({
            ...list,
            resource_string: resources.find(res => res.id === list.resource).legal_name
        }));
        dispatch(setJobRequested(jobList));
    };
}

function getJobBaseData(type = null) {
    return async (dispatch, getState) => {
        const {
            user: { currentCompany }
        } = getState();
        const urls = [`users/qs_users/fire/`, `users/supervisor_users/fire/`, `users/surveyor_users/fire/`, `${currentCompany}job/slim_address/`];

        const [qsUserResponse, supervisorResponse, surveyorResponse, addressResponse] = await Promise.all(urls.map(url => axiosInstance.get(url)));
        let resources;
        let suppliers;
        try {
            const allResource = await dispatch(crmActions.getSlimCurrentEntity());
            resources = allResource.reduce(
                (sp, res) => {
                    let client;
                    if (type === "new") {
                        client = res.current_client || res.pending_client;
                    } else {
                        client = res.relationships.find(relation => relation === "Client");
                    }
                    const resource = {
                        value: res.id,
                        label: res.name,
                        searchText: `${res.group_associations.join(", ")} ${res.parent_name_string || ""}`,
                        entityType: res.entity_type,
                        isClient: !!client,
                        pending: res.pending,
                        pending_relationship: res.pending_relationship,
                        pending_client: res.pending_client,
                        current_client: res.current_client
                    };
                    client ? sp[0].options.push(resource) : sp[1].options.push(resource);
                    return sp;
                },
                [
                    {
                        label: "Client",
                        options: []
                    },
                    { label: "Non-Client", options: [] }
                ]
            );
            suppliers = await dispatch(crmActions.getSlimCurrentEntity("?roles=1"));
            const baseData = {
                clients: resources,
                suppliers: suppliers,
                fireEngineer: [],
                priceType: JSON.parse(localStorage.getItem("priceType")) || dispatch(getJobCommons(true)),
                jobType: JSON.parse(localStorage.getItem("category")) || dispatch(getJobCommons(true)),
                qsUserData: qsUserResponse.data,
                supervisorData: supervisorResponse.data,
                surveyorData: surveyorResponse.data,
                addressData: addressResponse.data.map(addr => ({
                    value: addr.id,
                    label: addr.address_string
                })),
                serviceData: JSON.parse(localStorage.getItem("service")) || dispatch(getJobCommons(true)),
                qsCheckData: JSON.parse(localStorage.getItem("qsCheck")) || dispatch(getJobCommons(true)),
                // productData:
                //     JSON.parse(localStorage.getItem("product")) || dispatch(getJobCommons(true)),
                fileCategory: JSON.parse(localStorage.getItem("fileCategory")) || dispatch(getJobCommons(true)),
                regions: JSON.parse(localStorage.getItem("regions")) || dispatch(userAction.getUserCommons(true))
            };

            dispatch(setBaseDataForDetail(baseData));
        } catch (error) {
            console.log(error);
        }
    };
}

function getSkinnyJobDetail(id) {
    return async (dispatch, getState) => {
        const {
            user: { currentCompany }
        } = getState();
        const detailResponse = await axiosInstance.get(`${currentCompany}job/slim_job/${id}/skinny/`);
        return detailResponse.data;
    };
}

function getClientContactDetails(option, entityType = null) {
    return async dispatch => {
        let response;
        let responseData;
        response = await dispatch(crmActions.getSlimCurrentEntity(`?parent=${option.value}`));
        responseData = response;
        // if (entityType === "Individual") {
        //     response = await dispatch(crmActions.getSlimCurrentEntity(`?entity_type=5`));
        //     responseData = response;
        // } else {
        //     response = await dispatch(
        //         crmActions.getSlimCurrentEntity(`?parent=${option.value}`)
        //     );
        //     responseData = response;
        // }
        dispatch(setClientContactDetail(responseData));
    };
}

function postJob(data, dataType, files = []) {
    return async (dispatch, getState) => {
        const {
            user: {
                // permissions: { jobs: jobs_permission },
                currentCompany
            }
        } = getState();
        if (data.main_address && data.main_address.value === 0) {
            const { main_address_full } = data;
            const addrResponse = await axiosInstance.post(`${currentCompany}job/address/`, main_address_full);
            const addrResponseData = await addrResponse.data;
            data.main_address = addrResponseData.id;
        } else {
            data.main_address = data.main_address.value;
        }
        let response;
        try {
            if (data.id) {
                response = await axiosInstance.put(`${currentCompany}job/job/${data.id}/`, data);
            } else {
                response = await axiosInstance.post(`${currentCompany}job/job/`, data);
            }
            const responseData = await response.data;
            if (files.length > 0) {
                let jobType;
                if (dataType === "new") {
                    //if this is new task, it depands permission level which is if permission level is 4, it goes to approved otherwise request
                    jobType = "approved_job";
                } else {
                    // if this isn't new task, it depands which page came from which are detail and request.
                    jobType = dataType === "detail" ? "approved_job" : "request_job";
                }
                await Promise.all(
                    files.map(file => {
                        const fd = getFileData(file, jobType, responseData.id);
                        return axiosInstance.post(`${currentCompany}job/stored_file/`, fd);
                    })
                );
            }

            if (data.file && data.file.length > 0) {
                await Promise.all(
                    data.file
                        .filter(file => !file.new_file)
                        .map(file =>
                            axiosInstance.put(`${currentCompany}job/stored_file/${file.id}/`, {
                                id: file.id,
                                file_category: file.file_category
                            })
                        )
                );
            }

            if (responseData.id) {
                switch (dataType) {
                    case "detail":
                        dispatch(push(`/jobs/${responseData.id}`));
                        break;
                    case "new":
                        dispatch(push("/jobs/"));
                        break;
                    case "requested":
                        dispatch(push("/jobs/requested"));
                        break;
                    default:
                        dispatch(push("/jobs/"));
                        break;
                }
            }
        } catch (error) {
            return error;
        }
    };
}

function postFiles({ files, jobType, jobId, uploadProgress = null }) {
    return async (dispatch, getState) => {
        const {
            user: { currentCompany }
        } = getState();
        const totalFileSize = files.reduce((total, file) => {
            return file.new_file.size + total;
        }, 0);
        const limit = pLimit(limitRequest);
        let currentLoadedSize = 0;
        return await Promise.all(
            files.map(file => {
                const fd = getFileData(file, jobType, jobId);
                return limit(() =>
                    axiosInstance.post(`${currentCompany}job/stored_file/`, fd, {
                        onUploadProgress: ProgressEvent => {
                            const { loaded, total } = ProgressEvent;
                            if (loaded === total) {
                                currentLoadedSize = currentLoadedSize + loaded;
                            }
                            uploadProgress && uploadProgress(Math.round((currentLoadedSize / totalFileSize) * 100));
                        }
                    })
                );
            })
        );
    };
}

function markComplete({ id, date }) {
    return async (dispatch, getState) => {
        const {
            user: { currentCompany },
            jobs: { detailData }
        } = getState();
        const newJobData = await axiosInstance.post(`${currentCompany}job/job/${id}/mark_complete/`, {
            completion_date: date
        });
        dispatch(setDetailJob({ ...detailData, final_bill_date: newJobData.data.final_bill_date }));
    };
}

function approveRequest(requestData, items = []) {
    return async (dispatch, getState) => {
        const {
            user: { currentCompany }
        } = getState();
        if (requestData.main_address && requestData.main_address.value === 0) {
            const { main_address_full } = requestData;
            const addrResponse = await axiosInstance.post(`${currentCompany}job/address/`, main_address_full);
            const addrResponseData = await addrResponse.data;
            requestData.main_address = addrResponseData.id;
        } else {
            requestData.main_address = requestData.main_address.value;
        }
        const response = await axiosInstance.post(`${currentCompany}job/job/`, requestData);
        const responseData = await response.data;
        requestData.file.forEach(async file => {
            const { id } = file;
            file.approved_job = responseData.id;
            await axiosInstance.put(`${currentCompany}job/stored_file/${id}/`, {
                ...file
            });
        });

        if (items.length > 0) {
            items.forEach(async file => {
                if (file.new_file) {
                    const fd = new FormData();
                    const fileName = file.new_file.name;
                    const extention = fileName.split(".").slice(-1)[0];
                    const limitLength = 100;
                    if (fileName.length > limitLength) {
                        const newFileName = fileName.slice(0, limitLength - 5);
                        file.new_file = new File([file.new_file], `${newFileName}.${extention}`, {
                            type: file.new_file.type
                        });
                    }
                    fd.append("new_file", file.new_file);
                    fd.append("file_category", file.file_category);
                    fd.append("approved_job", responseData.id);
                    await axiosInstance.post(`${currentCompany}job/stored_file/`, fd);
                }
            });
        }
        dispatch(push("/jobs/"));
    };
}

// function getVariationList(jobId) {
//     return async (dispatch, getState) => {
//         const {
//             user: { currentCompany }
//         } = getState();
//         const variationsResponse = await axiosInstance.get(`${currentCompany}job/variation/by_job/${jobId}/`);
//         return variationsResponse.data;
//     };
// }

function getDetailJob(jobId, listType) {
    return async (dispatch, getState) => {
        const {
            user: { currentCompany, isLeadingHand, isSLT, isCommercialSupervisorTeamLeader, isResidentialSupervisorTeamLeader, isQS }
        } = getState();
        const detailUrl = listType === "detail" || listType === "view" ? "" : "_request";
        const fileUrl = listType === "detail" || listType === "view" ? "approved" : "request";
        const response = await axiosInstance.get(`${currentCompany}job/job${detailUrl}/${jobId}/`);
        const urls =
            listType === "detail" || listType === "requested"
                ? [`${currentCompany}job/stored_file/by_${fileUrl}_job${isLeadingHand ? "_leading_hand" : ""}/${jobId}/`]
                : [
                      `${currentCompany}job/stored_file/by_${fileUrl}_job${isLeadingHand ? "_leading_hand" : ""}/${jobId}/`
                      //   `${currentCompany}maintenance/slim_maintenance_job/${jobId}/job/`,
                      //   `${currentCompany}job/inspection/slim/by_job/${jobId}/`
                  ];
        let responseClient;
        if (currentCompany === "fire-wbop/") {
            responseClient = await axiosInstance.get(`crm/slim_contact/${response.data.resource}/resource/`);
        } else {
            if (response.data.client) responseClient = await dispatch(crmActions.getSlimCurrentEntity(`?parent=${response.data.client}`));
            // if (response.data.client_entity_type === "Individual") {
            //     responseClient = await dispatch(crmActions.getSlimCurrentEntity("?entity_type=5"));
            // } else {
            //     responseClient = await dispatch(
            //         crmActions.getSlimCurrentEntity(`?parent=${response.data.client}`)
            //     );
            // }
        }

        await Promise.all(urls.map(url => axiosInstance.get(url))).then(async values => {
            const [fileResponse, mjsByJobResponse = {}, inspectionsResponse = {}] = values;
            let clients;
            if (currentCompany === "fire-wbop/") {
                clients = responseClient.data.map(client => ({
                    value: client.id,
                    label: `${client.first_name} ${client.last_name}`
                }));
            } else {
                clients = responseClient;
            }
            await dispatch(setClientContactDetail(clients));
            const detailData = {
                ...response.data,
                file: fileResponse.data.filter(file => !file.replacement && file.file_category !== 16),
                mjs: mjsByJobResponse.data || "",
                consent: [],
                // consent: response.data.consent.map(con => ({
                //     ...con,
                //     old: true,
                //     idx: con.id,
                //     building_consent_number: {
                //         value: con.id,
                //         label: con.building_consent_number
                //     }
                // })),
                inspections: inspectionsResponse ? inspectionsResponse.data : []
            };

            if (!isLeadingHand) {
                // const variation = await dispatch(getVariationList(jobId));
                const variation = [];
                detailData.variations = variation.map(vari => ({ ...vari, files_list: vari.files_list.filter(file => !file.replacement) }));
                // listType === "view" && currentCompany === "fire-akl/" && dispatch(getChargingService({ id: detailData.id, signal }));
            }

            if (isLeadingHand) {
                detailData.service_rates = [];
                detailData.job_services = [];
            }
            detailData.service_rates = detailData.service_rates?.filter(rate =>
                isSLT || isCommercialSupervisorTeamLeader || isResidentialSupervisorTeamLeader || isQS ? rate : rate.service_rate !== 1
            );
            // if (!detailData.main_address_full.latitude) {
            //     const { main_address_full } = detailData;
            //     const addressString = `${main_address_full.number} ${main_address_full.road_name} ${main_address_full.suburb} ${main_address_full.post_code} ${main_address_full.country}`;
            //     const geocodeResponse = await axiosInstance.get(
            //         `https://api.mapbox.com/geocoding/v5/mapbox.places/${addressString}.json?access_token=${process.env.REACT_APP_MAPBOX_KEY}&limit=1&country=NZ`
            //     );
            //     const geocode = geocodeResponse.data.features[0];
            //     detailData.main_address_full.longitude = geocode.center[0];
            //     detailData.main_address_full.latitude = geocode.center[1];
            //     // save coordinates here
            //     await axiosInstance.patch(`${currentCompany}job/address/${detailData.main_address}/update_coordinates/`, {
            //         latitude: geocode.center[1],
            //         longitude: geocode.center[0]
            //     });
            // }
            await dispatch(setDetailJob(detailData));
        });
    };
}

function setHiddenJob(jobId) {
    return async (dispatch, getState) => {
        const {
            user: { currentCompany }
        } = getState();
        try {
            const response = await axiosInstance.post(`${currentCompany}job/job/${jobId}/set_hidden_job/`);
            if (response.status === 204) {
                dispatch(push("/jobs/"));
            }
        } catch (error) {
            alert("Something went wrong, please try again later.");
        }
    };
}

function clearHiddenJob(jobId) {
    return async (dispatch, getState) => {
        const {
            user: { currentCompany },
            jobs: { detailData }
        } = getState();
        try {
            const response = await axiosInstance.post(`${currentCompany}job/job/${jobId}/clear_hidden_job/`);
            if (response.status === 204) {
                dispatch(setDetailJob({ ...detailData, user_hidden_job: false }));
            }
        } catch (error) {
            alert("Something went wrong, please try again later.");
        }
    };
}

function getJobCommons(hasToRefresh = false) {
    return async (dispatch, getState) => {
        const urls = [
            "common-fire/job/category/",
            "common-fire/job/price_type/",
            "common-fire/job/offered_service/",
            "common/job/offered_service/",
            "common-fire/job/qs_check_item/",
            "common-fire/job/uploaded_file_category/"
        ];
        await Promise.all(urls.map(url => axiosInstance.get(url))).then(values => {
            const [categoryResponse, priceTypeResponse, offerdServiceResponse, liningsOfferedServiceResponse, qsCheckResponse, fileCategoryResponse] =
                values;
            localStorage.setItem("category", JSON.stringify(categoryResponse.data));
            localStorage.setItem("priceType", JSON.stringify(priceTypeResponse.data));
            localStorage.setItem("service", JSON.stringify(offerdServiceResponse.data));
            localStorage.setItem("linings-service", JSON.stringify(liningsOfferedServiceResponse.data));
            // localStorage.setItem(
            //     "product",
            //     JSON.stringify(
            //         productResponse.data.map(product => {
            //             product.unit_string = JSON.parse(`"${product.unit_string}"`);
            //             return product;
            //         })
            //     )
            // );
            localStorage.setItem("qsCheck", JSON.stringify(qsCheckResponse.data));
            localStorage.setItem("fileCategory", JSON.stringify(fileCategoryResponse.data));
        });
        if (hasToRefresh) {
            window.location.reload();
        }
    };
}

function generateBluesheet(jobId) {
    return async (dispatch, getState) => {
        const {
            user: { currentCompany, permissions, companyPrefix }
        } = getState();

        try {
            const downUrl = permissions.job !== 4 ? "_supervisor" : "";
            const bluesheetResponse = await axiosInstance.get(`${currentCompany}job/job/get_bluesheet${downUrl}/${jobId}/`, { responseType: "blob" });

            const url = window.URL.createObjectURL(new Blob([bluesheetResponse.data], { type: "application/pdf" }));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", `${companyPrefix}-BS${jobId}.pdf`);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            return { message: "completed", jobId };
        } catch (error) {
            console.log(error.response);
        }
    };
}

function downloadPLReport(downInfo, tempCompany = null) {
    return async (dispatch, getState) => {
        const {
            user: { isSLT, currentCompany }
        } = getState();
        if (downInfo.job.length >= 12) {
            const confirm = window.confirm("More than 11 jobs may be very slow or time out, are you sure you want to proceed?");
            if (!confirm) {
                return { message: "rejected", downInfo };
            }
        }
        if (isSLT) {
            try {
                const response = await axiosInstance.post(`${tempCompany || currentCompany}job/profit_loss/`, downInfo, {
                    responseType: "blob",
                    timeout: 50000
                });

                if (response.status === 204) {
                    alert("Either no data available or error occured");
                    return;
                }
                const disposition = response.request.getResponseHeader("Content-Disposition");
                const url = window.URL.createObjectURL(new Blob([response.data], { type: "application/pdf" }));
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", disposition.split('"')[1]);
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                return { message: "completed", downInfo };
            } catch (error) {
                return error.response || error;
            }
        } else {
        }
    };
}

function getNewClientBaseData() {
    return async (dispatch, getState) => {
        const {
            jobs: { baseData }
        } = getState();

        const urls = [`crm/resource_type/`, `crm/contact_role/`, `crm/name_type/`, `crm/other_name/`];

        await Promise.all(urls.map(url => axiosInstance.get(url))).then(values => {
            const [resourceTypeData, contactRoleData, nameTypeData, otherNameData] = values;
            dispatch(
                setNewClientBaseData({
                    resourceTypeData: resourceTypeData.data,
                    contactRoleData: contactRoleData.data,
                    addressData: baseData.addressData,
                    nameTypeData: nameTypeData.data,
                    otherNameData: otherNameData.data
                })
            );
        });
    };
}

function getFullAddress(id = 0) {
    return async (dispatch, getState) => {
        const {
            user: { currentCompany }
        } = getState();
        if (id !== 0) {
            const fullAddressResponse = await axiosInstance.get(`${currentCompany}job/address/${id}/`);
            const fullAddressData = fullAddressResponse.data;
            dispatch(setFullAddress({ ...fullAddressData, isNew: false }));
        } else {
            dispatch(
                setFullAddress({
                    number: "",
                    road_name: "",
                    suburb: "",
                    town_city: "",
                    post_code: "",
                    country: "",
                    isNew: true
                })
            );
        }
    };
}

function editAddress(address) {
    return async (dispatch, getState) => {
        const {
            user: { currentCompany }
        } = getState();
        if (address.id) {
            const editAddressResponse = await axiosInstance.put(`${currentCompany}job/address/${address.id}/`, address);
            const editedAddress = await editAddressResponse.data;
            dispatch(changeEditedAddress(editedAddress));
        } else {
            const addrResponse = await axiosInstance.post(`${currentCompany}job/address/`, address);
            return addrResponse.data;
        }
    };
}

function fileDownload(idx) {
    return async (dispatch, getState) => {
        const {
            user: { currentCompany }
        } = getState();
        const response = await axiosInstance.get(`${currentCompany}job/stored_file/get_public_download/${idx}/`);
        const linkurl = response.data.download_link;
        const link = document.createElement("a");
        link.href = linkurl;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };
}

function getBranchList() {
    return async (dispatch, getState) => {
        const {
            jobs: { branchList }
        } = getState();
        if (branchList && branchList.length > 0) return;
        try {
            const branchListResponse = await axiosInstance.get("common-fire/job/reporting_area/");
            const branchList = await branchListResponse.data;
            dispatch(setBranchList(branchList));
        } catch (error) {
            console.log(error);
        }
    };
}

function updateTimecloud(jobId) {
    return (_, getState) => {
        const {
            user: { currentCompany }
        } = getState();
        try {
            axiosInstance.patch(`${currentCompany}job/job/${jobId}/update_timecloud/`);
        } catch (error) {
            console.log(error.response);
        }
    };
}

function getLogList(filter = null) {
    return async (dispatch, getState) => {
        const {
            user: { currentCompany }
        } = getState();
        try {
            if (currentCompany === "linings-akl/") {
                const logResponse = await axiosInstance.get(`${currentCompany}job/job_note/${filter || ""}`);
                const filteredList = logResponse.data
                    .filter(log => !log.original)
                    .map(log => ({
                        ...log,
                        reply_details: log.reply_details.sort((a, b) => a.id - b.id)
                    }));
                dispatch(setLogList(filteredList));
            } else {
                dispatch(setLogList([]));
            }
        } catch (error) {
            console.log(error.response);
        }
    };
}

function getFollowupList(filter = null) {
    return async (dispatch, getState) => {
        const {
            user: { currentCompany }
        } = getState();

        try {
            if (currentCompany === "fire-akl/") {
                const logResponse = await axiosInstance.get(`${currentCompany}job/job_note_follow_up/incomplete/${filter || ""}`);
                const sortedList = logResponse.data.map(log => ({
                    ...log,
                    note_replies: log.note_replies.sort((a, b) => a.id - b.id)
                }));
                dispatch(setFollowupList(sortedList));
                return sortedList;
            } else {
                dispatch(setFollowupList([]));
                return [];
            }
        } catch (error) {
            console.log(error.response);
        }
    };
}

// action creators

function setSlimJobs(jobList) {
    return {
        type: SLIM_JOBS,
        jobList
    };
}

function setSlimJobsForQA(jobListForQA) {
    return {
        type: SLIM_JOBS_FOR_QA,
        jobListForQA
    };
}

function setSlimJobsNoDiary(jobListNoDiary) {
    return {
        type: SLIM_JOBS_NO_DIARY,
        jobListNoDiary
    };
}

function setJobRequested(jobList) {
    return {
        type: JOB_REQUESTED,
        jobList
    };
}

function setBaseDataForDetail(baseData) {
    return {
        type: SET_BASE_DATA_FOR_DETAIL,
        baseData
    };
}

function addNewClient(newClient) {
    return {
        type: ADD_NEW_CLIENT,
        newClient
    };
}

function addNewclientContactDetail(newClientContact) {
    return {
        type: ADD_NEW_CLIENT_CONTACT_DETAIL,
        newClientContact
    };
}

function setClientContactDetail(clientContactDetail) {
    return {
        type: SET_CLIENT_CONTACT_DETAIL,
        clientContactDetail
    };
}

function removeClientContact() {
    return {
        type: REMOVE_CLIENT_CONTACT
    };
}

function setDetailJob(detailData) {
    return {
        type: SET_DETAIL_JOB,
        detailData
    };
}

function setNewClientBaseData(newClientData) {
    return {
        type: SET_NEW_CLIENT_BASE_DATA,
        newClientData
    };
}

function changeEditedAddress(editedAddress) {
    return {
        type: CHANGE_EDITED_ADDRESS,
        editedAddress
    };
}

function setFullAddress(fullAddress) {
    return {
        type: SET_FULL_ADDRESS,
        fullAddress
    };
}

function setBranchList(branchList) {
    return {
        type: SET_BRANCH_LIST,
        branchList
    };
}

function setCurrentBranch(currentBranch) {
    return {
        type: SET_CURRENT_BRANCH,
        currentBranch
    };
}

function setLngLat(lngLat) {
    return {
        type: SET_LNG_LAT,
        lngLat
    };
}

function setLogList(logList) {
    return {
        type: SET_LOG_LIST,
        logList
    };
}

function setFollowupList(followupList) {
    return {
        type: SET_FOLLOW_UP_LIST,
        followupList
    };
}
function setLogOpened(logId) {
    return {
        type: SET_LOG_OPENED,
        logId
    };
}

function setJobView(isJobView) {
    return {
        type: SET_JOB_VIEW,
        isJobView
    };
}

// initial state

const initialState = {
    isDetail: false,
    inspectionType: JSON.parse(localStorage.getItem("inspectionType")) || [],
    inspectionStatus: JSON.parse(localStorage.getItem("inspectionStatus")) || [],
    bookingTime: JSON.parse(localStorage.getItem("bookingTime")) || [],
    currentBranch: localStorage.getItem("currentBranch") || 1
};

// reducer

function reducer(state = initialState, action) {
    switch (action.type) {
        case SLIM_JOBS:
            return applySlimJobs(state, action);
        case SLIM_JOBS_FOR_QA:
            return applySlimJobsForQA(state, action);
        case SLIM_JOBS_NO_DIARY:
            return applySlimJobsNoDiary(state, action);
        case JOB_REQUESTED:
            return applyJobRequested(state, action);
        case SET_BASE_DATA_FOR_DETAIL:
            return applyBaseData(state, action);
        case SET_CLIENT_CONTACT_DETAIL:
            return applyClientContactDetail(state, action);
        case REMOVE_CLIENT_CONTACT:
            return applyRemoveClientContact(state, action);
        case SET_DETAIL_JOB:
            return applyDetailJob(state, action);
        case SET_NEW_CLIENT_BASE_DATA:
            return applyNewClientBaseData(state, action);
        case ADD_NEW_CLIENT:
            return applyAddNewClient(state, action);
        case ADD_NEW_CLIENT_CONTACT_DETAIL:
            return applyAddNewClientContact(state, action);
        case CHANGE_EDITED_ADDRESS:
            return applyChangeEditedAddress(state, action);
        case SET_FULL_ADDRESS:
            return applyfullAddress(state, action);
        case SET_BRANCH_LIST:
            return applyBranchList(state, action);
        case SET_LNG_LAT:
            return applyLngLat(state, action);
        case SET_FOLLOW_UP_LIST:
            return { ...state, followupList: action.followupList };
        case SET_LOG_LIST:
            return { ...state, logList: action.logList };
        case SET_LOG_OPENED:
            return { ...state, openedLogId: action.logId };
        case SET_JOB_VIEW:
            return { ...state, isJobView: action.isJobView };
        case SET_CURRENT_BRANCH:
            return {
                ...state,
                currentBranch: action.currentBranch
            };
        default:
            return state;
    }
}

// reducer function

function applySlimJobs(state, action) {
    const { jobList } = action;
    return {
        ...state,
        jobList
    };
}

function applySlimJobsForQA(state, action) {
    const { jobListForQA } = action;
    return {
        ...state,
        jobListForQA
    };
}

function applySlimJobsNoDiary(state, action) {
    const { jobListNoDiary } = action;
    return {
        ...state,
        jobListNoDiary
    };
}

function applyJobRequested(state, action) {
    const { jobList } = action;
    return {
        ...state,
        jobList
    };
}

function applyLngLat(state, action) {
    const { lngLat } = action;
    const { detailData } = state;
    return {
        ...state,
        detailData: { ...detailData, ...lngLat }
    };
}

function applyBaseData(state, action) {
    const { baseData } = action;

    return {
        ...state,
        baseData
    };
}

function applyClientContactDetail(state, action) {
    const { clientContactDetail } = action;
    return {
        ...state,
        clientContactDetail
    };
}

function applyRemoveClientContact(state, action) {
    return {
        ...state,
        clientContactDetail: []
    };
}

function applyDetailJob(state, action) {
    const { detailData } = action;
    return {
        ...state,
        detailData
    };
}

function applyNewClientBaseData(state, action) {
    const { newClientData } = action;
    return {
        ...state,
        newClientData
    };
}

function applyAddNewClient(state, action) {
    const { newClient } = action;
    return {
        ...state,
        baseData: {
            ...state.baseData,
            clients: state.baseData.clients.map((client, i) => {
                if (i === 0) {
                    return { ...client, options: [newClient, ...client.options] };
                } else {
                    return client;
                }
            })
        }
    };
}

// TODO: fix add new client contact if it's detail page there is no "clientContactDetail" in state
function applyAddNewClientContact(state, action) {
    const {
        newClientContact: { newClientContactData, resourceNum }
    } = action;
    const { clientContactDetail } = state;
    if (resourceNum !== 0) {
        return {
            ...state,
            clientContactDetail: [...clientContactDetail, newClientContactData]
        };
    } else {
        return state;
    }
}

function applyChangeEditedAddress(state, action) {
    const { editedAddress } = action;

    let label = `${editedAddress.number} ${editedAddress.road_name} ${editedAddress.suburb}`;

    return {
        ...state,
        editedAddressData: {
            main_address: { value: editedAddress.id, label },
            main_address_full: editedAddress,
            main_address_string: label
        }
    };
}

function applyfullAddress(state, action) {
    const { fullAddress } = action;
    return {
        ...state,
        fullAddress
    };
}

function applyBranchList(state, action) {
    const { branchList } = action;
    return {
        ...state,
        branchList
    };
}

//export

const actionCreators = {
    getSlimJobs,
    getSlimJobsForQA,
    getSlimJobsNoDiary,
    getJobRequested,
    getSkinnyJobDetail,
    getJobBaseData,
    postJob,
    getClientContactDetails,
    removeClientContact,
    getDetailJob,
    generateBluesheet,
    getNewClientBaseData,
    editAddress,
    approveRequest,
    fileDownload,
    getFullAddress,
    getJobCommons,
    downloadPLReport,
    getBranchList,
    setCurrentBranch,
    markComplete,
    addNewClient,
    addNewclientContactDetail,
    postFiles,
    setLngLat,
    setHiddenJob,
    clearHiddenJob,
    updateTimecloud,
    getFollowupList,
    setFollowupList,
    getLogList,
    setLogList,
    setLogOpened,
    setJobView
};

export { actionCreators };

export default reducer;

export const getClientContact = (jobsState, userState) => {
    const { clientContactDetail } = jobsState;
    if (clientContactDetail) {
        return clientContactDetail.map(client => ({
            value: client.id,
            label: client.name,
            subLabel: client.parent_name_string,
            searchText: `${client.name} ${client.parent_name_string}`,
            pending: client.pending,
            pending_relationship: client.pending_relationship,
            pending_client: client.pending_client
        }));
    } else {
        return [];
    }
};

export const getServiceRateName = jobsState => {
    const serviceRate = JSON.parse(localStorage.getItem("serviceSubRate"));
    return serviceRate
        ? serviceRate.map(rate => {
              return {
                  associated_service: rate.associated_service,
                  name: rate.name.split(" ")[0],
                  service_rate: rate.id
              };
          })
        : "";
};

export const getSlimJobsListByBreakdown = jobsState => {
    const { jobList } = jobsState;
    return jobList ? jobList.filter(job => job.breakdown_created === true) : [];
};

export const getBranchListByOrderFiltered = jobState => {
    const { branchList } = jobState;
    return branchList ? branchList.filter(branch => branch.current_area).sort((a, b) => a.id - b.id) : [];
};

export const getSlimJobFilteredByBranch = jobState => {
    const { jobList, currentBranch, branchList } = jobState;
    const currentRegion = localStorage.getItem("currentRegion");
    if (currentRegion === "Auckland") {
        const regions = JSON.parse(localStorage.getItem("regions")) || userAction.getUserCommons(true)();
        const currentRegionList = branchList ? branchList.find(branch => branch.id === Number(currentBranch)).regions : [];
        return jobList
            ? jobList
                  .filter(job => currentRegionList.includes(job.region))
                  .map(job => ({
                      ...job,
                      short_region: regions.find(reg => reg.id === job.region).region_short_code
                  }))
            : [];
    } else {
        return jobList;
    }
};

export const getSlimJobListByDiary = (userState, jobsState) => {
    const { jobListForQA } = jobsState;
    const { companyPrefix } = userState;
    return jobListForQA
        ? jobListForQA.map(job => ({
              value: job.id,
              label: `${companyPrefix}-${job.id}: ${job.job_name} ${job.job_name ? "- " : ""}${job.client_string}`
          }))
        : undefined;
};

export const getSlimJobListNoDiary = (userState, jobsState) => {
    const { jobListNoDiary } = jobsState;
    return jobListNoDiary
        ? jobListNoDiary.map(job => ({
              value: job.id,
              label: `${job.id}: ${job.job_name} ${job.job_name ? "- " : ""}${job.client_string || job.resource_string}`
          }))
        : undefined;
};

export const getInternalStaffList = jobsState => {
    const { baseData } = jobsState;
    const qsUsers = baseData ? baseData.qsUserData.map(user => ({ label: user.name_string, value: user.id })) : [];
    const supervisor = baseData ? baseData.supervisorData.map(user => ({ label: user.name_string, value: user.id })) : [];
    return _.uniqBy([...qsUsers, ...supervisor], "value").sort((a, b) => {
        return a.label > b.label ? 1 : -1;
    });
};
