import React from "react";
import Loading from "components/Loading";
import styles from "shared/listStyles.scss";
import CreateNewButton from "components/CreateNewButton";
import { MDBDataTable, MDBIcon, MDBBtn, MDBBtnGroup } from "louis-mdbreact";
import { Link } from "react-router-dom";

const QualityAssurance = props => {
    const {
        normalView: { QA },
        toggleChangeView,
        downloadQAReport
    } = props;
    const data = !QA
        ? {
              columns: [
                  { label: "No.", field: "id", sort: "asc" },
                  { label: "Client", field: "client", sort: "asc" },
                  { label: "Site", field: "site", sort: "asc" },
                  { label: "Area", field: "area", sort: "asc" },
                  { label: "Supervisor", field: "supervisor", sort: "asc" },
                  { label: "QS", field: "qs", sort: "asc" },
                  { label: "QA Type", field: "qa_type", sort: "asc" },
                  { label: "Actions", field: "actions" }
              ],
              rows:
                  !props.loading &&
                  props.QAAllList.map(job => ({
                      id: `${job.id}`,
                      client: job.client_string || "-",
                      site: job.job_name || "-",
                      area: job.area || "-",
                      supervisor: job.supervisor_name_string || "-",
                      qs: job.qs_name_string || "-",
                      qa_type: job.qa_type_string,
                      actions: (
                          <MDBBtnGroup>
                              <Link to={`/operations/qa/${job.id}`}>
                                  <MDBBtn color="primary" className={`${styles.buttonSize} ${styles.noRound}`}>
                                      <MDBIcon icon="info-circle" />
                                  </MDBBtn>
                              </Link>
                              <MDBBtn color="success" value={job.id} onClick={downloadQAReport} className={`${styles.buttonSize} ${styles.noRound}`}>
                                  {job.pending ? (
                                      <div className="spinner-border spinner-border-sm" role="status">
                                          <span className="sr-only">Loading...</span>
                                      </div>
                                  ) : (
                                      <MDBIcon icon="arrow-down" />
                                  )}
                              </MDBBtn>
                          </MDBBtnGroup>
                      ),
                      rowClassName: job.qa_report_sent ? "yellow lighten-2" : ""
                  }))
          }
        : {
              columns: [
                  { label: "No.", field: "job_no", sort: "asc" },
                  { label: "Client", field: "client", sort: "asc" },
                  { label: "Site", field: "site", sort: "asc" },
                  { label: "Supervisor", field: "supervisor", sort: "asc" },
                  { label: "QS", field: "qs", sort: "asc" },
                  { label: "Details", field: "details" }
              ],
              rows:
                  !props.loading &&
                  props.jobList.map(job => ({
                      job_no: `${props.prefixText}-${job.id}`,
                      client: job.client_string || "-",
                      site: job.job_name || "-",
                      supervisor: job.internal_supervisor_string || "-",
                      qs: job.internal_qs_string || "-",
                      details: (
                          <MDBBtnGroup>
                              <MDBBtn color="primary" className={`${styles.buttonSize} ${styles.noRound}`} onClick={props.toggleQA} value={job.id}>
                                  <MDBIcon icon="info-circle" />
                              </MDBBtn>
                          </MDBBtnGroup>
                      )
                  }))
          };
    return (
        <div className={styles.container}>
            <div className={styles.titleWrap}>
                <h2 className={`${styles.taskTitle}`}>
                    <MDBBtn type="button" color="primary" className={styles.buttonSize} onClick={toggleChangeView} size="sm">
                        <MDBIcon icon={`${QA ? "align-justify" : "layer-group"}`} />
                    </MDBBtn>
                    {!QA ? "QA" : props.titleName}
                </h2>
                <CreateNewButton />
                {!QA ? undefined : (
                    <div className={styles.switchWrap}>
                        <div className={`${styles.listSwitch} custom-control custom-switch`}>
                            <input
                                type="checkbox"
                                className="custom-control-input"
                                id="myjob"
                                name="isMine"
                                checked={props.isMine}
                                onChange={props.changeList}
                                readOnly
                            />
                            <label className="custom-control-label" htmlFor="myjob">
                                MY QA
                            </label>
                        </div>
                        <div className={`${styles.listSwitch} custom-control custom-switch`}>
                            <input
                                type="checkbox"
                                className="custom-control-input"
                                id="current"
                                name="isCurrent"
                                checked={props.isCurrent}
                                onChange={props.changeList}
                                readOnly
                            />
                            <label className="custom-control-label" htmlFor="current">
                                CURRENT
                            </label>
                        </div>
                    </div>
                )}
            </div>
            {props.loading ? (
                <Loading loadingType="list" />
            ) : (
                <MDBDataTable
                    entriesOptions={[10, 20, 50, 100, 500]}
                    entries={20}
                    responsive
                    borderless
                    striped
                    responsiveSm
                    pagesAmount={5}
                    data={data}
                    searchText={props.searchText}
                    initialPage={props.activePage}
                    onSearch={props.onSearch}
                    onPageChange={props.onPageChange}
                    className={`${!QA ? styles.qaDataTable : styles.jobsDataTable} ${styles.dataTable}`}
                />
            )}
        </div>
    );
};

QualityAssurance.defaultProps = {
    QAAllList: [],
    jobList: []
};

export default QualityAssurance;
