import { Texture, Container, Sprite, TilingSprite, Graphics } from "pixi.js";
import { gsap } from "gsap";

import Clouds from "images/clouds 2.png";
import BackgroundFront from "images/building_layer_01.png";
import BackgroundMiddle from "images/building_layer_02.png";
import BackgroundBehind from "images/building_layer_03.png";
import Wave from "images/wave.png";
import purpleCow from "images/purple_cow.png";
import Logo from "images/logo.svg";

const BACKGROUND_BEHIND_POSITION_Y = 315;
const CLOUD_POSITION_Y = 213;
const BACKGROUND_MIDDLE_POSITION_Y = 450;
const BACKGROUND_FRONT_POSITION_Y = 497;
const GRASS_POSITION_Y = 620;

const RIVER_POSITION_Y = 625;
const WAVE_POSITION_Y = 625;
const WAVE_HEIGHT = 214;
const WAVES = 6;

const LOGO_WIDTH = 500;
const LOGO_HEIGHT = 188;

const backgroundImages = [
    { img: BackgroundBehind, timing: 0.3, height: 312, positionY: BACKGROUND_BEHIND_POSITION_Y },
    { img: Clouds, timing: 0.2, height: 200, positionY: CLOUD_POSITION_Y },
    { img: BackgroundMiddle, timing: 0.5, height: 171, positionY: BACKGROUND_MIDDLE_POSITION_Y },
    { img: BackgroundFront, timing: 1, height: 126, positionY: BACKGROUND_FRONT_POSITION_Y }
];

class Background {
    constructor() {
        this.container = new Container();
        const grd = this.gradient("#F89C64", "white");
        this.grdBackground = new Sprite(grd);
        this.width = window.innerWidth;

        this.tweens = [];
        this.container.addChild(this.grdBackground);
        this.createBuildings();
    }

    createBuildings = () => {
        const logoTexture = new Texture.from(Logo);
        this.logo = new Sprite(logoTexture);
        this.logo.width = LOGO_WIDTH;
        this.logo.height = LOGO_HEIGHT;
        this.logo.alpha = 0.7;
        this.backgrounds = backgroundImages.map((bg, index) => {
            const background = Texture.from(bg.img);
            const tilingSprite = new TilingSprite(background, window.innerWidth, bg.height);
            tilingSprite.y = bg.positionY;
            if (index === 2) {
                this.logo.x = (window.innerWidth - LOGO_WIDTH) / 2;
                this.logo.y = 360;
                this.container.addChild(this.logo);
            }

            this.container.addChild(tilingSprite);
            return tilingSprite;
        });

        this.bgUnderBuildings = new Graphics();
        this.bgUnderBuildings.beginFill(0x3ea63e);
        this.bgUnderBuildings.drawRect(0, GRASS_POSITION_Y, window.innerWidth, 6);
        this.bgUnderBuildings.endFill();
        const grd = this.gradient("#DDBBBA", "#26A5DC");
        this.river = new Sprite(grd);
        this.container.addChild(this.river);
        this.river.height = window.innerHeight - RIVER_POSITION_Y;
        this.river.x = 0;
        this.river.y = RIVER_POSITION_Y;
        this.container.addChild(this.bgUnderBuildings);
        this.waves = new Container();
        this.container.addChild(this.waves);

        const cow = new Sprite.from(purpleCow);
        this.container.addChild(cow);
        cow.x = 288;
        cow.y = 640;

        gsap.to(cow, {
            y: 645,
            duration: 0.6,
            repeatRefresh: true,
            repeat: -1,
            yoyo: true
        });

        const waveTexture = Texture.from(Wave);
        for (let i = 0; i < WAVES; i++) {
            const wave = new TilingSprite(waveTexture, this.river.width, WAVE_HEIGHT);
            wave.tilePosition.x = this.river.width * 0.5 * i;
            wave.y = WAVE_POSITION_Y + WAVE_HEIGHT * i;
            this.waves.addChild(wave);
        }
    };

    tick = () => {
        this.backgrounds.forEach((back, i) => {
            back.tilePosition.x %= back.texture.orig.width;
            back.tilePosition.y %= back.texture.orig.height;
            back.tilePosition.x -= backgroundImages[i].timing;
        });

        for (let i = 0; i < WAVES; i++) {
            this.waves.children[i].tilePosition.x %= this.waves.children[i].texture.orig.width;
            this.waves.children[i].tilePosition.x -= 1.5 * (i * 0.2 + 1);
        }
    };

    remove = () => {
        this.tweens.forEach(tween => {
            gsap.killTweensOf(tween);
            tween = null;
        });
        this.bgUnderBuildings.destroy(true);
        this.bgUnderBuildings = null;
        this.waves.destroy(true);
        this.waves = null;
        this.backgrounds.forEach(back => {
            back.destroy(true);
            back = null;
        });

        this.backgrounds = null;
        this.container.removeChildren();
        this.container.destroy(true);
        this.container = null;
    };

    resize = (width, height) => {
        this.grdBackground.width = width;
        this.grdBackground.height = height;
        this.backgrounds.forEach(back => {
            back.width = width;
        });
        this.width = width;
        this.waves.width = width;
        this.river.width = width;
        this.river.height = window.innerHeight - RIVER_POSITION_Y;
        this.bgUnderBuildings.width = width;
        this.logo.x = (width - LOGO_WIDTH) / 2;
    };

    gradient(from, to) {
        const quality = window.innerWidth;
        const height = window.innerHeight;
        const canvas = document.createElement("canvas");
        canvas.width = quality;
        canvas.height = height;

        const ctx = canvas.getContext("2d", { willReadFrequently: true });

        // use canvas2d API to create gradient
        const grd = ctx.createLinearGradient(0, 0, 0, quality / 2);
        grd.addColorStop(0, from);
        grd.addColorStop(1, to);

        ctx.fillStyle = grd;
        ctx.fillRect(0, 0, quality, height);

        return new Texture.from(canvas);
    }
}

export default Background;
