import React, { useState } from "react";
import styles from "shared/details.scss";
import { MDBCard, MDBCardHeader, MDBCardBody, MDBRow, MDBCol } from "louis-mdbreact";
import { getLastPayPeriod } from "shared/Utils";
import Box from "@mui/material/Box";
import { axiosInstance } from "shared/axiosInst";
import { useSelector } from "react-redux";
import LoadingButton from "@mui/lab/LoadingButton";
import SaveIcon from "@mui/icons-material/Save";
import { Helmet } from "react-helmet";

const LeaveReport = () => {
    const { currentCompany } = useSelector(state => state.user);
    const [leavePeriod, setLeavePeriod] = useState(getLastPayPeriod);
    const [loading, setLoading] = useState(false);
    const onClickGenerate = async () => {
        setLoading(true);
        await axiosInstance.post(`${currentCompany}hr/leave_day/salary_staff_leave/${leavePeriod.start}/${leavePeriod.end}/`);
        setLoading(false);
    };
    return (
        <div className={styles.container}>
            <Helmet>
                <title>HR | Leave Report</title>
            </Helmet>
            <h2 className={styles.title}>LEAVE REPORT</h2>
            <MDBCard className={styles.card}>
                <MDBCardHeader>Generate Report</MDBCardHeader>
                <MDBCardBody>
                    <MDBRow>
                        <MDBCol>
                            <label htmlFor="startDateRange">Leave Period</label>
                            <MDBRow>
                                <MDBCol sm="6">
                                    <div className="input-group mb-3">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text">from</span>
                                        </div>
                                        <input
                                            type="date"
                                            className="form-control"
                                            name="start"
                                            value={leavePeriod.start}
                                            onChange={e => {
                                                setLeavePeriod({ ...leavePeriod, start: e.currentTarget.value });
                                            }}
                                        />
                                    </div>
                                </MDBCol>
                                <MDBCol sm="6">
                                    <div className="input-group mb-3">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text">to</span>
                                        </div>
                                        <input
                                            type="date"
                                            className="form-control"
                                            name="end"
                                            value={leavePeriod.end}
                                            onChange={e => {
                                                setLeavePeriod({ ...leavePeriod, end: e.currentTarget.value });
                                            }}
                                        />
                                    </div>
                                </MDBCol>
                            </MDBRow>
                        </MDBCol>
                    </MDBRow>
                </MDBCardBody>
            </MDBCard>
            <Box sx={{ display: "flex", justifyContent: "flex-end", paddingY: "1rem" }}>
                <LoadingButton loading={loading} startIcon={<SaveIcon />} loadingPosition="start" variant="contained" onClick={onClickGenerate}>
                    Generate
                </LoadingButton>
            </Box>
        </div>
    );
};

export default LeaveReport;
