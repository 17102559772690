import React, { Component } from "react";
import SSSPCheck from "./presenter";
// import SignModal from "components/modals/SignModal";

class Container extends Component {
    constructor(props) {
        super(props);

        const {
            match: { params },
            permissions,
            jobDetail,
            type,
            formDetail
        } = props;
        this.state = {
            // modal: false,
            isLoading: true,
            taskType: JSON.parse(localStorage.getItem("service")),
            pageType: params.id ? "detail" : "new",
            permissionLevel: permissions.operations,
            formData: {
                id: formDetail.id || undefined,
                job: jobDetail.id,
                formFiles: [],
                form_type: type.id
            },
            form_content: [],
            files: []
        };
    }

    componentDidMount() {
        const {
            type: { id },
            getFormElement
        } = this.props;
        getFormElement(id).then(value => this.setFormType(value));
    }

    componentDidUpdate(prevProps) {
        const { jobDetail, formDetail } = this.props;
        const { formData, form_content, pageType } = this.state;
        if (jobDetail !== prevProps.jobDetail) {
            this.setState(
                {
                    formData: { ...formData, job: jobDetail.id },
                    form_content: form_content.map(element => {
                        const selectedElement =
                            pageType === "detail" ? formDetail.form_content.find(content => content.element === element.element) : {};
                        return {
                            ...element,
                            response: "",
                            comment: "",
                            ...selectedElement,
                            visibility: true
                        };
                    })
                },
                () => this.updateFormData()
            );
        }
    }

    setFormType = formElement => {
        const { formDetail, assignedData } = this.props;
        const { pageType, formData } = this.state;
        const newFormElement = formElement;
        const regex = /\/?(19[0-9]{2}|2[0-9]{3})-(0[1-9]|1[012])-([123]0|[012][1-9]|31)\/?/;
        this.setState(
            {
                isLoading: false,
                formData: { ...formData, formFiles: assignedData?.formFiles || [] },
                form_content: newFormElement.map(element => {
                    let selectedElement;
                    if (pageType === "detail") {
                        selectedElement = formDetail.form_content.find(content => content.element === element.element);
                    } else if (pageType === "new" && assignedData) {
                        selectedElement = assignedData.form_content.find(content => content.element === element.element);
                    } else {
                        selectedElement = {};
                    }
                    let selectedDates = { start: "", end: "" };
                    if (regex.test(selectedElement?.comment)) {
                        const splitted = selectedElement.comment.split("/");
                        selectedDates.start = splitted[0];
                        selectedDates.end = splitted[1];
                    }
                    return {
                        ...element,
                        response: element.limit_responses_to.length > 0 ? 2 : "",
                        comment: "",
                        ...selectedElement,
                        start: "",
                        end: "",
                        ...selectedDates,
                        relevant_date: selectedElement?.relevant_date || "",
                        relevant_time: selectedElement?.relevant_time || "",
                        visibility: true
                    };
                })
            },
            () => this.updateFormData()
        );
    };

    onResponseChange = event => {
        const {
            target: { name, value }
        } = event;
        const { form_content } = this.state;
        this.setState(
            {
                form_content: form_content.map(element =>
                    element.element === Number(name)
                        ? {
                              ...element,
                              response: value
                          }
                        : element
                )
            },
            () => this.updateFormData()
        );
    };

    onDateChange = event => {
        const {
            target: { name, value }
        } = event;

        const { form_content } = this.state;
        const splittedName = name.split("_")[0];
        const periodName = name.split("_")[1];
        this.setState(
            {
                form_content: form_content.map(element =>
                    element.element === Number(splittedName)
                        ? {
                              ...element,
                              [periodName]: value
                          }
                        : element
                )
            },
            () => {
                const { form_content } = this.state;
                this.setState(
                    {
                        form_content: form_content.map(element =>
                            element.form_element_string.includes("If yes, please provide the start and finish date")
                                ? {
                                      ...element,
                                      comment: `${element.start || ""}/${element.end || ""}`
                                  }
                                : element
                        )
                    },
                    () => this.updateFormData()
                );
            }
        );
    };

    onCommentChange = event => {
        const {
            target: { name, value }
        } = event;
        const { form_content } = this.state;
        this.setState(
            {
                form_content: form_content.map(element =>
                    element.element === Number(name)
                        ? {
                              ...element,
                              comment: value
                          }
                        : element
                )
            },
            () => this.updateFormData()
        );
    };

    onRelavantDateTimeChange = event => {
        const {
            target: { name, value, type }
        } = event;
        const { form_content } = this.state;
        this.setState(
            {
                form_content: form_content.map(element =>
                    element.element === Number(name)
                        ? {
                              ...element,
                              [`relevant_${type}`]: value
                          }
                        : element
                )
            },
            () => this.updateFormData()
        );
    };

    fileClickTrigger = name => {
        document.getElementById(name).click();
    };

    onSSSPFileChange = event => {
        const {
            target: { files }
        } = event;
        const { files: stateFiles } = this.state;
        for (let i = 0; i < files.length; i++) {
            stateFiles.push({ file: files[i], id: Number(new Date()) + i });
        }
        this.setState(
            {
                files: stateFiles
            },
            () => this.updateFiles()
        );
    };

    onFileChange = event => {
        const {
            target: { files }
        } = event;
        const {
            formData: { formFiles }
        } = this.state;
        for (let i = 0; i < files.length; i++) {
            formFiles.push({ file: files[i], id: Number(new Date()) + i });
        }
        this.setState(
            {
                formData: {
                    ...this.state.formData,
                    formFiles
                }
            },
            () => this.updateFormData()
        );
    };

    removeFormItem = id => {
        const {
            formData: { formFiles }
        } = this.state;
        this.setState(
            {
                formData: {
                    ...this.state.formData,
                    formFiles: formFiles.filter(file => file.id !== Number(id))
                }
            },
            () => this.updateFormData()
        );
    };

    removeItem = id => {
        const { files } = this.state;
        this.setState(
            {
                files: files.filter(file => file.id !== Number(id))
            },
            () => this.updateFiles()
        );
    };

    // signToggle = event => {
    //     this.setState(prevState => ({
    //         modal: !prevState.modal
    //     }));
    // };

    // saveAttendee = signedAttendee => {
    //     const { form_content } = this.state;
    //     this.setState(
    //         {
    //             form_content: form_content.map(element =>
    //                 element.element === Number(signedAttendee.name)
    //                     ? {
    //                           ...element,
    //                           signature_data: signedAttendee
    //                       }
    //                     : element
    //             )
    //         },
    //         () => {
    //             this.signToggle();
    //             this.updateFormData();
    //         }
    //     );
    // };

    updateFiles = () => {
        const { files } = this.state;
        const { updateFiles } = this.props;
        updateFiles(files);
    };

    updateFormData = () => {
        const { updateFormData } = this.props;
        const { formData, form_content, pageType } = this.state;
        updateFormData({
            ...formData,
            form_content: form_content
                .filter(content => {
                    if (content.element === 2 && content.response === "") {
                        return Number(form_content[0].response) === 1;
                    } else {
                        return content.visibility;
                    }
                })
                .map(content => ({
                    id: pageType === "detail" ? content.id || undefined : undefined,
                    element: content.element,
                    response_to: content.limit_responses_to.length > 0,
                    response: content.response || null,
                    comment: content.comment,
                    relevant_date: content.relevant_date || null,
                    relevant_time: content.relevant_time || null
                }))
        });
    };

    // addSignature = event => {
    //     const {
    //         currentTarget: { name }
    //     } = event;
    //     this.setState({
    //         selectedAttendee: { name, attendee_name: "", signature: "" }
    //     });
    //     this.signToggle();
    // };

    downloadImage = event => {
        const {
            currentTarget: { value }
        } = event;
        const { downloadImage } = this.props;
        downloadImage(value);
    };

    render() {
        const {
            type,
            jobDetail,
            formDetail: { response_file, files_list },
            isDisabled
        } = this.props;
        // const { modal, selectedAttendee } = this.state;
        return (
            <>
                <SSSPCheck
                    {...this.state}
                    type={type}
                    jobDetail={jobDetail}
                    isDisabled={isDisabled}
                    response_file={response_file}
                    files_list={files_list}
                    onResponseChange={this.onResponseChange}
                    onCommentChange={this.onCommentChange}
                    onDateChange={this.onDateChange}
                    onRelavantDateTimeChange={this.onRelavantDateTimeChange}
                    fileClickTrigger={this.fileClickTrigger}
                    onSSSPFileChange={this.onSSSPFileChange}
                    onFileChange={this.onFileChange}
                    removeItem={this.removeItem}
                    removeFormItem={this.removeFormItem}
                    downloadImage={this.downloadImage}
                    // addSignature={this.addSignature}
                />
                {/* <SignModal modal={modal} toggle={this.signToggle} saveAttendee={this.saveAttendee} selectedAttendee={selectedAttendee} /> */}
            </>
        );
    }
}

Container.defaultProps = {
    jobDetail: {},
    formDetail: {}
};

export default Container;
