import React, { useContext, useState } from "react";
import DialogContainer from "components/DialogContainer";
import {
    Button,
    DialogActions,
    DialogContent,
    Table,
    TableBody,
    TableCell,
    TableRow,
    TextField,
    InputAdornment,
    Stack,
    MenuItem,
    IconButton
} from "@mui/material";
import { axiosInstance } from "shared/axiosInst";
import { formatter, getCompanyColour } from "shared/Utils";
import dayjs from "shared/dayjs";
import { QuoteContext } from "../CrmContext";
import { errorMessage, quoteBaseURL } from "./quoteUtils";
import itemStyles from "./styles.scss";
import KeyboardReturnIcon from "@mui/icons-material/KeyboardReturn";
import { useSelector } from "react-redux";

const humanise = (key, value) => {
    if (key === "quote_value") return formatter.format(value);
    if ((String(key).includes("date") || String(key).includes("last_followed_up")) && value !== "None") return dayjs(value).format("DD/MM/YYYY");
    if (typeof value === "boolean") return value ? "Yes" : "No";
    return value;
};

const WinChanceModal = ({ open, toggle, stage, onUpdate, onAddNoteClick }) => {
    const { quoteStatusList } = useContext(QuoteContext);
    const { welcomeName } = useSelector(state => state.user);
    const [currentStage, setCurrentStage] = useState(stage);

    const [newWin, setNewWin] = useState(stage.win_chance);
    const [quoteStatus, setQuoteStatus] = useState(stage.status);
    const [lettingSoon, setLettingSoon] = useState(stage.letting_soon);
    const [isChanged, setIsChanged] = useState(false);
    const onWinChanceChange = event => {
        setNewWin(event.target.value);
    };

    const onPostResponse = ({ key, value }) => {
        setIsChanged(true);
        setCurrentStage(current => ({ ...current, [key]: value }));
    };

    const onEditClick = async event => {
        try {
            const res = await axiosInstance.post(`${quoteBaseURL}update_win_chance/${stage.company}/${stage.id}/${newWin}/`);
            if (res.status === 204) {
                onPostResponse({ key: "win_chance", value: newWin });
            }
        } catch (error) {
            alert(errorMessage);
        }
    };

    const onClickStatus = async () => {
        try {
            const res = await axiosInstance.post(`${quoteBaseURL}update_quote_status/`, {
                company: stage.company,
                name: welcomeName,
                pk: stage.id,
                status: quoteStatus
            });
            if (res.status === 204) {
                onPostResponse({ key: "status", value: quoteStatus });
            }
        } catch (error) {
            alert(errorMessage);
        }
    };

    const onLettingSoonChange = async event => {
        try {
            const res = await axiosInstance.post(`${quoteBaseURL}update_letting_soon/${stage.company}/${stage.id}/${lettingSoon ? 1 : 0}/`);
            if (res.status === 204) {
                onPostResponse({ key: "letting_soon", value: lettingSoon });
            }
        } catch (error) {
            alert(errorMessage);
        }
    };

    const onCloseClick = event => {
        event.preventDefault();
        toggle();
        if (isChanged) onUpdate();
    };

    return (
        <DialogContainer open={open} handleClose={toggle} title="Edit" fullScreen={false} maxWidth="md">
            <DialogContent>
                <Table size="small">
                    <TableBody>
                        {Object.keys(stage)
                            .filter(
                                st =>
                                    st !== "win_chance" &&
                                    st !== "id" &&
                                    st !== "follow_up_notes" &&
                                    st !== "status" &&
                                    st !== "follow_up_note" &&
                                    st !== "letting_soon" &&
                                    st !== "noteOpen"
                            )
                            .map(st =>
                                stage[st] !== "" && stage[st] !== "None" ? (
                                    <TableRow key={st}>
                                        <TableCell variant="head" sx={{ textTransform: "capitalize" }}>
                                            {st.replace(/_/g, " ").trim()}
                                        </TableCell>
                                        <TableCell sx={{ whiteSpace: "pre-line" }}>{humanise(st, stage[st])}</TableCell>
                                    </TableRow>
                                ) : (
                                    ""
                                )
                            )}
                        <TableRow>
                            <TableCell variant="head" width="15%">
                                Letting Soon & High Priority
                            </TableCell>
                            <TableCell>
                                <Stack direction="row" gap={1}>
                                    <TextField
                                        fullWidth
                                        size="small"
                                        select
                                        value={lettingSoon}
                                        onChange={e => {
                                            setLettingSoon(e.target.value);
                                        }}
                                    >
                                        <MenuItem value={true}>Yes</MenuItem>
                                        <MenuItem value={false}>No</MenuItem>
                                    </TextField>
                                    <Button
                                        color="primary"
                                        variant="contained"
                                        onClick={onLettingSoonChange}
                                        disabled={lettingSoon === currentStage.letting_soon}
                                    >
                                        Change
                                    </Button>
                                </Stack>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell variant="head">Follow Up Notes</TableCell>
                            <TableCell sx={{ position: "relative", height: "60px" }}>
                                <IconButton
                                    onClick={onAddNoteClick}
                                    size="small"
                                    sx={{
                                        position: "absolute",
                                        bottom: "12px",
                                        right: "18px",
                                        backgroundColor: "#af52bf",
                                        color: "white",
                                        "&:hover": { backgroundColor: "#9c27b0" }
                                    }}
                                >
                                    <KeyboardReturnIcon />
                                </IconButton>
                                <ul className={itemStyles.noteList} style={{ marginLeft: "-5px" }}>
                                    <li>
                                        <p>{stage.follow_up_note}</p>
                                    </li>
                                    {stage.follow_up_notes.map(note => (
                                        <li key={note.created_at}>
                                            <span style={{ color: getCompanyColour(stage.company).color }}>
                                                {note.created_by} ({dayjs(note.created_at).format("DD/MM/YYYY")})
                                            </span>
                                            <p>{note.note_text}</p>
                                        </li>
                                    ))}
                                </ul>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell variant="head">Win Chance</TableCell>
                            <TableCell>
                                <Stack direction="row" gap={1}>
                                    <TextField
                                        fullWidth
                                        size="small"
                                        value={newWin}
                                        onChange={onWinChanceChange}
                                        InputProps={{ endAdornment: <InputAdornment position="end">%</InputAdornment> }}
                                    />
                                    <Button color="primary" variant="contained" disabled={newWin === currentStage.win_chance} onClick={onEditClick}>
                                        Change
                                    </Button>
                                </Stack>
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </DialogContent>
            <DialogActions sx={{ flexDirection: { xs: "column", sm: "column", md: "row" }, gap: 1, justifyContent: "space-between" }}>
                <Stack direction="row" gap={1}>
                    <TextField size="small" select value={quoteStatus} onChange={event => setQuoteStatus(event.target.value)}>
                        {quoteStatusList.map(status => (
                            <MenuItem key={status[0]} value={status[0]}>
                                {status[1]}
                            </MenuItem>
                        ))}
                    </TextField>
                    <Button variant="contained" onClick={onClickStatus} disabled={quoteStatus === currentStage.status}>
                        Change status
                    </Button>
                </Stack>
                <Stack direction="row" gap={1}>
                    <Button color="error" variant="contained" onClick={onCloseClick}>
                        close
                    </Button>
                </Stack>
            </DialogActions>
        </DialogContainer>
    );
};

export default WinChanceModal;
